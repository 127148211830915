import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FormAttrBase } from "../form-attr-base";

@Component({
  selector: "app-form-template",
  templateUrl: "./form-template.component.html",
  styleUrls: ["./form-template.component.less"],
})
export class FormTemplateComponent implements OnInit {
  // TODO: Отсюда же должна передаваться функция для onSubmit
  public formAttrsObserv: any = new BehaviorSubject(null);

  public formAttrs: FormAttrBase<string>[] = [];

  ngOnInit() {
    this.formAttrs = [
      // new FormAttrBase({
      //   key: 'firstName',
      //   label: 'First name',
      //   placeholder: 'Just placeholder',
      //   value: 'Val',
      //   visualRequired: true,
      //   controlType: 'input',
      //   disabled: false,
      //   validators: [
      //     {
      //       minLength: 2,
      //       maxLength: 4,
      //     },
      //   ],
      //   tuiTextfieldMaxLength: 10,
      //   icon: 'tuiIconSearch',
      //   iconAlign: 'left',
      //
      //   //   options: [
      //   //     { key: "solid", value: "Solid" },
      //   //     { key: "great", value: "Great" },
      //   //     { key: "good", value: "Good" },
      //   //     { key: "unproven", value: "Unproven" },
      //   //   ],
      //   // }),
      // }),
      new FormAttrBase({
        key: "name",
        label: "Last name",
        placeholder: "Second placeholder",
        visualRequired: true,
        controlType: "inputText",
        icon: "tuiIconSearch",
        // disabled: false,
        validators: [
          {
            minLength: 2,
            maxLength: 4,
          },
        ],
        tuiTextfieldMaxLength: 10,
      }),
      new FormAttrBase({
        key: "age",
        label: "Enter only number",
        placeholder: "Enter your age",
        visualRequired: true,
        controlType: "inputNumber",
        disabled: false,
        sizeBlock: "l",
        decimal: "not-zero",
        precision: 3,
        postfix: "$",
        prefix: "@",
        min: 0,
        max: 150,
        validators: [
          {
            minLength: 3,
            maxLength: 5,
          },
        ],
        tuiTextfieldMaxLength: 10,
      }),
      new FormAttrBase({
        key: "password",
        label: "Password",
        placeholder: "Enter password",
        visualRequired: true,
        controlType: "inputPassword",
        disabled: false,
        validators: [
          {
            minLength: 2,
            maxLength: 4,
          },
        ],
        tuiTextfieldMaxLength: 10,
      }),
      new FormAttrBase({
        key: "select",
        label: "Сhoose something",
        placeholder: "just select",
        controlType: "select",
        isGrouped: true,
        datalistSelect: [
          { value: 1, label: "John Cleese", group: "Тестовая группа" },
          { value: 2, label: "John ", group: "Тестовая группа" },
          { value: 3, label: " Cleese", group: "Тестовая группа 2" },
          { value: 4, label: "fjgjgj Cleese", group: "Тестовая группа 2" },
        ],
        visualRequired: true,
        validators: [{ required: true }],
      }),
      new FormAttrBase({
        key: "checkboxBlock",
        controlType: "checkboxBlock",
        content: "Checkbox block",
        sizeBlock: "s",
        value: null, // can be null | false | true
        contentAlign: "left",
        hideCheckbox: false,
      }),
      new FormAttrBase({
        key: "checkboxLabeled",
        controlType: "checkboxLabeled",
        content: "Checkbox block",
      }),
      new FormAttrBase({
        key: "radioBlock",
        controlType: "radioBlock",
        content: "content",
        contentAlign: "left",
        hideRadio: false,
        sizeBlock: "s",
        pseudoDisabled: true,
        item: "some item",
      }),
      new FormAttrBase({
        key: "radioLabeled",
        controlType: "radioLabeled",
        content: "content for labeled",
        size: "l",
        //pseudoDisabled: true,
        item: "some other item",
        description: "some description",
        //TODO item can be the object, for example {name: 'ownership'} and content can contain not only string, but also div
      }),
      new FormAttrBase({
        key: "radioLabeled1",
        controlType: "radioLabeled",
        content: "labeled content",
        size: "m",
        //pseudoDisabled: true,
        item: " other item",
        //TODO item can be the object, for example {name: 'ownership'} and content can contain not only string, but also div
      }),
    ];

    this.formAttrsObserv.next(this.formAttrs);
  }
}
