import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class MainHeaderComponent implements OnInit {
  @Input()
  public dropdownOpen: boolean = false;

  public isOpened: boolean = false;

  constructor(
    public appService: AppService,
    private cookieService: CookieService,
    public router: Router
  ) {}

  ngOnInit() {
    this.appService.leftMenuStatus.subscribe((isOpenMenu: boolean) => {
      this.isOpened = isOpenMenu;
    });
  }

  openSettings() {}

  logout() {
    localStorage.clear();
    this.cookieService.deleteAll('/');
    location.href = '/';
  }

  // testMMMM() {
  //   this.appService.testEvents().subscribe();
  // }
}
