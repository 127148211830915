import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormAttrBase } from '../../../../../common/UI/form/form-attr-base';
import { ObjectType } from '../../../../../models/object-type';
import { TuiDecimal, TuiNotification } from '@taiga-ui/core/enums';
import { TuiNotificationsService } from '@taiga-ui/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { OBJECTS_DEBIT, TYPE_MINING } from '../../../../const/app-consts';
import { ObjectService } from '../../../../services/object.service';
import { TypeTree } from '../../../../../models/tree.model';

@Component({
  selector: 'app-settings-object',
  templateUrl: './settings-object.component.html',
  styleUrls: ['./settings-object.component.less'],
})
export class SettingsObjectComponent implements OnInit, OnDestroy {
  @Output()
  changeSetting: EventEmitter<any> = new EventEmitter();

  public data: any = null;

  public debits: any;

  public typeMining: any;

  public formAttrsObservable: any = new BehaviorSubject(null);

  public formFields: FormAttrBase<string>[] = [];

  public isDeleteObjectDialog = false;

  destroyer = new Subject();

  constructor(
    @Inject(TuiNotificationsService)
    private readonly notificationsService: TuiNotificationsService,
    public objectService: ObjectService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.debits = Object.entries(OBJECTS_DEBIT).map((debit: any) => {
      return { value: debit[0], label: debit[1] };
    });

    this.typeMining = Object.entries(TYPE_MINING).map((type: any) => {
      return { value: type[0], label: type[1] };
    });
    this.getData();
  }

  getData() {
    this.objectService.selectedObject
      .pipe(takeUntil(this.destroyer))
      .subscribe((obj) => {
        if (obj) {
          this.formFields = [
            new FormAttrBase({
              key: 'name',
              label: 'Название',
              placeholder: 'Введите название объекта',
              controlType: 'inputText',
              visualRequired: true,
              value: obj.name,
            }),
            new FormAttrBase({
              key: 'parentId',
              label: 'Родительский объект',
              placeholder: 'Выберите родительский объект',
              controlType: 'select',
              visualRequired: true,
              value: obj.parent_id ? obj.parent_id : '999999',
              datalistSelect: this.objectService.parents.filter(
                (x: any) => x.value !== obj.id
              ),
            }),
            new FormAttrBase({
              key: 'latitude',
              label: 'Ширина',
              placeholder: 'Введите ширину',
              controlType: 'inputNumber',
              precision: 10,
              decimal: TuiDecimal.Always,
              visualRequired: true,
              value: obj.latitude,
            }),
            new FormAttrBase({
              key: 'longitude',
              label: 'Долгота',
              placeholder: 'Введите долготу',
              precision: 10,
              decimal: TuiDecimal.Always,
              controlType: 'inputNumber',
              visualRequired: true,
              value: obj.longitude,
            }),
          ];
          if (obj.type === 'borehole') {
            this.formFields.push(
              new FormAttrBase({
                key: 'debit',
                label: 'Дебит скважины',
                placeholder: 'Введите дебит скважины',
                controlType: 'select',
                visualRequired: true,
                value: obj.attributes?.debit
                  ? obj.attributes?.debit
                  : 'Дебит скважины отсутствует',
                datalistSelect: this.debits,
              }),
              new FormAttrBase({
                key: 'typeMining',
                label: 'Тип добычи',
                placeholder: 'Выберите тип добычи',
                controlType: 'select',
                visualRequired: true,
                value: obj.attributes?.type_mining
                  ? obj.attributes?.type_mining
                  : 'Тип добычи отсутствует',
                datalistSelect: this.typeMining,
              })
            );
          }
        }
        this.formAttrsObservable.next(this.formFields);
        this.data = obj;
        this.changeDetector.markForCheck();
      });
  }

  sendForm(obj: any) {
    let body: TypeTree;
    if (this.data.type === ObjectType.BOREHOLE) {
      const { typeMining, debit, parentId, ...values } = obj.value;
      body = {
        ...this.data,
        ...values,
        attributes: { type_mining: typeMining, debit },
        parent_id: parentId === '999999' ? null : parentId,
      };
    } else {
      const { parentId, ...values } = obj.value;
      body = {
        ...this.data,
        ...values,
        parent_id: parentId === '999999' ? null : parentId,
      };
    }
    this.objectService
      .updateObject(body, this.data.id)
      .subscribe((updatedObject: any) => {
        this.objectService.selectedObject.next(updatedObject.updated_object);
        this.objectService.getDataToTree().subscribe((data: any) => {
          this.objectService.updateTree.next(data);
        });
        this.notificationsService
          .show('Объект обновлен', {
            label: '',
            status: TuiNotification.Success,
            hasIcon: true,
            autoClose: true,
            hasCloseButton: true,
          })
          .subscribe();
        this.changeSetting.emit();
      });
    return;
  }

  openDialogForDelete() {
    this.isDeleteObjectDialog = true;
  }

  confirmObjectDelete() {
    this.objectService.deleteObject(this.data.id).subscribe(() => {
      this.objectService.selectedObject.next(null);
      this.objectService.getDataToTree().subscribe((data: any) => {
        this.objectService.updateTree.next(data);
      });
      this.isDeleteObjectDialog = false;
    });
  }

  cancelDeleteObject() {
    this.isDeleteObjectDialog = false;
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
