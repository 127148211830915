import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { DataTableModel, LabelTableModel } from "../../../models/table.model";
import { DeviceModel } from "../../../models/device.model";
import { BADGE_TRANSLATE } from "../../../app/const/app-consts";
import { ObjectService } from "../../../app/services/object.service";
import { FormGroup } from "@angular/forms";
import { SelectedFilterObject } from "../../../models/event.model";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, AfterViewInit {
  /**
   * editMode = show/hide toolbar
   * data = table information
   * label = list of table label
   * leftToolBar = left toolbar
   * rightToolBar = right toolbar
   * paginator = paginator
   * */

  /** data
   * @description table data column and value*/
  @Input()
  data!: Array<DataTableModel>;

  @Input()
  selectedDevices: { [key: string]: DeviceModel | {} } = {};

  /** label
   * @description column list*/
  @Input()
  label: Array<LabelTableModel> = [];

  @Input()
  leftToolBar!: TemplateRef<any>;

  @Input()
  rightToolBar!: TemplateRef<any>;

  @Input()
  editMode: boolean = false;

  // фронтовая паниницая разделяет массив на 10/25/50/100 айтемов на странице
  @Input()
  frontPagination: boolean = false;

  /** paginator
   * @description table pagination information */
  @Input()
  paginator!: {
    currentPage: number;
    countPages: number;
    countItems: number;
    listOfCountItems: Array<number>;
  };

  @Input()
  actionButtons!: Array<{ value: number; label: string }>;

  @Input()
  buttons!: any;

  @Input()
  items: any[] = [];

  @Input()
  isChangeEquipmentDialog!: boolean;

  @Input()
  showSettingBlock: boolean = false;

  @Input()
  statusItems!: {
    form: FormGroup;
    values: Array<SelectedFilterObject>;
  };

  @Input()
  statusMessagesItems!: {
    form: FormGroup;
    values: Array<SelectedFilterObject>;
  };

  @Input()
  depositNameItems!: {
    form: FormGroup;
    values: Array<SelectedFilterObject>;
  };

  @Input()
  nameItems!: {
    form: FormGroup;
  };

  @Input()
  reactionItems!: {
    form: FormGroup;
    values: Array<SelectedFilterObject>;
  };

  @ViewChild("leftBar", { read: ViewContainerRef })
  leftBar!: ViewContainerRef;

  @ViewChild("rightBar", { read: ViewContainerRef })
  rightBar!: ViewContainerRef;

  /** handleClick
   * @description return paginator settings for parent */
  @Output() handleClick: EventEmitter<{
    page: number;
    per_page: number;
  }> = new EventEmitter<{
    page: number;
    per_page: number;
  }>();

  @Output() getActionBtn: EventEmitter<{ type: string; selectedItem: any }> =
    new EventEmitter<{ type: string; selectedItem: any }>();

  @Output() getItemFromBtn: EventEmitter<string> = new EventEmitter<string>();

  @Output() handleSearchItems: EventEmitter<{ [key: string]: DeviceModel }> =
    new EventEmitter<{ [key: string]: DeviceModel }>();

  @Output() handleClickEvents: EventEmitter<string> =
    new EventEmitter<string>();

  public isSkeletonActive: boolean = true;

  private paginationData = [];

  private templateDataTable: Array<DataTableModel> = [];

  public filterModalWindows: { [key: string]: boolean } = {
    dropdownReactOpen: false,
    dropdownStatusMessagesOpen: false,
    dropdownStatusOpen: false,
    depositNameOpen: false,
    nameOpen: false,
    dropdownOpen: false,
  };

  public translateBadge: { [key: string]: string } = BADGE_TRANSLATE;

  /** EMPTY_VALUE COUNTS DECREMENT INCREMENT
   * @description constants */
  public readonly EMPTY_VALUE: string = "";

  /** changedColumns
   * @description filtered list of columns and their status (visible or hidden) */
  public changedColumns: { [key: string]: boolean } = {};

  constructor(
    private _changeDetector: ChangeDetectorRef,
    public objectService: ObjectService
  ) {}

  /** ngOnInit
   * @description reassembles an array of columns and activity (by default all that came is shown) */
  ngOnInit(): void {
    if (this.label === undefined) {
      return;
    }
    this.label.map(
      (item: {
        name: string;
        title?: string;
        isSort: boolean;
        isFilter: boolean;
      }) => {
        this.changedColumns[item.name] = true;
      }
    );
    this.templateDataTable = this.data;
    this.changeForPaginationData();
    setTimeout(() => {
      this.isSkeletonActive = false;
      this._changeDetector.markForCheck();
    }, 10);
  }

  getTitleName(columnName: string): string {
    return this.label
      ? this.label.find((i) => i.name == columnName)?.title || columnName
      : columnName;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.leftBar && this.leftToolBar) {
        this.leftBar.createEmbeddedView(this.leftToolBar);
      }
      if (this.rightBar && this.rightToolBar) {
        this.rightBar.createEmbeddedView(this.rightToolBar);
      }
    }, 0);
  }

  get tableColumnsKeys(): Array<string> {
    return Object.keys(this.changedColumns);
  }

  // проверка на костыль
  notAnyButton(cell: string): boolean {
    return cell !== "dropdownButton" && cell !== "btn" && cell !== "select";
  }

  changeCountItems(): void {
    this.indexPage = 0;
    const dataChangedPaginator = {
      page: 1,
      per_page: this.paginator.countItems,
    };
    this.changeForPaginationData(
      dataChangedPaginator.per_page,
      dataChangedPaginator.page
    );
    this.handleClick.emit(dataChangedPaginator);
  }

  // разделяет массив на n части return typeof Array<{ [key: number]: { [key: string]: string | number } }>
  private splitTo(
    data: Array<{ [key: string]: string | number }>,
    count: number
  ) {
    let plen = Math.ceil(data.length / count);
    return data.reduce((p: any, c: any, i: any) => {
      if (i % plen === 0) {
        p.push({});
      }
      p[p.length - 1][i] = c;
      return p;
    }, []);
  }

  goToPage(numberPage: number) {
    this.indexPage = numberPage;
    const dataChangedPaginator = {
      page: numberPage + 1,
      per_page: this.paginator.countItems,
    };
    this.changeForPaginationData(
      dataChangedPaginator.per_page,
      dataChangedPaginator.page
    );
    this.handleClick.emit(dataChangedPaginator);
  }

  public indexPage: number = 0;

  selectAction(button: string, row: any): void {
    this.getActionBtn.emit({ type: button, selectedItem: row });
  }

  selectOption(selectedDevices: any) {
    this.handleSearchItems.emit(selectedDevices);
  }

  private changeForPaginationData(
    countItems: number = 10,
    currentPage: number = 1
  ): void {
    if (this.frontPagination) {
      this.paginator = {
        currentPage: currentPage,
        countItems: countItems,
        countPages: Math.floor(this.templateDataTable.length / countItems),
        listOfCountItems: [10, 25, 50, 100],
      };
      this.paginationData = this.splitTo(
        this.templateDataTable,
        this.paginator.countPages
      );
      this.data = Object.values(
        this.paginationData[this.paginator.currentPage - 1]
      );
      this._changeDetector.markForCheck();
    }
  }

  eventHandleChange(key: string) {
    this.handleClickEvents.emit(key);
  }
}
