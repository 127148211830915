<div class="normal-layout">
  <div class="normal-layout__container">
    <div class="normal-layout__container-cell" [class._isEdit]="isEditMode">
      <ng-container #content></ng-container>
      <app-plus *ngIf="isEditMode"></app-plus>
    </div>
    <ng-content></ng-content>
    <div class="normal-layout__container-cell" [class._isEdit]="isEditMode">
      <ng-container #secondContent></ng-container>
      <app-plus *ngIf="isEditMode"></app-plus>
    </div>
  </div>
</div>
