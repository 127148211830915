import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TuiAlertService } from '@taiga-ui/core';
import { ObjectService } from '../../../../../../services/object.service';
import { TypeTree } from 'src/models/tree.model';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import * as memoizee from 'memoizee';

function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control.value?.trim() || '';
    if ((control?.value?.length || '') !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

@Component({
  selector: 'app-common-info-oilfield',
  templateUrl: './common-info-oilfield.component.html',
  styleUrls: ['./common-info-oilfield.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class CommonInfoOilfieldComponent implements OnInit {
  public label: any[] = [
    {
      name: 'name',
      title: 'Название',
      isSort: true,
      isFilter: true,
    },
    {
      name: 'value',
      title: 'Значение',
      isSort: true,
      isFilter: true,
    },
  ];

  public data: any = null;

  public editObjectMode: boolean = false;

  @Input()
  public contextObject: any = null;

  @Output() handleChange: EventEmitter<boolean> = new EventEmitter();

  //TODO: add validation required for lan and lon
  public objectInfoForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
  });

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    public objectService: ObjectService
  ) {}

  ngOnInit() {
    this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
  }

  save() {
    if (!this.objectInfoForm.valid) {
      return this.objectInfoForm.markAllAsTouched();
    }
    /*
    if (
      this.objectInfoForm.get('name').value.trim() === this.contextObject?.name
    ) {
      this.editObjectMode = !this.editObjectMode;
      return;
    }*/
    if (this.objectInfoForm.dirty) {
      let body: TypeTree = {
        ...this.contextObject,
        name: this.objectInfoForm.get('name').value,
      };
      this.objectService
        .updateObject(body, this.contextObject.id)
        .subscribe(() => {
          this.objectService
            .getObjectByID(this.contextObject.id)
            .subscribe(() => {
              this.objectService
                .getDepositInfoByID(this.contextObject.id)
                .subscribe((deposit: any) => {
                  this.contextObject = deposit;
                  this.handleChange.emit(true);
                  this.editObjectMode = !this.editObjectMode;
                  this.notificationsService
                    .open('Объект обновлен', {
                      label: '',
                      status: TuiNotification.Success,
                      hasIcon: true,
                      autoClose: true,
                      hasCloseButton: true,
                    })
                    .subscribe();
                });
            });
        });
    }
    /*    else {
      this.notificationsService
        .open('Добавьте хотя бы один символ кроме пробелов', {
          label: '',
          status: TuiNotification.Warning,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
    }*/
  }

  changeInput(): void {
    this.editObjectMode = !this.editObjectMode;
  }

  cancelInput(): void {
    this.editObjectMode = false;
    this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
  }

  public memoizedChildrenCoordsAreSet = memoizee((children: any[]): boolean => {
    return children.some((child: any) => {
      return child.latitude !== null && child.longitude !== null;
    });
  });

  public memoizedIsAvailableChildrenCoords = memoizee(
    (children: any[]): boolean => {
      return children.some((child: any) => {
        return (
          child.latitude < 90 &&
          child.latitude > -90 &&
          child.longitude < 180 &&
          child.longitude > -180
        );
      });
    }
  );
}
