<ng-container *ngFor="let button of buttons">
  <!--  <div class="tui-space_top-3">-->
  <button
    type="button"
    class="button"
    [class._red]="button?.color"
    (click)="selectAction(button.label)"
  >
    {{ button.label }}
  </button>
  <!--  </div>-->
</ng-container>
