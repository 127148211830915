import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DesignService } from '../services/design.service';
import { Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[appCellColor]',
})
export class CellDirective implements OnInit, OnDestroy {
  @Input() status: string = 'no_connection';

  @Input() typeMining: string = 'NONE';

  /*тип добычи*/
  private readonly PPD: string = 'PPD';

  private readonly SHGN: string = 'SHGN';

  private readonly SHVN: string = 'SHVN';

  private readonly ECN: string = 'ECN';

  private readonly NONE: string = 'NONE';

  private readonly REPAIR: string = 'REPAIR';

  public destroyer: Subject<null> = new Subject();

  constructor(private el: ElementRef, private designService: DesignService) {}

  ngOnInit() {
    this.setStyles();
    this.designService.updateColors
      .pipe(takeUntil(this.destroyer))
      .subscribe(() => {
        this.setStyles();
      });
  }

  statusColor(status: string) {
    switch (status) {
      case 'ok':
        return 'status_ok';
      case 'no_connection':
        return 'status_no_connection';
      case 'stop':
        return 'status_stop';
      default:
        return 'status_no_connection';
    }
  }

  typeMiningColor(type: string) {
    switch (type) {
      case this.PPD:
        return 'borehole_type_ppd';
      case this.SHGN:
        return 'borehole_type_shgn';

      case this.SHVN:
        return 'borehole_type_shvn';

      case this.ECN:
        return 'borehole_type_ecn';

      case this.NONE:
        return 'borehole_type_no_type_mining';

      case this.REPAIR:
        return 'borehole_type_repair';
      default:
        return 'borehole_type_no_type_mining';
    }
  }

  setStyles() {
    this.el.nativeElement.classList.add(this.typeMiningColor(this.typeMining));
    this.el.nativeElement.classList.add(this.statusColor(this.status));
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
