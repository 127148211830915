<tui-loader [overlay]="true" [showLoader]="!visibleMap">
  <div
    leaflet
    leafletDraw
    [leafletOptions]="options"
    (leafletClick)="handleClickOnMap($event, 'map')"
    (leafletMapReady)="onMapReady($event)"
    [leafletDrawOptions]="drawOptions"
    id="map"
  ></div>
</tui-loader>
