<div class="oilfield-add">
  <app-primary-section>
    <div class="oilfield-add__container">
      <h2 class="title">Добавить нефтепровод</h2>
      <div class="cross" (click)="closeModal()">
        <img src="/assets/cross.png" alt="(X)" />
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="oilPipelineForm">
      <div class="form__title">
        <h4>Общая информация</h4>
      </div>
      <div class="form__field">
        <span>Название *</span>
        <div class="input">
          <tui-input [tuiTextfieldMaxLength]="40" formControlName="name">
            <span class="input__placeholder">Укажите название</span>
          </tui-input>
          <tui-field-error formControlName="name"></tui-field-error>
        </div>
      </div>
      <div class="form__field">
        <span>Протяженность, м</span>
        <div class="input">
          <tui-input-number tuiTextfieldSize="m" formControlName="length">
            Укажите протяженность
          </tui-input-number>
          <tui-field-error formControlName="length"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Диаметр, мм</span>
        <div class="input">
          <tui-input-number tuiTextfieldSize="m" formControlName="diameter">
            Укажите диаметр
          </tui-input-number>
          <tui-field-error formControlName="diameter"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Толщина стенки, мм</span>
        <div class="input">
          <tui-input-number
            tuiTextfieldSize="m"
            formControlName="wall_thickness"
          >
            Укажите толщину стенки
          </tui-input-number>
          <tui-field-error formControlName="wall_thickness"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Материал</span>
        <div class="input">
          <tui-input formControlName="material" tuiTextfieldSize="m">
            Укажите материал
            <input maxlength="40" tuiTextfield type="text" />
          </tui-input>
          <tui-field-error formControlName="material"></tui-field-error>
        </div>
      </div>

      <!-- <div class="form__field">
        <span>Наличие антикоррозийного покрытия</span>
        <tui-toggle
          formControlName="is_anticorrosion"
          size="l"
          class="tui-space_left-3"
        ></tui-toggle>
      </div> -->

      <div class="form__field">
        <span>Наличие антикоррозийного покрытия</span>
        <div class="input">
          <tui-select
            formControlName="is_anticorrosion"
            [valueContent]="typeValueContent"
          >
            <span>Укажите наличие антикоррозийного покрытия</span>
            <tui-data-list-wrapper
              *tuiDataList
              [items]="pipelinesAnticorrosiveOptions"
              [itemContent]="valueContent"
            >
            </tui-data-list-wrapper>
          </tui-select>
          <ng-template #typeValueContent let-data>
            {{ data.value }}
          </ng-template>
          <ng-template #valueContent let-data>
            {{ data.value }}
          </ng-template>
          <tui-field-error formControlName="is_anticorrosion"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Метод производства</span>
        <div class="input">
          <tui-input formControlName="production_method" tuiTextfieldSize="m">
            Укажите метод производства
            <input maxlength="40" tuiTextfield type="text" />
          </tui-input>
          <tui-field-error
            formControlName="production_method"
          ></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Год производства</span>
        <div class="input">
          <tui-select formControlName="production_year" tuiTextfieldSize="m">
            Укажите год производства
            <tui-data-list-wrapper *tuiDataList [items]="productionYearList">
            </tui-data-list-wrapper>
          </tui-select>
          <tui-field-error formControlName="production_year"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Номинальное давление, МПа</span>
        <div class="input">
          <tui-input-number
            tuiTextfieldSize="m"
            formControlName="nominal_pressure"
          >
            Введите номинальное давление
          </tui-input-number>
          <tui-field-error formControlName="nominal_pressure"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Рабочее давление, МПа</span>
        <div class="input">
          <tui-input-number
            tuiTextfieldSize="m"
            formControlName="working_pressure"
          >
            Укажите рабочее давление
          </tui-input-number>
          <tui-field-error formControlName="working_pressure"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Дата последней ЭПБ</span>
        <div class="input">
          <tui-input-date formControlName="last_EPB">
            Укажите дату последней ЭПБ
          </tui-input-date>
          <tui-field-error formControlName="last_EPB"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Дата следующей ЭПБ</span>
        <div class="input">
          <tui-input-date formControlName="next_EPB">
            Укажите дату следующей ЭПБ
          </tui-input-date>
          <tui-field-error formControlName="next_EPB"></tui-field-error>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="oilPipelineForm">
      <div class="form__title">
        <h4>Координаты</h4>
      </div>
      <div class="oilfield-add__map">
        <app-pipeline-map
          [pipelines]="oilPipeline"
          [isEdit]="isEdit"
          (handleDraw)="getCoords($event)"
        ></app-pipeline-map>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="footer">
      <span>* Обязательные для заполнения поля</span>
      <div class="footer__buttons">
        <button (click)="submitForm()" class="footer-button _add">
          Добавить
        </button>
        <button (click)="closeDialog()" class="footer-button _cancel">
          Отменить
        </button>
      </div>
    </div>
  </app-primary-section>
</div>
