import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  TuiContextWithImplicit,
  tuiPure,
  TUI_DATE_FORMAT,
  TUI_DATE_SEPARATOR,
} from '@taiga-ui/cdk';
import { TuiAlertService } from '@taiga-ui/core';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { PowerLinesService } from 'src/app/services/power-lines.service';
import { TypePowerLineListModel } from 'src/models/power-line.model';
import {
  maxValueValidator,
  minValueValidator,
} from '../../../dns-card/components/common-info-dns/common-info-dns.component';
import { PipelineColorType } from './../../../../../../models/pipeline.model';

function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control.value?.trim() || '';
    if ((control?.value?.length || '') !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

@Component({
  selector: 'app-common-info-power-line',
  templateUrl: './common-info-power-line.component.html',
  styleUrls: ['./common-info-power-line.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
    { provide: TUI_DATE_FORMAT, useValue: `DMY` },
    { provide: TUI_DATE_SEPARATOR, useValue: `.` },
  ],
})
export class CommonInfoPowerLineComponent implements OnInit {
  public label: any[] = [
    {
      name: 'name',
      title: 'Название',
      isSort: true,
      isFilter: true,
    },
    {
      name: 'value',
      title: 'Значение',
      isSort: true,
      isFilter: true,
    },
  ];

  public data: any = null;

  public coords: { latitude: number; longitude: number }[] = [];

  public editObjectMode: boolean = false;

  public visibleMap: boolean = true;

  public parent: any | null = null;

  public onClickOnMap = new EventEmitter();

  public latitude: number = 0;

  public longitude: number = 0;

  @Input()
  public contextObject: any = null;

  @Output() handleChange: EventEmitter<any> = new EventEmitter();

  public objectInfoForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    purpose: new FormControl('', []),
    laying_method: new FormControl('', []),
    voltage: new FormControl('', []),
    current_type: new FormControl('', []),
    connection_point: new FormControl('', []),
  });

  //для формы -> список скважин
  //public depositList: any = [];

  public productionYearList: any = [];

  public electricityPipeline: {
    line: { latitude: number; longitude: number }[];
    type: PipelineColorType.ELECTRICITY;
  }[] = [];

  // Массив опций для выпадающего списка поля "Род тока"
  public currentType: any[] | undefined;

  // Массив опций для выпадающего списка поля "Способ прокладки"
  public layingType: any[] | undefined;

  private deletedCoords: boolean = false;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    public powerLinesService: PowerLinesService
  ) {}

  async ngOnInit() {
    // Получаем список для выпадающего списка поля "Род тока"
    this.currentType = this.powerLinesService.currentType;

    // Получаем список для выпадающего списка поля "Способ прокладки"
    this.layingType = this.powerLinesService.layingType;

    if (this.contextObject) {
      this.electricityPipeline = [
        {
          line: this.contextObject.coordinates[0].line,
          type: PipelineColorType.ELECTRICITY,
        },
      ];
      this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
      this.objectInfoForm
        .get('purpose')
        ?.setValue(this.contextObject?.purpose || '');
      this.objectInfoForm
        .get('laying_method')
        ?.setValue(this.contextObject?.laying_method || '');
      this.objectInfoForm
        .get('voltage')
        ?.setValue(this.contextObject?.voltage || '');
      this.objectInfoForm
        .get('current_type')
        ?.setValue(this.contextObject?.current_type || '');
      this.objectInfoForm
        .get('connection_point')
        ?.setValue(this.contextObject?.connection_point || '');
    }
  }

  getCoords(coords: { latitude: number; longitude: number }[]) {
    this.coords = coords;
    if (!coords.length) {
      this.deletedCoords = true;
    } else {
      this.deletedCoords = false;
    }
  }

  isAvailableCoords(): boolean {
    const everyCoordIsAvailable = this.contextObject.coordinates[0].line.every(
      (coords: { latitude: number; longitude: number }) => {
        return (
          coords.latitude < 90 &&
          coords.latitude > -90 &&
          coords.longitude < 180 &&
          coords.longitude > -180
        );
      }
    );
    return everyCoordIsAvailable;
  }

  save() {
    if (!this.objectInfoForm.valid) {
      return this.objectInfoForm.markAllAsTouched();
    }
    if (
      this.deletedCoords ||
      (!this.coords.length && !this.contextObject?.coordinates[0].line)
    ) {
      this.notificationsService
        .open('Обозначьте ЛЭП на карте', {
          label: '',
          status: TuiNotification.Error,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      return;
    }
    if (this.objectInfoForm.dirty || this.coords.length) {
      let body: TypePowerLineListModel = {
        ...this.contextObject,
        name: this.objectInfoForm.get('name').value,
        purpose: this.objectInfoForm.get('purpose')?.value || null,
        laying_method: this.objectInfoForm.get('laying_method').value,
        voltage: this.objectInfoForm.get('voltage')?.value || null,
        current_type: this.objectInfoForm.get('current_type')?.value || null,
        connection_point:
          this.objectInfoForm.get('connection_point')?.value || null,

        coordinates: [
          {
            line: this.coords.length
              ? this.coords
              : this.contextObject?.coordinates[0].line,
          },
        ],
      };
      this.powerLinesService
        .updatePowerLine(this.contextObject.id, body)
        .subscribe((waterPipeline: any) => {
          this.contextObject = {
            ...waterPipeline,
          };
          this.notificationsService
            .open('Объект обновлен', {
              label: '',
              status: TuiNotification.Success,
              hasIcon: true,
              autoClose: true,
              hasCloseButton: true,
            })
            .subscribe();
          this.handleChange.emit();
          this.editObjectMode = false;
        });
    } else {
      this.editObjectMode = false;
    }
  }

  changeInput(): void {
    this.editObjectMode = !this.editObjectMode;
  }

  cancelInput(): void {
    this.objectInfoForm.reset();
    this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
    this.objectInfoForm
      .get('purpose')
      ?.setValue(this.contextObject?.purpose || '');
    this.objectInfoForm
      .get('laying_method')
      ?.setValue(this.contextObject?.laying_method || '');
    this.objectInfoForm
      .get('voltage')
      ?.setValue(this.contextObject?.voltage || '');
    this.objectInfoForm
      .get('current_type')
      ?.setValue(this.contextObject?.current_type || '');
    this.objectInfoForm
      .get('connection_point')
      ?.setValue(this.contextObject?.connection_point || '');

    this.coords = this.contextObject?.coordinates;
    this.editObjectMode = false;
  }

  @tuiPure
  stringify(items: readonly any[]): any {
    const map = new Map(
      items.map(({ id, name }) => [id, name] as [number, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }
}
