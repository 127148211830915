import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TuiContextWithImplicit, tuiPure } from '@taiga-ui/cdk';
import {
  AVAILABLE_DEBIT_FIELDS,
  AVAILABLE_FREQ_FIELDS,
  BADGE_TRANSLATE,
  OBJECTS_DEBIT,
  TYPE_MINING_VALUE,
} from '../../../../const/app-consts';
import { ObjectService } from '../../../../services/object.service';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { forkJoin, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { DirectoriesService } from '../../../../services/directories.service';
import { PressureService } from '../../../../services/pressure.service';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import * as memoizee from 'memoizee';
import { PipelineService } from '../../../../services/pipeline.service';
import { BoreholeService } from '../../../../services/borehole.service';
import { DataForSelectField } from 'src/models/controls.model';
import { ActualValueType } from '../../../../../models/pressure-meter.model';

//проверка на пробелы (если есть в начале или в конце тоби пипец)
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

export function minValueValidator(context: { min: number }): string {
  return `Значение должно быть не меньше ${context.min}`;
}

export function maxValueValidator(context: { max: number }): string {
  return `Значение должно быть не больше ${context.max}`;
}

@Component({
  selector: 'app-borehole-card-info',
  templateUrl: './borehole-card-info.component.html',
  styleUrls: ['./borehole-card-info.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class BoreholeCardInfoComponent implements OnInit, OnDestroy {
  @Input() data: any;

  @Input() parentsList: DataForSelectField[] = [];

  @Output() handleSubmit: EventEmitter<string> = new EventEmitter();

  public waterPipelines: DataForSelectField[] = [];

  public oilPipelines: DataForSelectField[] = [];

  public availableStatusDevices: DataForSelectField[] = [];

  public availablePressures: DataForSelectField[] = [];

  public availableBushes: DataForSelectField[] = [];

  public filterAvailableBushes: DataForSelectField[] = [];

  public listOfOil: any = {
    gzuList: [],
    dnsList: [],
    bgList: [],
  };

  public destroyer: Subject<null> = new Subject();

  public availableDns: DataForSelectField[] = [];

  public availableGzu: DataForSelectField[] = [];

  public availableBg: DataForSelectField[] = [];

  public isEdit = false;

  //для status_device_id, freq_device_id, debit_device_id устанавливаются
  // значения только при редактировании, чтобы лишние запросы не слать на
  // получение немалого кол-ва данных
  public infoFormGroup: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(10),
      checkForFirstSpaceCharacter(),
    ]),
    parent_id: new FormControl('', [Validators.required]),
    bush_parent_id: new FormControl(null),
    oil_parent_id: new FormControl(null),
    latitude: new FormControl(null, [
      Validators.required,
      Validators.min(-90),
      Validators.max(90),
    ]),
    longitude: new FormControl(null, [
      Validators.required,
      Validators.min(-180),
      Validators.max(180),
    ]),
    type_mining: new FormControl('', [Validators.required]),
    debit: new FormControl(null, []),
    water_cut: new FormControl(null, []),
    status_device_id: new FormControl('', []),
    freq_device_id: new FormControl('', []),
    freq_device_field: new FormControl(
      {
        value: null,
        disabled: true,
      },
      []
    ),
    debits_params_devices: new FormArray([]),
    curr_pressure: new FormControl(null, []),
    next_pressure: new FormControl(null, []),
    oil_pipe: new FormControl(null, []),
    water_pipe: new FormControl(null, []),
    debit_calculate_option: new FormControl(null, []),
  });

  initFormControlsArray() {
    const formGroup = new FormGroup({
      debit_device_id: new FormControl(null, []),
      debit_device_field: new FormControl(null, []),
    });

    formGroup.get('debit_device_field')?.disable();
    formGroup
      .get('debit_device_id')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (!item) {
          formGroup.get('debit_device_field')?.disable();
          return;
        }
        formGroup.get('debit_device_field')?.enable();
        formGroup.get('debit_device_field')?.setValidators(Validators.required);
      });
    setTimeout(() => {
      this.debitDeviceSearch$.next('');
    });
    return formGroup;
  }

  public memoizedGetSections = memoizee((form: any): any => {
    return form.controls.debits_params_devices.controls;
  });

  add() {
    const control = <FormArray>this.infoFormGroup.get('debits_params_devices');
    control.push(this.initFormControlsArray());
  }

  remove(i: number) {
    const control = <FormArray>this.infoFormGroup.get('debits_params_devices');
    control.removeAt(i);
  }

  public availableTypeMining = TYPE_MINING_VALUE.map((v: any) => ({
    id: v.value,
    name: v.title,
  }));

  public availableFreqFields = AVAILABLE_FREQ_FIELDS.map((v: any) => ({
    id: v.value,
    name: v.title,
  }));

  public availableDebitFields = AVAILABLE_DEBIT_FIELDS.map((v: any) => ({
    id: v.value,
    name: v.title,
  }));

  public onClickOnMap = new EventEmitter();

  readonly statusDeviceSearch$: Subject<string | null> = new Subject();

  readonly pressureDeviceSearch$: Subject<string | null> = new Subject();

  readonly frequencyDeviceSearch$: Subject<string | null> = new Subject();

  readonly debitDeviceSearch$: Subject<string | null> = new Subject();

  readonly search$: Subject<string | null> = new Subject();

  readonly availableDebitDevices$: Observable<readonly any[] | null> =
    this.debitDeviceSearch$.pipe(
      switchMap((search: string | null) =>
        this.filterDebitDevices(search).pipe(
          startWith<readonly any[] | null>(null)
        )
      )
    );

  public skeletonVisible = false;

  public statusTitles: any = {
    ok: BADGE_TRANSLATE.ok,
    stop: BADGE_TRANSLATE.stop,
    no_connection: BADGE_TRANSLATE.no_connection,
  };

  public visibleMap: boolean = false;

  public latitude: number = 0;

  public longitude: number = 0;

  public disabledOilField: boolean = false;

  public disabledBushField: boolean = false;

  public debitList = OBJECTS_DEBIT;

  public iconBetweenDebits: string = 'tuiIconPlus';

  public currentPressureValue: string = '';

  public nextPressureValue: string = '';

  public debitSensorErrorMessage: string | null = null;

  constructor(
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
    private objectService: ObjectService,
    public directoriesService: DirectoriesService,
    private pressureService: PressureService,
    private pipelineService: PipelineService,
    private boreholeService: BoreholeService
  ) {}

  async ngOnInit() {
    if (this.data) {
      this.boreholeService.borehole
        .pipe(takeUntil(this.destroyer))
        .subscribe((borehole: string[]) => {
          this.data = { ...borehole };
        });
      this.latitude = this.data.latitude;
      this.longitude = this.data.longitude;
      this.infoFormGroup
        .get('latitude')
        ?.setValue(
          typeof this.data.latitude === 'number' &&
            typeof this.data.longitude === 'number'
            ? this.data.latitude
            : null
        );
      this.infoFormGroup
        .get('longitude')
        ?.setValue(
          typeof this.data.longitude === 'number' &&
            typeof this.data.latitude === 'number'
            ? this.data.longitude
            : null
        );
      this.onClickOnMap
        .pipe(takeUntil(this.destroyer))
        .subscribe((coordinates) => {
          this.infoFormGroup.get('latitude')?.setValue(coordinates.lat);
          this.infoFormGroup.get('longitude')?.setValue(coordinates.lng);
        });
      this.infoFormGroup
        .get('latitude')
        .valueChanges.pipe(takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (this.infoFormGroup.get('latitude')?.valid) {
            this.latitude = item;
            return;
          }
          return;
        });
      this.infoFormGroup
        .get('longitude')
        .valueChanges.pipe(takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (this.infoFormGroup.get('longitude')?.valid) {
            this.longitude = item;
            return;
          }
          return;
        });
      this.infoFormGroup
        .get('freq_device_id')
        ?.valueChanges.pipe(takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (!item) {
            return;
          }
          this.infoFormGroup.get('freq_device_field')?.enable();
          this.infoFormGroup.get('freq_device_field')?.setValue('frequency');
        });
      this.infoFormGroup
        .get('parent_id')
        .valueChanges.pipe(takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (
            !item ||
            (!this.availableBushes.length &&
              !this.availableGzu.length &&
              !this.availableDns.length &&
              !this.availableBg.length)
          ) {
            return;
          }
          this.infoFormGroup.get('bush_parent_id')?.setValue(null);
          this.infoFormGroup.get('oil_parent_id')?.setValue(null);
          this.filterOilCollection(item);
        });
      this.visibleMap = true;
    }

    await this.updatePressureSensorValues();

    this.startDebitDeviceErrorMonitoring();
  }

  startDebitDeviceErrorMonitoring() {
    this.infoFormGroup
      .get('debits_params_devices')
      .valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe(() => {
        this.checkDebitDeviceError();
      });
  }

  checkDebitDeviceError() {
    const form = this.infoFormGroup.get('debits_params_devices');

    if (
      form.controls[0].get('debit_device_id').value &&
      !form.controls[0].get('debit_device_field').value
    ) {
      this.debitSensorErrorMessage =
        'Поле "Параметр устройства" обязательно для каждого датчика дебита';
      return;
    }

    if (
      !form.controls[0].get('debit_device_id').value &&
      form.controls[0].get('debit_device_field').value
    ) {
      this.debitSensorErrorMessage = 'Укажите датчик дебита';
      return;
    }

    if (form.controls[1]) {
      if (
        form.controls[1].get('debit_device_id').value &&
        !form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Поле "Параметр устройства" обязательно для каждого датчика дебита';
        return;
      }

      if (
        !form.controls[1].get('debit_device_id').value &&
        form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage = 'Укажите датчик дебита';
        return;
      }

      if (
        !form.controls[0].get('debit_device_id').value ||
        !form.controls[0].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Поля первого датчика дебита заполнены неправильно';
        return;
      }

      if (
        form.controls[0].get('debit_device_id').value ===
          form.controls[1].get('debit_device_id').value &&
        form.controls[0].get('debit_device_field').value ===
          form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Датчики должны различаться по названию и/или параметрам';
        return;
      }
    }

    this.debitSensorErrorMessage = null;
  }

  async updatePressureSensorValues() {
    this.currentPressureValue = await this.getPressureSensorValue(
      this.data?.curr_pressure?.id
    );
    this.nextPressureValue = await this.getPressureSensorValue(
      this.data?.next_pressure?.id
    );
  }

  async getPressureSensorValue(pressureId: number | null) {
    if (!pressureId) {
      return 'Датчик не выбран';
    }
    const pressureValueList: ActualValueType[] = await this.pressureService
      .values;
    const rightPressure = pressureValueList.find(
      (item) => item.pressure_id === pressureId
    );
    return rightPressure?.value
      ? rightPressure?.value + ' МПа'
      : 'Значение недоступно';
  }

  getDebit(debit: number | string | null, field: string[] | null) {
    if (debit && this.debitList.hasOwnProperty(debit)) {
      if (field?.length) {
        return (
          `${this.debitList[debit].toFixed(2)}` +
          ' ' +
          `${field.find((v: string) => ['M1', 'M2'].includes(v)) ? 'т' : 'м3'}`
        );
      } else return this.debitList[debit];
    } else if (debit && !this.debitList.hasOwnProperty(debit)) {
      if (field?.length && typeof debit == 'number') {
        return (
          `${debit.toFixed(2)}` +
          ' ' +
          `${field.find((v: string) => ['M1', 'M2'].includes(v)) ? 'т' : 'м3'}`
        );
      } else return debit;
    }
    return '-';
  }

  getFreq = memoizee((item: any): string => {
    const frequencyFieldName = item.freq_device_field;
    if (typeof frequencyFieldName === 'string') {
      if (item.freq_device?.last_message?.hasOwnProperty(frequencyFieldName)) {
        const value = item.freq_device.last_message[frequencyFieldName];
        return value.toFixed(2) + ' Гц';
      }
    }
    return '-';
  });

  getTypeMining(typeMining: any) {
    return (
      TYPE_MINING_VALUE.find((v: any) => v.value === typeMining)?.title || '-'
    );
  }

  checkStatusFreg() {
    const freqDeviceId = this.infoFormGroup.get('freq_device_id')?.value;
    const freqDeviceField = this.infoFormGroup.get('freq_device_field')?.value;
    if (freqDeviceId) {
      return !AVAILABLE_FREQ_FIELDS.some(
        (f: any) => f.value === freqDeviceField
      );
    }
    return false;
  }

  onEdit() {
    this.isEdit = !this.isEdit;
    if (this.data && this.isEdit) {
      this.skeletonVisible = true;
      this.patchForm().then(() => {
        this.skeletonVisible = false;
      });
    }

    if (this.data && !this.isEdit) {
      this.resetForm();
    }
  }

  patchForm() {
    return new Promise<void>((resolve) => {
      if (
        !this.availableBushes.length &&
        !this.availableGzu.length &&
        !this.availableDns.length &&
        !this.availableBg.length
      ) {
        this.oilCollection();
      }
      if (!this.availableStatusDevices.length) {
        forkJoin({
          pressures: this.pressureService.pressure,
          devices: this.objectService.getDeviceList(),
          oilPipelines: this.pipelineService.oilPipelinesList,
          waterPipelines: this.pipelineService.oilPipelinesList,
        }).subscribe((result: any) => {
          const { pressures, devices, oilPipelines, waterPipelines } = result;
          this.availablePressures = this.prepareArrayForSelectField(pressures);
          this.oilPipelines = this.prepareArrayForSelectField(oilPipelines);
          this.waterPipelines = this.prepareArrayForSelectField(waterPipelines);
          this.pressureDeviceSearch$.next('');
          this.availableStatusDevices =
            this.prepareArrayForSelectField(devices);
          this.statusDeviceSearch$.next('');
          this.frequencyDeviceSearch$.next('');
          this.debitDeviceSearch$.next('');

          this.infoFormGroup.patchValue({
            status_device_id:
              devices.find((d: any) => d.id === this.data.status_device_id) ||
              null,
          });
          if (
            this.data.debit_device_id.length &&
            this.data.debit_device_field.length
          ) {
            //здесь тоже можно обнулить массив formArray, но сюда мы заходим
            // в первый раз, когда нажимаем на редактирование и больше не должны
            this.data.debit_device_id.forEach((device: any, index: any) => {
              const deviceO = devices.find((d: any) => d.id === device);
              const field = this.data.debit_device_field[index];
              //ниже if даже не нужен, если мы сюда один раз заходим
              if (
                !this.infoFormGroup.controls.debits_params_devices.length ||
                this.infoFormGroup.controls.debits_params_devices.length !==
                  this.data.debit_device_id.length
              ) {
                this.infoFormGroup.controls.debits_params_devices.controls.push(
                  new FormGroup({
                    debit_device_id: new FormControl(deviceO, []),
                    debit_device_field: new FormControl(field, []),
                  })
                );
              }
            });
          } else {
            // ниже if тоже не нужен по идее, если мы один раз заходим
            if (!this.infoFormGroup.controls.debits_params_devices.length) {
              this.infoFormGroup.controls.debits_params_devices.push(
                this.initFormControlsArray()
              );
            }
          }
          this.infoFormGroup.patchValue({
            freq_device_id:
              this.availableStatusDevices.find(
                (d: any) => d.id === this.data.freq_device_id
              ) || null,
          });
          this.infoFormGroup.patchValue({
            name: this.data.name,
            parent_id: this.data.parent_id,
            type_mining: this.data.attributes?.type_mining,
            latitude: this.data.latitude || null,
            longitude: this.data.longitude || null,
            debit: Number.isInteger(parseInt(this.data.attributes?.debit))
              ? this.data.attributes?.debit
              : undefined,
            water_cut: this.data.attributes?.water_cut,
            curr_pressure: this.data.curr_pressure || null,
            next_pressure: this.data.next_pressure || null,
            oil_pipe:
              this.findObjectByIdFromArray(
                this.data.oil_pipe_id,
                this.oilPipelines
              ) || null,
            water_pipe:
              this.findObjectByIdFromArray(
                this.data.water_pipe_id,
                this.waterPipelines
              ) || null,
            debit_calculate_option: this.data.debit_calculate_option,
            freq_device_field: this.data.freq_device_field,
            bush_parent_id: this.data.bush_parent?.id || null,
            oil_parent_id: this.data.oil_parent?.id || null,
          });
          resolve();
          setTimeout(() => {
            this.pressureDeviceSearch$.next('');
            this.statusDeviceSearch$.next('');
            this.frequencyDeviceSearch$.next('');
            this.debitDeviceSearch$.next('');
          });
        });
      } else {
        this.infoFormGroup.patchValue({
          status_device_id:
            this.availableStatusDevices.find(
              (d: any) => d.id === this.data.status_device_id
            ) || null,
        });

        if (
          this.data.debit_device_id.length &&
          this.data.debit_device_field.length &&
          (!this.infoFormGroup.controls.debits_params_devices.length ||
            this.infoFormGroup.controls.debits_params_devices.length !==
              this.data.debit_device_id.length ||
            this.infoFormGroup.controls.debits_params_devices.length !==
              this.data.debit_device_field.length ||
            !this.infoFormGroup
              .get('debits_params_devices')
              .controls.some(
                (c: any) =>
                  this.data.debit_device_id.includes(
                    c.controls.debit_device_id.value.id
                  ) &&
                  this.data.debit_device_field.includes(
                    c.controls.debit_device_field.value
                  )
              ))
        ) {
          //выше условия, объединенные в одно условие должны бы false, сюда не
          // должно заходить, тк мы при отмене обнуляем массив formArray, а при сохранении
          //у нас должно быть одинаково в formArray и в параметрах скважины
          this.infoFormGroup.controls.debits_params_devices.controls.length = 0;
          this.data.debit_device_id.forEach((device: any, index: any) => {
            const deviceO = this.availableStatusDevices.find(
              (d: any) => d.id === device
            );
            const field = this.data.debit_device_field[index];
            this.infoFormGroup.controls.debits_params_devices.controls.push(
              new FormGroup({
                debit_device_id: new FormControl(deviceO, []),
                debit_device_field: new FormControl(field, []),
              })
            );
          });
        }
        resolve();
        setTimeout(() => {
          this.pressureDeviceSearch$.next('');
          this.statusDeviceSearch$.next('');
          this.frequencyDeviceSearch$.next('');
          this.debitDeviceSearch$.next('');
        });
      }
    });
  }

  resetForm() {
    // this.skeletonVisible = true;
    this.infoFormGroup.reset();
    this.visibleMap = false;
    this.infoFormGroup.patchValue({
      name: this.data.name,
      parent_id: this.data.parent_id || null,
      type_mining: this.data.attributes?.type_mining,
      latitude: this.data.latitude || null,
      longitude: this.data.longitude || null,
      debit: Number.isInteger(parseInt(this.data.attributes?.debit))
        ? this.data.attributes?.debit
        : undefined,
      water_cut: this.data.attributes?.water_cut,
      curr_pressure_id: this.data.curr_pressure_id || null,
      next_pressure_id: this.data.next_pressure_id || null,
      oil_pipe_id: this.data.oil_pipe_id || null,
      water_pipe_id: this.data.water_pipe_id || null,
      debit_calculate_option: this.data.debit_calculate_option,
      freq_device_field: this.data.freq_device_field,
      bush_parent_id: this.data.bush_parent?.id || null,
      oil_parent_id: this.data.oil_parent?.id || null,
    });

    if (this.availableStatusDevices.length) {
      this.infoFormGroup.patchValue({
        status_device_id:
          this.availableStatusDevices.find(
            (d: any) => d.id === this.data.status_device_id
          ) || null,
      });
      this.infoFormGroup.patchValue({
        freq_device_id:
          this.availableStatusDevices.find(
            (d: any) => d.id === this.data.freq_device_id
          ) || null,
      });

      if (
        this.data.debit_device_id.length &&
        this.data.debit_device_field.length
      ) {
        if (this.infoFormGroup.controls.debits_params_devices.controls.length) {
          this.infoFormGroup.controls.debits_params_devices.controls.length = 0;
          this.data.debit_device_id.forEach((device: any, index: any) => {
            const deviceO = this.availableStatusDevices.find(
              (d: any) => d.id === device
            );
            const field = this.data.debit_device_field[index];
            this.infoFormGroup.controls.debits_params_devices.controls.push(
              new FormGroup({
                debit_device_id: new FormControl(deviceO, []),
                debit_device_field: new FormControl(field, []),
              })
            );
          });
        } else {
          this.data.debit_device_id.forEach((device: any, index: any) => {
            const deviceO = this.availableStatusDevices.find(
              (d: any) => d.id === device
            );
            const field = this.data.debit_device_field[index];
            this.infoFormGroup.controls.debits_params_devices.controls.push(
              new FormGroup({
                debit_device_id: new FormControl(deviceO, []),
                debit_device_field: new FormControl(field, []),
              })
            );
          });
        }
      } else {
        if (this.infoFormGroup.controls.debits_params_devices.controls.length) {
          this.infoFormGroup.controls.debits_params_devices.controls.length = 0;
          this.infoFormGroup.controls.debits_params_devices.controls.push(
            this.initFormControlsArray()
          );
        } else {
          this.infoFormGroup.controls.debits_params_devices.controls.push(
            this.initFormControlsArray()
          );
        }
      }
    } else if (!this.availableStatusDevices.length) {
      //по идее сюда никогда не должно зайти
      forkJoin({
        pressures: this.pressureService.pressure,
        devices: this.objectService.getDeviceList(),
      }).subscribe((result: any) => {
        const { pressures, devices } = result;
        this.availablePressures = this.prepareArrayForSelectField(pressures);
        this.pressureDeviceSearch$.next('');
        this.availableStatusDevices = this.prepareArrayForSelectField(devices);
        this.statusDeviceSearch$.next('');
        this.frequencyDeviceSearch$.next('');
        this.debitDeviceSearch$.next('');

        this.infoFormGroup.patchValue({
          status_device_id:
            devices.find((d: any) => d.id === this.data.status_device_id) ||
            null,
        });
        this.infoFormGroup.patchValue({
          freq_device_id:
            devices.find((d: any) => d.id === this.data.freq_device_id) || null,
        });
        if (this.data.debit_device_id && this.data.debit_device_field) {
          if (
            this.infoFormGroup.controls.debits_params_devices.controls.length
          ) {
            this.infoFormGroup.controls.debits_params_devices.controls.length = 0;
            this.data.debit_device_id.forEach((device: any, index: any) => {
              const deviceO = devices.find((d: any) => d.id === device);
              const field = this.data.debit_device_field[index];
              this.infoFormGroup.controls.debits_params_devices.controls.push(
                new FormGroup({
                  debit_device_id: new FormControl(deviceO, []),
                  debit_device_field: new FormControl(field, []),
                })
              );
            });
          }
        } else {
          if (!this.infoFormGroup.controls.debits_params_devices.length) {
            this.infoFormGroup.controls.debits_params_devices.controls.push(
              this.initFormControlsArray()
            );
          }
        }
        setTimeout(() => {
          this.pressureDeviceSearch$.next('');
          this.statusDeviceSearch$.next('');
          this.frequencyDeviceSearch$.next('');
          this.debitDeviceSearch$.next('');
        });
      });
    }

    setTimeout(() => {
      this.visibleMap = true;
    });
    setTimeout(() => {
      this.pressureDeviceSearch$.next('');
      this.statusDeviceSearch$.next('');
      this.frequencyDeviceSearch$.next('');
      this.debitDeviceSearch$.next('');
    });
  }

  public memoizedGetCurrentLat = memoizee(
    (latitude: number | null, longitude: number | null) => {
      return typeof latitude === 'number' && typeof longitude === 'number'
        ? latitude
        : '-';
    }
  );

  public memoizedGetCurrentLon = memoizee(
    (latitude: number | null, longitude: number | null) => {
      return typeof latitude === 'number' && typeof longitude === 'number'
        ? longitude
        : '-';
    }
  );

  changeIconBetweenDebits() {
    if (this.iconBetweenDebits === 'tuiIconPlus') {
      this.iconBetweenDebits = 'tuiIconMinus';
    } else this.iconBetweenDebits = 'tuiIconPlus';
  }

  onSave() {
    if (this.infoFormGroup.invalid || this.checkStatusFreg()) {
      this.alertService
        .open('Проверьте правильность заполнения формы', {
          label: '',
          status: TuiNotification.Error,
        })
        .subscribe();
      return this.infoFormGroup.markAllAsTouched();
    }

    // Проверка валидности полей датчика дебета
    if (this.debitSensorErrorMessage) {
      this.alertService
        .open('Неправильно заполнены поля датчика дебита', {
          label: '',
          status: TuiNotification.Error,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      return;
    }

    let debitDeviceId: number[] = [];
    let debitDeviceField: string[] = [];
    const values = this.infoFormGroup
      .get('debits_params_devices')
      .controls.map((row: any) => {
        return {
          debit_device_id: row.get('debit_device_id').value,
          debit_device_field: row.get('debit_device_field').value,
        };
      });
    debitDeviceId = values
      .map((v: any) => {
        if (v.debit_device_id?.id) return v.debit_device_id.id;
      })
      .filter((value: number | undefined) => value);
    debitDeviceField = values
      .map((v: any) => {
        if (v.debit_device_field) return v.debit_device_field;
      })
      .filter((value: string | undefined) => value);

    if (
      this.data.debit_device_id.length !== debitDeviceId.length ||
      this.data.debit_device_field.length !== debitDeviceField.length ||
      !debitDeviceId.every((device: number) => {
        return this.data.debit_device_id.includes(device);
      }) ||
      !this.data.debit_device_id.every((device: number) => {
        return debitDeviceId.includes(device);
      }) ||
      !debitDeviceField.every((field: string) => {
        return this.data.debit_device_field.includes(field);
      }) ||
      !this.data.debit_device_field.every((field: string) => {
        return debitDeviceField.includes(field);
      }) ||
      this.infoFormGroup.dirty ||
      this.infoFormGroup.get('latitude')?.value !== this.data?.latitude ||
      this.infoFormGroup.get('longitude')?.value !== this.data?.longitude
    ) {
      const body = { ...this.data };
      body.name = this.infoFormGroup.get('name').value;
      body.parent_id = this.infoFormGroup.get('parent_id').value;
      body.bush_parent_id =
        this.infoFormGroup.get('bush_parent_id').value || null;
      body.oil_parent_id =
        this.infoFormGroup.get('oil_parent_id').value || null;
      body.attributes.type_mining = this.infoFormGroup.get('type_mining').value;
      body.attributes.debit = this.infoFormGroup.get('debit').value;
      body.attributes.water_cut = this.infoFormGroup.get('water_cut').value;
      body.latitude =
        typeof this.infoFormGroup.get('latitude').value === 'number'
          ? this.infoFormGroup.get('latitude')?.value
          : null;
      body.longitude =
        typeof this.infoFormGroup.get('longitude').value === 'number'
          ? this.infoFormGroup.get('longitude')?.value
          : null;
      body.debit_calculate_option = debitDeviceId.length
        ? this.iconBetweenDebits === 'tuiIconPlus'
          ? 'add'
          : 'subtract'
        : null;
      body.curr_pressure_id =
        this.infoFormGroup.get('curr_pressure').value?.id || null;
      body.next_pressure_id =
        this.infoFormGroup.get('next_pressure').value?.id || null;
      body.oil_pipe_id = this.infoFormGroup.get('oil_pipe').value?.id || null;
      body.water_pipe_id =
        this.infoFormGroup.get('water_pipe').value?.id || null;

      const paramBody = {
        object_params: this.data.params.map((param: any) => ({
          device_id: param.device_id,
          parametrs_id: param.param.id,
        })),
        debit_device_id: debitDeviceId,
        debit_device_field: debitDeviceField,
        status_device_id:
          this.infoFormGroup.get('status_device_id').value?.id || null,
        freq_device_id:
          this.infoFormGroup.get('freq_device_id').value?.id || null,
        freq_device_field:
          this.infoFormGroup.get('freq_device_field').value || null,
      };

      this.objectService.updateObject(body, this.data.id).subscribe(() => {
        this.directoriesService
          .setParamObject(this.data.id, paramBody)
          .subscribe(() => {
            this.objectService
              .getObjectByID(body.id)
              .subscribe(async (data: any) => {
                this.boreholeService.borehole.next(data);
                this.data = { ...data };
                await this.updatePressureSensorValues();
                this.alertService
                  .open('Объект успешно обновлен.', {
                    label: '',
                    status: TuiNotification.Success,
                  })
                  .subscribe();
                this.handleSubmit.emit(this.data.name);
                this.isEdit = false;
              });
          });
      });
    }
    this.isEdit = false;
  }

  //TODO: this function is called always and I don't know how to add memoizee for tuiPure, we decided to leave all as it is
  @tuiPure
  stringify(items: readonly any[]): any {
    const map = new Map(
      items.map(({ id, name }) => [id, name] as [number, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }

  onSearchChange(searchQuery: string | null, formControlName: string): void {
    switch (formControlName) {
      case 'freq_device_id':
        this.frequencyDeviceSearch$.next(searchQuery);
        break;
      case 'pressure':
        this.pressureDeviceSearch$.next(searchQuery);
        break;
      case 'debit_device_id':
        this.debitDeviceSearch$.next(searchQuery);
        break;
      case 'status_device_id':
        this.statusDeviceSearch$.next(searchQuery);
        break;
      default:
        this.search$.next(searchQuery);
    }
  }

  extractValueFromEvent(event: string | null | Event): string | null {
    return typeof event === 'string'
      ? event
      : (event?.target as HTMLInputElement)?.value || null;
  }

  getWorkTime(work_time: any) {
    if (work_time === null) {
      return '-';
    }
    const hours = Math.floor(work_time / 3600);
    const minutes = Math.floor((work_time % 3600) / 60);
    const seconds = work_time % 60;
    let result = `${minutes.toString().padStart(1, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
    if (!!hours) {
      result = `${hours.toString()}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return result;
  }

  private filterDebitDevices(
    searchQuery: string | null
  ): Observable<readonly any[]> {
    const result = this.availableStatusDevices.filter((device: any) =>
      device.name.toLowerCase().includes((searchQuery || '').toLowerCase())
    );
    result.splice(20);
    return of(result);
  }

  readonly stringifyComboBox = (item: { id: number; name: string }): string =>
    item.name || '';

  getDebitUnitDebitDevice(
    debit_device_sum: number,
    debit_device_field: string[]
  ) {
    return `${debit_device_sum.toFixed(2)} ${
      debit_device_field.find((parameter: string) =>
        ['M1', 'M2'].includes(parameter)
      )
        ? ' т'
        : ' м3'
    }`;
  }

  getConcatOils() {
    return [...this.availableDns, ...this.availableGzu, ...this.availableBg];
  }

  public oilCollection() {
    forkJoin({
      bushes: this.objectService.getBushesList(),
      gzu: this.objectService.getGzuList(),
      dns: this.objectService.getDnsList(),
      bg: this.objectService.getBGList(),
    }).subscribe((result: any) => {
      this.availableBushes = result.bushes;
      this.availableGzu = result.gzu;
      this.availableDns = result.dns;
      this.availableBg = result.bg;
      this.filterOilCollection(this.data.parent_id);
    });
  }

  filterOilCollection(idParent: number) {
    this.skeletonVisible = true;
    this.listOfOil.gzuList = this.prepareArrayForSelectField(
      this.availableGzu.filter((element: any) => {
        return element.parent_id === idParent;
      })
    );
    this.listOfOil.dnsList = this.prepareArrayForSelectField(
      this.availableDns.filter((element: any) => {
        return element.parent_id === idParent;
      })
    );
    this.listOfOil.bgList = this.prepareArrayForSelectField(
      this.availableBg.filter((element: any) => {
        return element.parent_id === idParent;
      })
    );
    this.filterAvailableBushes = this.prepareArrayForSelectField(
      this.availableBushes.filter((bush: any) => {
        return bush.parent_id === idParent;
      })
    );
    if (
      !this.listOfOil.gzuList.length &&
      !this.listOfOil.dnsList.length &&
      !this.listOfOil.bgList.length
    ) {
      this.infoFormGroup.get('oil_parent_id').disable();
      this.disabledOilField = true;
    } else {
      this.infoFormGroup.get('oil_parent_id').enable();
      this.disabledOilField = false;
    }
    if (!this.filterAvailableBushes.length) {
      this.infoFormGroup.get('bush_parent_id').disable();
      this.disabledBushField = true;
    } else {
      this.infoFormGroup.get('bush_parent_id').enable();
      this.disabledBushField = false;
    }
    this.skeletonVisible = false;
  }

  getDevicesName(): string {
    if (!this.data.debit_device_id.length) {
      return 'Не выбран';
    } else {
      if (this.data.debit_device.length !== this.data.debit_device_id.length) {
        const doubleDebitName = this.data.debit_device.map((d: any) => d.name);
        return [...doubleDebitName, ...doubleDebitName].join(', ');
      } else return this.data.debit_device.map((d: any) => d.name).join(', ');
    }
  }

  prepareArrayForSelectField(array: any): DataForSelectField[] {
    return array.map((v: DataForSelectField) => ({
      id: v.id,
      name: v.name,
    }));
  }

  findNameByIdFromArray(id: number | string, dataArray: DataForSelectField[]) {
    let result = dataArray.find((item) => item.id === id);
    return result ? result.name : null;
  }

  findObjectByIdFromArray(
    id: number | string,
    dataArray: DataForSelectField[]
  ) {
    let result = dataArray.find((item) => item.id === id);
    return result ? result : null;
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
