import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PlusComponent } from "../UI/plus/plus.component";
import { UiKitComponent } from "./ui-kit.component";
import { ComponentsListComponent } from "../components-list/components-list.component";
import { LayoutComponent } from "../UI/layout/layout.component";
import { GridLayoutComponent } from "../UI/layout/grid-layout/grid-layout.component";
import { SoloLayoutComponent } from "../UI/layout/solo-layout/solo-layout.component";
import { DefaultLayoutComponent } from "../UI/layout/default-layout/default-layout.component";
import { BaseLayoutComponent } from "../UI/layout/base-layout/base-layout.component";
import { FilteringComponent } from "../UI/filtering/filtering.component";
import { TableTemplateComponent } from "../UI/table/table-template/table-template.component";
import { FormTemplateComponent } from "../UI/form/form-template/form-template.component";
import { SchemeComponent } from "../UI/scheme/scheme.component";
import { NormalLayoutComponent } from "../UI/layout/normal-layout/normal-layout.component";

const routes: Routes = [
  {
    path: "ui",
    component: UiKitComponent,
    children: [
      {
        path: "components-list",
        component: ComponentsListComponent,
      },
      {
        path: "plus",
        component: PlusComponent,
      },
      {
        path: "layout",
        component: LayoutComponent,
        children: [
          {
            path: "grid",
            component: GridLayoutComponent,
          },
          {
            path: "normal",
            component: NormalLayoutComponent,
          },
          {
            path: "solo",
            component: SoloLayoutComponent,
          },
          {
            path: "default",
            component: DefaultLayoutComponent,
          },
          {
            path: "base",
            component: BaseLayoutComponent,
          },
        ],
      },
      {
        path: "filter",
        component: FilteringComponent,
      },
      {
        path: "table",
        component: TableTemplateComponent,
      },
      {
        path: "form",
        component: FormTemplateComponent,
      },
      {
        path: "scheme",
        component: SchemeComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class UiKitRoutingModule {}
