import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TYPE_OBJECTS } from '../../../../const/app-consts';
import {
  TuiAlertService,
  TuiDialogContext,
  TuiDialogService,
} from '@taiga-ui/core';
import { ObjectService } from '../../../../services/object.service';
import { ObjectType } from '../../../../../models/object-type';
import {
  AddObjectType,
  InputCoordinateData,
} from '../../../../../models/content-field.model';
import * as _ from 'lodash';
import { TypeTree } from 'src/models/tree.model';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}
@Component({
  selector: 'app-add-object',
  templateUrl: './add-object.component.html',
  styleUrls: ['./add-object.component.less'],
})
export class AddObjectComponent implements OnInit {
  @Input() changePopup: any = () => {};

  public parents: any = [
    {
      value: 'none',
      label: 'Родительский объект отсутствует',
    },
  ];

  /*  public validation_msgs = {
    contactAutocompleteControl: [
      {
        type: 'invalidAutocompleteObject',
        message:
          'Имя объекта не распознано. Щелкните один из вариантов автозаполнения.',
      },
      { type: 'required', message: 'Поле обязательно для заполнения.' },
    ],
  };*/

  public readonly GRAY: 'gray' = 'gray';

  public latitude: number = 0;

  public longitude: number = 0;

  public onClickOnMap = new EventEmitter();

  public selectedParent: {
    value: number;
    label: string;
  } | null = null;

  public typeObjects = TYPE_OBJECTS;

  public borehole = ObjectType.BOREHOLE;

  public formType: FormGroup = new FormGroup({
    type: new FormControl('', [Validators.required]),
  });

  public formAddObject: FormGroup = new FormGroup({});

  public formOilfield: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20),
      checkForFirstSpaceCharacter(),
    ]),
  });

  //ктп днс БГ/УЗА гзу куст
  public formOtherObjects: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20),
      checkForFirstSpaceCharacter(),
    ]),
    parent: new FormControl(
      {
        value: 'none',
        label: 'Родительский объект отсутствует',
      },
      [autocompleteObjectValidator()]
    ),
    latitude: new FormControl(null, [Validators.required]),
    longitude: new FormControl(null, [Validators.required]),
  });

  public formBorehole: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(12),
      checkForFirstSpaceCharacter(),
    ]),
    waterCut: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(50),
      checkForFirstSpaceCharacter(),
    ]),
    parent: new FormControl(
      {
        value: 'none',
        label: 'Родительский объект отсутствует',
      },
      []
    ),
    debit: new FormControl('', [Validators.required]),
    typeMining: new FormControl('', [Validators.required]),
    latitude: new FormControl(null, [Validators.required]),
    longitude: new FormControl(null, [Validators.required]),
  });

  public whiteList: Array<string> = [
    'borehole',
    'bush',
    'gzu',
    'dns',
    'bg',
    'ktp',
  ];

  public reloadForm = true;
  // public whiteListWaterMark: Array<string> = ['borehole'];

  public nameSetting: AddObjectType = {
    title: 'Название',
    formControlNameItem: 'name',
    maxLengthText: 20,
    isStringInput: true,
    placeholderText: 'Введите название объекта',
  };

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    private objectService: ObjectService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<any, any>
  ) {}

  ngOnInit() {
    this.getObjectList();
    this.objectService.selectedObject.subscribe(async (item: any) => {
      this.parents.push(...(await this.objectService.parentsList));
      this.selectedParent = this.parents?.find(
        (parent: { value: number | string; label: string }) => {
          return parent.value == item?.id;
        }
      ) || {
        value: 'none',
        label: 'Родительский объект отсутствует',
      };
      this.formAddObject.get('parent')?.setValue(this.selectedParent);
    });
    this.onClickOnMap.pipe().subscribe((coordinates) => {
      this.formAddObject.get('latitude')?.setValue(coordinates.lat);
      this.formAddObject.get('longitude')?.setValue(coordinates.lng);
    });
  }

  getObjectList() {
    this.objectService.getObjectList().subscribe((data: Array<TypeTree>) => {
      this.parents = [
        {
          value: 'none',
          label: 'Родительский объект отсутствует',
        },
      ];
      data.map((item: TypeTree) => {
        this.parents.push({
          value: item.id,
          label: item.name,
        });
      });
      if (this.selectedParent) {
        this.selectedParent = this.parents.find(
          (parent: { value: number | string; label: string }) => {
            return parent.value == this.selectedParent?.value;
          }
        ) || {
          value: 'none',
          label: 'Родительский объект отсутствует',
        };
        this.formAddObject.get('parent')?.setValue(this.selectedParent);
      }
    });
  }

  stateChangedType(): number {
    return this.formType.get('type')?.value.value;
  }

  /*showDialog() {
    this.getObjectList();
    this.formType.reset({
      type: '',
    });
    this.resetForm();
  }*/

  resetForm() {
    this.reloadForm = false;
    this.formAddObject.get('name')?.setValue('');
    this.formAddObject.reset({
      name: '',
      parent: this.selectedParent || {
        value: 'none',
        label: 'Родительский объект отсутствует',
      },
      debit: '',
      typeMining: '',
      latitude: null,
      longitude: null,
    });
    //плохо
    setTimeout(() => {
      this.reloadForm = true;
    }, 0);
  }

  saveObject() {
    if (!this.formType.valid) {
      return this.formType.markAllAsTouched();
    }
    if (!this.formAddObject.valid) {
      return this.formAddObject.markAllAsTouched();
    }
    let body: any = {};
    body.name = this.formAddObject.get('name')?.value;
    body.parent_id =
      this.formAddObject.get('parent')?.value.value === 'none'
        ? null
        : this.formAddObject.get('parent')?.value.value;

    body.type = this.formType.get('type')?.value.type;
    if (this.formType.get('type')?.value.type === this.borehole) {
      body.attributes = {
        debit: this.formAddObject.get('debit')?.value,
        water_cut: this.formAddObject.get('waterCut')?.value || null,
        type_mining: this.formAddObject.get('typeMining')?.value.value,
      };
    }
    this.objectService.objectCreate(body).subscribe(() => {
      this.objectService.getDataToTree().subscribe((data: any) => {
        this.objectService.updateTree.next(data);
      });
      this.context.completeWith(true);
      this.changePopup();
    });
  }

  setCoordinate(item: InputCoordinateData) {
    if (item.controlName === 'latitude') {
      this.latitude = item.coordinate;
    } else if (item.controlName === 'longitude') {
      this.longitude = item.coordinate;
    }
  }

  closeAddObjectDialog() {
    this.changePopup();
    this.context.completeWith(false);
  }

  changeNameValidator() {
    this.resetForm();
    if (this.isTypeBorehole()) {
      this.formAddObject = _.cloneDeep(this.formBorehole);
      this.nameSetting.maxLengthText = 13;
      this.formAddObject.get('parent')?.setValue(
        this.selectedParent || {
          value: 'none',
          label: 'Родительский объект отсутствует',
        }
      );
    } else if (this.isTypeDeposit()) {
      this.formAddObject = _.cloneDeep(this.formOilfield);
      this.nameSetting.maxLengthText = 21;
    } else {
      this.formAddObject = _.cloneDeep(this.formOtherObjects);
      this.nameSetting.maxLengthText = 21;
      this.formAddObject.get('parent')?.setValue(
        this.selectedParent || {
          value: 'none',
          label: 'Родительский объект отсутствует',
        }
      );
    }
  }

  isTypeBorehole(): boolean {
    return this.formType?.get('type')?.value.type === ObjectType.BOREHOLE;
  }

  isTypeDeposit(): boolean {
    return this.formType?.get('type')?.value.type === ObjectType.DEPOSIT;
  }

  hasSelectedType(): boolean {
    return !!this.formType.get('type')?.value.type;
  }
}
