import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ObjectService } from '../../../../services/object.service';
import { TypeTree } from '../../../../../models/tree.model';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { PressureService } from '../../../../services/pressure.service';
import { PipelineService } from '../../../../services/pipeline.service';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TuiAlertService } from '@taiga-ui/core';
import * as memoizee from 'memoizee';
import { startWith } from 'rxjs/operators';
import { TuiContextWithImplicit, tuiPure } from '@taiga-ui/cdk';
import { AVAILABLE_DEBIT_FIELDS } from '../../../../const/app-consts';
import { GzuService } from '../../../../services/gzu.service';

//проверка на пробелы
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

type DataForSelectField = {
  id: number | string;
  name: string;
};

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

export function minValueValidator(context: { min: number }): string {
  return `Значение должно быть не меньше ${context.min}`;
}

export function maxValueValidator(context: { max: number }): string {
  return `Значение должно быть не больше ${context.max}`;
}

@Component({
  selector: 'app-gzu-add',
  templateUrl: './gzu-add.component.html',
  styleUrls: ['./gzu-add.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class GzuAddComponent implements OnInit, OnDestroy {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  // возвращает созданное месторождение
  @Output() requestCreatedData: EventEmitter<null> = new EventEmitter();

  //для формы -> список скважин
  public depositList: any = [];

  //форма для имени месторождения
  public formGZU: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    deposit: new FormControl('', [Validators.required]),
    oil_parent_id: new FormControl({ value: null, disabled: true }),
    curr_pressure: new FormControl(null),
    next_pressure: new FormControl(null),
    oil_pipe: new FormControl(null, []),
    water_pipe: new FormControl(null, []),
    latitude: new FormControl('', [
      Validators.required,
      Validators.min(-90),
      Validators.max(90),
    ]),
    longitude: new FormControl('', [
      Validators.required,
      Validators.min(-180),
      Validators.max(180),
    ]),
    debits_params_devices: new FormArray([]),
  });

  getNameSelectedValue: any = () => {
    return this.formGZU.get('oil_parent_id')?.value?.name || '';
  };

  //клик по карте(по нему в onInit записываем координаты клика по карте)
  public onClickOnMap = new EventEmitter();

  public filterListOfOil: any = {
    gzuList: [],
    dnsList: [],
    bgList: [],
  };

  public listOfOil: any = {
    gzuList: [],
    dnsList: [],
    bgList: [],
  };

  public pressureList: DataForSelectField[] = [];

  public latitude: number = 55;

  public longitude: number = 55;

  public destroyer: Subject<null> = new Subject();

  public disabledFormControl: boolean = false;

  public oilPipelineList: DataForSelectField[] = [];

  public waterPipelineList: DataForSelectField[] = [];

  public availableStatusDevices: any[] = [];

  public memoizedGetSections = memoizee((form: any): any => {
    return form.controls.debits_params_devices.controls;
    //[{controls: {debit_device_field: {value:}, debit_device_id: {value: }}}] TODO remove this comment
  });

  public iconBetweenDebits: string = 'tuiIconPlus';

  public availableDebitFields = AVAILABLE_DEBIT_FIELDS.map((v: any) => ({
    id: v.value,
    name: v.title,
  }));

  public skeletonVisible: boolean = true;

  public debitSensorErrorMessage: string | null = null;

  constructor(
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
    public objectService: ObjectService,
    private pressureService: PressureService,
    private pipelineService: PipelineService,
    private gzuService: GzuService
  ) {}

  async ngOnInit() {
    const deposits = await this.objectService.deposits;
    this.listOfOil = {
      gzuList: await this.objectService.gzu,
      dnsList: await this.objectService.dns,
      bgList: await this.objectService.bg,
    };
    this.depositList = deposits.map((item: TypeTree) => {
      return { id: item.id, title: item.name };
    });
    this.onClickOnMap
      .pipe(takeUntil(this.destroyer))
      .subscribe((coordinates) => {
        this.formGZU.get('latitude')?.setValue(coordinates.lat);
        this.formGZU.get('longitude')?.setValue(coordinates.lng);
      });
    this.formGZU
      .get('latitude')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (this.formGZU.get('latitude')?.valid) {
          this.latitude = item;
          return;
        }
        return;
      });
    this.formGZU
      .get('longitude')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (this.formGZU.get('longitude')?.valid) {
          this.longitude = item;
          return;
        }
        return;
      });
    this.formGZU
      .get('deposit')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (!item) {
          this.formGZU.get('oil_parent_id')?.disable();
        } else {
          this.formGZU.get('oil_parent_id')?.enable();
        }
        this.formGZU.get('oil_parent_id')?.setValue(null);
        this.filterListOfOil = {
          gzuList: this.listOfOil.gzuList.filter(
            (bush: any) => item?.id === bush.parent_id
          ),
          dnsList: this.listOfOil.dnsList.filter(
            (bush: any) => item?.id === bush.parent_id
          ),
          bgList: this.listOfOil.bgList.filter(
            (bush: any) => item?.id === bush.parent_id
          ),
        };
        if (
          !this.filterListOfOil.gzuList.length &&
          !this.filterListOfOil.dnsList.length &&
          !this.filterListOfOil.bgList.length
        ) {
          this.formGZU.get('oil_parent_id')?.disable();
          this.disabledFormControl = true;
        } else {
          this.formGZU.get('oil_parent_id')?.enable();
          this.disabledFormControl = false;
        }
      });

    this.formGZU.controls.debits_params_devices.push(
      this.initFormControlsArray()
    );

    await this.getValuesForPressureSensorField();

    // Запрашиваем данные для поля выбора нефтепровода
    await this.getValuesForOilPipelineField();

    // Запрашиваем данные для поля выбора водопровода
    await this.getValuesForWaterPipelineField();

    await this.getValuesForDebitSensorField();

    this.startDebitDeviceErrorMonitoring();
  }

  startDebitDeviceErrorMonitoring() {
    this.formGZU
      .get('debits_params_devices')
      .valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe(() => {
        this.checkDebitDeviceError();
      });
  }

  checkDebitDeviceError() {
    const form = this.formGZU.get('debits_params_devices');

    if (
      form.controls[0].get('debit_device_id').value &&
      !form.controls[0].get('debit_device_field').value
    ) {
      this.debitSensorErrorMessage =
        'Поле "Параметр устройства" обязательно для каждого датчика дебита';
      return;
    }

    if (
      !form.controls[0].get('debit_device_id').value &&
      form.controls[0].get('debit_device_field').value
    ) {
      this.debitSensorErrorMessage = 'Укажите датчик дебита';
      return;
    }

    if (form.controls[1]) {
      if (
        form.controls[1].get('debit_device_id').value &&
        !form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Поле "Параметр устройства" обязательно для каждого датчика дебита';
        return;
      }

      if (
        !form.controls[1].get('debit_device_id').value &&
        form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage = 'Укажите датчик дебита';
        return;
      }

      if (
        !form.controls[0].get('debit_device_id').value ||
        !form.controls[0].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Поля первого датчика дебита заполнены неправильно';
        return;
      }

      if (
        form.controls[0].get('debit_device_id').value ===
          form.controls[1].get('debit_device_id').value &&
        form.controls[0].get('debit_device_field').value ===
          form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Датчики должны различаться по названию и/или параметрам';
        return;
      }
    }

    this.debitSensorErrorMessage = null;
  }

  private async getValuesForOilPipelineField() {
    const oilPipelines = await this.pipelineService.oilPipelinesList;
    this.oilPipelineList = this.prepareArrayForSelectField(oilPipelines);
  }

  private async getValuesForWaterPipelineField() {
    const waterPipelines = await this.pipelineService.waterPipelinesList;
    this.waterPipelineList = this.prepareArrayForSelectField(waterPipelines);
  }

  private async getValuesForPressureSensorField() {
    const pressure = await this.pressureService.pressure;
    this.pressureList = this.prepareArrayForSelectField(pressure);
  }

  private getValuesForDebitSensorField() {
    this.objectService.getDeviceList().subscribe((data: any) => {
      this.availableStatusDevices = this.prepareArrayForSelectField(data);
      // this.search$.next('');
      this.debitDeviceSearch$.next('');
      this.skeletonVisible = false;
    });
  }

  readonly debitDeviceSearch$: Subject<string | null> = new Subject();

  readonly availableDebitDevices$: Observable<readonly any[] | null> =
    this.debitDeviceSearch$.pipe(
      takeUntil(this.destroyer),
      switchMap((search: string | null) =>
        this.filterDebitDevices(search).pipe(
          startWith<readonly any[] | null>(null)
        )
      )
    );

  private filterDebitDevices(
    searchQuery: string | null
  ): Observable<readonly any[]> {
    const result = this.availableStatusDevices.filter((device: any) =>
      device.name.toLowerCase().includes((searchQuery || '').toLowerCase())
    );
    result.splice(20);
    return of(result);
  }

  //магия для статуса
  @tuiPure
  stringify(items: readonly any[]): any {
    const map = new Map(
      items.map(({ id, name }) => [id, name] as [number, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }

  readonly stringifyComboBox = (item: { id: number; name: string }): string =>
    item.name || '';

  initFormControlsArray() {
    const formGroup = new FormGroup({
      debit_device_id: new FormControl(null, []),
      debit_device_field: new FormControl(null, []),
    });

    formGroup.get('debit_device_field')?.disable();
    formGroup
      .get('debit_device_id')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (!item) {
          formGroup.get('debit_device_field')?.disable();
          return;
        }
        formGroup.get('debit_device_field')?.enable();
        formGroup.get('debit_device_field')?.setValidators(Validators.required);
      });
    setTimeout(() => {
      this.debitDeviceSearch$.next('');
    }, 10);
    return formGroup;
  }

  add() {
    // const control = <FormArray>(
    //   this.formGZU.get('debits_params_devices').controls
    // );
    // control.push(this.initFormControlsArray());
    const control = <FormArray>this.formGZU.get('debits_params_devices');
    control.push(this.initFormControlsArray());
  }

  remove(i: number) {
    // this.formGZU.get('debits_params_devices').controls.splice(i, 1);
    const control = <FormArray>this.formGZU.get('debits_params_devices');
    control.removeAt(i);
  }

  onDebitSearchChange(s: string | null) {
    this.debitDeviceSearch$.next(s ?? '');
  }

  extractValueFromEvent(event: Event): string | null {
    return (event.target as HTMLInputElement)?.value || null;
  }

  changeIconBetweenDebits() {
    if (this.iconBetweenDebits === 'tuiIconPlus') {
      this.iconBetweenDebits = 'tuiIconMinus';
    } else this.iconBetweenDebits = 'tuiIconPlus';
  }

  // проверка на валидность поля -> отправка запроса при успешной валидации
  submitForm() {
    if (!this.formGZU.valid) {
      this.alertService
        .open('Проверьте правильность заполнения формы', {
          label: '',
          status: TuiNotification.Error,
        })
        .subscribe();
      return this.formGZU.markAllAsTouched();
    }
    // if (
    //   this.formGZU
    //     .get('debits_params_devices')
    //     .controls.some((c: any) => c.dirty) &&
    //   this.formGZU
    //     .get('debits_params_devices')
    //     .controls.some(
    //       (c: any) =>
    //         (!c.controls.debit_device_id &&
    //           !c.controls.debit_device_id.value?.id &&
    //           !c.controls.debit_device_id.value?.name) ||
    //         !c.controls.debit_device_field.value
    //     )
    // ) {
    //   this.alertService
    //     .open('Проверьте правильность заполнения формы', {
    //       label: '',
    //       status: TuiNotification.Error,
    //     })
    //     .subscribe();
    //   this.formGZU
    //     .get('debits_params_devices')
    //     .controls.map((formGroup: any) => {
    //       formGroup.controls.debit_device_id.updateValueAndValidity({
    //         onlySelf: true,
    //       });
    //       formGroup.controls.debit_device_field.updateValueAndValidity({
    //         onlySelf: true,
    //       });
    //     });
    //   return;
    // }

    // Проверка валидности полей датчика дебета
    if (this.debitSensorErrorMessage) {
      this.alertService
        .open('Неправильно заполнены поля датчика дебита', {
          label: '',
          status: TuiNotification.Error,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      return;
    }
    // Если выбран нефтепровод и/или водопровод и не выбран объект нефтесбора, то требуем указать объект нефтесбора
    if (
      !this.formGZU.get('oil_parent_id')?.value &&
      (this.formGZU.get('oil_pipe')?.value?.id ||
        this.formGZU.get('water_pipe')?.value?.id)
    ) {
      this.alertService
        .open(
          'Для выбора нефтепровода и/или водопровода необходимо указать объект нефтесбора',
          {
            label: '',
            status: TuiNotification.Warning,
            hasIcon: true,
            autoClose: true,
            hasCloseButton: true,
          }
        )
        .subscribe();
      return;
    }
    let debitDeviceId: number[] = [];
    let debitDeviceField: string[] = [];
    const values = this.formGZU
      .get('debits_params_devices')
      .controls.map((row: any) => {
        return {
          debit_device_id: row.get('debit_device_id').value,
          debit_device_field: row.get('debit_device_field').value,
        };
      });
    debitDeviceId = values
      .map((v: any) => {
        if (v.debit_device_id?.id) return v.debit_device_id.id;
      })
      .filter((value: number | undefined) => value);
    debitDeviceField = values
      .map((v: any) => {
        if (v.debit_device_field) return v.debit_device_field;
      })
      .filter((value: string | undefined) => value);
    if (debitDeviceId.length !== debitDeviceField.length) {
      this.alertService
        .open('Проверьте правильность заполнения формы', {
          label: '',
          status: TuiNotification.Error,
        })
        .subscribe();
      this.formGZU
        .get('debits_params_devices')
        .controls.map((formGroup: any) => {
          formGroup.controls.debit_device_id.updateValueAndValidity({
            onlySelf: true,
          });
          formGroup.controls.debit_device_field.updateValueAndValidity({
            onlySelf: true,
          });
        });
      return this.formGZU.markAllAsTouched();
    }
    this.gzuService
      .createGzu({
        type: 'gzu',
        name: this.formGZU.get('name')?.value,
        parent_id: this.formGZU.get('deposit')?.value.id,
        oil_parent_id: this.formGZU.get('oil_parent_id')?.value?.id || null,
        curr_pressure_id: this.formGZU.get('curr_pressure')?.value?.id || null,
        next_pressure_id: this.formGZU.get('next_pressure')?.value?.id || null,
        oil_pipe_id: this.formGZU.get('oil_pipe')?.value?.id || null,
        water_pipe_id: this.formGZU.get('water_pipe')?.value?.id || null,
        latitude: this.formGZU.get('latitude')?.value || null,
        longitude: this.formGZU.get('longitude')?.value || null,
        debit_device_id: debitDeviceId,
        debit_device_field: debitDeviceField,
        debit_calculate_option: debitDeviceId.length
          ? this.iconBetweenDebits === 'tuiIconPlus'
            ? 'add'
            : 'subtract'
          : null,
      })
      .pipe(takeUntil(this.destroyer))
      .subscribe(() => {
        this.requestCreatedData.emit();
        this.closeDialog();
        this.alertService
          .open('Объект создан', {
            label: '',
            status: TuiNotification.Success,
            hasIcon: true,
            autoClose: true,
            hasCloseButton: true,
          })
          .subscribe();
      });
  }

  prepareArrayForSelectField(array: any): DataForSelectField[] {
    return array.map((v: DataForSelectField) => ({
      id: v.id,
      name: v.name,
    }));
  }

  // очистка полей и закрытие
  closeDialog() {
    this.formGZU.reset({ name: '' });
    this.closeModal();
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
