import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-table-add-object",
  templateUrl: "./table-add-object.component.html",
  styleUrls: ["./table-add-object.component.less"],
})
export class TableAddObjectComponent {
  @Output()
  toggleButton: EventEmitter<null> = new EventEmitter();

  changePopup() {
    this.toggleButton.emit();
  }
}
