import { Component, Input } from "@angular/core";
import { TypeNavigation } from "../../../models/navigation.model";
import { LIST_OF_NAV_ITEMS } from "../../../app/const/app-consts";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.less"],
})
export class NavigationComponent {
  @Input()
  public listOfNavLinks: TypeNavigation[] = LIST_OF_NAV_ITEMS;

  @Input()
  isEditMode: boolean = false;

  public isMenuVisible: boolean = false;

  removeItem(id: string) {
    this.listOfNavLinks = this.listOfNavLinks.filter((item: TypeNavigation) => {
      return item.id !== id;
    });
  }

  addNewItemByNavigationBar() {
    //  проверка на максимальное кол-во айтемов, не должно быть больше 10, если больше скрываем
  }

  changeBurgerMenu(state: string) {
    if (state !== "selectItem") {
      this.isMenuVisible = !this.isMenuVisible;
    } else {
      this.isMenuVisible = false;
    }
  }
}
