import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  divIcon,
  latLng,
  layerGroup,
  LeafletMouseEvent,
  marker,
  tileLayer,
} from 'leaflet';

import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { LeafletMapService } from 'src/app/services/translate-map-controls.service';
import { TypeTree } from 'src/models/tree.model';
import { TRANSLATE_TYPES_ICON_IMAGE } from 'src/app/const/app-consts';

const L = LeafletMapService.get();

@Component({
  selector: 'app-object-card-map',
  templateUrl: './object-card-map.component.html',
  styleUrls: ['./object-card-map.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectCardMapComponent implements OnInit, OnChanges {
  @Input() data: any;

  @Input() currentIdObject: number = 0;

  @Input() onClickOnMap: any;

  @Input() isEdit: boolean = false;

  @Input() latitude: number = 0;

  @Input() longitude: number = 0;

  @Input() visibleMap: boolean = false;

  public objectsLayer: any = layerGroup();

  public options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        attribution: '',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      this.objectsLayer,
    ],
    zoom: 7,
    center: latLng(55.560981, 52.638667),
    minZoom: 2,
    maxBounds: LeafletMapService.getBounds(),
  };

  private translateType: any = TRANSLATE_TYPES_ICON_IMAGE;

  public key = 0;

  public mapDns: any = null;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService
  ) {}

  // Условие вынесенное из ngOnChanges
  changeValidation(changes: any) {
    const isNotFirstChange =
      !changes?.longitude?.firstChange || !changes?.latitude?.firstChange;

    const isLatitudeChanged =
      typeof changes?.latitude?.currentValue === 'number' &&
      changes?.latitude?.currentValue !== changes?.latitude?.previousValue;

    const isLongitudeChanged =
      typeof changes?.longitude?.currentValue === 'number' &&
      changes?.longitude?.currentValue !== changes?.longitude?.previousValue;

    const validationSummary =
      this.data &&
      isNotFirstChange &&
      (isLatitudeChanged || isLongitudeChanged);

    return validationSummary;
  }

  markerObjectCreation(object: any) {
    if (this.currentIdObject === 107) {
      return this.markerPressureSensorCreation(object);
    }
    return {
      icon: divIcon({
        html: `
          <div
            class="object-map-balloon object-status-${this.getColorClassForBalloon(
              object
            )}"
            style="font-size: ${
              object.name.length > 6
                ? (5 / object.name.length) * 20 >= 10
                  ? 10
                  : (5 / object.name.length) * 20
                : 10
            }px">
            <div class="object-content">
              <span>
                ${object.name}
              </span>
              <img src="/assets/icons-white/${
                this.currentIdObject === 105
                  ? this.getIconNameForBorehole(object)
                  : this.translateType[this.currentIdObject]
              }.svg" alt="">
            </div>
          </div>`,
      }),
      objectId: object.id,
    };
  }

  // Метод создания маркера для датчика давления
  markerPressureSensorCreation(object: any) {
    return {
      icon: L.divIcon({
        html: `
          <div
            class="object-map-balloon object-status-pressure_sensor"
            style="font-size: ${
              object.name.length > 6
                ? (5 / object.name.length) * 20 >= 10
                  ? 10
                  : (5 / object.name.length) * 20
                : 10
            }px">
            <div class="object-content">
              <span>
                ${object.name}
              </span>
              <img src="/assets/icons-white/pressure_sensor.svg" alt="">
            </div>
          </div>`,
      }),
      objectId: object.id,
    };
  }

  ngOnChanges(changes: any) {
    if (this.changeValidation(changes)) {
      this.objectsLayer.clearLayers();
      this.mapDns.panTo(new L.LatLng(this.latitude, this.longitude));
      this.objectsLayer.addLayer(
        marker(
          [this.latitude, this.longitude],
          this.markerObjectCreation(this.data) as any
        )
      );
      this.key = +Object.keys(this.objectsLayer._layers)[0];
    }
  }

  ngOnInit(): void {
    if (this.data) {
      this.options.center = latLng(
        this.data.latitude || 0,
        this.data.longitude || 0
      );
      this.objectsLayer.clearLayers();
      this.objectsLayer.addLayer(
        marker(
          [this.data.latitude || 0, this.data.longitude || 0],
          this.markerObjectCreation(this.data) as any
        )
      );
      this.key = +Object.keys(this.objectsLayer._layers)[0];
    }
  }

  getColorClassForBalloon(object: TypeTree) {
    if (object?.status) {
      return object?.attributes?.type_mining === 'PPD' ? 'ppd' : object.status;
    } else return 'default';
  }

  getIconNameForBorehole(borehole: TypeTree) {
    return borehole.attributes?.type_mining &&
      borehole.attributes?.type_mining !== 'REPAIR' &&
      borehole.attributes?.type_mining !== 'NONE'
      ? `borehole_${borehole.attributes?.type_mining}`
      : 'borehole';
  }

  onMapReady(map: any) {
    LeafletMapService.translateZoomButtons(map);
    this.mapDns = map;
  }

  handleClickOnMap($event: LeafletMouseEvent) {
    if (this.isEdit) {
      if (
        $event.latlng.lat < 90 &&
        $event.latlng.lat > -90 &&
        $event.latlng.lng < 180 &&
        $event.latlng.lng > -180
      ) {
        const latitude = $event.latlng.lat;
        const longitude = $event.latlng.lng;
        const newLatLng = latLng(latitude, longitude);
        this.objectsLayer._layers[this.key].setLatLng(newLatLng);
        this.latitude = latitude;
        this.longitude = longitude;
        this.onClickOnMap.emit({
          lat: $event.latlng.lat,
          lng: $event.latlng.lng,
        });
      } else {
        this.notificationsService
          .open('Эта зона карты недоступна для взаимодействия с ней', {
            status: TuiNotification.Error,
          })
          .subscribe();
      }
      return;
    }
  }
}
