<div *ngIf="treeData" class="tree">
  <ng-container *ngIf="hasSearchBlock">
    <app-search (handlerWrittenText)="changeTree($event)"></app-search>
  </ng-container>
  <perfect-scrollbar>
    <div style="height: calc(100vh - 260px)">
      <app-tree-branch
        [children]="treeData"
        [topLevel]="true"
        [schemeMode]="schemeMode"
      >
      </app-tree-branch>
    </div>
  </perfect-scrollbar>
</div>
