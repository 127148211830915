import { PressureSensorAddComponent } from './../pages/objects-list/components/pressure-sensor-add/pressure-sensor-add.component';
import { PowerLineAddComponent } from './../pages/objects-list/components/power-line-add/power-line-add.component';
import { NgModule } from '@angular/core';
import { TaiCommonModule } from '../../common/tai-common.module';
import { CommonModule } from '../../common/common.module';
import { ContentObjectComponent } from '../pages/objects/components/content-object/content-object.component';
import { CommonInfoObjectComponent } from '../pages/objects/components/common-info-object/common-info-object.component';
import { SettingsObjectComponent } from '../pages/objects/components/settings-object/settings-object.component';
import { SchemeOilfieldsComponent } from '../pages/scheme/components/scheme-oilfields/scheme-oilfields.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ControlsComponent } from '../pages/scheme/components/controls/controls.component';
import { AddObjectComponent } from '../pages/objects/components/add-object/add-object.component';
import { MapObjectComponent } from '../pages/objects/components/map-object/map-object.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SchemeEditorComponent } from './schemes/scheme-editor/scheme-editor.component';
import { SchemeViewerComponent } from './schemes/scheme-viewer/scheme-viewer.component';
import { AddObjectFieldsComponent } from '../pages/objects/components/add-object/components/add-object-fields/add-object-fields.component';
import {
  TuiComboBoxModule,
  TuiFilterByInputPipeModule,
  TuiInputNumberModule,
  TuiPaginationModule,
} from '@taiga-ui/kit';
import { BoreholeFieldsComponent } from '../pages/objects/components/add-object/components/borehole-fields/borehole-fields.component';
import { InputFieldComponent } from '../pages/objects/components/add-object/components/add-object-fields/components/input-field/input-field.component';
import { InputNumberFieldComponent } from '../pages/objects/components/add-object/components/add-object-fields/components/input-number-field/input-number-field.component';
import { SelectFieldComponent } from '../pages/objects/components/add-object/components/add-object-fields/components/select-field/select-field.component';
import { LocationFieldsComponent } from '../pages/objects/components/add-object/components/location-fields/location-fields.component';
import { CommonButtonsComponent } from '../pages/objects/components/common-info-object/components/common-buttons/common-buttons.component';
import { CommonSelectorComponent } from '../pages/objects/components/common-info-object/components/common-selector/common-selector.component';
import { CommonDeletePopupComponent } from '../pages/objects/components/common-info-object/components/common-delete-popup/common-delete-popup.component';
import { CommonFormComponent } from '../pages/objects/components/common-info-object/components/common-form/common-form.component';
import { OptionFormComponent } from '../pages/objects/components/common-info-object/components/option-form/option-form.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  TuiErrorModule,
  TuiHintModule,
  TuiLinkModule,
  TuiTooltipModule,
} from '@taiga-ui/core';
import { SensorsObjectComponent } from '../pages/objects/components/sensors-object/sensors-object.component';
import { EditButtonsComponent } from '../pages/objects/components/common-info-object/components/edit-buttons/edit-buttons.component';
import { OptionObjectComponent } from '../pages/objects/components/common-info-object/components/option-object/option-object.component';
import { MatMenuModule } from '@angular/material/menu';
import { BoreholesObjectComponent } from '../pages/objects/components/boreholes-object/boreholes-object.component';
import { CommonInfoOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/common-info-oilfield/common-info-oilfield.component';
import { CommonFormOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/common-info-oilfield/components/common-form/common-form-oilfield.component';
import { CommonButtonsOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/common-info-oilfield/components/common-buttons/common-buttons-oilfield.component';
import { CommonSelectorOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/common-info-oilfield/components/common-selector/common-selector-oilfield.component';
import { EditButtonsOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/common-info-oilfield/components/edit-buttons/edit-buttons-oilfield.component';
import { OptionFormOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/common-info-oilfield/components/option-form/option-form-oilfield.component';
import { OptionObjectOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/common-info-oilfield/components/option-object/option-object-oilfield.component';
import { SensorsObjectOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/common-info-oilfield/components/sensors-object-oilfield/sensors-object-oilfield.component';
import { MapObjectOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/common-info-oilfield/components/map-object/map-object-oilfield.component';
import { BoreholesOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/boreholes-oilfield/boreholes-oilfield.component';
import { EventsOilfieldComponent } from '../pages/oilfield/components/oilfield-info-card/components/events-oilfield/events-oilfield.component';
import { IconsModule } from '../icons/icons.module';
import { OilfieldAddComponent } from '../pages/objects-list/components/oilfield-add/oilfield-add.component';
import { BoreholeAddComponent } from '../pages/objects-list/components/borehole-add/borehole-add.component';
import { BushAddComponent } from '../pages/objects-list/components/bush-add/bush-add.component';
import { GzuAddComponent } from '../pages/objects-list/components/gzu-add/gzu-add.component';
import { DnsAddComponent } from '../pages/objects-list/components/dns-add/dns-add.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { BgAddComponent } from '../pages/objects-list/components/bg-add/bg-add.component';
import { KtpAddComponent } from '../pages/objects-list/components/ktp-add/ktp-add.component';
import { CellComponent } from '../pages/oilfield/components/cell/cell.component';
import { CellDirective } from '../directives/cell.directive';
import { WaterPipelineAddComponent } from '../pages/objects-list/components/water-pipeline-add/water-pipeline-add.component';
import { OilPipelineAddComponent } from '../pages/objects-list/components/oil-pipeline-add/oil-pipeline-add.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

//Если компонента используется больше 1 раза,то заносим ей сюда
@NgModule({
  imports: [
    TaiCommonModule,
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    LeafletModule,
    TuiLinkModule,
    TuiInputNumberModule,
    MatFormFieldModule,
    MatSelectModule,
    TuiTooltipModule,
    TuiHintModule,
    MatMenuModule,
    TuiPaginationModule,
    IconsModule,
    TuiComboBoxModule,
    TuiLetModule,
    TuiErrorModule,
    ScrollingModule,
    TuiFilterByInputPipeModule,
  ],
  declarations: [
    ContentObjectComponent,
    CommonInfoObjectComponent,
    CommonInfoOilfieldComponent,
    CommonFormOilfieldComponent,
    CommonButtonsOilfieldComponent,
    CommonSelectorOilfieldComponent,
    EditButtonsOilfieldComponent,
    OptionFormOilfieldComponent,
    OptionObjectOilfieldComponent,
    SensorsObjectOilfieldComponent,
    MapObjectOilfieldComponent,
    SettingsObjectComponent,
    SensorsObjectComponent,
    SchemeOilfieldsComponent,
    ControlsComponent,
    AddObjectComponent,
    MapObjectComponent,
    SchemeEditorComponent,
    SchemeViewerComponent,
    AddObjectFieldsComponent,
    BoreholeFieldsComponent,
    InputFieldComponent,
    InputNumberFieldComponent,
    SelectFieldComponent,
    LocationFieldsComponent,
    CommonButtonsComponent,
    EditButtonsComponent,
    CommonSelectorComponent,
    CommonDeletePopupComponent,
    CommonFormComponent,
    OptionFormComponent,
    OptionObjectComponent,
    BoreholesObjectComponent,
    BoreholesOilfieldComponent,
    EventsOilfieldComponent,
    OilfieldAddComponent,
    BoreholeAddComponent,
    BushAddComponent,
    GzuAddComponent,
    DnsAddComponent,
    BgAddComponent,
    KtpAddComponent,
    CellComponent,
    CellDirective,
    WaterPipelineAddComponent,
    OilPipelineAddComponent,
    PowerLineAddComponent,
    PressureSensorAddComponent,
  ],
  exports: [
    ContentObjectComponent,
    CommonInfoObjectComponent,
    CommonInfoOilfieldComponent,
    CommonFormOilfieldComponent,
    CommonButtonsOilfieldComponent,
    CommonSelectorOilfieldComponent,
    EditButtonsOilfieldComponent,
    OptionFormOilfieldComponent,
    OptionObjectOilfieldComponent,
    SensorsObjectOilfieldComponent,
    MapObjectOilfieldComponent,
    SettingsObjectComponent,
    SensorsObjectComponent,
    SchemeOilfieldsComponent,
    ControlsComponent,
    AddObjectComponent,
    MapObjectComponent,
    AddObjectFieldsComponent,
    BoreholeFieldsComponent,
    InputFieldComponent,
    InputNumberFieldComponent,
    SelectFieldComponent,
    LocationFieldsComponent,
    CommonButtonsComponent,
    EditButtonsComponent,
    CommonSelectorComponent,
    CommonDeletePopupComponent,
    CommonFormComponent,
    OptionFormComponent,
    SchemeViewerComponent,
    OptionObjectComponent,
    BoreholesObjectComponent,
    BoreholesOilfieldComponent,
    EventsOilfieldComponent,
    OilfieldAddComponent,
    BoreholeAddComponent,
    BushAddComponent,
    GzuAddComponent,
    DnsAddComponent,
    BgAddComponent,
    KtpAddComponent,
    CellComponent,
    CellDirective,
    WaterPipelineAddComponent,
    OilPipelineAddComponent,
    PowerLineAddComponent,
    PressureSensorAddComponent,
  ],
})
export class SharedModule {}
