<ng-container *ngIf="columnName === EVENT">
  <div class="table-filter-filter">
    <span class="table-filter-text" [class.tui-skeleton]="isSkeletonActive">
      {{ getTitleName(columnName) }}
    </span>

    <tui-hosted-dropdown
      [content]="statusDropdown"
      [(open)]="filterModalWindows['dropdownStatusOpen']"
    >
      <button
        tuiIconButton
        appearance="transparent"
        size="m"
        icon="tuiIconFilter"
        type="button"
        class="table-filter-filter-btn"
      >
        <div
          class="marker"
          *ngIf="statusItems.form.controls['statusValue'].value?.length"
        ></div>
      </button>
    </tui-hosted-dropdown>
    <ng-template #statusDropdown>
      <form [formGroup]="statusItems.form">
        <tui-data-list>
          <tui-opt-group tuiMultiSelectGroup formControlName="statusValue">
            <button
              tuiOption
              size="s"
              [value]="status"
              *ngFor="let status of statusItems.values"
            >
              {{ status.name }}
            </button>
          </tui-opt-group>
        </tui-data-list>
      </form>
    </ng-template>
  </div>
</ng-container>
<ng-container *ngIf="columnName === DEPOSIT_NAME">
  <div class="table-filter-filter">
    <span class="table-filter-text" [class.tui-skeleton]="isSkeletonActive">
      {{ getTitleName(columnName) }}
    </span>

    <tui-hosted-dropdown
      [content]="depositNameDropdown"
      [(open)]="filterModalWindows['depositNameOpen']"
    >
      <button
        tuiIconButton
        appearance="transparent"
        size="m"
        icon="tuiIconFilter"
        type="button"
        class="table-filter-filter-btn"
      >
        <div
          class="marker"
          *ngIf="
            depositNameItems.form.controls['depositNameValue'].value?.length
          "
        ></div>
      </button>
    </tui-hosted-dropdown>
    <ng-template #depositNameDropdown>
      <form [formGroup]="depositNameItems.form">
        <tui-data-list>
          <tui-opt-group tuiMultiSelectGroup formControlName="depositNameValue">
            <button
              tuiOption
              size="s"
              [value]="deposit"
              *ngFor="let deposit of depositNameItems.values"
            >
              {{ deposit.name }}
            </button>
          </tui-opt-group>
        </tui-data-list>
      </form>
    </ng-template>
  </div>
</ng-container>
<ng-container *ngIf="columnName === NAME">
  <div class="table-filter-filter">
    <span class="table-filter-text" [class.tui-skeleton]="isSkeletonActive">
      {{ getTitleName(columnName) }}
    </span>
    <tui-hosted-dropdown
      [content]="nameDropdown"
      [(open)]="filterModalWindows['nameOpen']"
    >
      <button
        tuiIconButton
        appearance="transparent"
        size="m"
        icon="tuiIconFilter"
        type="button"
        class="table-filter-filter-btn"
      >
        <div
          class="marker"
          *ngIf="nameItems.form.controls['nameValue'].value?.length"
        ></div>
      </button>
    </tui-hosted-dropdown>
    <ng-template #nameDropdown>
      <form [formGroup]="nameItems.form">
        <tui-input style="width: 400px" formControlName="nameValue"></tui-input>
      </form>
    </ng-template>
  </div>
</ng-container>
<ng-container *ngIf="columnName === MESSAGE">
  <div class="table-filter-filter">
    <span class="table-filter-text" [class.tui-skeleton]="isSkeletonActive">
      {{ getTitleName(columnName) }}
    </span>

    <tui-hosted-dropdown
      [content]="statusMessagesDropdown"
      [(open)]="filterModalWindows['dropdownStatusMessagesOpen']"
    >
      <button
        tuiIconButton
        appearance="transparent"
        size="m"
        icon="tuiIconFilter"
        type="button"
        class="table-filter-filter-btn"
      >
        <div
          class="marker"
          *ngIf="
            statusMessagesItems.form.controls['statusMessagesValue'].value
              ?.length
          "
        ></div>
      </button>
    </tui-hosted-dropdown>
    <ng-template #statusMessagesDropdown>
      <form [formGroup]="statusMessagesItems.form">
        <tui-data-list>
          <tui-opt-group
            tuiMultiSelectGroup
            formControlName="statusMessagesValue"
          >
            <button
              tuiOption
              size="s"
              [value]="status"
              *ngFor="let status of statusMessagesItems.values"
            >
              {{ status.name }}
            </button>
          </tui-opt-group>
        </tui-data-list>
      </form>
    </ng-template>
  </div>
</ng-container>
<ng-container *ngIf="columnName === REACTION">
  <div class="table-filter-filter">
    <span class="table-filter-text" [class.tui-skeleton]="isSkeletonActive">
      {{ getTitleName(columnName) }}
    </span>

    <tui-hosted-dropdown
      [content]="reactionDropdown"
      [(open)]="filterModalWindows['dropdownReactOpen']"
    >
      <button
        tuiIconButton
        appearance="transparent"
        size="m"
        icon="tuiIconFilter"
        type="button"
        class="table-filter-filter-btn"
      >
        <div
          class="marker"
          *ngIf="reactionItems.form.controls['reactionValue'].value?.length"
        ></div>
      </button>
    </tui-hosted-dropdown>
    <ng-template #reactionDropdown>
      <form [formGroup]="reactionItems.form">
        <div
          style="
            display: flex;
            flex-direction: column;
            grid-gap: 10px;
            padding: 10px;
          "
        >
          <tui-radio-block
            size="s"
            formControlName="reactionValue"
            *ngFor="let reaction of reactionItems.values"
            [item]="reaction"
            >{{ reaction.name }}
          </tui-radio-block>
        </div>
      </form>
    </ng-template>
  </div>
</ng-container>
