import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  accessToken: string | null = null;

  private readonly TOKEN_KEY: string = 'accessToken';

  constructor(private cookieService: CookieService) {
    this.accessToken = this.cookieService.get(this.TOKEN_KEY);
  }

  setTokenToCookie(token: string, date: any): void {
    date = DateTime.fromISO(date);
    this.accessToken = token;
    this.cookieService.set(
      this.TOKEN_KEY,
      this.accessToken,
      new Date(date),
      '/'
    );
  }

  getToken(): string | null {
    return this.cookieService.get(this.TOKEN_KEY);
  }

  deleteToken(): void {
    this.accessToken = '';
    this.cookieService.delete(this.TOKEN_KEY, '/');
  }
}
