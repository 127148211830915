import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(public http: HttpClient) {}

  getReport(startDate: string, endDate: string, reportName: string) {
    return this.http
      .post(`${environment.apiUrl}report/${reportName}/`, {
        start_date: startDate,
        end_date: endDate,
      })
      .pipe(
        map((response: any) => {
          return response.data;
        })
      );
  }

  exportReport(startDate: string, endDate: string, reportName: string) {
    return this.http
      .post(`${environment.apiUrl}report/export/${reportName}/`, {
        start_date: startDate,
        end_date: endDate,
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
