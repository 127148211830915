import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { DeviceModel } from '../../models/device.model';
import { TypeTree } from '../../models/tree.model';

@Injectable({
  providedIn: 'root',
})
export class ObjectService {
  public parents: Array<{ value: string | number; label: string }> = [];

  public treeData: Array<TypeTree> = [];

  public selectedObject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public selectedObjectScheme: Subject<any> = new Subject<any>();

  public depositsList: TypeTree[] = [];

  public boreholesList: TypeTree[] = [];

  public treeIsDraggable: Subject<any> = new Subject<any>();

  public treeSkeletonVisible: BehaviorSubject<any> = new BehaviorSubject<any>(
    false
  );

  static expandedTrees: any = {};

  static expandedTreesArrowStates: any = {};

  static selectedObjectId: any = null;

  public createdObjectID: number | null = null;

  public updateTree: Subject<any> = new Subject<any>();

  public deviceList: Array<DeviceModel> = [];

  // Subject для информирования компонентов об изменениях в массиве объектов
  public objectListSubject: Subject<TypeTree[]> = new Subject<TypeTree[]>();

  // Массив для кэширования объектов
  private objectListCache: TypeTree[] = [];

  // Метод для получения объектов из кэша или с сервера
  get objectsList(): Promise<TypeTree[]> {
    return new Promise((resolve) => {
      if (this.objectListCache.length) {
        resolve(this.objectListCache);
      } else {
        return this.getObjectList().subscribe((res: TypeTree[]) => {
          resolve(res);
        });
      }
      return;
    });
  }

  // Массив для кэширования базовых станций
  private baseStationCache: any[] = [];

  // Метод для получения базовых станций из кэша или с сервера
  get baseStationsList(): Promise<any> {
    return new Promise((resolve) => {
      if (this.baseStationCache.length) {
        resolve(this.baseStationCache);
      } else {
        return this.baseStations().subscribe((res: any[]) => {
          resolve(res);
        });
      }
      return;
    });
  }

  get parentsList(): Promise<Array<{ value: string | number; label: string }>> {
    return new Promise((resolve) => {
      if (this.parents.length) {
        resolve(this.parents);
      } else {
        return this.getObjectList().subscribe((data: Array<TypeTree>) => {
          this.parents = data.reduce(
            (prevValue: any, currentValue: any) => {
              prevValue.push({
                value: currentValue.id,
                label: currentValue.name,
              });
              return prevValue;
            },
            [{ value: 'none', label: 'Родительский объект отсутствует' }]
          );
          resolve(this.parents);
        });
      }
      return;
    });
  }

  // Массив для кэширования кустов
  // private bushListCache: any[] = [];
  //
  // get bushList(): Promise<any> {
  //   return new Promise((resolve) => {
  //     if (this.bushListCache.length) {
  //       resolve(this.bushListCache);
  //     } else {
  //       return this.getBushesList().subscribe((res: any[]) => {
  //         resolve(res);
  //       });
  //     }
  //     return;
  //   });
  // }

  get devices(): Promise<DeviceModel[]> {
    return new Promise((resolve) => {
      if (this.deviceList.length) {
        resolve(this.deviceList);
      } else {
        return this.getDeviceList().subscribe((res: DeviceModel[]) => {
          resolve(res);
        });
      }
      return;
    });
  }

  get deposits(): Promise<TypeTree[]> {
    return new Promise((resolve) => {
      return this.getDepositsList().subscribe((res: TypeTree[]) => {
        resolve(res);
      });
    });
  }

  parentBoreholes(id: number): Promise<TypeTree[]> {
    return new Promise((resolve) => {
      return this.getInfoObject(id).subscribe((res: TypeTree) => {
        resolve(res.children as TypeTree[]);
      });
    });
  }

  get boreholes(): Promise<TypeTree[]> {
    return new Promise((resolve) => {
      return this.getBoreholesList().subscribe((res: TypeTree[]) => {
        resolve(res);
      });
    });
  }

  get gzu(): Promise<TypeTree[]> {
    return new Promise((resolve) => {
      return this.getGZUList().subscribe((res: TypeTree[]) => {
        resolve(res);
      });
    });
  }

  get dns(): Promise<TypeTree[]> {
    return new Promise((resolve) => {
      return this.getDNSList().subscribe((res: TypeTree[]) => {
        resolve(res);
      });
    });
  }

  get bg(): Promise<TypeTree[]> {
    return new Promise((resolve) => {
      return this.getBGList().subscribe((res: TypeTree[]) => {
        resolve(res);
      });
    });
  }

  constructor(public http: HttpClient) {}

  /* getObjectInfo(ObjectId: number) {
    return this.http.get(`${environment.apiUrl}objects/info/${ObjectId}/`);
  }*/
  baseStations(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}app/base_stations/`).pipe(
      map((response: any) => {
        this.baseStationCache = response.list;
        return response.list;
      })
    );
  }

  getDeviceList(): Observable<Array<DeviceModel>> {
    return this.http
      .get<Array<DeviceModel>>(`${environment.apiUrl}param/devices/`)
      .pipe(
        map((response: Array<DeviceModel>) => {
          this.deviceList = response;
          return response;
        })
      );
  }

  getBushList(): Observable<any[]> {
    return this.http
      .get<{ list: any[] }>(`${environment.apiUrl}objects/flat_list/bush/`)
      .pipe(
        map((response: { list: any[] }) => {
          return response.list;
        })
      );
  }

  getGZUList(): Observable<any[]> {
    return this.http
      .get<{ list: any[] }>(`${environment.apiUrl}objects/flat_list/gzu/`)
      .pipe(
        map((response: { list: any[] }) => {
          return response.list;
        })
      );
  }

  getDNSList(): Observable<any[]> {
    return this.http
      .get<{ list: any[] }>(`${environment.apiUrl}objects/flat_list/dns/`)
      .pipe(
        map((response: { list: any[] }) => {
          return response.list;
        })
      );
  }

  getBGList(): Observable<any[]> {
    return this.http
      .get<{ list: any[] }>(`${environment.apiUrl}objects/flat_list/bg/`)
      .pipe(
        map((response: { list: any[] }) => {
          return response.list;
        })
      );
  }

  getKTPList(): Observable<any[]> {
    return this.http
      .get<{ list: any[] }>(`${environment.apiUrl}objects/flat_list/ktp/`)
      .pipe(
        map((response: { list: any[] }) => {
          return response.list;
        })
      );
  }

  getInfoObject(id: number): Observable<TypeTree> {
    return this.http
      .get<{ info_object: TypeTree }>(
        `${environment.apiUrl}objects/deposit/info/${id}/`
      )
      .pipe(
        map((response: { info_object: TypeTree }) => {
          return response.info_object;
        })
      );
  }

  getDepositsList(): Observable<TypeTree[]> {
    return this.http
      .get<{ list: TypeTree[] }>(
        `${environment.apiUrl}objects/flat_list/deposits/`
      )
      .pipe(
        map((response: { list: TypeTree[] }) => {
          this.depositsList = response.list;
          return response.list;
        })
      );
  }

  getBoreholesList(): Observable<TypeTree[]> {
    return this.http
      .get<{ list: TypeTree[] }>(
        `${environment.apiUrl}objects/flat_list/boreholes/`
      )
      .pipe(
        map((response: { list: TypeTree[] }) => {
          this.boreholesList = response.list;
          return response.list;
        })
      );
  }

  getObjectByID(id: number): Observable<TypeTree> {
    return this.http
      .get<{ info_object: TypeTree }>(
        `${environment.apiUrl}objects/info/${id}/`
      )
      .pipe(
        map((response: { info_object: TypeTree }) => {
          return response.info_object;
        })
      );
  }

  objectCreate(body: any): Observable<any> {
    return this.http
      .post<{ created_object: TypeTree }>(
        `${environment.apiUrl}objects/create/`,
        body
      )
      .pipe(
        map((response: { created_object: TypeTree }) => {
          this.createdObjectID = response.created_object.id;
          this.objectListCache.push(response.created_object);
          this.objectListSubject.next(this.objectListCache);
          return response;
        })
      );
  }

  getObjectList(): Observable<Array<TypeTree>> {
    return this.http
      .get<{ list: Array<TypeTree> }>(`${environment.apiUrl}objects/list/`)
      .pipe(
        map((response: { list: Array<TypeTree> }) => {
          this.objectListCache = response.list;
          this.objectListSubject.next(this.objectListCache);
          return response.list;
        })
      );
  }

  getDepositList(): Observable<Array<TypeTree>> {
    return this.http
      .get<{ list: Array<TypeTree> }>(
        `${environment.apiUrl}objects/flat_list/deposits/`
      )
      .pipe(
        map((response: { list: Array<TypeTree> }) => {
          return response.list;
        })
      );
  }

  getDataToTree(): Observable<{ tree: Array<TypeTree> }> {
    if (this.treeData.length !== 0) {
      this.treeSkeletonVisible.next(true);
    }
    return this.http
      .get<{ tree: Array<TypeTree> }>(`${environment.apiUrl}objects/tree/`)
      .pipe(
        map((response) => {
          this.treeSkeletonVisible.next(false);
          this.treeData = response.tree;
          return response;
        })
      );
  }

  updateObject(updatedObject: TypeTree, updatedObjectId: number) {
    return this.http
      .post<{ updated_object: TypeTree }>(
        `${environment.apiUrl}objects/update/${updatedObjectId}/`,
        updatedObject
      )
      .pipe(
        map((response: { updated_object: TypeTree }) => {
          this.objectListCache = this.objectListCache.map((item: TypeTree) =>
            item.id === response.updated_object.id
              ? response.updated_object
              : item
          );
          this.objectListSubject.next(this.objectListCache);
          return response;
        })
      );
  }

  deleteObject(objectId: number) {
    return this.http
      .delete<{ deleted_object: TypeTree }>(
        `${environment.apiUrl}objects/delete/${objectId}/`
      )
      .pipe(
        map((response: { deleted_object: TypeTree }) => {
          this.objectListCache =
            this.objectListCache.filter((item) => {
              return item.id !== response.deleted_object.id;
            }) || [];
          this.objectListSubject.next(this.objectListCache);
          return response;
        })
      );
  }

  sortTree(data: any) {
    return this.http.post(`${environment.apiUrl}objects/tree/sort/`, data);
  }

  updateBoreholesSort(data: any) {
    return this.http.post(`${environment.apiUrl}objects/boreholes/sort/`, data);
  }

  getDepositInfoByID(id: number): Observable<TypeTree> {
    return this.http
      .get<{ info_object: TypeTree }>(
        `${environment.apiUrl}objects/deposit/info/${id}/`
      )
      .pipe(
        map((response: { info_object: TypeTree }) => {
          return response.info_object;
        })
      );
  }

  getBushesList(): Observable<any> {
    return this.http
      .get<{ info_object: any }>(`${environment.apiUrl}objects/flat_list/bush/`)
      .pipe(
        map((response: any) => {
          return response.list;
        })
      );
  }

  getGzuList(): Observable<any> {
    return this.http
      .get<{ info_object: any }>(`${environment.apiUrl}objects/flat_list/gzu/`)
      .pipe(
        map((response: any) => {
          return response.list;
        })
      );
  }

  getDnsList(): Observable<any> {
    return this.http
      .get<{ info_object: any }>(`${environment.apiUrl}objects/flat_list/dns/`)
      .pipe(
        map((response: any) => {
          return response.list;
        })
      );
  }

  getDebitHistory(objectId: number, dateStart: string, dateEnd: string) {
    return this.http
      .post<{ info_object: any }>(
        `${environment.apiUrl}objects/debit/${objectId}/`,
        { start_date: dateStart, end_date: dateEnd }
      )
      .pipe(
        map((response: any) => {
          return response.data;
        })
      );
  }

  // importWaterPipelinesFromFile(file: any) {
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   return this.http
  //     .post(`${environment.apiUrl}import/water_pipe/`, formData)
  //     .pipe(
  //       map((response: any) => {
  //         return response;
  //       })
  //     );
  // }

  // getWaterPipelineList(): Observable<any> {
  //   return this.http
  //     .get<{ list: TypePipelineModel[] }>(
  //       `${environment.apiUrl}water_pipeline/list/`
  //     )
  //     .pipe(
  //       map((response: any) => {
  //         return response.list;
  //       })
  //     );
  // }

  // createWaterPipeline(
  //   waterPipelineBody: TypePipelineListModel
  // ): Observable<TypePipelineListModel> {
  //   return this.http
  //     .post<{ info_object: any }>(
  //       `${environment.apiUrl}water_pipeline/create/`,
  //       waterPipelineBody
  //     )
  //     .pipe(
  //       map((response: any) => {
  //         return response;
  //       })
  //     );
  // }

  // updateWaterPipeline(
  //   waterPipelineId: number,
  //   waterPipelineBody: TypePipelineListModel
  // ) {
  //   return this.http.put(
  //     `${environment.apiUrl}water_pipeline/update/${waterPipelineId}/`,
  //     waterPipelineBody
  //   );
  // }

  // deleteWaterPipeline(pipeId: number) {
  //   return this.http.delete(
  //     `${environment.apiUrl}water_pipeline/delete/${pipeId}/`
  //   );
  // }

  // getWaterPipelineByID(id: number): Observable<any> {
  //   return this.http
  //     .get<{ info_object: TypePipelineModel }>(
  //       `${environment.apiUrl}water_pipeline/info/${id}/`
  //     )
  //     .pipe(
  //       map((response: any) => {
  //         return response;
  //       })
  //     );
  // }

  // pipelinesAnticorrosiveOptions = [
  //   { id: null, value: 'Не выбрано' },
  //   { id: false, value: 'Нет' },
  //   { id: true, value: 'Есть' },
  // ];
  //
  // importOilPipelinesFromFile(file: any) {
  //   const formData = new FormData();
  //   formData.append('file', file);
  //   return this.http
  //     .post(`${environment.apiUrl}import/oil_pipe/`, formData)
  //     .pipe(
  //       map((response: any) => {
  //         return response;
  //       })
  //     );
  // }

  // getOilPipelineList(): Observable<any> {
  //   return this.http
  //     .get<{ list: TypePipelineModel[] }>(
  //       `${environment.apiUrl}oil_pipeline/list/`
  //     )
  //     .pipe(
  //       map((response: any) => {
  //         return response.list;
  //       })
  //     );
  // }

  // createOilPipeline(
  //   oilPipelineBody: TypePipelineListModel
  // ): Observable<TypePipelineListModel> {
  //   return this.http
  //     .post<{ info_object: any }>(
  //       `${environment.apiUrl}oil_pipeline/create/`,
  //       oilPipelineBody
  //     )
  //     .pipe(
  //       map((response: any) => {
  //         return response;
  //       })
  //     );
  // }

  // updateOilPipeline(
  //   oilPipelineId: number,
  //   oilPipelineBody: TypePipelineListModel
  // ) {
  //   return this.http.put(
  //     `${environment.apiUrl}oil_pipeline/update/${oilPipelineId}/`,
  //     oilPipelineBody
  //   );
  // }

  // deleteOilPipeline(pipeId: number) {
  //   return this.http.delete(
  //     `${environment.apiUrl}oil_pipeline/delete/${pipeId}/`
  //   );
  // }

  // getOilPipelineByID(id: number): Observable<any> {
  //   return this.http
  //     .get<{ info_object: TypePipelineModel }>(
  //       `${environment.apiUrl}oil_pipeline/info/${id}/`
  //     )
  //     .pipe(
  //       map((response: any) => {
  //         return response;
  //       })
  //     );
  // }

  // getPipelineRelief(id: number, pipelineType: 'aqua' | 'oil'): Observable<any> {
  //   return this.http
  //     .get(
  //       `${environment.apiUrl}${
  //         pipelineType === 'oil' ? 'oil' : 'water'
  //       }_pipeline/elevation/${id}/ `
  //     )
  //     .pipe(
  //       map((response: any) => {
  //         return response.data[0]?.line || [];
  //       })
  //     );
  // }
}
