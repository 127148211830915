import { Action, createReducer, on } from '@ngrx/store';
import * as BoreholesActions from '../actions/borehole.actions';

export interface BoreholeState {
  list: any[];
}
export const initialState: BoreholeState = {
  list: [],
};

const boreholeReducer = createReducer(
  initialState,
  on(BoreholesActions.setBoreholes, (state, props) => ({
    ...state,
    list: props.payload,
  })),
  //eslint-disable-next-line import/namespace
  on(BoreholesActions.setStatusBorehole, (state, props) => {
    return {
      ...state,
      list: state.list.map((b: any) => {
        return {
          ...b,
          children: b.children.map((bb: any) =>
            bb.id === props.id ? { ...bb, status: props.status } : bb
          ),
        };
      }),
    };
  })
);

export function reducer(state: BoreholeState | undefined, action: Action) {
  return boreholeReducer(state, action);
}
