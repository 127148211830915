import { Component } from "@angular/core";

@Component({
  selector: "app-table-template",
  templateUrl: "./table-template.component.html",
  styleUrls: ["./table-template.component.less"],
})
export class TableTemplateComponent {
  public data = [
    {
      position: "value",
      newPositionz: "value",
      newPosition: "value",
    },
    {
      position: "value",
      newPosition: "<app-button>div</app-button>",
    },
    {
      position: "value",
      newPositionz: "value",
      newPosition: "value",
    },
  ];

  public label = [
    {
      name: "position",
      isSort: true,
      isFilter: true,
    },
    {
      name: "newPositionz",
      isSort: true,
      isFilter: true,
    },
    {
      name: "newPosition",
      isSort: true,
      isFilter: true,
    },
  ];

  public paginator = {
    currentPage: 1,
    countPages: 10,
    countItems: 5,
    listOfCountItems: [5, 10, 20, 30],
  };

  public filtersData = [
    {
      name: "object",
      title: "Объект",
      type: "checkbox",
      multi: true,
      values: [23, "string", "SOMETHING"],
    },
    {
      name: "obj4ect",
      title: "Объект",
      type: "checkbox",
      multi: true,
      values: [23],
    },
    {
      name: "object9",
      title: "Объект",
      type: "checkbox",
      multi: false,
      values: [23, "string", "SOMETHING"],
    },
    {
      name: "maps",
      title: "Карты",
      type: "select",
      multi: false,
      values: [23, "string1"],
    },
    {
      name: "notMaps34",
      title: "НеКарты",
      type: "radio",
      values: [45, "string1"],
    },
    {
      name: "notMaps",
      title: "НеКарты",
      type: "radio",
      values: [45],
    },
    {
      name: "notMaps2",
      title: "НеКарты2",
      type: "range",
      values: 23,
      min: 10,
      max: 80,
    },
    {
      name: "notMaps3",
      title: "НеКарты2",
      type: "range",
      values: 23,
      min: 10,
      max: 80,
    },
    {
      name: "notMaps5",
      title: "НеКарты2",
      type: "range",
      values: 23,
      min: 10,
      max: 80,
    },
  ];

  public sortData = [
    { name: "name", value: "value" },
    { name: "name1", value: "value1" },
  ];

  public bodyRequest: any = {
    pagination: {},
    filter: {},
    sort: {},
  };

  changeSettingsTable(settings: any, key: string) {
    this.bodyRequest[key] = settings;
    //  get request, body this.bodyRequest;
  }
}
