import { Component, Input, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { latLng, LayerGroup, marker, tileLayer } from 'leaflet';
import { TypeTree } from '../../../../../../../../../models/tree.model';

@Component({
  selector: 'app-map-object-oilfield',
  templateUrl: './map-object-oilfield.component.html',
  styleUrls: ['./map-object-oilfield.component.less'],
})
export class MapObjectOilfieldComponent implements OnInit {
  @Input() oilfield: any = null;

  public boreholesList: any[] = [];

  public averageLatLng: Array<number> = [0, 0];

  public marker: LayerGroup = L.layerGroup();

  public mapObject!: any;

  public options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        attribution: '',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      this.marker,
    ],
    zoom: 7,
    center: latLng(55.558741, 37.378847),
  };

  ngOnInit(): void {
    this.boreholesList = this.oilfield.children.filter((borehole: any) => {
      return (
        borehole.type === 'borehole' && borehole.latitude && borehole.longitude
      );
    });
    this.boreholesList.forEach((child: any) => {
      if (this.boreholesList.length > 1) {
        this.averageLatLng = [
          (this.averageLatLng[0] += child.latitude),
          (this.averageLatLng[1] += child.longitude),
        ];
      } else {
        this.averageLatLng = [
          (this.averageLatLng[0] = child.latitude),
          (this.averageLatLng[1] = child.longitude),
        ];
      }
      this.marker.addLayer(
        marker([child.latitude, child.longitude], {
          icon: L.divIcon({
            html: `<div class="object-map-balloon status_${this.getColorClassForBalloon(
              child
            )}" style="font-size: ${
              child.name.length > 6
                ? (5 / child.name.length) * 20 >= 10
                  ? 10
                  : (5 / child.name.length) * 20
                : 10
            }px">
                       <div class="object-content"><span>${
                         child.name
                       }</span><img src="/assets/icons-white/${this.getIconNameForBorehole(
              child
            )}.svg" alt=""></div></div>`,
          }),
        })
          .on('mouseover', (event: any) => {
            const layer = event.target;
            layer.setZIndexOffset(1000);
          })
          .on('mouseout', (event: any) => {
            const layer = event.target;
            layer.setZIndexOffset(0);
          })
      );
    });
    if (this.boreholesList.length > 1) {
      this.averageLatLng = [
        this.averageLatLng[0] / this.boreholesList.length,
        this.averageLatLng[1] / this.boreholesList.length,
      ];
    }
    if (this.boreholesList.length !== 0) {
      this.options.center = latLng(
        this.averageLatLng[0],
        this.averageLatLng[1]
      );
      if (this.mapObject) {
        this.mapObject.setView(
          latLng(this.averageLatLng[0], this.averageLatLng[1])
        );
      }
    }
  }

  getColorClassForBalloon(object: TypeTree) {
    if (object.status) {
      return object?.attributes?.type_mining === 'PPD'
        ? 'stop_ppd'
        : object.status;
    } else return 'default';
  }

  getIconNameForBorehole(borehole: TypeTree) {
    return borehole.attributes?.type_mining &&
      borehole.attributes?.type_mining !== 'REPAIR' &&
      borehole.attributes?.type_mining !== 'NONE'
      ? `borehole_${borehole.attributes?.type_mining}`
      : 'borehole';
  }

  onMapReady(map: any) {
    this.mapObject = map;
  }
}
