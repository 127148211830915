<tui-loader [overlay]="true" [showLoader]="visibleMap">
  <div class="pipelines-map">
    <div
      leaflet
      [leafletOptions]="options"
      (leafletMapReady)="onMapReady($event)"
      id="map"
    >
      <div [leafletLayer]="drawLayers"></div>
      <div
        leafletDraw
        *ngIf="isEdit"
       (leafletDrawCreated)="onDrawCreated($event)"
        (leafletDrawStart)="onDrawStart()"
       (leafletDrawEdited)="onDrawEdit($event)"
        (leafletDrawDeleted)="onDrawDelete()"
       [leafletDrawOptions]="drawOptions"
        (leafletDrawReady)="drawControlObject = $event"
      ></div>
    </div>
    <button
      *ngIf="pipeline !== null"
      tuiButton
      type="button"
      appearance="icon"
      class="show-relief-btn"
      (click)="onShowRelief()"
    >
      {{ showRelief ? "Скрыть" : "Показать" }} рельеф
    </button>
    <div class="{{showRelief ? 'relief_wrapper' : 'relief_wrapper closed'}}">
      <app-pipeline-relief
        *ngIf="showRelief"
        (clickOnPoint)="clickOnRelief($event)"
        [pipeline]="pipeline"
        [pipelineType]="pipelineType"
      ></app-pipeline-relief>
    </div>
  </div>
</tui-loader>
