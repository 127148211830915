export type TypePipelineListModel = {
  name: string;
  length: number;
  diameter: number;
  wall_thickness: number;
  material: string;
  is_anticorrosion: boolean;
  production_method: string;
  production_year: number;
  nominal_pressure: number;
  working_pressure: number;
  last_EPB: string;
  next_EPB: string;
  coordinates: TypeCoordsPipelineModel[];
};
export type TypeCoordsPipelineModel = {
  line: { latitude: number; longitude: number }[];
};

export interface TypePipelineModel extends TypePipelineListModel {
  id: number;
}

export enum PipelineColorType {
  DEFAULT = 'default',
  ELECTRICITY = 'electricity',
  AQUA = 'aqua',
  OIL = 'oil',
}
