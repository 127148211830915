<div
  [class]="
    !isEdit && !selectedObject.latitude && !selectedObject.longitude
      ? 'container-without-map'
      : 'container'
  "
>
  <form [formGroup]="form" class="form">
    <div class="inline-table">
      <div class="header">
        <span>Месторождение</span>
        <app-common-buttons-oilfield
          [isEdit]="isEdit"
          (handelCancel)="cancel()"
          (handelChange)="changeInput()"
        >
        </app-common-buttons-oilfield>
      </div>
      <div class="table">
        <div class="row" *ngFor="let row of formData">
          <div class="label">{{ row.name }}</div>
          <div class="value">
            <div
              *ngIf="isEdit && row.title === 'name'"
              class="label__edit-input"
            >
              <tui-input
                [tuiTextfieldMaxLength]="40"
                [formControlName]="'name'"
              >
              </tui-input>
              <tui-field-error formControlName="name"></tui-field-error>
            </div>
            <span
              *ngIf="
                (!isEdit && row.title === 'name') || row.title === 'children'
              "
              >{{ row.value }}</span
            >
            <div *ngIf="row.title === 'status'">
              <span class="value__status __ok">{{ row.value.ok }}</span>
              <span class="value__status __stop">{{ row.value.stop }}</span>
              <span class="value__status __no-connection">{{
                row.value["no_connection"]
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div
    *ngIf="isEdit || (selectedObject.latitude && selectedObject.longitude)"
    class="map"
  >
    <app-map-object-oilfield [oilfield]="selectedObject">
    </app-map-object-oilfield>
  </div>
</div>
