<div *ngIf="selectedObject && !isLoading">
  <app-primary-section>
    <h2 class="oilfield-popup__title">
      {{ selectedObject?.name || "object" }}
    </h2>
    <tui-tabs class="tabs">
      <button
        class="tabs__btns"
        tuiTab
        *ngFor="let tab of tabs"
        (click)="changeTabElement(tab)"
      >
        <div>
          {{ tab.title }}
        </div>
      </button>
    </tui-tabs>
  </app-primary-section>
  <app-primary-section *ngIf="activeItemIndex.url === 'info'">
    <div class="container__form">
      <app-common-info-oilfield
        [contextObject]="selectedObject"
        (handleChange)="getEditableDeposit($event)"
      >
      </app-common-info-oilfield>
    </div>
  </app-primary-section>
  <app-boreholes-oilfield
    *ngIf="activeItemIndex.url === 'boreholes'"
    [selectedDeposit]="selectedObject"
    (handleChange)="getEditableDeposit($event)"
  ></app-boreholes-oilfield>
  <div *ngIf="activeItemIndex.url === 'gzu'">gzu page</div>
  <div *ngIf="activeItemIndex.url === 'dns'">dns page</div>
  <div *ngIf="activeItemIndex.url === 'other-objects'">other objects page</div>
  <div *ngIf="activeItemIndex.url === 'events'">
    <app-events-oilfield [depositId]="selectedObject.id"></app-events-oilfield>
  </div>

  <app-primary-section *ngIf="activeItemIndex.url === 'scheme'">
    <app-scheme-viewer [contextObject]="selectedObject"></app-scheme-viewer>
  </app-primary-section>
</div>
<tui-loader
  *ngIf="isLoading"
  [size]="'xl'"
  style="margin-top: 30px"
>
</tui-loader>
