import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AddObjectType,
  InputType,
  SelectType,
} from '../../../../../../../models/content-field.model';

@Component({
  selector: 'app-add-object-fields',
  templateUrl: './add-object-fields.component.html',
  styleUrls: ['./add-object-fields.component.less'],
})
export class AddObjectFieldsComponent {
  // имя контрола формы
  @Input()
  formGroup!: FormGroup;

  /*селектор или инпут: по умолчанию инпут стоит
  имя контрола формы
  обязательное поле или нет
  только для селектора список выпадающих данных
  только  для инпута максимальная длина текста
  только  для инпута: выбор типа (числа или строка) по стандарту число*/
  /*{
    isSelector?: boolean;
    formControlNameItem: string;
    isRequired?: boolean;
    title: string;
    itemsList?: any;
    maxLengthText?: number;
    placeholderText?: string;
    isStringInput?: boolean;
  }*/
  @Input()
  fieldSettings: AddObjectType = {
    isSelector: false,
    formControlNameItem: '',
    isRequired: true,
    title: '',
    itemsList: [],
    maxLengthText: 50,
    placeholderText: '',
    isStringInput: false,
  };

  // возвращает данные только для селектора
  getSelectorSettings(fieldSettings: AddObjectType): SelectType {
    return {
      controlName: fieldSettings.formControlNameItem,
      title: fieldSettings.title,
      isRequired: fieldSettings?.isRequired || true,
      items: fieldSettings.itemsList || [],
    };
  }

  // возвращает данные только для инпутов
  getInputSettings(fieldSettings: AddObjectType): InputType {
    return {
      controlName: fieldSettings.formControlNameItem,
      placeholder: fieldSettings.placeholderText || '',
      title: fieldSettings.title,
      isRequired: fieldSettings.isRequired || true,
      maxLength: fieldSettings.maxLengthText || 50,
    };
  }
}
