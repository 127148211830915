<header class="tui-container_fullwidth container_expand">
  <div class="tui-row tui-row_adaptive row_expand">
    <div
      routerLink="/main/oilfield"
      style="cursor: pointer"
      class="tui-col_lg-1 tui-col_md-1 tui-col_xs-6"
    >
      <ng-container *ngIf="hasLogo; else withoutLogo">
        <div class="header__logo-template">
          <img
            class="header__logo"
            [src]="logoUrl"
            alt="logo"
            (click)="changeLogo()"
          />
          <div class="header__logo-remove" (click)="removeLogo()">
            <tui-svg src="tuiIconMinus"></tui-svg>
          </div>
        </div>
      </ng-container>
      <ng-template #withoutLogo>
        <div class="header__logo-template">
          <img
            class="header__logo"
            src="/assets/img/logo.svg"
            alt="logo"
            (click)="changeLogo()"
          />
        </div>
      </ng-template>
    </div>

    <div class="tui-col_lg-11 tui-col_md-11 tui-col_xs-6">
      <ng-content></ng-content>
    </div>
  </div>
</header>
