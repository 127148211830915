import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BoreholeStatusTypeOptions,
  OilfieldService,
} from '../../../../services/oilfield.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  // фильтрация по запущеных/остановленных/нет связи скважен
  public lastSelectedStatus: string | null = null;

  public oilfieldsIdAndNameList: { id: number; name: string }[] = [];

  public selectedOilfields: { id: number; name: string }[] = [];

  public boreholesStatusCounts: {
    ok: number;
    stop: number;
    no_connection: number;
  } = {
    ok: 0,
    stop: 0,
    no_connection: 0,
  };

  public destroyer = new Subject();

  constructor(private oilfieldService: OilfieldService) {}

  ngOnInit() {
    this.oilfieldService.oilfieldsIdAndNames
      .pipe(takeUntil(this.destroyer))
      .subscribe((oilfields: { id: number; name: string }[]) => {
        this.oilfieldsIdAndNameList = oilfields;

        if (!this.selectedOilfields.length) {
          this.selectedOilfields = oilfields;
          this.oilfieldService.selectedOilfield.next(this.selectedOilfields);
        }
      });
    this.oilfieldService.boreholesStatusCount
      .pipe(takeUntil(this.destroyer))
      .subscribe((boreholesStatusCount: any) => {
        this.boreholesStatusCounts = boreholesStatusCount;
      });
  }

  changeGraph(status: BoreholeStatusTypeOptions) {
    if (this.lastSelectedStatus === status) {
      this.lastSelectedStatus = null;
      return this.oilfieldService.boreholesStatus.next(null);
    }
    this.lastSelectedStatus = status;
    this.oilfieldService.boreholesStatus.next(status);
  }

  changeOils() {
    this.oilfieldService.selectedOilfield.next(this.selectedOilfields);
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
