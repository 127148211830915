<div *ngIf="contextObject">
  <app-primary-section>
    <form [formGroup]="objectInfoForm" class="form" *ngIf="editObjectMode">
      <div class="inline-table">
        <div class="header">
          <span>ЛЭП</span>
          <app-common-buttons-dns
            [isEdit]="editObjectMode"
            (handelCancel)="cancelInput()"
            (handelChange)="changeInput()"
            (handelSave)="save()"
          >
          </app-common-buttons-dns>
        </div>
        <div class="form-table">
          <div class="row">
            <div class="form-label">Название *</div>
            <div class="value">
              <tui-input
                style="width: 90%"
                formControlName="name"
                tuiTextfieldSize="m"
              >
                Укажите название
                <input maxlength="40" tuiTextfield type="text" />
              </tui-input>
              <tui-field-error formControlName="name"></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Назначение</div>
            <div class="value">
              <tui-input
                style="width: 90%"
                formControlName="purpose"
                tuiTextfieldSize="m"
              >
                Укажите назначение
                <input maxlength="40" tuiTextfield type="text" />
              </tui-input>
              <tui-field-error formControlName="purpose"></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Способ прокладки</div>
            <div class="value">
              <app-form-select-with-manual-input
                style="width: 90%"
                [items]="layingType ? layingType : []"
                [form]="objectInfoForm"
                [formControlNameSelect]="'laying_method'"
                [label]="'Укажите cпособ прокладки'"
                [visualRequired]="false"
              >
              </app-form-select-with-manual-input>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Напряжение, В</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                tuiTextfieldSize="m"
                formControlName="voltage"
              >
                Укажите напряжение
              </tui-input-number>
              <tui-field-error formControlName="voltage"></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Род тока</div>
            <div class="value">
              <app-form-select-with-manual-input
                style="width: 90%"
                [items]="currentType ? currentType : []"
                [form]="objectInfoForm"
                [formControlNameSelect]="'current_type'"
                [label]="'Укажите род тока'"
                [visualRequired]="false"
              >
              </app-form-select-with-manual-input>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Точка подключения</div>
            <div class="value">
              <tui-input
                style="width: 90%"
                formControlName="connection_point"
                tuiTextfieldSize="m"
              >
                Укажите точку подключения
                <input maxlength="40" tuiTextfield type="text" />
              </tui-input>
              <tui-field-error
                formControlName="connection_point"
              ></tui-field-error>
            </div>
          </div>
        </div>
        <span class="form-footer">* Обязательные для заполнения поля</span>
      </div>
    </form>
    <div class="inline-table" *ngIf="!editObjectMode">
      <div class="header">
        <span>ЛЭП</span>
        <app-common-buttons-dns
          [isEdit]="editObjectMode"
          (handelCancel)="cancelInput()"
          (handelChange)="changeInput()"
          (handelSave)="save()"
        >
        </app-common-buttons-dns>
      </div>
      <div class="table">
        <div class="row">
          <div class="label">Название</div>
          <div class="value">
            {{ contextObject.name }}
          </div>
        </div>
        <div class="row">
          <div class="label">Назначение</div>
          <div class="value">
            {{ contextObject?.purpose || "-" }}
          </div>
        </div>
        <div class="row">
          <div class="label">Способ прокладки</div>
          <div class="value">
            <td>{{ contextObject?.laying_method || "-" }}</td>
          </div>
        </div>
        <div class="row">
          <div class="label">Напряжение, В</div>
          <div class="value">
            <td>{{ contextObject?.voltage || "-" }}</td>
          </div>
        </div>
        <div class="row">
          <div class="label">Род тока</div>
          <div class="value">
            <td>{{ contextObject?.current_type || "-" }}</td>
          </div>
        </div>

        <div class="row">
          <div class="label">Точка подключения</div>
          <div class="value">
            <td>{{ contextObject?.connection_point || "-" }}</td>
          </div>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div
      *ngIf="
        (isAvailableCoords() && contextObject?.coordinates[0].line.length) ||
        editObjectMode
      "
    >
      <div class="oil-pipeline__map">
        <app-pipeline-map
          [pipelines]="electricityPipeline"
          [isEdit]="editObjectMode"
          (handleDraw)="getCoords($event)"
        >
        </app-pipeline-map>
      </div>
    </div>
    <ng-container *ngIf="!isAvailableCoords() && !editObjectMode">
      <div class="unavailable-map">
        <span class="unavailable-map__text">
          Точка на карте расположена вне области видимости карты, измените
          координаты в соответствии с допустимыми значениями, а именно: значения
          широты должны быть менее 90, но более -90, значения долготы должны
          быть менее 180, но более -180, чтобы отобразить точку на карте
        </span>
      </div>
    </ng-container>

    <ng-container
      *ngIf="!contextObject?.coordinates[0].line.length && !editObjectMode"
    >
      <div class="unavailable-map">
        <span class="unavailable-map__text">
          Координаты объекта не указаны
        </span>
      </div>
    </ng-container>
  </app-primary-section>
</div>
<div *ngIf="!contextObject" class="common-info__no-content">
  Выберите объект
</div>
