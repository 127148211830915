import { NgModule } from "@angular/core";
import { NormalLayoutComponent } from "./UI/layout/normal-layout/normal-layout.component";
import { HeaderComponent } from "./UI/header/header.component";
import { NavigationComponent } from "./UI/navigation/navigation.component";
import { TreeComponent } from "./UI/tree/tree.component";
import { PlusComponent } from "./UI/plus/plus.component";
import { TaiCommonModule } from "./tai-common.module";
import { BaseLayoutComponent } from "./UI/layout/base-layout/base-layout.component";
import { SoloLayoutComponent } from "./UI/layout/solo-layout/solo-layout.component";
import { DefaultLayoutComponent } from "./UI/layout/default-layout/default-layout.component";
import { GridLayoutComponent } from "./UI/layout/grid-layout/grid-layout.component";
import { LayoutComponent } from "./UI/layout/layout.component";
import { FilteringComponent } from "./UI/filtering/filtering.component";
import { TableComponent } from "./UI/table/table.component";
import { TableTemplateComponent } from "./UI/table/table-template/table-template.component";
import { SortingComponent } from "./UI/sorting/sorting.component";
import { SelectComponent } from "./UI/select/select.component";
import { ComponentsListComponent } from "./components-list/components-list.component";
import { FormComponent } from "./UI/form/form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormAttrComponent } from "./UI/form/form-attr/form-attr.component";
import { FormInputComponent } from "./UI/form/components/form-input/form-input.component";
import { FormTemplateComponent } from "./UI/form/form-template/form-template.component";
import { FormInputPasswordComponent } from "./UI/form/components/form-input-password/form-input-password.component";
import { FormSelectComponent } from "./UI/form/components/form-select/form-select.component";
import { FormCheckboxBlockComponent } from "./UI/form/components/form-checkbox-block/form-checkbox-block.component";
import { FormCheckboxLabeledComponent } from "./UI/form/components/form-checkbox-labeled/form-checkbox-labeled.component";
import { FormRadioBlockComponent } from "./UI/form/components/form-radio-block/form-radio-block.component";
import { FormRadioLabeledComponent } from "./UI/form/components/form-radio-labeled/form-radio-labeled.component";
import { FormInputNumberComponent } from "./UI/form/components/form-input-number/form-input-number.component";
import {
  TuiComboBoxModule,
  TuiFieldErrorPipeModule,
  TuiFilesModule,
  TuiFilterByInputPipeModule,
  TuiInputNumberModule,
  TuiPaginationModule,
} from "@taiga-ui/kit";
import { TuiFilterPipeModule, TuiLetModule } from "@taiga-ui/cdk";
import { TableDropdownButtonComponent } from "./UI/table/table-dropdown-button/table-dropdown-button.component";
import { SchemeComponent } from "./UI/scheme/scheme.component";
import { FormSelectSearchComponent } from "./UI/form/components/form-select-search/form-select-search.component";
import { TableButtonsComponent } from "./UI/table/table-buttons/table-buttons.component";
import { SearchComponent } from "./UI/search/search.component";
import { SearchSelectorComponent } from "./UI/search-selector/search-selector.component";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { OptionsScrollDirective } from "./UI/search-selector/options-scroll.directive";
import { TreeBranchComponent } from "./UI/tree/tree-branch/tree-branch.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { TableFilterComponent } from "./UI/table/table-filter/table-filter.component";
import { EventButtonsComponent } from "../app/pages/events/components/event-buttons/event-buttons.component";
import { WidgetComponent } from "./UI/widget/widget.component";
import { PrimarySectionComponent } from "./UI/sections/primary-section/primary-section.component";
import { SecondarySectionComponent } from "./UI/sections/secondary-section/secondary-section.component";
import { PrimaryTableComponent } from "./components/primary-table/primary-table.component";
import { TableSortComponent } from "./components/primary-table/table-sort/table-sort.component";
import { TableColumnSettingsComponent } from "./components/primary-table/table-column-settings/table-column-settings.component";
import { TuiTableModule } from "@taiga-ui/addon-table";
import { SecondaryTableComponent } from "./components/secondary-table/secondary-table.component";
import { TableAddObjectComponent } from "./components/primary-table/table-add-object/table-add-object.component";
import { DeleteDialogComponent } from "../app/pages/objects-list/components/delete-dialog/delete-dialog.component";
import { IconsModule } from "../app/icons/icons.module";
import { BoreholeCardComponent } from "../app/pages/object-cards/borehole-card/borehole-card.component";
import { BoreholeCardInfoComponent } from "../app/pages/object-cards/borehole-card/borehole-card-info/borehole-card-info.component";
import { BoreholeCardMapComponent } from "../app/pages/object-cards/borehole-card/borehole-card-info/borehole-card-map/borehole-card-map.component";
import { BoreholeCardEventsComponent } from "../app/pages/object-cards/borehole-card/borehole-card-events/borehole-card-events.component";
import { TuiErrorModule, TuiHintModule } from "@taiga-ui/core";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletDrawModule } from "@asymmetrik/ngx-leaflet-draw";
import { BushCardComponent } from "../app/pages/object-cards/bush-card/bush-card.component";
import { BoreholesBushComponent } from "../app/pages/object-cards/bush-card/components/boreholes-bush/boreholes-bush.component";
import { CommonInfoBushComponent } from "../app/pages/object-cards/bush-card/components/common-info-bush/common-info-bush.component";
import { EventsBushComponent } from "../app/pages/object-cards/bush-card/components/events-bush/events-bush.component";
import { CommonButtonsOilfieldComponent } from "../app/pages/object-cards/bush-card/components/common-info-bush/components/common-buttons/common-buttons-oilfield.component";
import { MapObjectOilfieldComponent } from "../app/pages/object-cards/bush-card/components/common-info-bush/components/map-object/map-object-oilfield.component";
import { GzuCardComponent } from "../app/pages/object-cards/gzu-card/gzu-card.component";
import { BoreholesGzuComponent } from "../app/pages/object-cards/gzu-card/components/boreholes-gzu/boreholes-gzu.component";
import { CommonInfoGzuComponent } from "../app/pages/object-cards/gzu-card/components/common-info-gzu/common-info-gzu.component";
import { GzuCardMapComponent } from "../app/pages/object-cards/gzu-card/components/common-info-gzu/components/gzu-card-map/gzu-card-map.component";
import { DnsCardComponent } from "../app/pages/object-cards/dns-card/dns-card.component";
import { CommonInfoDnsComponent } from "../app/pages/object-cards/dns-card/components/common-info-dns/common-info-dns.component";
import { DnsCardMapComponent } from "../app/pages/object-cards/dns-card/components/common-info-dns/components/dns-card-map/dns-card-map.component";
import { CommonButtonsDnsComponent } from "../app/pages/object-cards/dns-card/components/common-info-dns/components/common-buttons/common-buttons-dns.component";
import { EventsFilterComponent } from "../app/pages/events/components/events-filter/events-filter.component";
import { BoreholesBgComponent } from "../app/pages/object-cards/bg-card/components/boreholes-bg/boreholes-bg.component";
import { CommonInfoBgComponent } from "src/app/pages/object-cards/bg-card/components/common-info-bg/common-info-bg.component";
import { BgCardMapComponent } from "../app/pages/object-cards/bg-card/components/common-info-bg/components/bg-card-map/bg-card-map.component";
import { BgCardComponent } from "../app/pages/object-cards/bg-card/bg-card.component";
import { KtpCardMapComponent } from "../app/pages/object-cards/ktp-card/components/common-info-dns/components/ktp-card-map/ktp-card-map.component";
import { CommonInfoKtpComponent } from "../app/pages/object-cards/ktp-card/components/common-info-dns/common-info-ktp.component";
import { KtpCardComponent } from "../app/pages/object-cards/ktp-card/ktp-card.component";
import { BoreholeCardDebitHistoryComponent } from "../app/pages/object-cards/borehole-card/borehole-card-debit-history/borehole-card-debit-history.component";
import { WaterPipelineCardComponent } from "../app/pages/object-cards/water-pipeline-card/water-pipeline-card.component";
import { CommonInfoWaterPipelineComponent } from "../app/pages/object-cards/water-pipeline-card/components/common-info-water-pipeline/common-info-water-pipeline.component";
import { WaterPipelineCardMapComponent } from "../app/pages/object-cards/water-pipeline-card/components/common-info-water-pipeline/components/water-pipeline-card-map/water-pipeline-card-map.component";
import { ImportFileModalComponent } from "../app/pages/objects-list/components/import-file-modal/import-file-modal.component";
import { OilPipelineCardComponent } from "../app/pages/object-cards/oil-pipeline-card/oil-pipeline-card.component";
import { CommonInfoOilPipelineComponent } from "../app/pages/object-cards/oil-pipeline-card/components/common-info-water-pipeline/common-info-oil-pipeline.component";
import { OilPipelineCardMapComponent } from "../app/pages/object-cards/oil-pipeline-card/components/common-info-water-pipeline/components/water-pipeline-card-map/oil-pipeline-card-map.component";
import { PipelineMapComponent } from "./components/pipeline-map/pipeline-map.component";
import { PowerLineCardComponent } from "src/app/pages/object-cards/power-line-card/power-line-card.component";
import { CommonInfoPowerLineComponent } from "src/app/pages/object-cards/power-line-card/components/common-info-power-line/common-info-power-line.component";
import { PressureSensorCardComponent } from "src/app/pages/object-cards/pressure-sensor-card/pressure-sensor-card.component";
import { CommonInfoPressureSensorComponent } from "src/app/pages/object-cards/pressure-sensor-card/components/common-info-pressure-sensor/common-info-pressure-sensor.component";
import { FormSelectWithManualInputComponent } from "./UI/form/components/form-select-with-manual-input/form-select-with-manual-input";
import { ObjectCardMapComponent } from "src/app/pages/object-cards/object-card-map/object-card-map.component";
import { PipelineReliefComponent } from "./components/pipeline-relief/pipeline-relief.component";
import { PressureSensorEventsComponent } from "../app/pages/object-cards/pressure-sensor-card/components/pressure-sensor-events/pressure-sensor-events.component";
import { PressureSensorHistoryComponent } from "../app/pages/object-cards/pressure-sensor-card/components/pressure-sensor-history/pressure-sensor-history.component";
import { FormSelectWithSearchForHugeArraysComponent } from "./UI/form/components/form-select-with-search-for-huge-arrays/form-select-with-search-for-huge-arrays.component";
import { FormMultiselectWithSearchForHugeArraysComponent } from "./UI/form/components/form-multiselect-with-search-for-huge-arrays/form-multiselect-with-search-for-huge-arrays.component";

@NgModule({
  declarations: [
    OptionsScrollDirective,
    NormalLayoutComponent,
    HeaderComponent,
    NavigationComponent,
    TreeComponent,
    TreeBranchComponent,
    SearchComponent,
    PlusComponent,
    BaseLayoutComponent,
    SoloLayoutComponent,
    DefaultLayoutComponent,
    GridLayoutComponent,
    LayoutComponent,
    FilteringComponent,
    TableComponent,
    TableTemplateComponent,
    SortingComponent,
    SelectComponent,
    ComponentsListComponent,
    FormComponent,
    FormAttrComponent,
    FormInputComponent,
    FormTemplateComponent,
    FormInputPasswordComponent,
    FormSelectComponent,
    FormSelectSearchComponent,
    FormCheckboxBlockComponent,
    FormCheckboxLabeledComponent,
    FormRadioBlockComponent,
    FormRadioLabeledComponent,
    FormInputNumberComponent,
    TableDropdownButtonComponent,
    SchemeComponent,
    TableFilterComponent,
    TableButtonsComponent,
    SearchSelectorComponent,
    EventButtonsComponent,
    PrimarySectionComponent,
    SecondarySectionComponent,
    WidgetComponent,
    PrimaryTableComponent,
    TableSortComponent,
    TableColumnSettingsComponent,
    SecondaryTableComponent,
    TableAddObjectComponent,
    DeleteDialogComponent,
    BoreholeCardComponent,
    BoreholeCardInfoComponent,
    BoreholeCardMapComponent,
    BoreholeCardEventsComponent,
    BoreholeCardDebitHistoryComponent,
    BushCardComponent,
    BoreholesBushComponent,
    CommonInfoBushComponent,
    EventsBushComponent,
    CommonButtonsOilfieldComponent,
    MapObjectOilfieldComponent,
    GzuCardComponent,
    BoreholesGzuComponent,
    CommonInfoGzuComponent,
    GzuCardMapComponent,
    DnsCardComponent,
    CommonInfoDnsComponent,
    DnsCardMapComponent,
    CommonButtonsDnsComponent,
    EventsFilterComponent,
    BoreholesBgComponent,
    BgCardMapComponent,
    CommonInfoBgComponent,
    BgCardComponent,
    KtpCardMapComponent,
    CommonInfoKtpComponent,
    KtpCardComponent,
    WaterPipelineCardComponent,
    CommonInfoWaterPipelineComponent,
    WaterPipelineCardMapComponent,
    ImportFileModalComponent,
    OilPipelineCardComponent,
    CommonInfoOilPipelineComponent,
    OilPipelineCardMapComponent,
    PipelineMapComponent,
    PowerLineCardComponent,
    CommonInfoPowerLineComponent,
    PressureSensorCardComponent,
    CommonInfoPressureSensorComponent,
    FormSelectWithManualInputComponent,
    ObjectCardMapComponent,
    PipelineReliefComponent,
    PressureSensorEventsComponent,
    PressureSensorHistoryComponent,
    FormSelectWithSearchForHugeArraysComponent,
    FormMultiselectWithSearchForHugeArraysComponent,
  ],
  exports: [
    NormalLayoutComponent,
    HeaderComponent,
    NavigationComponent,
    TreeComponent,
    EventButtonsComponent,
    // TreeBranchComponent,
    SearchComponent,
    PlusComponent,
    BaseLayoutComponent,
    SoloLayoutComponent,
    DefaultLayoutComponent,
    GridLayoutComponent,
    LayoutComponent,
    FilteringComponent,
    TableComponent,
    TableTemplateComponent,
    SortingComponent,
    SelectComponent,
    ComponentsListComponent,
    FormComponent,
    FormAttrComponent,
    FormInputComponent,
    FormTemplateComponent,
    SchemeComponent,
    TableDropdownButtonComponent,
    TableButtonsComponent,
    SearchSelectorComponent,
    FormSelectSearchComponent,
    TableFilterComponent,
    PrimarySectionComponent,
    SecondarySectionComponent,
    SecondaryTableComponent,
    WidgetComponent,
    PrimarySectionComponent,
    PrimaryTableComponent,
    TableAddObjectComponent,
    DeleteDialogComponent,
    BoreholeCardComponent,
    BoreholeCardInfoComponent,
    BoreholeCardEventsComponent,
    BoreholeCardDebitHistoryComponent,
    BushCardComponent,
    BoreholesBushComponent,
    CommonInfoBushComponent,
    EventsBushComponent,
    GzuCardComponent,
    BoreholesGzuComponent,
    CommonInfoGzuComponent,
    DnsCardComponent,
    CommonInfoDnsComponent,
    DnsCardMapComponent,
    CommonButtonsDnsComponent,
    EventsFilterComponent,
    BoreholesBgComponent,
    BgCardMapComponent,
    CommonInfoBgComponent,
    BgCardComponent,
    KtpCardMapComponent,
    CommonInfoKtpComponent,
    KtpCardComponent,
    TaiCommonModule,
    WaterPipelineCardComponent,
    CommonInfoWaterPipelineComponent,
    WaterPipelineCardMapComponent,
    ImportFileModalComponent,
    OilPipelineCardComponent,
    CommonInfoOilPipelineComponent,
    OilPipelineCardMapComponent,
    PipelineMapComponent,
    PowerLineCardComponent,
    CommonInfoPowerLineComponent,
    PressureSensorCardComponent,
    CommonInfoPressureSensorComponent,
    FormSelectWithManualInputComponent,
    ObjectCardMapComponent,
    PipelineReliefComponent,
    PressureSensorEventsComponent,
    PressureSensorHistoryComponent,
    FormSelectWithSearchForHugeArraysComponent,
    FormMultiselectWithSearchForHugeArraysComponent,
  ],
  imports: [
    TaiCommonModule,
    FormsModule,
    ReactiveFormsModule,
    TuiInputNumberModule,
    TuiLetModule,
    TuiComboBoxModule,
    TuiFilterByInputPipeModule,
    TuiFilterPipeModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    MatAutocompleteModule,
    MatInputModule,
    DragDropModule,
    TuiPaginationModule,
    TuiTableModule,
    IconsModule,
    TuiHintModule,
    LeafletModule,
    LeafletDrawModule,
    TuiFieldErrorPipeModule,
    TuiErrorModule,
    TuiFilesModule,
  ],
})
export class CommonModule {}
