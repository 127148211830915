<div class="oilfield-add">
  <app-primary-section>
    <div class="oilfield-add__container">
      <h2 class="title">Добавить датчик давления</h2>
      <div class="cross" (click)="closeModal()">
        <img src="/assets/cross.png" alt="(X)" />
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="formPressureSensor">
      <div class="form__title">
        <h4>Общая информация</h4>
      </div>
      <div class="form__field">
        <span>Название *</span>
        <div class="input">
          <tui-input [tuiTextfieldMaxLength]="40" formControlName="name">
            <span class="input__placeholder">Укажите название</span>
          </tui-input>
          <tui-field-error formControlName="name"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Месторождение *</span>
        <div class="input">
          <tui-select
            formControlName="deposit"
            [valueContent]="typeValueContent"
          >
            <span *ngIf="!formPressureSensor.get('deposit')?.value"
              >Не выбрано</span
            >
            <span *ngIf="formPressureSensor.get('deposit')?.value"
              >Выберите месторождение
            </span>
            <tui-data-list-wrapper
              *tuiDataList
              [items]="depositList"
              [itemContent]="valueContent"
            >
            </tui-data-list-wrapper>
          </tui-select>
          <ng-template #typeValueContent let-data>
            {{ data.name }}
          </ng-template>
          <ng-template #valueContent let-data>
            {{ data.name }}
          </ng-template>
          <tui-field-error formControlName="deposit"></tui-field-error>
        </div>
      </div>

      <div class="form__field _multi">
        <span>Датчик давления *</span>
        <div class="debit-devices">
          <div class="device-row">
            <div class="input">
              <ng-container>
                <!--                  <tui-combo-box-->
                <!--                    [class.tui-skeleton]="skeletonVisible"-->
                <!--                    [class.tui-skeleton_rounded]="skeletonVisible"-->
                <!--                    *tuiLet="availablePressureDevices$ | async as items"-->
                <!--                    tuiTextfieldSize="m"-->
                <!--                    class="tui-space_vertical-1"-->
                <!--                    formControlName="device"-->
                <!--                    [stringify]="stringifyComboBox"-->
                <!--                    [valueContent]="-->
                <!--                      skeletonVisible ? loading : statusSelectContent-->
                <!--                    "-->
                <!--                  >-->
                <!--                    Выберите датчик давления-->
                <!--                    <input-->
                <!--                      tuiTextfield-->
                <!--                      placeholder="Введите название датчика..."-->
                <!--                      (input)="-->
                <!--                        onDebitSearchChange(extractValueFromEvent($event))-->
                <!--                      "-->
                <!--                    />-->

                <!--                    <tui-data-list-wrapper-->
                <!--                      *tuiDataList-->
                <!--                      [items]="items"-->
                <!--                      [itemContent]="statusSelectContent"-->
                <!--                    ></tui-data-list-wrapper>-->
                <!--                  </tui-combo-box>-->

                <!--                  Попытка реализовать виртуальный скролл-->
                <!--                  <tui-combo-box-->
                <!--                    [class.tui-skeleton]="skeletonVisible"-->
                <!--                    [class.tui-skeleton_rounded]="skeletonVisible"-->
                <!--                    *tuiLet="availablePressureDevices$ | async as items"-->
                <!--                    tuiTextfieldSize="m"-->
                <!--                    class="tui-space_vertical-1"-->
                <!--                    formControlName="device"-->
                <!--                    [stringify]="stringifyComboBox"-->
                <!--                    [valueContent]="-->
                <!--                      skeletonVisible ? loading : statusSelectContent-->
                <!--                    "-->
                <!--                  >-->
                <!--                    Выберите датчик давления-->
                <!--                    <input-->
                <!--                      tuiTextfield-->
                <!--                      placeholder="Введите название датчика..."-->
                <!--                      (input)="-->
                <!--                        onDebitSearchChange(extractValueFromEvent($event))-->
                <!--                      "-->
                <!--                    />-->
                <!--                                        <ng-container *tuiDataList>-->
                <!--                    <cdk-virtual-scroll-viewport-->
                <!--                      tuiScrollable-->
                <!--                      class="scroll"-->
                <!--                      [style.height.px]="220"-->
                <!--                      [itemSize]="44"-->
                <!--                    >-->
                <!--                                              <tui-data-list #list>-->
                <!--                                                <button-->
                <!--                                                  *cdkVirtualFor="let item of items"-->
                <!--                                                  tuiOption-->
                <!--                                                  [value]="item.title"-->
                <!--                                                >-->
                <!--                                                  {{ item.title }}-->
                <!--                                                </button>-->

                <!--                                                </tui-data-list>-->

                <!--                    </cdk-virtual-scroll-viewport>-->
                <!--                                        </ng-container>-->
                <!--                  </tui-combo-box>-->

                <app-form-select-with-search-for-huge-arrays
                  [form]="formPressureSensor"
                  [formControlNameSelect]="'device'"
                  [label]="'Выберите датчик давления'"
                  [placeholderMessage]="'Введите название датчика...'"
                  [visualRequired]="true"
                  [greyStyle]="true"
                  [items]="deviceList"
                >
                </app-form-select-with-search-for-huge-arrays>
              </ng-container>

              <tui-error
                *ngIf="
                  formPressureSensor.controls['device'].touched &&
                  !formPressureSensor.controls['device'].value
                "
                class="debit_device_error"
                error="Поле обязательно для заполнения"
              ></tui-error>
            </div>
            <div class="input grey-input">
              <tui-select
                [class.tui-skeleton]="skeletonVisible"
                [class.tui-skeleton_rounded]="skeletonVisible"
                *tuiLet="pressureSensorType as items"
                [tuiTextfieldLabelOutside]="false"
                [valueContent]="items ? stringify(items) : loading"
                formControlName="device_type"
                tuiTextfieldSize="m"
                class="b-form tui-space_vertical-1"
              >
                Параметр устройства
                <ng-template tuiDataList>
                  <tui-data-list *ngIf="items; else loading">
                    <button
                      *ngFor="let item of items"
                      tuiOption
                      [value]="item.id"
                    >
                      {{ item.name }}
                    </button>
                  </tui-data-list>
                </ng-template>
                <ng-template #loading>
                  <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
                </ng-template>
              </tui-select>
              <tui-error
                *ngIf="
                  formPressureSensor.controls['device_type'].touched &&
                  formPressureSensor.controls['device'].touched &&
                  !formPressureSensor.controls['device_type'].value
                "
                class="debit_device_error"
                error="Поле обязательно для заполнения"
              ></tui-error>
            </div>
            <!--       <tui-error
              *ngIf="
                (formSensors
                  .get('debits_params_devices')
                  ?.controls[i].get('debit_device_field').touched ||
                  formSensors
                    .get('debits_params_devices')
                    ?.controls[i].get('debit_device_id').touched) &&
                formSensors
                  .get('debits_params_devices')
                  ?.controls[i].get('debit_device_field').value === null
              "
              error="Поля обязательны для заполнения"
            ></tui-error>-->
          </div>
        </div>
      </div>
    </div>

    <!--      <div class="form__field">-->
    <!--        <span>Тип датчика *</span>-->
    <!--        <div class="input">-->
    <!--          <tui-select-->
    <!--            formControlName="device"-->
    <!--            [valueContent]="typeValueContent"-->
    <!--          >-->
    <!--            <span *ngIf="!formPressureSensor.get('device')?.value"-->
    <!--              >Не выбрано</span-->
    <!--            >-->
    <!--            <span *ngIf="formPressureSensor.get('device')?.value"-->
    <!--              >Выберите тип датчика</span-->
    <!--            >-->
    <!--            <tui-data-list-wrapper-->
    <!--              *tuiDataList-->
    <!--              [items]="deviceList"-->
    <!--              [itemContent]="valueContent"-->
    <!--            >-->
    <!--            </tui-data-list-wrapper>-->
    <!--          </tui-select>-->
    <!--          <ng-template #typeValueContent let-data>-->
    <!--            {{ data.title }}-->
    <!--          </ng-template>-->
    <!--          <ng-template #valueContent let-data>-->
    <!--            {{ data.title }}-->
    <!--          </ng-template>-->
    <!--          <tui-field-error formControlName="device"></tui-field-error>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="formPressureSensor">
      <div class="form__title">
        <h4>Пороги</h4>
      </div>

      <div class="form__field">
        <span>Указать пороги</span>
        <tui-toggle
          formControlName="limits"
          size="l"
          class="tui-space_left-2"
        ></tui-toggle>
      </div>

      <div formGroupName="limitsGroup">
        <div class="form__field">
          <span
            >Минимальный порог предупреждения
            {{ limitsFormActive ? "*" : "" }}</span
          >
          <div class="input">
            <tui-input-number formControlName="min_warning">
              <span class="input__placeholder"
                >Введите минимальный порог предупреждения</span
              >
            </tui-input-number>
            <tui-field-error formControlName="min_warning"></tui-field-error>
          </div>
        </div>

        <div class="form__field">
          <span
            >Минимальный порог аварии {{ limitsFormActive ? "*" : "" }}</span
          >
          <div class="input">
            <tui-input-number formControlName="min_error">
              <span class="input__placeholder"
                >Введите минимальный порог аварии</span
              >
            </tui-input-number>
            <tui-field-error formControlName="min_error"></tui-field-error>
          </div>
        </div>

        <div class="form__field">
          <span
            >Максимальный порог предупреждения
            {{ limitsFormActive ? "*" : "" }}</span
          >
          <div class="input">
            <tui-input-number formControlName="max_warning">
              <span class="input__placeholder"
                >Введите максимальный порог предупреждения</span
              >
            </tui-input-number>
            <tui-field-error formControlName="max_warning"></tui-field-error>
          </div>
        </div>

        <div class="form__field">
          <span
            >Максимальный порог аварии {{ limitsFormActive ? "*" : "" }}</span
          >
          <div class="input">
            <tui-input-number formControlName="max_error">
              <span class="input__placeholder"
                >Введите максимальный порог аварии</span
              >
            </tui-input-number>
            <tui-field-error formControlName="max_error"></tui-field-error>
          </div>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="formPressureSensor">
      <div class="form__title">
        <h4>Координаты</h4>
      </div>
      <div class="form__field">
        <span>Широта *</span>
        <div class="input">
          <tui-input-number
            [tuiTextfieldMaxLength]="50"
            [precision]="14"
            formControlName="latitude"
          >
            <span class="input__placeholder">Введите широту</span>
          </tui-input-number>
          <tui-field-error formControlName="latitude"></tui-field-error>
        </div>
      </div>
      <div class="form__field">
        <span>Долгота *</span>
        <div class="input">
          <tui-input-number
            [tuiTextfieldMaxLength]="50"
            [precision]="14"
            formControlName="longitude"
          >
            <span class="input__placeholder">Введите долготу</span>
          </tui-input-number>
          <tui-field-error formControlName="longitude"></tui-field-error>
        </div>
      </div>
      <div class="oilfield-add__map">
        <app-map-object
          [onClickOnMap]="onClickOnMap"
          [currentIdObject]="107"
          [latitude]="latitude"
          [longitude]="longitude"
        >
        </app-map-object>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="footer">
      <span>* Обязательные для заполнения поля</span>
      <div class="footer__buttons">
        <button (click)="submitForm()" class="footer-button _add">
          Добавить
        </button>
        <button (click)="closeDialog()" class="footer-button _cancel">
          Отменить
        </button>
      </div>
    </div>
  </app-primary-section>
  <ng-template #loading>
    <tui-loader class="loader"></tui-loader>
  </ng-template>
  <ng-template #statusSelectContent let-data>
    <div class="template">
      <div class="name">{{ data.name }}</div>
    </div>
  </ng-template>
</div>
