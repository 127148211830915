import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TabsModel } from 'src/models/tabs.model';
import { TypeTree } from '../../../../../models/tree.model';
import { ObjectService } from '../../../../services/object.service';
import { OilfieldService } from '../../../../services/oilfield.service';

@Component({
  selector: 'app-oilfield-info-card',
  templateUrl: './oilfield-info-card.component.html',
  styleUrls: ['./oilfield-info-card.component.less'],
})
export class OilfieldInfoCardComponent implements OnInit {
  public selectedObject?: any | TypeTree = null;

  public isObjFromMap: boolean = false;

  public tabs: Array<TabsModel> = [
    { url: 'info', title: 'Информация' },
    { url: 'boreholes', title: 'Скважины' },
    { url: 'events', title: 'События' },
    { url: 'scheme', title: 'Схема' },
  ];

  public activeItemIndex: TabsModel = {
    url: 'info',
    title: 'Общая информация',
  };

  public objectID: string = '';

  public isLoading: boolean = true;

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<any, any>,
    private objectsService: ObjectService,
    private oilfieldService: OilfieldService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.context.data.objectId) {
      this.objectsService
        .getDepositInfoByID(this.context.data.objectId)
        .subscribe((deposit: any) => {
          this.selectedObject = deposit;
          this.changeDetectorRef.markForCheck();
          this.isLoading = false;
        });
      this.isObjFromMap = this.context.data.isClickFromMap;
    }
  }

  changeTabElement(tab: any) {
    this.activeItemIndex = tab;
  }

  getEditableDeposit(isChanged: boolean) {
    if (isChanged) {
      this.isLoading = true;
      this.objectsService
        .getDepositInfoByID(this.context.data.objectId)
        .subscribe((deposit: TypeTree) => {
          this.selectedObject = deposit;
          this.context.data.update = true;
          this.changeDetectorRef.markForCheck();
          this.isLoading = false;
        });
      this.context.data.updated = true;
    } else {
      return;
    }
  }

  close() {
    this.context.completeWith(true);
  }
}
