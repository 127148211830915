import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  BADGE_TRANSLATE,
  TYPE_EVENT_TRANSLATE,
} from '../../../../const/app-consts';
import { ObjectService } from 'src/app/services/object.service';
import { Subject, takeUntil } from 'rxjs';
import { EventService } from '../../../../services/event.service';
import { PressureService } from '../../../../services/pressure.service';

type DataForSelectField = {
  id: number | string;
  name: string;
};

@Component({
  selector: 'app-events-filter',
  templateUrl: './events-filter.component.html',
  styleUrls: ['./events-filter.component.less'],
  animations: [
    trigger('expandedFilter', [
      state('initial', style({ right: -360 })),
      state('expanded', style({ right: 0 })),
      transition('initial <=> expanded', animate('0.3s')),
    ]),
  ],
})
export class EventsFilterComponent implements OnChanges, OnInit, OnDestroy {
  @HostListener('body:click', ['$event'])
  clickout(event: { path: any; target: any }) {
    if (
      !this.eRef.nativeElement.contains(event.target) &&
      !event.path?.some((p: any) => p.tagName === 'TUI-DATA-LIST')
    ) {
      if (this.isOpen && this.state === 'expanded') {
        this.isOpen = false;
        this.state = 'initial';
        this.closeFilter.emit();
      }
    }
  }

  @Input() isOpen: boolean = false;

  @Output() closeFilter: EventEmitter<any> = new EventEmitter();

  @Output() filterChanged: EventEmitter<any> = new EventEmitter();

  public availableStatus = [
    { value: 'ok', title: BADGE_TRANSLATE.ok },
    { value: 'stop', title: BADGE_TRANSLATE.stop },
    { value: 'no_connection', title: BADGE_TRANSLATE.no_connection },
  ];

  public availableStatusMessages: any = [
    { value: 'stop_to_ok', title: TYPE_EVENT_TRANSLATE.stop_to_ok },
    { value: 'ok_to_stop', title: TYPE_EVENT_TRANSLATE.ok_to_stop },
    {
      value: 'stop_to_no_connection',
      title: TYPE_EVENT_TRANSLATE.stop_to_no_connection,
    },
    {
      value: 'ok_to_no_connection',
      title: TYPE_EVENT_TRANSLATE.ok_to_no_connection,
    },
    {
      value: 'no_connection_to_stop',
      title: TYPE_EVENT_TRANSLATE.no_connection_to_stop,
    },
    {
      value: 'no_connection_to_ok',
      title: TYPE_EVENT_TRANSLATE.no_connection_to_ok,
    },
  ];

  public pressureStatusMessages: any = [
    { value: 'max_error', title: TYPE_EVENT_TRANSLATE.max_error },
    { value: 'max_warning', title: TYPE_EVENT_TRANSLATE.max_warning },
    { value: 'min_error', title: TYPE_EVENT_TRANSLATE.min_error },
    { value: 'min_warning', title: TYPE_EVENT_TRANSLATE.min_warning },
  ];

  public filterForm: any;

  public state = 'initial';

  public availableNamesObject: DataForSelectField[] = [];

  public availableNamesDeposits: DataForSelectField[] = [];

  public availableNamesPressures: DataForSelectField[] = [];

  public filterMode: string = 'borehole-events';

  private destroyer: Subject<any> = new Subject();

  private dataForBoreholeSelectFieldLoaded: boolean = false;

  private dataForPressureSelectFieldLoaded: boolean = false;

  constructor(
    private eRef: ElementRef,
    private formBuilder: FormBuilder,
    private objectService: ObjectService,
    private eventService: EventService,
    private pressureService: PressureService
  ) {}

  ngOnInit() {
    this.formCreator(this.filterMode);
    this.eventService.eventFilterMode
      .pipe(takeUntil(this.destroyer))
      .subscribe((mode: string) => {
        this.filterMode = mode;
        this.formCreator(this.filterMode);
      });
  }

  formCreator(mode: string) {
    if (mode === 'borehole-events') {
      this.filterForm = new FormGroup({
        status: new FormArray(
          this.availableStatus.map(() => new FormControl(false))
        ),
        event_type: new FormControl('Все'),
        type: new FormArray(
          this.availableStatusMessages.map(() => new FormControl(false))
        ),
        react_at: new FormControl('Все'),
        object_name: new FormControl(null),
        deposit_name: new FormControl(null),
      });
    }

    if (mode === 'pressure-events') {
      this.filterForm = new FormGroup({
        status: new FormArray(
          this.availableStatus.map(() => new FormControl(false))
        ),
        event_type: new FormControl('Все'),
        type: new FormArray(
          this.pressureStatusMessages.map(() => new FormControl(false))
        ),
        react_at: new FormControl('Все'),
        object_name: new FormControl(null),
        deposit_name: new FormControl(null),
      });
    }
  }

  async ngOnChanges() {
    setTimeout(() => {
      this.state = this.isOpen ? 'expanded' : 'initial';
    }, 100);
    // Загружаем данные для выпадающих списков только тогда, когда они нужны
    if (
      this.isOpen &&
      !this.dataForBoreholeSelectFieldLoaded &&
      this.filterMode === 'borehole-events'
    ) {
      this.availableNamesObject = this.prepareArrayForSelectField(
        await this.objectService.boreholes
      );
      this.availableNamesDeposits = this.prepareArrayForSelectField(
        await this.objectService.deposits
      );
      this.dataForBoreholeSelectFieldLoaded = true;
    }

    if (
      this.isOpen &&
      !this.dataForPressureSelectFieldLoaded &&
      this.filterMode === 'pressure-events'
    ) {
      this.availableNamesPressures = this.prepareArrayForSelectField(
        await this.pressureService.pressure
      );
      this.dataForPressureSelectFieldLoaded = true;
    }
  }

  close() {
    this.isOpen = false;
    this.state = 'initial';
    this.closeFilter.emit();
  }

  getStatusControls(): any {
    return this.filterForm.controls.status.controls;
  }

  getTypeControls(): any {
    return this.filterForm.controls.type.controls;
  }

  applyFilter() {
    if (this.filterMode === 'borehole-events') {
      this.boreholeFilterApply();
      this.close();
    }
    if (this.filterMode === 'pressure-events') {
      this.pressureFilterApply();
      this.close();
    }
  }

  pressureFilterApply() {
    const body: any = {
      type: undefined,
      react: undefined,
      object_name: undefined,
      deposit_name: undefined,
      message_type: undefined,
    };
    if (this.filterForm.get('object_name')?.value) {
      body.pressure_name = this.filterForm
        .get('object_name')
        ?.value.map((item: any) => item.id);
    }
    if (this.filterForm.get('deposit_name')?.value) {
      body.deposit_name = this.filterForm
        .get('deposit_name')
        ?.value.map((item: any) => item.id);
    }
    if (this.filterForm.value.type.some((v: boolean) => v)) {
      body.type = this.filterForm.value.type
        .map((v: boolean, index: number) =>
          v ? this.pressureStatusMessages[index].value : undefined
        )
        .filter((v: any) => v !== undefined);
    }
    if (['Да', 'Нет'].includes(this.filterForm.value.react_at)) {
      body.react = this.filterForm.value.react_at === 'Да';
    }
    this.filterChanged.next(body);
  }

  boreholeFilterApply() {
    const body: any = {
      status: undefined,
      type: undefined,
      react: undefined,
      object_name: undefined,
      deposit_name: undefined,
      message_type: undefined,
    };
    if (this.filterForm.get('object_name')?.value) {
      body.object_name = this.filterForm
        .get('object_name')
        ?.value.map((item: any) => item.id);
    }
    if (this.filterForm.get('deposit_name')?.value) {
      body.deposit_name = this.filterForm
        .get('deposit_name')
        ?.value.map((item: any) => item.id);
    }
    if (this.filterForm.value.status.some((v: boolean) => v)) {
      body.status = this.filterForm.value.status
        .map((v: boolean, index: number) =>
          v ? this.availableStatus[index].value : undefined
        )
        .filter((v: any) => v !== undefined);
    }
    if (this.filterForm.value.type.some((v: boolean) => v)) {
      body.type = this.filterForm.value.type
        .map((v: boolean, index: number) =>
          v ? this.availableStatusMessages[index].value : undefined
        )
        .filter((v: any) => v !== undefined);
    }
    if (['Да', 'Нет'].includes(this.filterForm.value.react_at)) {
      body.react = this.filterForm.value.react_at === 'Да';
    }
    this.filterChanged.next(body);
  }

  clearFilter() {
    this.formCreator(this.filterMode);
    this.filterChanged.next({});
  }

  prepareArrayForSelectField(array: any): DataForSelectField[] {
    return array.map((v: DataForSelectField) => ({
      id: v.id,
      name: v.name,
    }));
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
