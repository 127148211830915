import * as L from 'leaflet';

export class LeafletMapService {
  //TODO: метод вызывается уже 9 раз
  static get() {
    (L as any).drawLocal.draw.toolbar = {
      ...(L as any).drawLocal.draw.toolbar,
      actions: {
        title: 'Отменить рисование',
        text: 'Отмена',
      },
      finish: {
        title: 'Закончить рисунок',
        text: 'Закончить',
      },
      undo: {
        title: 'Удалить последнюю нарисованную точку',
        text: 'Удалить последнюю точку',
      },
      buttons: {
        polyline: 'Нарисовать линию',
        polygon: 'Draw a polygon',
        rectangle: 'Draw a rectangle',
        circle: 'Draw a circle',
        marker: 'Draw a marker',
        circlemarker: 'Draw a circlemarker',
      },
    };
    (L as any).drawLocal.edit.toolbar.buttons = {
      ...(L as any).drawLocal.edit.toolbar.buttons,
      edit: 'Редактировать линию',
      remove: 'Удалить линию',
      editDisabled: 'Нет линий для редактирования',
      removeDisabled: 'Нет линий для удаления',
    };
    (L as any).drawLocal.edit.toolbar.actions = {
      ...(L as any).drawLocal.edit.toolbar.actions,
      save: {
        title: 'Сохранить изменения',
        text: 'Сохранить',
      },
      cancel: {
        title: 'Отменить редактирование, отменить все изменения',
        text: 'Отмена',
      },
      clearAll: {
        title: 'Удалить все линии',
        text: 'Удалить всё',
      },
    };
    (L as any).drawLocal.draw.handlers.polyline.tooltip = {
      start: 'Нажмите, чтобы начать рисовать линию.',
      cont: 'Нажмите, чтобы продолжить рисование линии.',
      end: 'Нажмите на последнюю точку, чтобы закончить линию.',
    };
    (L as any).drawLocal.edit.handlers.edit = {
      tooltip: {
        text: 'Перетащите маркеры или маркеры для редактирования объектов.',
        subtext: 'Нажмите «Отмена», чтобы отменить изменения.',
      },
    };
    (L as any).drawLocal.edit.handlers.remove = {
      tooltip: {
        text: 'Нажмите на объект, который нужно удалить.',
      },
    };

    return L;
  }

  static translateZoomButtons(map: any) {
    map.zoomControl.remove();
    L.control
      .zoom({ zoomInTitle: 'Приблизить', zoomOutTitle: 'Отдалить' })
      .addTo(map);
  }

  static getBounds() {
    const southWest: any = L.latLng(90, -180);
    const northEast: any = L.latLng(-90, 180);
    const bounds: any = L.latLngBounds(southWest, northEast);
    return bounds;
  }
}
