import { takeUntil, Subject } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { TuiFileLike } from '@taiga-ui/kit';
import { PipelineService } from '../../../../services/pipeline.service';

@Component({
  selector: 'app-import-file-modal',
  templateUrl: './import-file-modal.component.html',
  styleUrls: ['./import-file-modal.component.less'],
})
export class ImportFileModalComponent implements OnInit, OnDestroy {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  //get it with true if delete dialog is opened in another popup
  //@Input() closeWithHandleClick: boolean = false;

  @Input() typeObject: 'water-pipeline' | 'oil-pipeline' = 'water-pipeline';

  @Input() handleSubmit: any = () => {};

  //закрытие попапа в попапе
  @Output() handleClick: EventEmitter<boolean> = new EventEmitter();

  // данные для удаления (id, имя и тип (переводы их в TRANSLATE_TYPES) из выбранного объекта)
  /*  @Input() data: null | {
    id: number | null;
    name: string;
    type: string;
  } = null;*/

  // сообщает родителю об успешном удалении объекта
  @Output() updateTableAfterImport: EventEmitter<boolean> = new EventEmitter();

  public link: string = 'Выберите файл';

  public label: string = ' или перетащите сюда (формат *gpx или *kml)';

  public isFileUploaded: boolean = false;

  readonly importForm: any = new FormGroup({
    control: new FormControl('', []),
  });

  readonly rejectedFiles$ = new Subject<TuiFileLike | null>();

  readonly loadingFiles$ = new Subject<TuiFileLike | null>();

  readonly loadedFiles$ = new Subject<TuiFileLike | null>();

  public rejectedFile: boolean = false;

  public destroyer: Subject<null> = new Subject();

  // readonly deletedFiles$ = new Subject<TuiFileLike | boolean>();

  /*  readonly loadedFiles$ = this.control.valueChanges.pipe(
    switchMap((file: any) => (file ? this.makeRequest(file) : of(null)))
  );*/

  constructor(private pipelineService: PipelineService) {}

  ngOnInit() {
    this.rejectedFiles$
      .pipe(takeUntil(this.destroyer))
      .subscribe((value: any) => {
        if (value) {
          this.rejectedFile = true;
        } else this.rejectedFile = false;
      });
    this.importForm
      .get('control')
      .valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((file: any) => {
        if (file) {
          this.loadingFiles$.next(file);
          this.loadedFiles$.next(file);
          this.loadingFiles$.next(null);
          this.isFileUploaded = true;
        } else {
          this.loadingFiles$.next(null);
          this.isFileUploaded = false;
        }
      });
  }

  onReject(file: TuiFileLike | readonly TuiFileLike[]): void {
    this.rejectedFiles$.next(file as TuiFileLike);
  }

  removeFile(): void {
    this.importForm.get('control').setValue(null);
    this.loadedFiles$.next(null);
  }

  clearRejected(): void {
    this.removeFile();
    this.rejectedFiles$.next(null);
  }

  makeRequest(file: TuiFileLike): any {
    if (this.typeObject == 'water-pipeline') {
      this.pipelineService.importWaterPipelinesFromFile(file).subscribe(
        () => {
          this.isFileUploaded = true;
          this.updateTableAfterImport.emit();
          this.closeModal();
        },
        () => {
          this.isFileUploaded = false;
        }
      );
    } else {
      this.pipelineService.importOilPipelinesFromFile(file).subscribe(
        () => {
          this.isFileUploaded = true;
          this.updateTableAfterImport.emit();
          this.closeModal();
        },
        () => {
          this.isFileUploaded = false;
        }
      );
    }
  }

  //удаление и закрытие
  importDataFromFile() {
    this.makeRequest(this.importForm.get('control').value);
  }

  //отмена
  cancel() {
    this.closeModal();
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
