import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  TuiContextWithImplicit,
  TuiDay,
  tuiPure,
  TUI_DATE_FORMAT,
  TUI_DATE_SEPARATOR,
} from '@taiga-ui/cdk';
import { TuiAlertService } from '@taiga-ui/core';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import {
  PipelineColorType,
  TypePipelineListModel,
} from '../../../../../../models/pipeline.model';
import {
  maxValueValidator,
  minValueValidator,
} from '../../../dns-card/components/common-info-dns/common-info-dns.component';
import { PipelineService } from '../../../../../services/pipeline.service';

function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control.value?.trim() || '';
    if ((control?.value?.length || '') !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

@Component({
  selector: 'app-common-info-water-pipeline',
  templateUrl: './common-info-water-pipeline.component.html',
  styleUrls: ['./common-info-water-pipeline.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
    { provide: TUI_DATE_FORMAT, useValue: `DMY` },
    { provide: TUI_DATE_SEPARATOR, useValue: `.` },
  ],
})
export class CommonInfoWaterPipelineComponent implements OnInit {
  public label: any[] = [
    {
      name: 'name',
      title: 'Название',
      isSort: true,
      isFilter: true,
    },
    {
      name: 'value',
      title: 'Значение',
      isSort: true,
      isFilter: true,
    },
  ];

  public data: any = null;

  public coords: { latitude: number; longitude: number }[] = [];

  public editObjectMode: boolean = false;

  //public mapCoords: any = {};

  public visibleMap: boolean = true;

  public parent: any | null = null;

  public onClickOnMap = new EventEmitter();

  public latitude: number = 0;

  public longitude: number = 0;

  public currentDay: any = new Date();

  public currentWeekEnd: number[] = [
    this.currentDay.getFullYear(),
    this.currentDay.getMonth(),
    this.currentDay.getDate(),
  ];

  public dayWeekAgo: any = new Date(
    this.currentDay.getTime() - 6 * 24 * 60 * 60 * 1000
  );

  public currentWeekStart: number[] = [
    this.dayWeekAgo.getFullYear(),
    this.dayWeekAgo.getMonth(),
    this.dayWeekAgo.getDate(),
  ];

  public tuiDayLastEPB: any = null;

  public tuiDayNextEPB: any = null;

  @Input()
  public contextObject: any = null;

  @Output() handleChange: EventEmitter<any> = new EventEmitter();

  public objectInfoForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    length: new FormControl('', []),
    diameter: new FormControl('', []),
    wall_thickness: new FormControl('', []),
    material: new FormControl('', []),
    is_anticorrosion: new FormControl('', []),
    production_method: new FormControl('', []),
    production_year: new FormControl('', []),
    nominal_pressure: new FormControl('', []),
    working_pressure: new FormControl('', []),
    last_EPB: new FormControl(
      new TuiDay(
        this.currentWeekStart[0],
        this.currentWeekStart[1],
        this.currentWeekStart[2]
      ),
      []
    ),
    next_EPB: new FormControl(
      new TuiDay(
        this.currentWeekEnd[0],
        this.currentWeekEnd[1],
        this.currentWeekEnd[2]
      ),
      []
    ),
  });

  public productionYearList: any = [];

  public waterPipeline: {
    id: number;
    line: { latitude: number; longitude: number }[];
    type: PipelineColorType.AQUA;
  }[] = [];

  // Массив вариантов антикоррозийной обработки
  public pipelinesAnticorrosiveOptions: any[] = [];

  private deletedCoords: boolean = false;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    private pipelineService: PipelineService
  ) {}

  async ngOnInit() {
    if (this.contextObject) {
      this.waterPipeline = [
        {
          id: this.contextObject.id,
          line: this.contextObject.coordinates[0].line,
          type: PipelineColorType.AQUA,
        },
      ];

      this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
      this.objectInfoForm
        .get('length')
        ?.setValue(this.contextObject?.length || '');
      this.objectInfoForm
        .get('diameter')
        ?.setValue(this.contextObject?.diameter || '');
      this.objectInfoForm
        .get('wall_thickness')
        ?.setValue(this.contextObject?.wall_thickness || '');
      this.objectInfoForm
        .get('material')
        ?.setValue(this.contextObject?.material || '');
      this.objectInfoForm
        .get('is_anticorrosion')
        ?.setValue(this.contextObject?.is_anticorrosion || '');
      this.objectInfoForm
        .get('production_method')
        ?.setValue(this.contextObject?.production_method || '');
      this.objectInfoForm
        .get('production_year')
        ?.setValue(this.contextObject?.production_year || '');
      this.objectInfoForm
        .get('nominal_pressure')
        ?.setValue(this.contextObject?.nominal_pressure || '');
      this.objectInfoForm
        .get('working_pressure')
        ?.setValue(this.contextObject?.working_pressure || '');

      if (this.contextObject?.last_EPB) {
        const arrDateLastEPB = this.contextObject?.last_EPB
          .split('-')
          .map((el: string) => {
            return +el;
          });
        this.tuiDayLastEPB = new TuiDay(
          arrDateLastEPB[0],
          arrDateLastEPB[1] - 1,
          arrDateLastEPB[2]
        );
        this.objectInfoForm.get('last_EPB')?.setValue(this.tuiDayLastEPB);
      } else {
        this.objectInfoForm.get('last_EPB')?.setValue(null);
      }
      if (this.contextObject?.next_EPB) {
        const arrDateNextEPB = this.contextObject?.next_EPB
          .split('-')
          .map((el: string) => {
            return +el;
          });
        this.tuiDayNextEPB = new TuiDay(
          arrDateNextEPB[0],
          arrDateNextEPB[1] - 1,
          arrDateNextEPB[2]
        );
        this.objectInfoForm.get('next_EPB')?.setValue(this.tuiDayNextEPB);
      } else {
        this.objectInfoForm.get('next_EPB')?.setValue(null);
      }
      this.generateProductionYearList();
    }

    // Забираем массив значений для поля антикоррозийной обработки из сервиса
    this.pipelinesAnticorrosiveOptions =
      this.pipelineService.pipelinesAnticorrosiveOptions;
    // Получаем значение из поля переданного объекта
    const anticorrosionValue = this.contextObject.is_anticorrosion;
    // Проверяем есть ли данное значение в списке опций
    const filtered = this.pipelinesAnticorrosiveOptions.filter(
      (option) => option.id === anticorrosionValue
    );
    // Добавляем проверки для значения null
    if (filtered.length) {
      // Если соответствующая опция найдена, то присваеваем ее значение полю
      this.objectInfoForm.controls.is_anticorrosion.setValue(filtered[0]);
    } else {
      // В противном случае мы столкнулись с объектом null, который не равен самому себе
      // Находим первую опцию, равную "Не выбрано"
      const firstOptrion = this.pipelinesAnticorrosiveOptions[0];
      // Присваеваем данную опцию полю
      this.objectInfoForm.controls.is_anticorrosion.setValue(firstOptrion);
    }
  }

  generateProductionYearList() {
    const max = new Date().getFullYear();
    const min = 1900;

    for (let i = max; i >= min; i--) {
      this.productionYearList.push(i);
    }
  }

  getCoords(coords: { latitude: number; longitude: number }[]) {
    this.coords = coords;
    if (!coords.length) {
      this.deletedCoords = true;
    } else {
      this.deletedCoords = false;
    }
  }

  isAvailableCoords(): boolean {
    const everyCoordIsAvailable = this.contextObject.coordinates[0].line.every(
      (coords: { latitude: number; longitude: number }) => {
        return (
          coords.latitude < 90 &&
          coords.latitude > -90 &&
          coords.longitude < 180 &&
          coords.longitude > -180
        );
      }
    );
    return everyCoordIsAvailable;
  }

  save() {
    if (!this.objectInfoForm.valid) {
      return this.objectInfoForm.markAllAsTouched();
    }
    if (
      this.deletedCoords ||
      (!this.coords.length && !this.contextObject?.coordinates[0].line)
    ) {
      this.notificationsService
        .open('Обозначьте нефтепровод на карте', {
          label: '',
          status: TuiNotification.Error,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      return;
    }
    if (this.objectInfoForm.dirty || this.coords.length) {
      let body: TypePipelineListModel = {
        ...this.contextObject,
        name: this.objectInfoForm.get('name').value,
        length: this.objectInfoForm.get('length')?.value || null,
        diameter: this.objectInfoForm.get('diameter').value || null,
        wall_thickness:
          this.objectInfoForm.get('wall_thickness')?.value || null,
        material: this.objectInfoForm.get('material')?.value || null,
        is_anticorrosion: this.objectInfoForm.value.is_anticorrosion.id,
        production_method:
          this.objectInfoForm.get('production_method')?.value || null,
        production_year:
          this.objectInfoForm.get('production_year')?.value || null,
        nominal_pressure:
          this.objectInfoForm.get('nominal_pressure')?.value || null,
        working_pressure:
          this.objectInfoForm.get('working_pressure')?.value || null,
        last_EPB:
          this.objectInfoForm
            .get('last_EPB')
            ?.value?.toString()
            .split('.')
            .reverse()
            .join('-') || null,
        next_EPB:
          this.objectInfoForm
            .get('next_EPB')
            ?.value?.toString()
            .split('.')
            .reverse()
            .join('-') || null,
        coordinates: [
          {
            line: this.coords.length
              ? this.coords
              : this.contextObject?.coordinates[0].line,
          },
        ],
      };

      this.pipelineService
        .updateWaterPipeline(this.contextObject.id, body)
        .subscribe((waterPipeline: any) => {
          this.contextObject = {
            ...waterPipeline,
          };
          this.notificationsService
            .open('Объект обновлен', {
              label: '',
              status: TuiNotification.Success,
              hasIcon: true,
              autoClose: true,
              hasCloseButton: true,
            })
            .subscribe();
          this.handleChange.emit();
          this.editObjectMode = false;
        });
    } else {
      this.editObjectMode = false;
    }
  }

  changeInput(): void {
    this.editObjectMode = !this.editObjectMode;
  }

  cancelInput(): void {
    this.objectInfoForm.reset();
    this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
    this.objectInfoForm
      .get('length')
      ?.setValue(this.contextObject?.length || '');
    this.objectInfoForm
      .get('diameter')
      ?.setValue(this.contextObject?.diameter || '');
    this.objectInfoForm
      .get('wall_thickness')
      ?.setValue(this.contextObject?.wall_thickness || '');
    this.objectInfoForm
      .get('material')
      ?.setValue(this.contextObject?.material || '');
    this.objectInfoForm
      .get('is_anticorrosion')
      ?.setValue(this.contextObject?.is_anticorrosion || '');
    this.objectInfoForm
      .get('production_method')
      ?.setValue(this.contextObject?.production_method || '');
    this.objectInfoForm
      .get('production_year')
      ?.setValue(this.contextObject?.production_year || '');
    this.objectInfoForm
      .get('nominal_pressure')
      ?.setValue(this.contextObject?.nominal_pressure || '');
    this.objectInfoForm
      .get('working_pressure')
      ?.setValue(this.contextObject?.working_pressure || '');

    this.objectInfoForm.get('last_EPB')?.setValue(this.tuiDayLastEPB);
    this.objectInfoForm.get('next_EPB')?.setValue(this.tuiDayNextEPB);
    this.coords = this.contextObject?.coordinates;
    this.editObjectMode = false;
  }

  @tuiPure
  stringify(items: readonly any[]): any {
    const map = new Map(
      items.map(({ id, name }) => [id, name] as [number, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }
}
