<div *ngIf="selectedObj" class="boreholes__content">
  <app-table
    *ngIf="data"
    [label]="label"
    [data]="data"
    [frontPagination]="isFrontPagination"
  >
  </app-table>
</div>
<ng-container *ngIf="!selectedObj">
  <div class="boreholes__no-content">Для данного объекта нет скважин</div>
</ng-container>
