import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as _ from "lodash";

type FilterItem = {
  name: string;
  title: string;
  type: string;
  multi?: boolean;
  values: any;
  min?: number;
  max?: number;
};

@Component({
  selector: "app-filtering",
  templateUrl: "./filtering.component.html",
  styleUrls: ["./filtering.component.less"],
})
export class FilteringComponent implements OnInit {
  /** listOfFilters
   * @description array from the filter list */
  @Input()
  listOfFilters: Array<FilterItem> = [];

  /** handleClick
   * @description return object for parents */
  @Output()
  handleClick: any = new EventEmitter<Event>();

  /** changeModalWindow
   * @description открытие или закрытие окна фильтрации */
  public changeModalWindow: boolean = false;

  /** changeModalWindow
   * @description opening or closing the filtering window  */
  public changedFilterFormWithoutNullElements: any = {};

  /** changeModalWindow
   * @description redesigned list with filters  */
  public changedFilterForm: any = {};

  public readonly SELECT: string = "select";

  public readonly RADIO: string = "radio";

  public readonly CHECKBOX: string = "checkbox";

  public readonly RANGE: string = "range";

  /** ngOnInit
   * @description  creating a new object based on what came from the parent */
  ngOnInit(): void {
    this.changedFilterForm = this.listOfFilters.reduce(
      (prevValue: any, filter: any) => {
        prevValue[filter.name] = filter.type === "checkbox" ? {} : null;
        return prevValue;
      },
      this.changedFilterForm
    );
  }

  /** saveFilterSettings
   * @description Remove null or empty objects from the list  */
  saveFilterSettings(): void {
    Object.keys(this.changedFilterForm).forEach((item: any) => {
      if (
        this.changedFilterForm[item] === null ||
        (this.isObject(this.changedFilterForm[item]) &&
          _.isEmpty(this.changedFilterForm[item]))
      ) {
        return;
      } else {
        this.changedFilterFormWithoutNullElements[item] =
          this.changedFilterForm[item];
      }
    });
    this.handleClick.emit(this.changedFilterFormWithoutNullElements);
  }

  /** changeModal
   * @description opening or closing a modal window  */
  changeModal(open: boolean) {
    this.changeModalWindow = open;
  }

  /** isObject
   * @return {string} value - object type
   * @param value
   * @description returns object type */
  isObject(value: any): boolean {
    return _.isObject(value);
  }

  setValueInFilterForm(item: any, filter: FilterItem) {
    this.changedFilterForm[filter.name] = item;
  }
}
