<div *ngIf="contextObject">
  <app-primary-section>
    <form [formGroup]="objectInfoForm" class="form" *ngIf="editObjectMode">
      <div class="inline-table">
        <div class="header">
          <span>Куст</span>
          <app-common-buttons-oilfield
            [isEdit]="editObjectMode"
            (handelCancel)="cancelInput()"
            (handelChange)="changeInput()"
            (handelSave)="save()"
          >
          </app-common-buttons-oilfield>
        </div>
        <div class="form-table">
          <div class="row">
            <div class="form-label">Название *</div>
            <div class="value">
              <tui-input
                style="width: 90%"
                formControlName="name"
                tuiTextfieldSize="m"
              >
                Введите название
                <input maxlength="41" tuiTextfield type="text" />
              </tui-input>
              <tui-field-error formControlName="name"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Месторождение *</div>
            <div class="value">
              <tui-select
                style="width: 90%"
                formControlName="deposit"
                [valueContent]="typeValueContent"
                tuiTextfieldSize="m"
              >
                <tui-data-list-wrapper
                  *tuiDataList
                  [items]="depositList"
                  [itemContent]="valueContent"
                >
                </tui-data-list-wrapper>
              </tui-select>
              <ng-template #typeValueContent let-data>
                {{ data.title }}
              </ng-template>
              <ng-template #valueContent let-data>
                {{ data.title }}
              </ng-template>
              <tui-field-error formControlName="deposit"></tui-field-error>
            </div>
          </div>
        </div>
        <span class="form-footer">* Обязательные для заполнения поля</span>
      </div>
    </form>
    <div class="inline-table" *ngIf="!editObjectMode">
      <div class="header">
        <span>Куст</span>
        <app-common-buttons-oilfield
          [isEdit]="editObjectMode"
          (handelCancel)="cancelInput()"
          (handelChange)="changeInput()"
          (handelSave)="save()"
        >
        </app-common-buttons-oilfield>
      </div>
      <div class="table">
        <div class="row">
          <div class="label">Название</div>
          <div class="value">
            {{ contextObject.name }}
          </div>
        </div>
        <div class="row">
          <div class="label">Месторждение</div>
          <div class="value">
            {{ contextObject?.parent?.name || "Не выбран" }}
          </div>
        </div>
        <div class="row">
          <div class="label">Всего скважин</div>
          <div class="value">
            {{ contextObject?.children?.length || 0 }}
          </div>
        </div>
        <div class="row">
          <div class="label">Скважин включено\остановлено\не в сети</div>
          <div class="value">
            <div>
              <span class="value__status __ok">{{
                contextObject.status?.boreholes?.ok || 0
              }}</span>
              <span class="value__status __stop">{{
                contextObject.status?.boreholes?.stop || 0
              }}</span>
              <span class="value__status __no-connection">{{
                contextObject.status?.boreholes?.no_connection || 0
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section
    *ngIf="
      contextObject?.children &&
      childrenCoordsAreSet(contextObject?.children) &&
      !editObjectMode
    "
  >
    <div
      *ngIf="
        isAvailableChildrenCoords(contextObject?.children);
        else unavailableChildrenCoords
      "
    >
      <app-map-object-oilfield [oilfield]="contextObject">
      </app-map-object-oilfield>
    </div>
    <ng-template #unavailableChildrenCoords>
      <div class="unavailable-map">
        <span class="unavailable-map__text">
          Точка на карте расположена вне области видимости карты, измените
          координаты в соответствии с допустимыми значениями, а именно: значения
          широты должны быть менее 90, но более -90, значения долготы должны
          быть менее 180, но более -180, чтобы отобразить точку на карте
        </span>
      </div>
    </ng-template>
  </app-primary-section>
</div>
<div *ngIf="!contextObject" class="common-info__no-content">
  Выберите объект
</div>
