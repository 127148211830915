import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MapService } from '../../../../services/map.service';

@Component({
  selector: 'app-header-map',
  templateUrl: './header-map.component.html',
  styleUrls: ['./header-map.component.less'],
})
export class HeaderMapComponent {
  @Input()
  form: FormGroup = new FormGroup({});

  @Output()
  handleClick: EventEmitter<string> = new EventEmitter();

  public listOfTypes: any = [
    { id: 'oilfield', title: 'Месторождение' },
    { id: 'borehole', title: 'Скважина' },
    { id: 'bush', title: 'Куст' },
    { id: 'gzu', title: 'ГЗУ' },
    { id: 'dns', title: 'ДНС' },
    { id: 'bg', title: 'БГ/УЗА' },
    { id: 'ktp', title: 'КТП' },
    { id: 'pressure', title: 'Датчик давления' },
  ];

  public listWithViewData: any = [];

  public listWithViewTypesData: any = this.listOfTypes;

  constructor(private mapService: MapService) {}

  openPopup(id: string) {
    this.handleClick.emit(id);
  }

  idsForClose: number[] = [];

  isDisable(item: any) {
    return this.idsForClose.includes(item.id);
  }
}
