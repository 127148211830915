<app-primary-section>
  <tui-loader
    class="oilfield"
    [showLoader]="isReloading"
    [inheritColor]="isReloading"
    [overlay]="isReloading"
  >
    <app-primary-table
      [isSort]="false"
      [columns]="tableColumns"
      [disabled]="false"
    >
      <tr *ngFor="let row of boreholes">
        <td (click)="openBorehole(row.id)">
          <div class="oilfield__name">
            <h3 class="object_name">{{ row.name }}</h3>
            <span class="coords">{{ getCurrentLatLon(row.latitude, row.longitude) }}</span>
          </div>
        </td>
        <td (click)="openBorehole(row.id)">
          {{ row?.oil_parent?.name || "Не выбрано" }}
        </td>
        <td (click)="openBorehole(row.id)">
          {{ TYPE_MINING[row.attributes?.type_mining] || "-" }}
        </td>
        <td>
          <tui-badge
            *ngIf="row.status; else emptyStatus"
            [value]="statusTitles[row.status]"
            [attr.data-status]="row.status"
            class="status"
          >
          </tui-badge>
          <ng-template #emptyStatus>-</ng-template>
        </td>
        <td (click)="openBorehole(row.id)">
          {{ getDebit(row?.attributes?.debit, row?.debit_device_field)}}
        </td>
        <td (click)="openBorehole(row.id)">{{ getRealDebit(row?.debit_device_value, row?.debit_device_field) }}</td>
        <!--<td (click)="openBorehole(row.id)">{{ (row?.debit_device_sum !== null &&
          row?.debit_device_sum !== undefined) ?
          (row?.debit_device_sum + ' м3') : '-' }}</td>-->
        <td (click)="openBorehole(row.id)">
          {{ getFreq(row) }}
        </td>
        <td (click)="openBorehole(row.id)">
          {{ getWorkTime(row.work_time) }}
        </td>
        <td class="actions">
          <tui-hosted-dropdown
            #dropdown
            [tuiDropdownMaxHeight]="500"
            [content]="content"
          >
            <button
              tuiIconButton
              appearance="flat"
              type="button"
              icon="tuiIconMoreHorLarge"
              [pseudoHovered]="dropdown.open || null"
            ></button>
          </tui-hosted-dropdown>
          <ng-template #content>
            <tui-data-list>
              <!--            <button (click)="deleteNode(item)" tuiOption size="l">-->
              <!--              Запустить-->
              <!--            </button>-->
              <button (click)="deleteNode(row)" tuiOption size="l">
                Удалить
              </button>
            </tui-data-list>
          </ng-template>
        </td>
      </tr>
    </app-primary-table>
  </tui-loader>
</app-primary-section>
<ng-template
  [tuiDialogOptions]="dialogDeleteObject"
  [(tuiDialog)]="openDeletePopup"
>
  <app-delete-dialog
    (updateTableAfterDelete)="updateTable($event)"
    [data]="selectedForDeleteObject"
    [closeWithHandleClick]="true"
    (handleClick)="closeDeleteDialog($event)"
  ></app-delete-dialog>
</ng-template>
