import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EventItem, EventModel, FilterOptions } from '../../models/event.model';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  constructor(public http: HttpClient) {}

  public newEvent = new Subject();

  public eventFilterMode = new Subject<string>();

  eventsList(
    page: number = 1,
    per_page: number = 10,
    sortOptions: FilterOptions = {}
  ): Observable<EventModel> {
    return this.http
      .post<EventModel>(
        `${environment.apiUrl}events/list/filter/?page=${page}&per_page=${per_page}`,
        sortOptions
      )
      .pipe(
        map((response: EventModel) => {
          return response;
        })
      );
  }

  eventUpdate(id: number): Observable<EventItem> {
    return this.http
      .put<EventItem>(`${environment.apiUrlEvents}events/react/${id}/`, {})
      .pipe(
        map((response: EventItem) => {
          return response;
        })
      );
  }

  readAllEvent(): Observable<boolean> {
    return this.http
      .put<{ success: boolean }>(
        `${environment.apiUrlEvents}events/react/all/`,
        {}
      )
      .pipe(
        map((response: { success: boolean }) => {
          return response.success;
        })
      );
  }

  readAllEventsByObject(objectId: number): Observable<boolean> {
    return this.http
      .put<{ success: boolean }>(
        `${environment.apiUrlEvents}events/react_object/${objectId}/`,
        {}
      )
      .pipe(
        map((response: { success: boolean }) => {
          return response.success;
        })
      );
  }

  readAllEventsByDeposit(objectId: number): Observable<boolean> {
    return this.http
      .put<{ success: boolean }>(
        `${environment.apiUrlEvents}events/react_deposit/${objectId}/`,
        {}
      )
      .pipe(
        map((response: { success: boolean }) => {
          return response.success;
        })
      );
  }

  getEventsByObjectId(
    objectId: number,
    page: number = 1,
    perPage: number = 50
  ) {
    return this.http
      .get<any>(
        `${environment.apiUrl}events/list/by_object_id/${objectId}/?page=${page}&per_page=${perPage}`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getEventsByDepositId(
    objectId: number,
    page: number = 1,
    perPage: number = 50
  ) {
    return this.http
      .get<any>(
        `${environment.apiUrl}events/list/by_deposit_id/${objectId}/?page=${page}&per_page=${perPage}`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
