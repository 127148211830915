import { NgModule } from '@angular/core';
import { MainComponent } from './main.component';
import { MainRoutingModule } from './main-routing.module';
import { CommonModule as MnktCommonModule } from '../../../common/common.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TuiButtonModule, TuiErrorModule, TuiHintModule } from '@taiga-ui/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TuiSidebarModule } from '@taiga-ui/addon-mobile';
import { MenuComponent } from '../../components/sidebar/components/menu/menu.component';
import { TaiCommonModule } from '../../../common/tai-common.module';
import { MatMenuModule } from '@angular/material/menu';
import { TuiAvatarModule, TuiToggleModule } from '@taiga-ui/kit';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SharedModule } from '../../components/shared.module';
import { MainHeaderComponent } from '../../components/header/header.component';
import { DrawColorLinesComponent } from '../map/components/draw-color-lines/draw-color-lines.component';
import { ObjectInfoCardComponent } from '../../components/sidebar/object-info-card/object-info-card.component';
import { OilfieldInfoCardComponent } from '../oilfield/components/oilfield-info-card/oilfield-info-card.component';
import { OilfieldInfoPageComponent } from '../oilfield/components/oilfield-info-card/page/oilfield-info-page.component';
import { HeaderMapComponent } from '../map/components/header-map/header-map.component';
import { TablerIconsModule } from 'angular-tabler-icons';
import { UsersComponent } from '../users/users.component';
import { AddUserComponent } from '../users/components/add-user/add-user.component';
import { EditUserComponent } from '../users/components/edit-user/edit-user.component';
import { ReportsComponent } from '../reports/reports.component';
import { ReportBarComponent } from '../reports/report-bar/report-bar.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { DashboardComponent } from '../oilfield/components/dashboard/dashboard.component';

@NgModule({
  imports: [
    MnktCommonModule,
    MainRoutingModule,
    DragDropModule,
    TuiButtonModule,
    PerfectScrollbarModule,
    TuiSidebarModule,
    TaiCommonModule,
    MatMenuModule,
    TuiAvatarModule,
    TuiHintModule,
    MatCheckboxModule,
    SharedModule,
    TablerIconsModule,
    TuiToggleModule,
    TuiLetModule,
    TuiErrorModule,
  ],
  providers: [],
  declarations: [
    MainComponent,
    DrawColorLinesComponent,
    SidebarComponent,
    MenuComponent,
    MainHeaderComponent,
    ObjectInfoCardComponent,
    OilfieldInfoCardComponent,
    OilfieldInfoPageComponent,
    HeaderMapComponent,
    UsersComponent,
    AddUserComponent,
    EditUserComponent,
    ReportsComponent,
    ReportBarComponent,
    DashboardComponent,
  ],
  exports: [
    MainComponent,
    DrawColorLinesComponent,
    SidebarComponent,
    MenuComponent,
    MainHeaderComponent,
    ObjectInfoCardComponent,
    OilfieldInfoCardComponent,
    OilfieldInfoPageComponent,
    HeaderMapComponent,
  ],
})
export class MainModule {}
