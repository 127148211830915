<div class="grid-layout">
  <div class="grid-layout__navigation">
    <app-header>
      <app-navigation></app-navigation>
    </app-header>
  </div>
  <div class="grid-layout__container">
    <div class="grid-layout__container-cell">
      <app-plus></app-plus>
    </div>
    <div class="grid-layout__container-cell">
      <app-plus></app-plus>
    </div>
    <div class="grid-layout__container-cell">
      <app-plus></app-plus>
    </div>
    <div class="grid-layout__container-cell">
      <app-plus></app-plus>
    </div>
  </div>
</div>
