import { TypeObjectModel } from '../../models/type-object.model';
import { TypeNavigation } from '../../models/navigation.model';
import { ObjectType } from '../../models/object-type';
import { ReportsSideBarModel } from '../../models/reports.model';
import { MiningType } from '../../models/content-field.model';
import { SimpleDirectoriesObjectType } from '../../models/directories.model';
import { TabsObject } from '../../models/tabs.model';

export const TYPE_OBJECTS: TypeObjectModel[] = [
  { value: 100, title: 'Месторождение', type: ObjectType.DEPOSIT },
  { value: 101, title: 'ДНС', type: ObjectType.DNS },
  { value: 102, title: 'ГЗУ', type: ObjectType.GZU },
  { value: 103, title: 'БГ/УЗА', type: ObjectType.BG },
  { value: 104, title: 'Куст', type: ObjectType.BUSH },
  { value: 105, title: 'Скважина', type: ObjectType.BOREHOLE },
  { value: 106, title: 'КТП', type: ObjectType.KTP },
  { value: 107, title: 'Датчик давления', type: ObjectType.PRESSURE },
];
export const TRANSLATE_TYPES_ICON_IMAGE = {
  100: 'deposit',
  105: 'borehole',
  102: 'gzu',
  101: 'dns',
  103: 'bg',
  104: 'bush',
  106: 'ktp',
  107: 'pressure',
};

export const TYPE_MINING_VALUE: MiningType = [
  {
    value: 'PPD',
    title: 'ППД',
  },
  {
    value: 'SHGN',
    title: 'ШГН',
  },
  {
    value: 'SHVN',
    title: 'ШВН',
  },
  {
    value: 'ECN',
    title: 'ЭЦН',
  },
  {
    value: 'NONE',
    title: 'Нет',
  },
  {
    value: 'REPAIR',
    title: 'Ремонт',
  },
];

export const LIST_OF_NAV_ITEMS: TypeNavigation[] = [
  { id: 'oilfield', title: 'Месторождения', active: false },
  { id: 'objects', title: 'Объекты', active: true },
  //{ id: 'main', active: false },
  { id: 'map', title: 'Карта', active: false },
  //{
  //id: 'scheme',
  //multiButton: [
  //  { id: 'scheme-oilfields', title: 'Месторождений', active: false },
  //  { id: 'scheme-oilfield-pads', title: 'Кустов', active: false },
  //  { id: 'scheme-dns', title: 'ДНС', active: false },
  //],
  //title: 'Схемы',
  //active: false,
  //},
  //{ id: 'equipment', title: 'Оборудование', active: false },
  { id: 'events', title: 'События', active: false },
  //{ id: 'reports', title: 'Отчеты', active: false },
  { id: 'directories/params', title: 'Справочники', active: false },
  { id: 'reports', title: 'Отчеты', active: false },
  { id: 'users', title: 'Пользователи', active: false },
];
export const OBJECTS_DEBIT: any = {
  BEFORE_15: 15,
  BEFORE_10: 10,
  BEFORE_5: 5,
  AFTER_15: 15,
};
export const OBJECTS_DEBIT_LIST: Array<string> = [
  'BEFORE_15',
  'BEFORE_10',
  'BEFORE_5',
  'AFTER_15',
];
export const TYPE_SYSTEM: Array<SimpleDirectoriesObjectType> = [
  { value: 1, label: 'nekta' },
];
export const TYPE_MINING: any = {
  PPD: 'ППД',
  SHGN: 'ШГН',
  SHVN: 'ШВН',
  ECN: 'ЭЦН',
  NONE: 'Нет',
  REPAIR: 'Ремонт',
};
export const TYPE_MINING_LIST: any = [
  {
    value: 'PPD',
    title: 'ППД',
  },
  {
    value: 'SHGN',
    title: 'ШГН',
  },
  {
    value: 'SHVN',
    title: 'ШВН',
  },
  {
    value: 'ECN',
    title: 'ЭЦН',
  },
];
export const ACTION_BUTTONS = [
  { value: 1, label: 'Редактировать' },
  { value: 2, label: 'Удалить' },
];

/*export const DIRECTORISE_SIDEBAR_DATA = [
  {
    name: 'Справочник параметров',
    params: [
      {
        id: 1,
        name: 'давление',
        message_type_id: 1,
        external_name_id: 1,
        system_type: 1,
      },
      {
        id: 2,
        name: 'ускорение',
        message_type_id: 4,
        external_name_id: 1,
        system_type: 1,
      },
      {
        id: 3,
        name: 'пуля',
        message_type_id: 1,
        external_name_id: 3,
        system_type: 1,
      },
      {
        id: 4,
        name: 'мнкт',
        message_type_id: 1,
        external_name_id: 2,
        system_type: 1,
      },
    ],
  },
];*/
export const OBJECT_FIELDS_TRANSLATES: any = {
  id: 'ID',
  name: 'Название',
  parent_id: 'Родительский объект',
  latitude: 'Широта',
  longitude: 'Долгота',
  type: 'Тип',
  status: 'Статус',
  debit: 'Режимный дебит',
  type_mining: 'Тип добычи',
  water_cut: 'Обводнённость',
};

export const OILFIELD_FIELDS_TRANSLATES: any = {
  name: 'Название',
  children: 'Всего скважин',
  status: 'Скважин включено\\остановлено\\не в сети',
};

export const PAGINATOR_EVENT = {
  currentPage: 1,
  countPages: 10,
  countItems: 10,
  listOfCountItems: [10, 20, 50, 100],
};

//last four types relate to devices, before them types relate to borehole
export const TYPE_EVENT_TRANSLATE = {
  stop_to_ok: 'Скважина запущена',
  ok_to_stop: 'Скважина остановлена',
  stop_to_no_connection:
    'Потеряна связь (до потери связи скважина была остановлена)',
  ok_to_no_connection:
    'Потеряна связь (до потери связи скважина была запущена)',
  no_connection_to_stop: 'Связь восстановлена (скважина остановлена)',
  no_connection_to_ok: 'Связь восстановлена (скважина запущена)',
  max_error: 'Выход за максимальный порог аварии',
  max_warning: 'Выход за максимальный порог предупреждения',
  min_error: 'Выход за минимальный порог аварии',
  min_warning: 'Выход за минимальный порог предупреждения',
  normal: 'Давление в норме',
};
export const PATH_DELETE_ICON =
  "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

export const STATUS_EVENT_TRANSLATE = {
  stop_to_ok: 'ok',
  ok_to_stop: 'stop',
  stop_to_no_connection: 'no_connection',
  ok_to_no_connection: 'no_connection',
  no_connection_to_stop: 'stop_middle',
  no_connection_to_ok: 'ok_middle',
};
export const STATUS_EVENT_BADGE = {
  no_connection: 'neutral',
  ok: 'success',
  stop: 'error',
};
export const BADGE_TRANSLATE = {
  ok: 'Запущена',
  stop: 'Остановлена',
  no_connection: 'Нет связи',
  ok_middle: 'Включена',
  stop_middle: 'Выключена',
  neutral: 'Нет связи',
  success: 'Включена',
  error: 'Выключена',
};
export const TRANSLATE_TYPES = {
  deposit: 'месторождение',
  borehole: 'скважину',
  gzu: 'ГЗУ',
  dns: 'ДНС',
  bg: 'блок гребенок',
  bush: 'куст',
  ktp: 'КТП',
  water_pipeline: 'водопровод',
  oil_pipeline: 'нефтепровод',
  power_line: 'ЛЭП',
  pressure_sensor: 'датчик давления',
};

export const EVENTS_PAGE_TABLE_LABEL = [
  {
    name: 'dateAndTime',
    title: 'Дата и время',
    isSort: false,
    isFilter: false,
  },
  {
    name: 'name',
    title: 'Название объекта',
    isSort: false,
    isFilter: true,
  },
  {
    name: 'depositName',
    title: 'Название месторождения',
    isSort: false,
    isFilter: true,
  },
  {
    name: 'message',
    title: 'Сообщение',
    isSort: false,
    isFilter: true,
  },
  {
    name: 'eventStatus',
    title: 'Статус',
    isSort: false,
    isFilter: true,
  },
  {
    name: 'reactAction',
    title: 'Реакция',
    isSort: false,
    isFilter: true,
  },
];
export const URLS_MAP = {
  scheme: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
  sputnik: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
  hybrid: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
  terrain: 'http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
};
export const DIRECTORIES_LABEL_TABLE: any = {
  id: 'ID',
  name: 'Название',
  message_type_id: 'Тип сообщения',
  external_name_id: 'Название поля из внешней системы',
  system_type: 'Тип системы',
  action: 'Действия',
  device_id: 'Устройство',
  min_warning: 'Min порог аварии',
  min_error: 'Min порог предупреждения',
  max_warning: 'Max порог предупреждения',
  max_error: 'Max порог аварии',
};
export const LABEL_MAIN_OPTION_INFORMATION = [
  {
    name: 'name',
    title: 'имя',
    isSort: false,
    isFilter: false,
  },
  {
    name: 'value',
    title: 'значение',
    isSort: false,
    isFilter: false,
  },
];
export const TABS_DEFAULT = [
  { url: 'info', title: 'Общая информация' },
  { url: 'scheme', title: 'Схема' },
];
export const TABS_OILFIELD = [
  { url: 'info', title: 'Общая информация' },
  { url: 'scheme', title: 'Схема' },
  { url: 'boreholes', title: 'Скважины' },
];
export const TABS_DNS = [
  { url: 'info', title: 'Общая информация' },
  { url: 'scheme', title: 'Схема' },
];

export const REPORT_TREE_DATA: ReportsSideBarModel = [
  {
    id: 'general',
    title: 'Общие',
    children: [
      {
        id: 0,
        value: 'name',
        title: 'Название',
        type: 'number',
        method: null,
      },
      {
        id: 1,
        value: 'type mining',
        title: 'Тип добычи',
        type: 'number',
        method: null,
      },
      {
        id: 2,
        value: 'status',
        title: 'Статус',
        type: 'number',
        method: null,
      },
    ],
  },
  {
    id: 'options',
    title: 'Параметры',
    children: [
      {
        id: 0,
        title: 'Параметр 1',
        value: 'option 1',
        type: 'date',
        method: null,
      },
      {
        id: 1,
        title: 'Параметр 2',
        value: 'option 2',
        type: 'date',
        method: null,
      },
      {
        id: 3,
        title: 'Параметр 3',
        value: 'option 3',
        type: 'date',
        method: null,
      },
    ],
  },
];
export const ALPHABET: Array<string> = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
export const TABS_LIST: TabsObject[] = [
  { id: 'oilfield', isActive: true, title: 'Месторождения', url: 'oilfield' },
  { id: 'boreholes', isActive: false, title: 'Скважины', url: 'boreholes' },
  { id: 'bush', isActive: false, title: 'Кусты', url: 'bush' },
  { id: 'gzu', isActive: false, title: 'ГЗУ', url: 'gzu' },
  { id: 'dns', isActive: false, title: 'ДНС', url: 'dns' },
  { id: 'bg', isActive: false, title: 'БГ/УЗА', url: 'bg' },
  { id: 'ktp', isActive: false, title: 'КТП', url: 'ktp' },
  {
    id: 'water-pipeline',
    isActive: false,
    title: 'Водопроводы',
    url: 'water-pipeline',
  },
  {
    id: 'oilPipeline',
    isActive: false,
    title: 'Нефтепроводы',
    url: 'oil-pipeline',
  },
  {
    id: 'powerLines',
    isActive: false,
    title: 'ЛЭП',
    url: 'power-lines',
  },
  {
    id: 'pressureSensor',
    isActive: false,
    title: 'Датчики давления',
    url: 'pressure-sensor',
  },
];
export const AVAILABLE_FREQ_FIELDS: any[] = [
  {
    value: 'reference_frequency_before_ramp',
    title: 'Заданная частота до ЗИ (ATV900)',
  },
  {
    value: 'frequency',
    title: 'Частота (КУБ-2059ПЧ)',
  },
  {
    value: 'show_motor_frequency',
    title: 'Частота (Delta CP2000)',
  },
];
export const AVAILABLE_DEBIT_FIELDS: any[] = [
  {
    value: 'M1',
    title: 'M1',
  },
  {
    value: 'M2',
    title: 'M2',
  },
  {
    value: 'v1plus',
    title: 'V1 (ИВК)',
  },
  {
    value: 'V1',
    title: 'V1',
  },
  {
    value: 'V2',
    title: 'V2',
  },
  {
    value: 'V3',
    title: 'V3',
  },
  {
    value: 'V4',
    title: 'V4',
  },
  {
    value: 'impulse',
    title: 'Импульсный',
  },
];
