<div class="dashboard tui-form__buttons_align_center">
  <div class="dashboard__status">
    <button
      tuiButton
      type="button"
      size="s"
      appearance="dashboard-btn-ok"
      [class.__active]="lastSelectedStatus === 'ok'"
      (click)="changeGraph('ok')"
    >
      Запущено: {{ boreholesStatusCounts.ok }}
    </button>
    <button
      tuiButton
      type="button"
      size="s"
      appearance="dashboard-btn-stop"
      [class.__active]="lastSelectedStatus === 'stop'"
      (click)="changeGraph('stop')"
    >
      Остановлено: {{ boreholesStatusCounts.stop }}
    </button>
    <button
      tuiButton
      type="button"
      size="s"
      appearance="dashboard-btn-no-connection"
      [class.__active]="lastSelectedStatus === 'no_connection'"
      (click)="changeGraph('no_connection')"
    >
      Нет связи: {{ boreholesStatusCounts.no_connection }}
    </button>
  </div>
  <div class="dashboard__triangle">
    <div
      class="dashboard__triangle-type borehole_type_ppd"
      data-after="ППД"
    ></div>
    <div
      class="dashboard__triangle-type borehole_type_shgn"
      data-after="ШГН"
    ></div>
    <div
      class="dashboard__triangle-type borehole_type_shvn"
      data-after="ШВН"
    ></div>
    <div
      class="dashboard__triangle-type borehole_type_ecn"
      data-after="ЭЦН"
    ></div>
    <!-- <div class="dashboard__triangle-type _gray">НЕТ</div>
    <div class="dashboard__triangle-type _white">Ремонт</div>-->
  </div>
  <div class="dashboard__filter">
    <tui-hosted-dropdown
      #dropdown
      [tuiDropdownMaxHeight]="500"
      [content]="content"
    >
      <button
        size="m"
        tuiIconButton
        appearance="menu"
        type="button"
        icon="tuiIconFilterLarge"
        [pseudoHovered]="dropdown.open || null"
      ></button>
    </tui-hosted-dropdown>
    <ng-template #content>
      <tui-data-list>
        <tui-opt-group
          class="option-group"
          tuiMultiSelectGroup
          [(ngModel)]="selectedOilfields"
        >
          <button
            *ngFor="let col of oilfieldsIdAndNameList"
            tuiOption
            size="xs"
            (click)="changeOils()"
            [value]="col"
          >
            {{ col.name }}
          </button>
        </tui-opt-group>
      </tui-data-list>
    </ng-template>
  </div>
</div>
