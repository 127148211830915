import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TuiAlertService } from '@taiga-ui/core';
import { ObjectService } from '../../../../../services/object.service';
import { TypeTree } from 'src/models/tree.model';
import * as memoizee from 'memoizee';
import { TuiContextWithImplicit, tuiPure } from '@taiga-ui/cdk';
import { forkJoin, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import {
  maxValueValidator,
  minValueValidator,
} from '../../../dns-card/components/common-info-dns/common-info-dns.component';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { PressureService } from '../../../../../services/pressure.service';
import { PipelineService } from '../../../../../services/pipeline.service';
import { DirectoriesService } from 'src/app/services/directories.service';
import { AVAILABLE_DEBIT_FIELDS } from 'src/app/const/app-consts';
import { GzuService } from 'src/app/services/gzu.service';
import { ActualValueType } from '../../../../../../models/pressure-meter.model';

function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control.value?.trim() || '';
    if ((control?.value?.length || '') !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

type DataForSelectField = {
  id: number | string;
  name: string;
};

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

@Component({
  selector: 'app-common-info-gzu',
  templateUrl: './common-info-gzu.component.html',
  styleUrls: ['./common-info-gzu.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class CommonInfoGzuComponent implements OnInit, OnDestroy {
  public pressureList: DataForSelectField[] = [];

  public oilPipelines: DataForSelectField[] = [];

  public waterPipelines: DataForSelectField[] = [];

  public label: any[] = [
    {
      name: 'name',
      title: 'Название',
      isSort: true,
      isFilter: true,
    },
    {
      name: 'value',
      title: 'Значение',
      isSort: true,
      isFilter: true,
    },
  ];

  public data: any = null;

  public editObjectMode: boolean = false;

  public onClickOnMap = new EventEmitter();

  public visibleMap: boolean = false;

  public skeletonVisible = false;

  @Input()
  public contextObject: any = null;

  @Input() depositList: DataForSelectField[] = [];

  @Output() handleChange: EventEmitter<string> = new EventEmitter();

  //for displaying placeholder "Выберите из списка" for  oil_parent_id we should set FormControl(null),
  public objectInfoForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    parent_id: new FormControl('', [Validators.required]),
    oil_parent_id: new FormControl(null),
    curr_pressure: new FormControl(null, []),
    next_pressure: new FormControl(null, []),
    oil_pipe: new FormControl(null, []),
    water_pipe: new FormControl(null, []),
    latitude: new FormControl('', [
      Validators.required,
      Validators.min(-90),
      Validators.max(90),
    ]),
    longitude: new FormControl('', [
      Validators.required,
      Validators.min(-180),
      Validators.max(180),
    ]),
    debits_params_devices: new FormArray([]),
    debit_calculate_option: new FormControl(null, []),
  });

  initFormControlsArray() {
    const formGroup = new FormGroup({
      debit_device_id: new FormControl(null, []),
      debit_device_field: new FormControl(null, []),
    });

    formGroup.get('debit_device_field')?.disable();
    formGroup
      .get('debit_device_id')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (!item) {
          return;
        }
        formGroup.get('debit_device_field')?.enable();
        formGroup.get('debit_device_field')?.setValidators(Validators.required);
      });
    setTimeout(() => {
      this.debitDeviceSearch$.next('');
    });
    return formGroup;
  }

  public destroyer: Subject<null> = new Subject();

  public latitude: number = 0;

  public longitude: number = 0;

  public listOfOil: any = {
    gzuList: [],
    dnsList: [],
    bgList: [],
  };

  public availableDns: any[] = [];

  public availableGzu: any[] = [];

  public availableBg: any[] = [];

  public parent: any | null = null;

  public oilParent: any | null = null;

  public disabledOilField: boolean = false;

  public iconBetweenDebits: string = 'tuiIconPlus';

  public availableDevices: any[] = [];

  public availableDebitFields = AVAILABLE_DEBIT_FIELDS.map((v: any) => ({
    id: v.value,
    name: v.title,
  }));

  readonly debitDeviceSearch$: Subject<string | null> = new Subject();

  private filterDebitDevices(
    searchQuery: string | null
  ): Observable<readonly any[]> {
    const result = this.availableDevices.filter((device: any) =>
      device.name.toLowerCase().includes((searchQuery || '').toLowerCase())
    );
    result.splice(20);
    return of(result);
  }

  readonly stringifyComboBox = (item: { id: number; name: string }): string =>
    item.name || '';

  readonly availableDebitDevices$: Observable<readonly any[] | null> =
    this.debitDeviceSearch$.pipe(
      switchMap((search: string | null) =>
        this.filterDebitDevices(search).pipe(
          startWith<readonly any[] | null>(null)
        )
      )
    );

  public currentPressureValue: string = '';

  public nextPressureValue: string = '';

  public debitSensorErrorMessage: string | null = null;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    public objectService: ObjectService,
    public directoriesService: DirectoriesService,
    private pressureService: PressureService,
    private pipelineService: PipelineService,
    private gzuService: GzuService
  ) {}

  async ngOnInit() {
    if (this.contextObject) {
      this.gzuService.gzu
        .pipe(takeUntil(this.destroyer))
        .subscribe((gzu: string[]) => {
          this.contextObject = { ...gzu };
        });
      this.latitude = this.contextObject?.latitude || 0;
      this.longitude = this.contextObject?.longitude || 0;
      this.objectInfoForm
        .get('oil_pipe')
        ?.setValue(
          this.findObjectByIdFromArray(
            this.contextObject?.oil_pipe_id,
            this.oilPipelines
          ) || null
        );
      this.objectInfoForm
        .get('water_pipe')
        ?.setValue(
          this.findObjectByIdFromArray(
            this.contextObject?.water_pipe_id,
            this.waterPipelines
          ) || null
        );
      this.onClickOnMap
        .pipe(takeUntil(this.destroyer))
        .subscribe((coordinates) => {
          this.objectInfoForm.get('latitude')?.setValue(coordinates.lat);
          this.objectInfoForm.get('longitude')?.setValue(coordinates.lng);
        });
      this.objectInfoForm
        .get('latitude')
        .valueChanges.pipe(
          takeUntil(this.destroyer),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe((item: any) => {
          if (this.objectInfoForm.get('latitude')?.valid) {
            this.latitude = item;
            return;
          }
          return;
        });
      this.objectInfoForm
        .get('longitude')
        .valueChanges.pipe(
          takeUntil(this.destroyer),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe((item: any) => {
          if (this.objectInfoForm.get('longitude')?.valid) {
            this.longitude = item;
            return;
          }
          return;
        });
      this.objectInfoForm
        .get('parent_id')
        .valueChanges.pipe(takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (
            !item ||
            (!this.availableGzu.length &&
              !this.availableDns.length &&
              !this.availableBg.length)
          ) {
            return;
          }
          this.objectInfoForm.get('oil_parent_id').setValue(null);
          this.filterOilCollection(item);
        });
      this.visibleMap = true;
    }
    await this.updatePressureSensorValues();

    this.startDebitDeviceErrorMonitoring();
  }

  startDebitDeviceErrorMonitoring() {
    this.objectInfoForm
      .get('debits_params_devices')
      .valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe(() => {
        this.checkDebitDeviceError();
      });
  }

  checkDebitDeviceError() {
    const form = this.objectInfoForm.get('debits_params_devices');

    if (
      form.controls[0].get('debit_device_id').value &&
      !form.controls[0].get('debit_device_field').value
    ) {
      this.debitSensorErrorMessage =
        'Поле "Параметр устройства" обязательно для каждого датчика дебита';
      return;
    }

    if (
      !form.controls[0].get('debit_device_id').value &&
      form.controls[0].get('debit_device_field').value
    ) {
      this.debitSensorErrorMessage = 'Укажите датчик дебита';
      return;
    }

    if (form.controls[1]) {
      if (
        form.controls[1].get('debit_device_id').value &&
        !form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Поле "Параметр устройства" обязательно для каждого датчика дебита';
        return;
      }

      if (
        !form.controls[1].get('debit_device_id').value &&
        form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage = 'Укажите датчик дебита';
        return;
      }

      if (
        !form.controls[0].get('debit_device_id').value ||
        !form.controls[0].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Поля первого датчика дебита заполнены неправильно';
        return;
      }

      if (
        form.controls[0].get('debit_device_id').value ===
          form.controls[1].get('debit_device_id').value &&
        form.controls[0].get('debit_device_field').value ===
          form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Датчики должны различаться по названию и/или параметрам';
        return;
      }
    }

    this.debitSensorErrorMessage = null;
  }

  async updatePressureSensorValues() {
    this.currentPressureValue = await this.getPressureSensorValue(
      this.contextObject?.curr_pressure?.id
    );
    this.nextPressureValue = await this.getPressureSensorValue(
      this.contextObject?.next_pressure?.id
    );
  }

  async getPressureSensorValue(pressureId: number | null) {
    if (!pressureId) {
      return 'Датчик не выбран';
    }
    const pressureValueList: ActualValueType[] = await this.pressureService
      .values;
    const rightPressure = pressureValueList.find(
      (item) => item.pressure_id === pressureId
    );
    return rightPressure?.value
      ? rightPressure?.value + ' МПа'
      : 'Значение недоступно';
  }

  public memoizedGetSections = memoizee((form: any): any => {
    return form.controls.debits_params_devices.controls;
  });

  add() {
    const control = <FormArray>this.objectInfoForm.get('debits_params_devices');
    control.push(this.initFormControlsArray());
  }

  remove(i: number) {
    const control = <FormArray>this.objectInfoForm.get('debits_params_devices');
    control.removeAt(i);
  }

  searchDebitDevice(event: string | null | Event): void {
    const searchQuery: string | null =
      typeof event === 'string'
        ? event
        : (event?.target as HTMLInputElement)?.value || null;
    this.debitDeviceSearch$.next(searchQuery);
  }

  changeIconBetweenDebits() {
    return this.iconBetweenDebits === 'tuiIconPlus'
      ? (this.iconBetweenDebits = 'tuiIconMinus')
      : this.iconBetweenDebits === 'tuiIconPlus';
    // if (this.iconBetweenDebits === 'tuiIconPlus') {
    //   this.iconBetweenDebits = 'tuiIconMinus';
    // } else this.iconBetweenDebits = 'tuiIconPlus';
  }

  save() {
    if (!this.objectInfoForm.valid) {
      this.notificationsService
        .open('Проверьте правильность заполнения формы', {
          label: '',
          status: TuiNotification.Error,
        })
        .subscribe();
      return this.objectInfoForm.markAllAsTouched();
    }

    let debitDeviceId: number[] = [];
    let debitDeviceField: string[] = [];

    const values = this.objectInfoForm
      .get('debits_params_devices')
      .controls.map((row: any) => {
        return {
          debit_device_id: row.get('debit_device_id').value,
          debit_device_field: row.get('debit_device_field').value,
        };
      });

    debitDeviceId = values
      .map((v: any) => {
        if (v.debit_device_id?.id) return v.debit_device_id.id;
      })
      .filter((value: number | undefined) => value);

    debitDeviceField = values
      .map((v: any) => {
        if (v.debit_device_field) return v.debit_device_field;
      })
      .filter((value: string | undefined) => value);

    // Проверка валидности полей датчика дебета
    if (this.debitSensorErrorMessage) {
      this.notificationsService
        .open('Неправильно заполнены поля датчика дебита', {
          label: '',
          status: TuiNotification.Error,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      return;
    }
    // Если выбран нефтепровод и/или водопровод и не выбран объект нефтесбора, то требуем указать объект нефтесбора
    if (
      !this.objectInfoForm.get('oil_parent_id')?.value &&
      (this.objectInfoForm.get('oil_pipe').value?.id ||
        this.objectInfoForm.get('water_pipe').value?.id)
    ) {
      this.notificationsService
        .open(
          'Для выбора нефтепровода и/или водопровода необходимо указать объект нефтесбора',
          {
            label: '',
            status: TuiNotification.Warning,
            hasIcon: true,
            autoClose: true,
            hasCloseButton: true,
          }
        )
        .subscribe();
      return;
    }
    if (
      this.objectInfoForm.dirty ||
      this.objectInfoForm.get('latitude')?.value !==
        this.contextObject?.latitude ||
      this.objectInfoForm.get('longitude')?.value !==
        this.contextObject?.longitude
    ) {
      let body: TypeTree = {
        ...this.contextObject,
        name: this.objectInfoForm.get('name').value,
        parent_id: this.objectInfoForm.get('parent_id')?.value || null,
        oil_parent_id: this.objectInfoForm.get('oil_parent_id')?.value || null,
        curr_pressure_id:
          this.objectInfoForm.get('curr_pressure')?.value?.id || null,
        next_pressure_id:
          this.objectInfoForm.get('next_pressure')?.value?.id || null,
        oil_pipe_id: this.objectInfoForm.get('oil_pipe').value?.id || null,
        water_pipe_id: this.objectInfoForm.get('water_pipe').value?.id || null,
        latitude: this.objectInfoForm.get('latitude')?.value ?? null,
        longitude: this.objectInfoForm.get('longitude')?.value ?? null,
        debit_calculate_option: debitDeviceId.length
          ? this.iconBetweenDebits === 'tuiIconPlus'
            ? 'add'
            : 'subtract'
          : null,
      };

      const paramBody = {
        object_params: this.contextObject.params.map((param: any) => ({
          device_id: param.device_id,
          parametrs_id: param.param.id,
        })),
        debit_device_id: debitDeviceId,
        debit_device_field: debitDeviceField,
      };

      this.objectService
        .updateObject(body, this.contextObject.id)
        .pipe(takeUntil(this.destroyer))
        .subscribe(() => {
          this.directoriesService
            .setParamObject(this.contextObject.id, paramBody)
            .subscribe(() => {
              this.objectService
                .getObjectByID(this.contextObject.id)
                .subscribe(async (data: any) => {
                  this.gzuService.gzu.next(data);
                  this.contextObject = {
                    ...data,
                    oil_pipe: this.findObjectByIdFromArray(
                      data.oil_pipe_id,
                      this.oilPipelines
                    ),
                    water_pipe: this.findObjectByIdFromArray(
                      data.water_pipe_id,
                      this.waterPipelines
                    ),
                  };
                  this.oilParent =
                    this.objectInfoForm.get('oil_parent_id')?.value || null;
                  await this.updatePressureSensorValues();
                  this.notificationsService
                    .open('Объект обновлен', {
                      label: '',
                      status: TuiNotification.Success,
                      hasIcon: true,
                      autoClose: true,
                      hasCloseButton: true,
                    })
                    .subscribe();
                  this.handleChange.emit(this.contextObject.name);
                  this.editObjectMode = false;
                });
            });
        });
    } else {
      this.editObjectMode = false;
    }
  }

  changeInput(): void {
    this.editObjectMode = !this.editObjectMode;
    if (this.contextObject) {
      this.skeletonVisible = true;
      this.patchForm().then(() => {
        this.skeletonVisible = false;
      });
    }
  }

  cancelInput(): void {
    if (this.contextObject) {
      this.resetForm();
    }
    this.editObjectMode = !this.editObjectMode;
  }

  patchForm() {
    return new Promise<void>((resolve) => {
      if (
        !this.availableGzu.length &&
        !this.availableDns.length &&
        !this.availableBg.length
      ) {
        this.oilCollection();
      }
      if (!this.availableDevices.length) {
        forkJoin({
          pressure: this.pressureService.pressure,
          devices: this.objectService.getDeviceList(),
          oilPipelines: this.pipelineService.oilPipelinesList,
        }).subscribe((result: any) => {
          const { pressure, devices, oilPipelines } = result;
          this.pressureList = this.prepareArrayForSelectField(pressure);
          this.oilPipelines = this.prepareArrayForSelectField(oilPipelines);
          this.availableDevices = devices;
          this.debitDeviceSearch$.next('');

          if (
            this.contextObject.debit_device_id.length &&
            this.contextObject.debit_device_field.length
          ) {
            //здесь тоже можно обнулить массив formArray, но сюда мы заходим
            // в первый раз, когда нажимаем на редактирование и больше не должны
            this.contextObject.debit_device_id.forEach(
              (device: any, index: any) => {
                const deviceO = devices.find((d: any) => d.id === device);
                const field = this.contextObject.debit_device_field[index];
                //ниже if даже не нужен, если мы сюда один раз заходим
                if (
                  this.objectInfoForm.controls.debits_params_devices.length !==
                  this.contextObject.debit_device_id.length
                ) {
                  this.objectInfoForm.controls.debits_params_devices.controls.push(
                    new FormGroup({
                      debit_device_id: new FormControl(deviceO, []),
                      debit_device_field: new FormControl(field, []),
                    })
                  );
                }
              }
            );
          } else {
            // ниже if тоже не нужен по идее, если мы один раз заходим
            if (!this.objectInfoForm.controls.debits_params_devices.length) {
              this.objectInfoForm.controls.debits_params_devices.push(
                this.initFormControlsArray()
              );
            }
          }
          this.objectInfoForm.patchValue({
            name: this.contextObject.name,
            parent_id: this.contextObject.parent_id || null,
            oil_parent_id: this.contextObject.oil_parent?.id || null,
            curr_pressure: this.contextObject?.curr_pressure || null,
            next_pressure: this.contextObject?.next_pressure || null,
            latitude: this.contextObject.latitude || 0,
            longitude: this.contextObject.longitude || 0,
            debit_calculate_option: this.contextObject.debit_calculate_option,
            oil_pipe:
              this.findObjectByIdFromArray(
                this.contextObject?.oil_pipe_id,
                this.oilPipelines
              ) || null,
            water_pipe:
              this.findObjectByIdFromArray(
                this.contextObject?.water_pipe_id,
                this.waterPipelines
              ) || null,
          });
          resolve();
          setTimeout(() => {
            this.debitDeviceSearch$.next('');
          });
        });
      } else {
        if (
          this.contextObject.debit_device_id.length &&
          this.contextObject.debit_device_field.length &&
          (!this.objectInfoForm.controls.debits_params_devices.length ||
            this.objectInfoForm.controls.debits_params_devices.length !==
              this.contextObject.debit_device_id.length ||
            this.objectInfoForm.controls.debits_params_devices.length !==
              this.contextObject.debit_device_field.length ||
            !this.objectInfoForm
              .get('debits_params_devices')
              .controls.some(
                (c: any) =>
                  this.contextObject.debit_device_id.includes(
                    c.controls.debit_device_id.value.id
                  ) &&
                  this.contextObject.debit_device_field.includes(
                    c.controls.debit_device_field.value
                  )
              ))
        ) {
          //выше условия, объединенные в одно условие должны бы false, сюда не
          // должно заходить, тк мы при отмене обнуляем массив formArray, а при сохранении
          //у нас должно быть одинаково в formArray и в параметрах скважины
          this.objectInfoForm.controls.debits_params_devices.controls.length = 0;
          this.contextObject.debit_device_id.forEach(
            (device: any, index: any) => {
              const deviceO = this.availableDevices.find(
                (d: any) => d.id === device
              );
              const field = this.contextObject.debit_device_field[index];
              this.objectInfoForm.controls.debits_params_devices.controls.push(
                new FormGroup({
                  debit_device_id: new FormControl(deviceO, []),
                  debit_device_field: new FormControl(field, []),
                })
              );
            }
          );
        }
        resolve();
        setTimeout(() => {
          this.debitDeviceSearch$.next('');
        });
      }
    });
  }

  resetForm() {
    this.objectInfoForm.reset();
    this.visibleMap = false;
    this.objectInfoForm.patchValue({
      name: this.contextObject.name,
      parent_id: this.contextObject.parent_id || null,
      oil_parent_id: this.contextObject.oil_parent?.id || null,
      curr_pressure: this.contextObject?.curr_pressure || null,
      next_pressure: this.contextObject?.next_pressure || null,
      latitude: this.contextObject.latitude || 0,
      longitude: this.contextObject.longitude || 0,
      debit_calculate_option: this.contextObject.debit_calculate_option,
    });

    if (this.availableDevices.length) {
      if (
        this.contextObject.debit_device_id.length &&
        this.contextObject.debit_device_field.length
      ) {
        if (
          this.objectInfoForm.controls.debits_params_devices.controls.length
        ) {
          this.objectInfoForm.controls.debits_params_devices.controls.length = 0;
          this.contextObject.debit_device_id.forEach(
            (device: any, index: any) => {
              const deviceO = this.availableDevices.find(
                (d: any) => d.id === device
              );
              const field = this.contextObject.debit_device_field[index];
              this.objectInfoForm.controls.debits_params_devices.controls.push(
                new FormGroup({
                  debit_device_id: new FormControl(deviceO, []),
                  debit_device_field: new FormControl(field, []),
                })
              );
            }
          );
        } else {
          this.contextObject.debit_device_id.forEach(
            (device: any, index: any) => {
              const deviceO = this.availableDevices.find(
                (d: any) => d.id === device
              );
              const field = this.contextObject.debit_device_field[index];
              this.objectInfoForm.controls.debits_params_devices.controls.push(
                new FormGroup({
                  debit_device_id: new FormControl(deviceO, []),
                  debit_device_field: new FormControl(field, []),
                })
              );
            }
          );
        }
      } else {
        if (
          this.objectInfoForm.controls.debits_params_devices.controls.length
        ) {
          this.objectInfoForm.controls.debits_params_devices.controls.length = 0;
          this.objectInfoForm.controls.debits_params_devices.controls.push(
            this.initFormControlsArray()
          );
        } else {
          this.objectInfoForm.controls.debits_params_devices.controls.push(
            this.initFormControlsArray()
          );
        }
      }
    } else if (!this.availableDevices.length) {
      //по идее сюда никогда не должно зайти
      forkJoin({
        pressure: this.pressureService.pressure,
        devices: this.objectService.getDeviceList(),
        oilPipelines: this.pipelineService.oilPipelinesList,
      }).subscribe((result: any) => {
        const { pressure, devices, oilPipelines } = result;
        this.pressureList = this.prepareArrayForSelectField(pressure);
        this.oilPipelines = this.prepareArrayForSelectField(oilPipelines);
        this.availableDevices = devices;
        this.debitDeviceSearch$.next('');

        if (
          this.contextObject.debit_device_id.length &&
          this.contextObject.debit_device_field.length
        ) {
          if (
            this.objectInfoForm.controls.debits_params_devices.controls.length
          ) {
            this.objectInfoForm.controls.debits_params_devices.controls.length = 0;
            this.contextObject.debit_device_id.forEach(
              (device: any, index: any) => {
                const deviceO = devices.find((d: any) => d.id === device);
                const field = this.contextObject.debit_device_field[index];
                this.objectInfoForm.controls.debits_params_devices.controls.push(
                  new FormGroup({
                    debit_device_id: new FormControl(deviceO, []),
                    debit_device_field: new FormControl(field, []),
                  })
                );
              }
            );
          }
        } else {
          if (!this.objectInfoForm.controls.debits_params_devices.length) {
            this.objectInfoForm.controls.debits_params_devices.controls.push(
              this.initFormControlsArray()
            );
          }
        }
        setTimeout(() => {
          this.debitDeviceSearch$.next('');
        });
      });
    }
    setTimeout(() => {
      this.visibleMap = true;
      this.debitDeviceSearch$.next('');
    });
  }

  @tuiPure
  stringify(items: readonly any[]): any {
    const map = new Map(
      items.map(({ id, name }) => [id, name] as [number, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }

  // для нефтесбора
  getConcatOils() {
    return [...this.availableDns, ...this.availableGzu, ...this.availableBg];
  }

  public oilCollection() {
    forkJoin({
      gzu: this.objectService.getGzuList(),
      dns: this.objectService.getDnsList(),
      bg: this.objectService.getBGList(),
    }).subscribe((result: any) => {
      this.availableGzu = result.gzu;
      this.availableDns = result.dns;
      this.availableBg = result.bg;
      this.availableGzu = this.availableGzu.filter((item: any) => {
        return item.id !== this.contextObject.id;
      });
      this.filterOilCollection(this.contextObject.parent_id);
    });
  }

  filterOilCollection(idParent: number) {
    this.listOfOil.gzuList = this.availableGzu.filter((element: any) => {
      return element.parent_id === idParent;
    });
    this.listOfOil.dnsList = this.availableDns.filter((element: any) => {
      return element.parent_id === idParent;
    });
    this.listOfOil.bgList = this.availableBg.filter((element: any) => {
      return element.parent_id === idParent;
    });
    if (
      !this.listOfOil.gzuList.length &&
      !this.listOfOil.dnsList.length &&
      !this.listOfOil.bgList.length
    ) {
      this.objectInfoForm.get('oil_parent_id').disable();
      this.disabledOilField = true;
    } else {
      this.objectInfoForm.get('oil_parent_id').enable();
      this.disabledOilField = false;
    }
  }

  getDevicesName(): string {
    if (!this.contextObject.debit_device_id.length) {
      return 'Не выбран';
    } else {
      if (
        this.contextObject.debit_device.length !==
        this.contextObject.debit_device_id.length
      ) {
        const doubleDebitName = this.contextObject.debit_device.map(
          (d: any) => d.name
        );
        return [...doubleDebitName, ...doubleDebitName].join(', ');
      } else
        return this.contextObject.debit_device
          .map((d: any) => d.name)
          .join(', ');
    }
  }

  getDebitUnitDebitDevice(
    debit_device_sum: number,
    debit_device_field: string[]
  ) {
    return `${debit_device_sum.toFixed(2)} ${
      debit_device_field.find((parameter: string) =>
        ['M1', 'M2'].includes(parameter)
      )
        ? ' т'
        : ' м3'
    }`;
  }

  prepareArrayForSelectField(array: any): DataForSelectField[] {
    return array.map((v: DataForSelectField) => ({
      id: v.id,
      name: v.name,
    }));
  }

  findObjectByIdFromArray(
    id: number | string | null,
    dataArray: DataForSelectField[]
  ) {
    let result = null;
    if (id) {
      result = dataArray.find((item) => item.id === id);
    }
    return result ? result : null;
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
