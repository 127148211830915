<div class="event-buttons">
  <tui-hosted-dropdown [content]="statusDropdown" [(open)]="openSettings">
    <button
      tuiIconButton
      appearance="transparent"
      size="m"
      icon="tuiIconSettings"
      type="button"
      class="table-filter-filter-btn"
    ></button>
  </tui-hosted-dropdown>
  <ng-template #statusDropdown let-activeZone>
    <div class="event-buttons__list">
      <tui-data-list
        tuiDataListDropdownManager
        [tuiActiveZoneParent]="activeZone"
      >
        <button tuiOption size="s" (click)="rollback()">Отмена фильтров</button>
        <button tuiOption size="s" (click)="readAllEvents()">
          Прочитать всё
        </button>
        <button
          class="button-svg"
          tuiOption
          type="button"
          size="s"
          tuiDropdownDirection="bottom"
          [tuiDropdown]="false"
          [tuiDropdownContent]="updateTimeTmp"
          [tuiDropdownSided]="true"
        >
          <tui-svg src="tuiIconChevronLeft"></tui-svg>
          Уведомления о событиях
        </button>
      </tui-data-list>
      <ng-template #updateTimeTmp>
        <div>
          <form [formGroup]="eventSettingsForm">
            <tui-data-list
              role="menu"
              tuiDataListDropdownManager
              [tuiActiveZoneParent]="activeZone"
            >
              <tui-opt-group
                tuiMultiSelectGroup
                formControlName="eventsSettings"
              >
                <button
                  *ngFor="let time of eventsSettingsValues"
                  tuiOption
                  size="s"
                  [value]="time.value"
                >
                  {{ time.label }}
                </button>
              </tui-opt-group>
            </tui-data-list>
          </form>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
