import { NgModule } from '@angular/core';

import { TablerIconsModule } from 'angular-tabler-icons';
import {
  IconDeviceFloppy,
  IconFilter,
  IconMailOpened,
  IconX,
  IconApps,
  IconAntennaBars5,
  IconCircleDashed,
  IconLayersIntersect,
  IconPlus,
  IconArrowBarLeft,
  IconQuestionMark,
} from 'angular-tabler-icons/icons';

// Select some icons (use an object, not an array)
const icons = {
  IconMailOpened,
  IconDeviceFloppy,
  IconFilter,
  IconX,
  IconApps,
  IconAntennaBars5,
  IconCircleDashed,
  IconLayersIntersect,
  IconPlus,
  IconArrowBarLeft,
  IconQuestionMark,
};

@NgModule({
  imports: [TablerIconsModule.pick(icons)],
  exports: [TablerIconsModule],
})
export class IconsModule {}
