import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ObjectService } from 'src/app/services/object.service';
import { fabric } from 'fabric';
import { SchemeSendData } from '../../../../../models/scheme.model';
import { TypeTree } from '../../../../../models/tree.model';
import { TreeService } from '../../../../services/tree.service';
import { OptionModel } from '../../../../../models/option.model';
import { PATH_DELETE_ICON } from '../../../../const/app-consts';
import { TuiDialogService } from '@taiga-ui/core';
import { Rect } from 'fabric/fabric-impl';
import { SchemeService } from '../../../../services/scheme.service';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-scheme-oilfields',
  templateUrl: './scheme-oilfields.component.html',
  styleUrls: ['./scheme-oilfields.component.less'],
})
export class SchemeOilfieldsComponent implements OnInit, OnDestroy {
  @Input()
  disable: boolean = true; // при true значения дерева будут приниматься с сервиса, false = значения принимаются с nodeSchemeData

  @Input()
  nodeSchemeData: Array<SchemeSendData> = []; // данные схемы с объекта

  @Input()
  idObject!: number; // id выбранного объекта

  @Input()
  closeModal: any = () => {}; // закрытие модалки , используется в меторождении и на карте

  public lastPosX: any;

  public treeData!: Array<TypeTree>; // данные дерева

  protected _canvas?: any; //галвная сцена

  public reload = true;

  public listOfOption: Array<OptionModel> = []; // список параметров выбранного объекта

  public objects: any[] = []; // ??

  public selectedOption: any = {}; // выбранная опция ??

  public checkOption: any = {}; // only for view true status checkbox

  public optionIDs: any = {}; // id объектов с id выбранных опций (переменная в первую очередь нужна для [(ngModel)]) ;

  public lastSelectedObjectID: number = 0; // id выбранного объекта (клик на шестеренку)

  public positionSettingOptionBlock = { left: 0, top: 0 }; // позиция блока с настройками параметров

  public changeSettingOptionMenu: boolean = false; // показывает/скрывает popup настроек(там лишь параметры)

  public isDragging: boolean = false; // переменная для использования метода по перемещению на сцене

  public lastPosY: any;

  private BLACK_LIST_ARRAY: Array<number> = [];

  private readonly DEFAULT_SIZE: number = 24; // размер шрифта

  private readonly PATH_DELETE_ICON: string = PATH_DELETE_ICON; // линка на иконку удаления у объекта

  // позиции при движении с помощью ctrl+ колесико
  private clientPosition = {
    x: 0,
    y: 0,
  };

  private destroyer: Subject<any> = new Subject<any>();

  constructor(
    public objectService: ObjectService,
    private schemeService: SchemeService,
    public changeDetector: ChangeDetectorRef,
    private treeService: TreeService,
    private router: Router,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService
  ) {}

  //disable true: в объектах, в остальном уже false
  ngOnInit() {
    if (this.disable) {
      this.objectService.selectedObject
        .pipe(takeUntil(this.destroyer))
        .subscribe((node: any) => {
          if (node === null) {
            this.router.navigate(['/main/objects']);
            return;
          }
          this.BLACK_LIST_ARRAY.map((id) => {
            this.setDisableObject(id.toString(), false);
          });
          this.BLACK_LIST_ARRAY = [];
          this.idObject = node.id;
          this.changeDataByID(this.idObject);
        });
    } else {
      this.changeDataByID(this.idObject);
    }
  }

  changeDataByID(id: number) {
    this.objectService.getObjectByID(id).subscribe((nodeID) => {
      this.nodeSchemeData = nodeID?.scheme?.scheme || [];
      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerColor = 'gray';
      fabric.Object.prototype.cornerStyle = 'circle';
      if (!this.disable) {
        this._canvas = new fabric.Canvas('scheme', {
          backgroundColor: '#ebebef',
          selection: true,
          preserveObjectStacking: true,
          width: 900, //window?.innerWidth - 370 ||
          height: 790,
          fireMiddleClick: true,
          fireRightClick: true,
        });
      } else {
        this._canvas = new fabric.StaticCanvas('scheme', {
          backgroundColor: '#ebebef',
          selection: true,
          preserveObjectStacking: true,
          width: 900, //window?.innerWidth - 370 ||
          height: 790,
          fireMiddleClick: true,
          fireRightClick: true,
        });
      }
      if (this.objectService.treeData.length) {
        this.treeData = this.objectService.treeData;
        this.changeDetector.markForCheck();
        this.setup();
      } else {
        this.objectService.getDataToTree().subscribe((data: any) => {
          this.treeData = data.tree;
          this.changeDetector.markForCheck();
          this.setup();
        });
      }
      this.globalFabricMethods();
      this.addControls();
    });
  }

  setup() {
    (this.nodeSchemeData || []).map((element: any) => {
      if (!element?.fill) {
        if (element?.optionsID.length && element && element?.id) {
          this._canvas._objects.map((item: any) => {
            if (element.id && +item.stroke === +element.id) {
              this._canvas.remove(item);
            } else {
              return;
            }
          });
          this.listOfOption =
            (
              this.treeService.findObjectByID(
                this.treeData,
                +element?.id
              ) as TypeTree
            )?.params || [];
          const changedOption: any = [];
          element.optionsID.map((id: any) => {
            const findedOption = this.listOfOption.find((option) => {
              return option.param.id === +id;
            });
            if (findedOption) {
              changedOption.push(findedOption);
            }
          });
          if (element && element?.id) {
            changedOption.map((el: any) => {
              if (!Object.keys(this.checkOption).includes(element.id || '0')) {
                this.checkOption[element.id] = {};
              }
              this.checkOption[element.id][el?.param?.id] = true;
              if (!Object.keys(this.optionIDs).includes(element.id || '0')) {
                this.optionIDs[element.id] = [];
              }
              this.optionIDs[element.id].push(Number(el?.param?.id));

              if (
                !Object.keys(this.selectedOption).includes(element.id || '0')
              ) {
                this.selectedOption[element?.id] = {};
              }
              this.selectedOption[element?.id][el?.param?.id] = {
                name: el?.param?.name,
                value: el?.value ? el?.value : '-',
              };
            });
          }
          this.getObjectFromTree(
            {
              type: element.type,
              id: element.id,
              name: element.name,
            },
            {
              left: element.left,
              top: element.top,
              scaleX: element.scaleX,
              scaleY: element.scaleY,
              angle: element.angle,
              type: element.type,
              id: element.id,
              optionsID: [],
            },
            false,
            this.selectedOption[element.id]
          );
        } else {
          this.getObjectFromTree(
            {
              type: element.type,
              id: element.id,
              name: element.name,
            },
            element
          );
        }
      } else {
        this.addObject(element.type, element);
      }
    });
  }

  getObjectFromTree(
    value: any,
    settings?: SchemeSendData | null,
    reloadOptions = true,
    addText: null | any = null
  ) {
    const nodeHasValue = value === null;
    if (nodeHasValue) {
      return;
    }
    if (value?.id && this.BLACK_LIST_ARRAY.includes(+value?.id)) {
      return;
    }
    if (value?.id) {
      this.BLACK_LIST_ARRAY.push(+value.id);
    }
    this.setDisableObject(value?.id);
    if (reloadOptions) {
      this.selectedOption[value.id] = {};
      this.checkOption[value.id] = {};
    }
    let textLength = 0;
    if (addText !== null && Object.values(addText).length >= 2) {
      textLength = Object.values(addText).length - 1;
    }
    fabric.loadSVGFromURL(
      `../../../../../assets/icons-old/${value.type}.svg`,
      (objects: Object[], options: any) => {
        const rect = new fabric.Rect({
          left: 0,
          top: 0,
          originX: 'left',
          originY: 'top',
          width: 150,
          height: 150 + textLength * 15,
          fill: 'rgba(255,255,255,1)',
          stroke: `${value.type}`,
          strokeDashArray: [2, 2],
          hasBorders: true,
          transparentCorners: false,
        });
        let groupObjects = [rect];
        let text;
        if (value?.name) {
          text = new fabric.Textbox(value.name, {
            left: 5,
            textAlign: 'center',
            top: 5,
            originX: 'left',
            originY: 'top',
            scaleX: 0.5,
            scaleY: 0.5,
            width: 280,
            height: 100,
            fontSize: value?.name?.length < 9 ? 36 : 28,
            fill: 'rgb(0,0,0)',
            stroke: 'name',
            strokeDashArray: [2, 2],
            hasBorders: true,
            transparentCorners: false,
          });
          groupObjects.push(text as Rect);
        }
        const img = fabric.util.groupSVGElements(objects, options);
        img.set({
          scaleY: 4,
          scaleX: 4,
          left: 40,
          top: 50,
        });
        groupObjects.push(img as Rect);
        if (addText !== null) {
          groupObjects.push(...this.renderTextInsideObject(value.id, addText));
        }
        const shape = new fabric.Group(groupObjects, {
          left: settings ? settings.left : 0,
          top: settings ? settings.top : 0,
          scaleX: settings ? settings.scaleX : 1,
          stroke: `${value.id}`,
          scaleY: settings ? settings.scaleX : 1,
          angle: settings ? settings.angle : 0,
          width: 150,
          height: 150 + textLength * 20,
          snapAngle: 90,
        });
        this._canvas?.add(shape);
      }
    );
  }

  saveScheme() {
    let body = this._canvas.toObject();
    let filteredBody = body.objects.reduce(
      (endData: Array<SchemeSendData>, item: any) => {
        let filteredItem: any = {
          left: item.left,
          top: item.top,
          scaleX: item.scaleX,
          scaleY: item.scaleY,
          angle: item.angle,
          optionsID: [],
        };
        //name
        if (item.hasOwnProperty('objects')) {
          filteredItem.id = item.stroke;
          item.objects.map((object: any) => {
            if (object.type === 'textbox') {
              filteredItem.name = object.text;
            }
            if (object.type === 'rect') {
              filteredItem.type = object.stroke;
            }
            if (object.type === 'text') {
              filteredItem.optionsID.push(+object.stroke);
            }
          });
        } else {
          filteredItem.type = item.fill === '#37474f' ? 'pipe' : 'electro-line';
          filteredItem.fill = item.fill;
        }
        endData.push(filteredItem);
        return endData;
      },
      []
    );
    this.schemeService.changeScheme(this.idObject, filteredBody).subscribe();
    this.closeModal();
  }

  // addObjectToCanvas(object: any) {
  //   console.log(object);
  // }

  addControls() {
    this.addDeleteControl();
    this.addSettingControl();
    this.removeDefaultControls();
  }

  removeDefaultControls() {
    fabric.Object.prototype.controls['ml'].visible = false;
    fabric.Object.prototype.controls['mr'].visible = false;
    fabric.Object.prototype.controls['mt'].visible = false;
    fabric.Object.prototype.controls['mb'].visible = false;
  }

  addDeleteControl() {
    const deleteObject = (eventData: any, transform: any) => {
      const target = transform.target;
      const canvas = target.canvas;
      this.setDisableObject(target.stroke, false);
      this.changeSettingOptionMenu = false;
      canvas.remove(target);
      canvas.requestRenderAll();
    };
    const deleteIcon = this.PATH_DELETE_ICON;
    const img = document.createElement('img');
    img.src = deleteIcon;
    const renderIcon = (
      ctx: any,
      left: any,
      top: any,
      styleOverride: any,
      fabricObject: any
    ) => {
      const size = this.DEFAULT_SIZE;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(img, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
    fabric.Object.prototype.controls['deleteControl'] = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -16,
      offsetX: 16,
      cursorStyle: 'pointer',
      //@ts-ignore
      mouseUpHandler: deleteObject,
      render: renderIcon,
      cornerSize: this.DEFAULT_SIZE,
    });
  }

  //TODO: после зума this.cl {X,Y} надо менять
  addSettingControl() {
    const addOption = (eventData: any, value: any) => {
      this.positionSettingOptionBlock.left = value.target.left + 180;
      this.lastSelectedObjectID = +value.target.stroke;
      this.listOfOption =
        (
          this.treeService.findObjectByID(
            this.treeData,
            this.lastSelectedObjectID
          ) as TypeTree
        )?.params || [];
      this.positionSettingOptionBlock.top = value.target.top + 40;
      this.changeSettingOptionMenu = !this.changeSettingOptionMenu;
    };
    const img: any = document.createElement('img');
    img.src = `../../../../../assets/icons/setting.svg`;
    const renderIcon = (
      ctx: any,
      left: any,
      top: any,
      styleOverride: any,
      fabricObject: any
    ) => {
      if (fabricObject.type === 'line') return; // у 'линии' нет данной функции
      const size = this.DEFAULT_SIZE;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(img, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
    fabric.Object.prototype.controls['settingControl'] = new fabric.Control({
      x: -0.8,
      y: -0.5,
      offsetY: -16,
      offsetX: 16,
      cursorStyle: 'pointer',
      //@ts-ignore
      mouseUpHandler: addOption,
      render: renderIcon,
      cornerSize: this.DEFAULT_SIZE,
    });
  }

  addTextByID(option: OptionModel) {
    const objectID = this.lastSelectedObjectID;
    if (this.selectedOption[objectID].hasOwnProperty(option.param.id)) {
      delete this.selectedOption[objectID][option.param.id];
    } else {
      this.selectedOption[objectID][option.param.id] = {
        name: option.param.name,
        value: option.value ? option.value : '-',
      };
    }
    if (Object.values(this.selectedOption[objectID]).length > 2) {
      // TODO: сообщение о привышении лимита
      delete this.selectedOption[objectID][option.param.id];
      this.checkOption[objectID][option.param.id] = false; // ?
      return;
    }
    this.BLACK_LIST_ARRAY = this.BLACK_LIST_ARRAY.filter((el) => {
      return el !== objectID;
    });

    //remove fabric text object
    let objectKklass: any;
    let nameObject = '';
    this._canvas._objects.map((item: any) => {
      if (+item.stroke === objectID) {
        item._objects = item._objects.filter((object: any) => {
          return object.type !== 'text';
        });
        item._objects.map((el: any) => {
          if (el.type === 'textbox') {
            nameObject = el.text;
          }
        });
        objectKklass = item;
        this._canvas.remove(item);
      }
    });
    let type = '';
    objectKklass._objects.map((object: any) => {
      if (object.type === 'rect') {
        type = object.stroke;
      }
    });
    this.getObjectFromTree(
      { type: type, id: objectID, name: nameObject },
      {
        left: objectKklass.left,
        top: objectKklass.top,
        scaleX: objectKklass.scaleX,
        scaleY: objectKklass.scaleY,
        angle: objectKklass.angle,
        type: type,
        id: objectID.toString(),
        optionsID: [],
      },
      false,
      this.selectedOption[objectID]
    );
    this._canvas.requestRenderAll();
  }

  renderTextInsideObject(ID: number, selection: any): Array<any> {
    //TODO после выбора 6 параметра выбрасывается нотификация о том, что максимум 5
    const listOfText: any = [];
    Object.entries(selection).map((param: any, length: number) => {
      const text = new fabric.Text(
        param[1].name + ' : ' + (param[1].value ? param[1].value : '-'),
        {
          left: 0,
          top: 90 + (length + 2) * 17,
          originX: 'left',
          originY: 'top',
          scaleX: 0.5,
          scaleY: 0.5,
          width: 100,
          height: 100,
          fill: 'rgb(0,0,0)',
          fontSize: 28,
          stroke: param[0].toString(),
          strokeDashArray: [2, 2],
          hasBorders: true,
          transparentCorners: false,
        }
      );
      listOfText.push(text);
    });
    return listOfText;
  }

  private globalFabricMethods(): void {
    this._canvas.on('selection:updated', () => {
      this._canvas.bringForward(this._canvas?.getActiveObject());
    });
    this._canvas.on('mouse:wheel', (opt: any) => {
      let delta = opt.e.deltaY;
      this.positionSettingOptionBlock = {
        left: opt.e.clientX,
        top: opt.e.clientY,
      };
      let zoom = this._canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      this._canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    this._canvas.on('mouse:down', (opt: any) => {
      let evt = opt.e;
      if (evt.ctrlKey === true && opt.button === 2 && !this.disable) {
        this.isDragging = true;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
        // fabric.Object.prototype.selectable = false;
      } else {
      }
    });
    this._canvas.on('mouse:move', (opt: any) => {
      if (this.isDragging) {
        let e = opt.e;
        const x = this.clientPosition.x + (e.clientX - this.lastPosX);
        const y = this.clientPosition.y + (e.clientY - this.lastPosY);

        this._canvas.absolutePan({
          x: x,
          y: y,
        });
        this.clientPosition = {
          x: x,
          y: y,
        };
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
    });
    this._canvas.on('object:moving', (opt: any) => {
      let obj = opt.target; // Перемещаемый объект
      /*   let canvas = obj.canvas;
      let bound = obj.getBoundingRect(); // Границы объекта*/

      obj.setCoords();
      /*     if (bound.top + bound.height > canvas.height) {
        //  return back object!
      } // Если выходит за нижнюю границу

      if (bound.left + bound.width > canvas.width) {
      } // Если выходит за правую границу

      if (bound.top < 0) {
      } // Если выходит за верхнюю границу

      if (bound.left < 0) {
      } // Если выходит за левую границу*/
      this.changeSettingOptionMenu = false;
    });
    this._canvas.on('object:mouseUpHandler', () => {
      this.changeSettingOptionMenu = false;
    });
    this._canvas.on('mouse:up', (el: any) => {
      this.isDragging = false;
      if (!el.target?.stroke || +el.target.stroke !== this.lastSelectedObjectID)
        this.changeSettingOptionMenu = false;
    });
  }

  // закрытие попапа с параметрами
  saveOption() {
    this.changeSettingOptionMenu = !this.changeSettingOptionMenu;
  }

  // принимает id типа стринг по которому будет дизеблиться ветка в дереве
  setDisableObject(value: string, type = true) {
    this.treeData = this.infinityChanger(this.treeData, +value, type);
  }

  private infinityChanger(
    tree: Array<TypeTree> | TypeTree,
    id: number,
    type: boolean
  ) {
    if (Array.isArray(tree)) {
      tree.map((item: any) => this.infinityChanger(item, id, type));
    } else {
      if (tree?.children && tree?.children.length) {
        tree.children.map((item: any) => {
          this.infinityChanger(item, id, type);
        });
        if (tree?.id === id) {
          tree.disabled = type;
        }
      } else {
        if (tree?.id === id) {
          tree.disabled = type;
        }
      }
    }
    return tree as any;
  }

  ngOnDestroy(): void {
    this.BLACK_LIST_ARRAY.map((id) => {
      this.setDisableObject(id.toString(), false);
    });
    this.BLACK_LIST_ARRAY = [];
    this.objectService.selectedObjectScheme.next(null);
    this.destroyer.next(null);
    this.destroyer.complete();
  }

  addObject(type: string, settings?: SchemeSendData | null) {
    switch (type) {
      case 'pipe':
        const pipe = new fabric.Line([0, 0, 0, 150], {
          left: settings ? settings.left : 0,
          top: settings ? settings.top : 0,
          scaleX: settings ? settings.scaleX : 1,
          scaleY: settings ? settings.scaleX : 1,
          angle: settings ? settings.angle : 0,
          fill: '#37474f',
          stroke: '#37474f',
          strokeWidth: 5,
          selectable: true,
          perPixelTargetFind: true,
          snapAngle: 90,
          strokeUniform: true,
        });
        this._canvas?.add(pipe);
        return;
      case 'electro-line':
        const electroLine = new fabric.Line([0, 0, 0, 150], {
          left: settings ? settings.left : 0,
          top: settings ? settings.top : 0,
          scaleX: settings ? settings.scaleX : 1,
          scaleY: settings ? settings.scaleX : 1,
          angle: settings ? settings.angle : 0,
          fill: '#ffab00',
          stroke: '#ffab00',
          strokeWidth: 5,
          selectable: true,
          perPixelTargetFind: true,
          snapAngle: 90,
          strokeUniform: true,
        });
        this._canvas?.add(electroLine);
        return;
    }
  }
}
