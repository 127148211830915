import {
  TuiDecimalT,
  TuiHorizontalDirection,
  TuiOrientationT,
  TuiSizeL,
  TuiSizeS,
} from "@taiga-ui/core";
import { TuiBooleanHandler } from "@taiga-ui/cdk";
import { ObjectType } from "../../../models/object-type";

export type Validators = {
  min?: number;
  max?: number;
  required?: boolean;
  email?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string | RegExp;
};

export class FormAttrBase<T> {
  //value for select can't be 0, you can use string '999999' instead 0, look for more details on setting object component
  value:
    | string
    | number
    | boolean
    | null
    | undefined
    | { value: string; label: string }
    | { value: number; label: string };

  key: string;

  label: string;

  placeholder: string;

  visualRequired: boolean;

  order: number;

  controlType: string;

  disabled: boolean;

  validators: Validators[];

  tuiTextfieldMaxLength: number | null;

  icon: string | null;

  iconAlign: TuiHorizontalDirection;

  datalistSelect:
    | ReadonlyArray<
        ReadonlyArray<{
          value: ObjectType | number | string;
          label: string;
          group?: string;
        }>
      >
    | ReadonlyArray<{
        value: ObjectType | number | string;
        label: string;
        group?: string;
      }>;

  //groups: Array<{ label: string; items: any[] }> | null;

  size: TuiSizeL;

  sizeBlock: TuiSizeS | TuiSizeL;

  content: string;

  // itemContent: PolymorpheusContent<TuiValueContentContext<T> & {
  //         index: number;
  //     }>;

  contentAlign: TuiHorizontalDirection;

  hideCheckbox: boolean;

  hideRadio: boolean;

  pseudoDisabled: boolean;

  item: string;

  readonly items: any[];

  orientation: TuiOrientationT;

  disabledItemHandler: TuiBooleanHandler<T>;

  options: { key: string; value: string }[];

  description: string;

  min: number;

  max: number;

  prefix: string;

  postfix: string;

  precision: number;

  decimal: TuiDecimalT;

  isGrouped: boolean;

  constructor(
    options: {
      value?:
        | string
        | number
        | boolean
        | null
        | undefined
        | { value: string; label: string }
        | { value: number; label: string };
      key?: string;
      label?: string;
      isGrouped?: boolean;
      placeholder?: string;
      visualRequired?: boolean;
      order?: number;
      controlType?: string;
      disabled?: boolean;
      validators?: Validators[];
      tuiTextfieldMaxLength?: number | null;
      icon?: string | null;
      iconAlign?: TuiHorizontalDirection;
      datalistSelect?:
        | ReadonlyArray<
            ReadonlyArray<{
              value: ObjectType | number | string;
              label: string;
              group?: string;
            }>
          >
        | ReadonlyArray<{
            value: ObjectType | number | string;
            label: string;
            group?: string;
          }>;
      //groups?: Array<{ label: string; items: any[] }> | null;
      size?: TuiSizeL;
      sizeBlock?: TuiSizeS | TuiSizeL;
      content?: string;
      contentAlign?: TuiHorizontalDirection;
      hideCheckbox?: boolean;
      hideRadio?: boolean;
      pseudoDisabled?: boolean;
      item?: string;
      items?: any[];
      orientation?: TuiOrientationT;
      disabledItemHandler?: TuiBooleanHandler<T>;
      options?: { key: string; value: string }[];
      description?: string;
      min?: number;
      max?: number;
      prefix?: string;
      postfix?: string;
      precision?: number;
      decimal?: TuiDecimalT;
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || "";
    this.label = options.label || "";
    this.placeholder = options.placeholder || "";
    this.visualRequired = options.visualRequired || false;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || "";
    this.disabled = options.disabled || false;
    this.validators = options.validators || [];
    this.tuiTextfieldMaxLength = options.tuiTextfieldMaxLength || null;
    this.icon = options.icon || null;
    this.iconAlign = options.iconAlign || "right";
    this.datalistSelect = options.datalistSelect || [];
    //this.groups = options.groups || null;
    this.size = options.size || "m";
    this.sizeBlock = options.sizeBlock || "m";
    this.content = options.content || "";
    this.contentAlign = options.contentAlign || "right";
    this.hideCheckbox = options.hideCheckbox || false;
    this.hideRadio = options.hideRadio || false;
    this.pseudoDisabled = options.pseudoDisabled || false;
    this.item = options.item || "";
    this.items = options.items || [];
    this.orientation = options.orientation || "horizontal";
    this.disabledItemHandler = options.disabledItemHandler || (() => !1);
    this.options = options.options || [];
    this.description = options.description || "";
    this.min = options.min || -Infinity;
    this.max = options.max || Infinity;
    this.prefix = options.prefix || "";
    this.postfix = options.postfix || "";
    this.precision = options.precision || 2;
    this.decimal = options.decimal || "never";
    this.isGrouped = options.isGrouped || false;
  }
}
