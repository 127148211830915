import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ObjectService } from '../../../../services/object.service';
import { Router } from '@angular/router';
import { TabsModel } from '../../../../../models/tabs.model';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-content-object',
  templateUrl: './content-object.component.html',
  styleUrls: ['./content-object.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentObjectComponent implements OnInit, OnChanges, OnDestroy {
  public tabs: Array<TabsModel> = [{ url: 'info', title: 'Общая информация' }];

  public defaultTabs: Array<TabsModel> = [
    { url: 'info', title: 'Общая информация' },
  ];

  public tabsForRest: Array<TabsModel> = [
    { url: 'info', title: 'Общая информация' },
    { url: 'scheme', title: 'Схема' },
  ];

  public tabsForOilfield: Array<TabsModel> = [
    { url: 'info', title: 'Общая информация' },
    { url: 'boreholes', title: 'Скважины' },
    { url: 'scheme', title: 'Схема' },
  ];

  public tabsForDns: Array<TabsModel> = [
    { url: 'info', title: 'Общая информация' },
    { url: 'scheme', title: 'Схема' },
  ];

  destroyer = new Subject();

  public selectedObject: any = null;

  constructor(private objectService: ObjectService, private router: Router) {}

  ngOnInit() {
    this.objectService.selectedObject
      .pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        this.selectedObject = item;
        if (item) {
          if (['deposit', 'bush'].includes(item.type)) {
            this.tabs = this.tabsForOilfield;
          } else if (item.type === 'dns') {
            this.tabs = this.tabsForDns;
          } else {
            this.tabs = this.tabsForRest;
          }
        } else {
          this.tabs = this.defaultTabs;
        }
      });
  }

  ngOnChanges(changes: any): void {
    if (changes?.tabs?.previousValue !== undefined) {
      this.router.navigate(['/main/objects/info']).then();
    }
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
    this.objectService.selectedObject.next(null);
  }
}
