<tui-hosted-dropdown [content]="dropdown" [(open)]="open">
  <button
    tuiIconButton
    appearance="flat"
    icon="tuiIconAlignJustifyLarge"
    type="button"
  ></button>
</tui-hosted-dropdown>
<ng-template #dropdown>
  <tui-data-list role="menu">
    <button
      *ngFor="let button of actionButtons"
      tuiOption
      (click)="selectAction(button.label)"
      [value]="button.value"
    >
      {{ button.label }}
    </button>
  </tui-data-list>
</ng-template>
