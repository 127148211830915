import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  ActualValueType,
  DeviceMeterType,
  PressureEventResponseType,
  PressureHistoryResponseType,
  PressureItemType,
  RequestPressureType,
} from '../../models/pressure-meter.model';
import { EventModel, FilterOptions } from '../../models/event.model';

@Injectable({
  providedIn: 'root',
})
export class PressureService {
  private pressureListCache: PressureItemType[] = [];

  private devicesList: DeviceMeterType[] = [];

  // Subject для информирования компонентов об изменениях в массиве датчиков давления
  public pressureListSubject: Subject<PressureItemType[]> = new Subject<
    PressureItemType[]
  >();

  public pressureSensorTypes = [
    { id: 'in1', name: '4-20 мА' },
    { id: 'P1', name: 'Терноманометр А835' },
  ];

  constructor(public http: HttpClient) {}

  get pressure(): Promise<PressureItemType[]> {
    return new Promise((resolve) => {
      if (this.pressureListCache.length) {
        resolve(this.pressureListCache);
      } else {
        return this.getPressureList().subscribe((res: PressureItemType[]) => {
          resolve(res);
        });
      }
      return;
    });
  }

  get devices(): Promise<DeviceMeterType[]> {
    return new Promise((resolve) => {
      if (this.devicesList.length) {
        resolve(this.devicesList);
      } else {
        return this.getDeviceList().subscribe((res: DeviceMeterType[]) => {
          resolve(res);
        });
      }
      return;
    });
  }

  get values(): Promise<ActualValueType[]> {
    return new Promise((resolve) => {
      return this.getActualValues().subscribe((res: ActualValueType[]) => {
        resolve(res);
      });
    });
  }

  private getActualValues(): Observable<ActualValueType[]> {
    return this.http
      .get<{ data: ActualValueType[] }>(`${environment.apiUrl}pressure/values/`)
      .pipe(
        map((response: { data: ActualValueType[] }) => {
          return response.data;
        })
      );
  }

  private getDeviceList(): Observable<DeviceMeterType[]> {
    return this.http
      .get<{ data: DeviceMeterType[] }>(
        `${environment.apiUrl}pressure/available_devices/`
      )
      .pipe(
        map((response: { data: DeviceMeterType[] }) => {
          this.devicesList = response.data;
          return response.data;
        })
      );
  }

  private getPressureList(): Observable<PressureItemType[]> {
    return this.http
      .get<{ data: PressureItemType[] }>(`${environment.apiUrl}pressure/list/`)
      .pipe(
        map((response: { data: PressureItemType[] }) => {
          this.pressureListCache = response.data;
          this.pressureListSubject.next(this.pressureListCache);
          return response.data;
        })
      );
  }

  setPressure(body: RequestPressureType): Observable<PressureItemType> {
    return this.http
      .post<{ data: PressureItemType }>(
        `${environment.apiUrl}pressure/add/`,
        body
      )
      .pipe(
        map((response: { data: PressureItemType }) => {
          this.pressureListCache.push(response.data);
          this.pressureListSubject.next(this.pressureListCache);
          return response.data;
        })
      );
  }

  deletePressure(id: number): Observable<boolean> {
    return this.http
      .delete<{ success: boolean }>(
        `${environment.apiUrl}pressure/delete/${id}/`
      )
      .pipe(
        map((response: { success: boolean }) => {
          this.pressureListCache =
            this.pressureListCache.filter((option) => {
              return id !== option.id;
            }) || [];
          this.pressureListSubject.next(this.pressureListCache);
          return response.success;
        })
      );
  }

  updateParams(data: PressureItemType) {
    return this.http
      .put<{ data: PressureItemType }>(
        `${environment.apiUrl}pressure/update/${data.id}/`,
        data
      )
      .pipe(
        map((response: { data: PressureItemType }) => {
          this.pressureListCache = this.pressureListCache.map((pressure) =>
            pressure.id === response.data.id ? response.data : { ...pressure }
          );
          this.pressureListSubject.next(this.pressureListCache);
          return response.data;
        })
      );
  }

  getPressureSensorByID(id: number): Observable<PressureItemType> {
    return this.http
      .get<{ info_object: PressureItemType }>(
        `${environment.apiUrl}pressure/info/${id}/`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  // СОБЫТИЯ

  getPressureEventsByPressureID(
    objectId: number,
    page: number = 1,
    perPage: number = 50
  ) {
    return this.http.get<PressureEventResponseType>(
      `${environment.apiUrl}pressure_events/list/by_pressure/${objectId}/?page=${page}&per_page=${perPage}`
    );
  }

  markAllPressureSensorEventsAsRead(pressureSensorID: number) {
    return this.http.put(
      `${environment.apiUrl}pressure_events/react_pressure/${pressureSensorID}/`,
      {},
      {}
    );
  }

  markOnePressureEventAsRead(pressureEventID: number) {
    return this.http.put(
      `${environment.apiUrl}pressure_events/react/${pressureEventID}/`,
      {}
    );
  }

  getHistoryByPressureID(
    pressureID: number,
    page: number,
    eventsPerPage: number,
    startDate: string,
    finalDate: string
  ) {
    return this.http.post<PressureHistoryResponseType>(
      `${environment.apiUrl}pressure/history/${pressureID}/?page=${page}&per_page=${eventsPerPage}`,
      { start_date: startDate, end_date: finalDate }
    );
  }

  getPressureEventsList(
    page: number = 1,
    per_page: number = 10,
    sortOptions: FilterOptions = {}
  ): Observable<EventModel> {
    return this.http
      .post<EventModel>(
        `${environment.apiUrl}pressure_events/list/filter/?page=${page}&per_page=${per_page}`,
        sortOptions
      )
      .pipe(
        map((response: EventModel) => {
          return response;
        })
      );
  }

  markAbsolutelyAllPressureEventsAsRead(): Observable<boolean> {
    return this.http
      .put<{ success: boolean }>(
        `${environment.apiUrl}pressure_events/react/all/`,
        {}
      )
      .pipe(
        map((response: { success: boolean }) => {
          return response.success;
        })
      );
  }
}
