<div class="oilfield-add">
  <app-primary-section>
    <div class="oilfield-add__container">
      <h2 class="title">Добавить КТП</h2>
      <div class="cross" (click)="closeModal()">
        <img src="/assets/cross.png" alt="(X)" />
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="formKTP">
      <div class="form__title">
        <h4>Общая информация</h4>
      </div>
      <div class="form__field">
        <span>Название *</span>
        <div class="input">
          <tui-input [tuiTextfieldMaxLength]="40" formControlName="name">
            <span class="input__placeholder">Укажите название</span>
          </tui-input>
          <tui-field-error formControlName="name"></tui-field-error>
        </div>
      </div>
      <div class="form__field">
        <span>Месторождение *</span>
        <div class="input">
          <tui-select
            formControlName="deposit"
            [valueContent]="typeValueContent"
          >
            <span *ngIf="!formKTP.get('deposit')?.value">Не выбрано</span>
            <span *ngIf="formKTP.get('deposit')?.value"
              >Выберите месторождение</span
            >
            <tui-data-list-wrapper
              *tuiDataList
              [items]="depositList"
              [itemContent]="valueContent"
            >
            </tui-data-list-wrapper>
          </tui-select>
          <ng-template #typeValueContent let-data>
            {{ data.title }}
          </ng-template>
          <ng-template #valueContent let-data>
            {{ data.title }}
          </ng-template>
          <tui-field-error formControlName="deposit"></tui-field-error>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="formKTP">
      <div class="form__title">
        <h4>Координаты</h4>
      </div>
      <div class="form__field">
        <span>Широта *</span>
        <div class="input">
          <tui-input-number
            [tuiTextfieldMaxLength]="50"
            [precision]="14"
            formControlName="latitude"
          >
            <span class="input__placeholder">Введите широту</span>
          </tui-input-number>
          <tui-field-error formControlName="latitude"></tui-field-error>
        </div>
      </div>
      <div class="form__field">
        <span>Долгота *</span>
        <div class="input">
          <tui-input-number
            [tuiTextfieldMaxLength]="50"
            [precision]="14"
            formControlName="longitude"
          >
            <span class="input__placeholder">Введите долготу</span>
          </tui-input-number>
          <tui-field-error formControlName="longitude"></tui-field-error>
        </div>
      </div>
      <div class="oilfield-add__map">
        <app-map-object
          [onClickOnMap]="onClickOnMap"
          [currentIdObject]="106"
          [latitude]="latitude"
          [longitude]="longitude"
        >
        </app-map-object>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="footer">
      <span>* Обязательные для заполнения поля</span>
      <div class="footer__buttons">
        <button (click)="submitForm()" class="footer-button _add">
          Добавить
        </button>
        <button (click)="closeDialog()" class="footer-button _cancel">
          Отменить
        </button>
      </div>
    </div>
  </app-primary-section>
</div>
