import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-table-sort",
  templateUrl: "./table-sort.component.html",
  styleUrls: ["./table-sort.component.less"],
})
export class TableSortComponent implements OnInit {
  @Input() columns: any[] = [];

  @Input() direction: "asc" | "desc" = "asc";

  @Output() sorted: EventEmitter<any> = new EventEmitter();

  sortField: any = {};

  sortDropdownOpen = false;

  ngOnInit(): void {
    this.sortField = this.columns[0];
  }

  selectSortField(item: any) {
    this.sortDropdownOpen = false;
    this.sortField = item;
    this.sorted.emit({ field: item, direction: this.direction });
  }

  changeDirection() {
    this.direction = this.direction === "asc" ? "desc" : "asc";
    this.sorted.emit({ field: this.sortField, direction: this.direction });
  }
}
