<div class="draw-color-lines">
  <button
    class="pipelines-color__button_electricity"
    [class._selected]="currentTypePolylineColor === colorPolyline.ELECTRICITY"
    (click)="changePolyLineColor(colorPolyline['ELECTRICITY'])"
  >
    ЛЭП
  </button>
  <button
    class="pipelines-color__button_aqua"
    [class._selected]="currentTypePolylineColor === colorPolyline.AQUA"
    (click)="changePolyLineColor(colorPolyline.AQUA)"
  >
    Водопровод
  </button>
  <button
    class="pipelines-color__button_oil"
    [class._selected]="currentTypePolylineColor === colorPolyline.OIL"
    (click)="changePolyLineColor(colorPolyline.OIL)"
  >
    Нефтепровод
  </button>
</div>
