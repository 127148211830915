import { NgModule } from "@angular/core";
import { CommonModule as NgCommonModule } from "@angular/common";

import {
  TuiButtonModule,
  TuiColorModule,
  TuiDataListModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiSvgModule,
  TuiTextfieldControllerModule,
  TuiDialogModule,
  TuiModeModule,
  TuiHintModule,
  TuiHintControllerModule,
  TuiLoaderModule,
  TuiDropdownControllerModule,
  TuiGroupModule,
  TuiNotificationModule,
} from "@taiga-ui/core";
import {
  TuiCheckboxBlockModule,
  TuiCheckboxLabeledModule,
  TuiDataListWrapperModule,
  TuiFieldErrorModule,
  TuiInputModule,
  TuiInputSliderModule,
  TuiIslandModule,
  TuiMultiSelectModule,
  TuiRadioBlockModule,
  TuiRadioLabeledModule,
  TuiSelectModule,
  TuiSliderModule,
  TuiTreeModule,
  TuiTabsModule,
  TuiInputPasswordModule,
  TuiCheckboxModule,
  TuiRadioModule,
  TuiRadioListModule,
  TuiBadgeModule,
  TuiArrowModule,
  TuiInputDateRangeModule,
  TuiInputCountModule,
  TuiInputDateModule,
  TuiInputFilesModule,
  TuiToggleModule,
} from "@taiga-ui/kit";
import { TuiActiveZoneModule, TuiMapperPipeModule } from "@taiga-ui/cdk";
import { TuiSidebarModule } from "@taiga-ui/addon-mobile";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TuiColorPickerModule } from "@taiga-ui/addon-editor";
//import { DynamicHooksModule, HookParserEntry } from "ngx-dynamic-hooks";
//import { EventsParser } from "../app/pages/events/events-parser";

//const componentParsers: Array<HookParserEntry> = [
//  EventsParser
//  //{ module: TuiButtonModule },
//  // ...
//];
@NgModule({
  imports: [
    //DynamicHooksModule.forRoot({
    //  globalParsers: componentParsers,
    //}),
    TuiButtonModule,
    TuiSvgModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiDataListWrapperModule,
    TuiHostedDropdownModule,
    TuiCheckboxBlockModule,
    TuiDataListModule,
    TuiActiveZoneModule,
    TuiDropdownModule,
    TuiSidebarModule,
    TuiRadioBlockModule,
    TuiRadioLabeledModule,
    TuiSliderModule,
    TuiInputSliderModule,
    TuiMultiSelectModule,
    TuiIslandModule,
    PerfectScrollbarModule,
    TuiTreeModule,
    TuiCheckboxLabeledModule,
    TuiMapperPipeModule,
    TuiColorModule,
    FormsModule,
    RouterModule,
    TuiDialogModule,
    TuiInputModule,
    TuiFieldErrorModule,
    ReactiveFormsModule,
    TuiModeModule,
    TuiHintControllerModule,
    TuiHintModule,
    TuiTabsModule,
    TuiLoaderModule,
    TuiDropdownControllerModule,
    TuiInputPasswordModule,
    TuiCheckboxModule,
    TuiRadioModule,
    TuiGroupModule,
    TuiRadioListModule,
    TuiBadgeModule,
    TuiArrowModule,
    TuiInputDateRangeModule,
    TuiInputCountModule,
    TuiColorPickerModule,
    TuiNotificationModule,
    TuiInputDateModule,
    TuiInputFilesModule,
    TuiToggleModule,
  ],
  exports: [
    NgCommonModule,
    TuiButtonModule,
    TuiSvgModule,
    TuiSelectModule,
    TuiTextfieldControllerModule,
    TuiDataListWrapperModule,
    TuiHostedDropdownModule,
    TuiCheckboxBlockModule,
    TuiDataListModule,
    TuiActiveZoneModule,
    TuiDropdownModule,
    TuiSidebarModule,
    TuiRadioBlockModule,
    TuiRadioLabeledModule,
    TuiSliderModule,
    TuiInputSliderModule,
    TuiMultiSelectModule,
    TuiIslandModule,
    PerfectScrollbarModule,
    TuiTreeModule,
    TuiCheckboxLabeledModule,
    TuiMapperPipeModule,
    TuiColorModule,
    FormsModule,
    RouterModule,
    TuiDialogModule,
    TuiInputModule,
    TuiFieldErrorModule,
    ReactiveFormsModule,
    TuiModeModule,
    TuiHintControllerModule,
    TuiHintModule,
    TuiTabsModule,
    TuiLoaderModule,
    TuiDropdownControllerModule,
    TuiInputPasswordModule,
    TuiCheckboxModule,
    TuiRadioModule,
    TuiGroupModule,
    TuiRadioListModule,
    TuiBadgeModule,
    TuiArrowModule,
    TuiInputDateRangeModule,
    TuiInputCountModule,
    TuiColorPickerModule,
    TuiNotificationModule,
    TuiInputDateModule,
    TuiInputFilesModule,
    TuiToggleModule,
  ],
})
export class TaiCommonModule {}
