import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TuiSizeL } from "@taiga-ui/core";

@Component({
  selector: "app-form-checkbox-labeled",
  templateUrl: "./form-checkbox-labeled.component.html",
  styleUrls: ["./form-checkbox-labeled.component.less"],
})
export class FormCheckboxLabeledComponent {
  @Input()
  form!: FormGroup;

  @Input()
  formControlKey!: string;

  @Input()
  content!: string;

  @Input()
  nativeId!: string;

  @Input()
  size!: TuiSizeL;
}
