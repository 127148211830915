<div class="table">
  <div class="table__toolbar">
    <ng-container #leftBar></ng-container>
    <ng-content></ng-content>
    <div class="table__toolbar-right">
      <tui-hosted-dropdown
        *ngIf="editMode"
        [content]="content"
        [(open)]="filterModalWindows['dropdownOpen']"
      >
        <button
          tuiIconButton
          appearance="flat"
          size="m"
          icon="tuiIconFilter"
          type="button"
        ></button>
      </tui-hosted-dropdown>
      <ng-template #content>
        <div class="table__toolbar-list">
          <tui-checkbox-block
            contentAlign="right"
            *ngFor="let column of tableColumnsKeys"
            [(ngModel)]="changedColumns[column]"
            size="s"
          >
            {{ column }}
          </tui-checkbox-block>
        </div>
      </ng-template>
      <ng-container #rightBar></ng-container>
    </div>
  </div>
  <div class="table__container">
    <table class="table__container-template">
      <thead class="table__container-header">
        <tr class="table__container-row">
          <ng-container *ngFor="let column of label">
            <th
              *ngIf="changedColumns[column.name]"
              class="table__container-head"
            >
              <ng-container *ngIf="!column.isFilter">
                <span
                  class="table__container-text"
                  [class.tui-skeleton]="isSkeletonActive"
                >
                  {{ getTitleName(column.name) }}
                </span>
              </ng-container>
              <app-table-filter
                *ngIf="column.isFilter"
                [columnName]="column.name"
                [statusMessagesItems]="statusMessagesItems"
                [filterModalWindows]="filterModalWindows"
                [isSkeletonActive]="isSkeletonActive"
                [label]="label"
                [statusItems]="statusItems"
                [nameItems]="nameItems"
                [reactionItems]="reactionItems"
                [depositNameItems]="depositNameItems"
              ></app-table-filter>
            </th>
          </ng-container>
          <th *ngIf="showSettingBlock" class="table__container-head _small">
            <app-event-buttons
              (handleClickByBack)="eventHandleChange('back')"
              (handleClickReadAll)="eventHandleChange('readAll')"
            >
            </app-event-buttons>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of data">
          <tr class="table__container-row">
            <ng-container
              *ngFor="let column of tableColumnsKeys; let i = index"
            >
              <!--            TODO: костыли в отдельную компоненту-->
              <td *ngIf="row[column] === 'dropdownButton'">
                <div>
                  <app-table-dropdown-button
                    [actionButtons]="actionButtons"
                    (getAction)="selectAction($event, row)"
                  >
                  </app-table-dropdown-button>
                </div>
              </td>
              <td *ngIf="row[column] === 'select'">
                <app-search-selector
                  (handleSelectedItem)="selectOption($event)"
                  [row]="row"
                  [class.tui-skeleton]="items === undefined"
                  title="Оборудование"
                  [idSelectedElement]="selectedDevices"
                  [data]="items"
                >
                </app-search-selector>
              </td>
              <td *ngIf="row[column] === 'btn'">
                <div>
                  <app-table-buttons
                    [buttons]="buttons"
                    (getAction)="selectAction($event, row)"
                  >
                  </app-table-buttons>
                </div>
              </td>
              <td *ngIf="column === 'eventFilterStatus'">
                <tui-badge
                  status="default"
                  [value]="translateBadge[row[column]]"
                >
                </tui-badge>
              </td>
              <td *ngIf="column === 'eventStatus'">
                <tui-badge
                  [value]="translateBadge[row[column]]"
                  status="default"
                >
                </tui-badge>
              </td>
              <td
                *ngIf="
                  notAnyButton(row[column]) &&
                  column !== 'eventFilterStatus' &&
                  column !== 'eventStatus'
                "
              >
                <span class="header-mobile-title">{{ label[i].title }}</span>
                <span [class.tui-skeleton]="isSkeletonActive">
                  {{ row[column] || EMPTY_VALUE }}
                </span>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div *ngIf="paginator" class="table__pagination">
    <div
      class="table__pagination-counter"
      [class.tui-skeleton]="isSkeletonActive"
    >
      <span>Показать по:</span>
      <tui-select
        class="table__pagination-select"
        tuiTextfieldSize="s"
        [(ngModel)]="paginator.countItems"
        (ngModelChange)="changeCountItems()"
        [tuiTextfieldLabelOutside]="true"
      >
        <tui-data-list-wrapper
          *tuiDataList
          [items]="paginator.listOfCountItems"
        >
        </tui-data-list-wrapper>
      </tui-select>
    </div>
    <tui-pagination
      [activePadding]="1"
      [length]="this.paginator.countPages"
      (indexChange)="goToPage($event)"
      [index]="indexPage"
    ></tui-pagination>
  </div>
</div>
