import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ObjectService } from '../../../../services/object.service';
import { PressureService } from '../../../../services/pressure.service';
import { Observable, of, Subject, switchMap } from 'rxjs';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { TuiContextWithImplicit, tuiPure } from '@taiga-ui/cdk';
import { DirectoriesService } from '../../../../services/directories.service';
import {
  AVAILABLE_DEBIT_FIELDS,
  AVAILABLE_FREQ_FIELDS,
} from '../../../../const/app-consts';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import {
  maxValueValidator,
  minValueValidator,
} from '../../../object-cards/dns-card/components/common-info-dns/common-info-dns.component';
import * as memoizee from 'memoizee';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { BoreholeService } from '../../../../services/borehole.service';
import { BoreholeCreateRequest } from '../../../../../models/borehole.model';
import { PipelineService } from '../../../../services/pipeline.service';

//проверка на пробелы (если есть в начале или в конце тоби пипец)
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

type DataForSelectField = {
  id: number | string;
  name: string;
};

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

@Component({
  selector: 'app-borehole-add',
  templateUrl: './borehole-add.component.html',
  styleUrls: ['./borehole-add.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class BoreholeAddComponent implements OnInit, OnDestroy {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  // возвращает созданное месторождение
  @Output() requestCreatedData: EventEmitter<null> = new EventEmitter();

  //тип добычи
  public typeMining = [
    {
      id: 'PPD',
      title: 'ППД',
    },
    {
      id: 'SHGN',
      title: 'ШГН',
    },
    {
      id: 'SHVN',
      title: 'ШВН',
    },
    {
      id: 'ECN',
      title: 'ЭЦН',
    },
    {
      id: 'NONE',
      title: 'Нет',
    },
    {
      id: 'REPAIR',
      title: 'Ремонт',
    },
  ];

  public availableFreqFields = AVAILABLE_FREQ_FIELDS.map((v: any) => ({
    id: v.value,
    name: v.title,
  }));

  public availableDebitFields = AVAILABLE_DEBIT_FIELDS.map((v: any) => ({
    id: v.value,
    name: v.title,
  }));

  public iconBetweenDebits: string = 'tuiIconPlus';

  //форма для скважины
  public formBorehole: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(10),
      checkForFirstSpaceCharacter(),
    ]),
    deposit: new FormControl({ value: null, disabled: true }, [
      Validators.required,
    ]),
    bush: new FormControl({ value: null, disabled: true }),
    oil_parent_id: new FormControl({ value: null, disabled: true }),
    waterCut: new FormControl(null, []),
    debit: new FormControl(null, []),
    typeMining: new FormControl(null, [Validators.required]),
    oil_pipe: new FormControl({ value: null, disabled: true }, []),
    water_pipe: new FormControl({ value: null, disabled: true }, []),
    latitude: new FormControl(null, [
      Validators.required,
      Validators.min(-90),
      Validators.max(90),
    ]),
    longitude: new FormControl(null, [
      Validators.required,
      Validators.min(-180),
      Validators.max(180),
    ]),
  });

  debitDeviceDisable: boolean = true;

  //форма датчиков
  public formSensors: any = new FormGroup({
    status: new FormControl(null),
    freq_device_id: new FormControl(null),
    curr_pressure: new FormControl({ value: null, disabled: true }),
    next_pressure: new FormControl({ value: null, disabled: true }),
    freq_device_field: new FormControl({
      value: null,
      disabled: this.debitDeviceDisable,
    }),
    debits_params_devices: new FormArray([]),
  });

  //для формы -- список скважин
  public depositList: any = [];

  public bushList: any = [];

  public filterBushList: any = [];

  public filterListOfOil: any = {
    gzuList: [],
    dnsList: [],
    bgList: [],
  };

  public listOfOil: any = {
    gzuList: [],
    dnsList: [],
    bgList: [],
  };

  getNameSelectedValue: any = () => {
    return this.formBorehole.get('oil_parent_id')?.value?.name || '';
  };

  //данные статуса
  public availableStatusDevices: any[] = [];

  //давления данные
  public pressureList: any = [];

  public oilPipelineList: any = [];

  public waterPipelineList: any = [];

  public skeletonVisible: boolean = true;

  private destroyer: Subject<any> = new Subject<any>();

  readonly debitDeviceSearch$: Subject<string | null> = new Subject();

  readonly availableDebitDevices$: Observable<readonly any[] | null> =
    this.debitDeviceSearch$.pipe(
      takeUntil(this.destroyer),
      switchMap((search: string | null) =>
        this.filterDebitDevices(search).pipe(
          startWith<readonly any[] | null>(null)
        )
      )
    );

  //магия для статуса
  @tuiPure
  stringify(items: readonly any[]): any {
    const map = new Map(
      items.map(({ id, name }) => [id, name] as [number, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }

  //магия для статуса
  readonly stringifyComboBox = (item: { id: number; name: string }): string =>
    item.name || '';

  //клик по карте(по нему в onInit записываем координаты клика по карте)
  public onClickOnMap = new EventEmitter();

  public latitude: number = 55;

  public longitude: number = 55;

  //флаг для появления ошибки карты(проверка идет при submit)
  // вызывается когда не выбраны координаты
  public errorMap = false;

  public disabledOilField: boolean = false;

  public disabledBushField: boolean = false;

  public memoizedGetSections = memoizee((form: any): any => {
    return form.controls.debits_params_devices.controls;
  });

  public debitSensorErrorMessage: string | null = null;

  constructor(
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
    private objectService: ObjectService,
    private pressureService: PressureService,
    private optionService: DirectoriesService,
    private boreholeService: BoreholeService,
    private pipelineService: PipelineService
  ) {}

  //1) список устройств для статуса
  //2) месторождения с предобработкой
  //3) давление с предобработкой
  //4) подписка на карту (сбор координат точек)
  async ngOnInit() {
    // Запрашиваем данные для полей выбора кустов и объектов нефтесбора
    await this.getValuesForBushAndOilParentFields();

    // Запрашиваем данные для поля выбора месторождения
    await this.getValuesForDepositField();

    // Запрашиваем данные для поля выбора датчика дебита
    await this.getValuesForDebitSensorField();

    // Запрашиваем данные для поля выбора датчика давления
    await this.getValuesForPressureSensorField();

    // Инициализируем карту
    this.mapInitialisation();

    this.formSensors.controls.debits_params_devices.push(
      this.initFormControlsArray()
    );

    // Запрашиваем данные для поля выбора нефтепровода
    await this.getValuesForOilPipelineField();

    // Запрашиваем данные для поля выбора водопровода
    await this.getValuesForWaterPipelineField();

    this.startDebitDeviceErrorMonitoring();
  }

  startDebitDeviceErrorMonitoring() {
    this.formSensors
      .get('debits_params_devices')
      .valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe(() => {
        this.checkDebitDeviceError();
      });
  }

  checkDebitDeviceError() {
    const form = this.formSensors.get('debits_params_devices');

    if (
      form.controls[0].get('debit_device_id').value &&
      !form.controls[0].get('debit_device_field').value
    ) {
      this.debitSensorErrorMessage =
        'Поле "Параметр устройства" обязательно для каждого датчика дебита';
      return;
    }

    if (
      !form.controls[0].get('debit_device_id').value &&
      form.controls[0].get('debit_device_field').value
    ) {
      this.debitSensorErrorMessage = 'Укажите датчик дебита';
      return;
    }

    if (form.controls[1]) {
      if (
        form.controls[1].get('debit_device_id').value &&
        !form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Поле "Параметр устройства" обязательно для каждого датчика дебита';
        return;
      }

      if (
        !form.controls[1].get('debit_device_id').value &&
        form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage = 'Укажите датчик дебита';
        return;
      }

      if (
        !form.controls[0].get('debit_device_id').value ||
        !form.controls[0].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Поля первого датчика дебита заполнены неправильно';
        return;
      }

      if (
        form.controls[0].get('debit_device_id').value ===
          form.controls[1].get('debit_device_id').value &&
        form.controls[0].get('debit_device_field').value ===
          form.controls[1].get('debit_device_field').value
      ) {
        this.debitSensorErrorMessage =
          'Датчики должны различаться по названию и/или параметрам';
        return;
      }
    }

    this.debitSensorErrorMessage = null;
  }

  private async getValuesForOilPipelineField() {
    const oilPipelines = await this.pipelineService.oilPipelinesList;
    this.oilPipelineList = this.prepareArrayForSelectField(oilPipelines);
    this.formBorehole.controls['oil_pipe'].enable();
  }

  private async getValuesForWaterPipelineField() {
    const waterPipelines = await this.pipelineService.waterPipelinesList;
    this.waterPipelineList = this.prepareArrayForSelectField(waterPipelines);
    this.formBorehole.controls['water_pipe'].enable();
  }

  private async getValuesForDepositField() {
    const deposits = await this.objectService.deposits;
    this.depositList = this.prepareArrayForSelectField(deposits);
    this.formBorehole.controls['deposit'].enable();
  }

  private getValuesForDebitSensorField() {
    this.objectService.getDeviceList().subscribe((data: any) => {
      this.availableStatusDevices = this.prepareArrayForSelectField(data);
      this.debitDeviceSearch$.next('');
      this.skeletonVisible = false;
    });
  }

  private async getValuesForPressureSensorField() {
    const pressure = await this.pressureService.pressure;
    this.pressureList = this.prepareArrayForSelectField(pressure);
    this.formSensors.controls.curr_pressure.enable();
    this.formSensors.controls.next_pressure.enable();
  }

  private async getValuesForBushAndOilParentFields() {
    this.listOfOil = {
      gzuList: await this.objectService.gzu,
      dnsList: await this.objectService.dns,
      bgList: await this.objectService.bg,
    };

    this.objectService.getBushList().subscribe((list) => {
      this.bushList = list;
    });

    this.formBorehole
      .get('deposit')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item) => {
        if (item) {
          // Активируем поле для выбора куста
          this.formBorehole.controls['bush'].enable();
          // Активируем поле для выбора объекта нефтесбора
          this.formBorehole.controls['oil_parent_id'].enable();
          this.formBorehole.get('oil_parent_id')?.setValue(null);
          this.formBorehole.get('bush')?.setValue(null);
          this.filterBushList = this.bushList
            .filter((bush: any) => item.id === bush.parent_id)
            .map((element: any) => {
              return { id: element.id, title: element.name };
            });
          if (!this.filterBushList.length) {
            this.formBorehole.get('bush')?.disable();
            this.disabledBushField = true;
          } else {
            this.formBorehole.get('bush')?.enable();
            this.disabledBushField = false;
          }
          this.filterListOfOil = {
            gzuList: this.listOfOil.gzuList.filter(
              (bush: any) => item?.id === bush.parent_id
            ),
            dnsList: this.listOfOil.dnsList.filter(
              (bush: any) => item?.id === bush.parent_id
            ),
            bgList: this.listOfOil.bgList.filter(
              (bush: any) => item?.id === bush.parent_id
            ),
          };
          if (
            !this.filterListOfOil.gzuList.length &&
            !this.filterListOfOil.dnsList.length &&
            !this.filterListOfOil.bgList.length
          ) {
            this.formBorehole.get('oil_parent_id')?.disable();
            this.disabledOilField = true;
          } else {
            this.formBorehole.get('oil_parent_id')?.enable();
            this.disabledOilField = false;
          }
        }
      });
    this.formSensors
      .get('freq_device_id')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe(() => {
        this.formSensors.get('freq_device_field')?.enable();
        this.formSensors.get('freq_device_field')?.setValue(`frequency`);
      });
  }

  private mapInitialisation() {
    this.onClickOnMap
      .pipe(takeUntil(this.destroyer))
      .subscribe((coordinates) => {
        this.errorMap = false;
        this.formBorehole.get('latitude')?.setValue(coordinates.lat);
        this.formBorehole.get('longitude')?.setValue(coordinates.lng);
      });

    this.formBorehole
      .get('latitude')
      ?.valueChanges.pipe(takeUntil(this.destroyer), distinctUntilChanged())
      .subscribe((item: any) => {
        if (this.formBorehole.get('latitude')?.valid) {
          this.latitude = item;
          return;
        }
        return;
      });
    this.formBorehole
      .get('longitude')
      ?.valueChanges.pipe(takeUntil(this.destroyer), distinctUntilChanged())
      .subscribe((item: any) => {
        if (this.formBorehole.get('longitude')?.valid) {
          this.longitude = item;
          return;
        }
        return;
      });
  }

  extractValueFromEvent(event: Event): string | null {
    return (event.target as HTMLInputElement)?.value || null;
  }

  changeIconBetweenDebits() {
    if (this.iconBetweenDebits === 'tuiIconPlus') {
      this.iconBetweenDebits = 'tuiIconMinus';
    } else this.iconBetweenDebits = 'tuiIconPlus';
  }

  //проверка на валидность формы и карты -> если все гуд то отправка
  submitForm() {
    if (!this.formBorehole.valid || !this.checkStatusFreg()) {
      this.alertService
        .open('Проверьте правильность заполнения формы', {
          label: '',
          status: TuiNotification.Error,
        })
        .subscribe();
      this.formBorehole.markAllAsTouched();
      this.formSensors.markAllAsTouched();
      return;
    }
    // Проверка валидности полей датчика дебета
    if (this.debitSensorErrorMessage) {
      this.alertService
        .open('Неправильно заполнены поля датчика дебита', {
          label: '',
          status: TuiNotification.Error,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      return;
    }
    // Если выбран нефтепровод и/или водопровод и не выбран объект нефтесбора, то требуем указать объект нефтесбора
    if (
      !this.formBorehole.get('oil_parent_id')?.value &&
      (this.formBorehole.get('oil_pipe')?.value?.id ||
        this.formBorehole.get('water_pipe')?.value?.id)
    ) {
      this.alertService
        .open(
          'Для выбора нефтепровода и/или водопровода необходимо указать объект нефтесбора',
          {
            label: '',
            status: TuiNotification.Warning,
            hasIcon: true,
            autoClose: true,
            hasCloseButton: true,
          }
        )
        .subscribe();
      return;
    }
    let debitDeviceId: number[] = [];
    let debitDeviceField: string[] = [];
    if (this.formSensors.get('debits_params_devices').controls.length > 0) {
      const values = this.formSensors
        .get('debits_params_devices')
        .controls.map((row: any) => {
          return {
            debit_device_id: row.get('debit_device_id').value,
            debit_device_field: row.get('debit_device_field').value,
          };
        });
      debitDeviceId = values
        .map((v: any) => {
          if (v.debit_device_id?.id) return v.debit_device_id.id;
        })
        .filter((value: number | undefined) => value);
      debitDeviceField = values
        .map((v: any) => {
          if (v.debit_device_field) return v.debit_device_field;
        })
        .filter((value: string | undefined) => value);
    }
    if (debitDeviceId.length !== debitDeviceField.length) {
      this.alertService
        .open('Проверьте правильность заполнения формы', {
          label: '',
          status: TuiNotification.Error,
        })
        .subscribe();
      this.formSensors
        .get('debits_params_devices')
        .controls.map((formGroup: any) => {
          formGroup.controls.debit_device_id.updateValueAndValidity({
            onlySelf: true,
          });
          formGroup.controls.debit_device_field.updateValueAndValidity({
            onlySelf: true,
          });
        });
      return this.formSensors.markAllAsTouched();
    }

    const body: BoreholeCreateRequest = {
      type: 'borehole',
      name: this.formBorehole.get('name')?.value,
      parent_id: this.formBorehole.get('deposit')?.value?.id,
      bush_parent_id: this.formBorehole.get('bush')?.value?.id || null,
      oil_parent_id: this.formBorehole.get('oil_parent_id')?.value?.id || null,
      latitude:
        typeof this.formBorehole.get('latitude')?.value === 'number'
          ? this.formBorehole.get('latitude')?.value
          : null,
      longitude:
        typeof this.formBorehole.get('longitude')?.value === 'number'
          ? this.formBorehole.get('longitude')?.value
          : null,
      attributes: {
        debit: this.formBorehole.get('debit')?.value,
        water_cut: this.formBorehole.get('waterCut')?.value,
        type_mining: this.formBorehole.get('typeMining')?.value.id,
      },
      object_params: [],
      curr_pressure_id:
        this.formSensors.get('curr_pressure')?.value?.id || null,
      next_pressure_id:
        this.formSensors.get('next_pressure')?.value?.id || null,
      debit_device_id: debitDeviceId,
      debit_device_field: debitDeviceField,
      freq_device_field:
        this.formSensors.get('freq_device_field')?.value || null,
      status_device_id: this.formSensors.get('status')?.value?.id || null,
      freq_device_id: this.formSensors.get('freq_device_id')?.value?.id || null,
      oil_pipe_id: this.formBorehole.get('oil_pipe')?.value?.id || null,
      water_pipe_id: this.formBorehole.get('water_pipe')?.value?.id || null,
      debit_calculate_option: debitDeviceId.length
        ? this.iconBetweenDebits === 'tuiIconPlus'
          ? 'add'
          : 'subtract'
        : null,
    };

    this.boreholeService.createBorehole(body).subscribe(() => {
      this.requestCreatedData.emit();
      this.closeDialog();
    });
  }

  //  очистка полей и закрытие окна
  closeDialog() {
    this.errorMap = false;
    this.formSensors.reset({
      status: null,
      pressure: null,
    });
    this.formBorehole.reset({
      name: '',
      deposit: {
        value: 'none',
        label: 'Не выбрано',
      },
      typeMining: {
        value: 'none',
        label: 'Не выбрано',
      },
      waterCut: '',
      debit: 0,
    });
    this.closeModal();
  }

  checkStatusFreg() {
    return this.formSensors.get('freq_device_id')?.value
      ? this.formSensors.get('freq_device_id')?.value &&
          this.formSensors.get('freq_device_field')?.value
      : true;
  }

  initFormControlsArray() {
    const formGroup = new FormGroup({
      debit_device_id: new FormControl(null, []),
      debit_device_field: new FormControl(null, []),
    });

    formGroup.get('debit_device_field')?.disable();
    formGroup
      .get('debit_device_id')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (!item) {
          formGroup.get('debit_device_field')?.disable();
          return;
        }
        formGroup.get('debit_device_field')?.enable();
        formGroup.get('debit_device_field')?.setValidators(Validators.required);
      });
    setTimeout(() => {
      this.debitDeviceSearch$.next('');
    });
    return formGroup;
  }

  add() {
    const control = <FormArray>this.formSensors.get('debits_params_devices');
    control.push(this.initFormControlsArray());
  }

  remove(i: number) {
    const control = <FormArray>this.formSensors.get('debits_params_devices');
    control.removeAt(i);
  }

  private filterDebitDevices(
    searchQuery: string | null
  ): Observable<readonly any[]> {
    const result = this.availableStatusDevices.filter((device: any) =>
      device.name.toLowerCase().includes((searchQuery || '').toLowerCase())
    );
    result.splice(20);
    return of(result);
  }

  onDebitSearchChange(s: string | null) {
    this.debitDeviceSearch$.next(s ?? '');
  }

  prepareArrayForSelectField(array: any): DataForSelectField[] {
    return array.map((v: DataForSelectField) => ({
      id: v.id,
      name: v.name,
    }));
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
