import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-form-select",
  templateUrl: "./form-select.component.html",
  styleUrls: ["./form-select.component.less"],
})
export class FormSelectComponent {
  @Input()
  form!: FormGroup;

  @Input()
  formControlKey!: string;

  @Input()
  label!: string;

  @Input()
  visualRequired!: boolean;

  @Input()
  nativeId!: string;

  @Input()
  tuiTextfieldMaxLength!: number | null;

  @Input()
  tuiTextfieldExampleText!: string;

  @Input()
  items: readonly any[] = [];

  @Input() isGrouped!: boolean;

  get getGroups(): any {
    if (!this.isGrouped || !this.items) {
      return [];
    }
    return new Set(this.items.map((item: any) => item.group));
  }

  getItemsByGroup(groupName: string) {
    if (!this.items) {
      return [];
    }
    return this.items.filter((item: any) => item.group === groupName);
  }

  get itemContent() {
    if (this.items && this.form?.value[this.formControlKey]) {
      return (
        this.items.find((i: any) => {
          return i.value === this.form?.value[this.formControlKey];
        })?.label || ``
      );
    }
    return "";
  }
}
