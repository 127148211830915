<ng-container [formGroup]="form">
  <tui-checkbox-block
    [formControlName]="formControlKey"
    [nativeId]="nativeId"
    [hideCheckbox]="hideCheckbox"
    [size]="sizeBlock"
    [contentAlign]="contentAlign"
  >
    {{ content }}
  </tui-checkbox-block>
</ng-container>
