import { Component } from "@angular/core";

@Component({
  selector: "app-plus",
  templateUrl: "./plus.component.html",
  styleUrls: ["./plus.component.less"],
})
export class PlusComponent {
  addWidget() {
    // открытие попапа в дальнейшем
  }
}
