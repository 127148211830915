<div *ngIf="selectedObject">
  <app-primary-section>
    <h2 class="oilfield-popup__title">
      {{ selectedObject?.name || "object" }}
    </h2>
    <tui-tabs class="tabs">
      <button
        class="tabs__btns"
        tuiTab
        *ngFor="let tab of tabs"
        (click)="changeTabElement(tab)"
      >
        <div>
          {{ tab.title }}
        </div>
      </button>
    </tui-tabs>
  </app-primary-section>
  <div class="container__form" *ngIf="activeItemIndex.url === 'info'">
    <app-common-info-ktp
      [contextObject]="selectedObject"
      (handleChange)="getEditableDeposit()"
    >
    </app-common-info-ktp>
  </div>
</div>
