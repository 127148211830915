<div class="inline-table">
  <div class="header">
    <span>Информация о датчиках</span>
    <app-edit-buttons
      [isEdit]="isEdit"
      (handelChange)="changeMode()"
      (handelSave)="onSaveParams()"
      (handelCancel)="changeMode()"
    ></app-edit-buttons>
  </div>
  <div class="table">
    <div class="row" *ngFor="let row of sensors">
      <span class="label">{{ row.name }}</span>
      <div class="label__edit-input" [formGroup]="form" *ngIf="isEdit">
        <app-common-selector
          *ngIf="isSelectorType(row.control)"
          [data]="memoizedGetCurrentArray(row.id)"
          [content]="itemContent(row.id)"
          [form]="form"
          [name]="row.id"
        ></app-common-selector>
        <div *ngIf="isSearchSelectorType(row.control)">
          <app-form-select-search
            [form]="form"
            [formControlKey]="row.id"
            [visualRequired]="false"
            [isGrouped]="false"
            [items]="memoizedGetCurrentArray(row.id)"
          >
          </app-form-select-search>
          <tui-field-error
            *ngIf="this.form.get(row.id)?.touched"
            style="margin-bottom: 15px"
            [formControlName]="row.id"
          ></tui-field-error>
        </div>
        <button
          tuiIconButton
          type="button"
          size="s"
          class="cross"
          appearance="menu"
          icon="tuiIconClose"
          (click)="delete(row.id)"
        ></button>
      </div>
      <span class="selected" *ngIf="!isEdit">{{ row.value }}</span>
    </div>
  </div>
</div>
