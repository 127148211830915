<app-primary-section>
  <app-primary-table
    [isSearch]="true"
    [isHasButtonAdd]="true"
    (toggleTableClick)="handleAddUser()"
    [columns]="primaryTableColumns"
    (sorted)="sortTable($event)"
    (search)="handleSearch($event)"
    [disabled]="false"
  >
    <tr #row *ngFor="let user of filteredUsers$ | async" class="borehole__row">
      <td style="text-align: center">
        <tui-toggle
          size="l"
          (ngModelChange)="handleClickToggleActive(user.id)"
          [(ngModel)]="user.active"
        ></tui-toggle>
      </td>
      <td>
        {{ user.username }}
      </td>
      <td>
        {{ user.full_name }}
      </td>
      <td>
        {{ user.phone }}
      </td>
      <td>
        {{ user.duty }}
      </td>
      <td>
        {{ getTranslatedAccess(user.access) }}
      </td>
      <td>
        {{ user.comment }}
      </td>
      <td>
        <button
          tuiIconButton
          size="s"
          type="button"
          class="tui-space_right-3 tui-space_bottom-3"
          [icon]="iconEdit"
          (click)="handleEditUser(user)"
        ></button>
        <button
          size="s"
          tuiIconButton
          type="button"
          class="tui-space_right-3 tui-space_bottom-3 button-remove"
          [icon]="iconDelete"
          (click)="onDeleteUser(user)"
        ></button>
      </td>
    </tr>
  </app-primary-table>
</app-primary-section>
<ng-template #iconEdit>
  <tui-svg src="tuiIconEdit" class="icon"></tui-svg>
</ng-template>
<ng-template #iconDelete>
  <tui-svg src="tuiIconClose" class="icon"></tui-svg>
</ng-template>
<ng-template
  [tuiDialogOptions]="dialogDeleteUser"
  [(tuiDialog)]="openDeletePopup"
>
  <app-delete-dialog
    (updateTableAfterDelete)="updateUsers()"
    [closeModal]="closeDeleteModal"
    [handleSubmit]="handleDeleteUser"
    typeObject="user"
    [title]="
      'Вы уверены что хотите удалить пользователя ' +
      deletedUser?.full_name +
      '[' +
      deletedUser?.id +
      ']?'
    "
  ></app-delete-dialog>
</ng-template>
