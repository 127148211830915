import { Injectable } from '@angular/core';
import { TypeTree } from '../../models/tree.model';

@Injectable({
  providedIn: 'root',
})
export class TreeService {
  findObjectByID(treeData: Array<TypeTree>, id: number): TypeTree {
    return this.infinityFinder(treeData, id);
  }

  obj!: TypeTree;

  private infinityFinder(tree: Array<TypeTree> | TypeTree, id: number) {
    if (Array.isArray(tree)) {
      tree.map((item: any) => this.infinityFinder(item, id));
    } else {
      if (tree.children && tree.children.length) {
        tree.children.map((item: any) => {
          this.infinityFinder(item, id);
        });
        if (tree.id === id) {
          this.obj = tree;
        }
      } else {
        if (tree.id === id) {
          this.obj = tree;
        }
      }
    }
    return this.obj;
  }
}
