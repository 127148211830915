import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import * as memoizee from 'memoizee';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { Subject, takeUntil } from 'rxjs';
import { PressureItemType } from 'src/models/pressure-meter.model';
import { DeviceModel } from 'src/models/device.model';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { BodyModel } from '../../../../../../../../../models/option.model';
import { ObjectService } from '../../../../../../../../services/object.service';
import { DirectoriesService } from '../../../../../../../../services/directories.service';
import { PressureService } from '../../../../../../../../services/pressure.service';
import { TypeTree } from '../../../../../../../../../models/tree.model';
import { CommonDataForCycle } from '../../../../../../../../../models/common-info.model';

function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}
@Component({
  selector: 'app-sensors-object-oilfield',
  templateUrl: './sensors-object-oilfield.component.html',
  styleUrls: ['./sensors-object-oilfield.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService],
})
export class SensorsObjectOilfieldComponent implements OnInit, OnDestroy {
  //выбранные настройки параметров
  @Input()
  public selectedOption: BodyModel[] = [];

  //выбранные устройства
  public selectedDevice: DeviceModel | null = null;

  //выбранный статус давления
  public selectedPressure: PressureItemType | null = null;

  //список устройств
  public listDevice: DeviceModel[] = [];

  //список устройств давлений
  public listPressure: PressureItemType[] = [];

  //бум дыщь бум
  public destroyer: Subject<null> = new Subject();

  //режим редактирования
  public isEdit: boolean = false;

  //id выбранного объекта с дерева
  public objectID!: number;

  //форма под датчики
  public form: FormGroup = new FormGroup({
    status: new FormControl({ label: 'Не выбран', value: null }, [
      autocompleteObjectValidator(),
    ]),
    pressure: new FormControl({ label: 'Не выбран', value: null }),
  });

  //настройка селекторов
  public sensors = [
    {
      id: 'status',
      name: 'Статус',
      value: 'Прибор не выбран',
      control: 'search-selector',
    },
    {
      id: 'pressure',
      name: 'Давление',
      value: 'Прибор не выбран',
      control: 'selector',
    },
  ];

  public validation_msgs = {
    contactAutocompleteControl: [
      {
        type: 'invalidAutocompleteObject',
        message:
          'Имя объекта не распознано. Щелкните один из вариантов автозаполнения.',
      },
      { type: 'required', message: 'Поле обязательно для заполнения.' },
    ],
  };

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    private objectService: ObjectService,
    private optionService: DirectoriesService,
    private pressureService: PressureService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /*
  1) забираем id выбранного объекта
  2) получения  устройства
  3) получения  датчикво давления
  4) получения списка датчиков давления
  5) получения выбранного датчика давления если его нет то null
  6) получения имени по id датчика давления
  * */
  ngOnInit(): void {
    this.objectService.selectedObject
      .pipe(takeUntil(this.destroyer))
      .subscribe(async (object: TypeTree) => {
        this.objectID = object.id;
        await this.getDeviceItems(object.status_device_id || null);
        this.listPressure = await this.pressureService.pressure;
        this.selectedPressure = object.pressure || null;
        this.selectValueToTableById(
          'pressure',
          this.selectedPressure?.name || null
        );
        this.form.get('pressure')?.setValue({
          label: this.selectedPressure?.name || 'Не выбран',
          value: this.selectedPressure?.id || null,
        });
      });
  }

  /*
  1) полчения списка устройств
  2) поиск выбаррного устройста по id  и получения его в коорректном для вывода виде
  * */
  async getDeviceItems(id: number | null) {
    this.listDevice = await this.objectService.devices;
    this.selectedDevice = this.findByIDDevice(this.listDevice, id);
    this.form.get('status')?.setValue({
      label: this.selectedDevice?.name || 'Не выбран',
      value: id || null,
    });
    this.selectValueToTableById('status', this.selectedDevice?.name || null);
  }

  //поиск по id  выбранного устройства
  findByIDDevice(
    devices: DeviceModel[],
    subjectComparison: number | null
  ): DeviceModel | null {
    return (
      devices.find((item: DeviceModel) => {
        return item.id === subjectComparison;
      }) || null
    );
  }

  //возварщение имени выбранного объекта или присваение ему то что прибор Не выбран
  selectValueToTableById(key: string, value: string | null) {
    const valueToPass: string = value ? value : 'Прибор не выбран';
    this.sensors = this.sensors.map((item) => {
      return item.id === key ? { ...item, value: valueToPass } : { ...item };
    });
    this.changeDetectorRef.detectChanges();
  }

  //бум бада бум
  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }

  //по какому ключу вывод в селектор
  itemContent(id: string): string {
    return this.form?.get(id)?.value?.label;
  }

  //изменение режима (редактирование/просмотр)
  changeMode() {
    this.isEdit = !this.isEdit;
    this.form.get('pressure')?.setValue({
      label: this.selectedPressure?.name || 'Не выбран',
      value: this.selectedPressure?.id || null,
    });
    this.form.get('status')?.setValue({
      label: this.selectedDevice?.name || 'Не выбран',
      value: this.selectedDevice?.id || null,
    });
  }

  //сохрарение данных
  onSaveParams() {
    if (!this.form.valid) {
      return;
    }
    this.optionService
      .setParamObject(this.objectID as number, {
        object_params: this.selectedOption,
        pressure_id: this.form.get('pressure')?.value.value,
        status_device_id: this.form.get('status')?.value.value,
      })
      .subscribe(() => {
        this.objectService
          .getObjectByID(this.objectID as number)
          .subscribe((data: TypeTree) => {
            this.objectService.selectedObject.next(data);
            this.isEdit = !this.isEdit;
          });
        this.objectService
          .getDataToTree()
          .subscribe((data: { tree: Array<TypeTree> }) => {
            this.objectService.updateTree.next(data);
          });
        this.notificationsService
          .open('Настройки параметров сохранены', {
            status: TuiNotification.Success,
          })
          .subscribe();
      });
  }

  //use memoizee, чтобы не заходить второй раз в функию, если передаем такой же id
  public memoizedGetCurrentArray = memoizee((id: string) => {
    switch (id) {
      case 'status':
        return this.transformDataForSelector(this.listDevice);
      case 'pressure':
        return this.transformDataForSelector(this.listPressure);
      default:
        return [];
    }
  });

  transformDataForSelector(
    data: PressureItemType[] | DeviceModel[]
  ): CommonDataForCycle[] {
    return data.map((item: PressureItemType | DeviceModel) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
  }

  isSelectorType(control: string): boolean {
    return control === 'selector';
  }

  isSearchSelectorType(control: string): boolean {
    return control === 'search-selector';
  }

  delete(id: string) {
    this.form.get(id)?.markAsTouched();
    this.form.get(id)?.setValue({ label: 'Не выбран', value: null });
  }
}
