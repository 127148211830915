<app-primary-section>
  <tui-loader
    class="oilfield"
    [showLoader]="isReloading"
    [inheritColor]="isReloading"
    [overlay]="isReloading"
  >
    <div class="no-boreholes" *ngIf="boreholes.length === 0">
      В данном ГЗУ скважин нет
    </div>
    <app-primary-table
      [isSort]="false"
      [columns]="tableColumns"
      [disabled]="false"
      *ngIf="boreholes.length > 0"
    >
      <tr *ngFor="let row of boreholes">
        <td (click)="openBorehole(row.id)">{{ row.id }}</td>
        <td (click)="openBorehole(row.id)">{{ row.name }}</td>
        <td (click)="openBorehole(row.id)">-</td>
        <td (click)="openBorehole(row.id)">
          <tui-badge
            *ngIf="row.status; else emptyStatus"
            [value]="statusTitles[row.status]"
            [attr.data-status]="row.status"
            class="status"
          >
          </tui-badge>
          <ng-template #emptyStatus>-</ng-template>

          <!--        <app-table-dropdown-button></app-table-dropdown-button>-->
        </td>
        <td (click)="openBorehole(row.id)">
          {{ TYPE_MINING[row.attributes?.type_mining] || "-" }}
        </td>
        <td (click)="openBorehole(row.id)">
          {{ OBJECTS_DEBIT[row.attributes?.debit] || "-" }}
        </td>
        <td (click)="openBorehole(row.id)">
          {{ row.attributes?.water_cut || "-" }}
        </td>
        <td (click)="openBorehole(row.id)">{{ getFreq(row) }}</td>
        <td (click)="openBorehole(row.id)">{{ getWorkTime(row.work_time) }}</td>
        <td>
          <tui-hosted-dropdown
            #dropdown
            [tuiDropdownMaxHeight]="500"
            [content]="content"
          >
            <button
              tuiIconButton
              appearance="flat"
              type="button"
              icon="tuiIconMoreHorLarge"
              [pseudoHovered]="dropdown.open || null"
            ></button>
          </tui-hosted-dropdown>
          <ng-template #content>
            <tui-data-list>
              <!--            <button (click)="deleteNode(item)" tuiOption size="l">-->
              <!--              Запустить-->
              <!--            </button>-->
              <button (click)="deleteNode(row)" tuiOption size="l">
                Удалить
              </button>
            </tui-data-list>
          </ng-template>
        </td>
      </tr>
    </app-primary-table>
  </tui-loader>
</app-primary-section>
<ng-template
  [tuiDialogOptions]="dialogDeleteObject"
  [(tuiDialog)]="openDeletePopup"
>
  <app-delete-dialog
    (updateTableAfterDelete)="updateTable()"
    [data]="selectedForDeleteObject"
    [closeWithHandleClick]="true"
    (handleClick)="closeDeleteDialog($event)"
  ></app-delete-dialog>
</ng-template>
