<div class="scheme-viewer">
  <ng-container *ngIf="selectedObject">
    <ng-container *ngIf="selectedObject.scheme">
      <button
        tuiButton
        type="button"
        size="s"
        appearance="primary"
        class="tui-space_top-3 tui-space_right-3 tui-space_bottom-3 edit_button"
        (click)="handleClick()"
        routerLink="/main/scheme-editor/{{ currentObjectId }}"
      >
        Редактировать
      </button>
      <tui-tooltip direction="bottom-right" [content]="help"></tui-tooltip>
      <ng-template #help>
        <div class="hint">Для зума shift + колесико мыши</div>
      </ng-template>
      <tui-loader [showLoader]="loader" [inheritColor]="true"></tui-loader>
      <div class="buttons-zoom">
        <button (click)="upZoom()">+</button>
        <button (click)="downZoom()">-</button>
      </div>
      <div #schemeContent class="scheme-container">
        <div id="scheme-view"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="!selectedObject.scheme">
      <button
        tuiButton
        type="button"
        size="s"
        appearance="primary"
        class="tui-space_top-3 tui-space_right-3 tui-space_bottom-3"
        (click)="handleClick()"
        routerLink="/main/scheme-editor/{{ currentObjectId }}"
      >
        Добавить
      </button>
      <div class="no-content">У объекта нет схемы, Вы можете ее добавить</div>
    </ng-container>
  </ng-container>
  <ng-container>
    <div *ngIf="!selectedObject" class="common-info__no-content">
      Выберите объект
    </div>
  </ng-container>
</div>
