import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-bar',
  templateUrl: './report-bar.component.html',
  styleUrls: ['./report-bar.component.less'],
})
export class ReportBarComponent implements OnInit {
  @Input() timePeriods: any[] = [];

  @Input() reportName: string = 'connection';

  public maxWidthTime = 0;

  ngOnInit(): void {
    if (this.timePeriods && this.timePeriods.length > 0) {
      this.getAllWidth();
    }
  }

  getAllWidth() {
    let startPeriod: any = null;
    let endPeriod: any = null;
    if (this.timePeriods.length === 0) {
      startPeriod = this.timePeriods[0];
      endPeriod = this.timePeriods[0];
    } else {
      startPeriod = this.timePeriods[0];
      endPeriod = this.timePeriods[this.timePeriods.length - 1];
    }
    this.maxWidthTime = parseFloat(String(endPeriod.to - startPeriod.from));
  }

  getItemWidthInPercent(from: number, to: number): string {
    return `${parseFloat(String((100 * (to - from)) / this.maxWidthTime))}%`;
  }

  getClassByItem(item: any) {
    if (this.reportName === 'connection') {
      return ['bar-item', item.connect ? 'green' : 'gray'];
    }
    if (this.reportName === 'measurement') {
      return ['bar-item', item.active ? 'green' : 'red'];
    }
    return '';
  }
}
