<div *ngIf="contextObject">
  <app-primary-section>
    <form [formGroup]="objectInfoForm" class="form" *ngIf="editObjectMode">
      <div class="inline-table">
        <div class="header">
          <span>ДНС</span>
          <app-common-buttons-dns
            [isEdit]="editObjectMode"
            (handelCancel)="cancelInput()"
            (handelChange)="changeInput()"
            (handelSave)="save()"
          >
          </app-common-buttons-dns>
        </div>
        <div class="form-table">
          <div class="row">
            <div class="form-label">Название *</div>
            <div class="value">
              <tui-input
                style="width: 90%"
                formControlName="name"
                tuiTextfieldSize="m"
              >
                Введите название
                <input maxlength="40" tuiTextfield type="text" />
              </tui-input>
              <tui-field-error formControlName="name"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Месторождение *</div>
            <div class="value">
              <tui-select
                style="width: 90%"
                formControlName="deposit"
                [valueContent]="typeValueContent"
                tuiTextfieldSize="m"
              >
                <tui-data-list-wrapper
                  *tuiDataList
                  [items]="depositList"
                  [itemContent]="valueContent"
                >
                </tui-data-list-wrapper>
              </tui-select>
              <ng-template #typeValueContent let-data>
                {{ data.title }}
              </ng-template>
              <ng-template #valueContent let-data>
                {{ data.title }}
              </ng-template>
              <tui-field-error formControlName="deposit"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Широта *</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                [precision]="14"
                tuiTextfieldSize="m"
                formControlName="latitude"
              >
                Введите широту
              </tui-input-number>
              <tui-field-error formControlName="latitude"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Долгота *</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                [precision]="14"
                tuiTextfieldSize="m"
                formControlName="longitude"
              >
                Введите долготу
              </tui-input-number>
              <tui-field-error formControlName="longitude"></tui-field-error>
            </div>
          </div>
        </div>
        <span class="form-footer">* Обязательные для заполнения поля</span>
      </div>
    </form>
    <div class="inline-table" *ngIf="!editObjectMode">
      <div class="header">
        <span>ДНС</span>
        <app-common-buttons-dns
          [isEdit]="editObjectMode"
          (handelCancel)="cancelInput()"
          (handelChange)="changeInput()"
          (handelSave)="save()"
        >
        </app-common-buttons-dns>
      </div>
      <div class="table">
        <div class="row">
          <div class="label">Название</div>
          <div class="value">
            {{ contextObject.name }}
          </div>
        </div>
        <div class="row">
          <div class="label">Месторждение</div>
          <div class="value">
            {{ contextObject?.parent?.name || "Не выбрано" }}
          </div>
        </div>
        <div class="row">
          <div class="label">Широта</div>
          <div class="value">
            <td>
              {{
                contextObject?.latitude !== null ? contextObject?.latitude : "-"
              }}
            </td>
          </div>
        </div>
        <div class="row">
          <div class="label">Долгота</div>
          <div class="value">
            <td>
              {{
                contextObject?.longitude !== null
                  ? contextObject?.longitude
                  : "-"
              }}
            </td>
          </div>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div
      *ngIf="
        (contextObject?.latitude < 90 &&
          contextObject?.latitude > -90 &&
          contextObject?.longitude < 180 &&
          contextObject?.longitude > -180) ||
          editObjectMode;
        else unavailableCoords
      "
    >
      <app-dns-card-map
        [onClickOnMap]="onClickOnMap"
        [data]="contextObject"
        [isEdit]="editObjectMode"
        [latitude]="latitude"
        [longitude]="longitude"
        [visibleMap]="visibleMap"
      ></app-dns-card-map>
    </div>
    <ng-template #unavailableCoords>
      <div class="unavailable-map">
        <span class="unavailable-map__text">
          Точка на карте расположена вне области видимости карты, измените
          координаты в соответствии с допустимыми значениями, а именно: значения
          широты должны быть менее 90, но более -90, значения долготы должны
          быть менее 180, но более -180, чтобы отобразить точку на карте
        </span>
      </div>
    </ng-template>
  </app-primary-section>
</div>
<div *ngIf="!contextObject" class="common-info__no-content">
  Выберите объект
</div>
