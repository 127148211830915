<header
  class="tui-container_fullwidth"
  [class.container_expand]="isOpenMenu && state === 'expanded'"
  [@expandedSidebar]="state"
>
  <button
    *ngIf="isTransition"
    tuiIconButton
    type="button"
    appearance="menu"
    class="menu-sidebar_btn__toggle"
    [icon]="''"
    size="m"
  ></button>
  <button
    *ngIf="!isTransition"
    tuiIconButton
    type="button"
    appearance="menu"
    class="menu-sidebar_btn__toggle"
    style="z-index: 100"
    [icon]="isOpenMenu ? 'tuiIconRemoveLarge' : 'tuiIconMenuLarge'"
    (click)="toggleMenu()"
    size="m"
  ></button>
  <ng-content></ng-content>
</header>
