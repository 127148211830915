import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TuiHorizontalDirection, TuiSizeL, TuiSizeS } from "@taiga-ui/core";

@Component({
  selector: "app-form-checkbox-block",
  templateUrl: "./form-checkbox-block.component.html",
  styleUrls: ["./form-checkbox-block.component.less"],
})
export class FormCheckboxBlockComponent {
  @Input()
  form!: FormGroup;

  @Input()
  formControlKey!: string;

  @Input()
  content!: string;

  @Input()
  nativeId!: string;

  @Input()
  hideCheckbox!: boolean;

  @Input()
  sizeBlock!: TuiSizeS | TuiSizeL;

  @Input()
  contentAlign!: TuiHorizontalDirection;
}
