import {
  AfterViewInit,
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

@Component({
  selector: "app-normal-layout",
  templateUrl: "./normal-layout.component.html",
  styleUrls: ["./normal-layout.component.less"],
})
export class NormalLayoutComponent implements AfterViewInit {
  @Input()
  leftComponent!: TemplateRef<any>;

  @Input()
  rightComponent!: TemplateRef<any>;

  @Input()
  isEditMode: boolean = false;

  @ViewChild("content", { read: ViewContainerRef })
  content!: ViewContainerRef;

  @ViewChild("secondContent", { read: ViewContainerRef })
  secondContent!: ViewContainerRef;

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.content && this.leftComponent) {
        this.content.createEmbeddedView(this.leftComponent);
      }
      if (this.rightComponent && this.secondContent) {
        this.secondContent.createEmbeddedView(this.rightComponent);
      }
    }, 0);
  }
}
