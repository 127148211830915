import { Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { Subject } from 'rxjs';
import { DesignService } from '../../services/design.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        email: `Пожалуйста, введите валидный email адрес`,
        minlength: `Пароль должен состоять не менее из 8-ми символов `,
      },
    },
  ],
})
export class LoginComponent implements OnDestroy {
  authorizationForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  public hide: boolean = true;

  public destroyer = new Subject();

  constructor(
    private authService: AuthService,
    private router: Router,
    private tokenService: TokenService,
    private designService: DesignService
  ) {}

  onSubmit() {
    this.authService
      .postAuthorization({
        username: this.authorizationForm.controls['email'].value,
        password: this.authorizationForm.controls['password'].value,
      })
      .subscribe((token: any) => {
        this.tokenService.setTokenToCookie(token.token, token.expires_at);
        this.router.navigate(['/main/oilfield']).then();
      });
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
