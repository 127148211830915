import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  InputCoordinateData,
  InputType,
} from '../../../../../../../../../models/content-field.model';

@Component({
  selector: 'app-input-number-field',
  templateUrl: './input-number-field.component.html',
  styleUrls: ['./input-number-field.component.less'],
})
export class InputNumberFieldComponent {
  @Input()
  formGroup!: FormGroup;

  @Input()
  settings: InputType = {
    controlName: '',
    placeholder: '',
    maxLength: 50,
    title: '',
    isRequired: true,
  };

  @Output()
  handleInput: EventEmitter<InputCoordinateData> = new EventEmitter();

  inputElement(data: Event) {
    if (this.settings.isRequiredInput) {
      this.handleInput.emit({
        coordinate: +(data.target as HTMLInputElement).value,
        controlName: this.settings.controlName,
      });
    }
  }
}
