<div class="content">
  <div class="tree__template">
    <div *ngIf="!disable" class="tree">
      <perfect-scrollbar>
        <app-tree
          [treeData]="treeData"
          [schemeMode]="true"
          [hasStatusBlock]="false"
          (handleChangeByText)="getObjectFromTree($event)"
        >
        </app-tree>
      </perfect-scrollbar>
    </div>
    <div class="change-bar">
      <div *ngIf="!disable" class="header">
        <div class="controls">
          <button
            tuiButton
            size="s"
            style="background: coral"
            type="button"
            appearance="primary"
            class="tui-space_right-3"
            (click)="addObject('pipe')"
          >
            Добавить трубопровод
          </button>
          <button
            tuiButton
            size="s"
            style="background: coral"
            type="button"
            class="tui-space_right-3"
            (click)="addObject('electro-line')"
          >
            Добавить линию электричества
          </button>
        </div>
        <!--          <app-controls (handleAddObject)="addObjectToCanvas($event)"-->
        <!--                        [lineArray]="lineArray">-->
        <!--          </app-controls>-->
        <button tuiButton size="s" class="save" (click)="saveScheme()">
          Сохранить
        </button>
      </div>
      <canvas class="scheme" id="scheme"></canvas>
    </div>
  </div>
  <ng-container *ngIf="changeSettingOptionMenu">
    <div class="setting-block">
      <div class="setting-block__container">
        <ng-container *ngIf="listOfOption.length; else noOptions">
          <mat-form-field appearance="outline">
            <mat-label>Параметры</mat-label>
            <mat-select [(ngModel)]="optionIDs[lastSelectedObjectID]" multiple>
              <mat-option
                *ngFor="let option of listOfOption"
                (click)="addTextByID(option)"
                [value]="option['param'].id"
                >{{ option["param"].name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-template #noOptions>
          <span>нет параметров</span>
        </ng-template>
        <button tuiButton size="s" (click)="saveOption()">Закрыть</button>
      </div>
    </div>
  </ng-container>
</div>
