import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map, Observable, Subject } from 'rxjs';
import {
  TypePipelineListModel,
  TypePipelineModel,
} from '../../models/pipeline.model';

@Injectable({ providedIn: 'root' })
export class PipelineService {
  public pipelinesAnticorrosiveOptions = [
    { id: null, value: 'Не выбрано' },
    { id: false, value: 'Нет' },
    { id: true, value: 'Есть' },
  ];

  // Массив для кэширования списка водопроводов
  private waterPipelinesListCache: TypePipelineModel[] = [];

  // Subject для информирования компонентов об изменениях в списке трубопроводов
  public waterPipelinesListSubject: Subject<TypePipelineModel[]> = new Subject<
    TypePipelineModel[]
  >();

  // Массив для кэширования списка нефтепроводов
  private oilPipelinesListCache: TypePipelineModel[] = [];

  // Subject для информирования компонентов об изменениях в списке нефтепроводов
  public oilPipelinesListSubject: Subject<TypePipelineModel[]> = new Subject<
    TypePipelineModel[]
  >();

  constructor(private http: HttpClient) {}

  importWaterPipelinesFromFile(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<{ list: TypePipelineModel[] }>(
        `${environment.apiUrl}import/water_pipe/`,
        formData
      )
      .pipe(
        map((response: { list: TypePipelineModel[] }) => {
          this.waterPipelinesListCache = response.list;
          this.waterPipelinesListSubject.next(this.waterPipelinesListCache);
          return response.list;
        })
      );
  }

  get waterPipelinesList(): Promise<TypePipelineModel[]> {
    return new Promise((resolve) => {
      if (this.waterPipelinesListCache.length) {
        resolve(this.waterPipelinesListCache);
      } else {
        return this.getWaterPipelineList().subscribe(
          (list: TypePipelineModel[]) => {
            resolve(list);
          }
        );
      }
      return;
    });
  }

  getWaterPipelineList(): Observable<TypePipelineModel[]> {
    return this.http
      .get<{ list: TypePipelineModel[] }>(
        `${environment.apiUrl}water_pipeline/list/`
      )
      .pipe(
        map((response: { list: TypePipelineModel[] }) => {
          this.waterPipelinesListCache = response.list;
          this.waterPipelinesListSubject.next(this.waterPipelinesListCache);
          return response.list;
        })
      );
  }

  createWaterPipeline(
    waterPipelineBody: TypePipelineListModel
  ): Observable<TypePipelineListModel> {
    return this.http
      .post<TypePipelineModel>(
        `${environment.apiUrl}water_pipeline/create/`,
        waterPipelineBody
      )
      .pipe(
        map((response: TypePipelineModel) => {
          this.waterPipelinesListCache.push(response);
          this.waterPipelinesListSubject.next(this.waterPipelinesListCache);
          return response;
        })
      );
  }

  updateWaterPipeline(
    waterPipelineId: number,
    waterPipelineBody: TypePipelineListModel
  ) {
    return this.http
      .put<TypePipelineModel>(
        `${environment.apiUrl}water_pipeline/update/${waterPipelineId}/`,
        waterPipelineBody
      )
      .pipe(
        map((response: TypePipelineModel) => {
          this.waterPipelinesListCache = this.waterPipelinesListCache.map(
            (item: TypePipelineModel) => {
              return response.id === item.id ? response : item;
            }
          );
          this.waterPipelinesListSubject.next(this.waterPipelinesListCache);
          return response;
        })
      );
  }

  deleteWaterPipeline(pipeId: number) {
    return this.http
      .delete<TypePipelineModel>(
        `${environment.apiUrl}water_pipeline/delete/${pipeId}/`
      )
      .pipe(
        map((response: TypePipelineModel) => {
          this.waterPipelinesListCache = this.waterPipelinesListCache.filter(
            (item: TypePipelineModel) => {
              return item.id !== response.id;
            }
          );
          this.waterPipelinesListSubject.next(this.waterPipelinesListCache);
          return response;
        })
      );
  }

  getWaterPipelineByID(id: number): Observable<TypePipelineModel> {
    return this.http
      .get<TypePipelineModel>(`${environment.apiUrl}water_pipeline/info/${id}/`)
      .pipe(
        map((response: TypePipelineModel) => {
          return response;
        })
      );
  }

  importOilPipelinesFromFile(file: any) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<{ list: TypePipelineModel[] }>(
        `${environment.apiUrl}import/oil_pipe/`,
        formData
      )
      .pipe(
        map((response: { list: TypePipelineModel[] }) => {
          this.oilPipelinesListCache = response.list;
          this.oilPipelinesListSubject.next(this.oilPipelinesListCache);
          return response.list;
        })
      );
  }

  get oilPipelinesList(): Promise<TypePipelineModel[]> {
    return new Promise((resolve) => {
      if (this.oilPipelinesListCache.length) {
        resolve(this.oilPipelinesListCache);
      } else {
        return this.getOilPipelineList().subscribe(
          (list: TypePipelineModel[]) => {
            resolve(list);
          }
        );
      }
      return;
    });
  }

  getOilPipelineList(): Observable<any> {
    return this.http
      .get<{ list: TypePipelineModel[] }>(
        `${environment.apiUrl}oil_pipeline/list/`
      )
      .pipe(
        map((response: { list: TypePipelineModel[] }) => {
          this.oilPipelinesListCache = response.list;
          this.oilPipelinesListSubject.next(this.oilPipelinesListCache);
          return response.list;
        })
      );
  }

  createOilPipeline(
    oilPipelineBody: TypePipelineListModel
  ): Observable<TypePipelineModel> {
    return this.http
      .post<TypePipelineModel>(
        `${environment.apiUrl}oil_pipeline/create/`,
        oilPipelineBody
      )
      .pipe(
        map((response: TypePipelineModel) => {
          this.oilPipelinesListCache.push(response);
          this.oilPipelinesListSubject.next(this.oilPipelinesListCache);
          return response;
        })
      );
  }

  updateOilPipeline(
    oilPipelineId: number,
    oilPipelineBody: TypePipelineListModel
  ) {
    return this.http
      .put<TypePipelineModel>(
        `${environment.apiUrl}oil_pipeline/update/${oilPipelineId}/`,
        oilPipelineBody
      )
      .pipe(
        map((response: TypePipelineModel) => {
          this.oilPipelinesListCache = this.oilPipelinesListCache.map(
            (item: TypePipelineModel) => {
              return response.id === item.id ? response : item;
            }
          );
          this.oilPipelinesListSubject.next(this.oilPipelinesListCache);
          return response;
        })
      );
  }

  deleteOilPipeline(pipeId: number) {
    return this.http
      .delete<TypePipelineModel>(
        `${environment.apiUrl}oil_pipeline/delete/${pipeId}/`
      )
      .pipe(
        map((response: TypePipelineModel) => {
          this.oilPipelinesListCache = this.oilPipelinesListCache.filter(
            (item: TypePipelineModel) => {
              return item.id !== response.id;
            }
          );
          this.oilPipelinesListSubject.next(this.oilPipelinesListCache);
          return response;
        })
      );
  }

  getOilPipelineByID(id: number): Observable<TypePipelineModel> {
    return this.http
      .get<TypePipelineModel>(`${environment.apiUrl}oil_pipeline/info/${id}/`)
      .pipe(
        map((response: TypePipelineModel) => {
          return response;
        })
      );
  }

  getPipelineRelief(id: number, pipelineType: 'aqua' | 'oil'): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}${
          pipelineType === 'oil' ? 'oil' : 'water'
        }_pipeline/elevation/${id}/ `
      )
      .pipe(
        map((response: any) => {
          return response.data[0]?.line || [];
        })
      );
  }
}
