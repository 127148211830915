<div [formGroup]="formGroup">
  <tui-input
    [formControlName]="settings.controlName"
    (input)="inputElement($event)"
    tuiMode="onLight"
    [tuiTextfieldExampleText]="settings.placeholder"
    [tuiHintContent]="settings.placeholder"
    [tuiTextfieldMaxLength]="settings.maxLength"
  >
    <span [class]="settings.isRequired ? 'tui-required' : ''">{{
      settings.title
    }}</span>
  </tui-input>
  <tui-field-error [formControlName]="settings.controlName"></tui-field-error>
</div>
