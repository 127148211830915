import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  BADGE_TRANSLATE,
  TYPE_EVENT_TRANSLATE,
} from '../../../../../const/app-consts';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { EventService } from '../../../../../services/event.service';
import * as luxon from 'luxon';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-events-bush',
  templateUrl: './events-bush.component.html',
  styleUrls: ['./events-bush.component.less'],
})
export class EventsBushComponent implements OnInit {
  @Input() depositId: number | undefined;

  public paginator = {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    perPage: 50,
  };

  public tableColumns: any[] = [
    { name: 'datetime', title: 'Дата и время' },
    { name: 'message', title: 'Сообщение' },
    { name: 'status', title: 'Статус' },
    { name: 'react', title: 'Реакция' },
  ];

  public statusTitles: any = {
    ok: BADGE_TRANSLATE.ok,
    stop: BADGE_TRANSLATE.stop,
    no_connection: BADGE_TRANSLATE.no_connection,
  };

  public statusMessages: any = {
    stop_to_ok: TYPE_EVENT_TRANSLATE.stop_to_ok,
    ok_to_stop: TYPE_EVENT_TRANSLATE.ok_to_stop,
    stop_to_no_connection: TYPE_EVENT_TRANSLATE.stop_to_no_connection,
    ok_to_no_connection: TYPE_EVENT_TRANSLATE.ok_to_no_connection,
    no_connection_to_stop: TYPE_EVENT_TRANSLATE.no_connection_to_stop,
    no_connection_to_ok: TYPE_EVENT_TRANSLATE.no_connection_to_ok,
  };

  public events: any[] = [];

  public isLoading: boolean = true;

  public updateRequestSubject: Subject<any> = new Subject<any>();

  constructor(
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
    private eventService: EventService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.depositId) {
      setTimeout(() => {
        this.loadEvents();
      }, 0);
    }
  }

  loadEvents() {
    if (this.depositId) {
      this.isLoading = true;
      this.eventService
        .getEventsByDepositId(
          this.depositId,
          this.paginator.currentPage,
          this.paginator.perPage
        )
        .subscribe((data: any) => {
          this.events = data.data;
          this.paginator.lastPage = data.last_page;
          this.paginator.count = data.count;
          this.changeDetector.detectChanges();
          this.isLoading = false;
          this.updateRequestSubject.next('update');
        });
    }
  }

  markReadEvent(event: any) {
    this.eventService.eventUpdate(event.id).subscribe(() => {
      if (this.depositId) {
        setTimeout(() => {
          this.loadEvents();
        }, 0);
      }
    });
  }

  markReadEventsByObject() {
    if (this.depositId) {
      this.eventService
        .readAllEventsByDeposit(+this.depositId)
        .subscribe(() => {
          this.alertService
            .open(
              'События по данному месторождению успешно помечены как прочитанные.',
              { label: 'Успешно!', status: TuiNotification.Success }
            )
            .subscribe();
          if (this.depositId) {
            setTimeout(() => {
              this.loadEvents();
            }, 0);
          }
        });
    }
  }

  getFormattedDate(react_at: any) {
    return luxon.DateTime.fromISO(`${react_at}Z`).toFormat(
      'dd.MM.yyyy HH:mm:ss'
    );
  }

  changePage($event: number) {
    this.paginator.currentPage = $event + 1;
    setTimeout(() => {
      this.loadEvents();
    }, 0);
  }
}
