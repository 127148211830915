<!--  <div class="tui-row tui-row_adaptive tui-row_align_center">-->
<!--    <div class="tui-col_lg-12 tui-col_md-12">-->
<form (ngSubmit)="onSubmit(this.form)" [formGroup]="form">
  <div class="tui-row tui-row_sme">
    <div class="tui-col_12">
      <h3 class="tui-form__header form-header">
        <ng-content select="[header]"></ng-content>
      </h3>
      <div *ngFor="let formAttr of formAttrs" class="tui-form__row">
        <app-form-attr [formAttr]="formAttr" [form]="form"></app-form-attr>
      </div>
      <div class="tui-form__row">
        <div class="tui-form__buttons _space-between">
          <button
            tuiButton
            size="m"
            type="submit"
            [disabled]="!form.valid"
            class="tui-form__button"
          >
            <ng-content select="[submitBtn]"></ng-content>
          </button>
          <button
            tuiButton
            size="m"
            type="button"
            class="tui-form__button"
            appearance="whiteblock-active-red"
            (click)="clickOnAnotherBtn(formAttrs)"
          >
            <ng-content select="[anotherBtn]"></ng-content>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<!--  </div>-->
<!--</div>-->
