import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TabsModel } from 'src/models/tabs.model';
import { TypeTree } from '../../../../../../models/tree.model';
import { ObjectService } from '../../../../../services/object.service';
import { OilfieldService } from '../../../../../services/oilfield.service';
import { ActivatedRoute } from '@angular/router';
/*для Андрея: почему же отельная компонента
- дело в context из-за его объявления в конструкторе
все крушиться (а без его context контекст не будет работать)*/
@Component({
  selector: 'app-oilfield-info-page',
  templateUrl: './oilfield-info-page.component.html',
  styleUrls: ['./oilfield-info-page.component.less'],
})
export class OilfieldInfoPageComponent implements OnInit {
  //данные выбранного объекта
  public selectedObject?: any | TypeTree = null;

  //список tab
  public tabs: TabsModel[] = [
    { url: 'info', title: 'Информация' },
    { url: 'boreholes', title: 'Скважины' },
    //{ url: 'gzu', title: 'ГЗУ' },
    //{ url: 'dns', title: 'ДНС' },
    //{ url: 'other-objects', title: 'Другие объекты' },
    { url: 'events', title: 'События' },
    { url: 'scheme', title: 'Схема' },
  ];

  //выбранный tab
  public activeItemIndex: TabsModel = this.tabs[0];

  //id месторождения
  public objectID: string | null = null;

  public isLoading: boolean = false;

  constructor(
    private objectsService: ObjectService,
    private oilfieldService: OilfieldService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  //взятие с роутера id месторождения и по нему запрос на получение данных
  ngOnInit() {
    this.isLoading = true;
    this.objectID = this.route?.snapshot?.paramMap?.get('id') as string;
    this.objectsService
      .getDepositInfoByID(+this.objectID)
      .subscribe((deposit: TypeTree) => {
        this.selectedObject = deposit;
        this.changeDetectorRef.markForCheck();
        this.isLoading = false;
      });
  }

  //смена навигационных tab
  changeTabElement(tab: TabsModel) {
    this.activeItemIndex = tab;
  }

  //при каком либо изменении запрос на получения свежих данных
  getEditableDeposit(isChanged: boolean) {
    if (isChanged) {
      this.isLoading = true;
      this.objectsService
        .getDepositInfoByID(+(this.objectID as string))
        .subscribe((deposit: TypeTree) => {
          this.selectedObject = deposit;
          this.changeDetectorRef.markForCheck();
          this.isLoading = false;
        });
    } else {
      return;
    }
  }
}
