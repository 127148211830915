import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TuiSizeL } from "@taiga-ui/core";

@Component({
  selector: "app-form-radio-labeled",
  templateUrl: "./form-radio-labeled.component.html",
  styleUrls: ["./form-radio-labeled.component.less"],
})
export class FormRadioLabeledComponent {
  @Input()
  form!: FormGroup;

  @Input()
  formControlKey!: string;

  @Input()
  content!: string;

  @Input()
  nativeId!: string;

  @Input()
  size!: TuiSizeL;

  @Input()
  pseudoDisabled!: boolean;

  @Input()
  item!: string;

  @Input()
  description!: string;
}
