import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-common-delete-popup',
  templateUrl: './common-delete-popup.component.html',
  styleUrls: ['./common-delete-popup.component.less'],
})
export class CommonDeletePopupComponent {
  @Input()
  cancel: any = () => {};

  @Input()
  confirm: any = () => {};

  @Input()
  changePopup: boolean = false;
}
