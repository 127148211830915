import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { SettingsType } from '../../models/settings-type';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class DesignService implements OnDestroy {
  public isLoaded = false;

  public updateColors = new EventEmitter();

  public notification_font_size_label: number = 12;

  public notification_font_size_content: number = 12;

  public notification_font_style_label: { text: string; value: string } = {
    text: 'нет',
    value: 'no',
  };

  public notification_font_style_content: { text: string; value: string } = {
    text: 'нет',
    value: 'no',
  };

  public notification_color_status_ok: string = '#1464cc';

  public notification_color_status_no_connection: string = '#79818c';

  public notification_color_status_stop: string = '#d70707';

  public font_color_status_ok: string = '#000000';

  public font_color_status_stop: string = '#000000';

  public font_color_status_no_connection: string = '#000000';

  public font_color_status_ppd: string = '#000000';

  public color_status_ok: string = '#43b517';

  public color_status_ppd: string = '#0ba9d9';

  public color_status_no_connection: string = '#79818c';

  public color_status_stop: string = '#d70707';

  public color_ppd: string = '#1717a7';

  public color_shgn: string = '#832f2f';

  public color_shvn: string = '#ffff00';

  public color_ecn: string = '#6100fb';

  public color_no: string = '#3d4044';

  public color_repair: string = '#ffffff';

  public color_oil_pipeline: string = '#000000';

  public line_width_default_pipeline: number = 2;

  public line_width_oil_pipeline: number = 2;

  public color_water_pipeline: string = '#20aaf5';

  public line_width_water_pipeline: number = 2;

  // public color_default_pipeline: string = '#4c4a4a';
  public color_default_pipeline: string = '#b91010';

  public line_width_electricity_pipeline: number = 2;

  public color_electricity_pipeline: string = '#f5b820';

  public destroyer: Subject<null> = new Subject();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appService: AppService
  ) {}

  setColors(colors: any) {
    this.color_repair = colors.color_repair;
    this.notification_font_size_label = colors.notification_font_size_label;
    this.notification_font_size_content = colors.notification_font_size_content;
    this.notification_font_style_label = colors.notification_font_style_label;
    this.notification_font_style_content =
      colors.notification_font_style_content;
    this.notification_color_status_ok = colors.notification_color_status_ok;
    this.notification_color_status_no_connection =
      colors.notification_color_status_no_connection;
    this.notification_color_status_stop = colors.notification_color_status_stop;
    this.font_color_status_ok = colors.font_color_status_ok;
    this.font_color_status_no_connection =
      colors.font_color_status_no_connection;
    this.font_color_status_stop = colors.font_color_status_stop;
    this.font_color_status_ppd = colors.font_color_status_ppd;
    this.color_status_ok = colors.color_status_ok;
    this.color_status_ppd = colors.color_status_ppd;
    this.color_status_no_connection = colors.color_status_no_connection;
    this.color_status_stop = colors.color_status_stop;
    this.color_ppd = colors.color_ppd;
    this.color_shgn = colors.color_shgn;
    this.color_shvn = colors.color_shvn;
    this.color_ecn = colors.color_ecn;
    this.color_no = colors.color_no;
    this.color_repair = colors.color_repair;
    this.color_oil_pipeline = colors.color_oil_pipeline;
    this.line_width_oil_pipeline = colors.line_width_oil_pipeline;
    this.color_water_pipeline = colors.color_water_pipeline;
    this.line_width_water_pipeline = colors.line_width_water_pipeline;
    this.line_width_electricity_pipeline =
      colors.line_width_electricity_pipeline;
    this.color_electricity_pipeline = colors.color_electricity_pipeline;

    const root = this.document.documentElement;

    root.style.setProperty(
      '--notification-label-font-size',
      `${this.notification_font_size_label}px`
    );
    root.style.setProperty(
      '--notification-content-font-size',
      `${this.notification_font_size_content}px`
    );

    switch (this.notification_font_style_label.value) {
      case 'no':
        root.style.setProperty('--notification-label-font-style', `normal`);
        root.style.setProperty('--notification-label-font-weight', `500`);
        break;
      case 'bold':
        root.style.setProperty('--notification-label-font-style', `normal`);
        root.style.setProperty('--notification-label-font-weight', `800`);
        break;
      case 'cursive':
        root.style.setProperty('--notification-label-font-style', `italic`);
        root.style.setProperty('--notification-label-font-weight', `500`);
        break;
      case 'cursiveBold':
        root.style.setProperty('--notification-label-font-style', `italic`);
        root.style.setProperty('--notification-label-font-weight', `800`);
        break;
      default:
        root.style.setProperty('--notification-label-font-style', `normal`);
        root.style.setProperty('--notification-label-font-weight', `500`);
    }

    switch (this.notification_font_style_content.value) {
      case 'no':
        root.style.setProperty('--notification-content-font-style', `normal`);
        root.style.setProperty('--notification-content-font-weight', `500`);
        break;
      case 'bold':
        root.style.setProperty('--notification-content-font-style', `normal`);
        root.style.setProperty('--notification-content-font-weight', `800`);
        break;
      case 'cursive':
        root.style.setProperty('--notification-content-font-style', `italic`);
        root.style.setProperty('--notification-content-font-weight', `500`);
        break;
      case 'cursiveBold':
        root.style.setProperty('--notification-content-font-style', `italic`);
        root.style.setProperty('--notification-content-font-weight', `800`);
        break;
      default:
        root.style.setProperty('--notification-content-font-style', `normal`);
        root.style.setProperty('--notification-content-font-weight', `500`);
    }

    root.style.setProperty(
      '--warning-notification-color',
      this.notification_color_status_no_connection
    );
    root.style.setProperty(
      '--success-notification-color',
      this.notification_color_status_ok
    );
    root.style.setProperty(
      '--error-notification-color',
      this.notification_color_status_stop
    );

    root.style.setProperty('--triangle-color-ppd', `${this.color_ppd}`);
    root.style.setProperty('--triangle-color-shgn', `${this.color_shgn}`);
    root.style.setProperty('--triangle-color-shvn', `${this.color_shvn}`);
    root.style.setProperty('--triangle-color-ecn', `${this.color_ecn}`);
    root.style.setProperty('--triangle-color-repair', `${this.color_repair}`);
    root.style.setProperty(
      '--triangle-color-no-type-mining',
      `${this.color_no}`
    );

    root.style.setProperty(
      '--background-color-ppd',
      `${this.color_status_ppd}`
    );
    root.style.setProperty('--color_status_ok', `${this.color_status_ok}`);
    root.style.setProperty(
      '--color_status_no_connection',
      `${this.color_status_no_connection}`
    );
    root.style.setProperty('--color_status_stop', `${this.color_status_stop}`);

    root.style.setProperty(
      '--font_color_status_ppd',
      `${this.font_color_status_ppd}`
    );
    root.style.setProperty(
      '--font_color_status_ok',
      `${this.font_color_status_ok}`
    );
    root.style.setProperty(
      '--font_color_status_no_connection',
      `${this.font_color_status_no_connection}`
    );
    root.style.setProperty(
      '--font_color_status_stop',
      `${this.font_color_status_stop}`
    );

    root.style.setProperty('--color_water_pipeline', this.color_water_pipeline);
    root.style.setProperty('--color_oil_pipeline', this.color_oil_pipeline);
    root.style.setProperty(
      '--color_electricity_pipeline',
      this.color_electricity_pipeline
    );

    this.updateColors.emit();
  }

  loadColors() {
    return new Promise((resolve: any) => {
      this.appService
        .getStylesSettings()
        .pipe(takeUntil(this.destroyer))
        .subscribe((settings: { frontend_settings: SettingsType }) => {
          if (settings?.frontend_settings !== null) {
            this.setColors(settings?.frontend_settings);
            resolve();
            return;
          }
          const body = {
            color_ecn: this.color_ecn,
            color_no: this.color_no,
            color_ppd: this.color_ppd,
            color_repair: this.color_repair,
            color_shgn: this.color_shgn,
            color_shvn: this.color_shvn,
            color_status_no_connection: this.color_status_no_connection,
            font_color_status_ok: this.font_color_status_ok,
            font_color_status_no_connection:
              this.font_color_status_no_connection,
            font_color_status_ppd: this.font_color_status_ppd,
            font_color_status_stop: this.font_color_status_stop,
            color_status_ok: this.color_status_ok,
            color_status_ppd: this.color_status_ppd,
            color_status_stop: this.color_status_stop,
            notification_font_size_label: this.notification_font_size_label,
            notification_font_size_content: this.notification_font_size_content,
            notification_font_style_label: this.notification_font_style_label,
            notification_font_style_content:
              this.notification_font_style_content,
            notification_color_status_no_connection:
              this.notification_color_status_no_connection,
            notification_color_status_ok: this.notification_color_status_ok,
            notification_color_status_stop: this.notification_color_status_stop,
            color_oil_pipeline: this.color_oil_pipeline,
            line_width_oil_pipeline: this.line_width_oil_pipeline,
            color_water_pipeline: this.color_water_pipeline,
            line_width_water_pipeline: this.line_width_water_pipeline,
            color_electricity_pipeline: this.color_electricity_pipeline,
            line_width_electricity_pipeline:
              this.line_width_electricity_pipeline,
          };
          this.appService
            .updateStylesSettings(body)
            .pipe(takeUntil(this.destroyer))
            .subscribe(() => {});
          this.isLoaded = true;
          resolve();
        });
    });
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
