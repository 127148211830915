<div *ngIf="fieldSettings.isSelector; else input" class="tui-form__row">
  <app-select-field
    [formGroup]="formGroup"
    [settings]="getSelectorSettings(fieldSettings)"
  >
  </app-select-field>
</div>
<ng-template #input>
  <div [formGroup]="formGroup" class="tui-form__row">
    <app-input-field
      *ngIf="fieldSettings.isStringInput; else numberInput"
      [formGroup]="formGroup"
      [settings]="getInputSettings(fieldSettings)"
    >
    </app-input-field>
    <ng-template #numberInput>
      <app-input-number-field
        [formGroup]="formGroup"
        [settings]="getInputSettings(fieldSettings)"
      >
      </app-input-number-field>
    </ng-template>
  </div>
</ng-template>
