<div *ngIf="selectedObject">
  <app-primary-section>
    <h2 class="oilfield-popup__title">
      {{ selectedObject?.name || "object" }}
    </h2>
    <tui-tabs class="tabs">
      <button
        class="tabs__btns"
        tuiTab
        *ngFor="let tab of tabs"
        (click)="changeTabElement(tab)"
      >
        <div>
          {{ tab.title }}
        </div>
      </button>
    </tui-tabs>
  </app-primary-section>
  <div *ngIf="activeItemIndex.url === 'info'" class="container__form">
    <app-common-info-bush
      [contextObject]="selectedObject"
      (handleChange)="getEditableDeposit($event)"
    >
    </app-common-info-bush>
  </div>
  <app-boreholes-bush
    *ngIf="activeItemIndex.url === 'boreholes'"
    [selectedDeposit]="selectedObject"
    (handleChange)="getEditableDeposit($event)"
  ></app-boreholes-bush>
  <div *ngIf="activeItemIndex.url === 'gzu'">gzu page</div>
  <div *ngIf="activeItemIndex.url === 'dns'">dns page</div>
  <div *ngIf="activeItemIndex.url === 'other-objects'">other objects page</div>
  <div *ngIf="activeItemIndex.url === 'events'">
    <app-events-bush [depositId]="selectedObject.id"></app-events-bush>
  </div>
</div>
