<ng-container [formGroup]="form">
  <tui-input-number
    [formControlName]="formControlKey"
    [min]="min"
    [max]="max"
    [prefix]="prefix"
    [postfix]="postfix"
    [precision]="precision"
    [decimal]="decimal"
    [nativeId]="nativeId"
    [tuiTextfieldMaxLength]="tuiTextfieldMaxLength"
    [tuiTextfieldExampleText]="tuiTextfieldExampleText"
    [tuiTextfieldSize]="sizeBlock"
  >
    {{ label }}
    <span *ngIf="visualRequired" class="tui-required"></span>
  </tui-input-number>
  <tui-field-error [formControlName]="formControlKey"></tui-field-error>
</ng-container>
