import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TypeStat } from '../../models/stat.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public leftMenuStatus = new BehaviorSubject(false);

  public title: BehaviorSubject<string> = new BehaviorSubject('');

  public unreadEvents = new BehaviorSubject<number>(0);

  public filterOilData: any = null;

  private readonly TOKEN_KEY: string = 'filterOils';

  public updatingTime = new BehaviorSubject(
    localStorage.getItem('time') ? Number(localStorage.getItem('time')) : 30000
  );

  public watchCountUnreadNotifications: Subject<null> = new Subject();

  constructor(public http: HttpClient, private cookieService: CookieService) {
    this.filterOilData = JSON.parse(
      this.cookieService.get(this.TOKEN_KEY) || '[]'
    );
  }

  setFilteredOilDataToCookie(data: any): void {
    this.filterOilData = JSON.stringify(data || []);
    this.cookieService.set(this.TOKEN_KEY, this.filterOilData, undefined, '/');
  }

  getCookieData(): string | null {
    return JSON.parse(this.cookieService.get(this.TOKEN_KEY) || '[]');
  }

  getStat(): Observable<number> {
    return this.http.get(`${environment.apiUrl}app/stat/`, {}).pipe(
      map((response: any) => {
        this.unreadEvents.next(response.unread_events);
        return response.unread_events;
      })
    );
  }

  testEvents(): Observable<any> {
    return this.http
      .post<any>(`http://192.168.3.82:8013/api/events/test/`, { count: 1 })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  stateWidgets(): Observable<TypeStat> {
    return this.http
      .get<{ stat: TypeStat }>(`${environment.apiUrl}app/stat/deposit/`)
      .pipe(
        map((response: { stat: TypeStat }) => {
          return response.stat;
        })
      );
  }

  playSound(audio: any, variable: any, countUnreadEvents: number) {
    if (!!countUnreadEvents) {
      variable = setInterval(() => {
        audio.play().then();
      }, 10000);
    } else {
      clearInterval(variable);
    }
    return variable;
  }

  updateStylesSettings(colorAndFontSettings: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.apiUrl}front_settings/update/`,
        colorAndFontSettings
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getStylesSettings(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}front_settings/get/`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
