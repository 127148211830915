import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { AppService } from '../../services/app.service';
import { CookieService } from 'ngx-cookie-service';
import { io } from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { TYPE_EVENT_TRANSLATE } from '../../const/app-consts';
import { EventService } from '../../services/event.service';
import { DesignService } from '../../services/design.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit, AfterViewChecked {
  public dropdownOpen = false;

  public abbrTitles: any = {
    агбязовскоеместорождение: 'АгбМ',
    западнобурейкинскоеместорождение: 'ЗБМ',
    актанышскоеместорождение: 'АктМ',
    нуркеевскоеместорождение: 'НМ',
    сунчелеевскоеместорождение: 'СМ',
  };

  public typeTranslate: { [key: string]: string } = TYPE_EVENT_TRANSLATE;

  public ONE_THOUSAND = 1000;

  public timeSelected: number = 30000;

  public eventSettings: string[] = [];

  //public isNotification: boolean = false;

  public infinityAudio: any;

  private timeTimeout!: any;

  private eventsWithoutRefresh: number = 0;

  constructor(
    public appService: AppService,
    private cookieService: CookieService,
    private eventService: EventService,
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
    private changeDetector: ChangeDetectorRef,
    private designService: DesignService
  ) {}

  ngOnInit() {
    this.appService.getStat().subscribe();
    const socketIo = io(environment.socketUrl, {
      transports: ['websocket'],
    });
    let eventSettings: string | string[] | null =
      localStorage.getItem('eventsSettings');
    if (!!eventSettings) {
      eventSettings = eventSettings.split(',');
      this.eventSettings = eventSettings;
    } else {
      this.eventSettings = ['ok', 'stop', 'no_connection'];
    }
    this.activatedSound();
    socketIo.on('connect', () => {
      socketIo.emit('room', 'mnkt');
    });
    socketIo.on('object_event', (message: any) => {
      this.eventAccumulator();
      // this.eventService.newEvent.next(null);
      this.appService.getStat().subscribe();
      this.showNotifications(message);
    });
    socketIo.on('read_event', () => {
      this.eventAccumulator();
      // this.eventService.newEvent.next(null);
      this.appService.getStat().subscribe();
    });
    this.appService.watchCountUnreadNotifications.subscribe(() => {
      this.appService.getStat().subscribe((countUnreadEvents: number) => {
        if (!countUnreadEvents) {
          clearInterval(this.infinityAudio);
        }
      });
    });
    //this.showNotifications({
    //  object_name: 'скважина1',
    //  pressure_name: 'device',
    //  deposit_name: 'западно бурейкинское месторождение',
    //  type: 'max_warning',
    //  status: 'stop',
    //});
  }

  eventAccumulator() {
    if (this.eventsWithoutRefresh > 10) {
      clearTimeout(this.timeTimeout);
      this.eventsWithoutRefresh = 0;
      this.eventService.newEvent.next(null);
    } else {
      this.eventsWithoutRefresh++;
      clearTimeout(this.timeTimeout);
      this.timeTimeout = setTimeout(() => {
        this.eventsWithoutRefresh = 0;
        this.eventService.newEvent.next(null);
      }, 5000);
    }
  }

  getStatusEvent(status: string, type: string) {
    if (type == 'max_error' || type == 'min_error') {
      return TuiNotification.Error;
    } else if (type == 'min_warning' || type == 'max_warning') {
      return TuiNotification.Warning;
    } else {
      switch (status) {
        case 'ok':
          return TuiNotification.Success;
        case 'stop':
          return TuiNotification.Error;
        case 'no_connection':
          return TuiNotification.Warning;
        default:
          return TuiNotification.Info;
      }
    }
  }

  private showNotifications(msg: any) {
    this.activatedSound();
    this.alertService
      .open(
        `${msg.object_name ? msg.object_name : msg.pressure_name} ${
          msg.deposit_name &&
          this.abbrTitles[
            msg.deposit_name.toLowerCase().replace(/[^ЁёА-Яа-я]/g, '')
          ]
            ? `(${
                this.abbrTitles[
                  msg.deposit_name.toLowerCase().replace(/[^ЁёА-Яа-я]/g, '')
                ]
              })`
            : `(${
                msg.deposit_name
                  ? msg.deposit_name
                  : 'Неизвестное месторождение'
              })`
        } ${this.typeTranslate[msg.type]}`,
        {
          autoClose: 20000,
          status: this.getStatusEvent(msg.status, msg.type),
        }
      )
      .subscribe();
  }

  activatedSound() {
    this.appService.unreadEvents.subscribe((countUnreadEvents: number) => {
      const audio = new Audio(
        '/assets/audio/IPhone - Tweet Notification Ringtone.mp3'
      );
      clearInterval(this.infinityAudio);
      this.infinityAudio = this.appService.playSound(
        audio,
        this.infinityAudio,
        countUnreadEvents
      );
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  set updatingDataPerTime(time: number) {
    localStorage.setItem('time', String(time));
    this.timeSelected = time;
    this.appService.updatingTime.next(time);
    this.dropdownOpen = false;
  }

  //changeTime(timezone: any) {
  //  console.log(timezone);
  //  console.log(this.localTime);
  //  console.log(this.localTimezone);
  //}

  timeIsSelected(time: number) {
    return time === this.timeSelected;
  }
}
