import { Component, Input, OnInit } from '@angular/core';
import { divIcon, latLng, LayerGroup, marker, tileLayer } from 'leaflet';
import { LeafletMapService } from '../../../../../../../services/translate-map-controls.service';
import { TypeTree } from '../../../../../../../../models/tree.model';

const L = LeafletMapService.get();

@Component({
  selector: 'app-map-object-oilfield',
  templateUrl: './map-object-oilfield.component.html',
  styleUrls: ['./map-object-oilfield.component.less'],
})
export class MapObjectOilfieldComponent implements OnInit {
  @Input() oilfield: any = null;

  public averageLatLng: Array<number> = [0, 0];

  public marker: LayerGroup = L.layerGroup();

  public mapObject!: any;

  public options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        attribution: '',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      this.marker,
    ],
    zoom: 7,
    center: latLng(55.558741, 37.378847),
    minZoom: 2,
    maxBounds: LeafletMapService.getBounds(),
  };

  ngOnInit(): void {
    if (this.oilfield) {
      this.oilfield.children.forEach((child: any) => {
        if (this.oilfield.children.length > 1) {
          this.averageLatLng = [
            (this.averageLatLng[0] += child.latitude),
            (this.averageLatLng[1] += child.longitude),
          ];
        } else {
          this.averageLatLng = [
            (this.averageLatLng[0] = child.latitude),
            (this.averageLatLng[1] = child.longitude),
          ];
        }
        this.marker.addLayer(
          marker([child.latitude, child.longitude], {
            icon: divIcon({
              html: `<div class="object-map-balloon status_${this.getColorClassForBalloon(
                child
              )}" style="font-size: ${
                child.name.length > 6
                  ? (5 / child.name.length) * 20 >= 10
                    ? 10
                    : (5 / child.name.length) * 20
                  : 10
              }px">
                             <div class="object-content"><span>${
                               child.name
                             }</span>
                             <img src="/assets/icons-white/${this.getIconNameForBorehole(
                               child
                             )}.svg" alt=""></div></div>`,
            }),
          })
            .on('mouseover', (event: any) => {
              const layer = event.target;
              layer.setZIndexOffset(1000);
            })
            .on('mouseout', (event: any) => {
              const layer = event.target;
              layer.setZIndexOffset(0);
            })
        );
      });
      if (this.oilfield.children.length > 1) {
        this.averageLatLng = [
          this.averageLatLng[0] / this.oilfield.children.length,
          this.averageLatLng[1] / this.oilfield.children.length,
        ];
      }
      if (this.oilfield.children.length !== 0) {
        this.options.center = latLng(
          this.averageLatLng[0],
          this.averageLatLng[1]
        );
        if (this.mapObject) {
          this.mapObject.setView(
            latLng(this.averageLatLng[0], this.averageLatLng[1])
          );
        }
      }
    }
  }

  getColorClassForBalloon(object: TypeTree) {
    if (object.status) {
      return object?.attributes?.type_mining === 'PPD'
        ? 'stop_ppd'
        : object.status;
    } else return 'default';
  }

  getIconNameForBorehole(borehole: TypeTree) {
    return borehole.attributes?.type_mining &&
      borehole.attributes?.type_mining !== 'REPAIR' &&
      borehole.attributes?.type_mining !== 'NONE'
      ? `borehole_${borehole.attributes?.type_mining}`
      : 'borehole';
  }

  onMapReady(map: any) {
    this.mapObject = map;
  }
}
