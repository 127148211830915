import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonDataForCycle } from '../../../../../../../../../models/common-info.model';

@Component({
  selector: 'app-common-selector-oilfield',
  templateUrl: './common-selector-oilfield.component.html',
  styleUrls: ['./common-selector-oilfield.component.less'],
})
export class CommonSelectorOilfieldComponent {
  //привязанная к конролеру форма
  @Input()
  form!: FormGroup;

  //controlName
  @Input()
  name: string = '';

  //controlName
  @Input()
  isDisabled: boolean = false;

  @Input()
  placeholder: string = '';

  //выбранный прежде эллемент
  @Input()
  content: string = '';

  //data for cycle
  @Input()
  data!: Array<CommonDataForCycle>;
}
