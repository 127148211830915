<tui-tabs>
  <button tuiTab *ngFor="let tab of tabs" (click)="changeTabElement(tab)">
    {{ tab.title }}
  </button>
  <ng-container *ngIf="selectedObject && !isObjFromMap">
    <button
      tuiIconButton
      type="button"
      appearance="iconHovered"
      class="button"
      [icon]="icon"
      size="xl"
      routerLink="/main/map/{{ selectedObject.type }}/{{ selectedObject.id }}"
      (click)="close()"
    ></button>
    <ng-template #icon>
      <tui-svg src="/assets/icons-header/map.svg"> </tui-svg>
    </ng-template>
  </ng-container>
</tui-tabs>
<div class="container__form">
  <app-common-info-object
    *ngIf="activeItemIndex.url === 'info'"
    [contextObject]="selectedObject"
  >
  </app-common-info-object>
</div>
<app-scheme-viewer
  *ngIf="activeItemIndex.url === 'scheme'"
  [contextObject]="selectedObject"
  (closeModal)="editScheme()"
></app-scheme-viewer>
<app-boreholes-object
  *ngIf="activeItemIndex.url === 'boreholes'"
  [contextObject]="selectedObject"
></app-boreholes-object>
