<div class="navigation" [class.navigation_not_expanded]="!isExpandedMenu">
<!--  <img class="header__logo" src="/assets/img/logo.svg" alt="logo" />-->
  <img [class]="isExpandedMenu ? 'header__logo' : 'header__logo__hidden'" src="/assets/img/logo.svg" alt="logo" />
  <ng-container *ngFor="let nav of listOfNavLinks">
    <button
      tuiButton
      type="button"
      appearance="flat"
      class="left-menu_btn"
      [tuiHint]="!isExpandedMenu ? tooltip : null"
      tuiHintDirection="right"
      [tuiHintShowDelay]="10"
      [tuiHintHideDelay]="10"
      size="m"
      [routerLink]="'/main/' + nav.id"
      [routerLinkActive]="['_active']"
      (click)="changeBurgerMenu()"
    >
      <tui-svg
        class="icon"
        [src]="getCurrentUrl(nav.id)"
        [class._unread]="
          nav.id === 'events' && (appService.unreadEvents | async)
        "
      ></tui-svg>
      <div
        *ngIf="nav.id === 'events' && (appService.unreadEvents | async)"
        [class.notification]="!isExpandedMenu"
        [class.notification__expanded]="isExpandedMenu"
        class="notification"
      >
        {{ (appService.unreadEvents | async) || 0 }}
      </div>

      <!--      <tui-svg-->
      <!--        class="icon"-->
      <!--        [src]="getCurrentUrl(nav.id)"-->
      <!--        [class._unread]="-->
      <!--          nav.id === 'events' && ('100')-->
      <!--        "-->
      <!--      ></tui-svg>-->
      <!--      <div-->
      <!--        *ngIf="nav.id === 'events' && ('100')"-->
      <!--        [class.notification]="!isExpandedMenu"-->
      <!--        [class.notification__expanded]="isExpandedMenu"-->
      <!--        class="notification"-->
      <!--      >-->
      <!--        {{ ('10000') || 0 }}-->
      <!--      </div>-->

      <span>{{ nav?.title }}</span>
    </button>
    <ng-template #tooltip>
      <div>
        {{ nav?.title }}
      </div>
    </ng-template>
  </ng-container>
</div>
