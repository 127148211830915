import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-primary-table",
  templateUrl: "./primary-table.component.html",
  styleUrls: ["./primary-table.component.less"],
})
export class PrimaryTableComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("primaryTable", { static: false }) primaryTable:
    | ElementRef
    | undefined;

  @Input() columns: any[] = [];

  @Input() isSort: boolean = true;

  @Input() isSearch: boolean = false;

  @Input() isHasButtonAdd: boolean = false;

  @Input() isHasButtonSetting: boolean = true;

  @Input() emptyThInTitle: boolean = true;

  @Input() disabled: boolean = true;

  @Input() updateRequestSubject: Observable<any> = new Observable<any>();

  @Input() nothingWasFound: boolean = false;

  @Output() sorted: EventEmitter<any> = new EventEmitter();

  @Output() search: EventEmitter<any> = new EventEmitter();

  @Output() toggleTableClick: EventEmitter<null> = new EventEmitter();

  public direction: "asc" | "desc" = "asc";

  public searchField: any = new FormControl(
    { value: "", disabled: this.disabled },
    []
  );

  private destroyer: Subject<any> = new Subject<any>();

  private selectedColumns: any[] = [];

  ngOnInit() {
    if (this.isSearch) {
      this.searchField.valueChanges
        .pipe(takeUntil(this.destroyer))
        .subscribe((val: string) => {
          this.search.emit(val);
        });
    }
    this.updateRequestSubject.pipe(takeUntil(this.destroyer)).subscribe(() => {
      if (this.selectedColumns.length) {
        setTimeout(() => this.changeTableColumns(this.selectedColumns));
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes?.["disabled"]?.currentValue == false &&
      this.disabled == false
    ) {
      this.searchField.enable();
    }
    if (this.isHasButtonSetting) {
      for (const propName in changes) {
        if (changes.hasOwnProperty(propName)) {
          switch (propName) {
            case "columns": {
              this.isHasButtonSetting = false;
              setTimeout(() => (this.isHasButtonSetting = true), 0);
            }
          }
        }
      }
    }
  }

  onSort($event: any) {
    this.sorted.emit($event);
  }

  changeTableColumns($event: any) {
    this.selectedColumns = $event;
    for (const field of this.columns) {
      const colIndex = this.columns.map((i: any) => i.name).indexOf(field.name);
      if ($event.some((f: any) => f.name === field.name)) {
        this.primaryTable?.nativeElement
          .querySelector(`thead > tr > th:nth-child(${colIndex + 1})`)
          .style.setProperty("display", "table-cell");
        this.primaryTable?.nativeElement
          .querySelectorAll(`tbody tr td:nth-child(${colIndex + 1})`)
          .forEach((el: any) => {
            el.style.setProperty("display", "table-cell");
          });
      } else {
        this.primaryTable?.nativeElement
          .querySelector(`thead > tr > th:nth-child(${colIndex + 1})`)
          .style.setProperty("display", "none");
        this.primaryTable?.nativeElement
          .querySelectorAll(`tbody tr td:nth-child(${colIndex + 1})`)
          .forEach((el: any) => {
            el.style.setProperty("display", "none");
          });
      }
    }
  }

  changeAddPopup() {
    this.toggleTableClick.emit();
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
