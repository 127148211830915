import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TabsModel } from 'src/models/tabs.model';
import { ActivatedRoute } from '@angular/router';
import { PressureService } from 'src/app/services/pressure.service';
import { PressureItemType } from 'src/models/pressure-meter.model';

@Component({
  selector: 'app-pressure-sensor-card',
  templateUrl: './pressure-sensor-card.component.html',
  styleUrls: ['./pressure-sensor-card.component.less'],
})
export class PressureSensorCardComponent implements OnInit {
  //id месторождения
  @Input()
  public objectID: string | null | number = null;

  @Output()
  handleSubmit: EventEmitter<null> = new EventEmitter();

  //данные выбранного объекта
  public selectedObject: PressureItemType | null = null;

  //список tab
  public tabs: TabsModel[] = [
    { url: 'info', title: 'Информация' },
    { url: 'events', title: 'События' },
    { url: 'history', title: 'История' },
  ];

  //выбранный tab
  public activeItemIndex: TabsModel = this.tabs[0];

  public isLoading = true;

  constructor(
    private pressureService: PressureService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  //взятие с роутера id месторождения и по нему запрос на получение данных
  async ngOnInit() {
    if (!this.objectID) {
      this.objectID = this.route?.snapshot?.paramMap?.get('id') as string;
    }
    this.getPressureSensorByID(this.objectID);
  }

  //смена навигационных tab
  changeTabElement(tab: TabsModel) {
    this.activeItemIndex = tab;
  }

  //при каком либо изменении запрос на получения свежих данных
  getEditableDeposit() {
    if (this.objectID !== null) {
      this.getPressureSensorByID(this.objectID);
      this.handleSubmit.emit();
    }
  }

  getPressureSensorByID(id: string | number) {
    this.isLoading = true;
    this.pressureService
      .getPressureSensorByID(+id)
      .subscribe((deposit: PressureItemType) => {
        this.selectedObject = deposit;
        this.changeDetectorRef.markForCheck();
        this.isLoading = false;
      });
  }
}
