import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-form-select-with-manual-input",
  templateUrl: "./form-select-with-manual-input.html",
  styleUrls: ["./form-select-with-manual-input.less"],
})
export class FormSelectWithManualInputComponent implements OnInit, OnDestroy {
  // Название формы formGroup
  @Input()
  form!: FormGroup;

  // Название поля в форме formControlName
  @Input()
  formControlNameSelect!: string;

  // Сообщение для отображения внутри поля
  @Input()
  label!: string;

  // Является ли поле обязательным для заполнения
  @Input()
  visualRequired!: boolean;

  // Указать, если нужно добавить серую стилизацию
  @Input()
  greyStyle!: boolean;

  // Массив опций для выпадающего списка - объекты со свойством value
  @Input()
  items: readonly any[] = [];

  // Отредактированный массива опций
  itemsList: any[] | undefined;

  // Переменная для определения режима ввода
  manualMode: boolean = false;

  // Форма для переключателя режимов ввода
  public formManualToggle: FormGroup = new FormGroup({
    manual: new FormControl(false, []),
  });

  private destroyer: Subject<any> = new Subject();

  ngOnInit(): void {
    // Дополняем список опций вариантом 'Не выбрано'
    this.itemsList = [{ value: "Не выбрано" }, ...this.items];

    // Подписываемся на изменения переключателя и изменяем режим ввода в зависимости от его положения
    this.formManualToggle
      .get("manual")
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((value) => {
        if (value) {
          this.manualMode = true;
          // При переключении в режим ручного ввода проверяем значение поля
          // Если оно 'Не выбрано', то очищаем поле для ввода
          if (this.form.value[this.formControlNameSelect] === "Не выбрано") {
            this.form.get(this.formControlNameSelect)?.setValue("");
          }
        } else {
          this.manualMode = false;
          // При переключении в режим выпадающего списка проверяем введено ли значение
          // Если нет, то устанавливаем 'Не выбрано'
          if (!this.form.value[this.formControlNameSelect]) {
            this.form.get(this.formControlNameSelect)?.setValue("Не выбрано");
          }
        }
      });

    // Получаем имеющееся значение из поля ввода
    const selectedValue = this.form.value[this.formControlNameSelect];

    if (selectedValue) {
      // Если значение имеется, то проверяем входит ли оно в массив опций для выпадающего списка
      const filtered = this.itemsList?.filter(
        (item) => item.value === selectedValue
      );
      if (!filtered?.length) {
        // Если не входит, то переключаемся в режим ручного ввода
        this.formManualToggle.get("manual")?.setValue(true);
      }
    } else {
      // Если значения нет, то устанавливаем его в 'Не выбрано'
      this.form.get(this.formControlNameSelect)?.setValue("Не выбрано");
    }
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
