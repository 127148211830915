<div [formGroup]="formGroup">
  <tui-select
    [formControlName]="settings.controlName"
    tuiMode="onLight"
    [valueContent]="typeValueContent"
  >
    <span [class]="settings.isRequired ? 'tui-required' : ''">{{
      settings.title
    }}</span>
    <tui-data-list-wrapper
      *tuiDataList
      [items]="settings.items"
      [itemContent]="valueContent"
    >
    </tui-data-list-wrapper>
  </tui-select>
  <ng-template #typeValueContent let-data>
    {{ data.title }}
  </ng-template>
  <ng-template #valueContent let-data>
    {{ data.title }}
  </ng-template>
  <tui-field-error [formControlName]="settings.controlName"> </tui-field-error>
</div>
