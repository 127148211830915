import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormAttrBase } from "../form-attr-base";

@Component({
  selector: "app-form-attr",
  templateUrl: "./form-attr.component.html",
  styleUrls: ["./form-attr.component.less"],
})
export class FormAttrComponent {
  @Input() formAttr!: FormAttrBase<string>;

  @Input() form!: FormGroup;

  get isValid() {
    return this.form.controls[this.formAttr.key].valid;
  }
}
