import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
} from "@angular/forms";
import { Observable, of, Subject } from "rxjs";
import { takeWhileInclusive } from "rxjs-take-while-inclusive";
import {
  debounceTime,
  exhaustMap,
  filter,
  scan,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { DeviceModel } from "../../../models/device.model";
import { DataTableModel } from "../../../models/table.model";

export interface ItemsClass {
  id: number;
  name: string;
}
function autocompleteObjectValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === "string") {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}
//search selector with infinity scroll
@Component({
  selector: "app-search-selector",
  templateUrl: "./search-selector.component.html",
  styleUrls: ["./search-selector.component.less"],
})
export class SearchSelectorComponent implements OnInit {
  filteredStudents$: Observable<ItemsClass[]> | undefined;

  @Input()
  data: Array<any> = [];

  @Input()
  isDeleteValueWhenChange: boolean = false;

  @Input()
  title: string = "";

  @Input()
  idSelectedElement!: any;

  @Input() row!: DataTableModel;

  private nextPage$: any = new Subject();

  //id option к которому прикриплден( при условии если компонента исользуется в параметрах)
  private idOption: number | null = null;

  @Output() handleSelectedItem: EventEmitter<
    DeviceModel | { [key: string]: DeviceModel | null } | null
  > = new EventEmitter<
    DeviceModel | { [key: string]: DeviceModel | null } | null
  >();

  public validation_msgs = {
    contactAutocompleteControl: [
      {
        type: "invalidAutocompleteObject",
        message:
          "Имя объекта не распознано. Щелкните один из вариантов автозаполнения.",
      },
      { type: "required", message: "Contact is required." },
    ],
  };

  formDirectories: any = new FormGroup({
    name: new FormControl("", [autocompleteObjectValidator()]),
  });

  ngOnInit(): void {
    if (typeof this.idSelectedElement === "number") {
      this.formDirectories.get("name").setValue(
        this.data.find((item: DeviceModel) => {
          return this.idSelectedElement === item.id;
        }) || null
      );
    } else if (this.idSelectedElement !== null) {
      this.idOption = +Object.keys(this.idSelectedElement)[0];
      this.formDirectories
        .get("name")
        .setValue(Object.values(this.idSelectedElement)[0]);
    }

    // Note: listen for search text changes
    const filter$ = this.formDirectories.get("name").valueChanges.pipe(
      startWith(""),
      debounceTime(200),
      filter((q) => typeof q === "string")
    );

    this.filteredStudents$ = filter$.pipe(
      // eslint-disable-next-line
      switchMap((filter) => {
        //Note: Reset the page with every new search text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          // eslint-disable-next-line
          exhaustMap((_) => this.getStatusList(filter, currentPage)),
          tap(() => currentPage++),
          /** Note: This is a custom operator because we also need the last emitted value.
           Note: Stop if there are no more pages, or no results at all for the current search text.
           */
          // eslint-disable-next-line
          takeWhileInclusive((p: any) => p.length > 0) as any,
          scan(
            (allProducts: any, newProducts: any) =>
              allProducts.concat(newProducts),
            []
          ) as any
        );
      })
    );
  }

  getStatusList(startsWith: any, page: number): Observable<ItemsClass[]> {
    const take = 10;
    const skip = page > 0 ? (page - 1) * take : 0;
    const filtered = this.data.filter((option) =>
      option.name.toLowerCase().startsWith(startsWith.toLowerCase())
    );
    return of(filtered.slice(skip, skip + take));
  }

  displayWith(element: any) {
    return element ? element.name : null;
  }

  onScroll() {
    //Note: This is called multiple times after the scroll has reached the 80% threshold position.
    // eslint-disable-next-line
    this.nextPage$.next();
  }

  changeItem($event: any = null) {
    if (
      this.idOption === null &&
      $event !== null &&
      $event.target.value.length === 0
    ) {
      this.handleSelectedItem.emit(null);
    } else if (this.idOption === null && $event === null) {
      this.handleSelectedItem.emit(this.formDirectories.get("name").value);
    } else if (
      this.idOption !== null &&
      $event !== null &&
      $event.target.value.length === 0
    ) {
      this.handleSelectedItem.emit({ [this.row["id"]]: null });
    } else if (this.idOption !== null && $event === null) {
      this.handleSelectedItem.emit({
        [this.row["id"]]: this.formDirectories.get("name").value,
      });
    }
    if (this.isDeleteValueWhenChange && $event === null) {
      this.formDirectories.get("name").setValue(null);
    }
    return;
  }
}
