<div *ngIf="contextObject">
  <app-primary-section>
    <form [formGroup]="objectInfoForm" class="form" *ngIf="editObjectMode">
      <div class="inline-table">
        <div class="header">
          <span>Нефтепровод</span>
          <app-common-buttons-dns
            [isEdit]="editObjectMode"
            (handelCancel)="cancelInput()"
            (handelChange)="changeInput()"
            (handelSave)="save()"
          >
          </app-common-buttons-dns>
        </div>
        <div class="form-table">
          <div class="row">
            <div class="form-label">Название *</div>
            <div class="value">
              <tui-input
                style="width: 90%"
                formControlName="name"
                tuiTextfieldSize="m"
              >
                Укажите название
                <input maxlength="40" tuiTextfield type="text" />
              </tui-input>
              <tui-field-error formControlName="name"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Протяженность, м</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                tuiTextfieldSize="m"
                formControlName="length"
              >
                Укажите протяженность
              </tui-input-number>
              <tui-field-error formControlName="length"></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Диаметр, мм</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                tuiTextfieldSize="m"
                formControlName="diameter"
              >
                Укажите диаметр
              </tui-input-number>
              <tui-field-error formControlName="diameter"></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Толщина стенки, мм</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                tuiTextfieldSize="m"
                formControlName="wall_thickness"
              >
                Укажите толщину стенки
              </tui-input-number>
              <tui-field-error
                formControlName="wall_thickness"
              ></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Материал</div>
            <div class="value">
              <tui-input
                style="width: 90%"
                formControlName="material"
                tuiTextfieldSize="m"
              >
                Укажите материал
                <input maxlength="40" tuiTextfield type="text" />
              </tui-input>
              <tui-field-error formControlName="material"></tui-field-error>
            </div>
          </div>

          <!-- <div [class]="objectInfoForm.valid ? 'row' : 'row-input'">
            <div class="form-label">Наличие антикоррозийного покрытия</div>
            <div class="value" style="display: flex; align-items: center">
              <tui-toggle
                formControlName="is_anticorrosion"
                size="l"
                class="tui-space_left-1"
              ></tui-toggle>
            </div>
          </div> -->

          <div class="row">
            <div class="form-label">Наличие антикоррозийного покрытия</div>
            <div class="value">
              <tui-select
                style="width: 90%"
                formControlName="is_anticorrosion"
                [valueContent]="typeValueContent"
                tuiTextfieldSize="m"
              >
                <tui-data-list-wrapper
                  *tuiDataList
                  [items]="pipelinesAnticorrosiveOptions"
                  [itemContent]="valueContent"
                >
                </tui-data-list-wrapper>
              </tui-select>
              <ng-template #typeValueContent let-data>
                {{ data.value }}
              </ng-template>
              <ng-template #valueContent let-data>
                {{ data.value }}
              </ng-template>
              <tui-field-error
                formControlName="is_anticorrosion"
              ></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Метод производства</div>
            <div class="value">
              <tui-input
                style="width: 90%"
                formControlName="production_method"
                tuiTextfieldSize="m"
              >
                Укажите метод производства
                <input maxlength="40" tuiTextfield type="text" />
              </tui-input>
              <tui-field-error
                formControlName="production_method"
              ></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Год производства</div>
            <div class="value">
              <tui-select
                style="width: 90%"
                formControlName="production_year"
                tuiTextfieldSize="m"
                class="fix-select-padding"
              >
                Выберите год производства
                <tui-data-list-wrapper
                  *tuiDataList
                  [items]="productionYearList"
                >
                </tui-data-list-wrapper>
              </tui-select>
              <tui-field-error
                formControlName="production_year"
              ></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Номинальное давление, МПа</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                tuiTextfieldSize="m"
                formControlName="nominal_pressure"
              >
                Укажите номинальное давление
              </tui-input-number>
              <tui-field-error
                formControlName="nominal_pressure"
              ></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Рабочее давление, МПа</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                tuiTextfieldSize="m"
                formControlName="working_pressure"
              >
                Укажите рабочее давление
              </tui-input-number>
              <tui-field-error
                formControlName="working_pressure"
              ></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Дата последней ЭПБ</div>
            <div class="value">
              <tui-input-date style="width: 90%" formControlName="last_EPB">
                Выберите дату последней ЭПБ
              </tui-input-date>
              <tui-field-error formControlName="last_EPB"></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Дата следующей ЭПБ</div>
            <div class="value">
              <tui-input-date style="width: 90%" formControlName="next_EPB">
                Выберите дату следующей ЭПБ
              </tui-input-date>
              <tui-field-error formControlName="next_EPB"></tui-field-error>
            </div>
          </div>
        </div>
        <span class="form-footer">* Обязательные для заполнения поля</span>
      </div>
    </form>
    <div class="inline-table" *ngIf="!editObjectMode">
      <div class="header">
        <span>Водопровод</span>
        <app-common-buttons-dns
          [isEdit]="editObjectMode"
          (handelCancel)="cancelInput()"
          (handelChange)="changeInput()"
          (handelSave)="save()"
        >
        </app-common-buttons-dns>
      </div>
      <div class="table">
        <div class="row">
          <div class="label">Название</div>
          <div class="value">
            {{ contextObject.name }}
          </div>
        </div>

        <div class="row">
          <div class="label">Начальный объект нефтесбора</div>
          <div class="value">
            {{ contextObject?.init_oil_parent?.name || "-" }}
          </div>
        </div>

        <div class="row">
          <div class="label">Конечный объект нефтесбора</div>
          <div class="value">
            {{ contextObject?.final_oil_parent?.name || "-" }}
          </div>
        </div>

        <div class="row">
          <div class="label">Протяженность, м</div>
          <div class="value">
            {{ contextObject?.length || "-" }}
          </div>
        </div>
        <div class="row">
          <div class="label">Диаметр, мм</div>
          <div class="value">
            <td>{{ contextObject?.diameter || "-" }}</td>
          </div>
        </div>
        <div class="row">
          <div class="label">Толщина стенки, мм</div>
          <div class="value">
            <td>{{ contextObject?.wall_thickness || "-" }}</td>
          </div>
        </div>
        <div class="row">
          <div class="label">Материал</div>
          <div class="value">
            <td>{{ contextObject?.material || "-" }}</td>
          </div>
        </div>
        <div class="row">
          <div class="label">Наличие антикоррозийного покрытия</div>
          <div class="value">
            <td>
              {{
                contextObject?.is_anticorrosion === true
                  ? "Есть"
                  : contextObject?.is_anticorrosion === false
                  ? "Нет"
                  : "Не выбрано"
              }}
            </td>
          </div>
        </div>
        <div class="row">
          <div class="label">Метод производства</div>
          <div class="value">
            <td>{{ contextObject?.production_method || "-" }}</td>
          </div>
        </div>

        <div class="row">
          <div class="label">Год производства</div>
          <div class="value">
            <td>{{ contextObject?.production_year || "-" }}</td>
          </div>
        </div>
        <div class="row">
          <div class="label">Номинальное давление, МПа</div>
          <div class="value">
            <td>{{ contextObject?.nominal_pressure || "-" }}</td>
          </div>
        </div>
        <div class="row">
          <div class="label">Рабочее давление, МПа</div>
          <div class="value">
            <td>{{ contextObject?.working_pressure || "-" }}</td>
          </div>
        </div>
        <div class="row">
          <div class="label">Дата последней ЭПБ</div>
          <div class="value">
            <td>
              {{
                contextObject?.last_EPB
                  ? contextObject?.last_EPB.split("-").reverse().join(".")
                  : "-"
              }}
            </td>
          </div>
        </div>
        <div class="row">
          <div class="label">Дата следующей ЭПБ</div>
          <div class="value">
            <td>
              {{
                contextObject?.next_EPB
                  ? contextObject?.next_EPB.split("-").reverse().join(".")
                  : "-"
              }}
            </td>
          </div>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div
      *ngIf="
        (isAvailableCoords() && contextObject?.coordinates[0].line.length) ||
        editObjectMode
      "
    >
      <div class="oil-pipeline__map">
        <app-pipeline-map
          [pipelines]="oilPipeline"
          [isEdit]="editObjectMode"
          (handleDraw)="getCoords($event)"
        ></app-pipeline-map>
      </div>
    </div>
    <ng-container *ngIf="!isAvailableCoords() && !editObjectMode">
      <div class="unavailable-map">
        <span class="unavailable-map__text">
          Точка на карте расположена вне области видимости карты, измените
          координаты в соответствии с допустимыми значениями, а именно: значения
          широты должны быть менее 90, но более -90, значения долготы должны
          быть менее 180, но более -180, чтобы отобразить точку на карте
        </span>
      </div>
    </ng-container>

    <ng-container
      *ngIf="!contextObject?.coordinates[0].line.length && !editObjectMode"
    >
      <div class="unavailable-map">
        <span class="unavailable-map__text">
          Координаты объекта не указаны
        </span>
      </div>
    </ng-container>
  </app-primary-section>
</div>
<div *ngIf="!contextObject" class="common-info__no-content">
  Выберите объект
</div>
