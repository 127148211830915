import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ObjectService } from 'src/app/services/object.service';
import { UsersService } from '../../../../services/users.service';
import { TRANSLATE_TYPES } from '../../../../const/app-consts';
import { PowerLinesService } from 'src/app/services/power-lines.service';
import { PressureService } from 'src/app/services/pressure.service';
import { PipelineService } from '../../../../services/pipeline.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.less'],
})
export class DeleteDialogComponent {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  //get it with true if delete dialog is opened in another popup
  @Input() closeWithHandleClick: boolean = false;

  @Input() typeObject: string = 'objects';

  @Input() title: string | null = null;

  @Input() handleSubmit: any = () => {};

  //закрытие попапа в попапе
  @Output() handleClick: EventEmitter<boolean> = new EventEmitter();

  // данные для удаления (id, имя и тип (переводы их в TRANSLATE_TYPES) из выбранного объекта)
  @Input() data: null | {
    id: number | null;
    name: string;
    type: string;
  } = null;

  // сообщает родителю об успешном удалении объекта
  @Output() updateTableAfterDelete: EventEmitter<boolean> = new EventEmitter();

  public translate: any = TRANSLATE_TYPES;

  constructor(
    private objectService: ObjectService,
    private powerLinesService: PowerLinesService,
    private pipelineService: PipelineService,
    public usersService: UsersService,
    public pressureService: PressureService
  ) {}

  //ngOnInit(): void {
  //  console.log(this.data);
  //}

  //удаление и закрытие
  submitDelete() {
    switch (this.data?.type) {
      case this.translate.water_pipeline:
        this.pipelineService
          .deleteWaterPipeline(this.data?.id as number)
          .subscribe((responseWaterPipeline: any) => {
            if (responseWaterPipeline) {
              this.updateTableAfterDelete.emit(true);
              if (this.closeWithHandleClick) {
                this.handleClick.emit(false);
              } else {
                this.closeModal();
              }
            }
          });
        break;

      case this.translate.oil_pipeline:
        this.pipelineService
          .deleteOilPipeline(this.data?.id as number)
          .subscribe((responseOilPipeline: any) => {
            if (responseOilPipeline) {
              this.updateTableAfterDelete.emit(true);
              if (this.closeWithHandleClick) {
                this.handleClick.emit(false);
              } else {
                this.closeModal();
              }
            }
          });
        break;

      case this.translate.power_line:
        this.powerLinesService
          .deletePowerLine(this.data?.id as number)
          .subscribe((responsePowerLine: any) => {
            if (responsePowerLine) {
              this.updateTableAfterDelete.emit(true);
              if (this.closeWithHandleClick) {
                this.handleClick.emit(false);
              } else {
                this.closeModal();
              }
            }
          });
        break;

      case this.translate.pressure_sensor:
        this.pressureService
          .deletePressure(this.data?.id as number)
          .subscribe((responsePowerLine: any) => {
            if (responsePowerLine) {
              this.updateTableAfterDelete.emit(true);
              if (this.closeWithHandleClick) {
                this.handleClick.emit(false);
              } else {
                this.closeModal();
              }
            }
          });
        break;

      default:
        this.objectService
          .deleteObject(this.data?.id as number)
          .subscribe((response: any) => {
            if (response) {
              this.updateTableAfterDelete.emit(true);
              if (this.closeWithHandleClick) {
                this.handleClick.emit(false);
              } else {
                this.closeModal();
              }
            }
          });
        break;
    }
    this.handleSubmit();
  }

  //отмена
  cancel() {
    if (this.closeWithHandleClick) {
      this.handleClick.emit(false);
    } else {
      this.closeModal();
    }
  }
}
