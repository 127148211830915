import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OptionBody } from '../../../../../../../../../models/option.model';

@Component({
  selector: 'app-edit-buttons-oilfield',
  templateUrl: './edit-buttons-oilfield.component.html',
  styleUrls: ['./edit-buttons-oilfield.component.less'],
})
export class EditButtonsOilfieldComponent {
  @Input()
  isEdit: boolean = false;

  @Input()
  isOptionTable: boolean = false;

  @Input()
  listOptions: OptionBody[] = [];

  @Input()
  listSelectedIdsOption: number[] = [];

  @Output()
  handelChange: EventEmitter<null> = new EventEmitter();

  @Output()
  handelSave: EventEmitter<null> = new EventEmitter();

  @Output()
  handelCancel: EventEmitter<null> = new EventEmitter();

  @Output()
  handelPlus: EventEmitter<number> = new EventEmitter();

  public dropdownOpen: boolean = false;

  changeMode() {
    this.handelChange.emit();
  }

  onSaveParams() {
    this.handelSave.emit();
  }

  cancel() {
    this.handelCancel.emit();
  }

  plusChanges(id: number | undefined) {
    this.handelPlus.emit(id as number);
  }
}
