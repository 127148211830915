import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import {
  TUI_DEFAULT_MATCHER,
  TuiContextWithImplicit,
  TuiHandler,
} from '@taiga-ui/cdk';
import { map, startWith, switchMap } from 'rxjs/operators';
import { UsersService } from '../../../../services/users.service';
import {
  TuiAlertService,
  TuiDialogContext,
  TuiDialogService,
  TuiNotification,
} from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.less'],
})
export class AddUserComponent {
  public addUserForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    full_name: new FormControl('', [Validators.required]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9!@#$%^&*()-_=+,.\\[\\]\\{\\};:\'"~|]{8,}$'),
    ]),
    repeated_password: new FormControl('', [
      Validators.required,
      Validators.pattern('[a-zA-Z0-9!@#$%^&*()-_=+,.\\[\\]\\{\\};:\'"~|]{8,}$'),
    ]),
    duty: new FormControl('', []),
    phone: new FormControl('', []),
    comment: new FormControl('', []),
    access: new FormControl([], []),
  });

  public availableAccess = of([
    { value: 'read', title: 'Просмотр' },
    { value: 'create', title: 'Создание' },
    { value: 'update', title: 'Редактирование' },
    { value: 'delete', title: 'Удаление' },
    { value: 'control', title: 'Управление' },
  ]);

  private readonly search$ = new Subject<string>();

  readonly access$ = this.search$.pipe(
    startWith(``),
    switchMap((search: string) =>
      this.availableAccess.pipe(
        map((access) =>
          access
            .filter(({ title }) => TUI_DEFAULT_MATCHER(title, search))
            .map(({ value }) => value)
        )
      )
    ),
    startWith(null) // <-- loading
  );

  readonly stringify$: Observable<
    TuiHandler<number | TuiContextWithImplicit<any>, string>
  > = this.availableAccess.pipe(
    map(
      (access) =>
        new Map(
          access.map<[string, string]>(({ value, title }) => [value, title])
        )
    ),
    startWith(new Map()),
    map((a) => (value: any) => {
      return typeof value === 'string' ? a.get(value) : a.get(value.$implicit);
    })
  );

  get isPasswordWrong() {
    return this.addUserForm.get('password')?.errors?.['pattern'];
  }

  get isPasswordNotMatching() {
    return this.addUserForm.get('repeated_password')?.errors?.['no_match'];
  }

  constructor(
    private usersService: UsersService,
    private alertService: TuiAlertService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<any>
  ) {}

  handleSubmitAddUser() {
    if (this.addUserForm.valid) {
      if (
        this.addUserForm.get('password')?.value &&
        this.addUserForm.get('repeated_password')?.value &&
        this.addUserForm.get('password')?.value !==
          this.addUserForm.get('repeated_password')?.value
      ) {
        this.addUserForm
          .get('repeated_password')
          ?.setErrors({ no_match: true });
        return;
      }
      this.usersService.addUser(this.addUserForm.value).subscribe(() => {
        this.alertService
          .open('Пользователь успешно добавлен.', {
            label: 'Успешно!',
            status: TuiNotification.Success,
          })
          .subscribe();
        this.context.completeWith(true);
      });
    } else {
      this.alertService
        .open('Проверьте правильность заполнения формы', {
          label: 'Ошибка!',
          status: TuiNotification.Error,
        })
        .subscribe();
    }
  }

  onSearch(search: string | null): void {
    this.search$.next(search || ``);
  }
}
