import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { ObjectService } from '../../services/object.service';

@Injectable()
export class BoreholeEffects {
  //loadBoreholes$ = createEffect(() =>
  //  this.actions$.pipe(
  //    ofType(BoreholeActions.load),
  //    mergeMap(() =>
  //      this.boreholeService.boreholes().pipe(
  //        map((boreholes) => {
  //          console.log(boreholes);
  //          return {
  //            type: BoreholeActions.set,
  //            payload: boreholes,
  //          };
  //        }),
  //        catchError(() => EMPTY)
  //      )
  //    )
  //  )
  //);

  constructor(
    private actions$: Actions,
    private boreholeService: ObjectService
  ) {}
}
