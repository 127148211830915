<div [formGroup]="form">
  <div [ngSwitch]="formAttr.controlType">
    <ng-container *ngSwitchCase="'inputText'">
      <app-form-input
        [formControlKey]="formAttr.key"
        [form]="form"
        [label]="formAttr.label"
        [icon]="formAttr.icon"
        [iconAlign]="formAttr.iconAlign"
        [nativeId]="formAttr.key"
        [tuiTextfieldMaxLength]="formAttr.tuiTextfieldMaxLength"
        [tuiTextfieldExampleText]="formAttr.placeholder"
        [visualRequired]="formAttr.visualRequired"
      >
      </app-form-input>
    </ng-container>
    <ng-container *ngSwitchCase="'inputNumber'">
      <app-form-input-number
        [formControlKey]="formAttr.key"
        [form]="form"
        [label]="formAttr.label"
        [min]="formAttr.min"
        [max]="formAttr.max"
        [prefix]="formAttr.prefix"
        [postfix]="formAttr.postfix"
        [precision]="formAttr.precision"
        [decimal]="formAttr.decimal"
        [sizeBlock]="formAttr.sizeBlock"
        [nativeId]="formAttr.key"
        [tuiTextfieldMaxLength]="formAttr.tuiTextfieldMaxLength"
        [tuiTextfieldExampleText]="formAttr.placeholder"
        [visualRequired]="formAttr.visualRequired"
      >
      </app-form-input-number>
    </ng-container>
    <ng-container *ngSwitchCase="'inputPassword'">
      <app-form-input-password
        [formControlKey]="formAttr.key"
        [form]="form"
        [label]="formAttr.label"
        [nativeId]="formAttr.key"
        [tuiTextfieldMaxLength]="formAttr.tuiTextfieldMaxLength"
        [tuiTextfieldExampleText]="formAttr.placeholder"
        [visualRequired]="formAttr.visualRequired"
      ></app-form-input-password>
    </ng-container>
    <!--    <div tuiGroup class="group" [collapsed]="true">-->
    <ng-container *ngSwitchCase="'select'">
      <app-form-select
        [formControlKey]="formAttr.key"
        [isGrouped]="formAttr.isGrouped"
        [form]="form"
        [label]="formAttr.label"
        [nativeId]="formAttr.key"
        [tuiTextfieldMaxLength]="formAttr.tuiTextfieldMaxLength"
        [tuiTextfieldExampleText]="formAttr.placeholder"
        [items]="formAttr.datalistSelect"
        [visualRequired]="formAttr.visualRequired"
      ></app-form-select>
    </ng-container>
    <ng-container *ngSwitchCase="'selectSearch'">
      <app-form-select-search
        [formControlKey]="formAttr.key"
        [isGrouped]="formAttr.isGrouped"
        [form]="form"
        [label]="formAttr.label"
        [nativeId]="formAttr.key"
        [tuiTextfieldMaxLength]="formAttr.tuiTextfieldMaxLength"
        [tuiTextfieldExampleText]="formAttr.placeholder"
        [items]="formAttr.datalistSelect"
        [visualRequired]="formAttr.visualRequired"
      ></app-form-select-search>
    </ng-container>
    <ng-container *ngSwitchCase="'checkboxBlock'">
      <app-form-checkbox-block
        [formControlKey]="formAttr.key"
        [form]="form"
        [content]="formAttr.content"
        [nativeId]="formAttr.key"
        [hideCheckbox]="formAttr.hideCheckbox"
        [sizeBlock]="formAttr.sizeBlock"
        [contentAlign]="formAttr.contentAlign"
      ></app-form-checkbox-block>
    </ng-container>
    <ng-container *ngSwitchCase="'checkboxLabeled'">
      <app-form-checkbox-labeled
        [formControlKey]="formAttr.key"
        [form]="form"
        [content]="formAttr.content"
        [nativeId]="formAttr.key"
        [size]="formAttr.size"
      ></app-form-checkbox-labeled>
    </ng-container>
    <!--      </div>-->
    <ng-container *ngSwitchCase="'radioBlock'">
      <app-form-radio-block
        [formControlKey]="formAttr.key"
        [form]="form"
        [content]="formAttr.content"
        [nativeId]="formAttr.key"
        [hideRadio]="formAttr.hideRadio"
        [sizeBlock]="formAttr.sizeBlock"
        [contentAlign]="formAttr.contentAlign"
        [pseudoDisabled]="formAttr.pseudoDisabled"
        [item]="formAttr.item"
      ></app-form-radio-block>
    </ng-container>
    <ng-container *ngSwitchCase="'radioLabeled'">
      <app-form-radio-labeled
        [formControlKey]="formAttr.key"
        [form]="form"
        [content]="formAttr.content"
        [nativeId]="formAttr.key"
        [size]="formAttr.size"
        [pseudoDisabled]="formAttr.pseudoDisabled"
        [item]="formAttr.item"
        [description]="formAttr.description"
      ></app-form-radio-labeled>
    </ng-container>
  </div>
</div>
