import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ObjectService } from '../../../../services/object.service';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';

//проверка на пробелы
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

@Component({
  selector: 'app-oilfield-add',
  templateUrl: './oilfield-add.component.html',
  styleUrls: ['./oilfield-add.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class OilfieldAddComponent {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  // возвращает созданное месторождение
  @Output() requestCreatedData: EventEmitter<null> = new EventEmitter();

  //форма для имени месторождения
  public formOilfield: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
  });

  constructor(public objectService: ObjectService) {}

  // проверка на валидность поля -> отправка запроса при успешной валидации
  submitForm() {
    if (!this.formOilfield.valid) {
      return this.formOilfield.markAllAsTouched();
    }
    this.objectService
      .objectCreate({
        type: 'deposit',
        name: this.formOilfield.get('name')?.value,
      })
      .subscribe(() => {
        this.requestCreatedData.emit();
        this.closeDialog();
      });
  }

  // очистка полей и закрытие
  closeDialog() {
    this.formOilfield.reset({ name: '' });
    this.closeModal();
  }
}
