<div *ngIf="data" class="common-info__content">
  <app-common-form
    [form]="objectInfoForm"
    [isEdit]="editObjectMode"
    [idSelectedType]="currentType"
    [formData]="changeData()"
    [selectedObject]="selectedObject"
    [typeObjectList]="typeObject"
    (handleCancel)="back()"
    (handleChange)="changeInput()"
  ></app-common-form>
  <app-option-form
    [isEdit]="editOptionMode"
    [selectedOption]="optionData"
    [optionTable]="optionTable"
  ></app-option-form>
</div>
<div *ngIf="!data" class="common-info__no-content">Выберите объект</div>
