import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { Subject, takeUntil } from 'rxjs';
import { BodyModel, OptionBody, OptionModel } from 'src/models/option.model';
import { DeviceModel } from '../../../../../../../../../models/device.model';
import { ObjectService } from '../../../../../../../../services/object.service';
import { DirectoriesService } from '../../../../../../../../services/directories.service';
import { TypeTree } from '../../../../../../../../../models/tree.model';

@Component({
  selector: 'app-option-object-oilfield',
  templateUrl: './option-object-oilfield.component.html',
  styleUrls: ['./option-object-oilfield.component.less'],
})
export class OptionObjectOilfieldComponent implements OnInit, OnDestroy {
  //выбранные ранее у объекта параметры
  public selectedOption: OptionModel[] = [];

  //режим редактирования/просмотра
  public isEdit: boolean = false;

  //id выбраранных параметров нужен для меню выпадающего
  public selectedOptionIds: number[] = [];

  //настройка таблицы
  public options: Array<{
    value: string;
    name: string;
    optionID: number | null;
    deviceID: number | null;
  }> = [];

  //бум дыщь бум
  public destroyer: Subject<null> = new Subject();

  //список устройств
  public listDevice: DeviceModel[] = [];

  //список парамтеров
  public listOptions: OptionBody[] = [];

  //объект выбранный в дереве
  public selectedObject: TypeTree = {
    attributes: undefined,
    id: 0,
    latitude: 0,
    longitude: 0,
    name: '',
    params: [],
    parent_id: null,
    status: null,
    status_device_id: null,
    type: '',
  };

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    private objectService: ObjectService,
    private directoriesService: DirectoriesService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  /*
  1) запись выбранного объекта в переменную
  2) запись параметров с объекта если их нет то пустой массив
  3) выкачка устройств + параметров
  4) сборка таблицы
  * */
  ngOnInit(): void {
    this.objectService.selectedObject
      .pipe(takeUntil(this.destroyer))
      .subscribe(async (object: TypeTree) => {
        this.selectedObject = object;
        this.selectedOption = object?.params || [];
        this.listDevice = await this.objectService.devices;
        this.listOptions = await this.directoriesService.options;
        this.optionsConstructor();
      });
  }

  //бум бада бум
  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }

  /*
    1) смена режима (просмотр/режактирование)
    2) онулирование записей
    * */
  changeMode(): void {
    this.isEdit = !this.isEdit;
    this.optionsConstructor();
  }

  /*
    сохранение параметров
     * */
  hasNullVoidInputs(): boolean {
    let hasNullData = false;
    this.options.forEach((item) => {
      hasNullData = item?.deviceID === null;
      if (hasNullData) {
        return;
      }
    });
    return hasNullData;
  }

  onSaveParams(): void {
    if (this.hasNullVoidInputs()) {
      this.notificationsService
        .open('Заполните все поля', {
          status: TuiNotification.Warning,
        })
        .subscribe();
      return;
    }
    const objectParams: BodyModel[] = [];
    this.options.forEach((item) => {
      objectParams.push({
        parametrs_id: item.optionID as number,
        device_id: item.deviceID as number,
      });
    });
    const body: {
      object_params: BodyModel[];
      status_device_id?: number;
      pressure_id?: number;
    } = {
      object_params: objectParams,
    };
    if (this.selectedObject?.pressure) {
      body.pressure_id = this.selectedObject?.pressure?.id;
    }
    if (this.selectedObject?.status_device_id) {
      body.status_device_id = this.selectedObject?.status_device_id;
    }
    this.directoriesService
      .setParamObject(this.selectedObject.id as number, body)
      .subscribe(() => {
        this.objectService
          .getObjectByID(this.selectedObject.id as number)
          .subscribe((data: TypeTree) => {
            this.objectService.selectedObject.next(data);
            this.isEdit = !this.isEdit;
          });
        this.objectService
          .getDataToTree()
          .subscribe((data: { tree: Array<TypeTree> }) => {
            this.objectService.updateTree.next(data);
          });
        this.notificationsService
          .open('Настройки параметров сохранены', {
            status: TuiNotification.Success,
          })
          .subscribe();
      });
  }

  //удаление устойства от параметра
  deleteDevice(id: number | null): void {
    this.options = this.options.map((item) => {
      return id === item.optionID ? { ...item, deviceID: null } : { ...item };
    });
  }

  //доавбление устройства для парамтера
  selectDeviceForOption(
    item: DeviceModel | { [key: string]: DeviceModel | null } | null,
    idOption: number | null
  ): void {
    this.options = this.options.map((option) => {
      return option.optionID === idOption
        ? { ...option, deviceID: item?.id as number }
        : { ...option };
    });
  }

  //добавление параметра без устройства
  selectOption(id: number): void {
    if (this.options.some((item) => item.optionID === id)) {
      //удаление объекта
      this.options =
        this.options.filter((option) => {
          return id !== option.optionID;
        }) || [];
    } else {
      //добавление объекта
      this.options.push({
        value: '-',
        name:
          this.listOptions.find((item) => {
            return item.id === id;
          })?.name || '-',
        optionID: id || null,
        deviceID: null,
      });
    }
  }

  //создание данных для таблицы выбранных ранее данных
  optionsConstructor(): void {
    this.options = [];
    this.selectedOptionIds = [];
    this.selectedOption.map((item) => {
      this.options.push({
        value: item?.value || '-',
        name: item?.param.name || 'Не выбран',
        optionID: item?.param?.id || null,
        deviceID: item?.device_id || null,
      });
      this.selectedOptionIds.push(item.param.id);
    });
    this.changeDetectorRef.detectChanges();
  }
}
