<div class="sorting">
  <div class="sorting__header">
    <div class="sorting__header-icon">
      <button
        tuiIconButton
        (click)="changeSortDirection()"
        appearance="flat"
        size="m"
        type="button"
        [icon]="byDescending ? 'tuiIconArrowDown' : 'tuiIconArrowUp'"
      ></button>
    </div>
    <tui-hosted-dropdown [content]="content">
      <span class="sorting__input-title">Сортировать по:</span>
      <span class="sorting__input-selected">
        {{ selectedSortedItem?.name || MINUS }}
      </span>
    </tui-hosted-dropdown>
    <ng-template #content>
      <button
        tuiButton
        class="sorting__input-item"
        appearance="flat"
        *ngFor="let item of listOfSortingItems"
        (click)="setValue(item)"
      >
        {{ item.name }}
      </button>
    </ng-template>
  </div>
</div>
