import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-sorting",
  templateUrl: "./sorting.component.html",
  styleUrls: ["./sorting.component.less"],
})
export class SortingComponent {
  /**
   * Variables
   * @description listOfSortingItems type: Array{ id: string; value: string } -  takes a list of sortable items'
   * @description handleClick  type: EventEmitter<{ id: string; isDescending: boolean; }> -  handleClick output to the parent of the selected item
   * @description selectedSortedItem type: string - stores the selected item from the list
   * @description byDescending type: boolean- value changes by clicking on the icon
   */

  @Input()
  listOfSortingItems!: Array<{ name: string; value: string }>;

  @Output()
  handleClick = new EventEmitter<{ name: string; isDescending: boolean }>();

  public selectedSortedItem: { name: string; value: string } | null = null;

  public byDescending: boolean = true;

  public readonly MINUS = "-";

  /**
   * saves the variable name and sends the selected item with the sort type to the parent
   * @param {id: string; value: string} item
   * @return { id: string, isDescending: boolean }
   */
  setValue(item: { name: string; value: string }): void {
    this.selectedSortedItem = item;
    this.handleClick.emit({ name: item.name, isDescending: this.byDescending });
  }

  /**
   * saves the byDescending and sends the selected item with the sort type to the parent
   * @return { id: string, isDescending: boolean }
   */
  changeSortDirection(): void {
    if (this.selectedSortedItem) {
      this.byDescending = !this.byDescending;
      this.handleClick.emit({
        name: this.selectedSortedItem.name,
        isDescending: this.byDescending,
      });
    }
  }
}
