<app-primary-section>
  <div class="widgets">
    <app-secondary-section
      class="widgets__container"
      *ngFor="let widget of widgets"
    >
      <div class="widget">
        <div class="widget__title">
          <h2>{{ widget.title }}</h2>
        </div>
        <div class="widget__footer">
          <div>
            <h3>{{ widget.subtitle }}</h3>
            <span>{{ getCurrentStatus(widget.counterObject) }}</span>
          </div>
          <div class="icon">
            <i-tabler style="color: #ef6c00" [name]="widget.iconUrl"></i-tabler>
          </div>
        </div>
      </div>
    </app-secondary-section>
  </div>
</app-primary-section>
