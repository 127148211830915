<div class="navigation" [class._visible]="isMenuVisible">
  <ng-container *ngFor="let nav of listOfNavLinks">
    <div
      *ngIf="nav?.title"
      class="navigation__button"
      [class._visible]="isMenuVisible"
    >
      <ng-container *ngIf="!nav.hasOwnProperty('multiButton'); else scheme">
        <button
          [routerLink]="'/main/' + nav.id"
          [routerLinkActive]="['_active']"
          (click)="changeBurgerMenu('selectItem')"
        >
          {{ nav?.title }}
        </button>
      </ng-container>
      <ng-template #scheme>
        <button
          mat-button
          [matMenuTriggerFor]="menu"
          [routerLinkActive]="['_active']"
          (click)="changeBurgerMenu('selectItem')"
        >
          {{ nav?.title }}
        </button>
        <mat-menu #menu="matMenu">
          <div class="multi-menu">
            <ng-container *ngFor="let subNav of nav.multiButton">
              <button
                [routerLink]="'/main/' + subNav.id"
                (click)="changeBurgerMenu('selectItem')"
              >
                {{ subNav?.title }}
              </button>
            </ng-container>
          </div>
        </mat-menu>
      </ng-template>
    </div>
  </ng-container>
  <div
    *ngIf="isEditMode"
    class="navigation__button-plus"
    [class._visible]="isMenuVisible"
    (click)="addNewItemByNavigationBar()"
  >
    <app-plus></app-plus>
  </div>
  <div
    class="navigation__button-menu"
    [class._visible]="isMenuVisible"
    (click)="changeBurgerMenu('change')"
  >
    <tui-svg src="tuiIconAlignJustifyLarge"></tui-svg>
  </div>
</div>
