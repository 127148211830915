import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import * as echarts from "echarts";
import { PipelineService } from "../../../app/services/pipeline.service";

@Component({
  selector: "app-pipeline-relief",
  templateUrl: "./pipeline-relief.component.html",
  styleUrls: ["./pipeline-relief.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipelineReliefComponent implements OnInit {
  @Input() pipeline: any = null;

  @Input() pipelineType: "aqua" | "oil" | null = null;

  @Output() clickOnPoint = new EventEmitter();

  public data: any[] = [];

  public isLoading: boolean = true;

  constructor(
    private pipelineService: PipelineService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.pipeline && this.pipelineType) {
      this.pipelineService
        .getPipelineRelief(this.pipeline, this.pipelineType)
        .subscribe((result: any) => {
          this.data = result;
          this.renderGraph();
          this.isLoading = false;
          this.changeDetector.detectChanges();
        });
    }
  }

  renderGraph() {
    let chartDom = document.getElementById("relief-chart");
    if (chartDom) {
      let myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: "item",
          formatter: "{c0} м",
        },
        xAxis: {
          type: "category",
          data: this.data.map(() => ""),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: this.data.map((p: any) => p.elevation),
            type: "line",
            symbolSize: 8,
          },
        ],
      };
      myChart.setOption(option);
      myChart.on("mouseover", (params) => {
        if (params.componentType === "series") {
          const point = this.data[params.dataIndex];
          this.clickOnPoint.emit(point);
        }
      });
    }
  }
}
