<div class="oilfield-add">
  <app-primary-section>
    <div class="oilfield-add__container">
      <h2 class="title">Добавить ЛЭП</h2>
      <div class="cross" (click)="closeModal()">
        <img src="/assets/cross.png" alt="(X)" />
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="powerLineForm">
      <div class="form__title">
        <h4>Общая информация</h4>
      </div>
      <div class="form__field">
        <span>Название *</span>
        <div class="input">
          <tui-input [tuiTextfieldMaxLength]="40" formControlName="name">
            <span class="input__placeholder">Укажите название</span>
          </tui-input>
          <tui-field-error formControlName="name"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Назначение</span>
        <div class="input">
          <tui-input formControlName="purpose" tuiTextfieldSize="m">
            Укажите назначение
            <input maxlength="40" tuiTextfield type="text" />
          </tui-input>
          <tui-field-error formControlName="purpose"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Способ прокладки</span>
        <app-form-select-with-manual-input
          [items]="layingType ? layingType : []"
          [form]="powerLineForm"
          [formControlNameSelect]="'laying_method'"
          [label]="'Укажите способ прокладки'"
          [visualRequired]="false"
          [greyStyle]="true"
          class="form__field_double"
        >
        </app-form-select-with-manual-input>
      </div>

      <div class="form__field">
        <span>Напряжение, В</span>
        <div class="input">
          <tui-input-number tuiTextfieldSize="m" formControlName="voltage">
            Укажите напряжение
          </tui-input-number>
          <tui-field-error formControlName="voltage"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Род тока</span>
        <app-form-select-with-manual-input
          [items]="currentType ? currentType : []"
          [form]="powerLineForm"
          [formControlNameSelect]="'current_type'"
          [label]="'Укажите род тока'"
          [visualRequired]="false"
          [greyStyle]="true"
          class="form__field_double"
        >
        </app-form-select-with-manual-input>
      </div>

      <div class="form__field">
        <span>Точка подключения</span>
        <div class="input">
          <tui-input formControlName="connection_point" tuiTextfieldSize="m">
            Укажите точку подключения
            <input maxlength="40" tuiTextfield type="text" />
          </tui-input>
          <tui-field-error formControlName="connection_point"></tui-field-error>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="powerLineForm">
      <div class="form__title">
        <h4>Координаты</h4>
      </div>
      <div class="oilfield-add__map">
        <app-pipeline-map
          [pipelines]="electricityPipeline"
          [isEdit]="isEdit"
          (handleDraw)="getCoords($event)"
        >
        </app-pipeline-map>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="footer">
      <span>* Обязательные для заполнения поля</span>
      <div class="footer__buttons">
        <button (click)="submitForm()" class="footer-button _add">
          Добавить
        </button>
        <button (click)="closeDialog()" class="footer-button _cancel">
          Отменить
        </button>
      </div>
    </div>
  </app-primary-section>
</div>
