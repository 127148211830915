import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import {
  TUI_SANITIZER,
  TuiButtonModule,
  TuiDataListModule,
  TuiDialogModule,
  TuiHintModule,
  TuiLoaderModule,
  TuiNotificationsModule,
  TuiRootModule,
  TuiScrollbarModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from './auth/auth.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Interceptors } from './interceptors/interceptors';
import { MainModule } from './pages/main/main.module';
import { UIKitModule } from '../common/ui-kit/ui-kit.module';
import { TUI_LANGUAGE, TUI_RUSSIAN_LANGUAGE } from '@taiga-ui/i18n';
import { of } from 'rxjs';
import {
  TUI_VALIDATION_ERRORS,
  TuiBadgeModule,
  TuiComboBoxModule,
  TuiDataListWrapperModule,
  TuiFilterByInputPipeModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiPaginationModule,
  TuiSelectModule,
  TuiStringifyContentPipeModule,
  TuiTabsModule,
} from '@taiga-ui/kit';
import localeRu from '@angular/common/locales/ru';
import { registerLocaleData } from '@angular/common';
import { CommonModule } from '../common/common.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { IconsModule } from './icons/icons.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TuiDestroyService, TuiLetModule } from '@taiga-ui/cdk';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import * as boreholeStore from './store/reducers/borehole.reducer';
import { EffectsModule } from '@ngrx/effects';
import { BoreholeEffects } from './store/effects/borehole.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
// import { environment } from '../environments/environment.prod';

export function minLengthValidator(context: {
  requiredLength: string;
}): string {
  return `Количество символов должно быть не меньше ${context.requiredLength}`;
}

export function maxLengthValidator(context: {
  requiredLength: string;
}): string {
  return `Количество символов должно быть не больше ${context.requiredLength}`;
}

registerLocaleData(localeRu, 'ru-RU');
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    MainModule,
    UIKitModule,
    TuiRootModule,
    TuiDialogModule,
    TuiNotificationsModule,
    TuiTabsModule,
    CommonModule,
    TuiButtonModule,
    LeafletModule,
    LeafletDrawModule,
    TuiBadgeModule,
    IconsModule,
    TuiHintModule,
    ReactiveFormsModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiInputNumberModule,
    TuiDataListModule,
    TuiSelectModule,
    TuiLoaderModule,
    TuiLetModule,
    TuiComboBoxModule,
    TuiDataListWrapperModule,
    TuiFilterByInputPipeModule,
    TuiStringifyContentPipeModule,
    ScrollingModule,
    TuiScrollbarModule,
    TuiPaginationModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    StoreModule.forRoot({ boreholes: boreholeStore.reducer }),
    EffectsModule.forRoot([BoreholeEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptors,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
    {
      provide: TUI_LANGUAGE,
      useValue: of(TUI_RUSSIAN_LANGUAGE),
    },
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        email: `Пожалуйста, введите валидный email адрес`,
        minlength: minLengthValidator,
        maxlength: maxLengthValidator,
      },
    },
    TuiDestroyService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
