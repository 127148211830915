<div class="tree-search">
  <tui-input
    tuiTextfieldSize="m"
    icon="tuiIconSearchLarge"
    [(ngModel)]="search"
    (ngModelChange)="handleChange()"
    [tuiTextfieldCleaner]="true"
  >
    Поиск
  </tui-input>
</div>
