import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ObjectService } from '../../../../services/object.service';
import { TypeTree } from '../../../../../models/tree.model';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';

//проверка на пробелы
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

@Component({
  selector: 'app-bush-add',
  templateUrl: './bush-add.component.html',
  styleUrls: ['./bush-add.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class BushAddComponent implements OnInit {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  // возвращает созданное месторождение
  @Output() requestCreatedData: EventEmitter<null> = new EventEmitter();

  //для формы -> список скважин
  public depositList: any = [];

  //форма для имени месторождения
  public formBush: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    deposit: new FormControl('', [Validators.required]),
  });

  constructor(public objectService: ObjectService) {}

  async ngOnInit() {
    const deposits = await this.objectService.deposits;
    this.depositList = deposits.map((item: TypeTree) => {
      return { id: item.id, title: item.name };
    });
  }

  // проверка на валидность поля -> отправка запроса при успешной валидации
  submitForm() {
    if (!this.formBush.valid) {
      return this.formBush.markAllAsTouched();
    }
    this.objectService
      .objectCreate({
        type: 'bush',
        name: this.formBush.get('name')?.value,
        parent_id: this.formBush.get('deposit')?.value.id,
      })
      .subscribe(() => {
        this.requestCreatedData.emit();
        this.closeDialog();
      });
  }

  // очистка полей и закрытие
  closeDialog() {
    this.formBush.reset({ name: '' });
    this.closeModal();
  }
}
