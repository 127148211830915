<div class="events-filter" [@expandedFilter]="state">
  <div class="events-filter__template">
    <button type="button" class="close-filter" (click)="close()">
      <i-tabler name="x"></i-tabler>
    </button>
  </div>
  <app-primary-section>
    <app-secondary-section>
      <h2>Фильтр</h2>
    </app-secondary-section>
  </app-primary-section>

  <ng-container *ngIf="filterMode === 'borehole-events'">
    <form [formGroup]="filterForm">
      <div class="events-filter__container">
        <perfect-scrollbar>
          <!--                  <app-primary-section>-->
          <!--                    <app-secondary-section>-->
          <!--                      <h3>Тип событий</h3>-->
          <!--                      <tui-select-->
          <!--                        tuiTextfieldSize="m"-->
          <!--                        class="tui-space_vertical-4"-->
          <!--                        [formControl]="filterForm.controls.event_type"-->
          <!--                        [tuiTextfieldLabelOutside]="true"-->
          <!--                      >-->
          <!--                        Выберите статус прочтения-->
          <!--                        <input tuiTextfield />-->
          <!--                        <tui-data-list-wrapper-->
          <!--                          *tuiDataList-->
          <!--                          [items]="getAvailableEventTypesArr"-->
          <!--                        ></tui-data-list-wrapper>-->
          <!--                      </tui-select>-->
          <!--                    </app-secondary-section>-->
          <!--                  </app-primary-section>-->
          <app-primary-section>
            <app-secondary-section>
              <h3>Статус</h3>
              <div class="status-filter">
                <tui-checkbox-labeled
                  *ngFor="let control of getStatusControls(); let i = index"
                  [formControl]="control"
                  class="tui-space_bottom-3 status-checkbox"
                  >{{ availableStatus[i].title }}</tui-checkbox-labeled
                >
              </div>
            </app-secondary-section>
          </app-primary-section>
          <app-primary-section>
            <app-secondary-section>
              <h3>Тип события</h3>
              <div class="status-filter">
                <tui-checkbox-labeled
                  *ngFor="let control of getTypeControls(); let i = index"
                  [formControl]="control"
                  class="tui-space_bottom-3 status-checkbox"
                  >{{ availableStatusMessages[i].title }}</tui-checkbox-labeled
                >
              </div>
            </app-secondary-section>
          </app-primary-section>
          <app-primary-section>
            <app-secondary-section>
              <h3>Прочитанные</h3>
              <tui-select
                tuiTextfieldSize="m"
                class="tui-space_vertical-4"
                [formControl]="filterForm.controls.react_at"
                [tuiTextfieldLabelOutside]="true"
              >
                Выберите статус прочтения
                <input tuiTextfield />
                <tui-data-list-wrapper
                  *tuiDataList
                  [items]="['Все', 'Да', 'Нет']"
                ></tui-data-list-wrapper>
              </tui-select>
            </app-secondary-section>
          </app-primary-section>
          <app-primary-section>
            <app-secondary-section>
              <app-form-multiselect-with-search-for-huge-arrays
                [form]="filterForm"
                [formControlNameSelect]="'object_name'"
                [label]="'Выберите имена объектов'"
                [placeholderMessage]="'Введите имя объекта...'"
                [visualRequired]="false"
                [items]="availableNamesObject"
              >
              </app-form-multiselect-with-search-for-huge-arrays>

              <!--              <tui-multi-select-->
              <!--                [class.tui-skeleton]="skeletonVisible"-->
              <!--                [class.tui-skeleton_rounded]="skeletonVisible"-->
              <!--                *tuiLet="availableNamesObject$ | async as items"-->
              <!--                #component-->
              <!--                tuiTextfieldExampleText="Введите имя объекта..."-->
              <!--                class="control"-->
              <!--                tuiTextfieldSize="m"-->
              <!--                [stringify]="stringifyComboBox"-->
              <!--                formControlName="object_name"-->
              <!--                [tuiTextfieldLabelOutside]="true"-->

              <!--                (input)="onSearchChange(extractValueFromEvent($event))"-->
              <!--              >-->
              <!--                Выберите имена объектов-->
              <!--                <tui-data-list-wrapper-->
              <!--                  *tuiDataList-->
              <!--                  [items]="items | tuiHideSelected : component"-->
              <!--                  [itemContent]="skeletonVisible ? loading : itemContent"-->
              <!--                ></tui-data-list-wrapper>-->
              <!--              </tui-multi-select>-->
            </app-secondary-section>
            <app-secondary-section>
              <app-form-multiselect-with-search-for-huge-arrays
                [form]="filterForm"
                [formControlNameSelect]="'deposit_name'"
                [label]="'Выберите имена месторождений'"
                [placeholderMessage]="'Введите имя месторождения...'"
                [visualRequired]="false"
                [items]="availableNamesDeposits"
              >
              </app-form-multiselect-with-search-for-huge-arrays>

              <!--              <tui-multi-select-->
              <!--                [class.tui-skeleton]="skeletonVisible"-->
              <!--                [class.tui-skeleton_rounded]="skeletonVisible"-->
              <!--                tuiTextfieldSize="m"-->
              <!--                *tuiLet="availableNamesDeposits$ | async as items"-->
              <!--                #component-->
              <!--                tuiTextfieldExampleText="Введите имя месторождения..."-->
              <!--                class="control"-->
              <!--                [stringify]="stringifyComboBox"-->
              <!--                formControlName="deposit_name"-->
              <!--                [tuiTextfieldLabelOutside]="true"-->

              <!--                (input)="onSearchDepositChange(extractValueFromEvent($event))"-->
              <!--              >-->
              <!--                Выберите имена месторождения-->
              <!--                <tui-data-list-wrapper-->
              <!--                  *tuiDataList-->
              <!--                  [items]="items | tuiHideSelected : component"-->
              <!--                  [itemContent]="skeletonVisible ? loading : itemContent"-->
              <!--                ></tui-data-list-wrapper>-->
              <!--              </tui-multi-select>-->
            </app-secondary-section>
          </app-primary-section>
          <a class="clear-filter" (click)="clearFilter()">Сбросить фильтр</a>
        </perfect-scrollbar>
      </div>
      <button class="apply-filter" (click)="applyFilter()">
        Применить фильтр
      </button>
    </form>
  </ng-container>

  <ng-container *ngIf="filterMode === 'pressure-events'">
    <form [formGroup]="filterForm">
      <div class="events-filter__container">
        <perfect-scrollbar>
          <app-primary-section>
            <app-secondary-section>
              <h3>Тип события</h3>
              <div class="status-filter">
                <tui-checkbox-labeled
                  *ngFor="let control of getTypeControls(); let i = index"
                  [formControl]="control"
                  class="tui-space_bottom-3 status-checkbox"
                  >{{ pressureStatusMessages[i]?.title }}</tui-checkbox-labeled
                >
              </div>
            </app-secondary-section>
          </app-primary-section>
          <app-primary-section>
            <app-secondary-section>
              <h3>Прочитанные</h3>
              <tui-select
                tuiTextfieldSize="m"
                class="tui-space_vertical-4"
                [formControl]="filterForm.controls.react_at"
                [tuiTextfieldLabelOutside]="true"
              >
                Выберите статус прочтения
                <input tuiTextfield />
                <tui-data-list-wrapper
                  *tuiDataList
                  [items]="['Все', 'Да', 'Нет']"
                ></tui-data-list-wrapper>
              </tui-select>
            </app-secondary-section>
          </app-primary-section>
          <app-primary-section>
            <app-secondary-section>
              <app-form-multiselect-with-search-for-huge-arrays
                [form]="filterForm"
                [formControlNameSelect]="'object_name'"
                [label]="'Выберите имена датчиков'"
                [placeholderMessage]="'Введите имя датчика...'"
                [visualRequired]="false"
                [items]="availableNamesPressures"
              >
              </app-form-multiselect-with-search-for-huge-arrays>

              <!--              <tui-multi-select-->
              <!--                *tuiLet="availableNamesPressures$ | async as items"-->
              <!--                [class.tui-skeleton]="skeletonVisible"-->
              <!--                [class.tui-skeleton_rounded]="skeletonVisible"-->
              <!--                #component-->
              <!--                tuiTextfieldExampleText="Введите имя датчика..."-->
              <!--                class="control"-->
              <!--                tuiTextfieldSize="m"-->
              <!--                [stringify]="stringifyComboBox"-->
              <!--                formControlName="object_name"-->
              <!--                [tuiTextfieldLabelOutside]="true"-->

              <!--                (input)="onSearchPressureChange(extractValueFromEvent($event))"-->
              <!--              >-->
              <!--                Выберите имена датчиков-->
              <!--                <tui-data-list-wrapper-->
              <!--                  *tuiDataList-->
              <!--                  [items]="items | tuiHideSelected : component"-->
              <!--                  [itemContent]="skeletonVisible ? loading : itemContent"-->
              <!--                ></tui-data-list-wrapper>-->
              <!--              </tui-multi-select>-->
            </app-secondary-section>
            <app-secondary-section>
              <app-form-multiselect-with-search-for-huge-arrays
                [form]="filterForm"
                [formControlNameSelect]="'deposit_name'"
                [label]="'Выберите имена месторождений'"
                [placeholderMessage]="'Введите имя месторождения...'"
                [visualRequired]="false"
                [items]="availableNamesDeposits"
              >
              </app-form-multiselect-with-search-for-huge-arrays>

              <!--              <tui-multi-select-->
              <!--                [class.tui-skeleton]="skeletonVisible"-->
              <!--                [class.tui-skeleton_rounded]="skeletonVisible"-->
              <!--                tuiTextfieldSize="m"-->
              <!--                *tuiLet="availableNamesDeposits$ | async as items"-->
              <!--                #component-->
              <!--                tuiTextfieldExampleText="Введите имя месторождения..."-->
              <!--                class="control"-->
              <!--                [stringify]="stringifyComboBox"-->
              <!--                formControlName="deposit_name"-->
              <!--                [tuiTextfieldLabelOutside]="true"-->

              <!--                (input)="onSearchDepositChange(extractValueFromEvent($event))"-->
              <!--              >-->
              <!--                Выберите имена месторождения-->
              <!--                <tui-data-list-wrapper-->
              <!--                  *tuiDataList-->
              <!--                  [items]="items | tuiHideSelected : component"-->
              <!--                  [itemContent]="skeletonVisible ? loading : itemContent"-->
              <!--                ></tui-data-list-wrapper>-->
              <!--              </tui-multi-select>-->
            </app-secondary-section>
          </app-primary-section>
          <a class="clear-filter" (click)="clearFilter()">Сбросить фильтр</a>
        </perfect-scrollbar>
      </div>
      <button class="apply-filter" (click)="applyFilter()">
        Применить фильтр
      </button>
    </form>
  </ng-container>

  <!--  <ng-template #itemContent let-data>-->
  <!--    <div class="template">-->
  <!--      <div class="name">{{ data.name }}</div>-->
  <!--    </div>-->
  <!--  </ng-template>-->
</div>
<!--<ng-template #loading>-->
<!--  <tui-loader class="loader"></tui-loader>-->
<!--</ng-template>-->
