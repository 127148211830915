<app-primary-section>
  <app-primary-table
    [isSort]="false"
    [columns]="tableColumns"
    [disabled]="false"
    class="table"
    [updateRequestSubject]="updateRequestSubject"
  >
    <button
      additionalButtons
      tuiIconButton
      appearance="flat"
      [tuiHint]="tooltip"
      type="button"
      (click)="markReadEventsByObject()"
    >
      <i-tabler name="mail-opened"></i-tabler>
    </button>
    <tui-loader [showLoader]="isLoading" [size]="'l'"></tui-loader>
    <ng-container *ngIf="!events.length && !isLoading">
      <tr>
        <td [attr.colspan]="5">
          <div class="no_data"> События отсутствуют</div>
         </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="events.length && !isLoading">
      <tr *ngFor="let row of events; index as i">
        <td>{{ i + 1 + (paginator.currentPage - 1) * paginator.perPage }}</td>
        <td>{{ getFormattedDate(row.created_at) }}</td>
        <td>
          <tui-badge
            *ngIf="
              ['max_error', 'min_error', 'max_warning', 'min_warning', 'normal'].includes(
                row.type
              );
              else emptyStatus
            "
            [class.pressure-error]="
              ['max_error', 'min_error'].includes(row.type)
            "
            [class.pressure-warning]="
              ['max_warning', 'min_warning'].includes(row.type)
            "
            [class.pressure-normal]="
              ['normal'].includes(row.type)
            "
            [value]="statusMessages[row.type]"
            [size]="'m'"
            class="status"
          >
          </tui-badge>
          <ng-template #emptyStatus>-</ng-template>
        </td>
        <td *ngIf="row.react_at">{{ getFormattedDate(row.react_at) }}</td>
        <td *ngIf="!row.react_at">
          <button (click)="markReadEvent(row.id)" class="read-event">
            Прочитано
          </button>
        </td>
        <td></td>
      </tr>
    </ng-container>
  </app-primary-table>
  <tui-pagination
    class="events-paginator"
    *ngIf="events.length && paginator.lastPage !== 1"
    [activePadding]="1"
    [length]="paginator.lastPage"
    (indexChange)="changePage($event)"
    [index]="paginator.currentPage - 1"
  ></tui-pagination>
</app-primary-section>
<ng-template #tooltip>
  Отметить все события по объекту как прочитанные
</ng-template>
