import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TuiAlertService } from '@taiga-ui/core';
import { ObjectService } from '../../../../../services/object.service';
import { PressureService } from '../../../../../services/pressure.service';
import { TypeTree } from 'src/models/tree.model';
import { TuiContextWithImplicit, tuiPure } from '@taiga-ui/cdk';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import {
  maxValueValidator,
  minValueValidator,
} from '../../../dns-card/components/common-info-dns/common-info-dns.component';
import { takeUntil } from 'rxjs/operators';
import * as memoizee from 'memoizee';
import { PressureItemType } from 'src/models/pressure-meter.model';
import { Subject } from 'rxjs';

function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control.value?.trim() || '';
    if ((control?.value?.length || '') !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

@Component({
  selector: 'app-common-info-pressure-sensor',
  templateUrl: './common-info-pressure-sensor.component.html',
  styleUrls: ['./common-info-pressure-sensor.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class CommonInfoPressureSensorComponent implements OnInit, OnDestroy {
  public label: any[] = [
    {
      name: 'name',
      title: 'Название',
      isSort: true,
      isFilter: true,
    },
    {
      name: 'value',
      title: 'Значение',
      isSort: true,
      isFilter: true,
    },
  ];

  // public data: any = null;

  // Переменная для определения режима редактирования
  public editObjectMode: boolean = false;

  //public mapCoords: any = {};

  public visibleMap: boolean = true;

  public parent: any | null = null;

  public deviceType: any | null = null;

  public onClickOnMap = new EventEmitter();

  public latitude: number = 55;

  public longitude: number = 55;

  // Переменная для изменения названия полей для ввода лимитов в зависимости от того, активны ли они
  public limitsFormActive: boolean = false;

  public skeletonVisible: boolean = true;

  @Input()
  public contextObject: PressureItemType | null = null;

  @Output() handleChange: EventEmitter<any> = new EventEmitter();

  public pressureInfoForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    deposit: new FormControl('', [Validators.required]),
    device: new FormControl('', [Validators.required]),
    device_type: new FormControl(null, [Validators.required]),
    limits: new FormControl(this.limitsFormActive, []),
    // Объединяем лимиты в отдельную группу
    limitsGroup: new FormGroup({
      min_warning: new FormControl('', [Validators.required]),
      min_error: new FormControl('', [Validators.required]),
      max_warning: new FormControl('', [Validators.required]),
      max_error: new FormControl('', [Validators.required]),
    }),
    latitude: new FormControl('', [
      Validators.required,
      Validators.min(-90),
      Validators.max(90),
    ]),
    longitude: new FormControl('', [
      Validators.required,
      Validators.min(-180),
      Validators.max(180),
    ]),
  });

  //для формы -> список скважин
  public depositList: any = [];

  // Список доступных устройств (датчиков давления) для формы
  public deviceList: any = [];

  public pressureSensorType: any[] = [];

  public pressureSensorSelectedType: any = null;

  private destroyer: Subject<any> = new Subject<any>();

  public isLoading = true;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    public objectService: ObjectService,
    public pressureService: PressureService
  ) {}

  async ngOnInit() {
    if (this.contextObject) {
      // Получаем список месторождений для формирования выпадающего списка в форме
      const deposits = await this.objectService.deposits;

      // Получаем список устройств для формирования выпадающего списка в форме
      const devices = await this.pressureService.devices;

      // Избавляемся от лишних полей
      this.depositList = deposits.map((item: TypeTree) => {
        return { id: item.id, name: item.name };
      });

      // Получаем список типов датчиков давления
      this.pressureSensorType = this.pressureService.pressureSensorTypes;

      // Присваиваем значение переменной типа датчика давления
      if (this.contextObject?.device_field) {
        const pressureSensorSelectedTypeId = this.contextObject?.device_field;
        this.pressureSensorSelectedType = this.pressureSensorType.filter(
          (item) => {
            return item.id === pressureSensorSelectedTypeId;
          }
        )[0];
      }

      this.skeletonVisible = false;

      // Получаем месторождение, указанное в свойствах датчика давления
      this.parent = this.depositList.find((item: TypeTree) => {
        return item.id === this.contextObject?.deposit_id;
      });

      // Очищаем объекты устройств (типов датчиков давления) от ненужных нам полей для корректной работы выпадающего списка
      this.deviceList = devices.map((item: any) => {
        return { id: item.id, name: item.name };
      });

      // Получаем тип датчика, указанный в свойствах датчика давления
      this.deviceType = this.deviceList.find((item: TypeTree) => {
        return item.id === this.contextObject?.device_id;
      });

      // Устанавливаем начальные значения формы при редактировании
      this.pressureInfoForm
        .get('name')
        ?.setValue(this.contextObject?.name || '');
      this.pressureInfoForm
        .get('latitude')
        ?.setValue(this.contextObject?.latitude || 0);
      this.pressureInfoForm
        .get('longitude')
        ?.setValue(this.contextObject?.longitude || 0);
      this.pressureInfoForm.get('deposit')?.setValue(this.parent || null);
      this.pressureInfoForm.controls.device.setValue(this.deviceType || null);
      this.pressureInfoForm.controls.device_type.setValue(
        this.contextObject?.device_field
      );
      // Устанавливаем пороги
      if (this.contextObject?.min_warning !== null) {
        this.limitsFormActive = true;
        this.pressureInfoForm.controls.limits.setValue(true);
        this.pressureInfoForm.controls.limitsGroup.controls.min_warning.setValue(
          this.contextObject.min_warning
        );
        this.pressureInfoForm.controls.limitsGroup.controls.min_error.setValue(
          this.contextObject.min_error
        );
        this.pressureInfoForm.controls.limitsGroup.controls.max_warning.setValue(
          this.contextObject.max_warning
        );
        this.pressureInfoForm.controls.limitsGroup.controls.max_error.setValue(
          this.contextObject.max_error
        );
      } else {
        this.pressureInfoForm.controls.limitsGroup.disable();
      }
      this.latitude = this.contextObject?.latitude || 0;
      this.longitude = this.contextObject?.longitude || 0;
      this.onClickOnMap
        .pipe(takeUntil(this.destroyer))
        .subscribe((coordinates) => {
          this.pressureInfoForm.get('latitude')?.setValue(coordinates.lat);
          this.pressureInfoForm.get('longitude')?.setValue(coordinates.lng);
        });

      this.pressureInfoForm
        .get('latitude')
        .valueChanges.pipe(takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (this.pressureInfoForm.get('latitude')?.valid) {
            this.latitude = item;
            return;
          }
          return;
        });
      this.pressureInfoForm
        .get('longitude')
        .valueChanges.pipe(takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (this.pressureInfoForm.get('longitude')?.valid) {
            this.longitude = item;
            return;
          }
          return;
        });

      // Активация и деактивация полей для ввода порогов в зависимости от положения выключателя
      this.pressureInfoForm
        .get('limits')
        ?.valueChanges.pipe(takeUntil(this.destroyer))
        .subscribe((value: any) => {
          if (value) {
            // Активируем поля для ввода порогов
            this.pressureInfoForm.controls.limitsGroup.enable();
            // Добавляем звездочки в название полей лимитов
            this.limitsFormActive = true;
          } else {
            // Деактивируем поля для ввода порогов
            this.pressureInfoForm.controls.limitsGroup.disable();
            // Убираем звездочки из названий полей лимитов
            this.limitsFormActive = false;
          }
        });
    }
    this.isLoading = false;
  }

  getDepositNameByID = memoizee((parent_id: any) => {
    return new Promise((resolve) => {
      this.objectService.getDepositsList().subscribe((res: TypeTree[]) => {
        resolve(
          res.find((item) => {
            return item.id === parent_id;
          })?.name || 'Не выбран'
        );
      });
      return;
    });
  });

  save() {
    if (!this.pressureInfoForm.valid) {
      return this.pressureInfoForm.markAllAsTouched();
    }
    if (
      this.pressureInfoForm.dirty ||
      this.pressureInfoForm.get('latitude')?.value !==
        this.contextObject?.latitude ||
      this.pressureInfoForm.get('longitude')?.value !==
        this.contextObject?.longitude
    ) {
      let body: PressureItemType = {
        id: this.contextObject?.id || 0,
        name: this.pressureInfoForm.value.name,
        deposit_id: this.pressureInfoForm.value.deposit.id,
        device_id: this.pressureInfoForm.value.device.id,
        device_field: this.pressureInfoForm.value.device_type,
        min_warning: this.limitsFormActive
          ? this.pressureInfoForm.value.limitsGroup?.min_warning
          : null,
        min_error: this.limitsFormActive
          ? this.pressureInfoForm.value.limitsGroup?.min_error
          : null,
        max_warning: this.limitsFormActive
          ? this.pressureInfoForm.value.limitsGroup?.max_warning
          : null,
        max_error: this.limitsFormActive
          ? this.pressureInfoForm.value.limitsGroup?.max_error
          : null,
        latitude: this.pressureInfoForm.value.latitude || null,
        longitude: this.pressureInfoForm.value.longitude || null,
      };
      // let ID = this.contextObject?.id || 0
      this.pressureService.updateParams(body).subscribe((dns: any) => {
        this.contextObject = {
          ...dns,
        };
        this.parent = this.depositList.find((item: TypeTree) => {
          return item.id === this.contextObject?.deposit_id;
        });
        this.notificationsService
          .open('Объект обновлен', {
            label: '',
            status: TuiNotification.Success,
            hasIcon: true,
            autoClose: true,
            hasCloseButton: true,
          })
          .subscribe();
        this.handleChange.emit();
        this.editObjectMode = false;
      });
    } else {
      this.editObjectMode = false;
    }
  }

  changeInput(): void {
    this.editObjectMode = !this.editObjectMode;
  }

  cancelInput(): void {
    this.pressureInfoForm.reset();
    this.pressureInfoForm.get('name')?.setValue(this.contextObject?.name || '');
    this.pressureInfoForm.get('deposit')?.setValue(this.parent || null);
    this.pressureInfoForm
      .get('latitude')
      ?.setValue(this.contextObject?.latitude || 0);
    this.pressureInfoForm
      .get('longitude')
      ?.setValue(this.contextObject?.longitude || 0);

    if (
      this.pressureInfoForm.get('latitude')?.value !==
        this.contextObject?.latitude ||
      this.pressureInfoForm.get('longitude')?.value !==
        this.contextObject?.longitude
    ) {
      this.visibleMap = true;
    }
    this.editObjectMode = false;
  }

  @tuiPure
  stringify(items: readonly any[]): any {
    const map = new Map(
      items.map(({ id, name }) => [id, name] as [number, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
