<div class="delete-dialog">
  <div class="delete-dialog__header">
    <h2 class="title" *ngIf="!title; else withTitle">
      Вы точно хотите удалить {{ data?.type }} {{ data?.name }} ?
    </h2>
    <ng-template #withTitle>
      <h2 class="title">
        {{ title }}
      </h2>
    </ng-template>
    <div class="cross" (click)="cancel()">
      <img src="/assets/cross.png" alt="(X)" />
    </div>
  </div>
  <div class="delete-dialog__buttons">
    <button class="button _access" (click)="submitDelete()">Да</button>
    <button class="button _cancel" (click)="cancel()">Нет</button>
  </div>
</div>
