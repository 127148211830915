import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { latLng, LayerGroup, marker, tileLayer } from 'leaflet';
import {
  TRANSLATE_TYPES_ICON_IMAGE,
  TYPE_OBJECTS,
} from '../../../../const/app-consts';
import { TypeObjectModel } from '../../../../../models/type-object.model';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
//import * as L from 'leaflet';
import { TypeTree } from 'src/models/tree.model';
import { LeafletMapService } from '../../../../services/translate-map-controls.service';

const L = LeafletMapService.get();

@Component({
  selector: 'app-map-object',
  templateUrl: './map-object.component.html',
  styleUrls: ['./map-object.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapObjectComponent implements OnInit, OnChanges {
  @Input() onClickOnMap: any;

  @Input() currentIdObject: number = 105;

  @Input() latitude: number = 55;

  @Input() longitude: number = 55;

  @Input() coordsInputs: number[] = [];

  @Input() status: string | null = null;

  @Input() changeMode: boolean = true;

  @Input() arrayCoords: any = [];

  @Input()
  public nameObject: string = '';

  @Input()
  public miningType: null | string = null;

  public reInitMap: boolean = true;

  public reloadMap: boolean = false;

  public listOfTypesObject: TypeObjectModel[] = TYPE_OBJECTS;

  public marker: LayerGroup = L.layerGroup();

  public mapObject!: any;

  public options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        attribution: '',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      this.marker,
    ],
    zoom: 7,
    center: latLng(55.560981, 52.638667),
    minZoom: 2,
    maxBounds: LeafletMapService.getBounds(),
  };

  private translateType: any = TRANSLATE_TYPES_ICON_IMAGE;

  public inputsCoords: number[] = [];

  public idObject: any = null;

  public key: number = 0;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService
  ) {}

  ngOnInit(): void {
    this.idObject = this.getCurrentTypeObject();
    if (this.arrayCoords.length > 0) {
      this.generateMap();
    } else {
      this.changeCoord();
    }
  }

  // Вынесенная из ngOnChanges проверка условий
  objectValidationForChange(changes: any) {
    const latitudeChangedWithoutChangeMode =
      !this.changeMode &&
      this.idObject !== undefined &&
      changes?.latitude?.currentValue < 90 &&
      changes?.latitude?.currentValue > -90;

    const longitudeChangedWithoutChangeMode =
      !this.changeMode &&
      this.idObject !== undefined &&
      changes?.longitude?.currentValue < 180 &&
      changes?.longitude?.currentValue > -180;

    return (
      latitudeChangedWithoutChangeMode || longitudeChangedWithoutChangeMode
    );
  }

  // Вынесенная из ngOnChanges проверка условий
  objectValidationForCreation(changes: any) {
    const changeModeWithObject = this.changeMode && this.idObject !== undefined;

    const isNotFirstChange =
      !changes?.longitude?.firstChange || !changes?.latitude?.firstChange;

    const isLatitudeChanged =
      typeof changes?.latitude?.currentValue === 'number' &&
      changes?.latitude?.currentValue !== changes?.latitude?.previousValue;

    const isLongitudeChanged =
      typeof changes?.longitude?.currentValue === 'number' &&
      changes?.longitude?.currentValue !== changes?.longitude?.previousValue;

    const conditionSummary =
      (changeModeWithObject && isNotFirstChange && isLatitudeChanged) ||
      isLongitudeChanged;

    return conditionSummary;
  }

  // Вынесенное из ngOnChanges создание объекта
  markerObjectCreation(object: any) {
    if (this.currentIdObject === 107) {
      return this.markerPressureSensorCreation(object);
    }
    return {
      icon: L.divIcon({
        html: `<div class="object-map-balloon object-status-${this.getColorClassForBalloon(
          object
        )} style="font-size: ${
          object.name.length > 6
            ? (5 / object.name.length) * 20 >= 10
              ? 10
              : (5 / object.name.length) * 20
            : 10
        }px">
                         <div class="object-content"><span>${
                           object.name
                         }</span><img src="/assets/icons-white/${
          this.currentIdObject === 105
            ? this.getIconNameForBorehole(object)
            : this.translateType[this.currentIdObject]
        }.svg" alt=""></div></div>`,
      }),
    };
  }

  // Метод создания маркера для датчика давления
  markerPressureSensorCreation(object: any) {
    return {
      icon: L.divIcon({
        html: `<div class="object-map-balloon object-status-pressure_sensor" style="font-size: ${
          object.name.length > 6
            ? (5 / object.name.length) * 20 >= 10
              ? 10
              : (5 / object.name.length) * 20
            : 10
        }px">
                         <div class="object-content"><span>${
                           object.name
                         }</span><img src="/assets/icons-white/pressure_sensor.svg" alt=""></div></div>`,
      }),
    };
  }

  ngOnChanges(changes: any) {
    if (changes?.arrayCoords?.currentValue) {
      return this.generateMap();
    }
    if (this.objectValidationForChange(changes)) {
      this.latitude = +changes?.latitude?.currentValue;
      this.changeCoord();
    }
    if (changes?.changeMode?.currentValue) {
      this.changeMode = changes?.changeMode?.currentValue;
      this.changeCoord();
    }
    if (this.objectValidationForCreation(changes)) {
      this.marker.clearLayers();
      const object: any = {
        status: this.status || 'default',
        name: this.nameObject,
        attributes: { type_mining: this.miningType },
      };
      this.mapObject?.panTo(new L.LatLng(this.latitude, this.longitude));
      this.marker.addLayer(
        marker(
          [this.latitude, this.longitude],
          this.markerObjectCreation(object) as any
        )
      );
    }
  }

  public generateMap() {
    this.reInitMap = true;
    let averageLatLng: Array<number> = [0, 0];
    this.marker.clearLayers();
    this.arrayCoords.forEach((child: TypeTree) => {
      if (this.arrayCoords.length > 1) {
        averageLatLng[0] += child.latitude;
        averageLatLng[1] += child.longitude;
      } else {
        averageLatLng[0] = child.latitude;
        averageLatLng[1] = child.longitude;
      }
      this.marker.addLayer(
        marker(
          [child.latitude, child.longitude],
          this.markerObjectCreation(child)
        )
          .on('mouseover', (event: any) => {
            const layer = event.target;
            layer.setZIndexOffset(1000);
          })
          .on('mouseout', (event: any) => {
            const layer = event.target;
            layer.setZIndexOffset(0);
          })
      );
    });
    if (this.arrayCoords.length > 1) {
      averageLatLng[0] = averageLatLng[0] / this.arrayCoords.length;
      averageLatLng[1] = averageLatLng[1] / this.arrayCoords.length;
    }
    this.options.center = latLng(averageLatLng[0], averageLatLng[1]);
    if (this.mapObject) {
      this.mapObject.setView(latLng(averageLatLng[0], averageLatLng[1]));
    }
  }

  getIconNameForBorehole(borehole: TypeTree) {
    return borehole.attributes?.type_mining &&
      borehole.attributes?.type_mining !== 'REPAIR' &&
      borehole.attributes?.type_mining !== 'NONE'
      ? `borehole_${borehole.attributes?.type_mining}`
      : 'borehole';
  }

  getColorClassForBalloon(object: TypeTree) {
    if (object?.status) {
      return object?.attributes?.type_mining === 'PPD' ? 'ppd' : object.status;
    } else return 'default';
  }

  changeCoord() {
    if (
      +this.latitude < 90 &&
      +this.latitude > -90 &&
      +this.longitude < 180 &&
      +this.longitude > -180 &&
      this.idObject !== undefined
    ) {
      this.reInitMap = true;
      this.options.center = latLng(this.latitude, this.longitude);

      this.marker.clearLayers();
      const object: any = {
        status: this.status || 'default',
        name: this.nameObject,
        attributes: { type_mining: this.miningType },
      };
      this.marker.addLayer(
        marker(
          [this.latitude, this.longitude],
          this.markerObjectCreation(object)
        )
      );
      if (this.mapObject) {
        this.mapObject.setView(latLng(this.latitude, this.longitude));
      }
    } else {
      this.reInitMap = false;
      if (this.changeMode) {
        this.marker.clearLayers();
        this.slowlyUpdate();
      }
    }
  }

  slowlyUpdate() {
    setTimeout(() => {
      this.reInitMap = true;
    }, 300);
  }

  getCurrentTypeObject() {
    return this.listOfTypesObject.find((type: any) => {
      return type.value === this.currentIdObject;
    });
  }

  handlerClickOnMap($event: any, changingMode: boolean) {
    if (changingMode) {
      if (!this.idObject) {
        this.marker.clearLayers();
        this.notificationsService
          .open('Выберите тип объекта', {
            status: TuiNotification.Warning,
          })
          .subscribe();
        return;
      } else {
        if (
          $event.latlng.lat < 90 &&
          $event.latlng.lat > -90 &&
          $event.latlng.lng < 180 &&
          $event.latlng.lng > -180
        ) {
          this.latitude = $event.latlng.lat;
          this.longitude = $event.latlng.lng;
          const newLatLng = latLng(this.latitude, this.longitude);
          if (this.mapObject) {
            this.mapObject.setView(newLatLng);
          }

          this.onClickOnMap.emit({
            lat: $event.latlng.lat,
            lng: $event.latlng.lng,
          });
        } else {
          this.notificationsService
            .open('Эта зона карты недоступна для взаимодействия с ней', {
              status: TuiNotification.Error,
            })
            .subscribe();
        }
      }
    } else return;
  }

  onMapReady(map: any) {
    LeafletMapService.translateZoomButtons(map);
    this.mapObject = map;
  }
}
