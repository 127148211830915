<div class="scheme">
  <div class="scheme__grid">
    <ng-container *ngFor="let row of data; let i = index">
      <div id="grid-list-row-{{ i }}">
        <div
          *ngFor="let item of row; let j = index"
          (click)="changeFieldByCoordinate(i, j)"
          class="scheme__grid-item"
        >
          <img *ngIf="item.value" src="{{ item.value.icon }}" alt="h" />
        </div>
      </div>
    </ng-container>
  </div>
</div>
