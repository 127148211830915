import { Injectable } from "@angular/core";
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { FormAttrBase } from "./form-attr-base";

@Injectable()
export class FormAttrControlService {
  toFormGroup(formAttrs: FormAttrBase<string>[]) {
    const group: any = formAttrs.reduce((prevValue: any[], formAttr: any) => {
      let value;
      if (formAttr.controlType.startsWith("checkbox")) {
        value = formAttr.value !== undefined ? formAttr.value : false;
      } else {
        value = formAttr.value !== undefined ? formAttr.value : null;
      }
      prevValue[formAttr.key] = new FormControl(
        { value: value, disabled: formAttr.disabled },
        this.getValidatorsRules(formAttr.validators || [])
      );
      return prevValue;
    }, []);
    return new FormGroup(group);
  }

  getValidatorsRules(validators: Validators[]): ValidatorFn[] {
    if (!validators || !validators?.length) {
      return [];
    }
    const namesAndValuesValidators: [
      string,
      number | boolean | string | RegExp
    ][] = Object.entries(validators[0]);

    const validatorsArr: ValidatorFn[] = namesAndValuesValidators.reduce(
      (
        prevState: any[],
        currentState: [string, number | boolean | string | RegExp]
      ) => {
        const validator: ValidatorFn | null = this.getValidator(
          currentState[0],
          currentState[1]
        );
        if (validator) {
          prevState.push(validator);
        }
        return prevState;
      },
      []
    );
    return validatorsArr;
  }

  getValidator(name: string, value: any): ValidatorFn | null {
    switch (name) {
      case "min":
        return Validators.min(value);
      case "max":
        return Validators.max(value);
      case "minLength":
        return Validators.minLength(value);
      case "maxLength":
        return Validators.maxLength(value);
      case "required":
        return Validators.required;
      case "email":
        return Validators.email;
      case "pattern":
        return Validators.pattern(value);
      default:
        return null;
    }
  }
}
