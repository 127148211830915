import { Component, Input } from "@angular/core";
import { TuiHorizontalDirection } from "@taiga-ui/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-form-input",
  templateUrl: "./form-input.component.html",
  styleUrls: ["./form-input.component.less"],
})
export class FormInputComponent {
  @Input()
  form!: FormGroup;

  @Input()
  formControlKey!: string;
  // тут мог быть еще number и null типы, но только string потому что в form-attr-control.service.ts group[formAttr.key]

  @Input()
  icon!: string | null;

  @Input()
  label!: string;

  @Input()
  visualRequired!: boolean;

  @Input()
  iconAlign!: TuiHorizontalDirection;

  @Input()
  nativeId!: string;

  @Input()
  tuiTextfieldMaxLength!: number | null;

  @Input()
  tuiTextfieldExampleText!: string;
}
