import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-common-buttons',
  templateUrl: './common-buttons.component.html',
  styleUrls: ['./common-buttons.component.less'],
})
export class CommonButtonsComponent {
  @Input()
  isEdit: boolean = false;

  @Output()
  handelChange: EventEmitter<null> = new EventEmitter();

  @Output()
  handelOpen: EventEmitter<null> = new EventEmitter();

  @Output()
  handelCancel: EventEmitter<null> = new EventEmitter();

  changeInput() {
    this.handelChange.emit();
  }

  openDialogForDelete() {
    this.handelOpen.emit();
  }

  cancel() {
    this.handelCancel.emit();
  }
}
