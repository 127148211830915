import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, of, Subject, switchMap } from "rxjs";
import { startWith } from "rxjs/operators";

@Component({
  selector: "app-form-multiselect-with-search-for-huge-arrays",
  templateUrl: "./form-multiselect-with-search-for-huge-arrays.component.html",
  styleUrls: ["./form-multiselect-with-search-for-huge-arrays.component.less"],
})
export class FormMultiselectWithSearchForHugeArraysComponent
  implements OnInit, OnChanges
{
  // Название формы formGroup
  @Input()
  form!: FormGroup;

  // Название поля в форме formControlName
  @Input()
  formControlNameSelect!: string;

  // Название поля
  @Input()
  label!: string;

  // Сообщение для отображения внутри поля
  @Input()
  placeholderMessage!: string;

  // Является ли поле обязательным для заполнения
  @Input()
  visualRequired!: boolean;

  // Указать, если нужно добавить серую стилизацию
  @Input()
  greyStyle!: boolean;

  // Массив опций для выпадающего списка - объекты со свойствами id и name
  @Input()
  items: readonly any[] = [];

  // Переменная для отображения скелетона вместо поля ввода
  public skeletonVisible: boolean = true;

  readonly itemsSearch$: Subject<string | null> = new Subject();

  readonly availableItems$: Observable<readonly any[] | null> =
    this.itemsSearch$.pipe(
      switchMap((search: string | null) =>
        this.filterItems(search).pipe(startWith<readonly any[] | null>(null))
      )
    );

  // Количество опций в выпадающем списке
  public quantityOfResultsInField: number = 25;

  // Фильтрация, которая останавливает цикл при длине отфильтрованного массива равной количеству опций в выпадающем списке
  private filterItems(searchQuery: string | null): Observable<readonly any[]> {
    let filteredResult = [];
    if (this.items.length) {
      for (let i = 0; i < this.items.length; i++) {
        if (
          this.items[i].name
            .toLowerCase()
            .includes((searchQuery || "").toLowerCase())
        ) {
          filteredResult.push(this.items[i]);
        }
        if (filteredResult.length === this.quantityOfResultsInField) {
          break;
        }
      }
    }

    if (this.selectedItem && !searchQuery) {
      filteredResult = [this.selectedItem, ...filteredResult];
    }

    return of(filteredResult);
  }

  readonly stringifyComboBox = (item: { id: number; name: string }): string =>
    item.name || "";

  onItemSearchChange(s: string | null) {
    this.itemsSearch$.next(s ?? "");
  }

  extractValueFromEvent(event: Event): string | null {
    return (event.target as HTMLInputElement)?.value || null;
  }

  private selectedItem: any = null;

  ngOnInit() {
    this.fieldActivation();
  }

  fieldActivation() {
    this.skeletonVisible = true;
    if (this.items.length) {
      if (this.form.value[this.formControlNameSelect]) {
        this.selectedItem = this.form.value[this.formControlNameSelect];
      }

      setTimeout(() => {
        this.skeletonVisible = false;
        this.itemsSearch$.next("");
      }, 10);
    }
  }

  ngOnChanges() {
    this.fieldActivation();
  }
}
