import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ObjectService } from '../../../../services/object.service';
import { TypeTree } from '../../../../../models/tree.model';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { PressureService } from '../../../../services/pressure.service';
import { PipelineService } from '../../../../services/pipeline.service';
import { Subject, takeUntil } from 'rxjs';
import { TuiAlertService } from '@taiga-ui/core';
import { TuiNotification } from '@taiga-ui/core/enums';

//проверка на пробелы
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

type DataForSelectField = {
  id: number | string;
  name: string;
};

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

export function minValueValidator(context: { min: number }): string {
  return `Значение должно быть не меньше ${context.min}`;
}

export function maxValueValidator(context: { max: number }): string {
  return `Значение должно быть не больше ${context.max}`;
}

@Component({
  selector: 'app-bg-add',
  templateUrl: './bg-add.component.html',
  styleUrls: ['./bg-add.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class BgAddComponent implements OnInit, OnDestroy {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  // возвращает созданное месторождение
  @Output() requestCreatedData: EventEmitter<null> = new EventEmitter();

  //для формы -> список скважин
  public depositList: any = [];

  //форма для имени месторождения
  public formBG: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    deposit: new FormControl('', [Validators.required]),
    oil_parent_id: new FormControl({ value: null, disabled: true }),
    curr_pressure: new FormControl(null),
    next_pressure: new FormControl(null),
    oil_pipe: new FormControl(null, []),
    water_pipe: new FormControl(null, []),
    latitude: new FormControl(null, [
      Validators.required,
      Validators.min(-90),
      Validators.max(90),
    ]),
    longitude: new FormControl(null, [
      Validators.required,
      Validators.min(-180),
      Validators.max(180),
    ]),
  });

  getNameSelectedValue: any = () => {
    return this.formBG.get('oil_parent_id')?.value?.name || '';
  };

  //клик по карте(по нему в onInit записываем координаты клика по карте)
  public onClickOnMap = new EventEmitter();

  public pressureList: DataForSelectField[] = [];

  public oilPipelineList: DataForSelectField[] = [];

  public waterPipelineList: DataForSelectField[] = [];

  public latitude: number = 55;

  public longitude: number = 55;

  public filterListOfOil: any = {
    gzuList: [],
    dnsList: [],
    bgList: [],
  };

  public listOfOil: any = {
    gzuList: [],
    dnsList: [],
    bgList: [],
  };

  public disabledFormControl: boolean = false;

  public destroyer: Subject<null> = new Subject();

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    public objectService: ObjectService,
    private pressureService: PressureService,
    private pipelineService: PipelineService
  ) {}

  async ngOnInit() {
    const deposits = await this.objectService.deposits;
    this.listOfOil = {
      gzuList: await this.objectService.gzu,
      dnsList: await this.objectService.dns,
      bgList: await this.objectService.bg,
    };
    this.depositList = deposits.map((item: TypeTree) => {
      return { id: item.id, title: item.name };
    });
    this.onClickOnMap
      .pipe(takeUntil(this.destroyer))
      .subscribe((coordinates) => {
        this.formBG.get('latitude')?.setValue(coordinates.lat);
        this.formBG.get('longitude')?.setValue(coordinates.lng);
      });
    this.formBG
      .get('latitude')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (this.formBG.get('latitude')?.valid) {
          this.latitude = item;
          return;
        }
        return;
      });
    this.formBG
      .get('longitude')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (this.formBG.get('longitude')?.valid) {
          this.longitude = item;
          return;
        }
        return;
      });
    this.formBG
      .get('deposit')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item) => {
        if (!item) {
          this.formBG.get('oil_parent_id')?.disable();
        } else {
          this.formBG.get('oil_parent_id')?.enable();
        }
        this.formBG.get('oil_parent_id')?.setValue(null);
        this.filterListOfOil = {
          gzuList: this.listOfOil.gzuList.filter(
            (bush: any) => item?.id === bush.parent_id
          ),
          dnsList: this.listOfOil.dnsList.filter(
            (bush: any) => item?.id === bush.parent_id
          ),
          bgList: this.listOfOil.bgList.filter(
            (bush: any) => item?.id === bush.parent_id
          ),
        };
        if (
          !this.filterListOfOil.gzuList.length &&
          !this.filterListOfOil.dnsList.length &&
          !this.filterListOfOil.bgList.length
        ) {
          this.formBG.get('oil_parent_id')?.disable();
          this.disabledFormControl = true;
        } else {
          this.formBG.get('oil_parent_id')?.enable();
          this.disabledFormControl = false;
        }
      });

    await this.getValuesForPressureSensorField();

    // Запрашиваем данные для поля выбора нефтепровода
    await this.getValuesForOilPipelineField();

    // Запрашиваем данные для поля выбора водопровода
    await this.getValuesForWaterPipelineField();
  }

  private async getValuesForOilPipelineField() {
    const oilPipelines = await this.pipelineService.oilPipelinesList;
    this.oilPipelineList = this.prepareArrayForSelectField(oilPipelines);
  }

  private async getValuesForWaterPipelineField() {
    const waterPipelines = await this.pipelineService.waterPipelinesList;
    this.waterPipelineList = this.prepareArrayForSelectField(waterPipelines);
  }

  private async getValuesForPressureSensorField() {
    const pressure = await this.pressureService.pressure;
    this.pressureList = this.prepareArrayForSelectField(pressure);
  }

  // проверка на валидность поля -> отправка запроса при успешной валидации
  submitForm() {
    if (!this.formBG.valid) {
      return this.formBG.markAllAsTouched();
    }
    // Если выбран нефтепровод и/или водопровод и не выбран объект нефтесбора, то требуем указать объект нефтесбора
    if (
      !this.formBG.get('oil_parent_id')?.value &&
      (this.formBG.get('oil_pipe')?.value?.id ||
        this.formBG.get('water_pipe')?.value?.id)
    ) {
      this.notificationsService
        .open(
          'Для выбора нефтепровода и/или водопровода необходимо указать объект нефтесбора',
          {
            label: '',
            status: TuiNotification.Warning,
            hasIcon: true,
            autoClose: true,
            hasCloseButton: true,
          }
        )
        .subscribe();
      return;
    }
    this.objectService
      .objectCreate({
        type: 'bg',
        name: this.formBG.get('name')?.value,
        parent_id: this.formBG.get('deposit')?.value.id,
        oil_parent_id: this.formBG.get('oil_parent_id')?.value?.id || null,
        curr_pressure_id: this.formBG.get('curr_pressure')?.value?.id || null,
        next_pressure_id: this.formBG.get('next_pressure')?.value?.id || null,
        oil_pipe_id: this.formBG.get('oil_pipe')?.value?.id || null,
        water_pipe_id: this.formBG.get('water_pipe')?.value?.id || null,
        latitude:
          typeof this.formBG.get('latitude')?.value === 'number'
            ? this.formBG.get('latitude')?.value
            : null,
        longitude:
          typeof this.formBG.get('longitude')?.value === 'number'
            ? this.formBG.get('longitude')?.value
            : null,
      })
      .subscribe(() => {
        this.requestCreatedData.emit();
        this.closeDialog();
        this.notificationsService
          .open('Объект создан', {
            label: '',
            status: TuiNotification.Success,
            hasIcon: true,
            autoClose: true,
            hasCloseButton: true,
          })
          .subscribe();
      });
  }

  prepareArrayForSelectField(array: any): DataForSelectField[] {
    return array.map((v: DataForSelectField) => ({
      id: v.id,
      name: v.name,
    }));
  }

  // очистка полей и закрытие
  closeDialog() {
    this.formBG.reset({ name: '' });
    this.closeModal();
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
