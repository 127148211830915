<div *ngIf="contextObject">
  <tui-loader
    [showLoader]="isLoading"
    [inheritColor]="true"
    [overlay]="true"
    [size]="'xl'"
  >
    <app-primary-section>
      <form [formGroup]="pressureInfoForm" class="form" *ngIf="editObjectMode">
        <div class="inline-table">
          <div class="header">
            <span>Датчик давления</span>
            <app-common-buttons-dns
              [isEdit]="editObjectMode"
              (handelCancel)="cancelInput()"
              (handelChange)="changeInput()"
              (handelSave)="save()"
            >
            </app-common-buttons-dns>
          </div>
          <div class="form-table">
            <div class="row">
              <div class="form-label">Название *</div>
              <div class="value">
                <tui-input
                  style="width: 90%"
                  formControlName="name"
                  tuiTextfieldSize="m"
                >
                  Введите название
                  <input maxlength="40" tuiTextfield type="text" />
                </tui-input>
                <tui-field-error formControlName="name"></tui-field-error>
              </div>
            </div>

            <div class="row">
              <div class="form-label">Месторождение *</div>
              <div class="value">
                <tui-select
                  style="width: 90%"
                  formControlName="deposit"
                  [valueContent]="typeValueContent"
                  tuiTextfieldSize="m"
                >
                  <tui-data-list-wrapper
                    *tuiDataList
                    [items]="depositList"
                    [itemContent]="valueContent"
                  >
                  </tui-data-list-wrapper>
                </tui-select>
                <ng-template #typeValueContent let-data>
                  {{ data.name }}
                </ng-template>
                <ng-template #valueContent let-data>
                  {{ data.name }}
                </ng-template>
                <tui-field-error formControlName="deposit"></tui-field-error>
              </div>
            </div>

            <div class="row">
              <div class="form-label">Датчик давления *</div>
              <div class="value">
                <div class="device-row">
                  <app-form-select-with-search-for-huge-arrays
                    [form]="pressureInfoForm"
                    [formControlNameSelect]="'device'"
                    [label]="'Выберите датчик давления'"
                    [placeholderMessage]="'Введите название датчика...'"
                    [visualRequired]="true"
                    [items]="deviceList"
                    style="width: 100%"
                  >
                  </app-form-select-with-search-for-huge-arrays>

                  <tui-error
                    *ngIf="
                    pressureInfoForm.controls['device'].touched &&
                    !pressureInfoForm.controls['device'].value
                  "
                    class="debit_device_error"
                    error="Поле обязательно для заполнения"
                  ></tui-error>

                  <div class="input with_padding">
                    <tui-select
                      [class.tui-skeleton]="skeletonVisible"
                      [class.tui-skeleton_rounded]="skeletonVisible"
                      *tuiLet="pressureSensorType as items"
                      [tuiTextfieldLabelOutside]="false"
                      [valueContent]="items ? stringify(items) : loading"
                      formControlName="device_type"
                      tuiTextfieldSize="m"
                      class="b-form tui-space_vertical-1"
                      style="width: 100%"
                    >
                      Параметр устройства
                      <ng-template tuiDataList>
                        <tui-data-list *ngIf="items; else loading">
                          <button
                            *ngFor="let item of items"
                            tuiOption
                            [value]="item.id"
                          >
                            {{ item.name }}
                          </button>
                        </tui-data-list>
                      </ng-template>
                      <ng-template #loading>
                        <tui-loader
                          class="tui-space_vertical-3 loader"
                        ></tui-loader>
                      </ng-template>
                    </tui-select>
                    <tui-error
                      *ngIf="
                      pressureInfoForm.controls['device_type'].touched &&
                      pressureInfoForm.controls['device'].touched &&
                      !pressureInfoForm.controls['device_type'].value
                    "
                      class="debit_device_error"
                      error="Поле обязательно для заполнения"
                    ></tui-error>
                  </div>
                </div>

                <!--              <tui-select-->
                <!--                style="width: 90%"-->
                <!--                formControlName="device"-->
                <!--                [valueContent]="typeValueContent"-->
                <!--                tuiTextfieldSize="m"-->
                <!--              >-->
                <!--                <tui-data-list-wrapper-->
                <!--                  *tuiDataList-->
                <!--                  [items]="deviceList"-->
                <!--                  [itemContent]="valueContent"-->
                <!--                >-->
                <!--                </tui-data-list-wrapper>-->
                <!--              </tui-select>-->
                <!--              <ng-template #typeValueContent let-data>-->
                <!--                {{ data.title }}-->
                <!--              </ng-template>-->
                <!--              <ng-template #valueContent let-data>-->
                <!--                {{ data.title }}-->
                <!--              </ng-template>-->
                <!--              <tui-field-error formControlName="device"></tui-field-error>-->
              </div>
            </div>

            <div class="header">
              <span>Пороги</span>
            </div>

            <div class="row">
              <div class="form-label">Указать пороги</div>
              <div class="value">
                <tui-toggle formControlName="limits" size="l"></tui-toggle>
              </div>
            </div>

            <div formGroupName="limitsGroup">
              <div class="row">
                <div class="form-label">
                  Минимальный порог предупреждения
                  {{ limitsFormActive ? "*" : "" }}
                </div>
                <div class="value">
                  <tui-input-number
                    style="width: 90%"
                    [precision]="14"
                    tuiTextfieldSize="m"
                    formControlName="min_warning"
                  >
                    Введите минимальный порог предупреждения
                  </tui-input-number>
                  <tui-field-error
                    formControlName="min_warning"
                  ></tui-field-error>
                </div>
              </div>

              <div class="row">
                <div class="form-label">
                  Минимальный порог аварии {{ limitsFormActive ? "*" : "" }}
                </div>
                <div class="value">
                  <tui-input-number
                    style="width: 90%"
                    [precision]="14"
                    tuiTextfieldSize="m"
                    formControlName="min_error"
                  >
                    Введите минимальный порог аварии
                  </tui-input-number>
                  <tui-field-error formControlName="min_error"></tui-field-error>
                </div>
              </div>

              <div class="row">
                <div class="form-label">
                  Максимальный порог предупреждения
                  {{ limitsFormActive ? "*" : "" }}
                </div>
                <div class="value">
                  <tui-input-number
                    style="width: 90%"
                    [precision]="14"
                    tuiTextfieldSize="m"
                    formControlName="max_warning"
                  >
                    Введите максимальный порог предупреждения
                  </tui-input-number>
                  <tui-field-error
                    formControlName="max_warning"
                  ></tui-field-error>
                </div>
              </div>

              <div class="row">
                <div class="form-label">
                  Максимальный порог аварии {{ limitsFormActive ? "*" : "" }}
                </div>
                <div class="value">
                  <tui-input-number
                    style="width: 90%"
                    [precision]="14"
                    tuiTextfieldSize="m"
                    formControlName="max_error"
                  >
                    Введите максимальный порог аварии
                  </tui-input-number>
                  <tui-field-error formControlName="max_error"></tui-field-error>
                </div>
              </div>
            </div>

            <div class="header">
              <span>Координаты</span>
            </div>

            <div class="row">
              <div class="form-label">Широта *</div>
              <div class="value">
                <tui-input-number
                  style="width: 90%"
                  [precision]="14"
                  tuiTextfieldSize="m"
                  formControlName="latitude"
                >
                  Введите широту
                </tui-input-number>
                <tui-field-error formControlName="latitude"></tui-field-error>
              </div>
            </div>
            <div class="row">
              <div class="form-label">Долгота *</div>
              <div class="value">
                <tui-input-number
                  style="width: 90%"
                  [precision]="14"
                  tuiTextfieldSize="m"
                  formControlName="longitude"
                >
                  Введите долготу
                </tui-input-number>
                <tui-field-error formControlName="longitude"></tui-field-error>
              </div>
            </div>
          </div>
          <span class="form-footer">* Обязательные для заполнения поля</span>
        </div>
      </form>
      <div class="inline-table" *ngIf="!editObjectMode">
        <div class="header">
          <span>Датчик давления</span>
          <app-common-buttons-dns
            [isEdit]="editObjectMode"
            (handelCancel)="cancelInput()"
            (handelChange)="changeInput()"
            (handelSave)="save()"
          >
          </app-common-buttons-dns>
        </div>
        <div class="table">
          <div class="row">
            <div class="label">Название</div>
            <div class="value">
              {{ contextObject.name }}
            </div>
          </div>
          <div class="row">
            <div class="label">Месторождение</div>
            <div class="value">
              {{ parent?.name || "Не выбран" }}
            </div>
          </div>
          <div class="row">
            <div class="label">Датчик давления</div>
            <div class="value">
              {{ (deviceType?.name ) || "Не выбран" }}
            </div>
          </div>

          <div class="row">
            <div class="label">Параметры датчика давления</div>
            <div class="value">
              {{ pressureSensorSelectedType?.name || "Не выбран" }}
            </div>
          </div>

          <div class="header">
            <span>Пороги</span>
          </div>

          <div class="row">
            <div class="label">Минимальный порог предупреждения</div>
            <div class="value">
              {{ contextObject.min_warning || "Не выбран" }}
            </div>
          </div>
          <div class="row">
            <div class="label">Минимальный порог аварии</div>
            <div class="value">
              {{ contextObject.min_error || "Не выбран" }}
            </div>
          </div>
          <div class="row">
            <div class="label">Максимальный порог предупреждения</div>
            <div class="value">
              {{ contextObject.max_warning || "Не выбран" }}
            </div>
          </div>
          <div class="row">
            <div class="label">Максимальный порог аварии</div>
            <div class="value">
              {{ contextObject.max_error || "Не выбран" }}
            </div>
          </div>

          <div class="header">
            <span>Координаты</span>
          </div>

          <div class="row">
            <div class="label">Широта</div>
            <div class="value">
              <td>{{ contextObject.latitude || "-" }}</td>
            </div>
          </div>
          <div class="row">
            <div class="label">Долгота</div>
            <div class="value">
              <td>{{ contextObject.longitude || "-" }}</td>
            </div>
          </div>
        </div>
      </div>
    </app-primary-section>
    <app-primary-section>
      <div
        *ngIf="
        (contextObject.latitude < 90 &&
          contextObject.latitude > -90 &&
          contextObject.longitude < 180 &&
          contextObject.longitude > -180) ||
          editObjectMode;
        else unavailableCoords
      "
      >
        <app-object-card-map
          [currentIdObject]="107"
          [onClickOnMap]="onClickOnMap"
          [data]="contextObject"
          [isEdit]="editObjectMode"
          [latitude]="latitude"
          [longitude]="longitude"
          [visibleMap]="visibleMap"
        >
        </app-object-card-map>
      </div>
      <ng-template #unavailableCoords>
        <div class="unavailable-map">
        <span class="unavailable-map__text">
          Точка на карте расположена вне области видимости карты, измените
          координаты в соответствии с допустимыми значениями, а именно: значения
          широты должны быть менее 90, но более -90, значения долготы должны
          быть менее 180, но более -180, чтобы отобразить точку на карте
        </span>
        </div>
      </ng-template>
    </app-primary-section>
  </tui-loader>

</div>
<div *ngIf="!contextObject" class="common-info__no-content">
  Выберите объект
</div>
