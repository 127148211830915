<ng-template #leftToolBar>
  <app-sorting
    [listOfSortingItems]="sortData"
    (handleClick)="changeSettingsTable($event, 'sort')"
  >
  </app-sorting>
</ng-template>
<app-table
  [leftToolBar]="leftToolBar"
  [paginator]="paginator"
  [data]="data"
  (handleClick)="changeSettingsTable($event, 'pagination')"
  [label]="label"
  [rightToolBar]="rightToolBar"
>
</app-table>
<ng-template #rightToolBar>
  <app-filtering
    [listOfFilters]="filtersData"
    (handleClick)="changeSettingsTable($event, 'filter')"
  >
  </app-filtering>
</ng-template>
