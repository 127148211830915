<div cdkDropList (cdkDropListDropped)="drop($event)">
  <ng-container *ngIf="!topLevel">
    <div
      *ngFor="let item of children"
      class="tree__container"
      style="margin-top: 10px"
      cdkDrag
      [cdkDragDisabled]="!isDraggable"
    >
      <div
        class="tree__content"
        [class.tree__content__not_deposits]="item.type !== 'deposit'"
        [class.tui-skeleton]="objectService.treeSkeletonVisible | async"
      >
        <tui-svg
          *ngIf="item.children.length > 0"
          [@expandedObjectArrow]="getExpandedState(item.id)"
          src="tuiIconChevronDown"
          class="tree-arrow-icon"
          (click)="toggleExpanded(item.id)"
        >
        </tui-svg>
        <div
          class="tree_no_children_margin"
          *ngIf="item.children.length === 0"
        ></div>
        <div *ngIf="item?.type" class="tree__icon-container">
          <img [src]="returnCurrentPath(item?.type)" alt="icon" />
        </div>
        <span
          class="tree__text"
          (click)="handleClickByText(item)"
          [class._selected]="
            (objectService.selectedObject | async)?.id === item.id
          "
          [class._disabled]="item?.disabled"
          >{{ item?.name }}
        </span>
        <div
          class="tree__text-status"
          *ngIf="item?.type !== 'deposit'"
          [class._ok]="item?.status === 'ok'"
          [class._stop]="item?.status === 'stop'"
          [class._no_connection]="
            item?.status === null || item?.status === 'no_connection'
          "
        ></div>
        <div *ngIf="item?.status?.boreholes" class="tree__status">
          <span class="ok">{{ item?.status?.boreholes?.ok }}</span>
          <span class="stop">{{ item?.status?.boreholes?.stop }}</span>
          <span class="no_connection">{{
            item?.status?.boreholes?.no_connection
          }}</span>
        </div>
      </div>
      <div
        class="tree__children"
        [@expandedObjectChildren]="getExpandedState(item.id)"
      >
        <app-tree-branch
          [schemeMode]="schemeMode"
          *ngIf="item.children.length > 0"
          [children]="item.children"
        ></app-tree-branch>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="topLevel">
    <app-tree-branch
      [schemeMode]="schemeMode"
      [children]="children"
      [topLevel]="false"
    ></app-tree-branch>
  </ng-container>
</div>
