<div class="borehole-add">
  <app-primary-section>
    <div class="borehole-add__container">
      <h2 class="title">Добавить скважину</h2>
      <div class="cross" (click)="closeModal()">
        <img src="/assets/cross.png" alt="(X)" />
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="formBorehole">
      <div class="form__title">
        <h4>Общая информация</h4>
      </div>
      <div class="form__field">
        <span>Название *</span>
        <div class="input">
          <tui-input [tuiTextfieldMaxLength]="10" formControlName="name">
            <span class="input__placeholder">Укажите название</span>
          </tui-input>
          <tui-field-error formControlName="name"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Месторождение *</span>
        <div class="input">
          <tui-select
            formControlName="deposit"
            [valueContent]="typeValueContent"
          >
            <span *ngIf="!formBorehole.get('deposit')?.value">Не выбрано</span>
            <span *ngIf="formBorehole.get('deposit')?.value"
              >Выберите месторождение</span
            >
            <tui-data-list-wrapper
              *tuiDataList
              [items]="depositList"
              [itemContent]="valueContent"
            >
            </tui-data-list-wrapper>
          </tui-select>
          <ng-template #typeValueContent let-data>
            {{ data.name }}
          </ng-template>
          <ng-template #valueContent let-data>
            {{ data.name }}
          </ng-template>
          <tui-field-error formControlName="deposit"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Куст</span>
        <div class="input">
          <tui-select
            formControlName="bush"
            [valueContent]="typeValueContent"
          >
            <span *ngIf="!disabledBushField; else disabled_bush_field"
              >Выберите куст</span
            >
            <ng-template #disabled_bush_field
              >Нет элементов для выбора</ng-template
            >
            <tui-data-list-wrapper
              *tuiDataList
              [items]="filterBushList"
              [itemContent]="valueContent"
            >
            </tui-data-list-wrapper>
          </tui-select>
          <ng-template #typeValueContent let-data>
            {{ data.title }}
          </ng-template>
          <ng-template #valueContent let-data>
            {{ data.title }}
          </ng-template>
          <tui-field-error formControlName="bush"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Объект нефтесбора</span>
        <div class="input">
          <tui-select
            #select
            [valueContent]="getNameSelectedValue()"
            formControlName="oil_parent_id"
          >
            <span *ngIf="!disabledOilField; else disabled_oil_field"
              >Выберите объект нефтесбора</span
            >
            <ng-template #disabled_oil_field
              >Нет элементов для выбора</ng-template
            >
            <tui-data-list *tuiDataList>
              <tui-opt-group label="ГЗУ">
                <button
                  *ngFor="let element of filterListOfOil.gzuList"
                  tuiOption
                  [value]="element"
                >
                  {{ element.name }}
                </button>
              </tui-opt-group>
              <tui-opt-group label="ДНС">
                <button
                  *ngFor="let element of filterListOfOil.dnsList"
                  tuiOption
                  [value]="element"
                >
                  {{ element.name }}
                </button>
              </tui-opt-group>
              <tui-opt-group label="БГ/УЗА">
                <button
                  *ngFor="let element of filterListOfOil.bgList"
                  tuiOption
                  [value]="element"
                >
                  {{ element.name }}
                </button>
              </tui-opt-group>
            </tui-data-list>
          </tui-select>
        </div>
      </div>

      <div class="form__field">
        <span>Тип добычи *</span>
        <div class="input">
          <tui-select
            formControlName="typeMining"
            [valueContent]="typeValueContent"
          >
            <span *ngIf="!formBorehole.get('typeMining')?.value"
              >Не выбрано</span
            >
            <span *ngIf="formBorehole.get('typeMining')?.value"
              >Выберите тип добычи</span
            >
            <tui-data-list-wrapper
              *tuiDataList
              [items]="typeMining"
              [itemContent]="valueContent"
            >
            </tui-data-list-wrapper>
          </tui-select>
          <ng-template #typeValueContent let-data>
            {{ data.title }}
          </ng-template>
          <ng-template #valueContent let-data>
            {{ data.title }}
          </ng-template>
          <tui-field-error formControlName="typeMining"></tui-field-error>
        </div>
      </div>
      <div class="form__field">
        <span>Режимный дебит</span>
        <div class="input">
          <tui-input-number
            [tuiTextfieldMaxLength]="10"
            formControlName="debit"
          >
            <span class="input__placeholder">Введите дебит</span>
          </tui-input-number>
          <tui-field-error formControlName="debit"></tui-field-error>
        </div>
      </div>
      <div class="form__field">
        <span>Обводнённость</span>
        <div class="input">
          <tui-input-number
            [tuiTextfieldMaxLength]="50"
            formControlName="waterCut"
          >
            <span class="input__placeholder">Введите обводнённость</span>
          </tui-input-number>
          <tui-field-error formControlName="waterCut"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Нефтепровод</span>
        <div class="input">

          <app-form-select-with-search-for-huge-arrays
            [form]="formBorehole"
            [formControlNameSelect]="'oil_pipe'"
            [label]="'Выберите нефтепровод'"
            [placeholderMessage]="'Введите название нефтепровода...'"
            [visualRequired]="false"
            [greyStyle]="true"
            [items]="oilPipelineList"
            style="width: 100%"
          >
          </app-form-select-with-search-for-huge-arrays>

          <tui-field-error formControlName="oil_pipe"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Водопровод</span>
        <div class="input">

          <app-form-select-with-search-for-huge-arrays
            [form]="formBorehole"
            [formControlNameSelect]="'water_pipe'"
            [label]="'Выберите водопровод'"
            [placeholderMessage]="'Введите название водопровода...'"
            [visualRequired]="false"
            [greyStyle]="true"
            [items]="waterPipelineList"
            style="width: 100%"
          >
          </app-form-select-with-search-for-huge-arrays>

          <tui-field-error formControlName="water_pipe"></tui-field-error>
        </div>
      </div>

    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="formSensors">
      <div class="form__title">
        <h4>Датчики</h4>
      </div>
      <div class="form__field">
        <span>Датчик статуса</span>
        <div class="input">

          <app-form-select-with-search-for-huge-arrays
            [form]="formSensors"
            [formControlNameSelect]="'status'"
            [label]="'Выберите датчик статуса'"
            [placeholderMessage]="'Введите название датчика...'"
            [visualRequired]="false"
            [greyStyle]="true"
            [items]="availableStatusDevices"
            style="width: 100%"
          >
          </app-form-select-with-search-for-huge-arrays>

        </div>
      </div>

      <div class="form__field">
        <span>Датчик давления текущего объекта нефтесбора</span>
        <div class="input">

          <app-form-select-with-search-for-huge-arrays
            [form]="formSensors"
            [formControlNameSelect]="'curr_pressure'"
            [label]="'Выберите датчик давления'"
            [placeholderMessage]="'Введите название датчика...'"
            [visualRequired]="false"
            [greyStyle]="true"
            [items]="pressureList"
            style="width: 100%"
          >
          </app-form-select-with-search-for-huge-arrays>

          <tui-field-error formControlName="curr_pressure"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Датчик давления следующего объекта нефтесбора</span>
        <div class="input">

          <app-form-select-with-search-for-huge-arrays
            [form]="formSensors"
            [formControlNameSelect]="'next_pressure'"
            [label]="'Выберите датчик давления'"
            [placeholderMessage]="'Введите название датчика...'"
            [visualRequired]="false"
            [greyStyle]="true"
            [items]="pressureList"
            style="width: 100%"
          >
          </app-form-select-with-search-for-huge-arrays>

          <tui-field-error formControlName="next_pressure"></tui-field-error>
        </div>
      </div>

      <div class="form__field _multi">
        <span>Датчик частоты</span>
        <div class="input">

          <app-form-select-with-search-for-huge-arrays
            [form]="formSensors"
            [formControlNameSelect]="'freq_device_id'"
            [label]="'Выберите датчик статуса'"
            [placeholderMessage]="'Введите название датчика...'"
            [visualRequired]="false"
            [greyStyle]="true"
            [items]="availableStatusDevices"
            style="width: 100%"
          >
          </app-form-select-with-search-for-huge-arrays>

        </div>
        <div class="input _left-space">
          <tui-select
            *tuiLet="availableFreqFields as items"
            class="b-form"
            [tuiTextfieldLabelOutside]="false"
            [valueContent]="items ? stringify(items) : loading"
            formControlName="freq_device_field"
            tuiTextfieldSize="m"
          >
            Параметр устройства
            {{ this.formSensors.get("freq_device_id")?.value ? "*" : "" }}
            <ng-template tuiDataList>
              <tui-data-list *ngIf="items; else loading">
                <button *ngFor="let item of items" tuiOption [value]="item.id">
                  {{ item.name }}
                </button>
              </tui-data-list>
            </ng-template>
            <ng-template #loading>
              <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
            </ng-template>
          </tui-select>
          <span
            class="error"
            *ngIf="
              !checkStatusFreg() &&
              this.formSensors.get('freq_device_field')?.touched
            "
            >Поле обязательно для заполнения</span
          >
        </div>
      </div>
      <div class="form__field">
        <span>Датчик дебита</span>
        <div class="debit-devices">
          <div
            formArrayName="debits_params_devices"
            *ngFor="
              let section of memoizedGetSections(formSensors);
              let i = index
            "
            class="device-row-container"
          >
            <div [ngClass]="{'device-row': i === 0 && memoizedGetSections(formSensors).length < 2,
                      'device-row__first-control': i === 0 && memoizedGetSections(formSensors).length > 1,
                      'device-row__second-control': i === 1 && memoizedGetSections(formSensors).length > 1}"
                 [formGroupName]="i">
                <button
                  *ngIf="i === 0 && memoizedGetSections(formSensors).length < 2"
                  class="add-button"
                  tuiIconButton
                  type="button"
                  icon="tuiIconPlus"
                  appearance="iconHovered"
                  size="s"
                  (click)="add()"
                ></button>
              <div [class]="
                            i > 0 ? 'debit_device_multi' : 'debit_device'
                          ">
                  <tui-combo-box
                    [class.tui-skeleton]="skeletonVisible"
                    [class.tui-skeleton_rounded]="skeletonVisible"
                    *tuiLet="availableDebitDevices$ | async as items"
                    tuiTextfieldSize="m"
                    class="tui-space_vertical-5"
                    formControlName="debit_device_id"
                    [stringify]="stringifyComboBox"
                    [valueContent]="
                      skeletonVisible ? loading : statusSelectContent
                    "
                  >
                    Выберите датчик дебита
                    <input
                      tuiTextfield
                      placeholder="Введите название датчика..."
                      (input)="
                        onDebitSearchChange(extractValueFromEvent($event))
                      "
                    />

                    <tui-data-list-wrapper
                      *tuiDataList
                      [items]="items"
                      [itemContent]="statusSelectContent"
                    ></tui-data-list-wrapper>
                  </tui-combo-box>

              </div>
              <button
                *ngIf="i === 0 && memoizedGetSections(formSensors).length > 1"
                class="add-button_toggle"
                tuiIconButton
                type="button"
                [icon]="iconBetweenDebits"
                appearance="iconHovered"
                size="s"
                [tuiHint]="'Кликнув, Вы смените знак арифметического действия ' +
                             'на противоположный, что означает сложение или вычетание предыдущего параметра датчика с ' +
                              'последующим'"
                (click)="changeIconBetweenDebits()"
              ></button>
              <div *ngIf="i === 1 && memoizedGetSections(formSensors).length > 1" style="width: 32px"></div>
              <div class="debit_parameters">
                <tui-select
                  [class.tui-skeleton]="skeletonVisible"
                  [class.tui-skeleton_rounded]="skeletonVisible"
                  *tuiLet="availableDebitFields as items"
                  class="b-form"
                  [tuiTextfieldLabelOutside]="false"
                  [valueContent]="items ? stringify(items) : loading"
                  formControlName="debit_device_field"
                  tuiTextfieldSize="m"
                >
                  Параметр устройства
                  <ng-template tuiDataList>
                    <tui-data-list *ngIf="items; else loading">
                      <button
                        *ngFor="let item of items"
                        tuiOption
                        [value]="item.id"
                      >
                        {{ item.name }}
                      </button>
                    </tui-data-list>
                  </ng-template>
                  <ng-template #loading>
                    <tui-loader
                      class="tui-space_vertical-3 loader"
                    ></tui-loader>
                  </ng-template>
                </tui-select>

              </div>
               <button
                  *ngIf="memoizedGetSections(formSensors).length > 1"
                  class="delete-button"
                  tuiIconButton
                  type="button"
                  icon="tuiIconCloseLarge"
                  appearance="iconHovered"
                  size="s"
                  (click)="remove(i)"
                ></button>
            </div>

          </div>
          <tui-error
            *ngIf="debitSensorErrorMessage"
            class="debit_device_error"
            error="{{ debitSensorErrorMessage }}"
          ></tui-error>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="formBorehole">
      <div class="form__title">
        <h4>Координаты</h4>
      </div>
      <div class="form__field">
        <span>Широта *</span>
        <div class="input">
          <tui-input-number
            [precision]="14"
            [tuiTextfieldMaxLength]="50"
            formControlName="latitude"
          >
            <span class="input__placeholder">Введите широту</span>
          </tui-input-number>
          <tui-field-error formControlName="latitude"></tui-field-error>
        </div>
      </div>
      <div class="form__field">
        <span>Долгота *</span>
        <div class="input">
          <tui-input-number
            [precision]="14"
            [tuiTextfieldMaxLength]="50"
            formControlName="longitude"
          >
            <span class="input__placeholder">Введите долготу</span>
          </tui-input-number>
          <tui-field-error formControlName="longitude"></tui-field-error>
        </div>
      </div>
      <div class="borehole-add__map">
        <app-map-object
          [onClickOnMap]="onClickOnMap"
          [currentIdObject]="105"
          [latitude]="latitude"
          [longitude]="longitude"
        >
        </app-map-object>
      </div>
      <span *ngIf="errorMap" class="borehole-add__map-error"
        >Выберите точку на карте</span
      >
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="footer">
      <span>* Обязательные для заполнения поля</span>
      <div class="footer__buttons">
        <button (click)="submitForm()" class="footer-button _add">
          Добавить
        </button>
        <button (click)="closeDialog()" class="footer-button _cancel">
          Отменить
        </button>
      </div>
    </div>
  </app-primary-section>
  <ng-template #loading>
    <tui-loader class="loader"></tui-loader>
  </ng-template>
  <ng-template #statusSelectContent let-data>
    <div class="template">
      <div class="name">{{ data.name }}</div>
    </div>
  </ng-template>
</div>
