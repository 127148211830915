import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-table-buttons",
  templateUrl: "./table-buttons.component.html",
  styleUrls: ["./table-buttons.component.less"],
})
export class TableButtonsComponent {
  @Input()
  buttons!: any;

  @Output() getAction: any = new EventEmitter<string>();

  selectAction(button: string) {
    this.getAction.emit(button);
  }
}
