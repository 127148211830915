import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  BADGE_TRANSLATE,
  OBJECTS_DEBIT,
  TRANSLATE_TYPES,
  TYPE_MINING,
} from '../../../../../../const/app-consts';
import { TypeTree } from '../../../../../../../models/tree.model';
import { TuiDialogOptions } from '@taiga-ui/core';
import { ObjectService } from '../../../../../../services/object.service';
import * as memoizee from 'memoizee';

@Component({
  selector: 'app-boreholes-oilfield',
  templateUrl: './boreholes-oilfield.component.html',
  styleUrls: ['./boreholes-oilfield.component.less'],
})
export class BoreholesOilfieldComponent implements OnInit {
  @Input() selectedDeposit: any;

  public boreholes: any[] = [];

  public TYPE_MINING = TYPE_MINING;

  public OBJECTS_DEBIT = OBJECTS_DEBIT;

  public statusTitles: any = {
    ok: BADGE_TRANSLATE.ok,
    stop: BADGE_TRANSLATE.stop,
    no_connection: BADGE_TRANSLATE.no_connection,
  };

  public tableColumns = [
    { name: 'name', title: 'Название' },
    { name: 'oil_gathering', title: 'Объект нефтесбора' },
    { name: 'type_mining', title: 'Тип добычи' },
    { name: 'status', title: 'Состояние' },
    { name: 'debit', title: 'Режимный дебит' },
    { name: 'debit_fact', title: 'Фактический дебит' },
    { name: 'frequency', title: 'Частота' },
    { name: 'working_hours', title: 'Время работы' },
  ];

  public isReloading: boolean = true;

  public openDeletePopup: boolean = false;

  public closeDeleteModal = () => {
    this.openDeletePopup = false;
  };

  @Output() handleChange: EventEmitter<boolean> = new EventEmitter();

  //без оутпута с этим методом не работает закрытие диалога, когда он в другом диалоге
  public closeDeleteDialog(event: boolean): any {
    this.openDeletePopup = false;
    if (event) {
      this.handleChange.emit(true);
    } else {
      this.handleChange.emit(false);
    }
    this.changeDetectorRef.detectChanges();
  }

  public selectedForDeleteObject: null | {
    id: number | null;
    name: string;
    type: string;
  } = null;

  public dialogDeleteObject: Partial<TuiDialogOptions<boolean | string>> = {
    closeable: false,
    size: 's',
  };

  public translate: any = TRANSLATE_TYPES;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private objectService: ObjectService
  ) {}

  ngOnInit(): void {
    this.boreholes = [...this.selectedDeposit.children] || [];
    this.isReloading = false;
  }

  openBorehole(id: number) {
    window.open(`/main/card/borehole/${id}`);
  }

  deleteNode(item: TypeTree) {
    this.selectedForDeleteObject = {
      id: item.id,
      name: item.name,
      type: this.translate[item.type],
    };
    this.openDeletePopup = !this.openDeletePopup;
    this.changeDetectorRef.detectChanges();
  }

  updateTable(isUpdated: boolean) {
    if (isUpdated) {
      this.isReloading = true;
      this.objectService
        .getDepositInfoByID(this.selectedDeposit.id)
        .subscribe((deposit: any) => {
          this.boreholes = [...deposit.children];
          this.handleChange.emit(true);
          this.changeDetectorRef.markForCheck();
          this.isReloading = false;
        });
      this.changeDetectorRef.detectChanges();
    }
  }

  getWorkTime(work_time: any) {
    if (work_time === null) {
      return '-';
    }
    const hours = Math.floor(work_time / 3600);
    const minutes = Math.floor((work_time % 3600) / 60);
    const seconds = work_time % 60;
    let result = `${minutes.toString().padStart(1, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
    if (!!hours) {
      result = `${hours.toString()}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return result;
  }

  getCurrentLatLon(latitude: number, longitude: number) {
    return latitude && longitude ? [latitude, longitude].join(', ') : '-';
  }

  public debitList = OBJECTS_DEBIT;

  public getDebit = memoizee(
    (debit: number | string | null, field: string[] | null) => {
      if (typeof debit === 'string' && this.debitList.hasOwnProperty(debit)) {
        if (field?.length) {
          return (
            `${this.debitList[debit].toFixed(2)}` +
            `${
              field.find((v: string) => ['M1', 'M2'].includes(v)) ? ' т' : ' м3'
            }`
          );
        } else return this.debitList[debit] + ' м3';
      } else if (
        debit !== null &&
        debit !== undefined &&
        !this.debitList.hasOwnProperty(debit)
      ) {
        if (field?.length && typeof debit == 'number') {
          return (
            `${debit.toFixed(2)}` +
            ' ' +
            `${
              field.find((v: string) => ['M1', 'M2'].includes(v)) ? 'т' : 'м3'
            }`
          );
        } else return debit;
      }
      return '-';
    }
  );

  //эта функция будет выдавать undefined так как по запросу инфо месторождения
  // в поле сhildren в объектах скважин нет поля debit_device_value
  public getRealDebit = memoizee(
    (debit: number | null, field: string[] | null) => {
      if (typeof debit === 'number') {
        if (field?.length) {
          return (
            `${this.debitList[debit].toFixed(2)}` +
            `${
              field.find((v: string) => ['M1', 'M2'].includes(v)) ? ' т' : ' м3'
            }`
          );
        } else return debit + ' м3'; // по идее такого не должно быть, если нет
        //параметров устройств
      }
      return '-';
    }
    //return device.debit_device_value ? device.debit_device_value : '-';
  );

  getFreq = memoizee((item: any): string => {
    const frequencyFieldName = item.freq_device_field;
    if (typeof frequencyFieldName === 'string') {
      if (item.freq_device?.last_message?.hasOwnProperty(frequencyFieldName)) {
        const value = item.freq_device.last_message[frequencyFieldName];
        return value.toFixed(2) + ' Гц';
      }
    }
    return '-';
  });
}
