import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TuiAlertService } from '@taiga-ui/core';
import { ObjectService } from '../../../../../services/object.service';
import { TypeTree } from 'src/models/tree.model';
import * as memoizee from 'memoizee';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { maxValueValidator } from '../../../dns-card/components/common-info-dns/common-info-dns.component';

function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control.value?.trim() || '';
    if ((control?.value?.length || '') !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}
export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

@Component({
  selector: 'app-common-info-bush',
  templateUrl: './common-info-bush.component.html',
  styleUrls: ['./common-info-bush.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class CommonInfoBushComponent implements OnInit {
  public label: any[] = [
    {
      name: 'name',
      title: 'Название',
      isSort: true,
      isFilter: true,
    },
    {
      name: 'value',
      title: 'Значение',
      isSort: true,
      isFilter: true,
    },
  ];

  public data: any = null;

  public editObjectMode: boolean = false;

  @Input()
  public contextObject: any = null;

  @Output() handleChange: EventEmitter<boolean> = new EventEmitter();

  public objectInfoForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    deposit: new FormControl('', [Validators.required]),
  });

  //для формы -> список скважин
  public depositList: any = [];

  public parent: any | null = null;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    public objectService: ObjectService
  ) {}

  async ngOnInit() {
    if (this.contextObject) {
      const deposits = await this.objectService.deposits;
      this.depositList = deposits.map((item: TypeTree) => {
        return { id: item.id, title: item.name };
      });
      this.parent = this.depositList.find((item: TypeTree) => {
        return item.id === this.contextObject.parent_id;
      });
      this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
      this.objectInfoForm.get('deposit')?.setValue(this.parent || null);
      //console.log(this.childrenCoordsAreSet(this.contextObject.children));
    }
  }

  save() {
    if (!this.objectInfoForm.valid) {
      return this.objectInfoForm.markAllAsTouched();
    }
    if (this.objectInfoForm.dirty) {
      let body: TypeTree = {
        ...this.contextObject,
        name: this.objectInfoForm.get('name').value,
        parent_id: this.objectInfoForm.get('deposit')?.value?.id || null,
      };
      this.objectService
        .updateObject(body, this.contextObject.id)
        .subscribe((deposit: any) => {
          this.contextObject = {
            ...this.contextObject,
            name: deposit.updated_object.name,
            parent_id: this.objectInfoForm.get('deposit')?.value?.id || null,
          };
          this.parent = this.depositList.find((item: TypeTree) => {
            return item.id === this.contextObject.parent_id;
          });
          this.notificationsService
            .open('Объект обновлен', {
              label: '',
              status: TuiNotification.Success,
              hasIcon: true,
              autoClose: true,
              hasCloseButton: true,
            })
            .subscribe();
          this.handleChange.emit(true);
          this.changeInput();
        });
    } else {
      this.changeInput();
    }
  }

  changeInput(): void {
    this.editObjectMode = !this.editObjectMode;
  }

  cancelInput(): void {
    this.objectInfoForm.reset();
    this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
    this.objectInfoForm.get('deposit')?.setValue(this.parent || null);
    this.editObjectMode = false;
  }

  childrenCoordsAreSet(children: any[]) {
    return children.some((child: any) => {
      return child.latitude !== null && child.longitude !== null;
    });
  }

  isAvailableChildrenCoords(children: any[]) {
    return children.some((child: any) => {
      return (
        child.latitude < 90 &&
        child.latitude > -90 &&
        child.longitude < 180 &&
        child.longitude > -180
      );
    });
  }

  getDepositNameByID = memoizee((parent_id: any) => {
    return new Promise((resolve) => {
      this.objectService.getDepositsList().subscribe((res: TypeTree[]) => {
        resolve(
          res.find((item) => {
            return item.id === parent_id;
          })?.name || 'Не выбран'
        );
      });
      return;
    });
  });
}
