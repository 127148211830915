import { Component, Inject, OnInit } from '@angular/core';
import { TuiDialogContext, TuiDialogService } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TabsModel } from '../../../../models/tabs.model';
import {
  TABS_DEFAULT,
  TABS_DNS,
  TABS_OILFIELD,
} from '../../../const/app-consts';
import { TypeTree } from '../../../../models/tree.model';

@Component({
  selector: 'app-object-info-card',
  templateUrl: './object-info-card.component.html',
  styleUrls: ['./object-info-card.component.less'],
})
export class ObjectInfoCardComponent implements OnInit {
  public selectedObject?: TypeTree;

  public isObjFromMap: boolean = false;

  public tabs: Array<TabsModel> = TABS_DEFAULT;

  public defaultTabs: Array<TabsModel> = TABS_DEFAULT;

  public tabsForRest: Array<TabsModel> = TABS_DEFAULT;

  public tabsForOilfield: Array<TabsModel> = TABS_OILFIELD;

  public tabsForDns: Array<TabsModel> = TABS_DNS;

  public activeItemIndex: TabsModel = {
    url: 'info',
    title: 'Общая информация',
  };

  constructor(
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<any, any>
  ) {}

  ngOnInit() {
    this.selectedObject = this.context.data.selectedObject;
    this.isObjFromMap = this.context.data.isClickFromMap;

    if (this.selectedObject) {
      if (['deposit', 'bush'].includes(this.selectedObject.type)) {
        this.tabs = this.tabsForOilfield;
      } else if (this.selectedObject.type === 'dns') {
        this.tabs = this.tabsForDns;
      } else {
        this.tabs = this.tabsForRest;
      }
    } else {
      this.tabs = this.defaultTabs;
    }
  }

  changeTabElement(tab: any) {
    this.activeItemIndex = tab;
  }

  editScheme() {
    this.context.completeWith(true);
  }

  close() {
    this.context.completeWith(null);
  }
}
