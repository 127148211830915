import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
  animations: [
    trigger('expandedSidebar', [
      state('initial', style({ width: 70 })),
      state('expanded', style({ width: 250 })),
      transition('initial <=> expanded', animate(300)),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  public isOpenMenu: boolean = false;

  public state: string = 'initial';

  public test: boolean = true;

  public isTransition = false;

  //TODO: вызывался два раза подряд, плохиш был наказан
  /*  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (
      !this.el.nativeElement.contains(event.target) &&
      this.state === 'expanded'
    ) {
      // // this.appService.leftMenuStatus.next(false);
      // this.isOpenMenu = false;
      // this.state = 'initial';
    }
  }*/

  constructor(private appService: AppService) {}

  ngOnInit() {
    this.appService.leftMenuStatus.subscribe((isExpandedMenu: boolean) => {
      this.isOpenMenu = isExpandedMenu;
      this.state = this.isOpenMenu ? 'expanded' : 'initial';
    });
  }

  toggleMenu() {
    this.appService.leftMenuStatus.next(!this.isOpenMenu);
    this.isTransition = true;
    setTimeout(() => (this.isTransition = false), 300);
  }
}
