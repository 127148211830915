<div class="tui-container tui-container_adaptive">
  <div class="tui-row tui-row_adaptive tui-row_align_center login__row">
    <div class="tui-col_lg-12 tui-col_md-12">
      <div class="tui-row tui-row_adaptive login__card">
        <div class="tui-col_lg-6 tui-col_md-6 hidden-xs login__left"></div>
        <div class="tui-col_lg-6 tui-col_md-6 tui-col_xs-12">
          <div
            class="tui-row tui-row_adaptive tui-row_align_center login_right"
          >
            <div
              class="tui-col-offset_lg-1 tui-col-offset_md-1 tui-col_lg-10 tui-col_md-10"
            >
              <h1 class="login__title">Авторизация</h1>
              <form
                class="login__form"
                [formGroup]="authorizationForm"
                (ngSubmit)="onSubmit()"
              >
                <div class="login__form-field">
                  <mat-form-field>
                    <mat-label>E-mail</mat-label>
                    <input
                      matInput
                      formControlName="email"
                      type="email"
                      autocomplete="email"
                      maxlength="151"
                    />
                  </mat-form-field>
                  <tui-field-error
                    style="margin-bottom: 10px"
                    formControlName="email"
                  ></tui-field-error>
                  <mat-form-field>
                    <mat-label>Пароль</mat-label>
                    <input
                      formControlName="password"
                      matInput
                      autocomplete="password"
                      [type]="hide ? 'password' : 'text'"
                      maxlength="151"
                    />
                    <button
                      class="button-position"
                      mat-icon-button
                      matSuffix
                      (click)="hide = !hide"
                      type="button"
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide"
                    >
                      <mat-icon>{{
                        hide ? "visibility_off" : "visibility"
                      }}</mat-icon>
                    </button>
                  </mat-form-field>
                  <tui-field-error
                    style="margin-bottom: 10px"
                    formControlName="password"
                  ></tui-field-error>
                </div>
                <button tuiButton appearance="primary" size="l">Войти</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
