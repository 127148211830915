import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  public filterTypesData: any = null;

  private readonly TOKEN_KEY: string = 'filterTypes';

  constructor(private cookieService: CookieService) {
    this.filterTypesData = JSON.parse(
      this.cookieService.get(this.TOKEN_KEY) || '[]'
    );
  }

  setFilteredMapDataToCookie(data: any): void {
    this.filterTypesData = JSON.stringify(data || []);
    this.cookieService.set(
      this.TOKEN_KEY,
      this.filterTypesData,
      undefined,
      '/'
    );
  }

  getCookieData(): string | null {
    return JSON.parse(this.cookieService.get(this.TOKEN_KEY) || '[]');
  }
}
