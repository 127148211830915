import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  InputCoordinateData,
  InputType,
} from '../../../../../../../models/content-field.model';

@Component({
  selector: 'app-location-fields',
  templateUrl: './location-fields.component.html',
  styleUrls: ['./location-fields.component.less'],
})
export class LocationFieldsComponent {
  @Input()
  form!: FormGroup;

  @Output()
  handleChange: EventEmitter<InputCoordinateData> = new EventEmitter();

  settings: Array<InputType> = [
    {
      controlName: 'latitude',
      placeholder: 'Введите координаты широты',
      isRequiredInput: true,
      title: 'Широта',
      isRequired: true,
      maxLength: 50,
    },
    {
      controlName: 'longitude',
      isRequiredInput: true,
      placeholder: 'Введите координаты долготы',
      title: 'Долгота',
      isRequired: true,
      maxLength: 50,
    },
  ];

  setCoordinate(data: InputCoordinateData) {
    this.handleChange.emit(data);
  }
}
