import { Component, Input } from "@angular/core";
import * as memoizee from "memoizee";
import { WidgetModel } from "../../models/widget.model";

@Component({
  selector: "app-widget",
  templateUrl: "./widget.component.html",
  styleUrls: ["./widget.component.less"],
})
export class WidgetComponent {
  @Input()
  widgets: WidgetModel[] = [];

  public getCurrentStatus = memoizee((statusList: number[]): string => {
    return statusList.join(" / ");
  });
}
