import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-event-buttons',
  templateUrl: './event-buttons.component.html',
  styleUrls: ['./event-buttons.component.less'],
})
export class EventButtonsComponent implements OnInit, OnDestroy {
  @Output()
  handleClickByBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  handleClickReadAll: EventEmitter<boolean> = new EventEmitter<boolean>();

  public openSettings: boolean = false;

  public eventSettingsForm = new FormGroup({
    eventsSettings: new FormControl([]),
  });

  public eventsSettingsValues: { label: string; value: string }[] = [
    { label: 'Потеря связи', value: 'no_connection' },
    { label: 'Запуск скважины', value: 'ok' },
    { label: 'Остановка скважины', value: 'stop' },
  ];

  public eventSettings: string[] = [];

  public destroyer = new Subject();

  ngOnInit() {
    let eventSettings: string | string[] | null =
      localStorage.getItem('eventsSettings');
    if (!!eventSettings) {
      eventSettings = eventSettings.split(',');
      this.eventSettingsForm.setValue({
        eventsSettings: eventSettings,
      });
      this.eventSettings = eventSettings;
    } else {
      this.eventSettingsForm.setValue({
        eventsSettings: this.eventsSettingsValues.map((i: any) => i.value),
      });
      this.eventSettings = this.eventsSettingsValues.map((i: any) => i.value);
    }
    this.eventSettingsForm.valueChanges
      .pipe(takeUntil(this.destroyer))
      .subscribe((data: any) => {
        const { eventsSettings } = data;
        localStorage.setItem('eventsSettings', eventsSettings);
        this.eventSettings = eventsSettings;
      });
  }

  rollback() {
    this.handleClickByBack.emit();
  }

  readAllEvents() {
    this.handleClickReadAll.emit();
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
