<div class="filtering" (tuiActiveZoneChange)="changeModal($event)">
  <button
    tuiIconButton
    icon="tuiIconSettings"
    appearance="flat"
    size="m"
    type="button"
    (click)="changeModal(true)"
  ></button>
  <div
    *tuiSidebar="changeModalWindow; direction: 'right'"
    class="filtering__template"
  >
    <perfect-scrollbar>
      <div class="filtering__cancel">
        <div class="filtering__cancel-icon">
          <button
            tuiIconButton
            appearance="flat"
            size="m"
            icon="tuiIconCloseLarge"
            type="button"
            (click)="changeModal(false)"
          ></button>
        </div>
      </div>
      <h2>Фильтр</h2>
      <hr class="filtering-line" />
      <div class="filtering__form">
        <ng-container *ngFor="let filter of listOfFilters">
          <ng-container
            *ngIf="filter.type === SELECT && filter.multi !== undefined"
          >
            <app-select
              [title]="filter.title"
              [values]="filter.values"
              [isMulti]="filter.multi"
              (handleClick)="setValueInFilterForm($event, filter)"
            >
            </app-select>
          </ng-container>
          <ng-container *ngIf="filter.type === RADIO">
            <div class="filtering__form-list">
              <tui-radio-block
                *ngFor="let item of filter.values"
                [(ngModel)]="changedFilterForm[filter.name]"
                class="tui-space_bottom-3 tui-space_right-3"
                size="m"
                [item]="item"
              >
                {{ item }}
              </tui-radio-block>
            </div>
          </ng-container>
          <ng-container *ngIf="filter.type === CHECKBOX">
            <div class="filtering__form-list">
              <tui-checkbox-block
                *ngFor="let item of filter.values"
                [(ngModel)]="changedFilterForm[filter.name][item]"
                class="tui-space_bottom-3 tui-space_right-3"
                size="m"
              >
                {{ item }}
              </tui-checkbox-block>
            </div>
          </ng-container>
          <ng-container *ngIf="filter.type === RANGE">
            <div class="tui-space_top-4">
              <span>{{ filter.title }}</span>
            </div>
            <tui-input-slider
              [(ngModel)]="changedFilterForm[filter.name]"
              class="control tui-space_bottom-4 tui-space_right-3"
              size="m"
              [quantum]="1"
              [min]="filter.min || 1"
              [max]="filter.max || 10"
              [steps]="filter.max || 10"
            >
            </tui-input-slider>
          </ng-container>
        </ng-container>
      </div>
      <button
        tuiButton
        class="filtering__template-button tui-space_top-5"
        type="button"
        (click)="saveFilterSettings()"
      >
        Сохранить
      </button>
    </perfect-scrollbar>
  </div>
</div>
