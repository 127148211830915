import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ObjectService } from '../../../../services/object.service';
import { TypeTree } from '../../../../../models/tree.model';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { Subject, takeUntil } from 'rxjs';

//проверка на пробелы
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

export function minValueValidator(context: { min: number }): string {
  return `Значение должно быть не меньше ${context.min}`;
}

export function maxValueValidator(context: { max: number }): string {
  return `Значение должно быть не больше ${context.max}`;
}

@Component({
  selector: 'app-dns-add',
  templateUrl: './dns-add.component.html',
  styleUrls: ['./dns-add.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class DnsAddComponent implements OnInit, OnDestroy {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  // возвращает созданное месторождение
  @Output() requestCreatedData: EventEmitter<null> = new EventEmitter();

  //для формы -> список скважин
  public depositList: any = [];

  //форма для имени месторождения
  public formGZU: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    deposit: new FormControl('', [Validators.required]),
    latitude: new FormControl('', [
      Validators.required,
      Validators.min(-90),
      Validators.max(90),
    ]),
    longitude: new FormControl('', [
      Validators.required,
      Validators.min(-180),
      Validators.max(180),
    ]),
  });

  //клик по карте(по нему в onInit записываем координаты клика по карте)
  public onClickOnMap = new EventEmitter();

  public filterGzuWithDnsList: any = {
    gzuList: [],
    dnsList: [],
  };

  public gzuWithDnsList: any = {
    gzuList: [],
    dnsList: [],
  };

  //public addObjectMode: boolean = true;

  public latitude: number = 55;

  public longitude: number = 55;

  /*  public latitude: number = 55.560981;

  public longitude: number = 52.638667;*/

  //public visibleMap: boolean = true;

  public destroyer: Subject<null> = new Subject();

  constructor(public objectService: ObjectService) {}

  async ngOnInit() {
    const deposits = await this.objectService.deposits;
    const gzu = await this.objectService.gzu;
    const dns = await this.objectService.dns;
    this.gzuWithDnsList = {
      gzuList: gzu,
      dnsList: dns,
    };
    this.depositList = deposits.map((item: TypeTree) => {
      return { id: item.id, title: item.name };
    });
    this.onClickOnMap
      .pipe(takeUntil(this.destroyer))
      .subscribe((coordinates) => {
        this.formGZU.get('latitude')?.setValue(coordinates.lat);
        this.formGZU.get('longitude')?.setValue(coordinates.lng);
      });
    this.formGZU
      .get('latitude')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (this.formGZU.get('latitude')?.valid) {
          this.latitude = item;
          return;
        }
        return;
      });
    this.formGZU
      .get('longitude')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (this.formGZU.get('longitude')?.valid) {
          this.longitude = item;
          return;
        }
        return;
      });
  }

  // проверка на валидность поля -> отправка запроса при успешной валидации
  submitForm() {
    if (!this.formGZU.valid) {
      return this.formGZU.markAllAsTouched();
    }
    this.objectService
      .objectCreate({
        type: 'dns',
        name: this.formGZU.get('name')?.value,
        parent_id: this.formGZU.get('deposit')?.value.id,
        latitude: this.formGZU.get('latitude')?.value || null,
        longitude: this.formGZU.get('longitude')?.value || null,
      })
      .pipe(takeUntil(this.destroyer))
      .subscribe(() => {
        this.requestCreatedData.emit();
        this.closeDialog();
      });
  }

  // очистка полей и закрытие
  closeDialog() {
    this.formGZU.reset({ name: '' });
    this.closeModal();
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
