import { Component } from "@angular/core";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.less"],
})
export class HeaderComponent {
  public hasLogo: boolean = false;

  public logoUrl: string = "favicon.ico";

  changeLogo() {
    //выбор логотипа
    //console.log("change");
  }

  removeLogo() {
    //удаление логотипа
    //console.log("delete");
  }
}
