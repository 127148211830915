import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-scheme",
  templateUrl: "./scheme.component.html",
  styleUrls: ["./scheme.component.less"],
})
export class SchemeComponent implements OnInit {
  @Input()
  data?: any = [];

  @Output() changeField: any = new EventEmitter<any>();

  // сетка 10 на 10
  //public data: any = [];

  // выбранный объект из списка (труба, объект из дерева или кнопка удаления)
  public selectedType!: string | null | undefined;

  // id объекта с дерева, используется для манипуляции смены
  // при выборе другого объекта в дереве
  public lastId: number | null = null;

  // состояние кнопки очистки ячеек
  public activeRemoveMode: boolean = false;

  // массив труб
  public statePipe: Array<any> = [];

  // данные дерева
  public treeData: any;

  // 1) получаем данные дерева
  // 2) трубы
  // 3) создаем арену 10 на 10
  ngOnInit() {
    // this.mapService.treeData.subscribe((data) => {
    //   this.reloadActive = true;
    //   this.treeData = data;
    //   setTimeout(() => {
    //     this.reloadActive = false;
    //   }, 100);
    // });

    for (let i = 1; i < 10; i++) {
      this.statePipe.push({
        value: `pipe_${i}`,
        status: false,
      });
    }
    for (let i = 0; i < 10; i++) {
      this.data[i] = [];
      for (let j = 0; j < 10; j++) {
        this.data[i].push({ id: i * j, value: null });
      }
    }
  }

  // рекурсия по поиску нужного нам объекта
  // object массив объектов в котором ищем
  // idItem искомый id объекта который должен найтись
  // changedItem  тип его модификации в дереве
  getProp(object: any, changedItem: string, idItem: number) {
    if (object.hasOwnProperty("children")) {
      object.children.map((item: any) => {
        this.getProp(item, changedItem, idItem);
      });
      this.changeItem(changedItem, idItem, object);
    } else {
      this.changeItem(changedItem, idItem, object);
    }
  }

  // преобразование объекта по условию changedItem
  // object массив объектов в котором ищем
  // idItem искомый id объекта который должен найтись
  // changedItem  тип его модификации в дереве
  // set выделяет объект черным
  // delete удаляет выделение
  // disabled выделяет объект который уже нельзя использовать
  // undisabled удаляет выделение disabled
  changeItem(changedItem: string, idItem: number, object: any) {
    if (changedItem === "set" && idItem === object.id) {
      object.set = true;
    } else if (changedItem === "delete" && idItem === object.id) {
      delete object.set;
    } else if (changedItem === "disabled" && idItem === object.id) {
      object.disabled = true;
    } else if (changedItem === "undisabled" && idItem === object.id) {
      delete object.disabled;
    }
  }

  // по значению выбранного эллемента (selectedType) ставит нужную картинку
  // при ситуацц когда selectedType === null удаляет значение в ячейке
  changeFieldByCoordinate(row: number, column: number) {
    this.changeField.emit();
    if (this.selectedType !== undefined) {
      if (
        this.selectedType !== null &&
        (this.selectedType as string).startsWith("pipe")
      ) {
        this.data[row][column].value = {
          id: "-1",
          icon: `assets/pipes/${this.selectedType}.svg`,
        };
      } else {
        if (!this.activeRemoveMode && this.lastId !== null) {
          this.data[row][column].value = {
            id: this.lastId,
            icon: `assets/icons-scheme/${this.selectedType}.svg`,
          };
          this.selectedType = undefined;
          this.getProp(this.treeData, "disabled", this.lastId);
          this.getProp(this.treeData, "delete", this.lastId);
          this.lastId = null;
        } else {
          if (this.data[row][column].value.id !== "-1") {
            this.getProp(
              this.treeData,
              "undisabled",
              this.data[row][column].value.id
            );
          }
          this.data[row][column].value = null;
        }
      }
    } else {
      return;
    }
  }
}
