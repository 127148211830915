import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TabsModel } from 'src/models/tabs.model';
import { TypeTree } from '../../../../models/tree.model';
import { ActivatedRoute } from '@angular/router';
import { PipelineService } from '../../../services/pipeline.service';

/*для Андрея: почему же отельная компонента
- дело в context из-за его объявления в конструкторе
все крушиться (а без его context контекст не будет работать)*/
@Component({
  selector: 'app-water-pipeline-card',
  templateUrl: './water-pipeline-card.component.html',
  styleUrls: ['./water-pipeline-card.component.less'],
})
export class WaterPipelineCardComponent implements OnInit {
  //id месторождения
  @Input()
  public objectID: string | null | number = null;

  @Output()
  openDeletePopUpFunction: EventEmitter<null> = new EventEmitter();

  @Output()
  handleSubmit: EventEmitter<null> = new EventEmitter();

  //данные выбранного объекта
  public selectedObject?: any | TypeTree = null;

  //список tab
  public tabs: TabsModel[] = [{ url: 'info', title: 'Информация' }];

  //выбранный tab
  public activeItemIndex: TabsModel = this.tabs[0];

  public isLoading = true;

  constructor(
    private pipelineService: PipelineService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  //взятие с роутера id месторождения и по нему запрос на получение данных
  ngOnInit() {
    if (!this.objectID) {
      this.objectID = this.route?.snapshot?.paramMap?.get('id') as string;
    }
    this.pipelineService
      .getWaterPipelineByID(+this.objectID)
      .subscribe((waterPipeline: any) => {
        this.selectedObject = waterPipeline;
        this.changeDetectorRef.markForCheck();
        this.isLoading = false;
      });
  }

  //смена навигационных tab
  changeTabElement(tab: TabsModel) {
    this.activeItemIndex = tab;
  }

  //при каком либо изменении запрос на получения свежих данных
  getEditableWaterPipeline() {
    this.isLoading = true;
    this.pipelineService
      .getWaterPipelineByID(+(this.objectID as string))
      .subscribe((waterPipeline: any) => {
        this.selectedObject = waterPipeline;
        this.changeDetectorRef.markForCheck();
        this.isLoading = false;
        this.handleSubmit.emit();
      });
  }

  // Диалог удаления объекта (нефтепровода, водопровода, ЛЭП)
  onDelete() {
    this.openDeletePopUpFunction.emit(this.selectedObject);
  }
}
