<div class="header-map">
  <tui-hosted-dropdown
    #dropdown
    [tuiDropdownMaxHeight]="500"
    [content]="content"
  >
    <button
      size="m"
      tuiButton
      appearance="menu"
      type="button"
      [pseudoHovered]="dropdown.open || null"
    >
      <i-tabler name="layers-intersect"></i-tabler>
    </button>
  </tui-hosted-dropdown>
  <ng-template #content>
    <form
      tuiGroup
      orientation="vertical"
      class="group"
      [collapsed]="true"
      [formGroup]="form"
    >
      <tui-radio-block
        size="l"
        contentAlign="right"
        formControlName="type"
        item="scheme"
      >
        Схема
      </tui-radio-block>
      <tui-radio-block
        size="l"
        contentAlign="right"
        formControlName="type"
        item="sputnik"
      >
        Спутник
      </tui-radio-block>
      <tui-radio-block
        size="l"
        contentAlign="right"
        formControlName="type"
        item="hybrid"
      >
        Гибрид
      </tui-radio-block>
      <tui-radio-block
        size="l"
        contentAlign="right"
        formControlName="type"
        item="terrain"
      >
        Местность
      </tui-radio-block>
    </form>
  </ng-template>
  <!--  ++++++++++++++++ -->
  <tui-hosted-dropdown
    #addDropdown
    [tuiDropdownMaxHeight]="500"
    [content]="addContent"
  >
    <button
      size="m"
      tuiButton
      appearance="menu"
      type="button"
      [pseudoHovered]="addDropdown.open || null"
    >
      <i-tabler name="plus"></i-tabler>
    </button>
  </tui-hosted-dropdown>
  <ng-template #addContent>
    <div style="display: flex; flex-direction: column; gap: 5px">
      <button
        class="button-add"
        (click)="openPopup(item.id)"
        *ngFor="let item of listOfTypes"
        [class._disabled]="isDisable(item)"
        [disabled]="isDisable(item)"
      >
        {{ item.title }}
      </button>
    </div>
  </ng-template>
</div>
