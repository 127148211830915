/**
 * Тип объекта
 * @readonly
 * @enum {string}
 */
export enum ObjectType {
  NONE = 'none',
  DEPOSIT = 'deposit', //Месторождение
  BOREHOLE = 'borehole', //Скважина
  GZU = 'gzu', //ГЗУ
  DNS = 'dns', //ДНС
  BG = 'bg', //Блок гребенок
  BUSH = 'bush', //Куст
  KTP = 'ktp', //КТП
  PRESSURE = 'pressure', // Датчик давления
}

export const ObjectTypeTranslate = {
  [String(ObjectType.NONE)]: 'Тип объекта не выбран',
  [String(ObjectType.BOREHOLE)]: 'Скважина',
  [String(ObjectType.DEPOSIT)]: 'Месторождение',
  [String(ObjectType.GZU)]: 'ГЗУ',
  [String(ObjectType.DNS)]: 'ДНС',
  [String(ObjectType.BG)]: 'Блок гребенок',
  [String(ObjectType.BUSH)]: 'Куст',
  [String(ObjectType.KTP)]: 'КТП',
  [String(ObjectType.PRESSURE)]: 'Датчик давления',
};
