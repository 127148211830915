<div class="controls">
  <button
    tuiButton
    size="s"
    style="background: coral"
    type="button"
    appearance="primary"
    class="tui-space_right-3"
    (click)="addObject('pipe')"
  >
    Добавить трубопровод
  </button>
  <button
    tuiButton
    size="s"
    style="background: coral"
    type="button"
    class="tui-space_right-3"
    (click)="addObject('electro-line')"
  >
    Добавить линию электричества
  </button>
</div>
