import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { UsersService } from '../../services/users.service';
import { filter, Observable, of, Subject, switchMap } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { TypeTree } from '../../../models/tree.model';
import * as _ from 'lodash';
import { AddUserComponent } from './components/add-user/add-user.component';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import {
  TuiAlertService,
  TuiDialogOptions,
  TuiDialogService,
  TuiNotification,
} from '@taiga-ui/core';
import { tuiIconEdit, tuiIconClose } from '@taiga-ui/icons';
import { EditUserComponent } from './components/edit-user/edit-user.component';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.less'],
})
export class UsersComponent implements OnInit {
  readonly tuiIconEdit = tuiIconEdit;

  readonly tuiIconClose = tuiIconClose;

  public primaryTableColumns = [
    { name: 'active', title: 'Активность' },
    { name: 'username', title: 'Логин' },
    { name: 'full_name', title: 'Имя' },
    { name: 'phone', title: 'Номер телефона' },
    { name: 'duty', title: 'Должность' },
    { name: 'access', title: 'Права доступа' },
    { name: 'comment', title: 'Комментарий' },
  ];

  public accessTranslate: any = {
    read: 'Просмотр',
    create: 'Создание',
    update: 'Редактирование',
    delete: 'Удаление',
    control: 'Управление',
  };

  public primaryTableData: any[] = [];

  public sortData = {
    value: 'active',
    direction: 'asc',
  };

  readonly search$: Subject<string | null> = new Subject();

  private searchQuery = '';

  readonly filteredUsers$: Observable<readonly any[] | null> =
    this.search$.pipe(
      filter((value) => value !== null),
      switchMap((search) =>
        this.filterUsers(search).pipe(startWith<readonly any[] | null>(null))
      )
    );

  private readonly addUserDialog = this.dialogService.open<any>(
    new PolymorpheusComponent(AddUserComponent, this.injector),
    { dismissible: false }
  );

  public dialogDeleteUser: Partial<TuiDialogOptions<boolean | string>> = {
    closeable: false,
    size: 's',
  };

  public openDeletePopup: boolean = false;

  public closeDeleteModal = () => {
    this.deletedUser = null;
    this.openDeletePopup = false;
  };

  public deletedUser: any = null;

  public handleDeleteUser = () => {
    this.usersService.deleteUser(this.deletedUser?.id).subscribe(() => {
      this.alertService
        .open('Пользователь успешно удален.', {
          label: 'Успешно!',
          status: TuiNotification.Success,
        })
        .subscribe();
      this.deletedUser = null;
      this.openDeletePopup = false;
      this.updateUsers();
    });
  };

  constructor(
    private appService: AppService,
    public usersService: UsersService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
    private alertService: TuiAlertService
  ) {}

  ngOnInit(): void {
    this.appService.title.next('Пользователи');
    this.usersService.getList().subscribe((users: any) => {
      this.primaryTableData = this.sortedData(users);
      this.search$.next('');
    });
  }

  handleAddUser() {
    this.addUserDialog.subscribe({
      next: (data) => {
        if (data === true) {
          this.usersService.getList().subscribe((users: any) => {
            this.primaryTableData = this.sortedData(users);
            this.search$.next('');
          });
        }
      },
    });
  }

  sortTable(some: any) {
    this.sortData = {
      value: some.field.name,
      direction: some.direction,
    };
    this.primaryTableData = this.sortedData(this.primaryTableData);
    this.search$.next('');
  }

  sortedData(result: any) {
    return this.isRevert(
      _.sortBy(result, (u: any) =>
        String(u[this.sortData.value]).toLowerCase()
      ),
      this.sortData.direction
    );
  }

  isRevert(arr: TypeTree[], isRevert: string) {
    if (isRevert === 'asc') {
      return arr;
    } else {
      return arr.reverse();
    }
  }

  handleSearch($event: any) {
    this.searchQuery = $event;
    this.search$.next($event);
  }

  handleClickToggleActive(userId: number) {
    this.usersService.toggleActive(userId).subscribe();
  }

  getTranslatedAccess(access: string[]) {
    return access.map((a: any) => this.accessTranslate[a] || '-').join(', ');
  }

  private filterUsers(searchQuery: string | null): Observable<readonly any[]> {
    let result: any[] = this.primaryTableData.filter(
      (user: any) =>
        user.full_name
          .toLowerCase()
          .includes((searchQuery || '').toLowerCase()) ||
        user.username.toLowerCase().includes((searchQuery || '').toLowerCase())
    );
    result = this.sortedData(result);
    return of(result);
  }

  handleEditUser(user: any) {
    const editUserPopup = this.dialogService.open<any>(
      new PolymorpheusComponent(EditUserComponent, this.injector),
      { data: user, dismissible: false }
    );
    editUserPopup.subscribe({
      next: (data) => {
        if (data === true) {
          this.usersService.getList().subscribe((users: any) => {
            this.primaryTableData = this.sortedData(users);
            this.search$.next('');
          });
        }
      },
    });
  }

  onDeleteUser(user: any) {
    this.deletedUser = user;
    this.openDeletePopup = true;
  }

  updateUsers() {
    this.usersService.getList().subscribe((users: any) => {
      this.primaryTableData = this.sortedData(users);
      this.search$.next('');
    });
  }
}
