import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { TuiAlertService } from '@taiga-ui/core';
import { Subject, takeUntil } from 'rxjs';
import { ObjectService } from '../../../../services/object.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { tuiCeil, TuiDay, TuiDayRange } from '@taiga-ui/cdk';
import * as echarts from 'echarts';
import { DateTime } from 'luxon';
import { BoreholeService } from '../../../../services/borehole.service';
import { GzuService } from '../../../../services/gzu.service';

@Component({
  selector: 'app-borehole-card-debit-history',
  templateUrl: './borehole-card-debit-history.component.html',
  styleUrls: ['./borehole-card-debit-history.component.less'],
})
export class BoreholeCardDebitHistoryComponent implements OnInit, OnDestroy {
  @Input() objectDebitDeviceFields!: string[];

  @Input() objectId!: number | string;

  @Input() objectType!: string;

  public currentDay: any = new Date();

  public currentDate: number[] = [
    this.currentDay.getFullYear(),
    this.currentDay.getMonth(),
    this.currentDay.getDate(),
  ];

  public dayMonthAgo: any = new Date(
    this.currentDay.getTime() - 30 * 24 * 60 * 60 * 1000
  );

  public dateMonthAgo: number[] = [
    this.dayMonthAgo.getFullYear(),
    this.dayMonthAgo.getMonth(),
    this.dayMonthAgo.getDate(),
  ];

  public DateFormGroup: any = new FormGroup({
    date: new FormControl(
      new TuiDayRange(
        new TuiDay(
          this.dateMonthAgo[0],
          this.dateMonthAgo[1],
          this.dateMonthAgo[2]
        ),
        new TuiDay(
          this.currentDate[0],
          this.currentDate[1],
          this.currentDate[2]
        )
      ),
      [Validators.required]
    ),
  });

  public allDebitData: any[] = [];

  public tableColumns: any[] = [];

  public paginator = {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    perPage: 50,
  };

  public events: any[] = [];

  public isClicked: boolean = false;

  public destroyer = new Subject();

  public debitDeviceField: string[] = [];

  public lastPossibleDayToChoose = TuiDay.fromLocalNativeDate(new Date());

  constructor(
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
    private objectService: ObjectService,
    private boreholeService: BoreholeService,
    private gzuService: GzuService
  ) {}

  ngOnInit(): void {
    this.debitDeviceField = this.objectDebitDeviceFields;

    this.tableColumns = [
      { name: 'datetime', title: 'Дата' },
      {
        name: 'debit',
        title: `Дебит ${
          this.debitDeviceField && this.debitDeviceField.length
            ? this.debitDeviceField.find((v: string) =>
                ['M1', 'M2'].includes(v)
              )
              ? '(т)'
              : '(м3)'
            : ''
        }`,
      },
    ];
  }

  handleChangeDates() {
    this.isClicked = true;
    if (this.objectId) {
      this.objectService
        .getDebitHistory(
          +this.objectId,
          this.DateFormGroup.get('date').value.from.getFormattedDay('YMD', '-'),
          this.DateFormGroup.get('date').value.to.getFormattedDay('YMD', '-')
        )
        .pipe(takeUntil(this.destroyer))
        .subscribe((data: any) => {
          this.allDebitData = data.map((d: any) => {
            d.date = DateTime.fromFormat(d.date, 'yyyy-MM-dd').toFormat(
              'dd.MM.yyyy'
            );
            return d;
          });
          setTimeout(() => {
            this.renderGraph(
              this.allDebitData.map((d: any) => d.value),
              this.allDebitData.map((d: any) => d.date)
            );
          });
        });
    }
  }

  renderGraph(x: any, y: any) {
    let chartDom = document.getElementById('debit-history');
    if (chartDom) {
      let myChart = echarts.init(chartDom);
      let option;

      option = {
        xAxis: {
          type: 'category',
          data: y,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: x,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)',
            },
          },
        ],
      };
      myChart.setOption(option);
    }
  }

  getHeight(max: number): number {
    return (max / tuiCeil(max, -3)) * 100;
  }

  changePage($event: number) {
    this.paginator.currentPage = $event + 1;
    /*setTimeout(() => {
      this.loadEvents();
    }, 1000);*/
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
