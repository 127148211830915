<div
  [class]="
    !isEdit && !selectedObject.latitude && !selectedObject.longitude
      ? 'container-without-map'
      : 'container'
  "
>
  <form [formGroup]="form" class="form">
    <div class="inline-table">
      <div class="header">
        <span>Информация об объекте</span>
        <app-common-buttons
          [isEdit]="isEdit"
          (handelCancel)="cancel()"
          (handelOpen)="openDialogForDelete()"
          (handelChange)="changeInput()"
        >
        </app-common-buttons>
      </div>
      <div class="table">
        <div class="row" *ngFor="let row of formData">
          <div class="label">{{ row.name }}</div>
          <div class="value">
            <div *ngIf="isEdit" class="label__edit-input">
              <ng-container *ngIf="isSelectorType(row.control)">
                <app-common-selector
                  [data]="getCurrentArray(row.id)"
                  [content]="itemContent(row.id)"
                  [form]="form"
                  [name]="row.id"
                ></app-common-selector>
              </ng-container>
              <app-form-select-search
                *ngIf="isSearchSelectorType(row.control)"
                [form]="form"
                [formControlKey]="row.id"
                [visualRequired]="false"
                [isGrouped]="false"
                [items]="getCurrentArray(row.id)"
              >
              </app-form-select-search>
              <tui-input
                [tuiTextfieldMaxLength]="21"
                *ngIf="isInputType(row.control)"
                [formControlName]="row.id"
              >
              </tui-input>
              <tui-input-number
                *ngIf="isNumberInputType(row.control)"
                [precision]="14"
                [formControlName]="row.id"
              ></tui-input-number>
            </div>
            <tui-field-error
              *ngIf="row.id === 'name' && isEdit"
              formControlName="name"
            ></tui-field-error>
            <span *ngIf="!isEdit">{{ row.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div
    *ngIf="isEdit || (selectedObject.latitude && selectedObject.longitude)"
    class="map"
    [class._height-up]="isBorehole(idSelectedType)"
  >
    <app-map-object
      [onClickOnMap]="onClickOnMap"
      [changeMode]="isEdit"
      [status]="selectedObject.status"
      [latitude]="form.get('latitude')?.value"
      [longitude]="form.get('longitude')?.value"
      [currentIdObject]="idSelectedType"
    >
    </app-map-object>
  </div>
</div>
<app-common-delete-popup
  [confirm]="confirmObjectDelete"
  [changePopup]="isDeleteObjectDialog"
  [cancel]="cancelDeleteObject"
></app-common-delete-popup>
