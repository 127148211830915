<ng-container [formGroup]="form">
  <tui-radio-block
    [formControlName]="formControlKey"
    [nativeId]="nativeId"
    [size]="sizeBlock"
    [hideRadio]="hideRadio"
    [contentAlign]="contentAlign"
    [pseudoDisabled]="pseudoDisabled"
    [item]="item"
  >
    >
    {{ content }}
  </tui-radio-block>
</ng-container>
