import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TabsModel } from 'src/models/tabs.model';
import { TypeTree } from '../../../../models/tree.model';
import { ObjectService } from '../../../services/object.service';
import { OilfieldService } from '../../../services/oilfield.service';
import { ActivatedRoute } from '@angular/router';
/*для Андрея: почему же отельная компонента
- дело в context из-за его объявления в конструкторе
все крушиться (а без его context контекст не будет работать)*/
@Component({
  selector: 'app-ktp-card',
  templateUrl: './ktp-card.component.html',
  styleUrls: ['./ktp-card.component.less'],
})
export class KtpCardComponent implements OnInit {
  //id месторождения
  @Input()
  public objectID: string | null | number = null;

  @Output()
  handleSubmit: EventEmitter<null> = new EventEmitter();

  //данные выбранного объекта
  public selectedObject?: any | TypeTree = null;

  //список tab
  public tabs: TabsModel[] = [{ url: 'info', title: 'Информация' }];

  //выбранный tab
  public activeItemIndex: TabsModel = this.tabs[0];

  constructor(
    private objectsService: ObjectService,
    private oilfieldService: OilfieldService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  //взятие с роутера id месторождения и по нему запрос на получение данных
  ngOnInit() {
    if (!this.objectID) {
      this.objectID = this.route?.snapshot?.paramMap?.get('id') as string;
    }
    this.objectsService
      .getObjectByID(+this.objectID)
      .subscribe((deposit: TypeTree) => {
        this.selectedObject = deposit;
        this.changeDetectorRef.markForCheck();
      });
  }

  //смена навигационных tab
  changeTabElement(tab: TabsModel) {
    this.activeItemIndex = tab;
  }

  //при каком либо изменении запрос на получения свежих данных
  getEditableDeposit() {
    this.objectsService
      .getObjectByID(+(this.objectID as string))
      .subscribe((deposit: TypeTree) => {
        this.selectedObject = deposit;
        this.changeDetectorRef.markForCheck();
      });
    this.handleSubmit.emit();
  }
}
