<app-primary-section>
  <app-secondary-section>
    <form
      class="report-form"
      [formGroup]="reportForm"
      (submit)="handleSubmitReportForm()"
    >
      <tui-input-date-range
        tuiUnfinishedValidator="Заполните поле"
        formControlName="dates"
        class="report-dates-form"
        [maxLength]="{month: 3}"
      >
        Выберите даты для отчета
        <input tuiTextfield placeholder="От - до" />
      </tui-input-date-range>
      <tui-select
        class="report-select-form"
        [tuiTextfieldLabelOutside]="true"
        [valueContent]="reports ? stringify(reports) : loading"
        formControlName="report"
      >
        <ng-template tuiDataList>
          <tui-data-list *ngIf="reports; else loading">
            <button *ngFor="let item of reports" tuiOption [value]="item.value">
              {{ item.title }}
            </button>
          </tui-data-list>
        </ng-template>
        <ng-template #loading>
          <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
        </ng-template>
      </tui-select>
      <div class="button_container">
        <button
          tuiButton
          type="submit"
          appearance="primary"
          class="tui-space_right-3 tui-space_bottom-1"
        >
          Сформировать отчет
        </button>
        <button
          tuiButton
          type="button"
          (click)="exportReport()"
          appearance="primary"
          class="tui-space_right-3 tui-space_bottom-1"
        >
          Сформировать и выгрузить в Excel
        </button>
      </div>
    </form>
  </app-secondary-section>
</app-primary-section>
<tui-loader
  class="inline-flex tui-space_right-2"
  [showLoader]="isSpinnerVisible"
  [inheritColor]="true"
  [overlay]="true"
  size="xl"
>
  <app-primary-section
    *ngIf="
      reportForm.value.report === 'connection' &&
      this.reportData.length > 0 &&
      !isSpinnerVisible
    "
  >
    <ng-container>
      <div class="scroll">
        <table class="report-table" border="1">
          <thead>
            <tr>
              <th class="width-33-per">Скважина</th>
              <th class="width-33-per">Месторождение</th>
              <th class="width-33-per"></th>
              <th class="width-90-px-fixed" *ngFor="let day of dates">
                {{ day }}
              </th>
              <th class="width-90-px-fixed">Итого</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let borehole of reportData">
              <tr>
                <td rowspan="2">{{ borehole.borehole }}</td>
                <td rowspan="2">{{ borehole.deposit }}</td>
                <td>Время отсутствия связи</td>
                <td
                  class="width-90-px-fixed"
                  *ngFor="let info of borehole.info"
                >
                  {{ getWorkTime(info["no_connection_time"]) }}
                </td>
                <td class="width-90-px-fixed">
                  {{ getWorkTime(getSum(borehole.info, "no_connection_time")) }}
                </td>
              </tr>
              <tr>
                <td>Режим работы</td>
                <td
                  class="width-90-px-fixed"
                  *ngFor="let info of borehole.info"
                >
                  <app-report-bar
                    [reportName]="reportForm.value.report"
                    [timePeriods]="info['working_mode']"
                  ></app-report-bar>
                </td>
                <td class="width-90-px-fixed"></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
  </app-primary-section>
  <app-primary-section
    *ngIf="
      reportForm.value.report === 'measurement' &&
      this.reportData.length > 0 &&
      !isSpinnerVisible
    "
  >
    <ng-container>
      <div class="scroll">
        <table class="report-table" border="1">
          <thead>
            <tr>
              <th class="width-33-per">Скважина</th>
              <th class="width-33-per">Месторождение</th>
              <th class="width-33-per"></th>
              <th class="width-90-px-fixed" *ngFor="let day of dates">
                {{ day }}
              </th>
              <th class="width-90-px-fixed">Итого</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let borehole of reportData">
              <tr>
                <td rowspan="6">{{ borehole.borehole }}</td>
                <td rowspan="6">{{ borehole.deposit }}</td>
                <td>Дебит</td>
                <td
                  class="width-90-px-fixed"
                  *ngFor="let info of borehole.info"
                >
                  {{ info["debit"] !== null ? info["debit"] : "-" }}
                </td>
                <td class="width-90-px-fixed">
                  {{
                    getSum(borehole.info, "debit")
                      ? (getSum(borehole.info, "debit") | number: ".0-2")
                      : "-"
                  }}
                </td>
              </tr>

              <tr>
                <td>Частота</td>
                <td
                  class="width-90-px-fixed"
                  *ngFor="let info of borehole.info"
                >
                  {{ info.frequency !== null ? info.frequency : "-" }}
                </td>

                <!-- <td style="text-align: center">
                  {{
                    getSum(borehole.info, "frequency") !== null
                      ? (getSum(borehole.info, "frequency") | number: ".0-2")
                      : "-"
                  }}
                </td> -->
              </tr>

              <tr>
                <td>Время работы</td>
                <td
                  class="width-90-px-fixed"
                  *ngFor="let info of borehole.info"
                >
                  {{ getWorkTime(info["work_time"]) }}
                </td>
                <td class="width-90-px-fixed">
                  {{ getWorkTime(getSum(borehole.info, "work_time")) }}
                </td>
              </tr>
              <tr>
                <td>Время простоя</td>
                <td
                  class="width-90-px-fixed"
                  *ngFor="let info of borehole.info"
                >
                  {{ getWorkTime(info["stoppage_time"]) }}
                </td>
                <td class="width-90-px-fixed">
                  {{ getWorkTime(getSum(borehole.info, "stoppage_time")) }}
                </td>
              </tr>
              <tr>
                <td>Количество остановок</td>
                <td
                  class="width-90-px-fixed"
                  *ngFor="let info of borehole.info"
                >
                  {{
                    info["stops_number"] !== null ? info["stops_number"] : "-"
                  }}
                </td>
                <td class="width-90-px-fixed">
                  {{
                    getSum(borehole.info, "stops_number") !== null
                      ? (getSum(borehole.info, "stops_number") | number: ".0-2")
                      : "-"
                  }}
                </td>
              </tr>
              <tr>
                <td>Режим работы</td>
                <td
                  class="width-90-px-fixed"
                  *ngFor="let info of borehole.info"
                >
                  <app-report-bar
                    [reportName]="reportForm.value.report"
                    [timePeriods]="info['working_mode']"
                  ></app-report-bar>
                </td>
                <td class="width-90-px-fixed"></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
  </app-primary-section>
</tui-loader>
<!--<app-report-bar [timePeriods]="testTimePeriods"></app-report-bar>-->
