import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-table-column-settings",
  templateUrl: "./table-column-settings.component.html",
  styleUrls: ["./table-column-settings.component.less"],
})
export class TableColumnSettingsComponent implements OnInit {
  @Input() columns: any[] = [];

  @Input() disabled: boolean = true;

  @Output() changeTableColumns: EventEmitter<any> = new EventEmitter<any>();

  public selectedColumns: any = [];

  ngOnInit(): void {
    this.selectedColumns = this.columns;
  }

  changeColumns() {
    this.changeTableColumns.emit(this.selectedColumns);
  }
}
