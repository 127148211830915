import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { TokenService } from '../services/token.service';
import { DesignService } from '../services/design.service';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard implements CanActivate {
  constructor(
    private tokenService: TokenService,
    private router: Router,
    private designService: DesignService
  ) {}

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.tokenService.getToken()) {
        this.router.navigate(['/login/']).then(() => true);
      } else {
        if (!this.designService.isLoaded) {
          this.designService.loadColors().then(() => {
            resolve(true);
          });
        }
      }
    });
  }
}
