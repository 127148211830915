import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TuiDecimalT, TuiSizeL, TuiSizeS } from "@taiga-ui/core";

@Component({
  selector: "app-form-input-number",
  templateUrl: "./form-input-number.component.html",
  styleUrls: ["./form-input-number.component.less"],
})
export class FormInputNumberComponent {
  @Input()
  form!: FormGroup;

  @Input()
  formControlKey!: string;
  // тут мог быть еще number и null типы, но только string потому что в form-attr-control.service.ts group[formAttr.key]

  @Input()
  min!: number;

  @Input()
  max!: number;

  @Input()
  prefix!: string;

  @Input()
  postfix!: string;

  @Input()
  precision!: number;

  @Input()
  decimal!: TuiDecimalT;

  @Input()
  sizeBlock!: TuiSizeS | TuiSizeL;

  @Input()
  label!: string;

  @Input()
  visualRequired!: boolean;

  @Input()
  nativeId!: string;

  @Input()
  tuiTextfieldMaxLength!: number | null;

  @Input()
  tuiTextfieldExampleText!: string;
}
