import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { BADGE_TRANSLATE, TYPE_EVENT_TRANSLATE } from '../../const/app-consts';
import { EventModel } from '../../../models/event.model';
import { EventService } from '../../services/event.service';
import * as luxon from 'luxon';
import { TabsModel } from '../../../models/tabs.model';
import { PressureService } from '../../services/pressure.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.less'],
})
export class EventsComponent implements OnInit, OnDestroy {
  @Input()
  perPage: number = 50;

  @Input()
  inner: boolean = false;

  public events: any = [];

  public tabs: TabsModel[] = [
    { url: 'borehole-events', title: 'События по состоянию скважин' },
    { url: 'pressure-events', title: 'События по датчикам давления' },
  ];

  public activeItemIndex: TabsModel = this.tabs[0];

  public isLoading: boolean = true;

  public paginator = {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    perPage: 50,
  };

  public filter: any = {};

  public boreholeColumns: any[] = [
    { name: 'index', title: '№' },
    { name: 'datetime', title: 'Дата и время' },
    { name: 'name', title: 'Название объекта' },
    { name: 'deposit_name', title: 'Название месторождения' },
    { name: 'message', title: 'Сообщение' },
    { name: 'status', title: 'Статус' },
    { name: 'react', title: 'Реакция' },
  ];

  public pressureColumns: any[] = [
    { name: 'index', title: '№' },
    { name: 'datetime', title: 'Дата и время' },
    { name: 'name', title: 'Название датчика' },
    { name: 'deposit_name', title: 'Название месторождения' },
    { name: 'message', title: 'Сообщение' },
    { name: 'react', title: 'Реакция' },
  ];

  public statusTitles: any = {
    ok: BADGE_TRANSLATE.ok,
    stop: BADGE_TRANSLATE.stop,
    no_connection: BADGE_TRANSLATE.no_connection,
  };

  public statusMessages: any = {
    stop_to_ok: TYPE_EVENT_TRANSLATE.stop_to_ok,
    ok_to_stop: TYPE_EVENT_TRANSLATE.ok_to_stop,
    stop_to_no_connection: TYPE_EVENT_TRANSLATE.stop_to_no_connection,
    ok_to_no_connection: TYPE_EVENT_TRANSLATE.ok_to_no_connection,
    no_connection_to_stop: TYPE_EVENT_TRANSLATE.no_connection_to_stop,
    no_connection_to_ok: TYPE_EVENT_TRANSLATE.no_connection_to_ok,
    max_error: TYPE_EVENT_TRANSLATE.max_error,
    max_warning: TYPE_EVENT_TRANSLATE.max_warning,
    min_error: TYPE_EVENT_TRANSLATE.min_error,
    min_warning: TYPE_EVENT_TRANSLATE.min_warning,
    normal: TYPE_EVENT_TRANSLATE.normal,
  };

  public isCleanAllEvents: boolean = false;

  public isFilterOpen: boolean = false;

  private destroyer: Subject<any> = new Subject<any>();

  public updateRequestSubject: Subject<any> = new Subject<any>();

  constructor(
    private appService: AppService,
    private eventService: EventService,
    private pressureService: PressureService
  ) {}

  ngOnInit() {
    this.eventService.eventFilterMode.next('borehole-events');
    this.paginator.perPage = this.perPage;
    this.appService.unreadEvents
      .pipe(takeUntil(this.destroyer))
      .subscribe(() => {
        setTimeout(() => {
          this.loadEvents();
        });
      });
    if (!this.inner) {
      this.appService.title.next('События');
    }
  }

  changeTabElement(tab: TabsModel) {
    this.activeItemIndex = tab;
    this.eventService.eventFilterMode.next(tab.url);
    this.events = [];
    this.filter = {};
    this.paginator.currentPage = 1;
    this.loadEvents();
  }

  loadEvents() {
    this.isLoading = true;
    if (this.activeItemIndex.url === 'borehole-events') {
      this.eventService
        .eventsList(
          this.paginator.currentPage,
          this.paginator.perPage,
          this.filter
        )
        .subscribe((eventTable: EventModel) => {
          this.events = eventTable.data;
          this.paginator.lastPage = eventTable.last_page;
          this.paginator.count = eventTable.count;
          this.isLoading = false;
          this.updateRequestSubject.next('update');
        });
    }

    if (this.activeItemIndex.url === 'pressure-events') {
      this.pressureService
        .getPressureEventsList(
          this.paginator.currentPage,
          this.paginator.perPage,
          this.filter
        )
        .subscribe((eventTable: EventModel) => {
          this.events = eventTable.data;
          this.paginator.lastPage = eventTable.last_page;
          this.paginator.count = eventTable.count;
          this.isLoading = false;
          this.updateRequestSubject.next('update');
        });
    }
  }

  markReadEventsByObject() {
    this.isCleanAllEvents = true;
  }

  markReadEvent(event: any) {
    if (this.activeItemIndex.url === 'borehole-events') {
      this.eventService.eventUpdate(event.id).subscribe(() => {
        event.react_at = luxon.DateTime.utc().toISO().slice(0, -1);
      });
    }

    if (this.activeItemIndex.url === 'pressure-events') {
      this.pressureService
        .markOnePressureEventAsRead(event.id)
        .subscribe(() => {
          event.react_at = luxon.DateTime.utc().toISO().slice(0, -1);
        });
    }
  }

  changePage($event: number) {
    this.isLoading = true;
    this.paginator.currentPage = $event + 1;
    setTimeout(() => {
      this.loadEvents();
      if (!this.inner) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  }

  getFormattedDate(react_at: any) {
    return luxon.DateTime.fromISO(`${react_at}Z`).toFormat(
      'dd.MM.yyyy HH:mm:ss'
    );
  }

  identify(index: any, item: { id: any }) {
    return item.id;
  }

  cancelPopup() {
    this.isCleanAllEvents = false;
  }

  confirmCleanEvents() {
    if (this.activeItemIndex.url === 'borehole-events') {
      this.eventService.readAllEvent().subscribe((cleaningStatus: boolean) => {
        if (cleaningStatus) {
          this.isLoading = true;
          this.appService.watchCountUnreadNotifications.next(null);
          setTimeout(() => {
            this.loadEvents();
          });
        }
      });
    }

    if (this.activeItemIndex.url === 'pressure-events') {
      this.pressureService
        .markAbsolutelyAllPressureEventsAsRead()
        .subscribe((cleaningStatus: boolean) => {
          if (cleaningStatus) {
            this.isLoading = true;
            this.appService.watchCountUnreadNotifications.next(null);
            setTimeout(() => {
              this.loadEvents();
            });
          }
        });
    }
    this.isCleanAllEvents = false;
  }

  onCloseFilter() {
    this.isFilterOpen = false;
  }

  onFilter($event: any) {
    this.isLoading = true;
    this.paginator.currentPage = 1;
    this.filter = $event;

    setTimeout(() => {
      this.loadEvents();
    });
  }

  filterIsMark() {
    return Object.keys(this.filter).length > 0;
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
