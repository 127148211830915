<ng-container [formGroup]="form">
  <tui-input-password
    [formControlName]="formControlKey"
    [nativeId]="nativeId"
    [tuiTextfieldMaxLength]="tuiTextfieldMaxLength"
    [tuiTextfieldExampleText]="tuiTextfieldExampleText"
  >
    {{ label }}
    <span *ngIf="visualRequired" class="tui-required"></span>
  </tui-input-password>
  <tui-field-error [formControlName]="formControlKey"></tui-field-error>
</ng-container>
