<ng-template
  let-observer
  [tuiDialogOptions]="$any({ label: 'Удаление объекта', size: 's' })"
  [(tuiDialog)]="changePopup"
>
  <p>Вы уверены, что хотите удалить объект?</p>
  <p>При удалении объекта, все дочерние объекты будут так же удалены.</p>
  <div class="tui-form__buttons _space-between">
    <button
      tuiButton
      class="tui-space_top-5"
      type="button"
      size="s"
      (click)="confirm()"
    >
      Подтвердить удаление объекта
    </button>
    <button
      tuiButton
      class="tui-space_top-5"
      type="button"
      size="s"
      appearance="whiteblock-active"
      (click)="cancel()"
    >
      Отмена
    </button>
  </div>
</ng-template>
