<app-primary-section>
  <h1 style="text-align: center; margin-bottom: 0">
    Редактирование пользователя
  </h1>
</app-primary-section>
<app-primary-section>
  <form
    class="user-add-form"
    [formGroup]="editUserForm"
    (submit)="handleSubmitEditUser()"
  >
    <div class="form-row">
      <tui-input formControlName="username">
        Логин
        <input tuiTextfield type="text" />
      </tui-input>
    </div>
    <div class="form-row">
      <tui-input formControlName="full_name">
        ФИО
        <input tuiTextfield type="text" />
      </tui-input>
    </div>
    <div class="form-row">
      <tui-input formControlName="phone">
        Номер телефона
        <input tuiTextfield type="text" />
      </tui-input>
    </div>
    <div class="form-row">
      <tui-input formControlName="duty">
        Должность
        <input tuiTextfield type="text" />
      </tui-input>
    </div>
    <div class="form-row">
      <tui-multi-select
        *tuiLet="access$ | async as access"
        class="b-form"
        formControlName="access"
        [tuiTextfieldLabelOutside]="true"
        [stringify]="(stringify$ | async)!"
        (searchChange)="onSearch($event)"
        >Права доступа
        <tui-data-list-wrapper
          *tuiDataList
          tuiMultiSelectGroup
          [items]="access"
          [itemContent]="(stringify$ | async)!"
        ></tui-data-list-wrapper>
      </tui-multi-select>
    </div>
    <div class="form-row">
      <tui-input formControlName="comment">
        Комментарий
        <input tuiTextfield type="text" />
      </tui-input>
    </div>
    <button
      tuiButton
      type="submit"
      appearance="primary"
      class="tui-space_right-3 tui-space_bottom-3"
    >
      Сохранить
    </button>
  </form>
</app-primary-section>
