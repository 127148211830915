<div class="base-layout">
  <div class="base-layout__navigation">
    <app-header>
      <app-navigation></app-navigation>
    </app-header>
  </div>
  <div class="base-layout__container">
    <div class="base-layout__container-cell _nav">
      <app-plus></app-plus>
    </div>
    <div class="base-layout__container-cell _main">
      <app-plus></app-plus>
    </div>
    <div class="base-layout__container-cell _footer">
      <app-plus></app-plus>
    </div>
  </div>
</div>
