<form [formGroup]="formType">
  <div class="tui-form__row">
    <tui-select
      formControlName="type"
      tuiMode="onLight"
      (ngModelChange)="changeNameValidator()"
      [valueContent]="typeValueContent"
    >
      <span class="tui-required">Выберите тип объекта</span>
      <tui-data-list-wrapper
        *tuiDataList
        [items]="typeObjects"
        [itemContent]="valueContent"
      >
      </tui-data-list-wrapper>
    </tui-select>
    <ng-template #typeValueContent let-data>
      {{ data.title }}
    </ng-template>
    <ng-template #valueContent let-data>
      {{ data.title }}
    </ng-template>
    <tui-field-error formControlName="type"></tui-field-error>
  </div>
</form>
<form *ngIf="reloadForm" (ngSubmit)="saveObject()" [formGroup]="formAddObject">
  <app-add-object-fields
    *ngIf="hasSelectedType()"
    [formGroup]="formAddObject"
    [fieldSettings]="nameSetting"
  >
  </app-add-object-fields>
  <ng-container *ngIf="whiteList.includes(formType.get('type')?.value.type)">
    <div class="tui-form__row">
      <app-form-select-search
        [form]="formAddObject"
        formControlKey="parent"
        label="Родительский объект"
        [visualRequired]="false"
        [isGrouped]="false"
        [items]="parents"
        [style]="GRAY"
      >
      </app-form-select-search>
      <tui-field-error
        *ngIf="this.formAddObject.get('parent')?.touched"
        style="margin-bottom: 15px"
        formControlName="parent"
      ></tui-field-error>
    </div>
    <app-location-fields
      [form]="formAddObject"
      (handleChange)="setCoordinate($event)"
    ></app-location-fields>
  </ng-container>
  <app-borehole-fields
    *ngIf="isTypeBorehole()"
    [form]="formAddObject"
  ></app-borehole-fields>
  <div
    class="add-object__map"
    *ngIf="whiteList.includes(formType?.get('type')?.value.type)"
  >
    <app-map-object
      [onClickOnMap]="onClickOnMap"
      [latitude]="latitude"
      [longitude]="longitude"
      [currentIdObject]="stateChangedType()"
    >
    </app-map-object>
  </div>
  <div class="tui-form__buttons _space-between">
    <button class="tui-space_top-3" tuiButton size="m" type="submit">
      Сохранить
    </button>
    <button
      tuiButton
      class="tui-space_top-3"
      type="button"
      size="m"
      appearance="whiteblock-active"
      (click)="closeAddObjectDialog()"
    >
      Отмена
    </button>
  </div>
</form>
