import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map, Subject } from 'rxjs';

export type OilfieldType = {
  id: number;
  parent_id: number;
  name: string;
  type: string;
  order: number;
  latitude: number;
  longitude: number;
  attributes: null;
  status: {
    boreholes: {
      no_connection: number;
      stop: number;
      ok: number;
      not_count: number;
    };
  };
  scheme: {};
  params: [];
  children: any[];
};

export type BoreholeStatusTypeOptions =
  | 'no_connection'
  | 'stop'
  | 'ok'
  | 'not_count'
  | null;

export type ShorterOilfieldType = {
  id: number;
  name: string;
  children: any[];
};

@Injectable({
  providedIn: 'root',
})
export class OilfieldService {
  //public boreholesStatus: Subject<BoreholeStatusType | null> =
  //  new Subject<BoreholeStatusType | null>();

  public boreholesStatus: Subject<BoreholeStatusTypeOptions> =
    new Subject<BoreholeStatusTypeOptions>();

  public oilfieldsList: Subject<any[]> = new Subject<any[]>();

  //immutable array with initial oilfields
  public oilfieldsIdAndNames: Subject<{ id: number; name: string }[]> =
    new Subject<{ id: number; name: string }[]>();

  public boreholesStatusCount: Subject<any> = new Subject<any>();

  //mutable array with selected oilfields
  public selectedOilfield: Subject<{ id: number; name: string }[]> =
    new Subject<{ id: number; name: string }[]>();

  constructor(public http: HttpClient) {}

  getOilfieldsList() {
    return this.http
      .get(`${environment.apiUrl}objects/flat_list/deposits/`)
      .pipe(
        map((response: any) => {
          this.oilfieldsList.next(response.list);
          return response.list;
        })
      );
  }
}
