<div class="table-sort">
  <div class="direction">
    <button
      tuiIconButton
      appearance="flat"
      size="s"
      type="button"
      (click)="changeDirection()"
      [icon]="direction === 'asc' ? 'tuiIconArrowDown' : 'tuiIconArrowUp'"
    ></button>
  </div>
  <div class="sort-field">
    <tui-hosted-dropdown [content]="content" [(open)]="sortDropdownOpen">
      <span
        >Сортировать по: <strong>{{ sortField?.title || "-" }}</strong></span
      >
    </tui-hosted-dropdown>
    <ng-template #content>
      <tui-data-list role="menu">
        <a
          *ngFor="let item of columns"
          tuiOption
          role="menuitemradio"
          [attr.aria-checked]="sortField.name === item.name"
          (click)="selectSortField(item)"
        >
          {{ item.title }}
          <tui-svg
            *ngIf="sortField.name === item.name"
            src="tuiIconCheckLarge"
          ></tui-svg>
        </a>
      </tui-data-list>
    </ng-template>
  </div>
</div>
