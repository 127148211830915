import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { map, Observable, Subject } from 'rxjs';
import {
  TypePowerLineListModel,
  TypePowerLineModel,
} from '../../models/power-line.model';

@Injectable({
  providedIn: 'root',
})
export class PowerLinesService {
  // Массив для кэширования списка ЛЭП
  private powerLineListCache: TypePowerLineModel[] = [];

  // Subject для информирования компонентов об изменениях в массиве ЛЭП
  public powerLineListSubject: Subject<TypePowerLineModel[]> = new Subject<
    TypePowerLineModel[]
  >();

  constructor(private http: HttpClient) {}

  get powerLinesList(): Promise<TypePowerLineModel[]> {
    return new Promise((resolve) => {
      if (this.powerLineListCache.length) {
        resolve(this.powerLineListCache);
      } else {
        return this.getPowerLineList().subscribe(
          (res: TypePowerLineModel[]) => {
            resolve(res);
          }
        );
      }
      return;
    });
  }

  getPowerLineList(): Observable<any> {
    return this.http
      .get<{ list: TypePowerLineModel[] }>(`${environment.apiUrl}lep/list/`)
      .pipe(
        map((response: { list: TypePowerLineModel[] }) => {
          this.powerLineListCache = response.list;
          this.powerLineListSubject.next(this.powerLineListCache);
          return response.list;
        })
      );
  }

  createPowerLine(
    powerLineBody: TypePowerLineListModel
  ): Observable<TypePowerLineListModel> {
    return this.http
      .post<TypePowerLineModel>(
        `${environment.apiUrl}lep/create/`,
        powerLineBody
      )
      .pipe(
        map((response: TypePowerLineModel) => {
          this.powerLineListCache.push(response);
          this.powerLineListSubject.next(this.powerLineListCache);
          return response;
        })
      );
  }

  updatePowerLine(powerLineId: number, powerLineBody: TypePowerLineListModel) {
    return this.http
      .put<TypePowerLineModel>(
        `${environment.apiUrl}lep/update/${powerLineId}/`,
        powerLineBody
      )
      .pipe(
        map((response: TypePowerLineModel) => {
          this.powerLineListCache = this.powerLineListCache.map(
            (item: TypePowerLineModel) => {
              return item.id === response.id ? response : item;
            }
          );
          this.powerLineListSubject.next(this.powerLineListCache);
          return response;
        })
      );
  }

  deletePowerLine(lineId: number) {
    return this.http
      .delete<TypePowerLineModel>(`${environment.apiUrl}lep/delete/${lineId}/`)
      .pipe(
        map((response: TypePowerLineModel) => {
          this.powerLineListCache = this.powerLineListCache.filter(
            (item: TypePowerLineModel) => {
              return item.id !== response.id;
            }
          );
          this.powerLineListSubject.next(this.powerLineListCache);
          return response;
        })
      );
  }

  getPowerLineByID(id: number): Observable<any> {
    return this.http
      .get<{ info_object: TypePowerLineListModel }>(
        `${environment.apiUrl}lep/info/${id}/`
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  currentType = [{ value: 'Постоянный' }, { value: 'Переменный' }];

  layingType = [
    { value: 'По опорам' },
    { value: 'В грунте' },
    { value: 'Канализация' },
    { value: 'Эстакада' },
  ];
}
