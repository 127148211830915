import { NgModule } from "@angular/core";
import { UiKitComponent } from "./ui-kit.component";
import { UiKitRoutingModule } from "./ui-kit-routing.module";
import { CommonModule } from "../common.module";

@NgModule({
  imports: [UiKitRoutingModule, CommonModule],
  declarations: [UiKitComponent],
  exports: [UiKitComponent],
})
export class UIKitModule {}
