import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PipelineColorType } from '../../../../../models/pipeline.model';

@Component({
  selector: 'app-draw-color-lines',
  templateUrl: './draw-color-lines.component.html',
  styleUrls: ['./draw-color-lines.component.less'],
})
export class DrawColorLinesComponent implements OnInit {
  public colorPolyline = PipelineColorType;

  public currentTypePolylineColor: string = PipelineColorType.DEFAULT;

  @Output()
  handleClick: EventEmitter<string> = new EventEmitter<string>();

  // При инициализации компонента выбираем значение по умолчанию - нефтепровод
  ngOnInit() {
    this.changePolyLineColor(PipelineColorType.OIL);
  }

  changePolyLineColor(color: string) {
    this.currentTypePolylineColor = color;
    this.handleClick.emit(color);
  }
}
