<app-primary-section>
  <tui-loader
    [showLoader]="isLoading"
    [inheritColor]="isLoading"
    [overlay]="isLoading"
    [size]="'xl'"
  >
    <app-primary-table
      [isSort]="false"
      [columns]="tableColumns"
      [disabled]="false"
      [updateRequestSubject]="updateRequestSubject"
    >
      <button
        additionalButtons
        tuiIconButton
        appearance="flat"
        [tuiHint]="tooltip"
        type="button"
        (click)="markReadEventsByObject()"
      >
        <i-tabler name="mail-opened"></i-tabler>
      </button>
      <ng-container *ngIf="events.length === 0 && !isLoading">
        <tr>
          <td [attr.colspan]="tableColumns.length + 1">
            <div class="no_data">Нет событий</div>
          </td>
        </tr>
      </ng-container>

<!--      <ng-container *ngIf="isLoading">-->
<!--        <tr>-->
<!--          <td [attr.colspan]="tableColumns.length + 1">-->
<!--            <tui-loader [inheritColor]="true" [size]="'xl'"> </tui-loader>-->
<!--          </td>-->
<!--        </tr>-->
<!--      </ng-container>-->

      <ng-container *ngIf="events.length > 0 && !isLoading">
        <tr *ngFor="let row of events; index as i">
          <td>{{ i + 1 + (paginator.currentPage - 1) * paginator.perPage }}</td>
          <td>{{ getFormattedDate(row.created_at) }}</td>
          <td>
            {{ statusMessages[row.type] }}
          </td>
          <td>
            <tui-badge
              *ngIf="row.status; else emptyStatus"
              [value]="statusTitles[row.status]"
              [attr.data-status]="row.status"
              class="status"
            >
            </tui-badge>
            <ng-template #emptyStatus>-</ng-template>
          </td>
          <td *ngIf="row.react_at">{{ getFormattedDate(row.react_at) }}</td>
          <td *ngIf="!row.react_at">
            <button (click)="markReadEvent(row)" class="read-event">
              Прочитано
            </button>
          </td>
          <td></td>
        </tr>
      </ng-container>
    </app-primary-table>
  </tui-loader>
  <tui-pagination
    class="events-paginator"
    *ngIf="events.length > 0 && paginator.lastPage !== 1"
    [activePadding]="1"
    [length]="paginator.lastPage"
    (indexChange)="changePage($event)"
    [index]="paginator.currentPage - 1"
  ></tui-pagination>
</app-primary-section>
<ng-template #tooltip>
  Отметить все события по объекту как прочитанные
</ng-template>
