<ng-container [formGroup]="form" *ngIf="!isGrouped">
  <tui-select
    [formControlName]="formControlKey"
    [nativeId]="nativeId"
    [tuiTextfieldMaxLength]="tuiTextfieldMaxLength"
    [tuiTextfieldExampleText]="tuiTextfieldExampleText"
    [valueContent]="itemContent"
  >
    {{ label }}
    <span *ngIf="visualRequired" class="tui-required"></span>
    <ng-template tuiDataList>
      <tui-data-list>
        <button *ngFor="let item of items" tuiOption [value]="item.value">
          {{ item.label }}
        </button>
      </tui-data-list>
    </ng-template>
  </tui-select>
  <tui-field-error [formControlName]="formControlKey"></tui-field-error>
</ng-container>

<ng-container [formGroup]="form" *ngIf="isGrouped">
  <tui-select
    class="b-form"
    [formControlName]="formControlKey"
    [nativeId]="nativeId"
    [tuiTextfieldMaxLength]="tuiTextfieldMaxLength"
    [tuiTextfieldExampleText]="tuiTextfieldExampleText"
    [valueContent]="itemContent"
  >
    {{ label }}
    <span *ngIf="visualRequired" class="tui-required"></span>
    <tui-data-list *tuiDataList>
      <tui-opt-group *ngFor="let group of getGroups" [label]="group">
        <button
          *ngFor="let item of getItemsByGroup(group)"
          tuiOption
          [value]="item.value"
        >
          {{ item.label }}
        </button>
      </tui-opt-group>
    </tui-data-list>
  </tui-select>
  <tui-field-error [formControlName]="formControlKey"></tui-field-error>
</ng-container>
