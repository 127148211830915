import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import { TypeTree } from "../../../models/tree.model";
import * as _ from "lodash";
import { ObjectService } from "src/app/services/object.service";

@Component({
  selector: "app-tree",
  templateUrl: "./tree.component.html",
  styleUrls: ["./tree.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeComponent implements OnInit, OnChanges {
  /**
   * данные по дереву с родителькой компоненты. Начальное состояние null*/
  @Input()
  treeData: any = null;

  @Input()
  schemeMode: boolean = false;

  /**
   * включение поиска*/
  @Input()
  hasSearchBlock: boolean = false;

  @Input()
  hasStatusBlock: boolean = true;

  originTreeData: any = null;

  constructor(
    private changeDetector: ChangeDetectorRef,
    public objectService: ObjectService
  ) {}

  ngOnInit(): void {
    this.originTreeData = this.treeData;
  }

  ngOnChanges(changes: any) {
    this.originTreeData = changes.treeData.currentValue;
  }

  /**
   * обновление дерева по инпуту поиска  */
  changeTree(searchValue: string) {
    this.treeData = _.cloneDeep(this.originTreeData);
    if (searchValue.length >= 3) {
      this.treeData = this.filterTreeBySearchValue(searchValue, this.treeData);
    }
    this.changeDetector.markForCheck();
  }

  /**
   * святая рекурсия по дереву в конце которого возвращается дерево */
  filterTreeBySearchValue(
    searchValue: string,
    tree: Array<TypeTree>
  ): Array<TypeTree> {
    tree = this.filterTreeArray(tree, searchValue);
    tree.map((item, index) => {
      if (item.children) {
        tree[index].children = this.filterTreeBySearchValue(
          searchValue,
          item.children
        );
      }
    });
    tree = this.filterTreeArray(tree, searchValue);
    return tree;
  }

  /**
   *  фильтрация по условию того что дети есть и есть совпадение в слове*/
  filterTreeArray(tree: Array<TypeTree>, searchValue: string): Array<TypeTree> {
    return tree.filter((node) => {
      if (
        node.children &&
        !node.children.length &&
        !this.coincidence(searchValue, node.name)
      ) {
        return;
      } else {
        return node;
      }
    });
  }

  coincidence(searchValue: string, nodeValue: string) {
    return nodeValue
      .toLocaleLowerCase()
      .includes(searchValue.toLocaleLowerCase());
  }
}
