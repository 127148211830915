import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { PressureService } from 'src/app/services/pressure.service';
import { TypeTree } from '../../../../../models/tree.model';
import { ObjectService } from '../../../../services/object.service';
import { Subject, takeUntil } from 'rxjs';
import { TuiContextWithImplicit, tuiPure } from '@taiga-ui/cdk';
import {
  DeviceMeterType,
  RequestPressureType,
} from '../../../../../models/pressure-meter.model';

//проверка на пробелы
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

export function minValueValidator(context: { min: number }): string {
  return `Значение должно быть не меньше ${context.min}`;
}

export function maxValueValidator(context: { max: number }): string {
  return `Значение должно быть не больше ${context.max}`;
}

@Component({
  selector: 'app-pressure-sensor-add',
  templateUrl: './pressure-sensor-add.component.html',
  styleUrls: ['./pressure-sensor-add.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class PressureSensorAddComponent implements OnInit, OnDestroy {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  // возвращает созданное месторождение
  @Output() requestCreatedData: EventEmitter<null> = new EventEmitter();

  //для формы -> список скважин
  public depositList: any = [];

  // Список доступных устройств (датчиков давления) для формы
  public deviceList: any = [];

  //форма для имени месторождения
  public formPressureSensor: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    deposit: new FormControl(null, [Validators.required]),
    device: new FormControl('', [Validators.required]),
    device_type: new FormControl({ value: null, disabled: true }, [
      Validators.required,
    ]),
    limits: new FormControl('', []),
    // Объединяем лимиты в отдельную группу
    limitsGroup: new FormGroup({
      min_warning: new FormControl('', [Validators.required]),
      min_error: new FormControl('', [Validators.required]),
      max_warning: new FormControl('', [Validators.required]),
      max_error: new FormControl('', [Validators.required]),
    }),
    latitude: new FormControl('', [
      Validators.required,
      Validators.min(-90),
      Validators.max(90),
    ]),
    longitude: new FormControl('', [
      Validators.required,
      Validators.min(-180),
      Validators.max(180),
    ]),
  });

  //клик по карте(по нему в onInit записываем координаты клика по карте)
  public onClickOnMap = new EventEmitter();

  public latitude: number = 55;

  public longitude: number = 55;

  // Переменная для изменения названия полей для ввода лимитов в зависимости от того, активны ли они
  public limitsFormActive = false;

  public skeletonVisible: boolean = true;

  @tuiPure
  stringify(items: readonly any[]): any {
    const map = new Map(
      items.map(({ id, name }) => [id, name] as [number, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }

  public pressureSensorType: any[] = [];

  private destroyer: Subject<any> = new Subject<any>();

  constructor(
    public objectService: ObjectService,
    public pressureService: PressureService
  ) {}

  async ngOnInit() {
    // Получаем список месторождений для формирования выпадающего списка в форме
    const deposits = await this.objectService.deposits;

    // Получаем список устройств для формирования выпадающего списка в форме
    const devices = await this.pressureService.devices;

    // Очищаем объекты месторождений от ненужных нам полей
    this.depositList = deposits.map((item: TypeTree) => {
      return { id: item.id, name: item.name };
    });

    // Очищаем объекты устройств (типов датчиков давления) от ненужных нам полей для корректной работы выпадающего списка
    this.deviceList = devices.map((item: DeviceMeterType) => {
      return { id: item.id, name: item.name };
    });

    this.skeletonVisible = false;

    // Изменяем значения в полях ввода широты и долготы при клике на карту
    this.onClickOnMap
      .pipe(takeUntil(this.destroyer))
      .subscribe((coordinates) => {
        this.formPressureSensor.get('latitude')?.setValue(coordinates.lat);
        this.formPressureSensor.get('longitude')?.setValue(coordinates.lng);
      });

    // Изменяем параметр широты у объекта на карте при изменении значения в соответствующем поле ввода
    this.formPressureSensor
      .get('latitude')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (this.formPressureSensor.get('latitude')?.valid) {
          this.latitude = item;
          return;
        }
        return;
      });

    // Изменяем параметр долготы у объекта на карте при изменении значения в соответствующем поле ввода
    this.formPressureSensor
      .get('longitude')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (this.formPressureSensor.get('longitude')?.valid) {
          this.longitude = item;
          return;
        }
        return;
      });

    this.formPressureSensor
      .get('device')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((value) => {
        if (value) {
          this.formPressureSensor.controls['device_type'].enable();
        } else {
          this.formPressureSensor.controls['device_type'].disable();
        }
      });

    // Активация и деактивация полей для ввода порогов в зависимости от положения выключателя
    this.formPressureSensor
      .get('limits')
      ?.valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((value) => {
        if (value) {
          // Активируем поля для ввода порогов
          this.formPressureSensor.controls['limitsGroup'].enable();
          // Добавляем звездочки в название полей лимитов
          this.limitsFormActive = true;
        } else {
          // Деактивируем поля для ввода порогов
          this.formPressureSensor.controls['limitsGroup'].disable();
          // Стираем значения из полей лимитов
          this.formPressureSensor.controls['limitsGroup'].reset();
          // Убираем звездочки из названий полей лимитов
          this.limitsFormActive = false;
        }
      });

    // Деактивируем поля порогов при инициализации компонента
    this.formPressureSensor.controls['limitsGroup'].disable();
    // Стираем значения из полей лимитов
    this.formPressureSensor.controls['limitsGroup'].reset();

    this.pressureSensorType = this.pressureService.pressureSensorTypes;
  }

  limitChecker() {
    return (
      this.formPressureSensor.value.limitsGroup?.min_warning >=
        this.formPressureSensor.value.limitsGroup?.max_warning ||
      this.formPressureSensor.value.limitsGroup?.min_error >=
        this.formPressureSensor.value.limitsGroup?.max_error
    );
  }

  // проверка на валидность поля -> отправка запроса при успешной валидации
  submitForm() {
    if (!this.formPressureSensor.valid) {
      return this.formPressureSensor.markAllAsTouched();
    }
    const body: RequestPressureType = {
      name: this.formPressureSensor.value.name,
      deposit_id: this.formPressureSensor.value.deposit.id,
      device_id: this.formPressureSensor.value.device.id,
      device_field: this.formPressureSensor.value.device_type,
      min_warning:
        this.formPressureSensor.value.limitsGroup?.min_warning || null,
      min_error: this.formPressureSensor.value.limitsGroup?.min_error || null,
      max_warning:
        this.formPressureSensor.value.limitsGroup?.max_warning || null,
      max_error: this.formPressureSensor.value.limitsGroup?.max_error || null,
      latitude: this.formPressureSensor.value.latitude || null,
      longitude: this.formPressureSensor.value.longitude || null,
    };

    this.pressureService.setPressure(body).subscribe(() => {
      this.requestCreatedData.emit();
      this.closeDialog();
    });
  }

  // очистка полей и закрытие
  closeDialog() {
    this.formPressureSensor.reset({ name: '' });
    this.closeModal();
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
