<ng-container *ngIf="data">
  <div class="form">
    <div class="form__header">
      <button style="margin-top: 20px" tuiButton size="s">
        Отправить форму
      </button>
      <button style="margin-top: 20px" tuiButton size="s">Отмена</button>
    </div>

    <!--    -->
    <!--    <ng-container submitBtn (click)="">Отправить форму</ng-container>-->
    <!--    <ng-container anotherBtn>Удалить объект</ng-container>-->
    <app-form
      [formAttrsObservable]="formAttrsObservable"
      [formAttrs]="formFields"
      (sendForm)="sendForm($event)"
      (sendInfoFromAnotherBtn)="openDialogForDelete()"
    >
      <!--      <ng-container header>Редактирование объекта</ng-container>-->
    </app-form>
  </div>
  <ng-template
    let-observer
    [tuiDialogOptions]="$any({ label: 'Удаление объекта', size: 's' })"
    [(tuiDialog)]="isDeleteObjectDialog"
  >
    <p>Вы уверены, что хотите удалить объект?</p>
    <p>При удалении объекта, все дочерние объекты будут так же удалены.</p>
    <div class="tui-form__buttons _space-between">
      <button
        tuiButton
        class="tui-space_top-5"
        type="button"
        size="s"
        (click)="confirmObjectDelete()"
      >
        Подтвердить удаление объекта
      </button>
      <button
        tuiButton
        class="tui-space_top-5"
        type="button"
        size="s"
        appearance="whiteblock-active"
        (click)="cancelDeleteObject()"
      >
        Отмена
      </button>
    </div>
  </ng-template>
</ng-container>
<ng-container *ngIf="!data">
  <div class="no-content">Выберите объект</div>
</ng-container>
