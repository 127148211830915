<div class="scheme-editor">
  <div class="scheme-editor__tree">
    <perfect-scrollbar>
      <tui-loader
        class="inline-flex tui-space_right-2"
        [showLoader]="treeIsLoading"
        [inheritColor]="true"
        [overlay]="true"
      >
        <div style="height: calc(100vh - 235px)">
          <app-tree
            [hasSearchBlock]="true"
            [schemeMode]="true"
            [treeData]="treeData"
          >
          </app-tree>
        </div>
      </tui-loader>
    </perfect-scrollbar>
  </div>
  <div class="scheme-editor__container">
    <div class="scheme-editor__controls">
      <div class="scheme-editor__common-btns">
        <button
          tuiButton
          type="button"
          size="s"
          appearance="primary"
          class="tui-space_right-3"
          (click)="onSave()"
        >
          Сохранить
        </button>
        <button
          tuiButton
          type="button"
          size="s"
          appearance="primary"
          routerLink="/main/objects"
        >
          Отмена
        </button>
      </div>
      <tui-tooltip direction="bottom-right" [content]="help"></tui-tooltip>
      <ng-template #help>
        <div class="hint">
          Для перемещения используйте shift + зажатую левую кнопку мыши
          <br />Для зума shift + колесико мыши
        </div>
      </ng-template>
      <div class="scheme-editor__modification-btns">
        <div
          [tuiHint]="!selectedEl ? tooltip : null"
          tuiHintDirection="left"
          [tuiHintShowDelay]="10"
          [tuiHintHideDelay]="10"
          class="hint-wrapper"
        >
          <button
            [disabled]="selectedEl ? false : true"
            tuiButton
            type="button"
            size="s"
            appearance="primary"
            class="tui-space_right-3"
            (click)="onDelete()"
          >
            Удалить элемент
          </button>
        </div>
        <ng-template #tooltip>
          <div class="hint">Выберите элемент для удаления</div>
        </ng-template>
        <button
          [disabled]="elementsDeletedCount ? false : true"
          tuiButton
          type="button"
          appearance="iconHovered"
          size="s"
          (click)="undo()"
        >
          <tui-svg
            src="/assets/common-icons/undo.svg"
            style="transform: rotateY(180deg)"
          >
          </tui-svg>
        </button>

        <button
          [disabled]="undoCount ? false : true"
          tuiButton
          type="button"
          appearance="iconHovered"
          size="s"
          (click)="redo()"
        >
          <tui-svg src="/assets/common-icons/undo.svg"></tui-svg>
        </button>
      </div>
    </div>
    <div
      #schemeContent
      class="schemeContainer"
      [scrollLeft]="myScrollVariableLeft"
      [scrollTop]="myScrollVariableTop"
    >
      <div class="buttons-zoom">
        <button (click)="upZoom()">+</button>
        <button (click)="downZoom()">-</button>
      </div>
      <div id="scheme"></div>
    </div>
  </div>
</div>
