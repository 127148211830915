import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.less"],
})
export class SelectComponent {
  @Input()
  isMulti: boolean = false;

  @Input()
  title: string = "";

  @Input()
  values: any = {};

  @Output()
  handleClick: EventEmitter<any> = new EventEmitter();

  public changedValue: any;

  setChangedValue() {
    this.handleClick.emit(this.changedValue);
  }
}
