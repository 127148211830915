import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import {
  TUI_DEFAULT_MATCHER,
  TuiContextWithImplicit,
  TuiHandler,
} from '@taiga-ui/cdk';
import { map, startWith, switchMap } from 'rxjs/operators';
import { UsersService } from '../../../../services/users.service';
import {
  TuiAlertService,
  TuiDialogService,
  TuiNotification,
} from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.less'],
})
export class EditUserComponent implements OnInit {
  public editUserForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    full_name: new FormControl('', [Validators.required]),
    duty: new FormControl('', []),
    phone: new FormControl('', []),
    comment: new FormControl('', []),
    access: new FormControl([], []),
  });

  public availableAccess = of([
    { value: 'read', title: 'Просмотр' },
    { value: 'create', title: 'Создание' },
    { value: 'update', title: 'Редактирование' },
    { value: 'delete', title: 'Удаление' },
    { value: 'control', title: 'Управление' },
  ]);

  private readonly search$ = new Subject<string>();

  readonly access$ = this.search$.pipe(
    startWith(``),
    switchMap((search: string) =>
      this.availableAccess.pipe(
        map((access) =>
          access
            .filter(({ title }) => TUI_DEFAULT_MATCHER(title, search))
            .map(({ value }) => value)
        )
      )
    ),
    startWith(null) // <-- loading
  );

  readonly stringify$: Observable<
    TuiHandler<number | TuiContextWithImplicit<any>, string>
  > = this.availableAccess.pipe(
    map(
      (access) =>
        new Map(
          access.map<[string, string]>(({ value, title }) => [value, title])
        )
    ),
    startWith(new Map()),
    map((a) => (value: any) => {
      return typeof value === 'string' ? a.get(value) : a.get(value.$implicit);
    })
  );

  constructor(
    private usersService: UsersService,
    private alertService: TuiAlertService,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: any
  ) {}

  ngOnInit() {
    this.editUserForm.patchValue({ ...this.context.data });
  }

  handleSubmitEditUser() {
    if (this.editUserForm.valid) {
      this.usersService
        .editUser(this.editUserForm.value, this.context.data?.id)
        .subscribe(() => {
          this.alertService
            .open('Пользователь успешно изменен.', {
              label: 'Успешно!',
              status: TuiNotification.Success,
            })
            .subscribe();
          this.context.completeWith(true);
        });
    } else {
      this.alertService
        .open('Проверьте правильность заполнения формы', {
          label: 'Ошибка!',
          status: TuiNotification.Error,
        })
        .subscribe();
    }
  }

  onSearch(search: string | null): void {
    this.search$.next(search || ``);
  }
}
