<div class="import-file-modal">
  <div class="import-file-modal__header">
    <h2 class="title">Импорт файла</h2>
    <div class="cross" (click)="cancel()">
      <img src="/assets/cross.png" alt="(X)" />
    </div>
  </div>
  <form [formGroup]="importForm">
    <div>
      <tui-input-files
        *ngIf="!importForm.get('control').value && !rejectedFile"
        accept=".gpx, .kml"
        formControlName="control"
        (reject)="onReject($event)"
        [link]="link"
        [label]="label"
        [multiple]="false"
      ></tui-input-files>
      <tui-files class="tui-space_top-1">
        <tui-file
          *ngIf="loadedFiles$ | async as file"
          [file]="file"
          (removed)="removeFile()"
        ></tui-file>

        <tui-file
          *ngIf="rejectedFiles$ | async as file"
          state="error"
          [file]="file"
          (removed)="clearRejected()"
        ></tui-file>

        <tui-file
          *ngIf="loadingFiles$ | async as file"
          state="loading"
          [file]="file"
        ></tui-file>
      </tui-files>
    </div>
  </form>
  <div class="import-file-modal__buttons">
    <button
      class="button _access"
      (click)="importDataFromFile()"
      [disabled]="!isFileUploaded"
    >
      Импортировать
    </button>
    <button class="button _cancel" (click)="cancel()">Отмена</button>
  </div>
</div>
