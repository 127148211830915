import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public http: HttpClient) {}

  postAuthorization(body: any): any {
    return this.http.post(`${environment.apiUrl}auth/login/`, body);
  }
}
