<div class="buttons">
  <button
    *ngIf="!isEdit; else editOptionButtons"
    tuiIconButton
    type="tuiIconButton"
    size="s"
    icon="tuiIconDraft"
    appearance="menu"
    (click)="changeMode()"
  ></button>
  <ng-template #editOptionButtons>
    <button
      tuiIconButton
      type="button"
      size="s"
      appearance="menu"
      class="tui-space_right-1"
      icon="tuiIconCheck"
      (click)="onSaveParams()"
    ></button>
    <tui-hosted-dropdown #dropdown [content]="content" [(open)]="dropdownOpen">
      <button
        *ngIf="isOptionTable"
        tuiIconButton
        type="button"
        size="s"
        appearance="menu"
        class="tui-space_right-1"
        icon="tuiIconPlus"
        [pseudoHovered]="dropdown.open || null"
      ></button>
    </tui-hosted-dropdown>
    <ng-template #content>
      <div style="height: 150px; width: 350px">
        <perfect-scrollbar>
          <tui-data-list role="menu" tuiDataListDropdownManager>
            <tui-opt-group
              tuiMultiSelectGroup
              [(ngModel)]="listSelectedIdsOption"
            >
              <button
                *ngFor="let item of listOptions"
                (click)="plusChanges(item.id)"
                tuiOption
                size="s"
                [value]="item.id"
              >
                {{ item.name }}
              </button>
            </tui-opt-group>
          </tui-data-list>
        </perfect-scrollbar>
      </div>
    </ng-template>
    <button
      tuiIconButton
      type="button"
      size="s"
      class="cross"
      appearance="menu"
      icon="tuiIconClose"
      (click)="cancel()"
    ></button>
  </ng-template>
</div>
