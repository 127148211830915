import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ObjectService } from 'src/app/services/object.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SchemeService } from '../../../services/scheme.service';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';

declare var draw2d: any;

@Component({
  selector: 'app-scheme-editor',
  templateUrl: './scheme-editor.component.html',
  styleUrls: ['./scheme-editor.component.less'],
})
export class SchemeEditorComponent implements OnInit {
  public treeData = [];

  public treeIsLoading = true;

  public scheme: any;

  public editableSchemeObjectId: number | null = null;

  public selectedEl: object | null = null;

  public elementsDeletedCount: number = 0;

  public elementsCount: number = 0;

  public undoCount: number = 0;

  private editableSchemeObject: any | null = null;

  private jsonReader = new draw2d.io.json.Reader();

  @HostListener('click', ['$event'])
  onClick() {
    this.selectedEl = this.scheme.selection.all.data[0];
  }

  @ViewChild('scheme')
  public schemeContainer: any;

  @ViewChild('schemeContent')
  public schemeHeadContainer: any;

  public myScrollVariableLeft: any = 0;

  public myScrollVariableTop: any = 0;

  public isTouchScheme: boolean = false;

  public position: any = { top: 0, left: 0, x: 0, y: 0 };

  /* 1) Плучение координаты начала зажатия мыши - указать где то в переменной
   * 2) В mousemove будет просчет добавления пока  mouseleave не сработает
   * 3) Метод mouseleave по обнаружению того что он перестал нажимать
   * 4) Просчитать корректно логику передвижения мыши */
  @HostListener('mousedown', ['$event'])
  onClickByScheme(mouse: any) {
    //только сцена больше ничего!
    if (mouse.shiftKey && mouse.target.tagName === 'svg') {
      this.isTouchScheme = true;
      this.position = {
        // The current scroll
        left: this.schemeHeadContainer.nativeElement.scrollLeft,
        top: this.schemeHeadContainer.nativeElement.scrollTop,
        // Get the current mouse position
        x: mouse.clientX,
        y: mouse.clientY,
      };
    }
  }

  // метод по отпускание щажатой мышки
  @HostListener('mouseup', ['$event'])
  onUpClickByScheme() {
    this.isTouchScheme = false;
  }

  @HostListener('mousemove', ['$event'])
  onMove(mouse: any) {
    if (this.isTouchScheme) {
      const dx = mouse.clientX - this.position.x;
      const dy = mouse.clientY - this.position.y;
      this.schemeHeadContainer.nativeElement.scrollLeft =
        this.position.left - dx;
      this.schemeHeadContainer.nativeElement.scrollTop = this.position.top - dy;
    }
  }

  constructor(
    private objectService: ObjectService,
    private schemeService: SchemeService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: TuiAlertService
  ) {}

  ngOnInit(): void {
    this.editableSchemeObjectId = +this.route.snapshot.params['objectId'];
    this.objectService
      .getDepositInfoByID(this.editableSchemeObjectId)
      .subscribe((data: any) => {
        this.editableSchemeObject = data;
        this.initSchemeDraw2d();
      });
    this.objectService.getDataToTree().subscribe((data: any) => {
      this.treeData = data.tree;
      this.treeIsLoading = false;
    });
    this.objectService.selectedObjectScheme.subscribe((object: any) => {
      //TODO: Пофисксить
      if (object !== null) {
        this.addObjectToScheme(object);
      }
    });
  }

  zoomStartPosition(data: any) {
    if (!data?.length) {
      return;
    }
    let coordsX: number[] = [];
    let coordsY: number[] = [];
    data.forEach((element: any) => {
      if (element.x && element.y) {
        coordsX.push(element.x);
        coordsY.push(element.y);
      }
    });
    if (!coordsY.length && coordsX.length) {
      return;
    }
    let xDifference =
      Math.max.apply(Math, coordsX) - Math.min.apply(Math, coordsX);
    let yDifference =
      Math.max.apply(Math, coordsY) - Math.min.apply(Math, coordsY);
    let zoomCount =
      xDifference > yDifference ? xDifference / 850 : yDifference / 850;
    this.scheme.setZoom(zoomCount);
  }

  createBlackConnection = (sourcePort: any, targetPort: any) => {
    const router =
      new draw2d.layout.connection.InteractiveManhattanConnectionRouter();
    router.abortRoutingOnFirstVertexNode = false;
    const c = new draw2d.Connection({
      outlineColor: '#000',
      outlineStroke: 1,
      color: '#999',
      router: router,
      stroke: 2,
      radius: 2,
    });
    if (sourcePort) {
      c.setSource(sourcePort);
      c.setTarget(targetPort);
    }
    return c;
  };

  initSchemeDraw2d() {
    this.scheme = new draw2d.Canvas('scheme');
    this.scheme.installEditPolicy(
      new draw2d.policy.canvas.FadeoutDecorationPolicy()
    );
    this.scheme.installEditPolicy(
      new draw2d.policy.canvas.SnapToGridEditPolicy()
    );
    this.scheme.installEditPolicy(
      new draw2d.policy.connection.ComposedConnectionCreatePolicy([
        new draw2d.policy.connection.DragConnectionCreatePolicy({
          createConnection: this.createBlackConnection,
        }),
      ])
    );
    this.zoomStartPosition(this.editableSchemeObject.scheme?.scheme);
    if (this.editableSchemeObject.scheme?.scheme) {
      try {
        this.loadSchemeFromJson();
      } catch (e) {
        this.scheme.clear();
      }
    }
    this.scheme.on('select', () => {
      this.selectedEl = this.scheme.selection.all.data.length;
    });
    this.elementsCount = this.scheme.getFigures().data?.length;
  }

  loadSchemeFromJson() {
    //TODO: не лучшее решение...
    this.objectService.getObjectList().subscribe((data: any) => {
      const allObjects = data;

      for (let obj of this.editableSchemeObject.scheme?.scheme.filter(
        (o: any) => o.type === 'draw2d.shape.basic.Rectangle'
      )) {
        this.fillObjectNode(obj, allObjects);
      }
      for (let conn of this.editableSchemeObject.scheme?.scheme.filter(
        (o: any) => o.type === 'draw2d.Connection'
      )) {
        this.drawConnection(conn);
      }
    });
  }

  drawConnection(conn: any) {
    this.jsonReader.unmarshalConnection(this.scheme, conn);
  }

  fillObjectNode(obj: any, allObjects: any[]) {
    const objectNode: any = allObjects.find(
      (o: any) => o.id === obj.userData.objectId
    );
    const node = new draw2d.shape.basic.Rectangle({
      id: obj.id,
      bgColor: '#FFFFFF',
      resizeable: false,
      width: 75,
      height: 75,
      radius: 10,
      color: '#263238',
      userData: { objectId: objectNode.id },
    });
    obj.ports.forEach((port: any, index: number) => {
      let locator: any = null;
      if (port.locator === 'draw2d.layout.locator.OutputPortLocator') {
        locator = new draw2d.layout.locator.OutputPortLocator();
      } else if (port.locator === 'draw2d.layout.locator.InputPortLocator') {
        locator = new draw2d.layout.locator.InputPortLocator();
      } else if (port.locator === 'draw2d.layout.locator.PortLocator') {
        if (index > 5 && index <= 8) {
          let offset = 0;
          if (index === 6) {
            offset = 15;
          } else if (index === 8) {
            offset = -15;
          }
          locator = this.topPortLocator(offset);
        } else if (index > 8 && index <= 11) {
          let offset = 0;
          if (index === 9) {
            offset = 15;
          } else if (index === 11) {
            offset = -15;
          }
          locator = this.bottomPortLocator(offset);
        }
      }
      const newPort = new draw2d.HybridPort(port);
      newPort.setName(`hybrid${index}`);
      if (locator) {
        node.addPort(newPort, locator);
      } else {
        node.addPort(newPort);
      }
    });
    let objTypeIcon = new draw2d.shape.basic.Image({
      path: `/assets/icons/${objectNode.type}.svg`,
      width: 50,
      height: 50,
      resizeable: false,
      zIndex: 20,
    });
    node.add(
      new draw2d.shape.basic.Text({
        text: objectNode.name,
        width: 75,
        zIndex: 20,
      }),
      new draw2d.layout.locator.TopLocator()
    );
    node.add(objTypeIcon, new draw2d.layout.locator.CenterLocator());
    node.on('dblclick', () => {
      alert('user dbl click on the figure');
    });
    this.scheme.add(node, obj.x, obj.y);
  }

  topPortLocator = (offset: number) => {
    const topL = draw2d.layout.locator.PortLocator.extend({
      init: function () {
        this._super();
      },
      relocate: function (index: any, figure: any) {
        this.applyConsiderRotation(
          figure,
          figure.getParent().getWidth() / 2 - offset,
          1
        );
      },
    });
    return new topL();
  };

  bottomPortLocator = (offset: number) => {
    const bottomL = draw2d.layout.locator.PortLocator.extend({
      init: function () {
        this._super();
      },
      relocate: function (index: any, figure: any) {
        var p = figure.getParent();
        this.applyConsiderRotation(
          figure,
          p.getWidth() / 2 - offset,
          p.getHeight()
        );
      },
    });
    return new bottomL();
  };

  addObjectToScheme(object: any) {
    const node = new draw2d.shape.basic.Rectangle({
      bgColor: '#FFFFFF',
      resizeable: false,
      width: 75,
      height: 75,
      radius: 10,
      color: '#263238',
      userData: { objectId: object.id },
    });
    const leftLocator = new draw2d.layout.locator.InputPortLocator();
    const rightLocator = new draw2d.layout.locator.OutputPortLocator();
    const topLocator1 = this.topPortLocator(15);
    const topLocator2 = this.topPortLocator(0);
    const topLocator3 = this.topPortLocator(-15);
    const bottomLocator1 = this.bottomPortLocator(15);
    const bottomLocator2 = this.bottomPortLocator(0);
    const bottomLocator3 = this.bottomPortLocator(-15);
    node.createPort('hybrid', leftLocator);
    node.createPort('hybrid', leftLocator);
    node.createPort('hybrid', leftLocator);
    node.createPort('hybrid', rightLocator);
    node.createPort('hybrid', rightLocator);
    node.createPort('hybrid', rightLocator);
    node.createPort('hybrid', topLocator1);
    node.createPort('hybrid', topLocator2);
    node.createPort('hybrid', topLocator3);
    node.createPort('hybrid', bottomLocator1);
    node.createPort('hybrid', bottomLocator2);
    node.createPort('hybrid', bottomLocator3);
    let img = new draw2d.shape.basic.Image({
      path: `/assets/icons/${object.type}.svg`,
      width: 50,
      height: 50,
      resizeable: false,
      zIndex: 20,
    });
    node.add(
      new draw2d.shape.basic.Text({
        text: object.name,
        width: 75,
        zIndex: 20,
      }),
      new draw2d.layout.locator.TopLocator()
    );
    node.add(img, new draw2d.layout.locator.CenterLocator());
    this.scheme.add(node, 50, 50);
  }

  onSave() {
    const writer = new draw2d.io.json.Writer();
    writer.marshal(this.scheme, (json: any) => {
      this.schemeService
        .changeScheme(this.editableSchemeObjectId as number, json)
        .subscribe(() => {
          this.alertService
            .open('Схема успешно сохранена', {
              autoClose: 5000,
              status: TuiNotification.Success,
            })
            .subscribe();
        });
      this.router.navigate(['/main/objects/scheme']);
    });
  }

  onDelete() {
    let selection = this.scheme.getSelection();
    this.scheme
      .getCommandStack()
      .startTransaction(draw2d.Configuration.i18n.command.deleteShape);
    selection.each((index: number, figure: any) => {
      if (figure instanceof draw2d.Connection) {
        if (
          selection.contains(figure.getSource().getRoot()) ||
          selection.contains(figure.getTarget().getRoot())
        ) {
          return;
        }
      }
      let cmd = figure.createCommand(
        new draw2d.command.CommandType(draw2d.command.CommandType.DELETE)
      );
      if (cmd !== null) {
        this.scheme.getCommandStack().execute(cmd);
      }
    });
    this.scheme.getCommandStack().commitTransaction();
    this.elementsDeletedCount += 1;
  }

  undo(): void {
    this.undoCount += 1;
    this.scheme.getCommandStack().undo();
    this.elementsDeletedCount -= 1;
    if (this.elementsCount === this.scheme.getFigures().data?.length) {
      this.undoCount = 0;
      this.elementsDeletedCount = 0;
    }
  }

  redo(): void {
    this.scheme.getCommandStack().redo();
    this.elementsDeletedCount += 1;
    this.undoCount -= 1;
  }

  upZoom() {
    let zoom = this.scheme.getZoom();
    if (zoom > 1) {
      this.scheme.setZoom(zoom - 0.05);
    }
  }

  downZoom() {
    let zoom = this.scheme.getZoom();
    if (zoom < 3.8) {
      this.scheme.setZoom(zoom + 0.05);
    }
  }
}
