<div [formGroup]="form">
  <tui-select
    [formControlName]="name"
    [readOnly]="isDisabled"
    [valueContent]="content"
  >
    <span *ngIf="placeholder.length">{{ placeholder }}</span>
    <tui-data-list *tuiDataList>
      <button *ngFor="let item of data" tuiOption [value]="item">
        {{ item?.label || item?.title }}
      </button>
    </tui-data-list>
  </tui-select>
  <!--  <tui-field-error [formControlName]="name"></tui-field-error>-->
</div>
