import {
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { TokenService } from '../services/token.service';
import { TuiNotificationsService } from '@taiga-ui/core';

@Injectable()
export class Interceptors implements HttpInterceptor {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    @Inject(TuiNotificationsService)
    private readonly notificationsService: TuiNotificationsService
  ) {}

  BLACK_LIST = ['login/', 'registration/'];

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isThereInBlackList(req.url).length) {
      req = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + this.tokenService.getToken()
        ),
      });
    }
    return next.handle(req).pipe(
      tap(
        () => {},
        (error) => {
          if (error.status === 500 || error.status === 0) {
            this.notificationsService
              .show(
                'Ошибка сервера. Перезагрузите страницу и попробуйте ещё раз'
              )
              .subscribe();
          } else if (error.status === 401) {
            this.router.navigate(['/login']).then(
              () => true,
              () => true
            );
          } else if (
            error.status !== 200 &&
            error.status !== 500 &&
            error.status !== 401
          ) {
            this.showMessage(error);
          } else {
            return;
          }
        }
      )
    );
  }

  showMessage(msg: any) {
    //если detail в ответе строка выводить её содержимое  с типом ошибка,
    //если detail это объект, выводить сообщение "Проверьте корректность данных",
    if (typeof msg?.error?.detail === 'object' && msg?.error?.detail?.length) {
      this.notificationsService
        .show('Проверьте корректность данных')
        .subscribe();
    } else {
      this.notificationsService.show(msg?.error?.detail).subscribe();
    }
  }

  isThereInBlackList(url: string): string[] {
    return this.BLACK_LIST.filter((urlFormBlackList: string) => {
      return url.endsWith(urlFormBlackList);
    });
  }
}
