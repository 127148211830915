<div class="oilfield-add">
  <app-primary-section>
    <div class="oilfield-add__container">
      <h2 class="title">Добавить БГ/УЗА</h2>
      <div class="cross" (click)="closeModal()">
        <img src="/assets/cross.png" alt="(X)" />
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="formBG">
      <div class="form__title">
        <h4>Общая информация</h4>
      </div>
      <div class="form__field">
        <span>Название *</span>
        <div class="input">
          <tui-input [tuiTextfieldMaxLength]="40" formControlName="name">
            <span class="input__placeholder">Укажите название</span>
          </tui-input>
          <tui-field-error formControlName="name"></tui-field-error>
        </div>
      </div>
      <div class="form__field">
        <span>Месторождение *</span>
        <div class="input">
          <tui-select
            formControlName="deposit"
            [valueContent]="typeValueContent"
          >
            <span *ngIf="!formBG.get('deposit')?.value">Не выбрано</span>
            <span *ngIf="formBG.get('deposit')?.value"
              >Выберите месторождение</span
            >
            <tui-data-list-wrapper
              *tuiDataList
              [items]="depositList"
              [itemContent]="valueContent"
            >
            </tui-data-list-wrapper>
          </tui-select>
          <ng-template #typeValueContent let-data>
            {{ data.title }}
          </ng-template>
          <ng-template #valueContent let-data>
            {{ data.title }}
          </ng-template>
          <tui-field-error formControlName="deposit"></tui-field-error>
        </div>
      </div>
      <div class="form__field">
        <span>Объект нефтесбора</span>
        <div class="input">
          <tui-select
            #select
            [valueContent]="getNameSelectedValue()"
            formControlName="oil_parent_id"
          >
            <span *ngIf="!disabledFormControl; else disabled_form_control"
              >Выберите объект нефтесбора</span
            >
            <ng-template #disabled_form_control
              >Нет элементов для выбора</ng-template
            >
            <tui-data-list *tuiDataList>
              <tui-opt-group label="ГЗУ">
                <button
                  *ngFor="let element of filterListOfOil.gzuList"
                  tuiOption
                  [value]="element"
                >
                  {{ element.name }}
                </button>
              </tui-opt-group>
              <tui-opt-group label="ДНС">
                <button
                  *ngFor="let element of filterListOfOil.dnsList"
                  tuiOption
                  [value]="element"
                >
                  {{ element.name }}
                </button>
              </tui-opt-group>
              <tui-opt-group label="БГ/УЗА">
                <button
                  *ngFor="let element of filterListOfOil.bgList"
                  tuiOption
                  [value]="element"
                >
                  {{ element.name }}
                </button>
              </tui-opt-group>
            </tui-data-list>
          </tui-select>
        </div>
      </div>

      <div class="form__field">
        <span>Датчик давления текущего объекта нефтесбора</span>
        <div class="input">
          <app-form-select-with-search-for-huge-arrays
            [form]="formBG"
            [formControlNameSelect]="'curr_pressure'"
            [label]="'Выберите датчик давления'"
            [placeholderMessage]="'Введите название датчика...'"
            [visualRequired]="false"
            [greyStyle]="true"
            [items]="pressureList"
            style="width: 100%"
          >
          </app-form-select-with-search-for-huge-arrays>

          <tui-field-error formControlName="curr_pressure"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Датчик давления следующего объекта нефтесбора</span>
        <div class="input">
          <app-form-select-with-search-for-huge-arrays
            [form]="formBG"
            [formControlNameSelect]="'next_pressure'"
            [label]="'Выберите датчик давления'"
            [placeholderMessage]="'Введите название датчика...'"
            [visualRequired]="false"
            [greyStyle]="true"
            [items]="pressureList"
            style="width: 100%"
          >
          </app-form-select-with-search-for-huge-arrays>

          <tui-field-error formControlName="next_pressure"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Нефтепровод</span>
        <div class="input">
          <app-form-select-with-search-for-huge-arrays
            [form]="formBG"
            [formControlNameSelect]="'oil_pipe'"
            [label]="'Выберите нефтепровод'"
            [placeholderMessage]="'Введите название нефтепровода...'"
            [visualRequired]="false"
            [greyStyle]="true"
            [items]="oilPipelineList"
            style="width: 100%"
          >
          </app-form-select-with-search-for-huge-arrays>

          <tui-field-error formControlName="oil_pipe"></tui-field-error>
        </div>
      </div>

      <div class="form__field">
        <span>Водопровод</span>
        <div class="input">
          <app-form-select-with-search-for-huge-arrays
            [form]="formBG"
            [formControlNameSelect]="'water_pipe'"
            [label]="'Выберите водопровод'"
            [placeholderMessage]="'Введите название водопровода...'"
            [visualRequired]="false"
            [greyStyle]="true"
            [items]="waterPipelineList"
            style="width: 100%"
          >
          </app-form-select-with-search-for-huge-arrays>

          <tui-field-error formControlName="water_pipe"></tui-field-error>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form" [formGroup]="formBG">
      <div class="form__title">
        <h4>Координаты</h4>
      </div>
      <div class="form__field">
        <span>Широта *</span>
        <div class="input">
          <tui-input-number
            [precision]="14"
            [tuiTextfieldMaxLength]="50"
            formControlName="latitude"
          >
            <span class="input__placeholder">Введите широту</span>
          </tui-input-number>
          <tui-field-error formControlName="latitude"></tui-field-error>
        </div>
      </div>
      <div class="form__field">
        <span>Долгота *</span>
        <div class="input">
          <tui-input-number
            [precision]="14"
            [tuiTextfieldMaxLength]="50"
            formControlName="longitude"
          >
            <span class="input__placeholder">Введите долготу</span>
          </tui-input-number>
          <tui-field-error formControlName="longitude"></tui-field-error>
        </div>
      </div>
      <div class="oilfield-add__map">
        <app-map-object
          [onClickOnMap]="onClickOnMap"
          [currentIdObject]="103"
          [latitude]="latitude"
          [longitude]="longitude"
        >
        </app-map-object>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="footer">
      <span>* Обязательные для заполнения поля</span>
      <div class="footer__buttons">
        <button (click)="submitForm()" class="footer-button _add">
          Добавить
        </button>
        <button (click)="closeDialog()" class="footer-button _cancel">
          Отменить
        </button>
      </div>
    </div>
  </app-primary-section>
</div>
