import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TuiAlertService } from '@taiga-ui/core';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import {
  PipelineColorType,
  TypePipelineListModel,
} from '../../../../../models/pipeline.model';
import { PipelineService } from '../../../../services/pipeline.service';

//проверка на пробелы
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

export function minValueValidator(context: { min: number }): string {
  return `Значение должно быть не меньше ${context.min}`;
}

export function maxValueValidator(context: { max: number }): string {
  return `Значение должно быть не больше ${context.max}`;
}

@Component({
  selector: 'app-oil-pipeline-add',
  templateUrl: './oil-pipeline-add.component.html',
  styleUrls: ['./oil-pipeline-add.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class OilPipelineAddComponent implements OnInit {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  //координаты приходят со страницы карты при завершении рисования линии трубы
  @Input()
  coordinates: { latitude: number; longitude: number }[] = [];

  // возвращает созданное месторождение
  @Output() requestCreatedData: EventEmitter<null> = new EventEmitter();

  //для формы -> список скважин
  public productionYearList: any = [];

  // public currentDay: any = new Date();

  // public currentWeekEnd: number[] = [
  //   this.currentDay.getFullYear(),
  //   this.currentDay.getMonth(),
  //   this.currentDay.getDate(),
  // ];

  // public dayWeekAgo: any = new Date(
  //   this.currentDay.getTime() - 6 * 24 * 60 * 60 * 1000
  // );

  // public currentWeekStart: number[] = [
  //   this.dayWeekAgo.getFullYear(),
  //   this.dayWeekAgo.getMonth(),
  //   this.dayWeekAgo.getDate(),
  // ];

  //форма для имени месторождения
  public oilPipelineForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    length: new FormControl('', []),
    diameter: new FormControl('', []),
    wall_thickness: new FormControl('', []),
    material: new FormControl('', []),
    is_anticorrosion: new FormControl('', []),
    production_method: new FormControl('', []),
    production_year: new FormControl('', []),
    nominal_pressure: new FormControl('', []),
    working_pressure: new FormControl('', []),
    // last_EPB: new FormControl(
    //   new TuiDay(
    //     this.currentWeekStart[0],
    //     this.currentWeekStart[1],
    //     this.currentWeekStart[2]
    //   ),
    //   []
    // ),
    // next_EPB: new FormControl(
    //   new TuiDay(
    //     this.currentWeekEnd[0],
    //     this.currentWeekEnd[1],
    //     this.currentWeekEnd[2]
    //   ),
    //   []
    // ),
    last_EPB: new FormControl(null, []),
    next_EPB: new FormControl(null, []),
  });

  //клик по карте(по нему в onInit записываем координаты клика по карте)
  /*  public onClickOnMap = new EventEmitter();

  public latitude: number = 0;

  public longitude: number = 0;*/

  public coords: { latitude: number; longitude: number }[] = [];

  public oilPipeline: {
    line: { latitude: number; longitude: number }[];
    type: PipelineColorType.OIL;
  }[] = [];

  // Массив вариантов антикоррозийной обработки
  public pipelinesAnticorrosiveOptions: any[] = [];

  public isEdit: boolean = true;

  constructor(
    private pipelineService: PipelineService,
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService
  ) {}

  async ngOnInit() {
    if (this.coordinates.length) {
      this.isEdit = false;
    }
    this.generateProductionYearList();
    this.coords = this.coordinates;
    this.oilPipeline = [
      {
        line: this.coordinates.length ? this.coordinates : [],
        type: PipelineColorType.OIL,
      },
    ];

    // Забираем массив значений для поля антикоррозийной обработки из сервиса
    this.pipelinesAnticorrosiveOptions =
      this.pipelineService.pipelinesAnticorrosiveOptions;
    // Находим первую опцию, равную "Не выбрано"
    const firstOptrion = this.pipelinesAnticorrosiveOptions[0];
    // Если поле антикоррозийной обработки не имеет значения, то присвоим ему первую опцию
    if (!this.oilPipelineForm.value.is_anticorrosion) {
      this.oilPipelineForm.controls.is_anticorrosion.setValue(firstOptrion);
    }
  }

  generateProductionYearList() {
    const max = new Date().getFullYear();
    const min = 1900;

    for (let i = max; i >= min; i--) {
      this.productionYearList.push(i);
    }
  }

  getCoords(coords: { latitude: number; longitude: number }[]) {
    this.coords = coords;
  }

  // проверка на валидность поля -> отправка запроса при успешной валидации
  submitForm() {
    if (!this.oilPipelineForm.valid) {
      this.notificationsService
        .open('Проверьте правильность заполнения формы', {
          label: '',
          status: TuiNotification.Error,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      return this.oilPipelineForm.markAllAsTouched();
    }
    if (!this.coords.length) {
      this.notificationsService
        .open('Обозначьте нефтепровод на карте', {
          label: '',
          status: TuiNotification.Error,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      return;
    }

    let body: TypePipelineListModel = {
      name: this.oilPipelineForm.get('name').value,
      length: this.oilPipelineForm.get('length')?.value || null,
      diameter: this.oilPipelineForm.get('diameter').value || null,
      wall_thickness: this.oilPipelineForm.get('wall_thickness')?.value || null,
      material: this.oilPipelineForm.get('material')?.value || null,
      is_anticorrosion: this.oilPipelineForm.value.is_anticorrosion.id,
      production_method:
        this.oilPipelineForm.get('production_method')?.value || null,
      production_year:
        this.oilPipelineForm.get('production_year')?.value || null,
      nominal_pressure:
        this.oilPipelineForm.get('nominal_pressure')?.value || null,
      working_pressure:
        this.oilPipelineForm.get('working_pressure')?.value || null,
      last_EPB:
        this.oilPipelineForm
          .get('last_EPB')
          ?.value?.toString()
          .split('.')
          .reverse()
          .join('-') || null,
      next_EPB:
        this.oilPipelineForm
          .get('next_EPB')
          ?.value?.toString()
          .split('.')
          .reverse()
          .join('-') || null,
      coordinates: [
        {
          line: this.coords,
        },
      ],
    };
    this.pipelineService.createOilPipeline(body).subscribe(() => {
      this.notificationsService
        .open('Объект добавлен', {
          label: '',
          status: TuiNotification.Success,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      // this.requestCreatedData.emit();
      this.closeDialog();
    });
  }

  // очистка полей и закрытие
  closeDialog() {
    this.oilPipelineForm.reset({ name: '' });
    this.closeModal();
    this.requestCreatedData.emit();
  }
}
