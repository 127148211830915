<tui-hosted-dropdown #dropdown [tuiDropdownMaxHeight]="500" [content]="content"
                     [tuiHint]="tooltip"
>
  <button
    tuiIconButton
    appearance="flat"
    type="button"
    icon="tuiIconSettingsLarge"
    [pseudoHovered]="dropdown.open || null"
    [disabled]="disabled"
  ></button>
</tui-hosted-dropdown>
<ng-template #tooltip>
  <span *ngIf="disabled">Кнопка недоступна пока нет данных в таблице</span>
  <span *ngIf="!disabled">Выбор отображения столбцов в таблице</span>
</ng-template>
<ng-template #content >
  <tui-data-list *ngIf="!disabled">
    <tui-opt-group tuiMultiSelectGroup [(ngModel)]="selectedColumns">
      <button
        class="select-item"
        *ngFor="let col of columns"
        tuiOption
        size="l"
        (click)="changeColumns()"
        [value]="col"
      >
        <span class="select-item__title">{{ col.title }}</span>
      </button>
    </tui-opt-group>
  </tui-data-list>
</ng-template>
