<div *ngIf="selectedObject">
  <tui-loader
    [showLoader]="isLoading"
    [inheritColor]="true"
    [overlay]="true"
    [size]="'xl'"
  >
    <app-primary-section>
      <h2 class="oilfield-popup__title">
        {{ selectedObject.name || "object" }}
      </h2>
      <tui-tabs class="tabs">
        <button
          class="tabs__btns"
          tuiTab
          *ngFor="let tab of tabs"
          (click)="changeTabElement(tab)"
        >
          <div>
            {{ tab.title }}
          </div>
        </button>
      </tui-tabs>
    </app-primary-section>
    <div class="container__form">
      <app-common-info-pressure-sensor
        [contextObject]="selectedObject"
        (handleChange)="getEditableDeposit()"
        *ngIf="activeItemIndex.url === 'info'"
      >
      </app-common-info-pressure-sensor>
      <app-pressure-sensor-events
        [pressureSensorID]="selectedObject.id"
        *ngIf="activeItemIndex.url === 'events'"
      ></app-pressure-sensor-events>
    </div>
    <app-pressure-sensor-history
      *ngIf="activeItemIndex.url === 'history'"
      [pressureSensorID]="selectedObject.id"
    ></app-pressure-sensor-history>
  </tui-loader>
</div>
