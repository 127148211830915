<div *ngIf="contextObject">
  <app-primary-section>
    <form *ngIf="editObjectMode" [formGroup]="objectInfoForm" class="form">
      <div class="inline-table">
        <div class="header">
          <span>ГЗУ</span>
          <app-common-buttons-oilfield
            [isEdit]="editObjectMode"
            (handelCancel)="cancelInput()"
            (handelChange)="changeInput()"
            (handelSave)="save()"
          >
          </app-common-buttons-oilfield>
        </div>
        <div class="form-table">
          <div class="row">
            <div class="form-label">Название *</div>
            <div class="value">
              <tui-input
                style="width: 90%"
                formControlName="name"
                tuiTextfieldSize="m"
              >
                Введите название
                <input maxlength="41" tuiTextfield type="text" />
              </tui-input>
              <tui-field-error formControlName="name"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Месторождение *</div>
            <div class="value">
              <tui-select
                *tuiLet="depositList as items"
                [tuiTextfieldLabelOutside]="true"
                [valueContent]="items ? stringify(items) : loading"
                style="width: 90%"
                formControlName="parent_id"
                tuiTextfieldSize="m"
              >
                <ng-template tuiDataList>
                  <tui-data-list *ngIf="items; else loading">
                    <tui-opt-group>
                      <button
                        *ngFor="let item of items"
                        tuiOption
                        [value]="item.id"
                      >
                        {{ item.name }}
                      </button>
                    </tui-opt-group>
                  </tui-data-list>
                </ng-template>
                <ng-template #loading>
                  <tui-loader
                    class="tui-space_vertical-3 loader"
                  ></tui-loader>
                </ng-template>
              </tui-select>
              <ng-template #typeValueContent let-data>
                {{ data.title }}
              </ng-template>
              <ng-template #valueContent let-data>
                {{ data.title }}
              </ng-template>
              <tui-field-error formControlName="parent_id"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Объект нефтесбора</div>
            <div class="value">
              <tui-select
                style="width: 90%"
                *tuiLet="getConcatOils() as items"
                class="b-form"
                [tuiTextfieldLabelOutside]="true"
                [valueContent]="items ? stringify(items) : loading"
                formControlName="oil_parent_id"
                tuiTextfieldSize="m"
              >
                <span *ngIf="!disabledOilField; else disabled_oil_field"
                  >Выберите объект нефтесбора</span
                >
                <ng-template #disabled_oil_field
                  >Нет элементов для выбора
                </ng-template>
                <ng-template tuiDataList>
                  <tui-data-list *ngIf="items; else loading">
                    <tui-opt-group label="ДНС">
                      <button
                        *ngFor="let item of listOfOil.dnsList"
                        tuiOption
                        [value]="item.id"
                      >
                        {{ item.name }}
                      </button>
                    </tui-opt-group>
                    <tui-opt-group label="ГЗУ">
                      <button
                        *ngFor="let item of listOfOil.gzuList"
                        tuiOption
                        [value]="item.id"
                      >
                        {{ item.name }}
                      </button>
                    </tui-opt-group>
                    <tui-opt-group label="БГ/УЗА">
                      <button
                        *ngFor="let item of listOfOil.bgList"
                        tuiOption
                        [value]="item.id"
                      >
                        {{ item.name }}
                      </button>
                    </tui-opt-group>
                  </tui-data-list>
                </ng-template>
                <ng-template #loading>
                  <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
                </ng-template>
              </tui-select>
              <tui-field-error
                formControlName="oil_parent_id"
              ></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Датчик давления текущего объекта нефтесбора</div>
            <div class="value width90">

              <app-form-select-with-search-for-huge-arrays
                [form]="objectInfoForm"
                [formControlNameSelect]="'curr_pressure'"
                [label]="'Выберите датчик давления'"
                [placeholderMessage]="'Введите название датчика...'"
                [visualRequired]="false"
                [items]="pressureList"

              >
              </app-form-select-with-search-for-huge-arrays>

              <tui-field-error formControlName="curr_pressure"></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Датчик давления следующего объекта нефтесбора</div>
            <div class="value width90">

              <app-form-select-with-search-for-huge-arrays
                [form]="objectInfoForm"
                [formControlNameSelect]="'next_pressure'"
                [label]="'Выберите датчик давления'"
                [placeholderMessage]="'Введите название датчика...'"
                [visualRequired]="false"
                [items]="pressureList"

              >
              </app-form-select-with-search-for-huge-arrays>

              <tui-field-error formControlName="next_pressure"></tui-field-error>
            </div>
          </div>


          <div class="row">
            <div class="form-label">Датчик дебита</div>
            <div class="device-container width90">
              <div
                formArrayName="debits_params_devices"
                *ngFor="
                  let section of memoizedGetSections(objectInfoForm);
                  let i = index
                "
                class="device-row-container"
              >
                <div [ngClass]="{'device-row': i === 0 && memoizedGetSections(objectInfoForm).length < 2,
                'device-row__first-control': i === 0 && memoizedGetSections(objectInfoForm).length > 1,
                'device-row__second-control': i === 1 && memoizedGetSections(objectInfoForm).length > 1}"
                     [formGroupName]="i">
                    <button
                      *ngIf="i === 0 && memoizedGetSections(objectInfoForm).length < 2"
                      class="add-button"
                      tuiIconButton
                      type="button"
                      icon="tuiIconPlus"
                      appearance="iconHovered"
                      size="s"
                      (click)="add()"
                    ></button>
                  <div
                    [class]="
                            i > 0 ? 'debit_device_multi' : 'debit_device'
                          "
                  >

                      <tui-combo-box
                        [class.tui-skeleton]="skeletonVisible"
                        [class.tui-skeleton_rounded]="skeletonVisible"
                        *tuiLet="availableDebitDevices$ | async as items"
                        tuiTextfieldSize="m"
                        class="tui-space_vertical-5"
                        formControlName="debit_device_id"
                        [stringify]="stringifyComboBox"
                        [valueContent]="
                          skeletonVisible ? loading : statusSelectContent
                        "
                      >
                        Выберите датчик дебита
                        <input
                          tuiTextfield
                          placeholder="Введите название датчика..."
                          (input)="
                              searchDebitDevice($event)
                          "
                        />

                        <tui-data-list-wrapper
                          *tuiDataList
                          [items]="items"
                          [itemContent]="statusSelectContent"
                        ></tui-data-list-wrapper>
                      </tui-combo-box>

                  </div>
                    <button
                      *ngIf="i === 0 && memoizedGetSections(objectInfoForm).length > 1"
                      class="add-button_toggle"
                      tuiIconButton
                      type="button"
                      [icon]="iconBetweenDebits"
                      appearance="iconHovered"
                      size="s"
                      [tuiHint]="'Кликнув, Вы смените знак арифметического действия ' +
                       'на противоположный, что означает сложение или вычетание предыдущего параметра датчика с ' +
                        'последующим'"
                      (click)="changeIconBetweenDebits()"
                    ></button>
                  <div class="debit_parameters">
                    <tui-select
                      [class.tui-skeleton]="skeletonVisible"
                      [class.tui-skeleton_rounded]="skeletonVisible"
                      *tuiLet="availableDebitFields as items"
                      class="b-form"
                      [tuiTextfieldLabelOutside]="false"
                      [valueContent]="items ? stringify(items) : loading"
                      formControlName="debit_device_field"
                      tuiTextfieldSize="m"
                    >
                      Параметр устройства
                      <ng-template tuiDataList>
                        <tui-data-list *ngIf="items; else loading">
                          <button
                            *ngFor="let item of items"
                            tuiOption
                            [value]="item.id"
                          >
                            {{ item.name }}
                          </button>
                        </tui-data-list>
                      </ng-template>
                      <ng-template #loading>
                        <tui-loader
                          class="tui-space_vertical-3 loader"
                        ></tui-loader>
                      </ng-template>
                    </tui-select>

                  </div>

                    <button
                      *ngIf="
                        memoizedGetSections(objectInfoForm).length > 1
                      "
                      class="delete-button"
                      tuiIconButton
                      type="button"
                      icon="tuiIconCloseLarge"
                      appearance="iconHovered"
                      size="s"
                      (click)="remove(i)"
                    ></button>

                </div>

              </div>
              <tui-error
                *ngIf="debitSensorErrorMessage"
                class="debit_device_error"
                error="{{ debitSensorErrorMessage }}"
              ></tui-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Широта *</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                [precision]="14"
                tuiTextfieldSize="m"
                formControlName="latitude"
              >
                Введите широту
              </tui-input-number>
              <tui-field-error formControlName="latitude"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Долгота *</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                [precision]="14"
                tuiTextfieldSize="m"
                formControlName="longitude"
              >
                Введите долготу
              </tui-input-number>
              <tui-field-error formControlName="longitude"></tui-field-error>
            </div>
          </div>
        </div>
        <span class="form-footer">* Обязательные для заполнения поля</span>
      </div>
    </form>
    <div class="inline-table" *ngIf="!editObjectMode">
      <div class="header">
        <span>ГЗУ</span>
        <app-common-buttons-oilfield
          [isEdit]="editObjectMode"
          (handelCancel)="cancelInput()"
          (handelChange)="changeInput()"
          (handelSave)="save()"
        >
        </app-common-buttons-oilfield>
      </div>
      <div class="table">
        <div class="row">
          <div class="label">Название</div>
          <div class="value">
            {{ contextObject.name }}
          </div>
        </div>
        <div class="row">
          <div class="label">Месторждение</div>
          <div class="value">
            {{ contextObject?.parent?.name || "Не выбрано" }}
          </div>
        </div>
        <div class="row">
          <div class="label">Всего скважин</div>
          <div class="value">
            {{ contextObject?.children?.length || 0 }}
          </div>
        </div>
        <div class="row">
          <div class="label">Объект нефтесбора</div>
          <div class="value">
            {{ contextObject.oil_parent?.name || "-" }}
          </div>
        </div>

        <div class="row">
          <div class="label">Датчик давления текущего объекта нефтесбора</div>
          <div class="value">
            {{ contextObject.curr_pressure?.name || "Не выбран" }}
            <tui-badge
              *ngIf="contextObject.curr_pressure"
              [status]="'primary'"
              class="status"
              [value]="currentPressureValue"
            ></tui-badge>
          </div>
        </div>

        <div class="row">
          <div class="label">Датчик давления следующего объекта нефтесбора</div>
          <div class="value">
            {{ contextObject.next_pressure?.name || "Не выбран" }}
            <tui-badge
              *ngIf="contextObject.next_pressure"
              [status]="'primary'"
              class="status"
              [value]="nextPressureValue"
            ></tui-badge>
          </div>
        </div>

        <div class="row">
          <div class="label">Датчик дебита</div>
        <div class="value">
          {{ getDevicesName() }}
          <tui-badge
            *ngIf="contextObject.debit_device_value !== null"
            [status]="'primary'"
            class="status"
            [value]="
              getDebitUnitDebitDevice(
                contextObject.debit_device_value,
                contextObject.debit_device_field
              )
            "
          ></tui-badge>
        </div>
      </div>

        <div class="row">
          <div class="label">Широта</div>
          <div class="value">
            <td>
              {{
                contextObject?.latitude !== null ? contextObject?.latitude : "-"
              }}
            </td>
          </div>
        </div>
        <div class="row">
          <div class="label">Долгота</div>
          <div class="value">
            <td>
              {{
                contextObject?.longitude !== null
                  ? contextObject?.longitude
                  : "-"
              }}
            </td>
          </div>
        </div>
        <div class="row">
          <div class="label">Скважин включено\остановлено\не в сети</div>
          <div class="value">
            <div>
              <span class="value__status __ok">{{
                contextObject.status?.boreholes?.ok || 0
              }}</span>
              <span class="value__status __stop">{{
                contextObject.status?.boreholes?.stop || 0
              }}</span>
              <span class="value__status __no-connection">{{
                contextObject.status?.boreholes?.no_connection || 0
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <table>
        <thead>
        <tr>
          <th>Датчики</th>
          <th></th>
        </tr>
        </thead>
        <tbody>

delete
      <tr>
          <td>Датчик давления</td>
          <td *ngIf="!data.pressure">Не выбран</td>
          <td *ngIf="data.pressure">{{ data.pressure?.name || "-" }}</td>
        </tr>
delete
        <tr>
          <td>Датчик дебита</td>
          <td>
            {{ getDevicesName() }}
            <tui-badge
              *ngIf="data.debit_device_value !== null"
              [status]="'primary'"
              style="margin-left: 15px"
              [value]="
                    getDebitUnitDebitDevice(
                      data.debit_device_value,
                      data.debit_device_field
                    )
                  "
            ></tui-badge>
          </td>
        </tr>
        </tbody>
      </table> -->

    </div>
  </app-primary-section>
  <app-primary-section *ngIf="(contextObject?.latitude !== null && contextObject?.longitude !== null) || editObjectMode">
    <div
      *ngIf="
        (contextObject?.latitude < 90 &&
          contextObject?.latitude > -90 &&
          contextObject?.longitude < 180 &&
          contextObject?.longitude > -180) ||
          editObjectMode;
        else unavailableCoords
      "
    >
      <app-gzu-card-map
        [onClickOnMap]="onClickOnMap"
        [data]="contextObject"
        [isEdit]="editObjectMode"
        [latitude]="latitude"
        [longitude]="longitude"
        [visibleMap]="visibleMap"
      ></app-gzu-card-map>
    </div>
    <ng-template #unavailableCoords>
      <div class="unavailable-map">
        <span class="unavailable-map__text">
          Точка на карте расположена вне области видимости карты, измените
          координаты в соответствии с допустимыми значениями, а именно: значения
          широты должны быть менее 90, но более -90, значения долготы должны
          быть менее 180, но более -180, чтобы отобразить точку на карте
        </span>
      </div>
    </ng-template>
  </app-primary-section>
  <ng-template #loading>
    <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
  </ng-template>
  <ng-template #statusSelectContent let-data>
    <div class="template">
      <div class="name">{{ data.name }}</div>
    </div>
  </ng-template>
</div>
<div *ngIf="!contextObject" class="common-info__no-content">
  Выберите объект
</div>
