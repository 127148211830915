import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { SelectedFilterObject } from "../../../../models/event.model";
import { LabelTableModel } from "../../../../models/table.model";

@Component({
  selector: "app-table-filter",
  templateUrl: "./table-filter.component.html",
  styleUrls: ["./table-filter.component.less"],
})
export class TableFilterComponent {
  @Input()
  columnName!: string;

  @Input()
  statusMessagesItems!: {
    form: FormGroup;
    values: Array<SelectedFilterObject>;
  };

  @Input()
  filterModalWindows!: { [key: string]: boolean };

  @Input()
  isSkeletonActive: boolean = false;

  @Input()
  label!: Array<LabelTableModel>;

  @Input()
  statusItems!: {
    form: FormGroup;
    values: Array<SelectedFilterObject>;
  };

  @Input()
  depositNameItems!: {
    form: FormGroup;
    values: Array<SelectedFilterObject>;
  };

  @Input()
  nameItems!: {
    form: FormGroup;
  };

  @Input()
  reactionItems!: {
    form: FormGroup;
    values: Array<SelectedFilterObject>;
  };

  public readonly EVENT: string = "eventStatus";

  public readonly DEPOSIT_NAME: string = "depositName";

  public readonly NAME: string = "name";

  public readonly MESSAGE: string = "message";

  public readonly REACTION: string = "reactAction";

  getTitleName(columnName: string): string {
    return this.label
      ? this.label.find((i: any) => i.name == columnName)?.title || columnName
      : columnName;
  }
}
