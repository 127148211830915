<app-primary-section>
  <form [formGroup]="DateFormGroup" class="form-date">
    <tui-input-date-range
      class="b-form _date-field"
      formControlName="date"
      [disabled]="!pressureSensorID"
      [max]="lastPossibleDayToChoose"
      [maxLength]="{ month: 3 }"
    >
      Выберите даты
      <input tuiTextfield placeholder="От - До" />
    </tui-input-date-range>
    <button
      tuiButton
      type="button"
      appearance="primary"
      (click)="handleChangeDates()"
      class="tui-space_right-3 tui-space_bottom-3"
    >
      Показать
    </button>
  </form>
</app-primary-section>
<app-primary-section *ngIf="!pressureHistoryData.length && !isClicked">
  <div class="_no-available">
    <span>Нажмите кнопку "Показать"</span>
  </div>
</app-primary-section>

<tui-loader [showLoader]="isLoading" [size]="'l'"></tui-loader>

<app-primary-section *ngIf="!isLoading && isClicked">
  <app-primary-table
    [isSort]="false"
    [isHasButtonSetting]="false"
    [emptyThInTitle]="false"
    [columns]="tableColumns"
    [disabled]="false"
    class="table"
  >
    <ng-container *ngIf="!pressureHistoryData.length">
      <tr>
        <td [attr.colspan]="2">
          <div class="no_data">Данные за указанный период отсутствуют</div>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="pressureHistoryData.length">
      <tr *ngFor="let row of pressureHistoryData">
        <td>{{ row.date_time }}</td>
        <td>{{ row.value }}</td>
      </tr>
    </ng-container>
  </app-primary-table>
  <tui-pagination
    class="events-paginator"
    *ngIf="pressureHistoryData.length && paginator.lastPage !== 1"
    [activePadding]="1"
    [length]="paginator.lastPage"
    (indexChange)="changePage($event)"
    [index]="paginator.currentPage - 1"
  ></tui-pagination>
</app-primary-section>
