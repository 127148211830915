<tui-tabs>
  <a tuiTab *ngFor="let tab of tabs" [routerLink]="tab.url" routerLinkActive
    >{{ tab.title }}
  </a>
  <ng-container *ngIf="selectedObject">
    <button
      tuiIconButton
      type="button"
      appearance="iconHovered"
      class="button"
      [icon]="icon"
      size="xl"
      routerLink="/main/map/{{ selectedObject.type }}/{{ selectedObject.id }}"
    ></button>
    <ng-template #icon>
      <tui-svg src="/assets/icons-header/map.svg"> </tui-svg>
    </ng-template>
  </ng-container>
</tui-tabs>
<router-outlet></router-outlet>
