<tui-loader [overlay]="true" [showLoader]="isLoading">
<app-primary-section *ngIf="pipeline">
  <app-secondary-section>
    <div
      id="relief-chart"
      class="relief-chart"
    ></div>
  </app-secondary-section>
</app-primary-section>
</tui-loader>
