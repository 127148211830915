import { createAction, props } from '@ngrx/store';

export const BoreholeActions = {
  load: 'Load boreholes from server',
  set: 'Set boreholes to store',
  status: 'Set status boreholes to store',
};

export const loadBoreholes = createAction(BoreholeActions.load);
export const setBoreholes = createAction(BoreholeActions.set, props<any>());
export const setStatusBorehole = createAction(
  BoreholeActions.status,
  props<any>()
);
