<div class="common-buttons">
  <ng-container *ngIf="!isEdit; else editButtons">
    <button
      tuiButton
      appearance="transparentIconAndText"
      size="s"
      icon="tuiIconEdit"
      type="button"
      class="edit"
      (click)="changeInput()"
    >
      Изменить
    </button>
  </ng-container>
  <ng-template #editButtons>
    <button
      tuiIconButton
      type="button"
      size="s"
      class="tui-space_right-1"
      icon="tuiIconCheck"
      appearance="transparentIcon"
      shape="rounded"
      (click)="save()"
    ></button>
    <button
      tuiIconButton
      type="button"
      class="close"
      appearance="transparentIcon"
      shape="rounded"
      size="s"
      icon="tuiIconClose"
      (click)="cancel()"
    ></button>
  </ng-template>
</div>
