import { Component, Input } from "@angular/core";
import { ObjectService } from "../../../../app/services/object.service";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { TuiDestroyService } from "@taiga-ui/cdk";
import { takeUntil } from "rxjs";

@Component({
  selector: "app-tree-branch",
  templateUrl: "./tree-branch.component.html",
  styleUrls: ["./tree-branch.component.less"],
  providers: [TuiDestroyService],
  animations: [
    trigger("expandedObjectArrow", [
      state("initial", style({ transform: "rotate(0)" })),
      state("expanded", style({ transform: "rotate(-180deg)" })),
      transition("initial <=> expanded", animate("0.2s")),
    ]),
    trigger("expandedObjectChildren", [
      state("initial", style({ height: 0 })),
      state("expanded", style({ height: "*" })),
      transition("initial <=> expanded", animate(".8s")),
    ]),
  ],
})
export class TreeBranchComponent {
  public isDraggable = false;

  @Input() children: any[] = [];

  @Input() hasStatusBlock: boolean = true;

  @Input() topLevel: boolean = false;

  @Input()
  schemeMode: boolean = false;

  constructor(
    public objectService: ObjectService,
    private destroy$: TuiDestroyService
  ) {
    this.objectService.treeIsDraggable
      .pipe(takeUntil(destroy$))
      .subscribe((val: any) => {
        this.isDraggable = val;
      });
  }

  getIsExpanded = (objectId: number) => {
    return ObjectService.expandedTrees[objectId] || false;
  };

  setIsExpanded = (objectId: number) => {
    ObjectService.expandedTrees[objectId] =
      ObjectService.expandedTrees[objectId] !== undefined
        ? !ObjectService.expandedTrees[objectId]
        : true;
    ObjectService.expandedTreesArrowStates[objectId] = ObjectService
      .expandedTrees[objectId]
      ? "expanded"
      : "initial";
  };

  getExpandedState = (objectId: number) => {
    return ObjectService.expandedTreesArrowStates[objectId] || "initial";
  };

  /**
   * Клик по тексту в дереве*/
  handleClickByText(value: any): void {
    if (this.schemeMode) {
      this.objectService.selectedObjectScheme.next(
        ObjectService.selectedObjectId !== null &&
          ObjectService.selectedObjectId === value.id
          ? null
          : value
      );
    }
    this.objectService.selectedObject.next(value);
  }

  /**
   * Возвращает вернуть путь изображения по id */
  returnCurrentPath(icon: string): string {
    return `assets/icons/${icon}.svg`;
  }

  toggleExpanded(objectId: number) {
    this.setIsExpanded(objectId);
  }

  drop(event: any) {
    moveItemInArray(this.children, event.previousIndex, event.currentIndex);
    const sortData = this.children.map((val: any, index: any) => {
      return { id: val.id, order: index };
    });
    this.objectService.sortTree(sortData).subscribe();
  }
}
