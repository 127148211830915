<ng-container [formGroup]="form">
  <tui-radio-labeled
    [formControlName]="formControlKey"
    [nativeId]="nativeId"
    [size]="size"
    [pseudoDisabled]="pseudoDisabled"
    [item]="item"
  >
    <ng-container *ngIf="content"
      ><div>{{ content }}</div>
      <div class="description">
        {{ description }}
      </div></ng-container
    >
  </tui-radio-labeled>
</ng-container>
