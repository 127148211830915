<app-primary-section *ngIf="this.debitDeviceField.length">
  <form [formGroup]="DateFormGroup" class="form-date">
    <tui-input-date-range
      class="b-form _date-field"
      formControlName="date"
      [disabled]="!objectId"
      [max]="lastPossibleDayToChoose"
      [maxLength]="{ month: 3 }"
    >
      Выберите даты
      <input tuiTextfield placeholder="От - До" />
    </tui-input-date-range>
    <button
      tuiButton
      type="button"
      appearance="primary"
      (click)="handleChangeDates()"
      class="tui-space_right-3 tui-space_bottom-3"
    >
      Показать
    </button>
  </form>
</app-primary-section>
<app-primary-section>
  <ng-container *ngIf="!this.debitDeviceField.length">
    <div class="_no-available">
      <span>
        Не выбран датчик дебита для объекта
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="this.debitDeviceField.length">
    <div *ngIf="!allDebitData.length" class="_no-available">
    <span *ngIf="isClicked; else withoutClick"
    >Нет данных для отображения на графике</span
    >
      <ng-template #withoutClick>Нажмите кнопку "Показать"</ng-template>
    </div>
    <div
      *ngIf="allDebitData.length"
      id="debit-history"
      class="debit-history__chart"
    ></div>
  </ng-container>

</app-primary-section>
<app-primary-section *ngIf="allDebitData.length">
  <app-primary-table
    [isSort]="false"
    [isHasButtonSetting]="false"
    [emptyThInTitle]="false"
    [columns]="tableColumns"
    [disabled]="false"
    class="table"
  >
    <!--    <ng-container *ngIf="!allDebitData.length">-->
    <!--      <tr>-->
    <!--        <td [attr.colspan]="2">-->
    <!--          <span *ngIf="isClicked; else withoutClick">Данные отсутствуют</span>-->
    <!--          <ng-template #withoutClick>Нажмите кнопку "Показать"</ng-template>-->
    <!--        </td>-->
    <!--      </tr>-->
    <!--    </ng-container>-->
    <ng-container>
      <tr *ngFor="let row of allDebitData">
        <td>{{ row.date }}</td>
        <td>{{ row.value | number: "1.0-2" }}</td>
      </tr>
    </ng-container>
  </app-primary-table>
  <!--  <tui-pagination
    class="events-paginator"
    *ngIf="allDebitData.length > 5"
    [activePadding]="1"
    [length]="paginator.lastPage"
    (indexChange)="changePage($event)"
    [index]="paginator.currentPage - 1"
  ></tui-pagination>-->
</app-primary-section>
