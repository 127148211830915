import { Component, Input, OnInit } from '@angular/core';
import { TypeNavigation } from '../../../../../models/navigation.model';
import { LIST_OF_NAV_ITEMS } from '../../../../const/app-consts';
import { AppService } from '../../../../services/app.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less'],
})
export class MenuComponent implements OnInit {
  @Input()
  public listOfNavLinks: TypeNavigation[] = LIST_OF_NAV_ITEMS;

  public isExpandedMenu: boolean = false;

  constructor(public appService: AppService) {}

  ngOnInit() {
    this.appService.leftMenuStatus.subscribe((isOpenMenu: boolean) => {
      this.isExpandedMenu = isOpenMenu;
    });
  }

  changeBurgerMenu() {
    if (this.isExpandedMenu) {
      this.isExpandedMenu = !this.isExpandedMenu;
      this.appService.leftMenuStatus.next(this.isExpandedMenu);
    }
  }

  getCurrentUrl(id: string): string {
    if (id === 'directories/params') {
      return `/assets/icons-header/directories.svg`;
    }
    return `/assets/icons-header/${id}.svg`;
  }
}
