import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ObjectService } from '../../../../services/object.service';
import {
  OBJECTS_DEBIT,
  STATUS_EVENT_BADGE,
  TYPE_MINING,
} from '../../../../const/app-consts';
import { TypeTree } from '../../../../../models/tree.model';
import { Router } from '@angular/router';
import { OilfieldService } from '../../../../services/oilfield.service';

@Component({
  selector: 'app-boreholes-object',
  templateUrl: './boreholes-object.component.html',
  styleUrls: ['./boreholes-object.component.less'],
})
export class BoreholesObjectComponent implements OnInit, OnDestroy {
  public label: any[] = [
    {
      name: 'id',
      title: 'ID',
      isSort: false,
      isFilter: false,
    },
    {
      name: 'name',
      title: 'Название',
      isSort: false,
      isFilter: false,
    },
    {
      name: 'eventFilterStatus',
      title: 'Статус',
      isSort: false,
      isFilter: false,
    },
    {
      name: 'typeMining',
      title: 'Тип добычи',
      isSort: false,
      isFilter: false,
    },
    {
      name: 'debit',
      title: 'Дебит',
      isSort: false,
      isFilter: false,
    },
    /*    {
      name: 'actions',
      title: 'Действия',
      isSort: false,
      isFilter: false,
    },*/
  ];

  public data: any = null;

  public selectedObj: any = null;

  public isFrontPagination: boolean = true;
  /*  public buttons = [
    { label: 'Запустить' },
    { label: 'Остановить', color: 'red' },
  ];*/

  //public selectedItem: Subject<any> = new Subject<any>();

  destroyer = new Subject();

  public statusTranslate: { [key: string]: string } = STATUS_EVENT_BADGE;

  @Input() contextObject?: TypeTree | null = null;

  constructor(
    public objectService: ObjectService,
    public oilfieldService: OilfieldService,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit() {
    if (
      this.contextObject &&
      this.contextObject.type === 'deposit' &&
      this.contextObject?.children?.length
    ) {
      this.selectedObj = this.contextObject;
      this.prepareData(this.contextObject);
    } else {
      this.objectService.selectedObject
        .pipe(takeUntil(this.destroyer))
        .subscribe((obj) => {
          if (obj && obj.type === 'deposit') {
            if (obj?.children?.length) {
              this.selectedObj = null;
              setTimeout(() => {
                this.selectedObj = obj;
                this.prepareData(obj);
              }, 5);
            } else {
              this.selectedObj = null;
              this.oilfieldService
                .getOilfieldsList()
                .subscribe((depositList: any) => {
                  const objBoreholes = depositList.filter((deposit: any) => {
                    return deposit.id === obj.id;
                  });
                  if (objBoreholes.length) {
                    this.selectedObj = objBoreholes[0];
                    this.prepareData(objBoreholes[0]);
                  } else if (!this.contextObject) {
                    this.router.navigate(['/main/objects/info']).then();
                  }
                });
            }
          } else {
            this.selectedObj = null;
          }
          this.changeDetector.markForCheck();
        });
    }
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }

  prepareData(obj: TypeTree) {
    const preData = obj?.children?.map((child: any) => {
      return Object.entries(child).filter((value: any) => {
        return ['id', 'name', 'status', 'attributes'].includes(value[0]);
      });
    });
    /* this.isForntPagination = false;
    setTimeout(() => {
      this.isForntPagination = true;
    }, 10);*/
    this.data = preData?.map((arrays: any) => {
      return arrays.reduce(
        (prevValue: any, currentValue: any) => {
          if (currentValue[0] === 'attributes' && currentValue[1]) {
            if (currentValue[1].debit) {
              prevValue.debit = OBJECTS_DEBIT[currentValue[1].debit];
            }
            if (currentValue[1].type_mining) {
              prevValue.typeMining = TYPE_MINING[currentValue[1].type_mining];
            }
          }
          switch (currentValue[0]) {
            case 'id':
              prevValue.id = currentValue[1] || '-';
              break;
            case 'name':
              prevValue.name = currentValue[1] || '-';
              break;
            case 'status':
              prevValue.eventFilterStatus =
                this.statusTranslate[currentValue[1] || 'no_connection'];
              break;
          }
          return prevValue;
        },
        {
          id: '-',
          name: '-',
          eventFilterStatus: '-',
          typeMining: '-',
          debit: '-',
        }
      );
    });
    this.changeDetector.markForCheck();
  }
}
