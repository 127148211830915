<ng-container [formGroup]="form" >

  <div class="toggle" [formGroup]="formManualToggle">
    <tui-toggle
            formControlName="manual"

            class="tui-space_left-1"
          ></tui-toggle>
          <span class="toggle_label" [ngClass]="{'grey' : !manualMode}">Ввести значение вручную</span>
  </div>

  <div [ngClass]="{'input': !greyStyle, 'grey-input' : greyStyle}" *ngIf="!manualMode">
    <tui-select
      [formControlName]="formControlNameSelect"
    >
      {{ label }}
      <span *ngIf="visualRequired" class="tui-required"></span>
      <ng-template tuiDataList>
        <tui-data-list>
          <button *ngFor="let item of itemsList" tuiOption [value]="item.value">
            {{ item.value }}
          </button>
        </tui-data-list>
      </ng-template>
    </tui-select>
    <tui-field-error [formControlName]="formControlNameSelect"></tui-field-error>
  </div>

  <div [ngClass]="{'input': !greyStyle, 'grey-input' : greyStyle}" *ngIf="manualMode">
    <tui-input [formControlName]="formControlNameSelect" tuiTextfieldSize="m">
      {{ label }}
      <input maxlength="40" tuiTextfield type="text" />
    </tui-input>
    <tui-field-error [formControlName]="formControlNameSelect"></tui-field-error>
  </div>

</ng-container>


