import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TYPE_MINING_VALUE } from '../../../../../../const/app-consts';
import { AddObjectType } from '../../../../../../../models/content-field.model';
import { ObjectType } from '../../../../../../../models/object-type';

@Component({
  selector: 'app-borehole-fields',
  templateUrl: './borehole-fields.component.html',
  styleUrls: ['./borehole-fields.component.less'],
})
export class BoreholeFieldsComponent {
  @Input()
  form!: FormGroup;

  public borehole = ObjectType.BOREHOLE;

  public boreholeSettings: Array<AddObjectType> = [
    {
      formControlNameItem: 'debit',
      title: 'Дебит скважины',
      placeholderText: 'Введите дебит скважины',
    },
    {
      isSelector: true,
      formControlNameItem: 'typeMining',
      title: 'Тип добычи',
      itemsList: TYPE_MINING_VALUE as any, // TODO ??
    },
    {
      formControlNameItem: 'waterCut',
      title: 'Обводнённость',
      isStringInput: true,
      maxLengthText: 51,
      isRequired: false,
      placeholderText: 'Введите обводнённость объекта',
    },
  ];
}
