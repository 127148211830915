import { Component, Inject, Input, OnInit } from '@angular/core';
import { PressureService } from '../../../../../services/pressure.service';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import * as luxon from 'luxon';
import { TYPE_EVENT_TRANSLATE } from '../../../../../const/app-consts';
import {
  PressureEventResponseType,
  PressureEventType,
} from '../../../../../../models/pressure-meter.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-pressure-sensor-events',
  templateUrl: './pressure-sensor-events.component.html',
  styleUrls: ['/pressure-sensor-events.component.less'],
})
export class PressureSensorEventsComponent implements OnInit {
  @Input() pressureSensorID: number | undefined;

  public paginator = {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    perPage: 50,
  };

  public tableColumns: any[] = [
    { name: 'index', title: '№' },
    { name: 'datetime', title: 'Дата и время' },
    { name: 'message', title: 'Сообщение' },
    { name: 'react', title: 'Реакция' },
  ];

  public statusMessages: any = {
    stop_to_ok: TYPE_EVENT_TRANSLATE.stop_to_ok,
    ok_to_stop: TYPE_EVENT_TRANSLATE.ok_to_stop,
    stop_to_no_connection: TYPE_EVENT_TRANSLATE.stop_to_no_connection,
    ok_to_no_connection: TYPE_EVENT_TRANSLATE.ok_to_no_connection,
    no_connection_to_stop: TYPE_EVENT_TRANSLATE.no_connection_to_stop,
    no_connection_to_ok: TYPE_EVENT_TRANSLATE.no_connection_to_ok,
    max_error: TYPE_EVENT_TRANSLATE.max_error,
    max_warning: TYPE_EVENT_TRANSLATE.max_warning,
    min_error: TYPE_EVENT_TRANSLATE.min_error,
    min_warning: TYPE_EVENT_TRANSLATE.min_warning,
    normal: TYPE_EVENT_TRANSLATE.normal,
  };

  public events: PressureEventType[] = [];

  public isLoading: boolean = false;

  public updateRequestSubject: Subject<any> = new Subject<any>();

  constructor(
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
    private pressureService: PressureService
  ) {}

  ngOnInit() {
    this.getEvents();
  }

  getEvents() {
    if (this.pressureSensorID) {
      this.isLoading = true;
      this.pressureService
        .getPressureEventsByPressureID(
          this.pressureSensorID,
          this.paginator.currentPage,
          this.paginator.perPage
        )
        .subscribe(
          (response: PressureEventResponseType) => {
            this.events = response.data;
            this.paginator.lastPage = response.last_page;
            this.paginator.count = response.count;
            this.isLoading = false;
            this.updateRequestSubject.next('update');
          },
          () => {
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        );
    }
  }

  markReadEvent(event: number) {
    this.pressureService.markOnePressureEventAsRead(+event).subscribe(() => {
      this.getEvents();
    });
  }

  markReadEventsByObject() {
    if (this.pressureSensorID) {
      this.pressureService
        .markAllPressureSensorEventsAsRead(+this.pressureSensorID)
        .subscribe(() => {
          this.alertService
            .open(
              'События по данному объекту успешно помечены как прочитанные.',
              { label: 'Успешно!', status: TuiNotification.Success }
            )
            .subscribe();
          this.getEvents();
        });
    }
  }

  getFormattedDate(react_at: any) {
    return luxon.DateTime.fromISO(`${react_at}Z`).toFormat(
      'dd.MM.yyyy HH:mm:ss'
    );
  }

  changePage($event: number) {
    this.paginator.currentPage = $event + 1;
    this.getEvents();
  }
}
