import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchemeService {
  constructor(public http: HttpClient) {}

  lineArr: Subject<any> = new Subject();

  setLine: Subject<any> = new Subject();

  changeScheme(id: number, body: any) {
    return this.http.post(`${environment.apiUrl}objects/scheme/update/${id}/`, {
      scheme: body,
    });
  }
}
