<div class="oilfield-add">
  <app-primary-section>
    <div class="oilfield-add__container">
      <h2 class="title">Добавить месторождение</h2>
      <div class="cross" (click)="closeModal()">
        <img src="/assets/cross.png" alt="(X)" />
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="form">
      <div class="form__title">
        <h4>Общая информация</h4>
      </div>
      <div class="form__field" [formGroup]="formOilfield">
        <span>Название *</span>
        <div class="input">
          <tui-input [tuiTextfieldMaxLength]="40" formControlName="name">
            <span class="input__placeholder">Укажите название</span>
          </tui-input>
          <tui-field-error formControlName="name"></tui-field-error>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div class="footer">
      <span>* Обязательные для заполнения поля</span>
      <div class="footer__buttons">
        <button (click)="submitForm()" class="footer-button _add">
          Добавить
        </button>
        <button (click)="closeDialog()" class="footer-button _cancel">
          Отменить
        </button>
      </div>
    </div>
  </app-primary-section>
</div>
