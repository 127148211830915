import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ObjectService } from '../../../../services/object.service';
import {
  LABEL_MAIN_OPTION_INFORMATION,
  OBJECT_FIELDS_TRANSLATES,
  OBJECTS_DEBIT,
  OBJECTS_DEBIT_LIST,
  TYPE_MINING,
  TYPE_OBJECTS,
} from '../../../../const/app-consts';
import {
  ObjectType,
  ObjectTypeTranslate,
} from '../../../../../models/object-type';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TypeTree } from '../../../../../models/tree.model';
import { OptionBody, OptionModel } from '../../../../../models/option.model';
import { DirectoriesService } from '../../../../services/directories.service';
import { TableModel } from '../../../../../models/table.model';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TuiAlertService } from '@taiga-ui/core';
import { TypeObjectModel } from '../../../../../models/type-object.model';
import { RequestCommonInfo } from '../../../../../models/common-info.model';

function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control.value?.trim() || '';
    if ((control?.value?.length || '') !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}
@Component({
  selector: 'app-common-info-object',
  templateUrl: './common-info-object.component.html',
  styleUrls: ['./common-info-object.component.less'],
})
export class CommonInfoObjectComponent implements OnInit, OnDestroy {
  public selectedObject: TypeTree | null = null;

  public label: any[] = [
    {
      name: 'name',
      title: 'Название',
      isSort: true,
      isFilter: true,
    },
    {
      name: 'value',
      title: 'Значение',
      isSort: true,
      isFilter: true,
    },
  ];

  public initialObject: any;

  public data: any = null;

  public optionTable: TableModel = {
    label: LABEL_MAIN_OPTION_INFORMATION,
    data: [],
  };

  public parentName: string = '-';

  public destroyer = new Subject();

  public optionList: Array<OptionBody> = [];

  private LIST_OF_ID: Array<string> = [
    'name',
    'id',
    'parent_id',
    'type',
    'latitude',
    'longitude',
    'type_mining',
    'debit',
    'water_cut',
  ];

  public editObjectMode: boolean = false;

  public editOptionMode: boolean = false;

  public typeEnum: any = {
    none: ObjectType.NONE,
    deposit: ObjectType.DEPOSIT,
    borehole: ObjectType.BOREHOLE,
    gzu: ObjectType.GZU,
    dns: ObjectType.DNS,
    bg: ObjectType.BG,
    bush: ObjectType.BUSH,
    ktp: ObjectType.KTP,
  };

  //TODO: add validation required for lan and lon
  public objectInfoForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(20),
      checkForFirstSpaceCharacter(),
    ]),
    parent_id: new FormControl(''),
    type: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
    attributes: new FormControl(''),
    debit: new FormControl(''),
    type_mining: new FormControl(''),
    water_cut: new FormControl(''),
  });

  private readonly controlTypes: any = {
    name: 'input',
    parent_id: 'search-selector',
    type: 'selector',
    latitude: 'number-input',
    longitude: 'number-input',
    debit: 'number-input',
    type_mining: 'selector',
    water_cut: 'input',
  };

  private editFields: Array<string> = [
    'name',
    'parent_id',
    'type',
    'latitude',
    'longitude',
    'debit',
    'water_cut',
    'type_mining',
  ];

  public typeObject = TYPE_OBJECTS;

  public currentType: number = 100;

  public latitude: any;

  public longitude: any;

  public optionData: OptionModel[] = [];

  @Input()
  public contextObject?: TypeTree | null = null;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    public objectService: ObjectService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private optionService: DirectoriesService
  ) {}

  ngOnInit() {
    if (this.contextObject) {
      this.selectedObject = this.contextObject;
      this.optionData = this.contextObject.params;
      this.generateTable(this.contextObject);
      this.back();
    } else if (!this.contextObject) {
      this.objectService.selectedObject
        .pipe(takeUntil(this.destroyer))
        .subscribe((obj: any) => {
          this.selectedObject = obj;
          if (obj) {
            this.optionData = obj.params;
            this.generateTable(obj);
          }
          this.back();
        });
    }

    if (!this.optionService.optionsList.length) {
      this.optionService
        .getAllParamList()
        .subscribe((option: Array<OptionBody>) => {
          this.optionService.optionsList = option;
          this.optionList = option;
        });
    } else {
      this.optionList = this.optionService.optionsList;
    }
    //this.objectService.selectedObject
    //  .pipe(takeUntil(this.destroyer))
    //  .subscribe((node: TypeTree) => {
    //    this.back();
    //    this.selectedObject = node;
    //    this.optionData = this.selectedObject?.params;
    //    this.generateTable(node);
    //  });
  }

  generateTable(node: TypeTree) {
    //if (node?.id === null) {
    //  return;
    //}
    //if (!node) {
    //  this.data = null;
    //  this.changeDetector.markForCheck();
    //  return;
    //}
    this.initialObject = node;
    this.parentName =
      this.objectService.parents.find((object: any) => {
        return object.value === node.parent_id;
      })?.label || '-';
    const deepNode: any = node;
    let attributesArr =
      Object.entries(node).find((item: any) => {
        return item[0] === 'attributes' && item[1] !== null;
      }) || null;
    if (attributesArr !== null) {
      Object.entries(attributesArr[1]).forEach((item: any) => {
        let key = item[0];
        deepNode[key] = item[1];
      });
    }
    if (!deepNode.hasOwnProperty('water_cut') && deepNode.type === 'borehole') {
      deepNode.water_cut = '-';
    }
    this.data = Object.entries(node)
      .filter((value: any) => {
        return this.LIST_OF_ID.includes(value[0]);
      })
      .reduce((prevValue: any, currentValue: any) => {
        prevValue.push({
          id: currentValue[0],
          control: this.controlTypes[currentValue[0]],
          name: OBJECT_FIELDS_TRANSLATES[currentValue[0]],
          value: this.getValues(currentValue, this.parentName, false),
        });
        this.objectInfoForm
          .get(currentValue[0])
          ?.setValue(this.getValues(currentValue, this.parentName));
        return prevValue;
      }, []);
    if (node?.params && node?.params.length) {
      this.optionTable.data = node?.params.map((option: OptionModel) => ({
        name: option.param.name,
        value: option.value ? option.value : '-',
      }));
    }

    const type =
      this.data.find((key: any) => key.id === 'type').value || 'Месторождения';
    this.currentType =
      this.typeObject.find((item: TypeObjectModel) => {
        return item.title === type;
      })?.value || 100;
    this.latitude =
      this.data.find((key: any) => key.id === 'latitude').value || 0;
    this.longitude =
      this.data.find((key: any) => key.id === 'longitude').value || 0;
    this.changeDetector.markForCheck();
  }

  // возвращает в преобразованном варианты данные для формы или просто вывода
  getValues(
    currentValue: any,
    parentName: any,
    form = true
  ): RequestCommonInfo {
    let key = currentValue[0];
    let value = currentValue[1];
    switch (key) {
      case 'type':
        if (form)
          return this.typeObject.find((item: any) => {
            return item.type === currentValue[1];
          });
        else {
          return ObjectTypeTranslate[currentValue[1]];
        }
      case 'parent_id':
        if (form) {
          return (
            this.objectService.parents.find((object: any) => {
              return object.value === currentValue[1];
            }) || {
              value: 'none',
              label: 'Родительский объект отсутствует',
            }
          );
        } else {
          return parentName;
        }
      case 'debit':
        return OBJECTS_DEBIT_LIST.includes(value)
          ? OBJECTS_DEBIT[value] || 0
          : value;
      case 'water_cut':
        return value ? value : '-';
      case 'type_mining':
        if (form) {
          return { value: value, label: TYPE_MINING[value] };
        } else {
          return TYPE_MINING[value] || '-';
        }
      case 'attributes':
        return;
      default:
        return currentValue[1] || '-';
    }
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }

  changeData() {
    return this.data.filter((item: any) => {
      if (this.editObjectMode) {
        return this.editFields.includes(item.id);
      }
      return true;
    });
  }

  changeInput() {
    if (this.editObjectMode && this.selectedObject) {
      let body: TypeTree;
      body = {
        ...this.selectedObject,
        parent_id:
          this.objectInfoForm.get('parent_id').value.value === 'none'
            ? null
            : this.objectInfoForm.get('parent_id').value.value,
        name: this.objectInfoForm.get('name').value,
        type: this.typeEnum[this.objectInfoForm.get('type').value.type],
        latitude:
          this.objectInfoForm.get('latitude').value === '-'
            ? null
            : this.objectInfoForm.get('latitude').value,
        longitude:
          this.objectInfoForm.get('longitude').value === '-'
            ? null
            : this.objectInfoForm.get('longitude').value,
      };
      if (this.objectInfoForm.get('type').value.type === 'borehole') {
        body.attributes = {
          type_mining: this.objectInfoForm.get('type_mining').value.value,
          debit: this.objectInfoForm.get('debit').value,
          water_cut:
            this.objectInfoForm.get('water_cut').value === '-'
              ? null
              : this.objectInfoForm.get('water_cut').value,
        };
      }
      this.objectService
        .updateObject(body, this.selectedObject.id)
        .subscribe((updatedObject: any) => {
          this.objectService.selectedObject.next(updatedObject.updated_object);
          this.objectService.getDataToTree().subscribe((data: any) => {
            this.objectService.updateTree.next(data);
          });
          this.notificationsService
            .open('Объект обновлен', {
              label: '',
              status: TuiNotification.Success,
              hasIcon: true,
              autoClose: true,
              hasCloseButton: true,
            })
            .subscribe();
        });
    }
    this.editObjectMode = !this.editObjectMode;
  }

  back(): void {
    this.editObjectMode = false;
  }
}
