import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-common-buttons-oilfield',
  templateUrl: './common-buttons-oilfield.component.html',
  styleUrls: ['./common-buttons-oilfield.component.less'],
})
export class CommonButtonsOilfieldComponent {
  @Input()
  isEdit: boolean = false;

  @Output()
  handelChange: EventEmitter<null> = new EventEmitter();

  @Output()
  handelSave: EventEmitter<null> = new EventEmitter();

  @Output()
  handelCancel: EventEmitter<null> = new EventEmitter();

  changeInput() {
    this.handelChange.emit();
  }

  save() {
    this.handelSave.emit();
  }

  cancel() {
    this.handelCancel.emit();
  }
}
