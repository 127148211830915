import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ObjectService } from 'src/app/services/object.service';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { Subject, takeUntil } from 'rxjs';
import { TypeTree } from '../../../../models/tree.model';
declare var draw2d: any;

@Component({
  selector: 'app-scheme-viewer',
  templateUrl: './scheme-viewer.component.html',
  styleUrls: ['./scheme-viewer.component.less'],
  providers: [TuiDestroyService],
})
export class SchemeViewerComponent implements OnInit, OnDestroy {
  public scheme: any;

  @ViewChild('schemeContent')
  public schemeHeadContainer: any;

  public isTouchScheme: boolean = false;

  public position: any = { top: 0, left: 0, x: 0, y: 0 };

  /* 1) Плучение координаты начала зажатия мыши - указать где то в переменной
   * 2) В mousemove будет просчет добавления пока  mouseleave не сработает
   * 3) Метод mouseleave по обнаружению того что он перестал нажимать
   * 4) Просчитать корректно логику передвижения мыши */
  @HostListener('mousedown', ['$event'])
  onClickByScheme(mouse: any) {
    this.isTouchScheme = true;
    this.position = {
      // The current scroll
      left: this.schemeHeadContainer.nativeElement.scrollLeft,
      top: this.schemeHeadContainer.nativeElement.scrollTop,
      // Get the current mouse position
      x: mouse.clientX,
      y: mouse.clientY,
    };
  }

  // метод по отпускание щажатой мышки
  @HostListener('mouseup', ['$event'])
  onUpClickByScheme() {
    this.isTouchScheme = false;
  }

  @HostListener('mousemove', ['$event'])
  onMove(mouse: any) {
    if (this.isTouchScheme) {
      const dx = mouse.clientX - this.position.x;
      const dy = mouse.clientY - this.position.y;
      this.schemeHeadContainer.nativeElement.scrollLeft =
        this.position.left - dx;
      this.schemeHeadContainer.nativeElement.scrollTop = this.position.top - dy;
    }
  }

  public allObjects = [];

  public currentObjectId: number | null = null;

  public eventsWithoutRefresh = 0;

  public timeTimeout!: any;

  public selectedObject: TypeTree | null = null;

  public loader: boolean = true;

  private jsonReader = new draw2d.io.json.Reader();

  destroyer = new Subject();

  @Input() contextObject?: TypeTree | null = null;

  @Output() closeModal?: any = new EventEmitter<any>();

  constructor(
    private destroy$: TuiDestroyService,
    private objectService: ObjectService,
    private router: Router,
    private eventService: EventService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.drawScheme();
  }

  ngOnDestroy() {
    clearTimeout(this.timeTimeout);
    this.destroyer.next(null);
    this.destroyer.complete();
  }

  updateStatus(): void {
    this.scheme.figures.data
      .filter((f: any) => f.cssClass == 'draw2d_shape_basic_Rectangle')
      .forEach((f: any) => {
        const objectNode: any = this.allObjects.find(
          (o: any) => o.id === f.userData.objectId
        );
        if (objectNode?.status) {
          let color = '#fff';
          if (objectNode.status === 'no_connection') {
            color = '#9cb0af';
          } else if (objectNode.status === 'ok') {
            color = '#90dc90';
          } else if (objectNode.status === 'stop') {
            color = '#f16d6d';
          }
          f.setBackgroundColor(color);
        }
      });
    //});
  }

  /* Записываем все координаты по x,y
   *  Ищем минимальное и максимальное по каждой из плоскостей
   *  Выбираем у кого отрыв максимальный
   *  Задаем новый зум*/
  zoomStartPosition(data: any) {
    if (!data?.length) {
      return;
    }
    let coordsX: number[] = [];
    let coordsY: number[] = [];
    data.forEach((element: any) => {
      if (element.x && element.y) {
        coordsX.push(element.x);
        coordsY.push(element.y);
      }
    });
    if (!coordsY.length && coordsX.length) {
      return;
    }
    let xDifference =
      Math.max.apply(Math, coordsX) - Math.min.apply(Math, coordsX);
    let yDifference =
      Math.max.apply(Math, coordsY) - Math.min.apply(Math, coordsY);
    let zoomCount =
      xDifference > yDifference ? xDifference / 850 : yDifference / 850;
    this.scheme.setZoom(zoomCount);
  }

  drawScheme() {
    //TODO: не лучшее решение...
    if (this.contextObject) {
      this.selectedObject = this.contextObject;
    } else {
      this.objectService.selectedObject
        .pipe(takeUntil(this.destroyer))
        .subscribe((obj: any) => {
          if (obj) {
            this.selectedObject = obj;
          }
        });
    }

    if (!this.selectedObject) {
      this.router.navigate(['/main/objects']).then();
      return;
    }
    this.currentObjectId = this.selectedObject.id;
    if (!this.selectedObject?.scheme?.scheme) {
      return;
    }
    this.objectService.getObjectList().subscribe((data: any) => {
      this.allObjects = data;
      if (this.allObjects) {
        this.eventService.newEvent
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.updateStatus();
            // if (this.eventsWithoutRefresh > 10) {
            //   clearTimeout(this.timeTimeout);
            //   this.eventsWithoutRefresh = 0;
            //   this.updateStatus();
            // } else {
            //   this.eventsWithoutRefresh++;
            //   clearTimeout(this.timeTimeout);
            //   this.timeTimeout = setTimeout(() => {
            //     this.eventsWithoutRefresh = 0;
            //     this.updateStatus();
            //   }, 5000);
            // }
          });
      }
      this.scheme = new draw2d.Canvas('scheme-view');
      this.zoomStartPosition(this.selectedObject?.scheme?.scheme);
      this.loadSchemeFromJson(this.selectedObject?.scheme?.scheme || [], data);
      this.scheme.installEditPolicy(
        new draw2d.policy.canvas.ReadOnlySelectionPolicy()
      );
      this.loader = false;
      this.changeDetectorRef.markForCheck();
    });
  }

  loadSchemeFromJson(scheme: any, data: any) {
    //TODO: не лучшее решение...
    for (let obj of scheme.filter(
      (o: any) => o.type === 'draw2d.shape.basic.Rectangle'
    )) {
      //console.log(obj, data);
      this.fillObjectNode(obj, data);
    }
    for (let conn of scheme.filter(
      (o: any) => o.type === 'draw2d.Connection'
    )) {
      this.drawConnection(conn);
    }
  }

  drawConnection(conn: any) {
    //console.log(conn);
    this.jsonReader.unmarshalConnection(this.scheme, conn);
  }

  getIconNameForBorehole(borehole: TypeTree) {
    return borehole.attributes?.type_mining &&
      borehole.attributes?.type_mining !== 'REPAIR' &&
      borehole.attributes?.type_mining !== 'NONE'
      ? `borehole_${borehole.attributes?.type_mining}`
      : 'borehole';
  }

  topPortLocator = (offset: number) => {
    const topL = draw2d.layout.locator.PortLocator.extend({
      init: function () {
        this._super();
      },
      relocate: function (index: any, figure: any) {
        this.applyConsiderRotation(
          figure,
          figure.getParent().getWidth() / 2 - offset,
          1
        );
      },
    });
    return new topL();
  };

  bottomPortLocator = (offset: number) => {
    const bottomL = draw2d.layout.locator.PortLocator.extend({
      init: function () {
        this._super();
      },
      relocate: function (index: any, figure: any) {
        var p = figure.getParent();

        this.applyConsiderRotation(
          figure,
          p.getWidth() / 2 - offset,
          p.getHeight()
        );
      },
    });
    return new bottomL();
  };

  fillObjectNode(obj: any, allObjects: any) {
    const objectNode: any = allObjects.find(
      (o: any) => o.id === obj.userData.objectId
    );
    const node = new draw2d.shape.basic.Rectangle({
      id: obj.id,
      bgColor: '#FFFFFF',
      resizeable: false,
      width: 75,
      height: 75,
      radius: 10,
      color: '#263238',
      userData: { objectId: objectNode?.id },
    });
    obj.ports.forEach((port: any, index: number) => {
      let locator: any = null;
      if (port.locator === 'draw2d.layout.locator.OutputPortLocator') {
        locator = new draw2d.layout.locator.OutputPortLocator();
      } else if (port.locator === 'draw2d.layout.locator.InputPortLocator') {
        locator = new draw2d.layout.locator.InputPortLocator();
      } else if (port.locator === 'draw2d.layout.locator.PortLocator') {
        if (index > 5 && index <= 8) {
          let offset = 0;
          if (index === 6) {
            offset = 15;
          } else if (index === 8) {
            offset = -15;
          }
          locator = this.topPortLocator(offset);
        } else if (index > 8 && index <= 11) {
          let offset = 0;
          if (index === 9) {
            offset = 15;
          } else if (index === 11) {
            offset = -15;
          }
          locator = this.bottomPortLocator(offset);
        }
      }
      const newPort = new draw2d.HybridPort(port);
      newPort.setName(`hybrid${index}`);
      if (locator) {
        node.addPort(newPort, locator);
      } else {
        node.addPort(newPort);
      }
    });
    if (objectNode?.type === 'borehole') {
      node.add(
        new draw2d.shape.basic.Image({
          path: `/assets/icons-white/${this.getIconNameForBorehole(
            objectNode
          )}.svg`,
          width: 50,
          height: 50,
          resizeable: false,
        }),
        new draw2d.layout.locator.CenterLocator()
      );
    } else {
      node.add(
        new draw2d.shape.basic.Image({
          path: `/assets/icons/${objectNode?.type || 'place-market'}.svg`,
          width: 50,
          height: 50,
          resizeable: false,
        }),
        new draw2d.layout.locator.CenterLocator()
      );
    }

    if (objectNode?.status) {
      let color = '#fff';
      if (objectNode?.status === 'no_connection') {
        color = '#9cb0af';
      } else if (objectNode?.status === 'ok') {
        color = '#90dc90';
      } else if (objectNode?.status === 'stop') {
        color = '#f16d6d';
      }
      node.setBackgroundColor(color);
    }
    node.add(
      new draw2d.shape.basic.Text({
        text: objectNode?.name,
        width: 75,
        zIndex: 20,
      }),
      new draw2d.layout.locator.TopLocator()
    );
    this.scheme.add(node, obj.x, obj.y);
  }

  handleClick() {
    this.closeModal.emit();
  }

  upZoom() {
    let zoom = this.scheme.getZoom();
    if (zoom > 1) {
      this.scheme.setZoom(zoom - 0.05);
    }
  }

  downZoom() {
    let zoom = this.scheme.getZoom();
    if (zoom < 3.8) {
      this.scheme.setZoom(zoom + 0.05);
    }
  }
}
