import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { divIcon, latLng, layerGroup, marker, tileLayer } from 'leaflet';
import { LeafletMapService } from '../../../../../../../services/translate-map-controls.service';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';

const L = LeafletMapService.get();

@Component({
  selector: 'app-bg-card-map',
  templateUrl: './bg-card-map.component.html',
  styleUrls: ['./bg-card-map.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BgCardMapComponent implements OnInit, OnChanges {
  @Input() data: any;

  @Input() onClickOnMap: any;

  @Input() isEdit: boolean = false;

  @Input() latitude: number = 0;

  @Input() longitude: number = 0;

  @Input() visibleMap: boolean = false;

  public objectsLayer: any = layerGroup();

  public options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        attribution: '',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      this.objectsLayer,
    ],
    zoom: 7,
    center: latLng(55.560981, 52.638667),
    minZoom: 2,
    maxBounds: LeafletMapService.getBounds(),
  };

  public key = 0;

  public mapDns: any = null;

  public defaultTransparentBalloon: boolean = false;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService
  ) {}

  ngOnInit(): void {
    if (this.data) {
      if (
        typeof this.data.latitude === 'number' &&
        typeof this.data.longitude === 'number'
      ) {
        this.options.center = latLng(this.data.latitude, this.data.longitude);
        this.objectsLayer.clearLayers();
        this.objectsLayer.addLayer(
          marker([this.data.latitude, this.data.longitude], {
            icon: divIcon({
              html: `<div class="object-map-balloon object-status-${this.getColorClassForBalloon()}"
style="font-size: ${
                this.data.name.length > 6
                  ? (5 / this.data.name.length) * 20 >= 10
                    ? 10
                    : (5 / this.data.name.length) * 20
                  : 10
              }px">
                             <div class="object-content"><span>${
                               this.data.name
                             }</span>
                             <img src="/assets/icons-white/${this.getIconNameForBorehole()}.svg" alt=""></div></div>`,
            }),
            objectId: this.data.id,
          } as any)
        );
        this.key = +Object.keys(this.objectsLayer._layers)[0];
      } else {
        this.defaultTransparentBalloon = true;
        this.options.center = latLng(55.875310835696816, 52.32888273921966);
        this.objectsLayer.clearLayers();
        this.objectsLayer.addLayer(
          marker([55.875310835696816, 52.32888273921966], {
            icon: divIcon({
              html: `<div></div>`,
            }),
            objectId: this.data.id,
          } as any)
        );
        this.key = +Object.keys(this.objectsLayer._layers)[0];
      }
    }
  }

  ngOnChanges(changes: any) {
    if (
      this.data &&
      (!changes?.longitude?.firstChange || !changes?.latitude?.firstChange) &&
      ((typeof changes?.latitude?.currentValue === 'number' &&
        changes?.latitude?.currentValue !== changes?.latitude?.previousValue) ||
        (typeof changes?.longitude?.currentValue === 'number' &&
          changes?.longitude?.currentValue !==
            changes?.longitude?.previousValue))
    ) {
      //this.objectsLayer.clearLayers();
      this.handleClickOnMap(
        { latlng: { lat: this.latitude, lng: this.longitude } },
        'component'
      );
      this.mapDns.panTo(new L.LatLng(this.latitude, this.longitude));
    }
  }

  getColorClassForBalloon() {
    return 'default';
  }

  getIconNameForBorehole() {
    return this.data?.attributes?.type_mining
      ? `bg_${this.data?.attributes?.type_mining}`
      : 'bg';
  }

  //  handleClickOnMap($event: LeafletMouseEvent) {
  handleClickOnMap(coords: any, source: 'map' | 'component') {
    if (this.isEdit) {
      if (
        source === 'component' &&
        this.defaultTransparentBalloon &&
        coords.latlng.lat < 90 &&
        coords.latlng.lat > -90 &&
        coords.latlng.lng < 180 &&
        coords.latlng.lng > -180
      ) {
        this.objectsLayer.clearLayers();
        this.objectsLayer.addLayer(
          marker([coords.latlng.lat, coords.latlng.lng], {
            icon: divIcon({
              html: `<div class="object-map-balloon object-status-${this.getColorClassForBalloon()}" style="font-size: ${
                this.data.name.length > 6
                  ? (5 / this.data.name.length) * 20 >= 10
                    ? 10
                    : (5 / this.data.name.length) * 20
                  : 10
              }px">
                             <div class="object-content"><span>${
                               this.data.name
                             }</span>
                             <img src="/assets/icons-white/${this.getIconNameForBorehole()}.svg" alt=""></div></div>`,
            }),
            objectId: this.data.id,
          } as any)
        );
        this.key = +Object.keys(this.objectsLayer._layers)[0];
        this.defaultTransparentBalloon = false;
      } else if (
        source === 'component' &&
        !this.defaultTransparentBalloon &&
        coords.latlng.lat < 90 &&
        coords.latlng.lat > -90 &&
        coords.latlng.lng < 180 &&
        coords.latlng.lng > -180
      ) {
        const latitude = coords.latlng.lat;
        const longitude = coords.latlng.lng;
        const newLatLng = latLng(latitude, longitude);
        this.objectsLayer._layers[this.key].setLatLng(newLatLng);
        this.latitude = latitude;
        this.longitude = longitude;
        /* this.objectsLayer.clearLayers();
        this.objectsLayer.addLayer(
          marker([coords.latlng.lat, coords.latlng.lng], {
            icon: divIcon({
              html: `<div class="object-status-${this.getColorClassForBalloon()} ${this.getSizeClassForBalloon(
                this.data?.name
              )}">
                             <div class="object-content"><span>${
                               this.data.name
                             }</span>
                             <img src="/assets/icons-white/${this.getIconNameForBorehole()}.svg" alt=""></div></div>`,
            }),
            objectId: this.data.id,
          } as any)
        );
        this.key = +Object.keys(this.objectsLayer._layers)[0];*/
      } else if (
        source === 'map' &&
        !this.defaultTransparentBalloon &&
        coords.latlng.lat < 90 &&
        coords.latlng.lat > -90 &&
        coords.latlng.lng < 180 &&
        coords.latlng.lng > -180
      ) {
        const latitude = coords.latlng.lat;
        const longitude = coords.latlng.lng;
        const newLatLng = latLng(latitude, longitude);
        this.objectsLayer._layers[this.key].setLatLng(newLatLng);
        this.latitude = latitude;
        this.longitude = longitude;
        this.onClickOnMap.emit({
          lat: coords.latlng.lat,
          lng: coords.latlng.lng,
        });
      } else if (
        source === 'map' &&
        this.defaultTransparentBalloon &&
        coords.latlng.lat < 90 &&
        coords.latlng.lat > -90 &&
        coords.latlng.lng < 180 &&
        coords.latlng.lng > -180
      ) {
        const latitude = coords.latlng.lat;
        const longitude = coords.latlng.lng;
        const newLatLng = latLng(latitude, longitude);
        this.objectsLayer._layers[this.key].setLatLng(newLatLng);
        this.latitude = latitude;
        this.longitude = longitude;
        this.onClickOnMap.emit({
          lat: coords.latlng.lat,
          lng: coords.latlng.lng,
        });
        this.objectsLayer.clearLayers();
        this.objectsLayer.addLayer(
          marker([coords.latlng.lat, coords.latlng.lng], {
            icon: divIcon({
              html: `<div class="object-map-balloon object-status-${this.getColorClassForBalloon()}" style="font-size: ${
                this.data.name.length > 6
                  ? (5 / this.data.name.length) * 20 >= 10
                    ? 10
                    : (5 / this.data.name.length) * 20
                  : 10
              }px">
                             <div class="object-content"><span>${
                               this.data.name
                             }</span>
                             <img src="/assets/icons-white/${this.getIconNameForBorehole()}.svg" alt=""></div></div>`,
            }),
            objectId: this.data.id,
          } as any)
        );
        this.key = +Object.keys(this.objectsLayer._layers)[0];
        this.defaultTransparentBalloon = false;
      } else {
        this.notificationsService
          .open('Эта зона карты недоступна для взаимодействия с ней', {
            status: TuiNotification.Error,
          })
          .subscribe();
      }
    }
  }

  onMapReady(map: any) {
    LeafletMapService.translateZoomButtons(map);
    this.mapDns = map;
  }
}
