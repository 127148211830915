<div class="top-header">
  <app-sidebar class="top-header__menu">
    <app-menu class="menu" [class._open]="isOpened"></app-menu>
  </app-sidebar>
  <h2 [class]="router.url === '/main/oilfield' ? 'title-oilfield' : 'title'">
    {{ appService.title | async }}
  </h2>
  <div *ngIf="router.url === '/main/oilfield'" class="dashboard-container">
    <app-dashboard> </app-dashboard>
  </div>
  <div
    class="top-header-elements"
    [class.divider]="router.url === '/main/oilfield'"
  >
    <button
      data-test="icon_settings"
      tuiIconButton
      type="button"
      appearance="menu"
      size="m"
      icon="tuiIconSettingsLarge"
      class="icon"
      [routerLink]="'/main/setting/'"
      (click)="openSettings()"
      [tuiHint]="'Настройки'"
      tuiHintDirection="bottom-left"
      [tuiHintShowDelay]="10"
      [tuiHintHideDelay]="10"
    ></button>
    <button
      data-test="icon_log_out"
      tuiIconButton
      type="button"
      appearance="menu"
      size="m"
      icon="tuiIconLoginLarge"
      class="icon"
      (click)="logout()"
      [tuiHint]="'Выход'"
      tuiHintDirection="bottom-left"
      [tuiHintShowDelay]="10"
      [tuiHintHideDelay]="10"
    ></button>
  </div>
  <!--  <div (click)="testMMMM()">test</div>-->
</div>
