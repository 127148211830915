import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ObjectService } from '../../../../../../services/object.service';
import { TYPE_MINING_LIST } from '../../../../../../const/app-consts';
import * as memoizee from 'memoizee';
@Component({
  selector: 'app-common-form',
  templateUrl: './common-form.component.html',
  styleUrls: ['./common-form.component.less'],
})
export class CommonFormComponent implements OnInit {
  //форма с главной компоненты
  @Input()
  form!: FormGroup;

  //обработанные данные для формы
  // (список с типом поля, названия и значения)
  @Input()
  formData!: any;

  //выбранный с дерева объект
  @Input()
  selectedObject!: any;

  //id выбранного объекта думаю он тут не нужен
  @Input()
  idSelectedType: number = 100;

  //список родительских объектов нужен для первого инпута
  parents: Array<{ value: string | number; label: string }> = [];

  //список типов объектов
  @Input()
  typeObjectList!: any;

  //режим редактирования
  @Input()
  isEdit: boolean = false;

  @Input()
  isSlidePopup!: boolean;

  //отмена режима редактирования
  @Output()
  handleCancel: EventEmitter<null> = new EventEmitter();

  @Output()
  handleChange: EventEmitter<null> = new EventEmitter();

  public typeMiningList = TYPE_MINING_LIST;

  public onClickOnMap = new EventEmitter();

  public isDeleteObjectDialog: boolean = false;

  constructor(private objectService: ObjectService) {}

  async ngOnInit() {
    this.parents = await this.objectService.parentsList;
    this.onClickOnMap.pipe().subscribe((coordinates) => {
      this.form?.get('latitude')?.setValue(coordinates.lat);
      this.form?.get('longitude')?.setValue(coordinates.lng);
    });
  }

  itemContent(id: string) {
    return this.form?.get(id)?.value?.label || this.form?.get(id)?.value?.title;
  }

  isInputType(control: string): boolean {
    return control === 'input';
  }

  isBorehole(type: number): boolean {
    return type === 105;
  }

  isSearchSelectorType(control: string): boolean {
    return control === 'search-selector';
  }

  isSelectorType(control: string): boolean {
    return control === 'selector';
  }

  openDialogForDelete() {
    this.isDeleteObjectDialog = true;
  }

  confirmObjectDelete = () => {
    this.objectService.deleteObject(this.selectedObject.id).subscribe(() => {
      this.objectService.selectedObject.next(null);
      this.objectService.getDataToTree().subscribe((data: any) => {
        this.objectService.updateTree.next(data);
      });
      this.isDeleteObjectDialog = false;
    });
  };

  cancelDeleteObject = () => {
    this.isDeleteObjectDialog = false;
  };

  cancel() {
    this.handleCancel.emit();
  }

  changeInput() {
    this.handleChange.emit();
  }

  public getCurrentArray = memoizee((id: string) => {
    switch (id) {
      case 'parent_id':
        return this.parents;
      case 'type':
        return this.typeObjectList;
      case 'type_mining':
        return this.typeMiningList;
    }
  });

  isNumberInputType(control: string) {
    return control === 'number-input';
  }
}
