import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TabsModel } from 'src/models/tabs.model';
import { ObjectService } from '../../../services/object.service';
import { OilfieldService } from '../../../services/oilfield.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { GzuService } from '../../../services/gzu.service';

@Component({
  selector: 'app-gzu-card',
  templateUrl: './gzu-card.component.html',
  styleUrls: ['./gzu-card.component.less'],
})
export class GzuCardComponent implements OnInit, OnDestroy {
  @Input()
  public objectID: string | null | number = null;

  @Output()
  handleSubmit: EventEmitter<null> = new EventEmitter();

  //список tab
  public tabs: TabsModel[] = [
    { url: 'info', title: 'Информация' },
    { url: 'boreholes', title: 'Скважины' },
    { url: 'events', title: 'События' },
    { url: 'history', title: 'История дебита' },
  ];

  public destroyer: Subject<null> = new Subject();

  //выбранный tab
  public activeItemIndex: TabsModel = this.tabs[0];

  public gzu: any = null;

  public skeletonVisible: boolean = false;

  public loaderIsVisible: boolean = false;

  public availableParentsList: any[] = [];

  constructor(
    private objectsService: ObjectService,
    private oilfieldService: OilfieldService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private gzuService: GzuService
  ) {}

  //взятие с роутера id месторождения и по нему запрос на получение данных
  ngOnInit() {
    this.loaderIsVisible = true;
    this.skeletonVisible = true;
    let gzuId;
    if (this.objectID) {
      gzuId = this.objectID;
    } else {
      gzuId = this.route.snapshot.paramMap.get('id');
    }

    if (gzuId) {
      forkJoin({
        gzu: this.objectsService.getObjectByID(+gzuId),
        parents: this.objectsService.getDepositList(),
      })
        .pipe(takeUntil(this.destroyer))
        .subscribe(
          (result: any) => {
            const { gzu, parents } = result;
            this.gzu = { ...gzu };
            this.gzuService.gzu.next(gzu);
            this.availableParentsList = [...parents];
            this.loaderIsVisible = false;
            this.skeletonVisible = false;
          },
          () => {
            this.loaderIsVisible = false;
            this.skeletonVisible = false;
          },
          () => {
            this.loaderIsVisible = false;
            this.skeletonVisible = false;
          }
        );
    }
    this.gzuService.gzu
      .pipe(takeUntil(this.destroyer))
      .subscribe((gzu: any) => {
        this.gzu = { ...gzu };
      });
  }

  //смена навигационных tab
  changeTabElement(tab: TabsModel) {
    this.activeItemIndex = tab;
  }

  //при каком либо изменении запрос на получения свежих данных
  getEditableDeposit(name: string | boolean) {
    this.gzu.name = typeof name === 'string' ? name : this.gzu.name;
    if (name) {
      this.handleSubmit.emit();
    }
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
