import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.less"],
})
export class SearchComponent {
  search: string = "";

  @Output()
  handlerWrittenText: EventEmitter<string> = new EventEmitter();

  handleChange() {
    this.handlerWrittenText.emit(this.search);
  }
}
