import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TableModel } from '../../../../../../../models/table.model';
import { LABEL_MAIN_OPTION_INFORMATION } from '../../../../../../const/app-consts';
import {
  BodyModel,
  OptionModel,
} from '../../../../../../../models/option.model';

@Component({
  selector: 'app-option-form',
  templateUrl: './option-form.component.html',
  styleUrls: ['./option-form.component.less'],
})
export class OptionFormComponent implements OnInit {
  @Input()
  isEdit: boolean = false;

  @Input()
  selectedOption: OptionModel[] = [];

  @Input()
  optionTable: TableModel = {
    label: LABEL_MAIN_OPTION_INFORMATION,
    data: [],
  };

  public handleSaveSubject: Subject<null> = new Subject();

  public selectedOptionForSensors: BodyModel[] = [];

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    this.selectedOption?.map((option: OptionModel) => {
      this.selectedOptionForSensors.push({
        device_id: option.device_id,
        parametrs_id: option.param.id,
      });
      this.changeDetector.markForCheck();
    });
  }
}
