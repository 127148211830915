import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  BoreholeCreateRequest,
  BoreholeType,
} from '../../models/borehole.model';

@Injectable({
  providedIn: 'root',
})
export class BoreholeService {
  constructor(private http: HttpClient) {}

  public borehole: Subject<any> = new Subject<any>();

  createBorehole(body: BoreholeCreateRequest): Observable<any> {
    return this.http
      .post<{ created_object: BoreholeType }>(
        `${environment.apiUrl}objects/boreholes/create/`,
        body
      )
      .pipe(
        map((response: { created_object: BoreholeType }) => {
          return response.created_object;
        })
      );
  }
}
