import { Component } from "@angular/core";

@Component({
  selector: "app-components-list",
  templateUrl: "./components-list.component.html",
  styleUrls: ["./components-list.component.less"],
})
export class ComponentsListComponent {
  public primaryTableColumns = [
    { name: "id", title: "ID" },
    { name: "name", title: "Название" },
    { name: "debit", title: "Режимный дебит" },
    { name: "status", title: "Статус" },
  ];

  public primaryTableData = [
    { id: 1 },
    { name: 1 },
    { debit: 1 },
    { status: 1 },
  ];

  onPrimaryTableSort($event: any) {
    console.log($event);
  }
}
