<div class="components-list">
  <tui-island>
    <h1 class="tui-island__category">Компоненты:</h1>
    <button
      class="tui-space_vertical-6 tui-space_horizontal-6"
      tuiButton
      appearance="primary"
      size="m"
      type="button"
      routerLink="/ui/plus"
    >
      plus
    </button>
    <button
      class="tui-space_vertical-6 tui-space_horizontal-6"
      tuiButton
      appearance="primary"
      size="m"
      type="button"
      routerLink="/ui/layout"
    >
      layout
    </button>
    <button
      class="tui-space_vertical-6 tui-space_horizontal-6"
      tuiButton
      appearance="primary"
      size="m"
      type="button"
      routerLink="/ui/filter"
    >
      filter
    </button>
    <button
      class="tui-space_vertical-6 tui-space_horizontal-6"
      tuiButton
      appearance="primary"
      size="m"
      type="button"
      routerLink="/ui/table"
    >
      table
    </button>
    <button
      class="tui-space_vertical-6 tui-space_horizontal-6"
      tuiButton
      appearance="primary"
      size="m"
      type="button"
      routerLink="/ui/tree"
    >
      tree
    </button>
    <button
      class="tui-space_vertical-6 tui-space_horizontal-6"
      tuiButton
      appearance="primary"
      size="m"
      type="button"
      routerLink="/ui/form"
    >
      form
    </button>
    <button
      class="tui-space_vertical-6 tui-space_horizontal-6"
      tuiButton
      appearance="primary"
      size="m"
      type="button"
      routerLink="/ui/scheme"
    >
      scheme
    </button>
  </tui-island>
  <div class="component-item">
    <h3>SectionPrimary</h3>
    <app-primary-section>
      <app-secondary-section><h2>Заголовок</h2></app-secondary-section>
    </app-primary-section>
  </div>
  <div class="component-item">
    <h3>TablePrimary</h3>
    <app-primary-section>
      <app-primary-table
        [columns]="primaryTableColumns"
        [isSort]="true"
        (sorted)="onPrimaryTableSort($event)"
        [disabled]="false"
      >
        <tr #row>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td class="actions">
            <tui-hosted-dropdown
              #dropdown
              [tuiDropdownMaxHeight]="500"
              [content]="content"
            >
              <button
                tuiIconButton
                appearance="flat"
                type="button"
                icon="tuiIconSettingsLarge"
                [pseudoHovered]="dropdown.open || null"
              ></button>
            </tui-hosted-dropdown>
            <ng-template #content>
              <tui-data-list>
                <button tuiOption size="l">Действие 1</button>
                <button tuiOption size="l">Действие 2</button>
              </tui-data-list>
            </ng-template>
          </td>
        </tr>
        <tr #row>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td class="actions">
            Компонент для<br />действий в <br />таблице сделаете <br />свои
          </td>
        </tr>
        <tr #row>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td class="actions"></td>
        </tr>
        <tr #row>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td class="actions"></td>
        </tr>
        <tr #row>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td>1</td>
          <td class="actions"></td>
        </tr>
      </app-primary-table>
    </app-primary-section>
  </div>
</div>
