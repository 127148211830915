<tui-loader [overlay]="true" [showLoader]="loaderIsVisible" [size]="'xxl'" class="{{ loaderIsVisible ? 'loader' : '' }}">
<div *ngIf="borehole">
  <app-primary-section>
    <h1
      class="tui-text_h1"
      [class.tui-skeleton]="skeletonVisible"
      [class.tui-skeleton_rounded]="skeletonVisible"
    >
      Скважина: {{ borehole?.name }}
    </h1>
    <tui-tabs [(activeItemIndex)]="activeItemMenuIndex" class="tabs">
      <button
        *ngFor="let tab of tabs"
        [class.tui-skeleton]="skeletonVisible"
        [class.tui-skeleton_rounded]="skeletonVisible"
        tuiTab
        (click)="onClickTab(tab.name)"
        class="tabs__btns"
      >
          {{ tab.title }}
        </button>
      </tui-tabs>
    </app-primary-section>
    <div
      [class.tui-skeleton]="skeletonVisible"
      [class.tui-skeleton_rounded]="skeletonVisible"
    >
      <app-borehole-card-info
        (handleSubmit)="reloadTable($event)"
        [data]="borehole"
        *ngIf="currentTabName === 'info'"
        [parentsList]="availableParentsList"
      ></app-borehole-card-info>
      <app-borehole-card-events
        [boreholeId]="borehole.id"
        *ngIf="currentTabName === 'events'"
      ></app-borehole-card-events>
      <app-borehole-card-debit-history
        *ngIf="currentTabName === 'history'"
        [objectDebitDeviceFields]="borehole.debit_device_field"
        [objectId]="borehole.id"
        [objectType]="'borehole'"
      ></app-borehole-card-debit-history>
    </div>
  </div>
</tui-loader>
