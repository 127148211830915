import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TuiAlertService } from '@taiga-ui/core';
import { ObjectService } from '../../../../../services/object.service';
import { TypeTree } from 'src/models/tree.model';
import * as memoizee from 'memoizee';
import { TuiContextWithImplicit, tuiPure } from '@taiga-ui/cdk';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import {
  maxValueValidator,
  minValueValidator,
} from '../../../dns-card/components/common-info-dns/common-info-dns.component';
import { PressureService } from '../../../../../services/pressure.service';
import { PipelineService } from '../../../../../services/pipeline.service';
import { ActualValueType } from '../../../../../../models/pressure-meter.model';

function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control.value?.trim() || '';
    if ((control?.value?.length || '') !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

type DataForSelectField = {
  id: number | string;
  name: string;
};
export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

@Component({
  selector: 'app-common-info-bg',
  templateUrl: './common-info-bg.component.html',
  styleUrls: ['./common-info-bg.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class CommonInfoBgComponent implements OnInit, OnChanges, OnDestroy {
  public pressureList: DataForSelectField[] = [];

  public oilPipelines: DataForSelectField[] = [];

  public waterPipelines: DataForSelectField[] = [];

  public label: any[] = [
    {
      name: 'name',
      title: 'Название',
      isSort: true,
      isFilter: true,
    },
    {
      name: 'value',
      title: 'Значение',
      isSort: true,
      isFilter: true,
    },
  ];

  public data: any = null;

  public editObjectMode: boolean = false;

  public onClickOnMap = new EventEmitter();

  public visibleMap: boolean = false;

  @Input()
  public contextObject: any = null;

  @Output() handleChange: EventEmitter<boolean> = new EventEmitter();

  public objectInfoForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    deposit: new FormControl('', [Validators.required]),
    oil_parent_id: new FormControl(null),
    curr_pressure: new FormControl(null, []),
    next_pressure: new FormControl(null, []),
    oil_pipe: new FormControl(null, []),
    water_pipe: new FormControl(null, []),
    latitude: new FormControl(null, [
      Validators.required,
      Validators.min(-90),
      Validators.max(90),
    ]),
    longitude: new FormControl(null, [
      Validators.required,
      Validators.min(-180),
      Validators.max(180),
    ]),
  });

  //для формы -> список скважин
  public depositList: any = [];

  public latitude: number = 0;

  public longitude: number = 0;

  public listOfOil: any = {
    gzuList: [],
    dnsList: [],
    bgList: [],
  };

  public availableDns: any[] = [];

  public availableGzu: any[] = [];

  public availableBg: any[] = [];

  public destroyer: Subject<null> = new Subject();

  public parent: any | null = null;

  public oilParent: any | null = null;

  public disabledOilField: boolean = false;

  public currentPressureValue: string = '';

  public nextPressureValue: string = '';

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    public objectService: ObjectService,
    private pressureService: PressureService,
    private pipelineService: PipelineService
  ) {}

  async ngOnInit() {
    if (this.contextObject) {
      await this.getValuesForPressureSensorField();

      await this.getPipelineValuesForSelectFields();

      const deposits = await this.objectService.deposits;

      this.oilCollection();
      this.depositList = deposits.map((item: TypeTree) => {
        return { id: item.id, title: item.name };
      });
      this.parent = this.depositList.find((item: TypeTree) => {
        return item.id === this.contextObject.parent_id;
      });
      this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
      this.objectInfoForm
        .get('oil_parent_id')
        ?.setValue(this.contextObject?.oil_parent?.id || null);
      this.oilParent = this.contextObject?.oil_parent?.id || null;
      this.objectInfoForm
        .get('curr_pressure')
        ?.setValue(this.contextObject?.curr_pressure || null);
      this.objectInfoForm
        .get('next_pressure')
        ?.setValue(this.contextObject?.next_pressure || null);
      this.objectInfoForm.get('deposit')?.setValue(this.parent || null);
      this.objectInfoForm
        .get('oil_pipe')
        ?.setValue(
          this.findObjectByIdFromArray(
            this.contextObject?.oil_pipe_id,
            this.oilPipelines
          ) || null
        );
      this.objectInfoForm
        .get('water_pipe')
        ?.setValue(
          this.findObjectByIdFromArray(
            this.contextObject?.water_pipe_id,
            this.waterPipelines
          ) || null
        );
      this.latitude = this.contextObject?.latitude;
      this.longitude = this.contextObject?.longitude;
      this.objectInfoForm
        .get('latitude')
        ?.setValue(
          typeof this.contextObject?.latitude === 'number' &&
            typeof this.contextObject?.longitude === 'number'
            ? this.contextObject?.latitude
            : null
        );
      this.objectInfoForm
        .get('longitude')
        ?.setValue(
          typeof this.contextObject?.longitude === 'number' &&
            typeof this.contextObject?.latitude === 'number'
            ? this.contextObject?.longitude
            : null
        );
      this.onClickOnMap
        .pipe(takeUntil(this.destroyer))
        .subscribe((coordinates) => {
          this.objectInfoForm.get('latitude')?.setValue(coordinates.lat);
          this.objectInfoForm.get('longitude')?.setValue(coordinates.lng);
        });
      this.objectInfoForm
        .get('latitude')
        .valueChanges.pipe(takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (this.objectInfoForm.get('latitude')?.valid) {
            this.latitude = item;
            return;
          }
          return;
        });
      this.objectInfoForm
        .get('longitude')
        .valueChanges.pipe(takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (this.objectInfoForm.get('longitude')?.valid) {
            this.longitude = item;
            return;
          }
          return;
        });
      this.visibleMap = true;
    }
    this.objectInfoForm
      .get('deposit')
      .valueChanges.pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (
          !item ||
          (!this.availableGzu.length &&
            !this.availableDns.length &&
            !this.availableBg.length)
        ) {
          return;
        }
        this.objectInfoForm.get('oil_parent_id').setValue(null);
        this.filterOilCollection(item.id);
      });
    await this.updatePressureSensorValues();
  }

  async ngOnChanges() {
    await this.updatePressureSensorValues();
  }

  async updatePressureSensorValues() {
    this.currentPressureValue = await this.getPressureSensorValue(
      this.contextObject?.curr_pressure?.id
    );
    this.nextPressureValue = await this.getPressureSensorValue(
      this.contextObject?.next_pressure?.id
    );
  }

  async getPressureSensorValue(pressureId: number | null) {
    if (!pressureId) {
      return 'Датчик не выбран';
    }
    const pressureValueList: ActualValueType[] = await this.pressureService
      .values;
    const rightPressure = pressureValueList.find(
      (item) => item.pressure_id === pressureId
    );
    return rightPressure?.value
      ? rightPressure?.value + ' МПа'
      : 'Значение недоступно';
  }

  private async getPipelineValuesForSelectFields() {
    this.oilPipelines = this.prepareArrayForSelectField(
      await this.pipelineService.oilPipelinesList
    );
    this.waterPipelines = this.prepareArrayForSelectField(
      await this.pipelineService.waterPipelinesList
    );
  }

  private async getValuesForPressureSensorField() {
    const pressure = await this.pressureService.pressure;
    this.pressureList = this.prepareArrayForSelectField(pressure);
  }

  public memoizedGetCurrentLat = memoizee(
    (latitude: number | null, longitude: number | null) => {
      return typeof latitude === 'number' && typeof longitude === 'number'
        ? latitude
        : '-';
    }
  );

  public memoizedGetCurrentLon = memoizee(
    (latitude: number | null, longitude: number | null) => {
      return typeof latitude === 'number' && typeof longitude === 'number'
        ? longitude
        : '-';
    }
  );

  save() {
    if (!this.objectInfoForm.valid) {
      return this.objectInfoForm.markAllAsTouched();
    }
    // Если выбран нефтепровод и/или водопровод и не выбран объект нефтесбора, то требуем указать объект нефтесбора
    if (
      !this.objectInfoForm.get('oil_parent_id')?.value &&
      (this.objectInfoForm.get('oil_pipe').value?.id ||
        this.objectInfoForm.get('water_pipe').value?.id)
    ) {
      this.notificationsService
        .open(
          'Для выбора нефтепровода и/или водопровода необходимо указать объект нефтесбора',
          {
            label: '',
            status: TuiNotification.Warning,
            hasIcon: true,
            autoClose: true,
            hasCloseButton: true,
          }
        )
        .subscribe();
      return;
    }
    if (
      this.objectInfoForm.dirty ||
      this.contextObject.latitude !==
        this.objectInfoForm.get('latitude').value ||
      this.contextObject.longitude !==
        this.objectInfoForm.get('longitude').value
    ) {
      let body: TypeTree = {
        ...this.contextObject,
        name: this.objectInfoForm.get('name').value,
        parent_id: this.objectInfoForm.get('deposit')?.value?.id || null,
        oil_parent_id: this.objectInfoForm.get('oil_parent_id')?.value || null,
        curr_pressure_id:
          this.objectInfoForm.get('curr_pressure')?.value?.id || null,
        next_pressure_id:
          this.objectInfoForm.get('next_pressure')?.value?.id || null,
        oil_pipe_id: this.objectInfoForm.get('oil_pipe').value?.id || null,
        water_pipe_id: this.objectInfoForm.get('water_pipe').value?.id || null,
        latitude:
          typeof this.objectInfoForm.get('latitude')?.value === 'number'
            ? this.objectInfoForm.get('latitude')?.value
            : null,
        longitude:
          typeof this.objectInfoForm.get('longitude')?.value === 'number'
            ? this.objectInfoForm.get('longitude')?.value
            : null,
      };
      this.objectService
        .updateObject(body, this.contextObject.id)
        .pipe(takeUntil(this.destroyer))
        .subscribe(async (deposit: any) => {
          this.contextObject = {
            ...this.contextObject,
            name: deposit.updated_object.name,
            parent_id: deposit.updated_object.parent_id || null,
            oil_parent_id: deposit.updated_object.oil_parent?.id || null,
            curr_pressure: deposit.updated_object.curr_pressure,
            next_pressure: deposit.updated_object.next_pressure,
            oil_pipe: this.findObjectByIdFromArray(
              deposit.updated_object.oil_pipe_id,
              this.oilPipelines
            ),
            water_pipe: this.findObjectByIdFromArray(
              deposit.updated_object.water_pipe_id,
              this.waterPipelines
            ),
            latitude: deposit.updated_object.latitude,
            longitude: deposit.updated_object.longitude,
          };
          this.parent = this.depositList.find((item: TypeTree) => {
            return item.id === this.contextObject.parent_id;
          });
          this.oilParent =
            this.objectInfoForm.get('oil_parent_id')?.value || null;
          this.notificationsService
            .open('Объект обновлен', {
              label: '',
              status: TuiNotification.Success,
              hasIcon: true,
              autoClose: true,
              hasCloseButton: true,
            })
            .subscribe();
          this.handleChange.emit(true);
          this.editObjectMode = false;
        });
    } else {
      this.editObjectMode = false;
    }
  }

  changeInput(): void {
    this.editObjectMode = !this.editObjectMode;
  }

  cancelInput(): void {
    this.objectInfoForm.patchValue({
      name: this.contextObject?.name || '',
      deposit: this.parent || null,
      oil_parent_id: this.oilParent || null,
    });
    this.objectInfoForm
      .get('latitude')
      ?.setValue(
        typeof this.contextObject?.latitude === 'number' &&
          typeof this.contextObject?.longitude === 'number'
          ? this.contextObject?.latitude
          : null
      );
    this.objectInfoForm
      .get('longitude')
      ?.setValue(
        typeof this.contextObject?.longitude === 'number' &&
          typeof this.contextObject?.latitude === 'number'
          ? this.contextObject?.latitude
          : null
      );

    if (
      this.objectInfoForm.get('latitude').touched ||
      this.objectInfoForm.get('longitude').touched
    ) {
      this.visibleMap = true;
    }

    this.editObjectMode = false;
  }

  @tuiPure
  stringify(items: readonly any[]): any {
    const map = new Map(
      items.map(({ id, name }) => [id, name] as [number, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }

  // для нефтесбора
  getConcatOils() {
    return [...this.availableDns, ...this.availableGzu, ...this.availableBg];
  }

  getDepositNameByID = memoizee((parent_id: any) => {
    return new Promise((resolve) => {
      this.objectService.getDepositsList().subscribe((res: TypeTree[]) => {
        resolve(
          res.find((item) => {
            return item.id === parent_id;
          })?.name || 'Не выбран'
        );
      });
      return;
    });
  });

  public oilCollection() {
    forkJoin({
      gzu: this.objectService.getGzuList(),
      dns: this.objectService.getDnsList(),
      bg: this.objectService.getBGList(),
    }).subscribe((result: any) => {
      this.availableGzu = result.gzu;
      this.availableDns = result.dns;
      this.availableBg = result.bg;
      this.availableBg = this.availableBg.filter((item: any) => {
        return item.id !== this.contextObject.id;
      });
      this.filterOilCollection(this.contextObject.parent_id);
    });
  }

  filterOilCollection(idParent: number) {
    this.listOfOil.gzuList = this.availableGzu.filter((element: any) => {
      return element.parent_id === idParent;
    });
    this.listOfOil.dnsList = this.availableDns.filter((element: any) => {
      return element.parent_id === idParent;
    });
    this.listOfOil.bgList = this.availableBg.filter((element: any) => {
      return element.parent_id === idParent;
    });
    if (
      !this.listOfOil.gzuList.length &&
      !this.listOfOil.dnsList.length &&
      !this.listOfOil.bgList.length
    ) {
      this.objectInfoForm.get('oil_parent_id').disable();
      this.disabledOilField = true;
    } else {
      this.objectInfoForm.get('oil_parent_id').enable();
      this.disabledOilField = false;
    }
  }

  prepareArrayForSelectField(array: any): DataForSelectField[] {
    return array.map((v: DataForSelectField) => ({
      id: v.id,
      name: v.name,
    }));
  }

  findNameByIdFromArray(id: number | string, dataArray: DataForSelectField[]) {
    let result = dataArray.find((item) => item.id === id);
    return result ? result.name : null;
  }

  findObjectByIdFromArray(
    id: number | string,
    dataArray: DataForSelectField[]
  ) {
    let result = dataArray.find((item) => item.id === id);
    return result ? result : null;
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
