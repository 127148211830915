import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-form-input-password",
  templateUrl: "./form-input-password.component.html",
  styleUrls: ["./form-input-password.component.less"],
})
export class FormInputPasswordComponent {
  @Input()
  form!: FormGroup;

  @Input()
  formControlKey!: string;

  @Input()
  label!: string;

  @Input()
  visualRequired!: boolean;

  @Input()
  nativeId!: string;

  @Input()
  tuiTextfieldMaxLength!: number | null;

  @Input()
  tuiTextfieldExampleText!: string;
}
