import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ObjectService } from '../../../../../../../../services/object.service';

@Component({
  selector: 'app-common-form-oilfield',
  templateUrl: './common-form-oilfield.component.html',
  styleUrls: ['./common-form-oilfield.component.less'],
})
export class CommonFormOilfieldComponent {
  //форма с главной компоненты
  @Input()
  form!: FormGroup;

  //обработанные данные для формы
  // (список с типом поля, названия и значения)
  @Input()
  formData!: any;

  //выбранный с дерева объект
  @Input()
  selectedObject!: any;

  ////список типов объектов
  //@Input()
  //typeObjectList!: any;

  //режим редактирования
  @Input()
  isEdit: boolean = false;

  //@Input()
  //isSlidePopup!: boolean;

  //отмена режима редактирования
  @Output()
  handleCancel: EventEmitter<null> = new EventEmitter();

  @Output()
  handleChange: EventEmitter<null> = new EventEmitter();

  //public typeMiningList = TYPE_MINING_LIST;

  //public onClickOnMap = new EventEmitter();

  constructor(private objectService: ObjectService) {}

  cancel() {
    this.handleCancel.emit();
  }

  changeInput() {
    this.handleChange.emit();
  }
}
