<div *ngIf="selectedObject">
  <app-primary-section>
    <h2 class="oilfield-popup__title">
      {{ selectedObject?.name || "object" }}
    </h2>
    <tui-tabs class="tabs">
      <button
        class="tabs__btns"
        tuiTab
        *ngFor="let tab of tabs"
        (click)="changeTabElement(tab)"
      >
        <div>
          {{ tab.title }}
        </div>
      </button>
    </tui-tabs>
  </app-primary-section>
  <div *ngIf="activeItemIndex.url === 'info'" class="container__form">
    <app-common-info-dns
      [contextObject]="selectedObject"
      (handleChange)="getEditableDeposit()"
    >
    </app-common-info-dns>
  </div>
</div>
