import {
  // ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { EventService } from 'src/app/services/event.service';
import {
  BADGE_TRANSLATE,
  TYPE_EVENT_TRANSLATE,
} from '../../../../const/app-consts';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import * as luxon from 'luxon';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-borehole-card-events',
  templateUrl: './borehole-card-events.component.html',
  styleUrls: ['./borehole-card-events.component.less'],
})
export class BoreholeCardEventsComponent implements OnInit, OnDestroy {
  @Input() boreholeId: number | undefined;

  public paginator = {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    perPage: 50,
  };

  public tableColumns: any[] = [
    { name: 'index', title: '№' },
    { name: 'datetime', title: 'Дата и время' },
    { name: 'message', title: 'Сообщение' },
    { name: 'status', title: 'Статус' },
    { name: 'react', title: 'Реакция' },
  ];

  public statusTitles: any = {
    ok: BADGE_TRANSLATE.ok,
    stop: BADGE_TRANSLATE.stop,
    no_connection: BADGE_TRANSLATE.no_connection,
  };

  public statusMessages: any = {
    stop_to_ok: TYPE_EVENT_TRANSLATE.stop_to_ok,
    ok_to_stop: TYPE_EVENT_TRANSLATE.ok_to_stop,
    stop_to_no_connection: TYPE_EVENT_TRANSLATE.stop_to_no_connection,
    ok_to_no_connection: TYPE_EVENT_TRANSLATE.ok_to_no_connection,
    no_connection_to_stop: TYPE_EVENT_TRANSLATE.no_connection_to_stop,
    no_connection_to_ok: TYPE_EVENT_TRANSLATE.no_connection_to_ok,
    max_error: TYPE_EVENT_TRANSLATE.max_error,
    max_warning: TYPE_EVENT_TRANSLATE.max_warning,
    min_error: TYPE_EVENT_TRANSLATE.min_error,
    min_warning: TYPE_EVENT_TRANSLATE.min_warning,
  };

  public events: any[] = [];

  public destroyer = new Subject();

  public isLoading: boolean = true;

  public updateRequestSubject: Subject<any> = new Subject<any>();

  constructor(
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
    private eventService: EventService // private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.boreholeId) {
      this.loadEvents();
    }
  }

  loadEvents() {
    this.isLoading = true;
    this.getEvents();
    this.eventService.newEvent.pipe(takeUntil(this.destroyer)).subscribe(() => {
      this.getEvents();
      this.isLoading = false;
    });
  }

  getEvents() {
    if (this.boreholeId) {
      this.eventService
        .getEventsByObjectId(
          this.boreholeId,
          this.paginator.currentPage,
          this.paginator.perPage
        )
        .subscribe((data: any) => {
          this.events = data.data;
          this.paginator.lastPage = data.last_page;
          this.paginator.count = data.count;
          // this.changeDetector.detectChanges();
          this.updateRequestSubject.next('update');
          this.isLoading = false;
        });
    }
  }

  markReadEvent(event: any) {
    this.eventService.eventUpdate(event.id).subscribe(() => {
      if (this.boreholeId) {
        this.eventService
          .getEventsByObjectId(this.boreholeId)
          .subscribe((data: any) => {
            this.events = data.data;
            this.updateRequestSubject.next('update');
          });
      }
    });
  }

  markReadEventsByObject() {
    if (this.boreholeId) {
      this.eventService
        .readAllEventsByObject(+this.boreholeId)
        .subscribe(() => {
          this.alertService
            .open(
              'События по данному объекту успешно помечены как прочитанные.',
              { label: 'Успешно!', status: TuiNotification.Success }
            )
            .subscribe();
          if (this.boreholeId) {
            this.eventService
              .getEventsByObjectId(+this.boreholeId)
              .subscribe((data: any) => {
                this.events = data.data;
                this.updateRequestSubject.next('update');
              });
          }
        });
    }
  }

  getFormattedDate(react_at: any) {
    return luxon.DateTime.fromISO(`${react_at}Z`).toFormat(
      'dd.MM.yyyy HH:mm:ss'
    );
  }

  changePage($event: number) {
    this.isLoading = true;
    this.paginator.currentPage = $event + 1;
    setTimeout(() => {
      this.loadEvents();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
