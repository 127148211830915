<ng-container [formGroup]="form">
  <div
    class="form-select-search"
    [class._gray]="this.style === 'gray'"
    [class._white]="this.style === 'white'"
  >
    <mat-form-field
      style="width: 100%"
      class="example-full-width"
      appearance="outline"
    >
      <mat-label>{{ label }}</mat-label>
      <input
        type="text"
        [placeholder]="label"
        #text
        matInput
        [formControlName]="formControlKey"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayWith"
        showPanel="true"
        (optionsScroll)="onScroll()"
      >
        <mat-option
          *ngFor="let option of filteredItems$ | async"
          [value]="option"
        >
          {{ option.label || option.name }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</ng-container>
