<tui-loader [overlay]="true" [showLoader]="loaderIsVisible" [size]="'xxl'">
  <div *ngIf="gzu">
    <app-primary-section>
      <h2
        class="oilfield-popup__title"
        [class.tui-skeleton]="skeletonVisible"
        [class.tui-skeleton_rounded]="skeletonVisible"
      >
        {{ gzu?.name || "object" }}
      </h2>
      <tui-tabs class="tabs">
        <button
          class="tabs__btns"
          tuiTab
          *ngFor="let tab of tabs"
          (click)="changeTabElement(tab)"
          [class.tui-skeleton]="skeletonVisible"
          [class.tui-skeleton_rounded]="skeletonVisible"
        >
          <div>
            {{ tab.title }}
          </div>
        </button>
      </tui-tabs>
    </app-primary-section>
    <div
      [class.tui-skeleton]="skeletonVisible"
      [class.tui-skeleton_rounded]="skeletonVisible"
    >
      <!--  <div *ngIf="activeItemIndex.url === 'info'" class="container__form">-->
      <app-common-info-gzu
        [contextObject]="gzu"
        (handleChange)="getEditableDeposit($event)"
        *ngIf="activeItemIndex.url === 'info'"
        [depositList]="availableParentsList"
      >
      </app-common-info-gzu>
      <!--  </div>-->
      <app-boreholes-gzu
        *ngIf="activeItemIndex.url === 'boreholes'"
        [selectedDeposit]="gzu"
        (handleChange)="getEditableDeposit($event)"
      ></app-boreholes-gzu>
      <app-borehole-card-events
        [boreholeId]="gzu.id"
        *ngIf="activeItemIndex.url === 'events'"
      ></app-borehole-card-events>
      <app-borehole-card-debit-history
        *ngIf="activeItemIndex.url === 'history'"
        [objectDebitDeviceFields]="gzu.debit_device_field"
        [objectId]="gzu.id"
        [objectType]="'gzu'"
      ></app-borehole-card-debit-history>
    </div>
  </div>
</tui-loader>
