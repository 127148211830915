import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Observable, of, Subject } from "rxjs";
import { ItemsClass } from "../../../search-selector/search-selector.component";
import {
  debounceTime,
  exhaustMap,
  filter,
  scan,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { takeWhileInclusive } from "rxjs-take-while-inclusive";

@Component({
  selector: "app-form-select-search",
  templateUrl: "./form-select-search.component.html",
  styleUrls: ["./form-select-search.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSelectSearchComponent implements OnInit {
  @Input()
  form!: FormGroup;

  @Input()
  formControlKey!: string;

  @Input()
  label: string = "";

  @Input()
  visualRequired!: boolean;

  @Input()
  nativeId!: string;

  @Input()
  tuiTextfieldMaxLength!: number | null;

  @Input()
  tuiTextfieldExampleText!: string;

  @Input()
  items: readonly any[] = [];

  @Input()
  isGrouped!: boolean;

  @Input()
  style: "gray" | "default" | "white" = "default";

  private nextPage$: any = new Subject();

  filteredItems$: any;

  nothingFind: boolean = true;

  // constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    // this.form.controls[this.formControlKey].setValidators();
    // this.form.controls[this.formControlKey].();
    const filter$ = this.form.get(this.formControlKey)?.valueChanges.pipe(
      startWith(""),
      debounceTime(200), //задержка на 2 сек до отправки, если поиск не изменился то отправляет
      filter((q) => typeof q === "string")
    );
    // this.form.valueChanges.subscribe();
    this.filteredItems$ = filter$?.pipe(
      // eslint-disable-next-line
      switchMap((filter: string) => {
        //filter: то что вбиваем в инпут поиска
        //Note: Reset the page with every new search text
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          // Note: Until the backend responds, ignore NextPage requests.
          exhaustMap(() => this.getStatusList(filter, currentPage)),
          tap(() => currentPage++),
          /** Note: This is a custom operator because we also need the last emitted value.
           Note: Stop if there are no more pages, or no results at all for the current search text.
           */
          takeWhileInclusive(
            (p: Array<{ value: "none" | number; label: string }>) =>
              p.length > 0
          ) as any,
          scan(
            (
              all: Array<{ value: "none" | number; label: string }>,
              searchedValues: Array<{ value: "none" | number; label: string }>
            ) => {
              this.nothingFind = !!searchedValues.length;
              return all.concat(searchedValues);
            },
            []
          )
        );
      })
    );
  }

  getStatusList(startsWith: string, page: number): Observable<ItemsClass[]> {
    const take = 10;
    const skip = page > 0 ? (page - 1) * take : 0;
    const filtered = this.items.filter((option) =>
      (option.label || option.name)
        .toLowerCase()
        .startsWith(startsWith.toLowerCase())
    );
    return of(filtered.slice(skip, skip + take));
  }

  displayWith(element: any) {
    return element ? element.label || element.name : null;
  }

  onScroll() {
    this.nextPage$.next();
  }
}
