<div class="sidebar">
  <app-sidebar>
    <app-menu></app-menu>
  </app-sidebar>
</div>
<div class="main-title">
  <app-main-header [dropdownOpen]="dropdownOpen"> </app-main-header>
  <div class="main-pages">
    <router-outlet></router-outlet>
  </div>
</div>
