import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { TuiHostedDropdownComponent } from "@taiga-ui/core";

@Component({
  selector: "app-table-dropdown-button",
  templateUrl: "./table-dropdown-button.component.html",
  styleUrls: ["./table-dropdown-button.component.less"],
})
export class TableDropdownButtonComponent {
  @ViewChild(TuiHostedDropdownComponent)
  component?: TuiHostedDropdownComponent;

  @Input()
  actionButtons!: Array<{ value: number; label: string }>;

  @Output() getAction: any = new EventEmitter<string>();

  public open = false;

  selectAction(button: string) {
    this.getAction.emit(button);

    this.open = false;

    if (this.component && this.component.nativeFocusableElement) {
      this.component.nativeFocusableElement.focus();
    }
  }
}
