<div class="common-buttons">
  <ng-container *ngIf="!isEdit; else editButtons">
    <button
      tuiIconButton
      type="button"
      size="s"
      icon="tuiIconDraft"
      appearance="menu"
      (click)="changeInput()"
    ></button>
    <button
      tuiIconButton
      type="button"
      size="s"
      icon="tuiIconRemoveLarge"
      appearance="menu"
      (click)="openDialogForDelete()"
    ></button>
  </ng-container>
  <ng-template #editButtons>
    <button
      tuiIconButton
      type="button"
      size="s"
      class="tui-space_right-1"
      icon="tuiIconCheck"
      appearance="menu"
      (click)="changeInput()"
    ></button>
    <button
      tuiIconButton
      type="button"
      class="cross"
      appearance="menu"
      size="s"
      icon="tuiIconClose"
      (click)="cancel()"
    ></button>
  </ng-template>
</div>
