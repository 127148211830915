import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TabsModel } from 'src/models/tabs.model';
import { TypeTree } from '../../../../models/tree.model';
import { ActivatedRoute } from '@angular/router';
import { PowerLinesService } from 'src/app/services/power-lines.service';

@Component({
  selector: 'app-power-line-card',
  templateUrl: './power-line-card.component.html',
  styleUrls: ['./power-line-card.component.less'],
})
export class PowerLineCardComponent implements OnInit {
  //id месторождения
  @Input()
  public objectID: string | null | number = null;

  @Output()
  openDeletePopUpFunction: EventEmitter<null> = new EventEmitter();

  @Output()
  handleSubmit: EventEmitter<null> = new EventEmitter();

  //данные выбранного объекта
  public selectedObject?: any | TypeTree = null;

  //список tab
  public tabs: TabsModel[] = [{ url: 'info', title: 'Информация' }];

  //выбранный tab
  public activeItemIndex: TabsModel = this.tabs[0];

  public isLoading = true;

  constructor(
    private powerLinesService: PowerLinesService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  //взятие с роутера id месторождения и по нему запрос на получение данных
  ngOnInit() {
    if (!this.objectID) {
      this.objectID = this.route?.snapshot?.paramMap?.get('id') as string;
    }
    this.powerLinesService
      .getPowerLineByID(+this.objectID)
      .subscribe((waterPipeline: any) => {
        this.selectedObject = waterPipeline;
        this.changeDetectorRef.markForCheck();
        this.isLoading = false;
      });
  }

  //смена навигационных tab
  changeTabElement(tab: TabsModel) {
    this.activeItemIndex = tab;
  }

  //при каком либо изменении запрос на получения свежих данных
  getEditablePowerLine() {
    this.isLoading = true;
    this.powerLinesService
      .getPowerLineByID(+(this.objectID as string))
      .subscribe((waterPipeline: any) => {
        this.selectedObject = waterPipeline;
        this.changeDetectorRef.markForCheck();
        this.isLoading = false;
        this.handleSubmit.emit();
      });
  }

  // Диалог удаления объекта (нефтепровода, водопровода, ЛЭП)
  onDelete() {
    this.openDeletePopUpFunction.emit(this.selectedObject);
  }
}
