import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { BodyModel, OptionBody } from '../../models/option.model';
import { TypeTree } from '../../models/tree.model';
import { ParamList } from '../../models/param.list';

@Injectable({
  providedIn: 'root',
})
export class DirectoriesService {
  public optionsList: OptionBody[] = [];

  constructor(public http: HttpClient) {}

  get options(): Promise<OptionBody[]> {
    return new Promise((resolve) => {
      if (this.optionsList.length) {
        resolve(this.optionsList);
      } else {
        return this.getAllParamList().subscribe((res: OptionBody[]) => {
          resolve(res);
        });
      }
      return;
    });
  }

  getParamList(): Observable<ParamList> {
    return this.http
      .get<ParamList>(`${environment.apiUrl}param/paramlist/`)
      .pipe(
        map((response: ParamList) => {
          return response;
        })
      );
  }

  getAllParamList(): Observable<OptionBody[]> {
    return this.http
      .get<{ param: OptionBody[] }>(`${environment.apiUrl}param/list/`)
      .pipe(
        map((response: { param: OptionBody[] }) => {
          return response.param;
        })
      );
  }

  setParamObject(
    id: number,
    body: {
      object_params: BodyModel[];
      status_device_id?: number;
      pressure_id?: number;
      freq_device_id?: number;
      debit_device_id?: number[];
      debit_device_field?: string[];
      freq_device_field?: string;
    }
  ): Observable<TypeTree> {
    return this.http
      .post<TypeTree[]>(`${environment.apiUrl}param/objectrel/${id}/`, body)
      .pipe(
        map((response: any) => {
          return response.info_object;
        })
      );
  }

  setParams(body: OptionBody): Observable<OptionBody> {
    return this.http
      .post<OptionBody>(`${environment.apiUrl}param/create/`, body)
      .pipe(
        map((response: OptionBody) => {
          this.optionsList.push(response);
          return response;
        })
      );
  }

  updateParams(data: { id: number; body: OptionBody }): Observable<OptionBody> {
    return this.http
      .post<OptionBody>(
        `${environment.apiUrl}param/update/${data.id}/`,
        data.body
      )
      .pipe(
        map((response: OptionBody) => {
          this.optionsList = this.optionsList.map((option) =>
            option.id === response.id ? response : { ...option }
          );
          return response;
        })
      );
  }

  deleteParams(id: number): Observable<OptionBody> {
    return this.http
      .delete<OptionBody>(`${environment.apiUrl}param/delete/${id}/`)
      .pipe(
        map((response: OptionBody) => {
          this.optionsList =
            this.optionsList.filter((option) => {
              return response.id !== option.id;
            }) || [];
          return response;
        })
      );
  }
}
