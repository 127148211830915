<div class="map" [class.tui-skeleton]="reloadMap">
  <div
    leaflet
    *ngIf="reInitMap"
    (leafletClick)="handlerClickOnMap($event, this.changeMode)"
    [leafletOptions]="options"
    (leafletMapReady)="onMapReady($event)"
    class="map-container"
  ></div>
</div>
