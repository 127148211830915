import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObjectService } from '../../../services/object.service';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { BoreholeService } from '../../../services/borehole.service';

@Component({
  selector: 'app-borehole-card',
  templateUrl: './borehole-card.component.html',
  styleUrls: ['./borehole-card.component.less'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoreholeCardComponent implements OnInit, OnDestroy {
  @Input()
  objectID: number | null = null;

  @Output() handleSubmit: EventEmitter<null> = new EventEmitter();

  public activeItemMenuIndex = 0;

  public tabs = [
    { name: 'info', title: 'Информация' },
    { name: 'events', title: 'События' },
    { name: 'history', title: 'История дебита' },
  ];

  public currentTabName = this.tabs[0].name;

  public borehole: any = null;

  public skeletonVisible: boolean = false;

  public availableParentsList: any[] = [];

  public loaderIsVisible: boolean = false;

  public destroyer: Subject<null> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private boreholeService: BoreholeService,
    private objectsService: ObjectService //private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loaderIsVisible = true;
    this.skeletonVisible = true;
    let boreholeId;
    if (this.objectID) {
      boreholeId = this.objectID;
    } else {
      boreholeId = this.route.snapshot.paramMap.get('id');
    }

    if (boreholeId) {
      forkJoin({
        borehole: this.objectsService.getObjectByID(+boreholeId),
        parents: this.objectsService.getDepositList(),
      })
        .pipe(takeUntil(this.destroyer))
        .subscribe(
          (result: any) => {
            const { borehole, parents } = result;
            this.borehole = { ...borehole };
            this.boreholeService.borehole.next(this.borehole);
            this.availableParentsList = [...parents];
            this.loaderIsVisible = false;
            this.skeletonVisible = false;
            //this.changeDetectorRef.markForCheck();
          },
          () => {
            this.loaderIsVisible = false;
            this.skeletonVisible = false;
          },
          () => {
            this.loaderIsVisible = false;
            this.skeletonVisible = false;
          }
        );
    }
    this.boreholeService.borehole
      .pipe(takeUntil(this.destroyer))
      .subscribe((borehole: any) => {
        this.borehole = { ...borehole };
      });
  }

  onClickTab(tabName: string) {
    this.currentTabName = tabName;
  }

  reloadTable(name: string) {
    this.borehole.name = name ? name : this.borehole.name;
    this.handleSubmit.emit();
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
