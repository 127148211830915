<div class="table-settings">
  <div class="sort-search">
    <app-table-sort
      [columns]="columns"
      [direction]="direction"
      (sorted)="onSort($event)"
      *ngIf="isSort"
    ></app-table-sort>
    <tui-input
      [formControl]="searchField"
      *ngIf="isSearch"
      tuiTextfieldSize="m"
      class="search-input"
    >
      {{disabled ? 'Поиск недоступен пока нет данных в таблице' : 'Поиск...'}}
      <input tuiTextfield type="text" />
    </tui-input>
  </div>
  <div *ngIf="!isSort"></div>
  <div class="table-settings__primary">
    <app-table-add-object
      *ngIf="isHasButtonAdd"
      (toggleButton)="changeAddPopup()"
    ></app-table-add-object>
    <ng-content select="[additionalButtons]"></ng-content>
    <app-table-column-settings
      *ngIf="isHasButtonSetting"
      [columns]="columns"
      (changeTableColumns)="changeTableColumns($event)"
      [disabled]="disabled"
    ></app-table-column-settings>
  </div>
</div>

<ng-container *ngIf="nothingWasFound">
  <app-primary-section>
    <div class="nothingWasFound">Ничего не найдено</div>
  </app-primary-section>
</ng-container>

<ng-container *ngIf="!nothingWasFound">
  <table *ngIf="!disabled; else notAvailableTable" class="primary-table" #primaryTable>
    <thead>
    <tr>
      <th *ngFor="let column of columns">{{ column.title }}</th>
      <th *ngIf="emptyThInTitle"></th>
    </tr>
    </thead>
    <tbody>
    <ng-content></ng-content>
    </tbody>
  </table>
</ng-container>

<ng-template #notAvailableTable>
  <div class="not-available">Импортируйте файл для отображения данных</div>
</ng-template>
