import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main.component';
import { GuestGuard } from '../../guard/guest.guard';
import { SchemeEditorComponent } from 'src/app/components/schemes/scheme-editor/scheme-editor.component';
import { BoreholeCardComponent } from '../object-cards/borehole-card/borehole-card.component';
import { OilfieldInfoPageComponent } from '../oilfield/components/oilfield-info-card/page/oilfield-info-page.component';
import { BushCardComponent } from '../object-cards/bush-card/bush-card.component';
import { GzuCardComponent } from '../object-cards/gzu-card/gzu-card.component';
import { DnsCardComponent } from '../object-cards/dns-card/dns-card.component';
import { EventsComponent } from '../events/events.component';
import { BgCardComponent } from '../object-cards/bg-card/bg-card.component';
import { KtpCardComponent } from '../object-cards/ktp-card/ktp-card.component';
import { UsersComponent } from '../users/users.component';
import { ReportsComponent } from '../reports/reports.component';
import { WaterPipelineCardComponent } from '../object-cards/water-pipeline-card/water-pipeline-card.component';
import { OilPipelineCardComponent } from '../object-cards/oil-pipeline-card/oil-pipeline-card.component';
import { PowerLineCardComponent } from '../object-cards/power-line-card/power-line-card.component';
//import { SettingComponent } from '../setting/setting.component';

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    children: [
      /*{
        path: 'objects',
        loadChildren: () =>
          import('../objects/objects.module').then((m) => m.List),
        canActivate: [GuestGuard],
      },*/
      {
        path: 'oilfield',
        loadChildren: () =>
          import('../oilfield/oilfield.module').then((m) => m.List),
        canActivate: [GuestGuard],
      },
      {
        path: 'objects',
        loadChildren: () =>
          import('../objects-list/objects-list.module').then((m) => m.List),
        canActivate: [GuestGuard],
      },
      {
        path: 'map/:type/:id',
        loadChildren: () => import('../map/map.module').then((m) => m.List),
        canActivate: [GuestGuard],
      },
      {
        path: 'map',
        loadChildren: () => import('../map/map.module').then((m) => m.List),
        canActivate: [GuestGuard],
      },
      {
        path: 'scheme-editor/:objectId',
        component: SchemeEditorComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'events',
        component: EventsComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'equipment',
        loadChildren: () =>
          import('../equipment/equipment.module').then((m) => m.List),
        canActivate: [GuestGuard],
      },
      {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'directories',
        loadChildren: () =>
          import('../directories/directories.module').then((m) => m.List),
        canActivate: [GuestGuard],
      },
      {
        path: 'card/borehole/:id',
        component: BoreholeCardComponent,
      },
      {
        path: 'card/deposit/:id',
        component: OilfieldInfoPageComponent,
      },
      {
        path: 'card/bush/:id',
        component: BushCardComponent,
      },
      {
        path: 'card/gzu/:id',
        component: GzuCardComponent,
      },
      {
        path: 'card/dns/:id',
        component: DnsCardComponent,
      },
      {
        path: 'card/ktp/:id',
        component: KtpCardComponent,
      },
      {
        path: 'card/bg/:id',
        component: BgCardComponent,
      },
      {
        path: 'card/water-pipeline/:id',
        component: WaterPipelineCardComponent,
      },
      {
        path: 'card/oil-pipeline/:id',
        component: OilPipelineCardComponent,
      },
      {
        path: 'card/power-line/:id',
        component: PowerLineCardComponent,
      },
      {
        path: 'setting',
        loadChildren: () =>
          import('../setting/setting.module').then((m) => m.SettingModule),
        canActivate: [GuestGuard],
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [GuestGuard],
      },
    ],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
