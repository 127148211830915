<div class="select">
  <div *ngIf="!isMulti" class="filtering__form-select">
    <tui-select
      tuiTextfieldSize="m"
      class="b-form tui-space_bottom-3"
      [(ngModel)]="changedValue"
    >
      {{ title }}
      <tui-data-list *tuiDataList>
        <button
          *ngFor="let item of values"
          tuiOption
          (click)="setChangedValue()"
          [value]="item"
        >
          {{ item }}
        </button>
      </tui-data-list>
    </tui-select>
  </div>
  <div *ngIf="isMulti" class="filtering__form-select">
    <tui-multi-select
      class="b-form tui-space_bottom-3"
      tuiTextfieldSize="m"
      [(ngModel)]="changedValue"
      [tuiTextfieldLabelOutside]="true"
    >
      {{ title }}
      <tui-data-list *tuiDataList tuiMultiSelectGroup>
        <button
          *ngFor="let item of values"
          tuiOption
          (click)="setChangedValue()"
          [value]="item"
        >
          {{ item }}
        </button>
      </tui-data-list>
    </tui-multi-select>
  </div>
</div>
