import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TuiDay, TuiDayRange } from '@taiga-ui/cdk';
import { TuiAlertService } from '@taiga-ui/core';
import { PressureService } from '../../../../../services/pressure.service';
import {
  PressureHistoryResponseType,
  PressureHistoryType,
} from '../../../../../../models/pressure-meter.model';
import * as luxon from 'luxon';

@Component({
  selector: 'app-pressure-sensor-history',
  templateUrl: './pressure-sensor-history.component.html',
  styleUrls: ['./pressure-sensor-history.component.less'],
})
export class PressureSensorHistoryComponent implements OnInit {
  @Input() pressureSensorID: number | undefined;

  public currentDay: any = new Date();

  public currentDate: number[] = [
    this.currentDay.getFullYear(),
    this.currentDay.getMonth(),
    this.currentDay.getDate(),
  ];

  public dayMonthAgo: any = new Date(
    this.currentDay.getTime() - 30 * 24 * 60 * 60 * 1000
  );

  public dateMonthAgo: number[] = [
    this.dayMonthAgo.getFullYear(),
    this.dayMonthAgo.getMonth(),
    this.dayMonthAgo.getDate(),
  ];

  public DateFormGroup: any = new FormGroup({
    date: new FormControl(
      new TuiDayRange(
        new TuiDay(
          this.dateMonthAgo[0],
          this.dateMonthAgo[1],
          this.dateMonthAgo[2]
        ),
        new TuiDay(
          this.currentDate[0],
          this.currentDate[1],
          this.currentDate[2]
        )
      ),
      [Validators.required]
    ),
  });

  public pressureHistoryData: PressureHistoryType[] = [];

  public tableColumns: any[] = [
    { name: 'datetime', title: 'Дата' },
    { name: 'value', title: 'Давление, МПа' },
  ];

  public paginator = {
    currentPage: 1,
    lastPage: 1,
    count: 0,
    perPage: 50,
  };

  public lastPossibleDayToChoose = TuiDay.fromLocalNativeDate(new Date());

  public isLoading: boolean = false;

  public isClicked: boolean = false;

  constructor(
    @Inject(TuiAlertService)
    private readonly alertService: TuiAlertService,
    private pressureService: PressureService
  ) {}

  ngOnInit(): void {
    if (!this.pressureSensorID) {
      this.DateFormGroup.disable();
    }
  }

  handleChangeDates() {
    this.isClicked = true;
    if (this.pressureSensorID) {
      this.isLoading = true;
      this.pressureService
        .getHistoryByPressureID(
          this.pressureSensorID,
          this.paginator.currentPage,
          this.paginator.perPage,
          this.DateFormGroup.get('date').value.from.getFormattedDay('YMD', '-'),
          this.DateFormGroup.get('date').value.to.getFormattedDay('YMD', '-')
        )
        .subscribe(
          (res: PressureHistoryResponseType) => {
            this.paginator.currentPage = res.current_page;
            this.paginator.lastPage = res.last_page;
            this.isLoading = false;
            if (res.data.length) {
              this.pressureHistoryData = res.data.map(
                (event: PressureHistoryType) => {
                  return this.getFormattedDate(event);
                }
              );
              return;
            }
            return;
          },
          () => {
            this.isLoading = false;
          },
          () => {
            this.isLoading = false;
          }
        );
    }
  }

  getFormattedDate(event: PressureHistoryType) {
    return {
      ...event,
      date_time: luxon.DateTime.fromISO(`${event.date_time}Z`).toFormat(
        'dd.MM.yyyy HH:mm:ss'
      ),
    };
  }

  changePage($event: number) {
    this.paginator.currentPage = $event + 1;
    this.handleChangeDates();
  }
}
