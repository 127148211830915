<div *ngIf="selectedObject">
  <tui-loader
    [showLoader]="isLoading"
    [inheritColor]="true"
    [overlay]="true"
    [size]="'xl'"
  >
    <app-primary-section>
      <h2 class="oilfield-popup__title">
        {{ selectedObject?.name || "object" }}
      </h2>
      <div class="tabs_wrapper">
        <tui-tabs class="tabs">
          <button
            class="tabs__btns"
            tuiTab
            *ngFor="let tab of tabs"
            (click)="changeTabElement(tab)"
          >
            <div>
              {{ tab.title }}
            </div>
          </button>
        </tui-tabs>

        <div class="tabs">
          <button
            class="tabs__btns"
            (click)="onDelete()"
          >
            <div>
              Удалить
            </div>
          </button>
        </div>

      </div>
    </app-primary-section>
    <div class="container__form" *ngIf="activeItemIndex.url === 'info'">
      <app-common-info-oil-pipeline
        [contextObject]="selectedObject"
        (handleChange)="getEditableWaterPipeline()"
      >
      </app-common-info-oil-pipeline>
    </div>
  </tui-loader>

</div>
