import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  divIcon,
  featureGroup,
  FeatureGroup,
  latLng,
  latLngBounds,
  marker,
  Polyline,
  tileLayer,
} from "leaflet";
import { LeafletMapService } from "../../../app/services/translate-map-controls.service";
import { TuiAlertService, TuiNotification } from "@taiga-ui/core";
import { PipelineColorType } from "../../../models/pipeline.model";
import { DesignService } from "../../../app/services/design.service";

@Component({
  selector: "app-pipeline-map",
  templateUrl: "./pipeline-map.component.html",
  styleUrls: ["./pipeline-map.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipelineMapComponent implements OnChanges {
  /**
   * Флаг для отображения режима редактирования
   */
  @Input() isEdit!: boolean;

  //используется только для страницы настроек для отображения двух статичных линий
  @Input() pipelines: {
    line: { latitude: number; longitude: number }[];
    type: PipelineColorType;
    id?: number;
  }[] = [];

  /**
   * Лоадер на карту
   */
  @Input() visibleMap: boolean = false;

  /**
   * Возвращает массив координат
   */
  @Output() handleDraw = new EventEmitter();

  public drawLayers: FeatureGroup = featureGroup();

  public reliefLayer: FeatureGroup = featureGroup();

  public map: any;

  public options = {
    layers: [
      tileLayer("http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}", {
        maxZoom: 18,
        attribution: "",
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }),
      this.drawLayers,
      this.reliefLayer,
    ],
    zoom: 16,
    center: latLng(55.560981, 52.638667),
    minZoom: 2,
    maxBounds: LeafletMapService.getBounds(),
  };

  drawingOptions: any;

  get drawingModeOptions() {
    return {
      position: "topright",
      draw: {
        polyline: {
          allowIntersection: true,
          shapeOptions: {
            clickable: true,
            color: this.getPipelineColor(this.pipelines[0].type),
            weight: this.getPipelineWeight(this.pipelines[0].type),
          },
          icon: divIcon({
            html: `<div style="width:10px; height: 10px; background:white "></div>`,
          }),
          iconTouch: divIcon({
            html: `<div style="width:10px; height: 10px; background:white "></div>`,
          }),
        },
        circle: false,
        rectangle: false,
        circlemarker: false,
        marker: false,
        polygon: false,
      },
      edit: {
        allowIntersection: true,
        selectedPathOptions: {
          dashArray: "10, 10",
          fill: true,
          fillColor: "#fe57a1",
          fillOpacity: 0.1,
          maintainColor: false,
          toolbar: false,
        },
        poly: {
          allowIntersection: true,
          icon: divIcon({
            html: `<div style="width:10px; height: 10px; background:white "></div>`,
          }),
        },

        featureGroup: this.drawLayers,
      },
    };
  }

  get editingModeOptions() {
    return {
      position: "topright",
      draw: false,
      edit: {
        allowIntersection: true,
        selectedPathOptions: {
          dashArray: "10, 10",
          fill: true,
          fillColor: "#fe57a1",
          fillOpacity: 0.1,
          maintainColor: false,
          toolbar: false,
        },
        poly: {
          allowIntersection: true,
          icon: divIcon({
            html: `<div style="width:10px; height: 10px; background:white "></div>`,
          }),
        },

        featureGroup: this.drawLayers,
      },
    };
  }

  private drawingMode: boolean = false;

  get drawOptions(): any {
    return this.isEdit
      ? this.drawingMode
        ? this.drawingModeOptions
        : this.editingModeOptions
      : { draw: false, edit: false };
  }

  public drawControlObject!: any;

  public averageLatLng: Array<number> = [0, 0];

  /*
  Отображать ли рельеф
   */
  public showRelief: boolean = false;

  get pipeline() {
    return this.pipelines[0]?.id ?? null;
  }

  get pipelineType(): any {
    return this.pipelines[0]?.type ?? null;
  }

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    private designService: DesignService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["visibleMap"]?.currentValue === true) {
      this.addPipelinePolyline(this.map);
    }
  }

  onShowRelief() {
    this.showRelief = !this.showRelief;
    if (!this.showRelief) {
      this.reliefLayer.clearLayers();
    } else {
      const element = document.querySelector(".relief_wrapper");
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }, 700);
      }
    }
  }

  getPipelineWeight(pipelineType: PipelineColorType): number {
    switch (pipelineType) {
      case PipelineColorType.DEFAULT:
        return this.designService.line_width_default_pipeline;
      case PipelineColorType.ELECTRICITY:
        return this.designService.line_width_electricity_pipeline;
      case PipelineColorType.AQUA:
        return this.designService.line_width_water_pipeline;
      case PipelineColorType.OIL:
        return this.designService.line_width_oil_pipeline;
      default:
        return this.designService.line_width_default_pipeline;
    }
  }

  getPipelineColor(pipelineType: PipelineColorType): string {
    switch (pipelineType) {
      case PipelineColorType.DEFAULT:
        return this.designService.color_default_pipeline;
      case PipelineColorType.ELECTRICITY:
        return this.designService.color_electricity_pipeline;
      case PipelineColorType.AQUA:
        return this.designService.color_water_pipeline;
      case PipelineColorType.OIL:
        return this.designService.color_oil_pipeline;
      default:
        return this.designService.color_default_pipeline;
    }
  }

  onMapReady(map: any) {
    this.map = map;
    LeafletMapService.translateZoomButtons(map);
    this.addPipelinePolyline(map);
  }

  onDrawStart() {
    this.drawLayers.clearLayers();
  }

  addPipelinePolyline(map: any) {
    if (this.pipelines?.length && this.pipelines[0].line.length) {
      // Если линия уже есть, то выключаем возможность рисования
      this.drawingMode = false;

      this.drawLayers.clearLayers();
      let southWest: any;
      let northEast: any;
      let bounds: any;
      this.pipelines.forEach((c: any) => {
        const pipelinePolyline = new Polyline(map, {
          stroke: true,
          weight: this.getPipelineWeight(c.type),
          opacity: 0.5,
          fill: false,
          interactive: true,
          color: this.getPipelineColor(c.type),
        });
        const arrayLatitudes: any[] = [];
        const arrayLongitudes: any[] = [];
        let minLatitude = 0;
        let maxLatitude = 0;
        let minLongitude = 0;
        let maxLongitude = 0;
        c.line.forEach((coords: { latitude: number; longitude: number }) => {
          pipelinePolyline.addLatLng(latLng(coords.latitude, coords.longitude));
          arrayLatitudes.push(coords.latitude);
          arrayLongitudes.push(coords.longitude);
        });
        minLatitude = Math.min(...arrayLatitudes);
        maxLatitude = Math.max(...arrayLatitudes);
        minLongitude = Math.min(...arrayLongitudes);
        maxLongitude = Math.max(...arrayLongitudes);
        southWest = latLng(maxLatitude, minLongitude);
        northEast = latLng(minLatitude, maxLongitude);
        bounds = latLngBounds(southWest, northEast);
        this.drawLayers.addLayer(pipelinePolyline);
      });
      if (map) {
        map.fitBounds(bounds);
      }
    } else {
      this.drawingMode = true;
    }
  }

  latLngsToArray(latlngs: any[]) {
    return latlngs.map((ll: any) => ({ latitude: ll.lat, longitude: ll.lng }));
  }

  onDrawCreated($event: any) {
    let isOutsideTheMap: any = $event.layer._latlngs.find((coord: any) => {
      return (
        coord.lat > 90 || coord.lat < -90 || coord.lng > 180 || coord.lng < -180
      );
    });
    if (isOutsideTheMap) {
      this.notificationsService
        .open(
          `Выберите область карты в пределах вертикальных пунктирных красных линий.`,
          {
            label: "Эта зона карты недоступна для взаимодействия с ней",
            status: TuiNotification.Error,
          }
        )
        .subscribe();
    } else {
      // this.drawLayers.clearLayers();
      this.drawLayers.addLayer(($event as any).layer);
      const coords = this.latLngsToArray($event.layer._latlngs);
      this.handleDraw.emit(coords);
    }
  }

  onDrawEdit($event: any) {
    const layer: any = Object.values($event.layers._layers)[0];
    const coords = this.latLngsToArray(layer ? layer._latlngs : []);
    this.handleDraw.emit(coords);
  }

  onDrawDelete() {
    // Если что-то удалили, то включаем возможность рисования
    this.drawingMode = true;

    this.handleDraw.emit([]);
  }

  /*
  Отображение стрелки на карте в момент наведения на точку рельефа
   */
  clickOnRelief($event: any) {
    this.reliefLayer.clearLayers();
    this.reliefLayer.addLayer(
      marker([$event.latitude, $event.longitude], {
        icon: divIcon({
          html: `<div id="relief-balloon" class="relief-balloon">
                   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.0001 27.9998L5.6001 17.5998H11.2001V4.7998H20.8001V17.5998H26.4001L16.0001 27.9998Z" fill="#EF6C00"/>
                    </svg>
                 </div>`,
        }),
      } as any)
    );
  }
}
