import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { fabric } from 'fabric';
import { SchemeSendData } from '../../../../../models/scheme.model';
import { SchemeService } from '../../../../services/scheme.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.less'],
})
export class ControlsComponent implements OnInit, OnDestroy {
  @Output() handleAddObject = new EventEmitter();

  @Input() lineArray!: any;

  private destroyer: Subject<any> = new Subject<any>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private schemeService: SchemeService
  ) {}

  ngOnInit(): void {
    this.schemeService.lineArr
      .pipe(takeUntil(this.destroyer))
      .subscribe((item: any) => {
        if (item) {
          this.addObject(item.type, item);
        }
      });
  }

  addObject(type: string, settings?: SchemeSendData | null) {
    switch (type) {
      case 'pipe':
        const pipe = new fabric.Line([0, 0, 0, 150], {
          left: settings ? settings.left : 0,
          top: settings ? settings.top : 0,
          scaleX: settings ? settings.scaleX : 1,
          scaleY: settings ? settings.scaleX : 1,
          angle: settings ? settings.angle : 0,
          fill: '#37474f',
          stroke: '#37474f',
          strokeWidth: 5,
          selectable: true,
          perPixelTargetFind: true,
          snapAngle: 90,
          strokeUniform: true,
        });
        this.schemeService.setLine.next(pipe);
        return;
      case 'electro-line':
        const electroLine = new fabric.Line([0, 0, 0, 150], {
          left: settings ? settings.left : 0,
          top: settings ? settings.top : 0,
          scaleX: settings ? settings.scaleX : 1,
          scaleY: settings ? settings.scaleX : 1,
          angle: settings ? settings.angle : 0,
          fill: '#ffab00',
          stroke: '#ffab00',
          strokeWidth: 5,
          selectable: true,
          perPixelTargetFind: true,
          snapAngle: 90,
          strokeUniform: true,
        });
        this.schemeService.setLine.next(electroLine);
        return;
    }
  }

  ngOnDestroy() {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
