<app-primary-section>
  <tui-loader
    [showLoader]="isLoading"
    [inheritColor]="true"
    [overlay]="true"
    [size]="'xl'"
  >
    <app-primary-table
      [isSort]="false"
      [columns]="
      activeItemIndex.url === 'borehole-events'
        ? boreholeColumns
        : pressureColumns
    "
      [disabled]="false"
      class="relative"
      [updateRequestSubject]="updateRequestSubject"
    >
      <tui-tabs class="tabs">
        <button
          class="tabs__btns"
          tuiTab
          *ngFor="let tab of tabs"
          (click)="changeTabElement(tab)"
        >
          <div>
            {{ tab.title }}
          </div>
        </button>
      </tui-tabs>

      <button
        additionalButtons
        tuiIconButton
        appearance="flat"
        [tuiHint]="tooltip"
        type="button"
        (click)="markReadEventsByObject()"
      >
        <i-tabler name="mail-opened"></i-tabler>
      </button>
      <button
        additionalButtons
        tuiIconButton
        [tuiHint]="tooltipFilter"
        appearance="flat"
        type="button"
        (click)="isFilterOpen = true"
      >
        <i-tabler name="filter"></i-tabler>
        <div class="filter-mark" *ngIf="filterIsMark()"></div>
      </button>

      <ng-container *ngIf="activeItemIndex.url === 'borehole-events'">
        <tr *ngIf="!events.length">
          <td [attr.colspan]="boreholeColumns.length + 1">События не найдены</td>
        </tr>
        <tr *ngFor="let row of events; index as i; trackBy: identify">
          <td>{{ i + 1 + (paginator.currentPage - 1) * paginator.perPage }}</td>
          <td>{{ getFormattedDate(row.created_at) }}</td>
          <td>{{ row.object_name || "-" }}</td>
          <td>{{ row.deposit_name || "-" }}</td>
          <td>
            {{ statusMessages[row.type] }}
          </td>
          <td>
            <tui-badge
              *ngIf="row.status; else emptyStatus"
              [attr.data-status]="row.status"
              [value]="statusTitles[row.status]"
              class="status"
            >
            </tui-badge>
            <ng-template #emptyStatus>-</ng-template>
          </td>
          <td *ngIf="row.react_at">{{ getFormattedDate(row.react_at) }}</td>
          <td *ngIf="!row.react_at">
            <button (click)="markReadEvent(row)" class="read-event">
              Прочитано
            </button>
          </td>
          <td></td>
        </tr>
      </ng-container>

      <ng-container *ngIf="activeItemIndex.url === 'pressure-events'">
        <tr *ngIf="!events.length">
          <td [attr.colspan]="pressureColumns.length + 1">События не найдены</td>
        </tr>

        <tr *ngFor="let row of events; index as i; trackBy: identify">
          <td>{{ i + 1 + (paginator.currentPage - 1) * paginator.perPage }}</td>
          <td>{{ getFormattedDate(row.created_at) }}</td>
          <td>{{ row.pressure_name || "-" }}</td>
          <td>{{ row.deposit_name || "-" }}</td>
          <td>
            <tui-badge
              *ngIf="
              ['max_error', 'min_error', 'max_warning', 'min_warning', 'normal'].includes(
                row.type
              );
              else emptyStatus
            "
              [class.pressure-error]="
              ['max_error', 'min_error'].includes(row.type)
            "
              [class.pressure-warning]="
              ['max_warning', 'min_warning'].includes(row.type)
            "
              [class.pressure-normal]="
              ['normal'].includes(row.type)
            "
              [value]="statusMessages[row.type]"
              [size]="'m'"
              class="status"
            >
            </tui-badge>
            <ng-template #emptyStatus>-</ng-template>
          </td>
          <td *ngIf="row.react_at">{{ getFormattedDate(row.react_at) }}</td>
          <td *ngIf="!row.react_at">
            <button (click)="markReadEvent(row)" class="read-event">
              Прочитано
            </button>
          </td>
          <td></td>
        </tr>
      </ng-container>
    </app-primary-table>
  </tui-loader>

  <tui-pagination
    class="events-paginator"
    *ngIf="paginator.lastPage !== 1"
    [activePadding]="0"
    [length]="paginator.lastPage"
    (indexChange)="changePage($event)"
    [index]="paginator.currentPage - 1"
  ></tui-pagination>
</app-primary-section>
<ng-template #tooltip>
  Отметить все события по объекту как прочитанные
</ng-template>
<ng-template #tooltipFilter> Фильтр</ng-template>
<ng-template
  [tuiDialogOptions]="$any({ label: 'Прочитать всё', size: 's' })"
  [(tuiDialog)]="isCleanAllEvents"
>
  <p>Вы уверены, что хотите прочесть всё?</p>
  <div
    class="tui-form__buttons _space-between"
    style="justify-content: space-between"
  >
    <button
      tuiButton
      class="tui-space_top-5"
      type="button"
      size="s"
      (click)="confirmCleanEvents()"
    >
      Подтвердить
    </button>
    <button
      tuiButton
      class="tui-space_top-5"
      type="button"
      size="s"
      appearance="whiteblock-active"
      (click)="cancelPopup()"
    >
      Отмена
    </button>
  </div>
</ng-template>
<app-events-filter
  [isOpen]="isFilterOpen"
  (closeFilter)="onCloseFilter()"
  (filterChanged)="onFilter($event)"
></app-events-filter>
