import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GzuService {
  constructor(private http: HttpClient) {}

  public gzu: Subject<any> = new Subject<any>();

  createGzu(body: any): Observable<any> {
    return this.http
      .post<{ created_object: any }>(
        `${environment.apiUrl}objects/boreholes/create/`,
        body
      )
      .pipe(
        map((response: { created_object: any }) => {
          return response.created_object;
        })
      );
  }
}
