<div class="option-object">
  <div class="inline-table">
    <div class="header _option">
      <span>Параметр</span>
      <span>Значение</span>
      <app-edit-buttons-oilfield
        [isEdit]="isEdit"
        [listOptions]="listOptions"
        [isOptionTable]="true"
        [listSelectedIdsOption]="selectedOptionIds"
        (handelChange)="changeMode()"
        (handelSave)="onSaveParams()"
        (handelCancel)="changeMode()"
        (handelPlus)="selectOption($event)"
      ></app-edit-buttons-oilfield>
    </div>
    <div class="table">
      <div class="row" *ngFor="let row of options">
        <span class="label">{{ row.name }}</span>
        <div *ngIf="isEdit" class="label__edit-input">
          <app-search-selector
            [row]="row"
            [idSelectedElement]="row.deviceID"
            [data]="listDevice"
            (handleSelectedItem)="selectDeviceForOption($event, row.optionID)"
          ></app-search-selector>
          <button
            tuiIconButton
            type="button"
            size="s"
            class="cross"
            appearance="menu"
            icon="tuiIconClose"
            (click)="deleteDevice(row.optionID)"
          ></button>
        </div>
        <span *ngIf="!isEdit">{{ row.value }}</span>
      </div>
    </div>
  </div>
</div>
