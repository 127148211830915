import { Component, Inject, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import {
  TuiContextWithImplicit,
  TuiDay,
  TuiDayRange,
  TuiDestroyService,
  tuiPure,
  TuiStringHandler,
} from '@taiga-ui/cdk';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as luxonDateTime from 'luxon';
import { ReportsService } from '../../services/reports.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less'],
})
export class ReportsComponent implements OnInit {
  public today = luxonDateTime.DateTime.now().minus({ month: 1 });

  public prevMonth = luxonDateTime.DateTime.now().minus({ month: 2 });

  public reports = [
    { value: 'connection', title: 'Отчет по связи' },
    { value: 'measurement', title: 'Отчет по замерам' },
  ];

  // readonly min = new TuiDay(2021, 1, 1);
  //
  // readonly max = new TuiDay(2040, 12, 31);

  public currentDay: any = new Date();

  public lastPossibleDayToChoose = TuiDay.fromLocalNativeDate(new Date());

  public currentDate: number[] = [
    this.currentDay.getFullYear(),
    this.currentDay.getMonth(),
    this.currentDay.getDate(),
  ];

  public dayMonthAgo: any = new Date(
    this.currentDay.getTime() - 30 * 24 * 60 * 60 * 1000
  );

  public dateMonthAgo: number[] = [
    this.dayMonthAgo.getFullYear(),
    this.dayMonthAgo.getMonth(),
    this.dayMonthAgo.getDate(),
  ];

  readonly reportForm = new FormGroup({
    dates: new FormControl(
      new TuiDayRange(
        new TuiDay(
          this.dateMonthAgo[0],
          this.dateMonthAgo[1],
          this.dateMonthAgo[2]
        ),
        new TuiDay(
          this.currentDate[0],
          this.currentDate[1],
          this.currentDate[2]
        )
      ),
      [Validators.required]
    ),
    report: new FormControl('connection', [Validators.required]),
  });

  public reportData: any[] = [];

  public isSpinnerVisible = false;

  public dates: any = [];

  constructor(
    private appService: AppService,
    private reportsService: ReportsService,
    @Inject(TuiDestroyService) destroy$: TuiDestroyService
  ) {
    this.reportForm
      .get('report')
      ?.valueChanges.pipe(takeUntil(destroy$))
      .subscribe(() => {
        this.reportData = [];
      });
  }

  ngOnInit(): void {
    this.appService.title.next('Отчеты');
  }

  @tuiPure
  stringify(
    items: readonly any[]
  ): TuiStringHandler<TuiContextWithImplicit<string>> {
    const map = new Map(
      items.map(({ value, title }) => [value, title] as [string, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<string>) =>
      map.get($implicit) || ``;
  }

  handleSubmitReportForm() {
    this.isSpinnerVisible = true;
    this.dates = [];
    const startDates = `${this.reportForm.value.dates.from.year}-${String(
      this.reportForm.value.dates.from.month + 1
    ).padStart(2, '0')}-${String(this.reportForm.value.dates.from.day).padStart(
      2,
      '0'
    )}`;
    const endDates = `${this.reportForm.value.dates.to.year}-${String(
      this.reportForm.value.dates.to.month + 1
    ).padStart(2, '0')}-${String(this.reportForm.value.dates.to.day).padStart(
      2,
      '0'
    )}`;
    for (
      let start = luxonDateTime.DateTime.fromFormat(startDates, 'yyyy-MM-dd');
      start <= luxonDateTime.DateTime.fromFormat(endDates, 'yyyy-MM-dd');
      start = start.plus({ day: 1 })
    ) {
      this.dates.push(start.toFormat('dd.MM.yyyy'));
    }

    const startDate = `${this.reportForm.value.dates.from.year}-${String(
      this.reportForm.value.dates.from.month + 1
    ).padStart(2, '0')}-${String(this.reportForm.value.dates.from.day).padStart(
      2,
      '0'
    )}`;
    const endDate = `${this.reportForm.value.dates.to.year}-${String(
      this.reportForm.value.dates.to.month + 1
    ).padStart(2, '0')}-${String(this.reportForm.value.dates.to.day).padStart(
      2,
      '0'
    )}`;
    const reportName = this.reportForm.value.report;
    this.reportsService
      .getReport(startDate, endDate, reportName)
      .subscribe((data: any) => {
        this.reportData = data;
        this.isSpinnerVisible = false;
      });
  }

  getSum(info: any, type: string) {
    return (
      info.reduce((sum: number, i: any) => sum + (i[type] || 0), 0) ||
      (type === 'stops_number' ? 0 : null)
    );
  }

  getWorkTime(work_time: any) {
    if (work_time === null) {
      return '-';
    }
    const hours = Math.floor(work_time / 3600);
    const minutes = Math.floor((work_time % 3600) / 60);
    const seconds = work_time % 60;
    let result = `${minutes.toString().padStart(1, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
    if (!!hours) {
      result = `${hours.toString()}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return result;
  }

  downloadBase64File(
    contentBase64: string,
    mimeType: string,
    fileName: string
  ) {
    const linkSource = `data:${mimeType};base64,${contentBase64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = fileName;
    downloadLink.click();
    setTimeout(() => {
      document.body.removeChild(downloadLink);
    }, 0);
  }

  exportReport() {
    this.isSpinnerVisible = true;
    const startDate = `${this.reportForm.value.dates.from.year}-${String(
      this.reportForm.value.dates.from.month + 1
    ).padStart(2, '0')}-${String(this.reportForm.value.dates.from.day).padStart(
      2,
      '0'
    )}`;
    const endDate = `${this.reportForm.value.dates.to.year}-${String(
      this.reportForm.value.dates.to.month + 1
    ).padStart(2, '0')}-${String(this.reportForm.value.dates.to.day).padStart(
      2,
      '0'
    )}`;
    const reportName = this.reportForm.value.report;
    this.reportsService
      .exportReport(startDate, endDate, reportName)
      .subscribe((data: any) => {
        const { file, filename, mime } = data;
        this.downloadBase64File(file, mime, filename);
        this.isSpinnerVisible = false;
      });
  }
}
