<div class="container" [formGroup]="formDirectories">
  <mat-form-field
    style="width: 100%"
    class="example-full-width"
    appearance="outline"
  >
    <mat-label>{{ title }}</mat-label>
    <input
      type="text"
      [placeholder]="title"
      (input)="changeItem($event)"
      #text
      matInput
      formControlName="name"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayWith"
      showPanel="true"
      (optionsScroll)="onScroll()"
    >
      <mat-option
        *ngFor="let option of filteredStudents$ | async"
        (click)="changeItem()"
        [value]="option"
      >
        {{ option.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-error
    *ngFor="let validation of validation_msgs.contactAutocompleteControl"
  >
    <div *ngIf="formDirectories.get('name')?.hasError(validation.type)">
      {{ validation.message }}
    </div>
  </mat-error>
</div>
