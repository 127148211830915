import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectType } from '../../../../../../../../../models/content-field.model';

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.less'],
})
export class SelectFieldComponent {
  @Input()
  formGroup!: FormGroup;

  @Input()
  settings: SelectType = {
    controlName: '',
    title: '',
    isRequired: true,
    items: [],
  };
}
