import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  BADGE_TRANSLATE,
  OBJECTS_DEBIT,
  TRANSLATE_TYPES,
  TYPE_MINING,
} from '../../../../../const/app-consts';
import { TypeTree } from '../../../../../../models/tree.model';
import { TuiDialogOptions } from '@taiga-ui/core';
import { ObjectService } from '../../../../../services/object.service';
import * as memoizee from 'memoizee';

@Component({
  selector: 'app-boreholes-bg',
  templateUrl: './boreholes-bg.component.html',
  styleUrls: ['./boreholes-bg.component.less'],
})
export class BoreholesBgComponent implements OnInit {
  @Input() selectedDeposit: any;

  public boreholes: any[] = [];

  public TYPE_MINING = TYPE_MINING;

  public OBJECTS_DEBIT = OBJECTS_DEBIT;

  public statusTitles: any = {
    ok: BADGE_TRANSLATE.ok,
    stop: BADGE_TRANSLATE.stop,
    no_connection: BADGE_TRANSLATE.no_connection,
  };

  public tableColumns = [
    {
      name: 'id',
      title: 'ID',
    },
    {
      name: 'name',
      title: 'Название',
    },
    {
      name: 'oil-collection',
      title: 'Нефтесбор',
    },
    {
      name: 'status',
      title: 'Статус',
    },
    {
      name: 'type-mining',
      title: 'Тип добычи',
    },
    {
      name: 'debit',
      title: 'Режимный дебит',
    },
    {
      name: 'debit',
      title: 'Обводнённость',
    },
    {
      name: 'frequency',
      title: 'Частота',
    },
    {
      name: 'work-time',
      title: 'Время работы',
    },
  ];

  public isReloading: boolean = true;

  public openDeletePopup: boolean = false;

  public closeDeleteModal = () => {
    this.openDeletePopup = false;
  };

  @Output() handleChange: EventEmitter<boolean> = new EventEmitter();

  //без оутпута с этим методом не работает закрытие диалога, когда он в другом диалоге
  public closeDeleteDialog(event: boolean): any {
    this.openDeletePopup = false;
    if (event) {
      this.handleChange.emit(true);
    } else {
      this.handleChange.emit(false);
    }
    this.changeDetectorRef.markForCheck();
  }

  public selectedForDeleteObject: null | {
    id: number | null;
    name: string;
    type: string;
  } = null;

  public dialogDeleteObject: Partial<TuiDialogOptions<boolean | string>> = {
    closeable: false,
    size: 's',
  };

  public translate: any = TRANSLATE_TYPES;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private objectService: ObjectService
  ) {}

  ngOnInit(): void {
    this.boreholes = this.selectedDeposit.children || [];
    this.isReloading = false;
    //this.changeDetectorRef.detectChanges();
  }

  openBorehole(id: number) {
    window.open(`/main/card/borehole/${id}`);
  }

  deleteNode(item: TypeTree) {
    this.selectedForDeleteObject = {
      id: item.id,
      name: item.name,
      type: this.translate[item.type],
    };
    this.openDeletePopup = !this.openDeletePopup;
    //this.deletePopup = this.dialogService.open(
    //  new PolymorpheusComponent(DeleteDialogComponent, this.injector),
    //  {
    //    data: { id: item.id, name: item.name, type: this.translate[item.type] },
    //    size: 's',
    //  }
    //);
    //
    //this.deletePopup.subscribe({
    //  next: () => {},
    //  complete: () => {},
    //});
  }

  updateTable() {
    this.isReloading = true;
    this.objectService
      .getDepositInfoByID(this.selectedDeposit.id)
      .subscribe((deposit: any) => {
        this.boreholes = deposit.children;
        this.changeDetectorRef.markForCheck();
      });
    this.isReloading = false;
  }

  getFreq = memoizee((item: any): string => {
    const frequencyFieldName = item.freq_device_field;
    if (typeof frequencyFieldName === 'string') {
      if (item.freq_device?.last_message?.hasOwnProperty(frequencyFieldName)) {
        const value = item.freq_device.last_message[frequencyFieldName];
        return value.toFixed(2) + ' Гц';
      }
    }
    return '-';
  });

  getWorkTime(work_time: any) {
    if (work_time === null) {
      return '-';
    }
    const hours = Math.floor(work_time / 3600);
    const minutes = Math.floor((work_time % 3600) / 60);
    const seconds = work_time % 60;
    let result = `${minutes.toString().padStart(1, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
    if (!!hours) {
      result = `${hours.toString()}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return result;
  }
}
