<div *ngIf="contextObject">
  <app-primary-section>
    <form *ngIf="editObjectMode" [formGroup]="objectInfoForm" class="form">
      <div class="inline-table">
        <div class="header">
          <span>БГ/УЗА</span>
          <app-common-buttons-oilfield
            [isEdit]="editObjectMode"
            (handelCancel)="cancelInput()"
            (handelChange)="changeInput()"
            (handelSave)="save()"
          >
          </app-common-buttons-oilfield>
        </div>
        <div class="form-table">
          <div class="row">
            <div class="form-label">Название *</div>
            <div class="value">
              <tui-input
                style="width: 90%"
                formControlName="name"
                tuiTextfieldSize="m"
              >
                Введите название
                <input maxlength="40" tuiTextfield type="text" />
              </tui-input>
              <tui-field-error formControlName="name"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Месторождение *</div>
            <div class="value">
              <tui-select
                style="width: 90%"
                formControlName="deposit"
                [valueContent]="typeValueContent"
                tuiTextfieldSize="m"
              >
                <tui-data-list-wrapper
                  *tuiDataList
                  [items]="depositList"
                  [itemContent]="valueContent"
                >
                </tui-data-list-wrapper>
              </tui-select>
              <ng-template #typeValueContent let-data>
                {{ data.title }}
              </ng-template>
              <ng-template #valueContent let-data>
                {{ data.title }}
              </ng-template>
              <tui-field-error formControlName="deposit"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Объект нефтесбора</div>
            <div class="value">
              <tui-select
                style="width: 90%"
                *tuiLet="getConcatOils() as items"
                class="b-form"
                [valueContent]="items ? stringify(items) : loading"
                formControlName="oil_parent_id"
                tuiTextfieldSize="m"
                [tuiTextfieldLabelOutside]="true"
              >
                <span *ngIf="!disabledOilField; else disabled_oil_field"
                  >Выберите объект нефтесбора</span
                >
                <ng-template #disabled_oil_field
                  >Нет элементов для выбора
                </ng-template>
                <ng-template tuiDataList>
                  <tui-data-list *ngIf="items; else loading">
                    <tui-opt-group label="ДНС">
                      <button
                        *ngFor="let item of listOfOil.dnsList"
                        tuiOption
                        [value]="item.id"
                      >
                        {{ item.name }}
                      </button>
                    </tui-opt-group>
                    <tui-opt-group label="ГЗУ">
                      <button
                        *ngFor="let item of listOfOil.gzuList"
                        tuiOption
                        [value]="item.id"
                      >
                        {{ item.name }}
                      </button>
                    </tui-opt-group>
                    <tui-opt-group label="БГ/УЗА">
                      <button
                        *ngFor="let item of listOfOil.bgList"
                        tuiOption
                        [value]="item.id"
                      >
                        {{ item.name }}
                      </button>
                    </tui-opt-group>
                  </tui-data-list>
                </ng-template>
                <ng-template #loading>
                  <tui-loader class="tui-space_vertical-3 loader"></tui-loader>
                </ng-template>
              </tui-select>
              <tui-field-error
                formControlName="oil_parent_id"
              ></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">
              Датчик давления текущего объекта нефтесбора
            </div>
            <div class="value width90">
              <app-form-select-with-search-for-huge-arrays
                [form]="objectInfoForm"
                [formControlNameSelect]="'curr_pressure'"
                [label]="'Выберите датчик давления'"
                [placeholderMessage]="'Введите название датчика...'"
                [visualRequired]="false"
                [items]="pressureList"
              >
              </app-form-select-with-search-for-huge-arrays>

              <tui-field-error
                formControlName="curr_pressure"
              ></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">
              Датчик давления следующего объекта нефтесбора
            </div>
            <div class="value width90">
              <app-form-select-with-search-for-huge-arrays
                [form]="objectInfoForm"
                [formControlNameSelect]="'next_pressure'"
                [label]="'Выберите датчик давления'"
                [placeholderMessage]="'Введите название датчика...'"
                [visualRequired]="false"
                [items]="pressureList"
              >
              </app-form-select-with-search-for-huge-arrays>

              <tui-field-error
                formControlName="next_pressure"
              ></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Нефтепровод</div>
            <div class="value width90">
              <app-form-select-with-search-for-huge-arrays
                [form]="objectInfoForm"
                [formControlNameSelect]="'oil_pipe'"
                [label]="'Выберите нефтепровод'"
                [placeholderMessage]="'Введите название нефтепровода...'"
                [visualRequired]="false"
                [items]="oilPipelines"
              >
              </app-form-select-with-search-for-huge-arrays>

              <tui-field-error formControlName="oil_pipe"></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Водопровод</div>
            <div class="value width90">
              <app-form-select-with-search-for-huge-arrays
                [form]="objectInfoForm"
                [formControlNameSelect]="'water_pipe'"
                [label]="'Выберите водопровод'"
                [placeholderMessage]="'Введите название водопровода...'"
                [visualRequired]="false"
                [items]="waterPipelines"
              >
              </app-form-select-with-search-for-huge-arrays>

              <tui-field-error formControlName="water_pipe"></tui-field-error>
            </div>
          </div>

          <div class="row">
            <div class="form-label">Широта *</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                [precision]="14"
                tuiTextfieldSize="m"
                formControlName="latitude"
              >
                Введите широту
              </tui-input-number>
              <tui-field-error formControlName="latitude"></tui-field-error>
            </div>
          </div>
          <div class="row">
            <div class="form-label">Долгота *</div>
            <div class="value">
              <tui-input-number
                style="width: 90%"
                [precision]="14"
                tuiTextfieldSize="m"
                formControlName="longitude"
              >
                Введите долготу
              </tui-input-number>
              <tui-field-error formControlName="longitude"></tui-field-error>
            </div>
          </div>
        </div>
        <span class="form-footer">* Обязательные для заполнения поля</span>
      </div>
    </form>
    <div class="inline-table" *ngIf="!editObjectMode">
      <div class="header">
        <span>БГ/УЗА</span>
        <app-common-buttons-oilfield
          [isEdit]="editObjectMode"
          (handelCancel)="cancelInput()"
          (handelChange)="changeInput()"
          (handelSave)="save()"
        >
        </app-common-buttons-oilfield>
      </div>
      <div class="table">
        <div class="row">
          <div class="label">Название</div>
          <div class="value">
            {{ contextObject.name }}
          </div>
        </div>
        <div class="row">
          <div class="label">Месторждение</div>
          <div class="value">
            {{ contextObject?.parent?.name || "Не выбран" }}
          </div>
        </div>
        <div class="row">
          <div class="label">Всего скважин</div>
          <div class="value">
            {{ contextObject?.children?.length || 0 }}
          </div>
        </div>
        <div class="row">
          <div class="label">Объект нефтесбора</div>
          <div class="value">
            {{ contextObject.oil_parent?.name || "-" }}
          </div>
        </div>

        <div class="row">
          <div class="label">Датчик давления текущего объекта нефтесбора</div>
          <div class="value">
            {{ contextObject.curr_pressure?.name || "Не выбран" }}
            <tui-badge
              *ngIf="contextObject.curr_pressure"
              [status]="'primary'"
              class="status"
              [value]="currentPressureValue"
            ></tui-badge>
          </div>
        </div>

        <div class="row">
          <div class="label">Датчик давления следующего объекта нефтесбора</div>
          <div class="value">
            {{ contextObject.next_pressure?.name || "Не выбран" }}
            <tui-badge
              *ngIf="contextObject.next_pressure"
              [status]="'primary'"
              class="status"
              [value]="nextPressureValue"
            ></tui-badge>
          </div>
        </div>

        <div class="row">
          <div class="label">Нефтепровод</div>
          <div class="value">
            {{
              findNameByIdFromArray(contextObject.oil_pipe_id, oilPipelines) ||
                "Не выбран"
            }}
          </div>
        </div>

        <div class="row">
          <div class="label">Водопровод</div>
          <div class="value">
            {{
              findNameByIdFromArray(
                contextObject.water_pipe_id,
                waterPipelines
              ) || "Не выбран"
            }}
          </div>
        </div>

        <div class="row">
          <div class="label">Широта</div>
          <div class="value">
            <td>
              {{
                memoizedGetCurrentLat(
                  contextObject?.latitude,
                  contextObject?.longitude
                )
              }}
            </td>
          </div>
        </div>
        <div class="row">
          <div class="label">Долгота</div>
          <div class="value">
            <td>
              {{
                memoizedGetCurrentLon(
                  contextObject?.latitude,
                  contextObject?.longitude
                )
              }}
            </td>
          </div>
        </div>
        <div class="row">
          <div class="label">Скважин включено\остановлено\не в сети</div>
          <div class="value">
            <div>
              <span class="value__status __ok">{{
                contextObject.status?.boreholes?.ok || 0
              }}</span>
              <span class="value__status __stop">{{
                contextObject.status?.boreholes?.stop || 0
              }}</span>
              <span class="value__status __no-connection">{{
                contextObject.status?.boreholes?.no_connection || 0
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-primary-section>
  <app-primary-section>
    <div
      *ngIf="
        (contextObject?.latitude < 90 &&
          contextObject?.latitude > -90 &&
          contextObject?.longitude < 180 &&
          contextObject?.longitude > -180) ||
          editObjectMode;
        else unavailableCoords
      "
      class="map"
    >
      <app-bg-card-map
        [onClickOnMap]="onClickOnMap"
        [data]="contextObject"
        [isEdit]="editObjectMode"
        [latitude]="latitude"
        [longitude]="longitude"
        [visibleMap]="visibleMap"
      ></app-bg-card-map>
    </div>
    <ng-template #unavailableCoords>
      <div class="unavailable-map">
        <span class="unavailable-map__text">
          Точка на карте расположена вне области видимости карты, измените
          координаты в соответствии с допустимыми значениями, а именно: значения
          широты должны быть менее 90, но более -90, значения долготы должны
          быть менее 180, но более -180, чтобы отобразить точку на карте
        </span>
      </div>
    </ng-template>
  </app-primary-section>
</div>
<div *ngIf="!contextObject" class="common-info__no-content">
  Выберите объект
</div>
