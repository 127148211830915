import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  divIcon,
  latLng,
  layerGroup,
  LeafletMouseEvent,
  marker,
  tileLayer,
} from 'leaflet';
import { LeafletMapService } from '../../../../../../../services/translate-map-controls.service';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';

const L = LeafletMapService.get();

@Component({
  selector: 'app-gzu-card-map',
  templateUrl: './gzu-card-map.component.html',
  styleUrls: ['./gzu-card-map.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GzuCardMapComponent implements OnInit, OnChanges {
  @Input() data: any;

  @Input() onClickOnMap: any;

  @Input() isEdit: boolean = false;

  @Input() latitude: number = 0;

  @Input() longitude: number = 0;

  @Input() visibleMap: boolean = false;

  public objectsLayer: any = layerGroup();

  public mapDns: any = null;

  public options = {
    layers: [
      tileLayer('http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        attribution: '',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      this.objectsLayer,
    ],
    zoom: 7,
    center: latLng(55.560981, 52.638667),
    minZoom: 2,
    maxBounds: LeafletMapService.getBounds(),
  };

  public key = 0;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService
  ) {}

  ngOnChanges(changes: any) {
    if (
      this.data &&
      (!changes?.longitude?.firstChange || !changes?.latitude?.firstChange) &&
      ((typeof changes?.latitude?.currentValue === 'number' &&
        changes?.latitude?.currentValue !== changes?.latitude?.previousValue) ||
        (typeof changes?.longitude?.currentValue === 'number' &&
          changes?.longitude?.currentValue !==
            changes?.longitude?.previousValue))
    ) {
      this.objectsLayer.clearLayers();
      this.mapDns.panTo(new L.LatLng(this.latitude, this.longitude));
      this.objectsLayer.addLayer(
        marker([this.latitude, this.longitude], {
          icon: divIcon({
            html: `<div class="object-map-balloon object-status-${this.getColorClassForBalloon()}" style="font-size: ${
              this.data.name.length > 6
                ? (5 / this.data.name.length) * 20 >= 10
                  ? 10
                  : (5 / this.data.name.length) * 20
                : 10
            }px">
                             <div class="object-content"><span>${
                               this.data.name
                             }</span><img src="/assets/icons-white/${this.getIconNameForBorehole()}.svg" alt=""></div></div>`,
          }),
          objectId: this.data.id,
        } as any)
      );
      this.key = +Object.keys(this.objectsLayer._layers)[0];
    }
  }

  ngOnInit(): void {
    if (this.data) {
      this.options.center = latLng(
        this.data.latitude || 0,
        this.data.longitude || 0
      );
      this.objectsLayer.clearLayers();
      this.objectsLayer.addLayer(
        marker([this.data.latitude || 0, this.data.longitude || 0], {
          icon: divIcon({
            html: `<div class="object-map-balloon object-status-${this.getColorClassForBalloon()}" style="font-size: ${
              this.data.name.length > 6
                ? (5 / this.data.name.length) * 20 >= 10
                  ? 10
                  : (5 / this.data.name.length) * 20
                : 10
            }px">
                             <div class="object-content"><span>${
                               this.data.name
                             }</span>
                             <img src="/assets/icons-white/${this.getIconNameForBorehole()}.svg" alt=""></div></div>`,
          }),
          objectId: this.data.id,
        } as any)
      );
      this.key = +Object.keys(this.objectsLayer._layers)[0];
    }
  }

  getColorClassForBalloon() {
    return 'default';
  }

  getIconNameForBorehole() {
    return this.data?.attributes?.type_mining
      ? `gzu_${this.data?.attributes?.type_mining}`
      : 'gzu';
  }

  onMapReady(map: any) {
    LeafletMapService.translateZoomButtons(map);
    this.mapDns = map;
  }

  handleClickOnMap($event: LeafletMouseEvent) {
    if (this.isEdit) {
      if (
        $event.latlng.lat < 90 &&
        $event.latlng.lat > -90 &&
        $event.latlng.lng < 180 &&
        $event.latlng.lng > -180
      ) {
        const latitude = $event.latlng.lat;
        const longitude = $event.latlng.lng;
        const newLatLng = latLng(latitude, longitude);
        this.objectsLayer._layers[this.key].setLatLng(newLatLng);
        this.latitude = latitude;
        this.longitude = longitude;
        this.onClickOnMap.emit({
          lat: $event.latlng.lat,
          lng: $event.latlng.lng,
        });
      } else {
        this.notificationsService
          .open('Эта зона карты недоступна для взаимодействия с ней', {
            status: TuiNotification.Error,
          })
          .subscribe();
      }
      return;
    }
  }
}
