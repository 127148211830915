<ng-container [formGroup]="form">

  <tui-multi-select
    *tuiLet="availableItems$ | async as items"
    [class.tui-skeleton]="skeletonVisible"
    [class.tui-skeleton_rounded]="skeletonVisible"
    #component
    tuiTextfieldExampleText="{{ placeholderMessage }}"
    class="control"
    [ngClass]="{ 'grey-input' : greyStyle }"
    tuiTextfieldSize="m"
    [stringify]="stringifyComboBox"
    formControlName="{{ formControlNameSelect }}"
    [tuiTextfieldLabelOutside]="true"
    (input)="onItemSearchChange(extractValueFromEvent($event))"
  >
    {{ label }}
    <tui-data-list-wrapper
      *tuiDataList
      [items]="items | tuiHideSelected : component"
      [itemContent]="skeletonVisible ? loading : statusSelectContent"
    ></tui-data-list-wrapper>
  </tui-multi-select>

<!--  <tui-combo-box-->
<!--    [class.tui-skeleton]="skeletonVisible"-->
<!--    [class.tui-skeleton_rounded]="skeletonVisible"-->
<!--    *tuiLet="availableItems$ | async as items"-->
<!--    tuiTextfieldSize="m"-->
<!--    class="tui-space_vertical-1"-->
<!--    [ngClass]="{ 'grey-input' : greyStyle }"-->
<!--    formControlName="{{ formControlNameSelect }}"-->
<!--    [stringify]="stringifyComboBox"-->
<!--    [valueContent]="skeletonVisible ? loading : statusSelectContent"-->
<!--  >-->
<!--    {{ label }}-->
<!--    <input-->
<!--      tuiTextfield-->
<!--      placeholder="{{ placeholderMessage }}"-->
<!--      (input)="onItemSearchChange(extractValueFromEvent($event))"-->
<!--    />-->

<!--    <tui-data-list-wrapper-->
<!--      *tuiDataList-->
<!--      [items]="items"-->
<!--      [itemContent]="statusSelectContent"-->
<!--    ></tui-data-list-wrapper>-->
<!--  </tui-combo-box>-->

  <ng-template #loading>
    <tui-loader class="loader"></tui-loader>
  </ng-template>
  <ng-template #statusSelectContent let-data>
    <div class="template">
      <div class="name">{{ data.name }}</div>
    </div>
  </ng-template>
</ng-container>
