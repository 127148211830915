import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(public http: HttpClient) {}

  getList() {
    return this.http.get(`${environment.apiUrl}users/list/`).pipe(
      map((response: any) => {
        return response.list;
      })
    );
  }

  toggleActive(userId: number) {
    return this.http
      .get(`${environment.apiUrl}users/is_active/${userId}/`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  addUser(body: any) {
    return this.http.post(`${environment.apiUrl}users/create/`, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  editUser(body: any, userId: number) {
    return this.http
      .put(`${environment.apiUrl}users/update/${userId}/`, body)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteUser(userId: any) {
    return this.http
      .delete(`${environment.apiUrl}users/delete/${userId}/`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
