import { PipelineColorType } from './../../../../../models/pipeline.model';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TuiAlertService } from '@taiga-ui/core';
import { PowerLinesService } from 'src/app/services/power-lines.service';
import { TypePowerLineListModel } from 'src/models/power-line.model';

//проверка на пробелы
function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control?.value?.trim();
    if (control?.value?.length !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

export function minValueValidator(context: { min: number }): string {
  return `Значение должно быть не меньше ${context.min}`;
}

export function maxValueValidator(context: { max: number }): string {
  return `Значение должно быть не больше ${context.max}`;
}

@Component({
  selector: 'app-power-line-add',
  templateUrl: './power-line-add.component.html',
  styleUrls: ['./power-line-add.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class PowerLineAddComponent implements OnInit {
  //закрытие диалог окна
  @Input() closeModal: any = () => {};

  //координаты приходят со страницы карты при завершении рисования линии трубы
  @Input()
  coordinates: { latitude: number; longitude: number }[] = [];

  // возвращает созданное месторождение
  @Output() requestCreatedData: EventEmitter<null> = new EventEmitter();

  //форма для имени ЛЭП
  public powerLineForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    purpose: new FormControl('', []),
    laying_method: new FormControl('', []),
    voltage: new FormControl('', []),
    current_type: new FormControl('', []),
    connection_point: new FormControl('', []),
  });

  public coords: { latitude: number; longitude: number }[] = [];

  public electricityPipeline: {
    line: { latitude: number; longitude: number }[];
    type: PipelineColorType.ELECTRICITY;
  }[] = [];

  // Массив опций для выпадающего списка поля "Род тока"
  public currentType: any[] | undefined;

  // Массив опций для выпадающего списка поля "Способ прокладки"
  public layingType: any[] | undefined;

  public isEdit: boolean = true;

  constructor(
    public powerLinesService: PowerLinesService,
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService
  ) {}

  async ngOnInit() {
    if (this.coordinates.length) {
      this.isEdit = false;
    }
    this.coords = this.coordinates;
    this.electricityPipeline = [
      {
        line: this.coordinates.length ? this.coordinates : [],
        type: PipelineColorType.ELECTRICITY,
      },
    ];

    // Получаем список для выпадающего списка поля "Род тока"
    this.currentType = this.powerLinesService.currentType;

    // Получаем список для выпадающего списка поля "Способ прокладки"
    this.layingType = this.powerLinesService.layingType;
  }

  getCoords(coords: { latitude: number; longitude: number }[]) {
    this.coords = coords;
  }

  // проверка на валидность поля -> отправка запроса при успешной валидации
  submitForm() {
    if (!this.powerLineForm.valid) {
      this.notificationsService
        .open('Проверьте правильность заполнения формы', {
          label: '',
          status: TuiNotification.Error,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      return this.powerLineForm.markAllAsTouched();
    }
    if (!this.coords.length) {
      this.notificationsService
        .open('Обозначьте ЛЭП на карте', {
          label: '',
          status: TuiNotification.Error,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      return;
    }

    let body: TypePowerLineListModel = {
      name: this.powerLineForm.get('name').value,
      purpose: this.powerLineForm.get('purpose').value || null,
      laying_method: this.powerLineForm.get('laying_method').value || null,
      voltage: this.powerLineForm.get('voltage').value || null,
      current_type: this.powerLineForm.get('current_type').value || null,
      connection_point:
        this.powerLineForm.get('connection_point').value || null,

      coordinates: [
        {
          line: this.coords,
        },
      ],
    };

    this.powerLinesService.createPowerLine(body).subscribe(() => {
      this.notificationsService
        .open('Объект добавлен', {
          label: '',
          status: TuiNotification.Success,
          hasIcon: true,
          autoClose: true,
          hasCloseButton: true,
        })
        .subscribe();
      // this.requestCreatedData.emit();
      this.closeDialog();
    });
  }

  // очистка полей и закрытие
  closeDialog() {
    this.powerLineForm.reset({ name: '' });
    this.requestCreatedData.emit();
    this.closeModal();
  }
}
