import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TuiNotification } from '@taiga-ui/core/enums';
import { TuiAlertService } from '@taiga-ui/core';
import { ObjectService } from '../../../../../services/object.service';
import { TypeTree } from 'src/models/tree.model';
import { TuiContextWithImplicit, tuiPure } from '@taiga-ui/cdk';
import { TUI_VALIDATION_ERRORS } from '@taiga-ui/kit';
import { Subject, takeUntil } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

function checkForFirstSpaceCharacter(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let copyValue = control.value?.trim() || '';
    if ((control?.value?.length || '') !== copyValue?.length) {
      return { invalidAutocompleteObject: { value: control.value } };
    }
    return null; /* valid option selected */
  };
}

export function spacesFromBothSidesValidator(): string {
  return `Поле не должно содержать пробел в начале значения и в конце`;
}

export function minValueValidator(context: { min: number }): string {
  return `Значение должно быть не меньше ${context.min}`;
}

export function maxValueValidator(context: { max: number }): string {
  return `Значение должно быть не больше ${context.max}`;
}

@Component({
  selector: 'app-common-info-dns',
  templateUrl: './common-info-dns.component.html',
  styleUrls: ['./common-info-dns.component.less'],
  providers: [
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        required: `Поле обязательно для заполнения`,
        min: minValueValidator,
        max: maxValueValidator,
        invalidAutocompleteObject: spacesFromBothSidesValidator,
      },
    },
  ],
})
export class CommonInfoDnsComponent implements OnInit, OnDestroy {
  public label: any[] = [
    {
      name: 'name',
      title: 'Название',
      isSort: true,
      isFilter: true,
    },
    {
      name: 'value',
      title: 'Значение',
      isSort: true,
      isFilter: true,
    },
  ];

  public data: any = null;

  public editObjectMode: boolean = false;

  public visibleMap: boolean = false;

  public parent: any | null = null;

  public onClickOnMap = new EventEmitter();

  @Input()
  public contextObject: any = null;

  @Output() handleChange: EventEmitter<any> = new EventEmitter();

  public objectInfoForm: any = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(40),
      checkForFirstSpaceCharacter(),
    ]),
    deposit: new FormControl('', [Validators.required]),
    latitude: new FormControl(0, [
      Validators.required,
      Validators.min(-90),
      Validators.max(90),
    ]),
    longitude: new FormControl(0, [
      Validators.required,
      Validators.min(-180),
      Validators.max(180),
    ]),
  });

  //для формы -> список скважин
  public depositList: any = [];

  public destroyer: Subject<null> = new Subject();

  public latitude: number = 0;

  public longitude: number = 0;

  constructor(
    @Inject(TuiAlertService)
    private readonly notificationsService: TuiAlertService,
    public objectService: ObjectService
  ) {}

  async ngOnInit() {
    if (this.contextObject) {
      const deposits = await this.objectService.deposits;
      this.depositList = deposits.map((item: TypeTree) => {
        return { id: item.id, title: item.name };
      });
      this.parent = this.depositList.find((item: TypeTree) => {
        return item.id === this.contextObject.parent_id;
      });
      this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
      this.objectInfoForm
        .get('latitude')
        ?.setValue(this.contextObject?.latitude || 0);
      this.objectInfoForm
        .get('longitude')
        ?.setValue(this.contextObject?.longitude || 0);
      this.objectInfoForm.get('deposit')?.setValue(this.parent || null);
      this.latitude = this.contextObject?.latitude || 0;
      this.longitude = this.contextObject?.longitude || 0;

      this.onClickOnMap
        .pipe(takeUntil(this.destroyer))
        .subscribe((coordinates) => {
          this.objectInfoForm.get('latitude')?.setValue(coordinates.lat);
          this.objectInfoForm.get('longitude')?.setValue(coordinates.lng);
        });
      this.objectInfoForm
        .get('latitude')
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (this.objectInfoForm.get('latitude')?.valid) {
            this.latitude = item;
            return;
          }
          return;
        });
      this.objectInfoForm
        .get('longitude')
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyer))
        .subscribe((item: any) => {
          if (this.objectInfoForm.get('longitude')?.valid) {
            this.longitude = item;
            return;
          }
          return;
        });
      this.visibleMap = true;
    }
  }

  save() {
    if (!this.objectInfoForm.valid) {
      return this.objectInfoForm.markAllAsTouched();
    }
    if (
      this.objectInfoForm.dirty ||
      this.objectInfoForm.get('latitude')?.value !==
        this.contextObject?.latitude ||
      this.objectInfoForm.get('longitude')?.value !==
        this.contextObject?.longitude
    ) {
      let body: TypeTree = {
        ...this.contextObject,
        name: this.objectInfoForm.get('name').value,
        parent_id: this.objectInfoForm.get('deposit')?.value?.id || null,
        latitude: this.objectInfoForm.get('latitude')?.value ?? null,
        longitude: this.objectInfoForm.get('longitude')?.value ?? null,
      };
      this.objectService
        .updateObject(body, this.contextObject.id)
        .pipe(takeUntil(this.destroyer))
        .subscribe((dns: any) => {
          this.contextObject = {
            ...this.contextObject,
            name: dns.updated_object.name,
            parent_id: this.objectInfoForm.get('deposit')?.value?.id || null,
            latitude: dns.updated_object.latitude,
            longitude: dns.updated_object.longitude,
          };
          this.parent = this.depositList.find((item: TypeTree) => {
            return item.id === this.contextObject.parent_id;
          });
          this.notificationsService
            .open('Объект обновлен', {
              label: '',
              status: TuiNotification.Success,
              hasIcon: true,
              autoClose: true,
              hasCloseButton: true,
            })
            .subscribe();
          this.handleChange.emit();
          this.editObjectMode = false;
        });
    } else {
      this.editObjectMode = false;
    }
  }

  changeInput(): void {
    this.editObjectMode = !this.editObjectMode;
  }

  cancelInput(): void {
    this.objectInfoForm.reset();
    this.objectInfoForm.get('name')?.setValue(this.contextObject?.name || '');
    this.objectInfoForm.get('deposit')?.setValue(this.parent || null);
    this.objectInfoForm
      .get('latitude')
      ?.setValue(this.contextObject?.latitude || 0);
    this.objectInfoForm
      .get('longitude')
      ?.setValue(this.contextObject?.longitude || 0);
    if (
      this.objectInfoForm.get('latitude')?.value !==
        this.contextObject?.latitude ||
      this.objectInfoForm.get('longitude')?.value !==
        this.contextObject?.longitude
    ) {
      this.visibleMap = true;
    }
    this.editObjectMode = false;
  }

  @tuiPure
  stringify(items: readonly any[]): any {
    const map = new Map(
      items.map(({ id, name }) => [id, name] as [number, string])
    );

    return ({ $implicit }: TuiContextWithImplicit<number>) =>
      map.get($implicit) || '';
  }

  ngOnDestroy(): void {
    this.destroyer.next(null);
    this.destroyer.complete();
  }
}
