import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { FormAttrBase } from "./form-attr-base";
import { FormAttrControlService } from "./form-attr-control.service";
import { TUI_VALIDATION_ERRORS } from "@taiga-ui/kit";

export function minValidator(context: { requiredLength: string }): string {
  return `Значение должно быть не меньше ${context.requiredLength}`;
}

export function maxValidator(context: { requiredLength: string }): string {
  return `Значение должно быть не больше ${context.requiredLength}`;
}

export function maxLengthValidator(context: {
  requiredLength: string;
}): string {
  return `Количество символов должно быть не больше ${context.requiredLength}`;
}

export function minLengthValidator(context: {
  requiredLength: string;
}): string {
  return `Количество символов должно быть не меньше ${context.requiredLength}`;
}

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FormAttrControlService,
    {
      provide: TUI_VALIDATION_ERRORS,
      useValue: {
        min: minValidator,
        max: maxValidator,
        required: "Поле обязательно для заполнения",
        email: "Пожалуйста, введите валидный email адрес",
        minlength: minLengthValidator,
        maxlength: maxLengthValidator,
        pattern: "Поле заполнено некорректно",
      },
    },
  ],
})
export class FormComponent implements OnInit {
  @Input() formAttrs: FormAttrBase<string>[] | null = [];

  // костыль use anyway!
  @Input() formAttrsObservable?: any;

  // костыль
  @Output() beforeSubmit: any = new EventEmitter<any>();

  // нажатие на первую кнопку
  @Output() sendForm: any = new EventEmitter<string>();

  // клик по другой кнопке
  @Output() sendInfoFromAnotherBtn: any = new EventEmitter<any>();

  form!: FormGroup;

  constructor(
    private facs: FormAttrControlService,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.formAttrsObservable) {
      this.formAttrsObservable.subscribe((attrs: any) => {
        this.form = this.facs.toFormGroup(attrs as FormAttrBase<string>[]);
        this.changeDetectionRef.detectChanges();
      });
    }
  }

  onSubmit(formProperty: any) {
    this.sendForm.emit(formProperty);
    this.beforeSubmit.emit();
  }

  clickOnAnotherBtn(obj: any) {
    this.sendInfoFromAnotherBtn.emit(obj);
  }
}
