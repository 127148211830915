<tui-loader [overlay]="true" [showLoader]="isLoading" [size]="'xxl'" class="{{ isLoading ? 'loader' : '' }}">
  <div *ngIf="selectedObject">
    <app-primary-section>
      <h2 class="oilfield-popup__title">
        {{ selectedObject?.name || "object" }}
      </h2>
      <tui-tabs class="tabs">
        <button
          class="tabs__btns"
          tuiTab
          *ngFor="let tab of tabs"
          (click)="changeTabElement(tab)"
        >
          <div>
            {{ tab.title }}
          </div>
        </button>
      </tui-tabs>
    </app-primary-section>
    <div *ngIf="activeItemIndex.url === 'info'" class="container__form">
      <app-common-info-oilfield
        [contextObject]="selectedObject"
        (handleChange)="getEditableDeposit($event)"
      >
      </app-common-info-oilfield>
    </div>
    <app-boreholes-oilfield
      *ngIf="activeItemIndex.url === 'boreholes'"
      [selectedDeposit]="selectedObject"
      (handleChange)="getEditableDeposit($event)"
    ></app-boreholes-oilfield>
    <div *ngIf="activeItemIndex.url === 'events'">
      <app-events-oilfield [depositId]="selectedObject.id"></app-events-oilfield>
    </div>

    <app-primary-section *ngIf="activeItemIndex.url === 'scheme'">
      <app-scheme-viewer
        [contextObject]="selectedObject"
        (closeModal)="close()"
      ></app-scheme-viewer>
    </app-primary-section>
  </div>
</tui-loader>


